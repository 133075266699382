import { Box, Container, Modal, Typography } from '@material-ui/core'
import { Button } from 'components'
import { useState } from 'react'

import { useStyles } from './styles'

interface IModalPrinter {
  open: boolean
  handleClose: () => void
  confirm: () => Promise<void>
}

export const ModalReprinter = ({ confirm, handleClose, open }: IModalPrinter) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = async () => {
    setIsLoading(true)
    await confirm()
    handleClose()
    setIsLoading(false)
  }

  return (
    <Modal open={open} className={classes.root}>
      <Container className={classes.container}>
        {isLoading ? (
          <Typography className={classes.loading}>Aí Sim! Tamo imprimindo...🖨️</Typography>
        ) : (
          <>
            <Typography className={classes.title}>Aoba! Cê quer mais etiqueta mesmo?</Typography>

            <Box className={classes.containerBtn}>
              <Button className={classes.btnNo} onClick={handleClose}>
                QUERO NÃO, VALEU! 👍
              </Button>
              <Button onClick={handleConfirm} variant="contained">
                QUERO UAI! 🤠
              </Button>
            </Box>
          </>
        )}
      </Container>
    </Modal>
  )
}
