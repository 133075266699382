import { TitlePage, ContainerPage, Tabs } from './styles'
import { useState, useEffect, ChangeEvent } from 'react'

import { useConferenceChuchu } from 'hooks/useConferenceChuchu'
import { Box, Tab } from '@material-ui/core'
import CriticalOrders from './CriticalOrders'
import RoutesOver from './RoutesOver'
import { useSelector } from 'react-redux'
import { IState } from 'store'
import { enRules } from 'interfaces/IUser'

const Conference = () => {
  const { recoveryConference } = useConferenceChuchu()
  const [tab, setTab] = useState(0)
  const userPermissions = useSelector<IState, enRules[]>(state => state.user.userPermissions)

  const hasPermission = userPermissions.includes(enRules.SeparationCheckCriticalOrder)

  const onChange = (_: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  useEffect(() => {
    recoveryConference()
  }, [])

  return (
    <ContainerPage style={{ height: 'auto' }}>
      <TitlePage>Conferência</TitlePage>
      <Box>
        <Tabs value={tab} onChange={onChange} centered>
          <Tab label="Pedidos críticos" />
          {hasPermission && <Tab label="Rotas estouradas" />}
        </Tabs>
      </Box>
      <Box>
        {tab === 0 && <CriticalOrders />}
        {tab === 1 && <RoutesOver />}
      </Box>
    </ContainerPage>
  )
}

export default Conference
