import { TextField, makeStyles, styled } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    padding: '1rem 0.875rem',
    marginBottom: '2rem',
    borderRadius: '0.37rem',
    flexDirection: 'column',
  },
  boxContainerInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    marginBottom: '1rem',
  },
  img: {
    width: '4rem',
    height: '4rem',
    borderRadius: '4.37rem',
    float: 'left',
    margin: '0 1rem 1rem 0',
  },
  typography: {
    lineHeight: '1.31rem',
    fontSize: '1.12rem',
    '&:not(:last-child)': {
      marginBottom: '0.5rem',
    },
  },
  boxButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  button: {
    padding: '1rem',
    color: '#FFF',
    width: '49%',
    borderRadius: '4px',
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: '#C4C4C4 !important',
    },
  },
  buttonSecondary: {
    display: 'block',
    padding: '0.5rem 0.5rem 0.5rem 1rem',
    textTransform: 'none',
    borderRadius: '0',
  },
  boxContainerList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    border: '1px solid #C4C4C4',
    borderRadius: '0.62rem',
  },
  boxContainerTotal: {
    padding: '1rem 0.5rem 1rem 1rem',
    lineHeight: '1rem',
    fontSize: '1rem',
    borderTop: '1px solid #C4C4C4',
    height: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const InvisibleInput = styled(TextField)({
  opacity: 0,
  position: 'absolute',
})
