/* eslint-disable no-undef */
import { FC } from 'react'

interface SwitchProps<T> {
  test: T
  children: JSX.Element[]
}

export const Switch = <T,>({ test, children }: SwitchProps<T>) => {
  const defaultResult = children.find(child => child.props.default) || null
  const result = children.find(child => child.props.value === test)
  return result || defaultResult || null
}

type CasePropsWithRequiredProp = {
  children: JSX.Element | JSX.Element[]
} & ({ value: string | number } | { default: boolean })

export const Case: FC<CasePropsWithRequiredProp> = ({ children }) => children as JSX.Element | null
