import { Typography } from "@material-ui/core";
import { Container, ImgConveyorConnection, ImgWebSocketConnection } from "./styles";
import signalSvg from 'assets/icons/signal-svg.svg'
import conveyorSvg from 'assets/icons/conveyor.svg'

interface IProps {
  isConnected: boolean
  bottomValue: string
  Image: any
}

export const ImageConveyorConnection = () => <ImgConveyorConnection src={conveyorSvg} />

export const ImageWebSocketConnection = () => <ImgWebSocketConnection src={signalSvg} />



const CardConnection = (props: IProps) => {
  return (
    <Container isConnected={props.isConnected} bottomValue={props.bottomValue}>
      <props.Image />
      <Typography>{props.isConnected ? 'Conectado' : 'Desconectado'}</Typography>
    </Container>)
}

export default CardConnection;
