import {
  Box,
  styled,
  Accordion as AccordionMui,
  AccordionSummary as AccordionSummaryMui,
  AccordionDetails as AccordionDetailsMui,
  Typography,
  Table as TableMui,
  makeStyles,
  Button,
} from '@material-ui/core'

export const Container = styled(Box)(({ theme }) => ({
  marginTop: '2rem',
  height: '100%',
  paddingBottom: '3rem',
}))

export const ContainerRoutes = styled(Box)(({ theme }) => ({
  marginTop: '1rem',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#A1CA4F',
    borderRadius: '9px',
  },
}))

export const useStyles = makeStyles({
  skeleton: {
    animation: '$waveAnimation 2s infinite',
  },
  '@keyframes waveAnimation': {
    '0%': {
      backgroundPosition: '200% 0',
    },
    '100%': {
      backgroundPosition: '-200% 0',
    },
  },
  loadingSpinner: {
    border: '4px solid #f3f3f3',
    borderTop: '4px solid #618619',
    borderRadius: '50%',
    width: '25px',
    height: '25px',
    animation: '$spin 2s linear infinite',
    marginRight: '.5rem',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
})

export const SkeletonAccordion = styled(Box)({
  height: '48px',
  width: '100%',
  marginBottom: '1rem',
  borderRadius: '4px',
  background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
  backgroundSize: '200% 100%',
  minWidth: '580px',
})

export const AlertError = styled(Box)(
  ({ background, borderColor }: { background: string; borderColor: string }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '48px',
    minHeight: '48px',
    width: '100%',
    background: background,
    color: borderColor,
    borderRadius: '4px',
    border: `1px solid ${borderColor}`,
    fontSize: '1rem',
    fontWeight: 400,
    textAlign: 'center',
    padding: '0 1rem',
  }),
)

export const Accordion = styled(AccordionMui)(({ theme }) => ({
  background: 'white',
  minWidth: '580px',
  '&:not(:last-child)': {
    marginBottom: '1rem',
  },
}))

export const AccordionSummary = styled(AccordionSummaryMui)(
  ({ colorRoute }: { colorRoute: string }) => ({
    position: 'relative',
    '& .MuiAccordionSummary-content': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 200px',
      margin: ' 0 1rem',
    },
    '& .MuiTypography-root': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      width: '15px',
      background: colorRoute,
      borderRadius: '4px 0 0 4px',
    },
  }),
)

export const AccordionDetails = styled(AccordionDetailsMui)(({ theme }) => ({
  maxHeight: '400px',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  '&::-webkit-scrollbar': {
    width: '10px',
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#A1CA4F',
    borderRadius: '9px',
  },
}))

export const RouteStatus = styled(Typography)(
  ({ backgroundRoute }: { backgroundRoute: string }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& span': {
      backgroundColor: backgroundRoute,
      padding: '5px 10px',
      borderRadius: '8px',
      color: '#FFF',
      fontSize: '0.9rem',
    },
  }),
)

export const ButtonPrint = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  boxShadow: '1px 1px 1px 1px #ccc',
  '&:disabled': {
    filter: 'grayscale(100%)',
    color: '#FFF',
    cursor: 'not-allowed',
  },
}))

export const Table = styled(TableMui)(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  '& td, & th': {
    textAlign: 'center',
  },
  '& tbody tr:nth-child(odd)': {
    background: '#fbfcfa',
  },
  '& tbody tr:nth-child(even)': {
    background: '#FFF',
  },
}))
