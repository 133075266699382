import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    margin: '0 1rem',
    background: '#ffffff',
    width: '100%',
    height: '90vh',
    maxWidth: '750px',
    maxHeight: '700px',
    borderRadius: '4px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A1CA4F',
      borderRadius: '9px',
    },
  },
  content: {
    padding: '1rem 2rem 2rem',
  },
  header: {
    background: '#ffffff',
    boxShadow: 'none',
    flexDirection: 'row',
    padding: '0 2rem',
  },
  contentHeader: {
    borderBottom: '1px solid #C4C4C4',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    width: '100%',
    padding: '2rem 0 1rem',
  },
  trackImg: {
    background: '#f1eafd',
    borderRadius: '50%',
    width: '1.875rem',
    height: '1.875rem',
    padding: '0.3rem',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 500,
    fontFamily: '"Inter", sans-serif',
    color: '#353535',
  },
  closeBtn: {
    position: 'absolute',
    top: '60%',
    right: '2rem',
    transform: 'translateY(-50%)',
    height: '2.5rem',
    width: '2.5rem',
  },
  replecementBox: {
    marginBottom: '1rem',
  },
  separation: {
    fontFamily: '"Inter", sans-serif',
    color: '#353535',
    fontSize: '1rem',
    fontWeight: 600,
    marginBottom: '0.3rem',
  },
  user: {
    fontFamily: '"Inter", sans-serif',
    fontSize: '0.875rem',
    color: '#757575',
    fontWeight: 400,
  },
  nameUser: {
    color: '#353535',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  product: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem 0',
    'div + &': {
      borderTop: '1px solid #C4C4C4',
    },
  },
  productBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    paddingRight: '0.4rem',
    flex: 2,
  },
  productImg: {
    background: '#6B727D',
    borderRadius: '50%',
    minWidth: '1.875rem',
    height: '1.875rem',
  },
  btnReposition: {
    minWidth: '6.55rem',
    padding: '0.45rem',
    fontSize: '0.875rem',
  },
  boxBtn: {
    display: 'flex',
    gap: '0.875rem',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    flex: 1,
  },
})
