import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  container: {
    width: '7.4rem',
    height: '7.4rem',
    background: 'linear-gradient(261.08deg, #88C946 5.76%, #618619 87.46%), #C4C4C4',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    transtion: 'background 0.4s',
    paddingTop: '0.7rem',
    borderRadius: '0.625rem',
    '& > span': {
      display: 'flex',
      flexDirection: 'column',
    },
    '&:hover': {
      boxShadow:
        '0px 0px 20px rgba(0, 0, 0, 0.14), 0px 10px 10px rgba(0, 0, 0, 0.12), 0px 4px 6px rgba(0, 0, 0, 0.2)',
    },
  },
  fast: {
    background: 'linear-gradient(261.08deg, #9724BD 5.76%, #540070 87.46%), #C4C4C4',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
  },
  scanner: {
    background: 'linear-gradient(261.08deg, #3b6c09 5.76%, #384e0d 87.46%), #C4C4C4',
  },
  image: {
    width: '40%',
    marginBottom: '9px',
  },
  title: {
    color: '#FAFAFA',
    fontWeight: 500,
    fontSize: '0.9rem',
  },
})
