import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { styled } from '@material-ui/core'
import { ptBR } from 'date-fns/locale'
import { DateRangePicker, DateRangePickerProps, Range } from 'react-date-range'

interface IProps extends DateRangePickerProps {
  anchorEl: any
  startDate: Date
  endDate: Date
}

const Root = styled(DateRangePicker)({
  '& .rdrDefinedRangesWrapper': { display: 'none' },
})

const Component = (props: IProps) => {
  const selectionRange: Range = {
    startDate: props.startDate,
    endDate: props.endDate,
    key: 'selection',
  }

  const isSaturday = (date: Date) => {
    return date.getDay() === 6
  }

  return (
    <Root
      ranges={[selectionRange]}
      showDateDisplay={false}
      direction="horizontal"
      locale={ptBR}
      dateDisplayFormat="dd/MM/yyyy"
      shownDate={props.startDate}
      rangeColors={['#89BD23']}
      disabledDay={isSaturday}
      color="#1C1C1C"
      {...props}
    />
  )
}

export default Component
