export const mapFilter = (value: string) => {
  const filter = {
    today_data: 'Hoje',
    week_data: 'Semana',
    month_data: 'Mês',
    all_time_data: 'Todos',
  }[value]

  return filter
}
