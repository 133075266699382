import { Box, darken, Typography, makeStyles } from '@material-ui/core'
import { Button } from 'components'
import { IoCloseCircleOutline } from 'react-icons/io5'

interface Props {
  title?: string
  handleClose: () => void
  handleSend?: () => Promise<void> | void
}

const useStyles = makeStyles({
  boxError: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  message: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1rem',
    color: '#353535',
    margin: '1rem 0 2.5rem',
  },
  boxButtons: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    padding: '1rem 2.6rem',
    background: '#618619',
  },
  btnClose: {
    width: '8.3rem',
    height: '3.1rem',
    background: '#FFFFFF',
    color: '#757575',
    border: '1px solid #C4C4C4',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    marginTop: '1rem',
    '&:hover': {
      background: darken('#FFFFFF', 0.02),
    },
  },
})

export const Error = (props: Props) => {
  const classes = useStyles()
  const { title, handleSend, handleClose } = props

  return (
    <Box>
      <Box className={classes.boxError}>
        <IoCloseCircleOutline size="4.5rem" color="#DA3737" />
        <Typography variant="subtitle1" className={classes.message}>
          {title}
        </Typography>
      </Box>
      <Box className={classes.boxButtons}>
        <Button type="submit" onClick={handleSend} className={classes.btn}>
          Tentar novamente
        </Button>
        <Button
          type="submit"
          onClick={handleClose}
          className={classes.btnClose}
        >
          Fechar
        </Button>
      </Box>
    </Box>
  )
}
