import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    '&.MuiTableCell-root': {
      borderBottom: 'none',
      color: '#353535',
      fontSize: '1.1rem',
    },
    '& .MuiButtonBase-root': {
      color: '#353535',
      fontSize: '1.1rem',
    },
  },
})
