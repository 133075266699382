import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    width: '1.8rem',
    height: '1.8rem',
    border: '0.5px dashed #C4C4C4',
    borderRadius: '1.6rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '55%',
    },
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  description: {
    fontWeight: 500,
    color: '#ffffff',
  },
  descriptionUpdated: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1rem',
    color: '#757575',
    marginRight: '0.5rem',
    '& strong': {
      color: '#0d1b2a',
    },
  },
  containerNamePage: {
    [theme.breakpoints.down('sm')]: {
      width: '40%',
    },
  },
}))
