import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  container: {
    background: '#FFFFFF',
    borderRadius: '4px',
    padding: '1rem',
    boxShadow:
      '0px 0px 2px rgba(160, 160, 160, 0.14), 0px 2px 2px rgba(160, 160, 160, 0.12), 0px 1px 3px rgba(160, 160, 160, 0.2)',
  },
  containerSeparations: {
    position: 'relative',
    height: '13rem',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #C4C4C4',
    padding: '0.5rem 0',
    marginBottom: '1rem',
  },
  containerProducts: {
    marginTop: '0.5rem',
  },
})
