import { darken } from '@material-ui/core'
import { Overrides } from '@material-ui/core/styles/overrides'
import {} from '@material-ui/pickers'

const DatePicker = (): Overrides => {
  return {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#43A047',
        '& .MuiTypography-root': {
          color: '#ffffff',
        },
      },
    },
    MuiPickersYear: {
      year: {
        color: '#353535',
      },
      yearSelected: {
        color: '#43A047',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: '#353535',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#353535',
      },
      daySelected: {
        backgroundColor: '#43A047',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: darken('#43A047', 0.1),
        },
      },
      current: {
        color: '#43A047',
      },
    },
  }
}

export default DatePicker
