import { IDayliRankingResponse, IWeeklyRankingResponse } from 'interfaces/IRanking'

import { api as apiService, ApiService } from './api'
interface IParamsRanking {
  initialSeparationDate: string | Date
  finalSeparationDate: string | Date
}

class RankingService {
  constructor(private readonly api: ApiService) {}

  public async getDailyRanking(controller: AbortController): Promise<IDayliRankingResponse> {
    return await this.api.get(carambolaUrl + '/ranking/daily/v2', {
      signal: controller.signal,
    })
  }
  public async getWeeklyRanking(controller: AbortController): Promise<IWeeklyRankingResponse> {
    return await this.api.get(carambolaUrl + '/ranking/weekly/v2', {
      signal: controller.signal,
    })
  }
  public async getRanking(
    params: IParamsRanking,
    controller: AbortController,
  ): Promise<IDayliRankingResponse> {
    return await this.api.get(carambolaUrl + '/ranking/filter', {
      params,
      signal: controller.signal,
    })
  }
}
const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const rankingService = new RankingService(apiService)
