export const mapErrors = (value: string): string => {
  const error =
    {
      'New item to be separated': 'Novo item a ser separado',
      'Please wait for the other separators to finish':
        'Espere que os outros separadores terminem.',
      'Separation finished!': 'Separação concluída!',
      'Item not found': 'Item não encontrado',
      'Separation Order not found': 'Ordem de separação não encontrada',
      'Order Separation not found': 'Separação de pedido não encontrada',
      'Separation level still in progress': 'Nível de separação ainda em andamento.',
      'Maximum number of orders already being separated on this track. Please wait.':
        'Número máximo de pedidos sumultâneos atingido.',
      'Wait for the previous separation to complete': 'Aguarde a conclusão da separação anterior.',
      'Separation not found': 'Separação não encontrada',
      'Separation finished': 'Separação terminada',
      "Couldn't get new orders to separate": 'No momento não há pedidos para serem separados',
      'Network Error': 'Por favor, verifique sua conexão com a internet e tente novamente.',
    }[value] || 'Ocorreu um erro, contate o administrador'

  return error
}
