import { PaletteOptions } from '@material-ui/core/styles/createPalette'

const palette: PaletteOptions = {
  primary: {
    main: '#89bd23',
  },
  secondary: {
    main: '#416D0B',
  },
  warning: {
    main: '#DA3737',
  },
}

export default palette
