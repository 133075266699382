import { useState, ChangeEvent } from 'react'
import { ContainerPage, TitlePage } from 'components'
import BreakReport from './BreakReport'
import AverageTimeOrder from './AverageTimeOrder'
import { Box, Tab, Tabs, makeStyles } from '@material-ui/core'
import AverageItemTime from './AverageTimeItem'

const useStyles = makeStyles(theme => {
  const backgroundColor = (props: number) => {
    return {
      0: '#854AC2',
      1: '#2549CC',
      2: '#567B0D',
    }[props] || '#FFFFFF'
  }

  return {
    tabs: {
      marginBottom: '2.5rem',
      '& .MuiTab-wrapper': {
        fontWeight: 500,
        textTransform: 'none',
        fontSize: '1.1rem',
      },
      '& .MuiTabs-indicator': {
        height: '5px',
        backgroundColor,
        border: 'none',
        borderRadius: '5px',
      },
      '& .MuiTab-textColorInherit.Mui-selected': {
        color: '#353535',
      },
      '& .MuiTab-textColorInherit': {
        color: '#A3A3A3',
      },
    },
  }
})

const Performance = () => {
  const [tab, setTab] = useState(0)
  const classes = useStyles(tab)

  const onChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }
  return (
    <ContainerPage>
      <TitlePage>Desempenho dos separadores</TitlePage>
      <Box>
        <Tabs value={tab} onChange={onChange} centered className={classes.tabs}>
          <Tab label="Média de separação / pedido" />
          <Tab label="Média de separação / item" />
          <Tab label="Total de quebras" />
        </Tabs>
        {tab === 0 && <AverageTimeOrder />}
        {tab === 1 && <AverageItemTime />}
        {tab === 2 && <BreakReport />}
      </Box>
    </ContainerPage>
  )
}

export default Performance
