import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles({
  root: {
    maxWidth: '700px',
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    fontFamily: '"Inter", sans-serif',
    color: '#353535',
    margin: '1.5rem 0 2.0rem',
  },
  subtitle: {
    fontSize: '1.3rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    fontFamily: '"Inter", sans-serif',
    color: '#353535',
    margin: '1.5rem 0 1.5rem',
  },
})
