import { Runouts } from 'interfaces/IBreakOrder'
import { IPaginationParams, IPaginationResponse } from 'interfaces/IPagination'
import { ICheckResponse, IProduct } from 'interfaces/IProduct'
import { ICheckItems, enStatusItem } from 'interfaces/ISeparationItem'

import { api as apiService, ApiService } from './api'

export interface IDataConfirmItem {
  itemId: number
  statusId?: enStatusItem
  quantitySeparated?: number
  productCode?: number | string
  codeType?: 'data_matrix' | 'bar_code' | 'qr_code'
}

export interface IStepPickItem {
  step: number
  is_finished?: boolean
  items: IProduct[]
}

export interface IDataRepositionItemResponse {
  message: string
  confirmed_quantity: number
}

export interface IGetItensToReposition {
  order_id: string
  client_name: string
  checking_status: number
  released_by: string
  is_old_order: boolean
  runouts: Runouts[]
}

export interface IDataRepositionItem {
  productCode?: string
  codeType?: string
  finishReposition?: boolean
  totalRepositionAmount?: number
}

class SeparationItemService {
  constructor(private readonly api: ApiService) {}

  public async checkItems(data: ICheckItems): Promise<ICheckResponse> {
    return await this.api.get(carambolaUrl + '/separation/separation-item/recovery', {
      params: data,
    })
  }

  public async pickItem(data: IDataConfirmItem): Promise<IStepPickItem[]> {
    return await this.api.post(carambolaUrl + `/separation/pick-item`, data)
  }

  public async breachConfirmItem({
    id,
    statusId,
    quantityPartial,
    weighings,
    workId,
  }: {
    id: number
    statusId: number
    quantityPartial?: number
    weighings?: number[]
    workId?: number
  }): Promise<void> {
    return await this.api.put(carambolaUrl + `/separation/separation-item/${id}`, {
      statusId,
      quantityPartial,
      weighings,
      workId,
    })
  }

  public async replenishItem(
    itemId: number,
    data: IDataRepositionItem,
  ): Promise<IDataRepositionItemResponse> {
    return this.api.patch(carambolaUrl + `/reposition/${itemId}`, data)
  }

  public async getItensToReposition(
    params: IPaginationParams,
    signal?: AbortSignal,
  ): Promise<IPaginationResponse<IGetItensToReposition>> {
    return this.api
      .get(carambolaUrl + '/reposition/history', { params, signal })
      .then(response => ({ ...response, totalCount: response.total_count }))
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const separationItemService = new SeparationItemService(apiService)
