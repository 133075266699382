import { makeStyles } from '@material-ui/core/styles'
import { shade } from 'polished'

export const useStyles = makeStyles({
  container: {
    width: '100%',
    borderRadius: '0.25rem',
    margin: '0 auto',
    '& + div': {
      marginTop: '20px',
    },
  },
  default: {
    background: '#FFFFFF',
    border: '0.5px solid #478D00',
  },
  confirmed: {
    background: shade(0.02, '#F5F5F5'),
    border: '0.5px solid #C4C4C4',
  },
})
