import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  headerAlign: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  trackAlign: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '2rem',
    [theme.breakpoints.between(0, 840)]: {
      justifyContent: 'center',
    },
  },
  root: {
    marginTop: '1.5rem',
    position: 'relative',
  },
  noTracks: {
    width: '100%',
    height: '11.25rem',
    color: '#757575',
    fontSize: '1.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrowLeft: {
    cursor: 'pointer',
    outline: 'none',
    position: 'absolute',
    zIndex: 3,
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    width: '6rem',
    height: '11.25rem',
    border: 'none',
    background:
      'linear-gradient(267.93deg, rgba(245, 245, 245, 0.05) 1.74%, #FAFAFA 96.48%);',
  },
  arrowRight: {
    cursor: 'pointer',
    outline: 'none',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    width: '6rem',
    height: '11.25rem',
    border: 'none',
    background:
      'linear-gradient(266.77deg, #FAFAFA 2.3%, rgba(245, 245, 245, 0.05) 93.29%)',
  },
  arrowIcon: {
    fontSize: '2.5rem',
    color: '#646464',
  },
  containerTracks: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  warning: {
    color: '#757575',
    fontSize: '1.125rem',
    fontWeight: 500,
    textAlign: 'center',
    width: '100%',
  },
}))
