import { Dialog, Typography, styled, Button as ButtonMui } from '@material-ui/core'

export const Modal = styled(Dialog)({
  '& .MuiDialog-paper': {
    padding: '2rem',
    height: '400px',
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
})

export const Title = styled(Typography)({
  fontWeight: 500,
  fontSize: '1.5rem',
  textAlign: 'center',
})

export const Text = styled(Typography)({
  fontWeight: 400,
  fontSize: '1.1rem',
  textAlign: 'center',
})

export const Button = styled(ButtonMui)({
  backgroundColor: '#89BD23',
  color: '#FFF',
  height: '45px',
  borderRadius: '4px',
})
