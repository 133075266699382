import { useTableParams } from 'hooks/useTableParams'
import moment from 'moment'
import { useState } from 'react'
import { MdExpandMore } from 'react-icons/md'
import { Scrollbar } from 'shared'
import Pagination from 'shared/TableCustom/TablePagination'

import { Loading } from '../../styles'
import { CardContainerOrdersStyled, Search } from '../CardContainerOrders/styles'
import Calendar from './components/Calendar'
import {
  ContainerFilter,
  ContainerScroll,
  Accordion,
  AccordionSummary,
  ContainerAccordions,
  ContainerLabels,
  ContainerLabelsAccordion,
} from './styles'
import { invoiceService } from 'services/invoiceService'

const CardHistory = () => {
  const controller = new AbortController()
  const { data, params, count, status, onChange, handleSearch } = useTableParams({
    service: params => invoiceService.getOrdersWithoutInvoice(params, controller.signal),
    paramsDefault: {
      operationDate: moment(new Date()).format('YYYY-MM-DD'),
      releasedWithoutInvoice: true,
      pageSize: 10,
    },
  })
  const isLoading = status === 'pending'
  const [selectedDate, setSelectedDate] = useState(new Date())

  const handleOnSelectDate = async (newDate: Date) => {
    setSelectedDate(newDate)
    onChange({
      operationDate: moment(newDate).format('YYYY-MM-DD'),
      releasedWithoutInvoice: true,
      page: 1,
    })
  }

  return (
    <CardContainerOrdersStyled>
      <Loading loading={isLoading} loadingColor="#2549CC" colorBackground="#97a3d1" />
      <ContainerFilter>
        <Search
          placeholder="N° do pedido ou da rota"
          onChange={handleSearch}
          defaultValue={params.search ?? ''}
        />
        <Calendar
          onSelectedDate={handleOnSelectDate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          baseFilter="separationDate"
        />
      </ContainerFilter>
      <ContainerScroll>
        <ContainerAccordions>
          <ContainerLabels>
            <span>Rota</span>
            <span>N° Pedido</span>
            <span>Liberado por</span>
          </ContainerLabels>
          {data?.map(order => (
            <Accordion key={order.order_id}>
              <ContainerLabelsAccordion>
                <span>{order.route || 'Sem rota'}</span>
                <span>{order.order_id}</span>
                <span>{order.released_without_invoice_by}</span>
              </ContainerLabelsAccordion>
            </Accordion>
          ))}
        </ContainerAccordions>
      </ContainerScroll>
      <Scrollbar>
        <Pagination
          page={params.page}
          count={count}
          onChange={(_, newPage: number) => onChange({ page: newPage })}
          rowsPerPage={params.pageSize}
          handleRowsPerPageChange={newPageSize => onChange({ pageSize: newPageSize, page: 1 })}
        />
      </Scrollbar>
    </CardContainerOrdersStyled>
  )
}

export default CardHistory
