import { Typography, TypographyProps } from '@material-ui/core'

import { useStyles } from './styles'

export const Title = ({ children, className, ...rest }: TypographyProps) => {
  const classes = useStyles()

  return (
    <Typography className={`${classes.root} ${className}`} {...rest}>
      {children}
    </Typography>
  )
}
