import { IOrder } from 'interfaces/IOrder'
import { IOrderSeparation, IOrderSeparationResponse } from 'interfaces/IOrderSeparation'
import { IPaginationParams, IPaginationResponse } from 'interfaces/IPagination'

import { ApiService, api as apiService } from './api'

class OrderService {
  constructor(private readonly api: ApiService) {}

  async getOrders(
    params: IPaginationParams,
    signal: AbortSignal,
  ): Promise<IPaginationResponse<IOrder>> {
    return await this.api.get(carambolaUrl + '/separation/order-separation/', {
      params,
      signal,
    })
  }

  async getOneOrder(id: string): Promise<IOrder> {
    return await this.api.get(carambolaUrl + `/separation/order-separation/${id}`)
  }

  async updateOrder(data: IOrderSeparation): Promise<IOrderSeparationResponse> {
    return await this.api.patch(carambolaUrl + `/separation/order-separation/`, data)
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const orderService = new OrderService(apiService)
