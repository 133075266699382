import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  btnFilter: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#757575',
    fontWeight: 500,
    fontSize: '0.875rem',
    display: 'flex',
    gap: '0.2rem',
    '& strong': {
      color: '#353535',
    },
  },
}))
