import {
  alpha,
  Box,
  Container,
  Dialog as DialogMui,
  IconButton as IconButtonMui,
  styled,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import orderConferredImg from 'assets/images/conferred-order.svg'
import orderCanceledImg from 'assets/images/sad-orange.svg'
import { Button as ButtonMui } from 'components'
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react'
import { RiArrowRightSLine } from 'react-icons/ri'

export interface IOrderCanceled {
  handleChangeOrderId: (data: HandleChangeOrderId) => void
}

type HandleChangeOrderId = {
  id: string
  type: 'recused' | 'conferred' | 'previous'
}

const Dialog = styled(DialogMui)({
  '& .MuiDialog-paper': {
    margin: 0,
    width: '96%',
    maxWidth: '520px',
  },
})

const Header = styled(Box)({
  position: 'sticky',
  top: '0',
  left: '0',
  width: '100%',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)',
  backgroundColor: alpha('#FFFFFF', 0.72),
  zIndex: 2,
  padding: '1.25rem 0',
})

const IconButton = styled(IconButtonMui)({
  position: 'absolute',
  top: '0rem',
  left: '0rem',
  background: '#FAFAFA',
  height: '2.5rem',
  width: '2.5rem',
})

const Root = styled(Container)({
  padding: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& > img': {
    margin: '1.25rem auto 1rem',
  },
  width: '100%',
})

const Title = styled(Typography)({
  fontSize: '1.375rem',
  color: '#353535',
  fontAlign: 'center',
  fontWeight: 600,
  textAlign: 'center',
})

const SubTitle = styled(Typography)({
  color: '#353535',
  fontAlign: 'center',
  fontWeight: 500,
})

const Button = styled(ButtonMui)({
  borderRadius: '4rem',
  padding: '0.75rem 0',
  width: '100%',
  marginTop: '1.5rem',
})

export const Component: ForwardRefRenderFunction<IOrderCanceled> = (_, ref) => {
  const [orderId, setOrderId] = useState({
    id: '',
    type: '',
  })
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setOrderId({
      id: '',
      type: '',
    })
    setIsOpen(false)
  }

  const handleChangeOrderId = ({ id, type }: HandleChangeOrderId) => {
    setIsOpen(true)
    setOrderId({
      id,
      type,
    })
  }

  useImperativeHandle(ref, () => {
    return { handleChangeOrderId }
  })

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Root maxWidth="md">
        <Header component="header">
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ fontSize: '1.4rem' }} />
          </IconButton>
        </Header>
        {orderId.type === 'previous' && (
          <Title>O pedido #{orderId.id} foi conferido anteriormente!</Title>
        )}
        {orderId.type === 'recused' && <Title>O pedido #{orderId.id} foi cancelado!</Title>}
        {orderId.type === 'conferred' && <Title>Pedido #{orderId.id} conferido com sucesso!</Title>}
        <img src={orderId.type === 'recused' ? orderCanceledImg : orderConferredImg} />
        {orderId.type === 'recused' && (
          <SubTitle>Remova as caixas desse pedido da esteira</SubTitle>
        )}

        <Button variant="contained" endIcon={<RiArrowRightSLine />} onClick={handleClose}>
          Ler outro pedido
        </Button>
      </Root>
    </Dialog>
  )
}

export const ModalOrder = forwardRef(Component)
