import { useState, useMemo, useCallback } from 'react'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md'

import { IOrdersInfo } from '..'

import Card from '../Card'
import { CarouselContainer, ButtonCarousel } from './styles'

interface IProps {
  currentOrder: IOrdersInfo
  showOrders: IOrdersInfo[]
}

const Carousel = ({ currentOrder, showOrders }: IProps) => {
  const [activeIndex, setActiveIndex] = useState(0)

  useMemo(() => {
    const index = showOrders.findIndex(order => order.order_id === currentOrder.order_id)
    setActiveIndex(index < 0 ? 0 : index)
  }, [showOrders, currentOrder])

  const handlePrevClick = useCallback(() => {
    setActiveIndex(prevIndex => (prevIndex === 0 ? showOrders.length - 1 : prevIndex - 1))
  }, [showOrders])

  const handleNextClick = useCallback(() => {
    setActiveIndex(prevIndex => (prevIndex === showOrders.length - 1 ? 0 : prevIndex + 1))
  }, [showOrders])

  const disabledButtons = useMemo(
    () => ({
      next: activeIndex === showOrders.length - 1,
      prev: activeIndex === 0,
    }),
    [activeIndex, showOrders],
  )

  const renderItem = useCallback(
    () => <Card currentOrder={showOrders[activeIndex]} showOrders={showOrders} />,
    [activeIndex, showOrders],
  )

  return (
    <CarouselContainer>
      <ButtonCarousel onClick={handlePrevClick} disabled={disabledButtons.prev}>
        <MdOutlineKeyboardArrowLeft size={50} color="#757575" />
      </ButtonCarousel>
      {renderItem()}
      <ButtonCarousel onClick={handleNextClick} disabled={disabledButtons.next}>
        <MdOutlineKeyboardArrowRight size={50} color="#757575" />
      </ButtonCarousel>
    </CarouselContainer>
  )
}

export default Carousel
