import { Avatar, Card as MuiCard, CardActionArea } from '@material-ui/core'
import { ISeparators } from 'interfaces/ISeparator'
import { memo } from 'react'
import { useNavigate } from 'react-router'
import { stringAvatar, stringToColor } from 'utils'

import { useStyles } from './styles'

export const Card = ({ username, id }: ISeparators) => {
  const classes = useStyles()
  const navegate = useNavigate()

  const handleNavegate = () => {
    navegate(`../usuario/${id}`)
  }

  return (
    <>
      <MuiCard className={classes.root} onClick={handleNavegate}>
        <CardActionArea className={classes.content}>
          <Avatar className={classes.avatar} style={{ background: stringToColor(username) }}>
            {stringAvatar(username)}
          </Avatar>
          <strong className={classes.name}>{username}</strong>
        </CardActionArea>
      </MuiCard>
    </>
  )
}

const CardOperator = memo(Card, (prevProps, nextProps) => {
  return prevProps.id === nextProps.id
})

export { CardOperator }
