import { Box, List, ListItem } from '@material-ui/core'

import { IScanneds } from '../IUseScanner'
import { useStyles } from './styles'

interface IContainerWeighings {
  scanneds: IScanneds
  isTypeUnity: boolean
}

const ContainerWeighings = ({ isTypeUnity, scanneds }: IContainerWeighings) => {
  const classes = useStyles()

  return (
    <Box style={{ height: '100%' }}>
      <List className={classes.list}>
        {scanneds.products.length === 0 && (
          <ListItem>
            <strong style={{ color: '#757575', fontWeight: 500, fontSize: '1rem' }}>
              Bipe o código do item
            </strong>
          </ListItem>
        )}
        {scanneds.products.map((prePick, index) => (
          <ListItem className={classes.listItem} key={prePick.id}>
            <p style={{ color: '#757575' }}>{index + 1}.Pesagem:&nbsp;</p>
            <strong>
              {!isTypeUnity && `${prePick.valueBeeped.toFixed(3)} Kg`}
              {isTypeUnity && `${prePick.valueBeeped} Un`}
            </strong>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default ContainerWeighings
