import { Box, CircularProgress, CircularProgressProps, Typography } from '@material-ui/core'

import { useStyles } from './styles'

interface IProps extends CircularProgressProps {
  value: number
  maxValue: number
}

const CircularStatic = ({ value, maxValue, ...rest }: IProps) => {
  const percentage = (value / maxValue) * 100
  const classes = useStyles()

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="static"
        value={percentage > 100 ? 100 : percentage}
        className={classes.root}
        color="primary"
        {...rest}
      />
      <Box className={classes.box}>
        <Typography className={classes.title}>
          {value}
          <strong>/{maxValue}</strong>
        </Typography>
      </Box>
    </Box>
  )
}

export default CircularStatic
