import { usePopup } from 'hooks/usePopup'
import { ISkuBroken } from 'interfaces/IDashboard'
import { useEffect, useRef, useState } from 'react'
import { dashboardService } from 'services/dashboardService'

let controller: AbortController

export const useBreak = () => {
  const { addPopup } = usePopup()
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const [breaks, setBreaks] = useState<ISkuBroken[]>([])

  const pollingBreak = async (isError = false) => {
    try {
      controller = new AbortController()
      const data = await dashboardService.getBreaks(controller)
      setBreaks(Object.values(data))
    } catch {
      if (isError === false && controller.signal.aborted === false) {
        addPopup({
          type: 'error',
          title: 'Erro ao buscar dados de quebra',
        })
      }
    } finally {
      if (controller.signal.aborted === false) {
        timeout.current = setTimeout(() => pollingBreak(true), 7000)
      }
    }
  }

  useEffect(() => {
    pollingBreak()

    return () => {
      controller?.abort()
      // eslint-disable-next-line no-undef
      clearTimeout(timeout.current as NodeJS.Timeout)
    }
  }, [])

  return { breaks }
}
