import { IModalError } from 'components/CardProductScanner/ModalError'
import { TValueBip } from 'components/CardProductScanner/useScanner'
import { addPopup } from 'hooks/usePopup'
import { IProduct } from 'interfaces/IProduct'
import { IProductApi } from 'interfaces/IRequestProductApi'
import { RefObject } from 'react'
import { naboServices } from 'services/naboServices'
import { skuConfigService } from 'services/skuConfigService'
import { formatValueScanner } from 'utils/formatValueScanner'

import {
  FCheckPortionTypePrePicking,
  FCheckProductBaseStoragePrePicking,
  FCheckSkuPrePicking,
} from './types'

export const usePrePicking = () => {
  const getQuantityToSeparate = (item: IProduct, isAuthorizedBeepOtherSku: boolean) => {
    return isAuthorizedBeepOtherSku ? item.converted_quantity || item.quantity : item.quantity
  }

  const getLimitQuantity = (item: IProduct, isAuthorizedBeepOtherSku: boolean) => {
    const correctQuantity: number = getQuantityToSeparate(item, isAuthorizedBeepOtherSku)

    const limit: number = isAuthorizedBeepOtherSku
      ? item.unity_product_base === 'unidade'
        ? correctQuantity
        : correctQuantity + 0.999
      : correctQuantity

    return limit
  }

  const getIsTypeUnity = (item: IProduct) => {
    return item.unity_product_base === 'unidade'
  }

  const getIsUniversalTag = (qrcode: string, isInfrared: boolean): boolean => {
    const { id } = formatValueScanner(qrcode, isInfrared)
    return id === 'admin'
  }

  const checkProductBasePrePicking: FCheckProductBaseStoragePrePicking = async (
    qrcode,
    item,
    isInfrared,
  ) => {
    const { productBaseId, date, id, isBalance, scaleId } = formatValueScanner(qrcode, isInfrared)

    if (isBalance) {
      const { data } = await naboServices.getPrePicking({
        scaleLabelId: id,
        scaleId: Number(scaleId),
        date,
      })

      if (data.length === 0) {
        throw Error(`Falha ao encontrar pre picking. SLID${id} - SID${Number(scaleId)} - DT${date}`)
      }

      if (Number(data[0]?.product_base_id) !== item.product_base_id) {
        return {
          productBaseIdScanned: Number(data[0]?.product_base_id),
        }
      }
    }

    if (productBaseId && productBaseId !== item?.product_base_id) {
      return {
        productBaseIdScanned: productBaseId,
      }
    }

    return null
  }

  const checkSkuPrePicking: FCheckSkuPrePicking = async (qrcode, item, isInfrared) => {
    const { sku, typeProduct, valueBeeped, isBalance, productBaseId } = formatValueScanner(
      qrcode,
      isInfrared,
    )

    if (isBalance) {
      if (sku !== Number(item.sku)) {
        return {
          beeped: String(sku),
          correct: String(item.sku),
        }
      }
      return null
    }

    const portionType = typeProduct === 'un' ? 'unidade' : 'peso'
    const { results } = await skuConfigService.getProducts({
      productBaseId: productBaseId,
      purchaseValue: valueBeeped,
      portionType,
    })

    if (results.length === 0) {
      throw Error(
        `0 SKUs encontrados | PB:${productBaseId} - PV:${valueBeeped} - PT:${portionType}`,
      )
    }

    if (!results.some(productApi => Number(productApi.sku) === Number(item.sku))) {
      if (results.length === 1) {
        return {
          beeped: String(sku),
          correct: String(results[0]?.sku),
        }
      }
      throw Error(
        `${results.length} SKUs encontrados | PB:${productBaseId} - PV:${valueBeeped} - PT:${portionType}`,
      )
    }
    return null
  }

  const checkPortionTypePrePicking: FCheckPortionTypePrePicking = (qrcode, item, isInfrared) => {
    const { typeProduct } = formatValueScanner(qrcode, isInfrared)
    const isTypeUnity = getIsTypeUnity(item)

    return (isTypeUnity && typeProduct !== 'un') || (!isTypeUnity && typeProduct !== 'kg')
  }

  const checkCanUseUniversalTag = async (qrcode: string, isInfrared: boolean) => {
    const { productBaseId, typeProduct, valueBeeped } = formatValueScanner(qrcode, isInfrared)

    const isUniversalTag = getIsUniversalTag(qrcode, isInfrared)

    const res = await skuConfigService.getProducts({
      portionType: typeProduct === 'un' ? 'unidade' : 'peso',
      productBaseId: productBaseId,
      purchaseValue: valueBeeped,
    })

    if (!res.results[0]) {
      throw Error(
        `Nenhum SKU encontrado | PB:${productBaseId} - PV:${valueBeeped} - PT:${typeProduct}`,
      )
    }

    const { unique_tag_only } = await naboServices.getProductConfig(res.results[0].sku.toString())

    if (isUniversalTag && unique_tag_only) {
      throw Error(
        'Este SKU requer apenas etiquetas únicas. Por favor tente novamente com uma etiqueta apropriada.',
      )
    }
  }

  const getPrePickingData = async (qrcode: string, isInfrared: boolean) => {
    const { valueBeeped, date, id, isBalance, scaleId, sku } = formatValueScanner(
      qrcode,
      isInfrared,
    )

    const isUniversalTag = getIsUniversalTag(qrcode, isInfrared)
    if (isUniversalTag) throw new Error('Pre picking não é etiqueta única')

    if (isBalance) {
      return naboServices.getPrePicking({
        skuId: String(sku),
        scaleId: Number(scaleId),
        scaleLabelId: id,
        date,
        itemWeight: valueBeeped,
      })
    }
    return naboServices.getPrePicking({
      prePickingId: id,
    })
  }

  const getSkuData = async (qrcode: string, isInfrared: boolean): Promise<IProductApi> => {
    const { valueBeeped, productBaseId, typeProduct, isBalance, sku, id } = formatValueScanner(
      qrcode,
      isInfrared,
    )

    const isUniversalTag = getIsUniversalTag(qrcode, isInfrared)

    if (isUniversalTag) {
      const response = await skuConfigService.getProducts({
        portionType: typeProduct === 'un' ? 'unidade' : 'peso',
        productBaseId: productBaseId,
        purchaseValue: valueBeeped,
      })

      if (response.results.length === 0) {
        throw Error(
          `Falha ao encontrar SKU. PB${productBaseId} - PV${valueBeeped} - PT${typeProduct}`,
        )
      }
      if (response.results.length > 1) {
        throw Error(
          `Mais de um SKU encontrado. PB${productBaseId} - PV${valueBeeped} - PT${typeProduct}`,
        )
      }

      return response.results[0]
    }

    if (isBalance) {
      const response = await skuConfigService.getProducts({
        sku: String(sku),
      })

      return response.results[0]
    }

    const { data } = await naboServices.getPrePicking({
      prePickingId: id,
    })

    if (data.length === 0) {
      throw Error(`Falha ao encontrar pre picking. ID${id}`)
    }

    return data[0].sku
  }

  const checkIsWrongBarCode = async (
    productBaseId: number,
    barCode: string,
    addPopup: addPopup,
    modalErrorRef: RefObject<IModalError>,
  ) => {
    try {
      await naboServices.getPrePickingV2({
        codeType: 'bar_code',
        productCode: barCode,
        productBaseId,
      })
      return false
    } catch (err: any) {
      if (
        err?.message === 'Código de barras lido não corresponde com o código de barras do produto.'
      ) {
        modalErrorRef.current?.dispatchError({
          typeError: 'wrongBarcode',
          codeBarBiped: err?.code_received,
          CodeBarCorrect: err?.correct_code,
        })
        return true
      }

      addPopup({
        type: 'error',
        title: 'Erro ao verificar código de barras',
        description: err?.message || err?.msg || 'Contate um administrador',
        autoClose: false,
      })
      return true
    }
  }

  const checkIsBarcodeInfrared = (code: string) => {
    return code.length === 13
  }

  const checkIsBarcode = (code: TValueBip) => {
    return code.type === 'bar_code'
  }

  return {
    checkProductBasePrePicking,
    checkSkuPrePicking,
    checkPortionTypePrePicking,
    getIsUniversalTag,
    getLimitQuantity,
    getQuantityToSeparate,
    checkCanUseUniversalTag,
    getIsTypeUnity,
    getPrePickingData,
    getSkuData,
    checkIsBarcode,
    checkIsWrongBarCode,
    checkIsBarcodeInfrared,
  }
}
