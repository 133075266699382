import { Box, Typography } from '@material-ui/core'
import { ClientCritical } from 'components'
import { IOrder } from 'interfaces/IOrder'
import { ViewProps } from 'interfaces/IView'
import { BsCircleFill } from 'react-icons/bs'
import { Timer } from 'shared'
import { formatNameClient, storeMap } from 'utils'

import { useStyles, buttonStyle } from './styles'

interface Props {
  view: ViewProps
  order: IOrder
  begin: Date
}

export const Header = (props: Props) => {
  const { view, order, begin } = props
  const classes = useStyles({ store: storeMap(order?.store_id) })

  const buttonClasses = buttonStyle({ color: order?.color_route })

  return view === 'divergent' || view === 'form' || view === 'separation' ? (
    <Box>
      {order?.is_critical && <ClientCritical className={classes.clientCritical} />}
      <Box component="header" className={classes.root}>
        <Box>
          <Typography className={classes.info}>
            Cliente:{' '}
            <strong className={classes.strong}>
              {order?.client_data?.client_type === 'PF'
                ? formatNameClient(order?.client_data?.client_name)
                : formatNameClient(order?.client_data?.client_trade)}
            </strong>
          </Typography>
          <Typography className={classes.info}>
            Pedido: <strong className={classes.strong}>#{order?.order_id}</strong>
          </Typography>
          <Typography className={classes.info}>
            Rota: <strong className={classes.strong}>{order?.route}</strong>
            {order?.color_route ? (
              <>
                / <BsCircleFill className={buttonClasses.buttonColor} />
                <strong className={classes.strong}>{order?.color_route}</strong>
              </>
            ) : null}
          </Typography>
          <Typography className={classes.info}>
            Loja: <strong className={classes.store}>{storeMap(order?.store_id)}</strong>
          </Typography>
          {order?.client_data?.ifood_bag && (
            <Typography className={classes.info}>
              Embalagem: <strong className={classes.strong}>Sacola</strong>
            </Typography>
          )}

          {order?.instructions && (
            <Typography className={classes.obs}>
              Observação: <span className={classes.store}>{order?.instructions}</span>
            </Typography>
          )}
        </Box>
        <Box>
          <Typography className={classes.timeConference}>
            Tempo de separação:
            <br />
            <Timer className={classes.strong} time={begin} />
          </Typography>
        </Box>
      </Box>
    </Box>
  ) : null
}
