import { Box, Container, Typography } from '@material-ui/core'
import { Title } from 'components'
import { ITrack } from 'interfaces/IDashboard'

import Accordion from './Accordion'
import { useStyles } from './styles'

interface Props {
  averageDuration: string
  separators: ITrack[]
  pendentOrders: number
  refreshDashboard: () => Promise<void>
  averageIdleTime: string
}

export const SeparatorList = (props: Props) => {
  const { separators, averageDuration, pendentOrders, averageIdleTime } = props
  const ordersInSeparation = separators?.length
  const classes = useStyles()

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Box className={classes.headerAlign}>
        <Title>Separador</Title>
      </Box>

      <Box className={classes.containerTracks}>
        {ordersInSeparation ? (
          separators.map(item => (
            <Accordion
              separator={item}
              averageDuration={averageDuration}
              pendentOrders={pendentOrders}
              key={item.username}
              refreshDashboard={props.refreshDashboard}
              averageIdleTime={averageIdleTime}
            />
          ))
        ) : (
          <Typography className={classes.warning}>
            Não há pedidos sendo separados nesse momento
          </Typography>
        )}
      </Box>
    </Container>
  )
}
