import { Dialog, Typography } from '@material-ui/core'
import { ForwardRefRenderFunction, forwardRef, useImperativeHandle, useState } from 'react'
import { FaTruckLoading } from 'react-icons/fa'

import { useStyles } from './styles'

export interface IDialogLoading {
  onOpen: () => void
  onClose: () => void
}

const Component: ForwardRefRenderFunction<IDialogLoading> = (_, ref) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  useImperativeHandle(ref, () => ({ onOpen, onClose }))

  return (
    <Dialog className={classes.dialog} open={open}>
      <Typography className={classes.title}>Carregando...</Typography>
      <Typography
        style={{
          margin: '1.5rem 0 1rem 0',
          textAlign: 'center',
        }}
      >
        Espera um cadin que estamos finalizando esse trem. 🤠
      </Typography>
      <Typography
        style={{
          margin: '0.5rem 0 0.5rem 0',
          textAlign: 'center',
        }}
      >
        <strong>
          Num sai dessa tela não se não pode azedar o caldo. Se demorar demais dá um aló pra gente!{' '}
        </strong>
      </Typography>
      <FaTruckLoading
        size={150}
        color="#757575"
        style={{ margin: '2rem auto 0 auto', display: 'block' }}
      />
    </Dialog>
  )
}

export const DialogLoading = forwardRef(Component)
