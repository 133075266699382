import { usePopup } from 'hooks/usePopup'
import { IBoxImage } from 'interfaces/IDashboard'
import { IOrder } from 'interfaces/IOrder'
import { ISeparation, IItem } from 'interfaces/ISeparation'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ICheckBoxes, checkBoxesService } from 'services/checkBoxesService'
import { dashboardService } from 'services/dashboardService'

export const useOrderDetails = () => {
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const controller = new AbortController()
  const navigate = useNavigate()
  const { orderId } = useParams()
  const { addPopup } = usePopup()
  const [separations, setSeparations] = useState<ISeparation[]>([] as ISeparation[])
  const [boxes, setBoxes] = useState<IBoxImage[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [totalBoxesOrder, setTotalBoxesOrder] = useState(0)
  const [endtimeOrder, setEndtimeOrder] = useState(new Date())
  const [orderData, setOrderData] = useState<IOrder>({} as IOrder)
  const [orderConferenceData, setOrderConferenceData] = useState<ICheckBoxes>({} as ICheckBoxes)

  const summaryData = useMemo(() => {
    return separations.reduce(
      (acc, separation) => {
        const totalItens = separation.items.length
        const completed = separation.items.filter(item => {
          if (!item?.status) {
            return false
          }

          return item.status > 1
        }).length

        const itensPending = separation.items.filter(item => {
          if (!item?.status) {
            return false
          }

          return item.status === 1
        })

        return {
          totalItens: acc.totalItens + totalItens,
          completed: acc.completed + completed,
          itensPending: [...acc.itensPending, ...itensPending],
        }
      },
      {
        totalItens: 0,
        completed: 0,
        itensPending: [] as IItem[],
      },
    )
  }, [separations])

  const getDataOrder = async (isError = false, pulling = true) => {
    try {
      const response = await dashboardService.getOrderDetails(
        orderId as string | number,
        controller,
      )
      if (response.order.checking_status) {
        await getDataConferenceOrder()
      }
      setOrderData(response.order)
      setSeparations(response.data)
      setBoxes(response.boxes)
      setTotalBoxesOrder(response.total_boxes)
      setEndtimeOrder(response.end_time)
    } catch {
      if (isError === false && controller.signal.aborted === false) {
        navigate(-1)
        addPopup({
          type: 'error',
          title: 'Erro ao procurar pedido',
        })
      }
    } finally {
      if (controller.signal.aborted === false && pulling) {
        timeout.current = setTimeout(() => getDataOrder(true), 60000)
      }

      setIsLoading(false)
    }
  }

  const run = async () => {
    getDataOrder(false, false)
  }

  const getDataConferenceOrder = async () => {
    try {
      const response = await checkBoxesService.getAll({ orderId: orderId })
      setOrderConferenceData(response)
    } catch (err: any) {
      if (err?.message === `Pedido ${orderId} não encontrado na order da conferência`) {
        addPopup({
          type: 'info',
          title: 'Pedido não encontrado na conferência',
          description: 'Preocupa não, isso é porque ele não passou por conferência e revisão',
        })
        return
      }
      addPopup({
        type: 'error',
        title: 'Erro ao buscar pedido na conferência',
        description: err?.message || err?.msg || err,
      })
    }
  }

  useEffect(() => {
    if (orderId) {
      getDataOrder()
    } else {
      navigate('../dashboard')
    }

    return () => {
      controller.abort()
      // eslint-disable-next-line no-undef
      clearTimeout(timeout.current as NodeJS.Timeout)
    }
  }, [orderId])

  return {
    separations,
    boxes,
    orderData,
    summaryData,
    isLoading,
    refresh: run,
    orderConferenceData,
    totalBoxesOrder,
    endtimeOrder,
  }
}
