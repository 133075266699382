import { BreakOrder, ReportBreakBody } from 'interfaces/IBreakOrder'
import { breakUrl } from 'settings'

import { IPaginationParams, IPaginationResponse } from '../interfaces/IPagination'
import { api as apiService, ApiService } from './api'

export interface IGetBreakReports {
  break_quantity: number
  user_id: number
  username: string
  products: {
    order_id: string
    product: {
      id: number
      name: string
      product_base: number
      sku: string
    }
    reason: {
      id: number
      name: string
    }
  }[]
}

class BreakService {
  constructor(private readonly api: ApiService) {}

  async getBreakOrders(
    params: IPaginationParams,
    signal: AbortSignal,
  ): Promise<IPaginationResponse<BreakOrder>> {
    return this.api
      .get(carambolaUrl + '/reposition/', { params, signal })
      .then(response => ({ ...response, totalCount: response.total_count }))
  }

  async reportBreak(breakOrder: ReportBreakBody): Promise<void> {
    return this.api.post(`${breakUrl}/v1/complaint`, breakOrder)
  }

  async getBreakReports(
    initialSeparationDate: string,
    finalSeparationDate: string,
    controller: AbortController,
  ): Promise<IGetBreakReports[] | { message: string }> {
    return this.api.get(carambolaUrl + '/data-analysis/order-break-by-separator', {
      params: {
        initialSeparationDate,
        finalSeparationDate,
      },
      signal: controller.signal,
    })
  }
}
const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const breakService = new BreakService(apiService)
