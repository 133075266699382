import { useRef } from 'react'

interface IUseDebaunceProps {
  fn(params?: any): void
  delay: number
}

export const debaunce = ({ fn, delay }: IUseDebaunceProps) => {
  const timeoutRef = useRef<number | null>(null)

  function debaunceFn(params?: any) {
    window.clearTimeout(timeoutRef.current as number)
    timeoutRef.current = window.setTimeout(() => {
      fn(params)
    }, delay)
  }

  return debaunceFn
}
