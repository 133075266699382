import MomentUtils from '@date-io/moment'
import { IconButton, styled } from '@material-ui/core'
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import * as moment from 'moment'
import 'moment/locale/pt-br'
import { AiOutlineClose } from 'react-icons/ai'

interface Props extends KeyboardDatePickerProps {
  onClear: () => void
}

const KeyboardDatePickerStyle = styled(KeyboardDatePicker)({
  '& .MuiInputBase-root': {
    paddingLeft: 0,
  },
  '& .MuiToolbar-root': {
    background: 'black',
  },
})

const DatePicker = (props: Props) => {
  moment.locale('pt-br')
  const { value, onClear } = props

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="pt-br">
      <KeyboardDatePickerStyle
        inputVariant="outlined"
        InputAdornmentProps={{ position: 'start' }}
        {...props}
        InputProps={{
          endAdornment: value && (
            <IconButton onClick={onClear} size="small">
              <AiOutlineClose />
            </IconButton>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
