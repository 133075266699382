import { Box, Typography, makeStyles } from '@material-ui/core'
import BellIcon from 'assets/images/bell-icon.svg'
import { IProduct } from 'interfaces/IProduct'

import { IScanneds } from '../IUseScanner'

interface IAlertWeighinsProps {
  product: IProduct
  scanneds: IScanneds
  isAuthorized: boolean
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#DFEAFF',
    padding: '0.6rem 0 0.6rem 1rem',
    borderRadius: '16px',
    alignItems: 'center',
    color: '#2E75FF',
    gap: '1rem',
    fontWeight: 500,
    marginTop: '1rem',
  },
})

const AlertWeighins = (props: IAlertWeighinsProps) => {
  const { product, scanneds, isAuthorized } = props
  const classes = useStyles()

  const isUnity = product.unity_product_base === 'unidade'

  const quantityToSeparate = isAuthorized
    ? (product.converted_quantity || product.quantity) - scanneds.totalValue
    : product.quantity - scanneds.products.length

  return (
    <Box className={classes.root}>
      <img src={BellIcon} />{' '}
      {isAuthorized ? (
        <Typography>
          Bipe {isUnity ? quantityToSeparate : quantityToSeparate.toFixed(3).replace('.', ',')}{' '}
          {isUnity ? 'un' : 'kg'} do produto
        </Typography>
      ) : (
        <Typography>
          Bipe {quantityToSeparate} pacotes de{' '}
          {product.converted_quantity
            ? isUnity
              ? Number(product.converted_quantity) / product.quantity
              : (Number(product.converted_quantity) / product.quantity).toFixed(3).replace('.', ',')
            : 1}
          {product.type === 'peso' ? 'kg' : 'un'} do produto
        </Typography>
      )}
    </Box>
  )
}

export default AlertWeighins
