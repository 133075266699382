import { LinearProgress } from '@material-ui/core'
import { DataGridProps, DataGrid as MuiDataGrid } from '@material-ui/data-grid'
import { styled } from '@material-ui/styles'
import { FaOptinMonster } from 'react-icons/fa'

import { IStatusTable } from '../../hooks/useTableParams'
import NoData from '../TableCustom/NoData'
import tableConfig from '../TableCustom/TableConfig'
import Pagination from '../TableCustom/TablePagination'

const Root = styled(MuiDataGrid)({
  background: '#ffffff',
  minHeight: '450px',
})

const NoRowsOverlay = styled(NoData)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 4,
})

interface Props extends DataGridProps {
  refresh: () => void
  status: IStatusTable
  footer?: boolean
}

const DataGrid = (props: Props) => {
  const {
    refresh,
    status,
    rowCount,
    onPageChange,
    onPageSizeChange,
    pageSize,
    rows,
    columns,
    page,
    footer = true,
    ...rest
  } = props

  return (
    <Root
      rows={rows}
      columns={columns}
      autoHeight
      disableSelectionOnClick
      components={{
        NoRowsOverlay: () => (
          <NoRowsOverlay refresh={refresh} status={status} />
        ),
        LoadingOverlay: () => (
          <LinearProgress color="secondary" style={{ top: '3.2rem' }} />
        ),
        Pagination: () =>
          footer ? (
            <Pagination
              count={rowCount as number}
              handleRowsPerPageChange={(page: number) =>
                onPageSizeChange && onPageSizeChange(page)
              }
              page={page || 1}
              onChange={(_, page: number) => onPageChange && onPageChange(page)}
              rowsPerPage={pageSize as number}
            />
          ) : null,
      }}
      paginationMode="server"
      rowsPerPageOptions={tableConfig.rowsPerPageOptions}
      {...rest}
    />
  )
}

export default DataGrid
