import { Dialog, Typography } from '@material-ui/core'
import { Button } from 'components'
import { ForwardRefRenderFunction, forwardRef, useImperativeHandle, useState } from 'react'
import { FaPallet } from 'react-icons/fa'

import { useStyles } from './styles'

export interface IDialogPalletAlert {
  onOpen: () => void
  onClose: () => void
}

const Component: ForwardRefRenderFunction<IDialogPalletAlert> = (_, ref) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  useImperativeHandle(ref, () => ({ onOpen, onClose }))

  return (
    <Dialog className={classes.dialog} open={open}>
      <Typography
        style={{
          margin: '1.5rem 0 1rem 0',
          textAlign: 'center',
        }}
      >
        Meu chegado(a), esse pedido pode precisar de palete!
      </Typography>
      <FaPallet
        style={{ margin: '0 auto 16px auto', display: 'block' }}
        size={100}
        color="#757575"
      />
      <Button type="submit" className={classes.button} onClick={onClose}>
        Valeu!
      </Button>
    </Dialog>
  )
}

export const DialogPalletAlert = forwardRef(Component)
