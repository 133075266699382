export const formatNameClient = (name: string | null) => {
  if (!name) return null
  const clientName = name.replace('Ifood.com ', '').split(' ')

  if (clientName.length === 1) {
    return clientName[0]
  }

  if (clientName.length === 2) {
    return `${clientName[0]} ${clientName[1]}`
  }

  return `${clientName[0]} ${clientName[1]} ${clientName[2]}`
}
