import { Box, Button, Dialog, LinearProgress, TextField, Typography } from '@material-ui/core'
import { usePopup } from 'hooks/usePopup'
import { IStepStep } from 'interfaces/IFastTrack'
import { IProduct } from 'interfaces/IProduct'
import { IProductApi } from 'interfaces/IRequestProductApi'
import {
  useState,
  FormEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { skuConfigService } from 'services/skuConfigService'

import useStyles from './styles'

interface IStep {
  step: IStepStep
  items: IProduct[]
}

interface IContentProps {
  confirm: (boxes: number) => Promise<void>
  step: IStep
}

const Content = (props: IContentProps) => {
  const { addPopup } = usePopup()
  const classes = useStyles()
  const [boxesNumber, setBoxesNumber] = useState(0)
  const [section, setSection] = useState<'initial' | 'divergence'>('initial')
  const [loading, setLoading] = useState(false)

  const handleSubmitForm = async (e: FormEvent<HTMLFormElement>, boxesNumberParam: number) => {
    e?.preventDefault()
    const wholesales = await getWholesales()
    if (boxesNumberParam < wholesales.length) {
      setSection('divergence')
      return
    }
    handleConfirm(boxesNumberParam)
  }

  const handleConfirm = async (boxesNumberParam: number) => {
    if (boxesNumberParam < 0) {
      addPopup({
        type: 'error',
        title: 'Quantidade de caixas não pode ser negativa',
      })
      return
    }

    setLoading(true)
    try {
      await props.confirm(boxesNumberParam)
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao confirmar a quantidade de caixas da etapa',
        description: err?.message || err?.msg,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleClickBoxes = (value: number) => {
    setBoxesNumber(state => {
      if (state + value < 0) return state
      return state + value
    })
  }

  const getWholesales = async (): Promise<IProductApi[]> => {
    setLoading(true)
    try {
      const response = await skuConfigService.getProducts({
        sku: props?.step.items.map(item => String(item.sku)).join(','),
      })
      return response.results.filter(sku => sku.isWholesale)
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: "Erro ao buscar SKU's para pegar os atacados",
        description: err?.message || err?.msg,
      })
      return []
    } finally {
      setLoading(false)
    }
  }

  const confirmDivergence = () => {
    handleConfirm(boxesNumber)
  }

  const confirmChange = () => {
    setSection('initial')
  }

  return (
    <>
      <LinearProgress className={classes.linearProgress} style={{ opacity: loading ? 1 : 0 }} />
      {section === 'initial' && (
        <div>
          <Typography className={classes.title}>Etapa {props?.step?.step} concluída!</Typography>
          <Typography className={classes.textDescription}>
            Você <strong>adicionou</strong> caixas nessa etapa? Se sim, quantas?
          </Typography>
          <form onSubmit={e => handleSubmitForm(e, boxesNumber)}>
            <Box className={classes.containerInputButtons}>
              <Button onClick={() => handleClickBoxes(-1)}>
                <BiMinus size={20} color={'#757575'} />
              </Button>
              <TextField
                type="number"
                variant="outlined"
                className={classes.input}
                defaultValue={0}
                onChange={e => setBoxesNumber(Number(e.target.value))}
                value={boxesNumber}
              />
              <Button onClick={() => handleClickBoxes(1)}>
                <BiPlus size={20} color={'#757575'} />
              </Button>
            </Box>
            <Button type="submit" className={classes.button} disabled={loading}>
              Confirmar
            </Button>
          </form>
        </div>
      )}
      {section === 'divergence' && (
        <div>
          <Typography className={classes.title}>Número de caixas fora do esperado</Typography>
          <Typography className={classes.textDescription}>
            A quantidade de caixas informada está diferente da prevista.
          </Typography>
          <Typography className={classes.textDescription}>Deseja confirmar mesmo assim?</Typography>

          <Box display="flex" style={{ gap: '10px' }}>
            <Button
              className={`${classes.button} ${classes.buttonChange}`}
              onClick={confirmChange}
              disabled={loading}
            >
              Alterar
            </Button>
            <Button className={classes.button} onClick={confirmDivergence} disabled={loading}>
              Confirmar
            </Button>
          </Box>
        </div>
      )}
    </>
  )
}

interface IData {
  callback: (boxes: number) => Promise<void>
  step: IStep
}

export interface IDialogBoxCountByStep {
  onOpen: (data: IData) => void
  onClose: () => void
}

export const Component: ForwardRefRenderFunction<IDialogBoxCountByStep> = (_, ref) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [data, setData] = useState<IData>({} as IData)

  const onOpen = (newData: IData) => {
    setData(newData)
    setOpen(true)
  }

  const onClose = () => {
    setData({} as IData)
    setOpen(false)
  }

  useImperativeHandle(ref, () => ({ onOpen, onClose }))

  return (
    <Dialog open={open} className={classes.dialog}>
      <Content step={data.step} confirm={data?.callback} />
    </Dialog>
  )
}

export const DialogBoxCountByStep = forwardRef(Component)
