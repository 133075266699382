import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.between(0, 460)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  headerComponents: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.between(0, 460)]: {
      flexDirection: 'column',
    },
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
  },
  filter: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '1rem',
    color: '#757575',
    fontSize: '1rem',
    cursor: 'pointer',
  },
  boxPermissions: {
    padding: '1rem',
  },
  search: {
    width: '21rem',
    [theme.breakpoints.between(0, 460)]: {
      marginLeft: 'auto',
      marginBottom: '10px',
      width: '100%',
    },
  },
  addButton: {
    background: '#89BD23',
    '&:hover': {
      backgroundColor: '#90a955',
    },
  },
  iconButton: {
    color: '#ffffff',
  },
  noOperator: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translateX(-50%)',
    color: '#757575',
    fontSize: '1.2rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
  },
  addFloatButton: {
    bottom: '2.5rem',
    right: '2.5rem',
    position: 'fixed',
  },
}))
