import { Box, Typography } from '@material-ui/core'
import { ContainerCardProduct, Title } from 'components'
import CardProduct from 'components/CardProduct'
import { IHandleConfirmItem } from 'hooks/useOrderSeparation/useItem'
import { IStep } from 'interfaces/IFastTrack'
import { IProduct } from 'interfaces/IProduct'
import { useTransition } from 'react-spring'
import { IDataConfirmItem } from 'services/separationItemService'

import { IDataBreach } from '../DialogBreak'
import { useStylesContainer } from './styles'

interface Props extends IStep {
  nextStep?: IStep
  handleDialogBreach?: (x: IDataBreach) => void
  handleConfirmItem: (x: IHandleConfirmItem) => Promise<IProduct | void>
  orderId: string
  resetOrder: () => Promise<void>
}

export const Section = (props: Props) => {
  const { nextStep, step, handleDialogBreach, handleConfirmItem, orderId, resetOrder } = props
  const classes = useStylesContainer()
  const item = props.items[0]
  const nextItem = props.items[1] || nextStep?.items[0]
  const transition = useTransition(item, {
    keys: product => product.id,
    from: { transform: 'translateX(-140%)' },
    enter: { transform: 'translateX(0%)' },
  })

  const confirmItem = async (data: IDataConfirmItem): Promise<IProduct | void> => {
    return handleConfirmItem({ ...data, step })
  }

  return (
    <Box mt="3rem">
      <Title style={{ color: '#353535', marginBottom: '1rem' }}>
        {step === 'pre_boxed'
          ? 'Itens caixa pronta'
          : step === 'no_separation_step'
          ? 'Itens sem etapa'
          : `Etapa ${step}`}
      </Title>
      <Box>
        {transition((style, product) => (
          <ContainerCardProduct
            orderId={orderId}
            style={style}
            product={product}
            handleDialogBreach={data => handleDialogBreach && handleDialogBreach({ ...data, step })}
            handleConfirm={confirmItem}
            resetOrder={resetOrder}
            step={Number(props.step)}
          />
        ))}
      </Box>

      {nextItem ? (
        <Box className={classes.boxAlign}>
          <Typography className={classes.title}>
            Próximo item{' '}
            {nextStep
              ? nextStep.step === 'no_separation_step'
                ? '- Item sem etapa'
                : `- Etapa ${props.items[1] ? step : nextStep?.step}`
              : null}
          </Typography>

          <Box className={classes.boxProduct}>
            <CardProduct product={nextItem} view="fastTrack" isVisualization={true} />
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}
