import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  boxAlign: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: '4rem',
    backgroundColor: theme.palette.background.paper,
    marginBottom: '0.7rem',
    border: '1px solid #89BD23',
    borderRadius: '0.5rem',
  },
  rankingInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '55%',
    height: '100%',
  },
  positionText: {
    minWidth: '15%',
    fontFamily: 'Roboto',
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '135%',
    color: '#353535',
    [theme.breakpoints.between(0, 550)]: {
      fontSize: '1.5rem',
      marginLeft: '0.5rem',
    },
  },
  image: {
    width: '3rem',
    height: '3rem',
    [theme.breakpoints.between(0, 550)]: {
      width: '2.5rem',
      height: '2.5rem',
    },
  },
  nameText: {
    maxWidth: '100%',
    fontFamily: 'Inter',
    fontSize: '1.3rem',
    marginLeft: '0.5rem',
    fontWeight: 600,
    lineHeight: '135%',
    color: '#353535',
    whiteSpace: 'nowrap',
    [theme.breakpoints.between(0, 550)]: {
      fontSize: '0.7rem',
    },
    [theme.breakpoints.between(0, 335)]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  orderInfo: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '60%',
    height: '100%',
    paddingRight: '1rem',
    [theme.breakpoints.between(0, 550)]: {
      width: '40%',
    },
  },
  itemsText: {
    width: '80%',
    fontFamily: 'Inter',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '135%',
    color: '#757575',
    '& strong': {
      color: '#353535',
    },
    paddingRight: '1.1rem',
    [theme.breakpoints.between(0, 550)]: {
      fontSize: '0.8rem',
    },
  },
  ordersText: {
    width: '40%',
    fontFamily: 'Inter',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '135%',
    color: '#757575',
    '& strong': {
      color: '#353535',
    },
    paddingRight: '1rem',
    [theme.breakpoints.between(0, 550)]: {
      fontSize: '0.8rem',
    },
  },
  timeText: {
    width: '80%',
    fontFamily: 'Inter',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '135%',
    color: '#757575',
    '& strong': {
      color: '#000000',
      fontSize: '0.9rem',
    },
    [theme.breakpoints.between(0, 550)]: {
      fontSize: '0.9rem',
    },
  },
}))
