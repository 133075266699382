import Cam from 'components/CardProductScanner/Cam'
import { Button, Modal, Paper, Title } from './styles'
import { usePopup } from 'hooks/usePopup'
import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import { checkBoxesService } from 'services/checkBoxesService'
import { Box, CircularProgress } from '@material-ui/core'

interface IModalScan {
  open: boolean
  onClose: () => void
}

const ModalScan = ({ open, onClose }: IModalScan) => {
  const { addPopup } = usePopup()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const redirectToConference = async (QRcode: string) => {
    try {
      setIsLoading(true)
      if (!QRcode.includes(';') || QRcode.split(';').length !== 2) {
        addPopup({
          title: 'QR Code inválido',
          description: 'O QR Code lido não é de caixa',
          type: 'error',
        })
        return
      }
      const [orderId] = QRcode.split(';')
      await checkBoxesService.create(orderId)
      navigate(`../${orderId}`)
    } catch (error: any) {
      addPopup({
        title: 'Erro ao iniciar conferência',
        description: error?.message,
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const children = {
    true: (
      <Box padding="3.2rem" display="flex" justifyContent="center">
        <CircularProgress style={{ fontSize: '1.5rem' }} />
      </Box>
    ),
    false: <Cam onSubmit={redirectToConference} />,
  }[String(isLoading)]

  return (
    <Modal open={open} onClose={onClose}>
      <Paper>
        <Title>Aponte a câmera para o QR code</Title>
        {children}
        <Button disabled={isLoading} onClick={onClose}>
          Cancelar bipagem
        </Button>
      </Paper>
    </Modal>
  )
}

export default ModalScan
