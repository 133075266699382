import { useState, ChangeEvent, useRef, ReactNode, useEffect, useMemo } from 'react'
import {
  InputAdornment,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  Container,
  ContainerSelect,
  Header,
  InputSearch,
  MenuPopoverStyled,
  OptionSelected,
  TableContainer,
  useStyles,
} from './styles'
import DateRangerPicker, { ISelectDate } from 'components/DateRangerPicker'
import { IReplenishment, enStatusReplenishment } from 'interfaces/IRequestProduct'
import { IoSearch } from 'react-icons/io5'
import moment from 'moment'
import { debaunce } from 'utils'
import { usePopup } from 'hooks/usePopup'
import { productResquestService } from 'services/productRequestService'
import { KeyboardArrowDown } from '@material-ui/icons'

interface IFormatedData {
  id: number
  solicited_at: string
  sku_name: string
  separation_group: number
  user_request: string
  response: string
  user_response: string
  time_to_response: string
  status: ReactNode
}

const getYesterday = () => {
  const today = new Date()

  const isMadrugada = today.getHours() >= 0 && today.getHours() < 12
  const isSunday = today.getDay() === 0
  const isMonday = today.getDay() === 1
  const isSaturday = today.getDay() === 6

  if (isMadrugada && isMonday) {
    return moment(today).subtract(3, 'days').toDate()
  }

  if (isSunday || (isSaturday && isMadrugada) || isMadrugada) {
    return moment(today).subtract(2, 'days').toDate()
  }

  return moment(today).subtract(1, 'days').toDate()
}

const separationDateFormatted = moment(getYesterday()).format('YYYY-MM-DD')
const separationDate = moment(separationDateFormatted).toDate()

const History = () => {
  const classes = useStyles()
  const [data, setData] = useState<IReplenishment[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const { addPopup } = usePopup()
  const controller = useRef(new AbortController())
  const [params, setParams] = useState({
    finalSeparationDate: separationDateFormatted,
    initialSeparationDate: separationDateFormatted,
    status: 'todos' as any as string,
    userResponse: null as any as string,
  })
  const searchDebaunce = debaunce({ fn: handleSearch, delay: 300 })

  const [popover, setPopover] = useState(false)
  const optionsRef = useRef(null)

  const togglePopover = (e?: any) => {
    setRef(e?.currentTarget)
    e?.stopPropagation()
    setPopover(state => !state)
  }

  const setRef = (instance: HTMLButtonElement) => {
    optionsRef.current = instance as any
  }

  function handleSearch(value: string) {
    setParams(state => ({
      ...state,
      userResponse: value,
    }))
  }

  const onFilter = (params: ISelectDate) => {
    setParams(state => ({
      ...state,
      finalSeparationDate: moment(params.endDate).format('YYYY-MM-DD'),
      initialSeparationDate: moment(params.startDate).format('YYYY-MM-DD'),
    }))
  }

  const getData = async (params: {
    finalSeparationDate: string
    initialSeparationDate: string
    status?: string
    userResponse?: string
  }) => {
    if (controller.current) {
      controller.current.abort()
    }
    controller.current = new AbortController()
    try {
      setIsLoading(true)
      const response = await productResquestService.getReplacementProducts({
        controller: controller.current,
        ...params,
        status: (params.status === 'todos' ? undefined : params.status) as any,
      })
      setData(response)
    } catch (err: any) {
      if (err?.message === 'canceled') return
      addPopup({
        title: 'Erro ao buscar dados',
        type: 'error',
        description: err?.message ?? err?.msg ?? 'Erro desconhecido',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const formatDate = (date: string) => {
    return moment(date).format('DD/MM/YYYY HH:mm')
  }

  const formatTimeResponse = (time: string) => {
    return time?.substring(0, 8) ?? ''
  }

  const getResponse = (response: string) => {
    if (response === enStatusReplenishment.AGUARDANDO_RESPOSTA) {
      return 'Não'
    }
    return 'Sim'
  }

  const formatData = (products: IReplenishment[]): IFormatedData[] => {
    return products.map(product => ({
      id: product.id,
      solicited_at: formatDate(product.created),
      sku_name: product.sku_name,
      separation_group: product.separation_group,
      user_request: product.user_request,
      response: getResponse(product.status),
      status: formatStatus(product.status),
      user_response: product.user_response,
      time_to_response: formatTimeResponse(product.time_to_response),
    }))
  }

  const formatStatus = (status: string): ReactNode => {
    if (status === enStatusReplenishment.REABASTECIDO) return Status('#567B0D', 'Reabastecido')
    if (status === enStatusReplenishment.QUEBRA) return Status('#FF0000', 'Quebra')
    if (status === enStatusReplenishment.AGUARDANDO_RESPOSTA)
      return Status('#FFC107', 'Aguardando resposta')
    if (status === 'todos') return Status('#0d02a6', 'Todos')
    return Status('#FFC107', 'Resposta não mapeada')
  }

  const Status = (color: string, status: string) => {
    return (
      <>
        <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="5" fill={color} />
        </svg>
        <p>{status}</p>
      </>
    )
  }

  const options = [
    {
      value: 'todos',
      label: 'Todos',
    },
    {
      value: enStatusReplenishment.AGUARDANDO_RESPOSTA,
      label: 'Aguardando resposta',
    },
    {
      value: enStatusReplenishment.QUEBRA,
      label: 'Quebra',
    },
    {
      value: enStatusReplenishment.REABASTECIDO,
      label: 'Reabastecido',
    },
  ]

  const rows = useMemo(() => formatData(data), [data])

  useEffect(() => {
    getData(params)
    return () => {
      controller.current.abort()
    }
  }, [params])

  return (
    <Container>
      <Header>
        <InputSearch
          placeholder="Nome"
          startAdornment={
            <InputAdornment position="start">
              <IoSearch />
            </InputAdornment>
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => searchDebaunce(e.target.value)}
        />
        <Tooltip title="Selecione a data que a separação iniciou">
          <div>
            <DateRangerPicker
              onSelectedDate={onFilter}
              defaultEndDate={separationDate}
              defaultStartDate={separationDate}
              classContainer={classes.calendar}
            />
          </div>
        </Tooltip>
        <ContainerSelect>
          <Typography>Status:</Typography>
          <OptionSelected onClick={togglePopover}>
            {formatStatus(params.status)}{' '}
            <KeyboardArrowDown style={popover ? { transform: 'rotate(180deg)' } : {}} />
          </OptionSelected>
          {popover && (
            <MenuPopoverStyled
              open={popover}
              onClose={togglePopover}
              anchorEl={optionsRef?.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              {options.map(option => (
                <OptionSelected
                  key={option.value}
                  onClick={() => {
                    setParams(state => ({
                      ...state,
                      status: option.value,
                    }))
                    togglePopover()
                  }}
                >
                  {formatStatus(option.value)}
                </OptionSelected>
              ))}
            </MenuPopoverStyled>
          )}
        </ContainerSelect>
      </Header>
      <LinearProgress style={{ opacity: isLoading ? 1 : 0 }} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Solicitado em</TableCell>
              <TableCell>Nome do SKU</TableCell>
              <TableCell>Grupo de separação</TableCell>
              <TableCell>Solicitado por</TableCell>
              <TableCell>Atendido por</TableCell>
              <TableCell>Tempo de resposta</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.solicited_at}</TableCell>
                <TableCell>{row.sku_name}</TableCell>
                <TableCell>{row.separation_group}</TableCell>
                <TableCell>{row.user_request}</TableCell>
                <TableCell>{row.user_response}</TableCell>
                <TableCell>{row.time_to_response}</TableCell>
                <TableCell>{row.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default History
