import '@brainhubeu/react-carousel/lib/style.css'
import {
  styled,
  Box,
  Typography,
  Button as ButtonMui,
  makeStyles,
  TextField,
} from '@material-ui/core'

export const Container = styled(Box)({
  transition: 'background 1s linear',
  padding: '0.5rem 0 0',
  height: '100%',
  width: '100%',
  display: 'flex',
  position:'relative'
})

export const CarouselContainer = styled(Box)({
  display: 'flex',
  margin: '0 auto 0',
  padding: '0 1rem',
})

export const Card = styled(Box)(({ theme }) => ({
  boxShadow: '19px 34px 99px 0px rgba(0,0,0,0.12)',
  width: '100%',
  height: '500px',
  maxWidth: '600px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px',
}))

export const CardEmpty = styled(Box)(({ theme }) => ({
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px',
  maxWidth: '1200px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '50%',
  padding: '4rem 0',
  height: '100%',
}))

export const InfoCustomer = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '.3rem',
  fontSize: '1.2rem',
  fontWeight: 500,
  '& > strong': {
    color: '#757575',
  },
  '& > span': {
    color: '#353535',
  },
})

export const Section = styled(Box)({
  display: 'flex',
  height: '250px',
  justifyContent: 'center',
  '& > img': {
    width: '50%',
    maxWidth: '400px',
  },
})

export const InvisibleInput = styled(TextField)(() => ({
  position: 'absolute',
  opacity: 0,
}))

export const Button = styled(ButtonMui)({
  borderColor: '#FFFFFF',
  padding: '0 20px',
  borderRadius: '24px',
  display: 'block',
  margin: '1rem auto 0 auto',
  color: '#FFFFFF',
  fontSize: '1.2rem',
  lineHeight: '48px',
  textTransform: 'none',
})

export const useStyles = makeStyles({
  animationImage: {
    '& img': {
      animation: '$move infinite 4s linear',
    },
  },
  boxContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  '@keyframes move': {
    '0%': {
      transform: 'translateX(-20%)',
    },
    '50%': {
      transform: 'translateX(20%)',
    },
    '100%': {
      transform: 'translateX(-20%)',
    },
  },
  lastQrcode: {
    color: '#353535',
    fontSize: '4rem',
    fontWeight: 500,
    position: 'absolute',
    top: '-5px',
    background: '#FFF',
    padding: '.2rem 1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    borderRadius: '8px',
    border: '1px solid #E0E0E0',
    letterSpacing: '0.3rem',
    lineHeight: '4rem',
  },
})

export const ButtonCarousel = styled(ButtonMui)({})
