import { Box, Typography } from '@material-ui/core'
import { useMemo } from 'react'
import { BsFillClockFill } from 'react-icons/bs'

import { useStyles } from './styles'

interface ICardInfo {
  title: string
  totalOrder: number
  endTime: Date | null
}

export const CardInfoToggle = (props: ICardInfo) => {
  const classes = useStyles()
  const { totalOrder, title, endTime } = props

  const time = useMemo(() => {
    if (!endTime) {
      return '00:00'
    }

    const forecast = new Date(endTime)
    const date = new Date()
    const dateToday = date.getDate()
    const dateForCheck = forecast.getDate()
    const hh = String(forecast.getHours()).padStart(2, '0')
    const mm = String(forecast.getMinutes()).padStart(2, '0')
    const day = dateToday !== dateForCheck ? 'Amanhã as' : 'Hoje às'

    return `${day} ${hh}:${mm}`
  }, [props])

  return (
    <Box className={classes.root}>
      <span className={classes.containerIcon}>
        <BsFillClockFill size={20} color="#2E656A" />
      </span>

      <Box>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>

        {!totalOrder || !endTime ? (
          <Typography className={classes.noOrders}>Nenhum pedido em separação</Typography>
        ) : (
          <Typography variant="subtitle2" className={classes.subtitle}>
            {time}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
