import Cam from 'components/CardProductScanner/Cam'
import { Button, ButtonBack, ButtonConfirm, Modal, Paper, Subtitle, Text, Title } from './styles'
import { usePopup } from 'hooks/usePopup'
import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import { IRouteOverResponse } from 'services/routeService'
import { Box, LinearProgress, TextField } from '@material-ui/core'
import { loadingConference } from 'services/loadingConferenceService'
import { dashboardService } from 'services/dashboardService'

interface IModalScan {
  open: boolean
  onClose: () => void
  data: IRouteOverResponse
  refresh: () => Promise<void>
}

enum enSection {
  SCAN_BOX_ORDER = 'SCAN_BOX_ORDER',
  WRONG_BOX_ORDER = 'WRONG_BOX_ORDER',
  INPUT_NUMBER_DECREASED_BOXES = 'INPUT_NUMBER_DESCREASED_BOXES',
  FINISH_DECREASE_BOXES = 'FINISH_DECREASE_BOXES',
}

const ModalScan = ({ open, onClose, data, refresh }: IModalScan) => {
  const { addPopup } = usePopup()
  const [valueQR, setValueQR] = useState('')
  const [section, setSection] = useState<enSection>(enSection.SCAN_BOX_ORDER)
  const [orderData, setOrderData] = useState({ order: '', box: '' })
  const [numberBoxes, setNumberBoxes] = useState(0)
  const [loading, setLoading] = useState(false)

  const onScanQr = (QRcode: string) => {
    setValueQR(QRcode)
  }

  const getOrderData = async (order: string) => {
    try {
      const response = await dashboardService.getOrderDetails(order)
      return response
    } catch (err: any) {
      addPopup({
        title: 'Erro ao buscar dados do pedido',
        description: err?.message ?? err?.msg ?? 'Erro desconhecido',
        type: 'error',
      })
      return null
    }
  }

  const handleScanQr = async (QRcode: string) => {
    if (QRcode.split(';').length !== 2) {
      addPopup({
        title: 'Erro ao ler QRCode',
        description: `QRCode #${QRcode} inválido`,
        type: 'error',
      })
      return
    }
    const [order, box] = QRcode.split(';')
    const response = await getOrderData(order)
    if (!response) return
    if (response.order.route !== data.route) {
      addPopup({
        title: `Pedido #${order} da rota ${response.order.route} não pertence a rota ${data.route}`,
        type: 'error',
      })
      return
    }
    setOrderData({ box, order })
    setSection(enSection.INPUT_NUMBER_DECREASED_BOXES)
  }

  const updateLoadingConference = async () => {
    setLoading(true)
    try {
      const loading = await loadingConference.getById(orderData.order)
      if (!loading) {
        addPopup({
          title: 'Erro ao buscar conferência',
          description: `Não foi possível encontrar a conferência do pedido #${orderData.order}`,
          type: 'error',
        })
        return
      }
      await loadingConference.update(loading.id, {
        adjusted_boxes_number: Number(numberBoxes),
      })
      setSection(enSection.FINISH_DECREASE_BOXES)
      refresh()
    } catch (err: any) {
      addPopup({
        title: 'Erro ao atualizar número de caixas',
        description: err?.message ?? err?.msg ?? 'Erro desconhecido',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const onBack = () => {
    setOrderData({ order: '', box: '' })
    setSection(enSection.SCAN_BOX_ORDER)
  }

  useEffect(() => {
    if (valueQR) {
      handleScanQr(valueQR)
    }
  }, [valueQR])

  return (
    <Modal open={open} onClose={onClose}>
      <Paper>
        <LinearProgress style={{ width: '100%', opacity: loading ? 1 : 0 }} />
        {section === enSection.SCAN_BOX_ORDER && (
          <>
            <Title>Rota {data.route}</Title>
            <Subtitle>Bipe uma caixa de um pedido dessa rota</Subtitle>
            <Cam onSubmit={onScanQr} />
            <Button onClick={onClose}>Cancelar bipagem</Button>
          </>
        )}
        {section === enSection.INPUT_NUMBER_DECREASED_BOXES && (
          <>
            <Title>Pedido {orderData.order}</Title>
            <Text>Quantas caixas sobraram no pedido após a diminuição?</Text>
            <TextField
              type="number"
              variant="outlined"
              label="Número de caixas"
              style={{ width: '100%', margin: '1rem 0' }}
              value={numberBoxes}
              onChange={e => setNumberBoxes(e.target.value as any)}
            />
            <Box display="flex" justifyContent="center" style={{ gap: '1rem' }}>
              <ButtonBack onClick={onBack}>Bipar outro pedido</ButtonBack>
              <ButtonConfirm onClick={updateLoadingConference}>Confirmar</ButtonConfirm>
            </Box>
          </>
        )}
        {section === enSection.FINISH_DECREASE_BOXES && (
          <>
            <Title>Diminuição concluída</Title>
            <Text>O número de caixas foi atualizado com sucesso!</Text>
            <Button onClick={onClose}>Fechar</Button>
          </>
        )}
      </Paper>
    </Modal>
  )
}

export default ModalScan
