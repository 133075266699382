import { Box, LinearProgress } from '@material-ui/core'
import { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import DateRangerPicker, { ISelectDate } from 'components/DateRangerPicker'
import { usePopup } from 'hooks/usePopup'
import { separatorService } from 'services/separatorService'
import { useStyles } from './styles'

interface IData {
  username: string
  average_item_separate: number
}

const AverageItemTime = () => {
  const [data, setData] = useState<IData[]>([])
  const [noData, setNoData] = useState(false)
  const [averageItemTime, setAverageItemTime] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const { addPopup } = usePopup()
  const controllerRef = useRef<any>(null)

  const getData = async ({ endDate, startDate }: { startDate: Date; endDate: Date }) => {
    const date = (param: Date): string => {
      return moment(param).format('YYYY-MM-DD')
    }

    try {
      if (controllerRef.current) {
        controllerRef.current?.abort()
      }
      controllerRef.current = new AbortController()

      setLoading(true)
      const response = await separatorService.getAverageTimeItemSeparators(
        date(startDate),
        date(endDate),
        controllerRef.current,
      )
      const average = response.find(user => user.username === 'average_all_items_separated')

      setAverageItemTime(Number(Number(average?.average_item_separate).toFixed(1)))

      setData(
        response
          .filter(
            user =>
              user.username !== 'average_all_items_separated' &&
              Number(user.average_item_separate) > 0,
          )
          .map(user => ({
            username: user.username,
            average_item_separate: Number(Number(user.average_item_separate).toFixed(1)),
          })),
      )
      if (response.length <= 1) {
        setNoData(true)
        return
      }
      setNoData(false)
    } catch (err: any) {
      if (err?.message === 'canceled') return
      addPopup({
        title: 'Erro ao pegar os dados de média de separação por itens',
        type: 'error',
        description: err?.message || err?.msg,
      })
    } finally {
      setLoading(false)
    }
  }

  const options: ApexOptions = {
    colors: ['#2549CC'],
    legend: {
      show: true,
      position: 'bottom',
      fontSize: '14px',
      fontFamily: 'Arial, sans-serif',
    },
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      width: '100%',
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4,
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1,
          },
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: `${data.length * 3 > 100 ? 100 : data.length * 3}%`,
        dataLabels: {
          position: 'top',
        },
        borderRadius: 1,
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -30,
      style: {
        fontSize: '17px',
        colors: ['#2549CC'],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data.map(user => user.username),
    },
    yaxis: {
      min: 0,
      max:
        data.reduce(
          (acc, current) =>
            current.average_item_separate > acc ? current.average_item_separate : acc,
          0,
        ) + 3,
      decimalsInFloat: 0,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: value => {
          return `${value} segundos`
        },
      },
    },
    annotations: {
      yaxis: [
        {
          y: averageItemTime,
          borderColor: '#383B40',
          strokeDashArray: 6,
          borderWidth: 2,
          label: {
            borderColor: '#383B40',
            style: {
              color: '#fff',
              background: '#383B40',
            },
            text: averageItemTime.toString(),
          },
        },
      ],
    },
  }

  const series: ApexOptions['series'] = [
    {
      name: 'Tempo médio por separador em segundos',
      data: data.map(user => user.average_item_separate),
    },
    {
      name: 'Tempo médio total em segundos',
      data: [],
      color: '#383B40',
    },
  ]

  const getYesterday = () => {
    const date = new Date()
    const hour = date.getHours()
    date.setDate(date.getDate() - (hour >= 0 && hour < 12 ? 2 : 1))
    return date
  }

  const onFilter = (params: ISelectDate) => {
    getData(params)
  }

  useEffect(() => {
    getData({
      startDate: getYesterday(),
      endDate: getYesterday(),
    })
  }, [])

  return (
    <Box>
      <LinearProgress variant="indeterminate" style={{ width: '100%', opacity: loading ? 1 : 0 }} />
      <Box display="flex" style={{ width: '100%' }}>
        <Box className={classes.filter}>
          <DateRangerPicker onSelectedDate={onFilter} />
        </Box>
      </Box>
      {noData && <Box className={classes.alertNoData}>Sem dados para o período selecionado!</Box>}

      <Box className={classes.scrollChart}>
        <Box style={{ minWidth: '900px' }}>
          <ReactApexChart options={options} series={series} type="bar" height={350} />
        </Box>
      </Box>
    </Box>
  )
}

export default AverageItemTime
