import { ISettingsRequest } from 'services/settingsService'

import { eSettings } from './types'
import { ISeparationGroupConfig } from 'interfaces/ISeparationGroupService'

const initialState: ISettingsRequest = {
  separations: 0,
  numberOfSeparators: 0,
  goalSeparate: 0,
  separationGroups: [] as ISeparationGroupConfig[],
} as ISettingsRequest

export default (state = initialState, action: any): ISettingsRequest => {
  switch (action.type) {
    case eSettings.SETTINGS:
      return action.settings
    default:
      return state
  }
}
