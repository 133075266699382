import { Theme } from '@material-ui/core'
import { Overrides } from '@material-ui/core/styles/overrides'

const Popover = (theme: Theme): Overrides => {
  return {
    MuiPopover: {
      paper: {
        boxShadow: theme.shadows[2],
      },
    },
  }
}

export default Popover
