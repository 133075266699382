import { enRules } from 'interfaces/IUser'
import LoadingRules from 'pages/Public/LoadingRules'
import { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, Outlet } from 'react-router-dom'
import { IState } from 'store'
import { IUser } from 'store/reducers/user/reducer'

type IPrivateRoute = {
  component?: ComponentType
  rules: enRules[]
}

export const PrivateRoute = ({ component: RouteComponent, rules }: IPrivateRoute) => {
  const { isAuthenticated, userPermissions, isLoading } = useSelector<IState, IUser>(
    state => state.user,
  )
  const location = useLocation()

  return !isAuthenticated ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : isLoading ? (
    <LoadingRules />
  ) : userPermissions.some(rule => rules.includes(rule)) || rules.length === 0 ? (
    RouteComponent ? (
      <RouteComponent />
    ) : (
      <Outlet />
    )
  ) : userPermissions.includes(enRules.SeparationCheck) ? (
    <Navigate to="/operacao/conferencia/inicio" state={{ from: location }} replace />
  ) : (
    <Navigate to="/operacao/separacao/desempenho" state={{ from: location }} replace />
  )
}
