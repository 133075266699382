import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Grid,
  InputAdornment,
  CircularProgress,
  IconButton,
  Button as MuiButton,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import LockIcon from '@material-ui/icons/Lock'
import PersonIcon from '@material-ui/icons/Person'
import { useAuth } from 'hooks/useAuth'
import { ILogin } from 'interfaces/ILogin'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Keyboard from 'react-simple-keyboard'
import * as Yup from 'yup'

import { useStyles, Input, Button } from './styles'
import 'react-simple-keyboard/build/css/index.css'

const validateSchema = Yup.object().shape({
  username: Yup.string().required('Login obrigatório'),
  password: Yup.string().required('Senha obrigatória'),
})

const SignIn = () => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const { signIn } = useAuth()
  const [showPassword, setShowPassword] = useState(false)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [activeInput, setActiveInput] = useState('')
  const [layoutName, setLayoutName] = useState('')

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(validateSchema),
  })

  const { errors } = formState

  const handleSignIn: SubmitHandler<ILogin> = async data => {
    setIsLoading(true)
    await signIn(data)
    setIsLoading(false)
  }

  const handleShowPassword = () => setShowPassword(state => !state)
  const [useCustomKeyboard, setUseCustomKeyboard] = useState(false)

  const toggleKeyboard = () => {
    setUseCustomKeyboard(prev => !prev)
  }

  // Função para manipular a lógica de backspace
  const handleBackspace = () => {
    if (activeInput === 'username') {
      const updatedValue = username.slice(0, -1)
      setUsername(updatedValue)
      setValue('username', updatedValue)
    } else if (activeInput === 'password') {
      const updatedValue = password.slice(0, -1)
      setPassword(updatedValue)
      setValue('password', updatedValue)
    }
  }

  // Função para capturar a tecla pressionada no teclado virtual
  const handleKeyboardPress = (button: string) => {
    let newLayoutName = layoutName
    const isShift = !!(layoutName === 'shift')
    const isAlt0 = !!(button === '~' && !isShift)
    const isAlt1 = !!(button === '~' && isShift)
    const isAlt2 = !!(button === '^' && !isShift)
    const isAlt3 = !!(button === '^' && isShift)
    const isAlt4 = !!(button === '´' && !isShift)
    const isAlt5 = !!(button === '´' && isShift)

    const returnShift = !!(
      (layoutName === 'alt1' && button === '~') ||
      (layoutName === 'alt3' && button === '^') ||
      (layoutName === 'alt5' && button === '´')
    )
    const returnDefault = !!(
      (layoutName === 'alt0' && button === '~') ||
      (layoutName === 'alt2' && button === '^') ||
      (layoutName === 'alt4' && button === '´')
    )
    if (button === '{bksp}') {
      handleBackspace()
      return
    }
    if (button === '{shift}') {
      setLayoutName(layoutName === 'default' ? 'shift' : 'default')
      return
    }
    if (returnShift) {
      newLayoutName = 'shift'
    } else if (returnDefault) {
      newLayoutName = 'default'
    } else if (isAlt0) {
      newLayoutName = 'alt0'
    } else if (isAlt1) {
      newLayoutName = 'alt1'
    } else if (isAlt2) {
      newLayoutName = 'alt2'
    } else if (isAlt3) {
      newLayoutName = 'alt3'
    } else if (isAlt4) {
      newLayoutName = 'alt4'
    } else if (isAlt5) {
      newLayoutName = 'alt5'
    }

    if (newLayoutName !== layoutName) {
      setLayoutName(newLayoutName)
      return
    }

    if (button === '{space}') {
      if (activeInput === 'username') {
        const updatedValue = username + ' '
        setUsername(updatedValue)
        setValue('username', updatedValue)
        return
      }

      if (activeInput === 'password') {
        const updatedValue = password + ' '
        setPassword(updatedValue)
        setValue('password', updatedValue)
        return
      }
    }
    if (activeInput === 'username') {
      const updatedValue = username + button
      setUsername(updatedValue)
      setValue('username', updatedValue)
      return
    }

    if (activeInput === 'password') {
      const updatedValue = password + button
      setPassword(updatedValue)
      setValue('password', updatedValue)
    }
  }

  const handleInputChangeUsername = (event: any) => {
    const value = event.target.value
    setUsername(value)
    setValue('username', value)
  }

  const handleInputChangePassword = (event: any) => {
    const value = event.target.value
    setPassword(value)
    setValue('password', value)
  }

  return (
    <main className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <div className={classes.img} />

          <div className={classes.box}>
            <h1 className={classes.title}>Bem vindo(a) de volta!</h1>
            <p className={classes.subtitle}>Faça login na sua conta</p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              marginBottom: '1rem',
            }}
          >
            <MuiButton variant="contained" color="primary" onClick={toggleKeyboard}>
              {useCustomKeyboard ? 'Usar teclado do sistema' : 'Usar teclado virtual'}
            </MuiButton>
          </div>
          <Box component="form" onSubmit={handleSubmit(handleSignIn)} className={classes.form}>
            <Input
              data-cy="login"
              variant="outlined"
              placeholder="Login"
              autoComplete="none"
              autoCorrect="none"
              error={!!errors?.username}
              helperText={errors?.username?.message}
              value={username}
              onChange={handleInputChangeUsername}
              onFocus={() => setActiveInput('username')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Input
              data-cy="password"
              variant="outlined"
              placeholder="Senha"
              type={showPassword ? 'text' : 'password'}
              error={!!errors?.password}
              helperText={errors?.password?.message}
              value={password}
              onChange={handleInputChangePassword}
              onFocus={() => setActiveInput('password')}
              autoComplete="none"
              autoCorrect="none"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility style={{ color: '#89BD23' }} />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button type="submit" variant="contained" data-cy="btn-signin" disabled={isLoading}>
              {isLoading ? (
                <CircularProgress data-cy="loading-signin" size={25} style={{ color: '#ffffff' }} />
              ) : (
                'Entrar'
              )}
            </Button>
          </Box>

          {useCustomKeyboard && (
            <Keyboard
              onKeyPress={handleKeyboardPress}
              layoutName={layoutName}
              layout={{
                default: [
                  '1 2 3 4 5 6 7 8 9 0 {bksp}',
                  'q w e r t y u i o p',
                  'a s d f g h j k l ç . ,',
                  'z x c v b n m ~ ^ ´',
                  '{shift} {space}',
                ],
                shift: [
                  '1 2 3 4 5 6 7 8 9 0 {bksp}',
                  'Q W E R T Y U I O P',
                  'A S D F G H J K L Ç . ,',
                  'Z X C V B N M ~ ^ ´',
                  '{shift} {space}',
                ],
                alt0: [
                  '1 2 3 4 5 6 7 8 9 0 {bksp}',
                  'q w e r t y u i õ p',
                  'ã s d f g h j k l ç . ,',
                  'z x c v b n m ~ ^ ´',
                  '{shift} {space}',
                ],
                alt1: [
                  '1 2 3 4 5 6 7 8 9 0 {bksp}',
                  'Q W E R T Y U I Õ P',
                  'Ã S D F G H J K L Ç . ,',
                  'Z X C V B N M ~ ^ ´',
                  '{shift} {space}',
                ],
                alt2: [
                  '1 2 3 4 5 6 7 8 9 0 {bksp}',
                  'q w ê r t y û î ô p',
                  'â s d f g h j k l ç . ,',
                  'z x c v b n m ~ ^ ´',
                  '{shift} {space}',
                ],
                alt3: [
                  '1 2 3 4 5 6 7 8 9 0 {bksp}',
                  'Q W Ê R T Y Û Î Ô P',
                  'Â S D F G H J K L Ç . ,',
                  'Z X C V B N M ~ ^ ´',
                  '{shift} {space}',
                ],
                alt4: [
                  '1 2 3 4 5 6 7 8 9 0 {bksp}',
                  'q w é r t y ú í ó p',
                  'á s d f g h j k l ç . ,',
                  'z x c v b n m ~ ^ ´',
                  '{shift} {space}',
                ],
                alt5: [
                  '1 2 3 4 5 6 7 8 9 0 {bksp}',
                  'Q W É R T Y Ú Í Ó P',
                  'Á S D F G H J K L Ç . ,',
                  'Z X C V B N M ~ ^ ´',
                  '{shift} {space}',
                ],
              }}
              display={{
                '{bksp}': '⌫',
                '{shift}': '⇧',
                '{space}': 'Espaço',
              }}
            />
          )}
        </Grid>
      </Grid>
    </main>
  )
}

export default SignIn
