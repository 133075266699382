import { api as apiService, ApiService } from './api'

export enum enStatusRoute {
  CONCLUIDA = 'concluída',
  INCOMPLETA = 'incompleta',
}

export interface IGetRoutes {
  data: {
    [key: string]: IRoutes
  }
}

export interface IRoutes {
  orders: [
    {
      order_id: string
      boxes: number
      quantity_reprint: number
      date_printed: string
    },
  ]
  status: enStatusRoute
  color_route: string
}

export interface IOverRouteParams {
  scheduledDate?: string
  controller: AbortController
}

export interface IRouteOverResponse {
  route: number
  real_boxes_number: number
  capacity_vehicle: number
  route_above: IRouteLimit
}

export interface IRouteAboveLimit {
  totalCount: number
  pageIndex: number
  pageSize: number
  results: IRouteLimit[]
}

export interface IRouteAboveLimitParams {
  routes?: string
  page?: number
  pageSize?: number
}

export interface IRouteLimit {
  id: number
  route: number
  is_hidden: boolean
  is_hidden_by: number | null
  reduced: boolean | null
  reduced_by: string | number | null
}

class RouteService {
  constructor(private readonly api: ApiService) {}

  async getRoutes(scheduledDate: string, controller: AbortController): Promise<IGetRoutes> {
    return this.api.get(carambolaUrl + '/routes/orders-by-route', {
      params: { scheduledDate },
      signal: controller.signal,
    })
  }

  async postReprintRoute(routeId: string, orderId?: string): Promise<{ message: string }> {
    return this.api.post(carambolaUrl + '/print/reprint-by-route', {
      routeId,
      orderId,
    })
  }

  async getOverRoutes(params: IOverRouteParams): Promise<{ data: IRouteOverResponse[] }> {
    return this.api.get(carambolaUrl + '/routes/route-over-boxes', {
      params,
      signal: params.controller.signal,
    })
  }

  async getListRouteLimit(params: IRouteAboveLimitParams): Promise<IRouteAboveLimit> {
    return this.api.get(carambolaUrl + '/routes/route-above-box-limit', {
      params,
    })
  }

  async updateRouteLimit(routeLimitId: number, data: Partial<IRouteLimit>): Promise<IRouteLimit> {
    return this.api.patch(carambolaUrl + `/routes/route-above-box-limit/${routeLimitId}`, data)
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const routeService = new RouteService(apiService)
