import Carousel, { slidesToShowPlugin, arrowsPlugin } from '@brainhubeu/react-carousel'
import { Box, Container, Divider, Typography } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import iconOrderFinished from 'assets/images/icon-orderFinished.svg'
import { useEffect, useState, useRef } from 'react'
import { AiOutlineCheck, AiOutlineProfile } from 'react-icons/ai'
import logoFrexco from 'assets/icons/logo-frexco.png'
import logoIfood from 'assets/icons/logo-ifood.png'

import { useStyles } from './styles'
import { IOrdersResume } from 'interfaces/IDashboard'
import { FaBoxes, FaPeopleCarry } from 'react-icons/fa'
import { GiWeight } from 'react-icons/gi'
import { MdOutlineSpeed } from 'react-icons/md'

interface ISummaryProps {
  totalItens: number
  totalOrders: number
  totalIfoodOrders: IOrdersResume
  totalFrexcoOrders: IOrdersResume
  completed: number
  separationOrders: number
  totalVolume: number
  processedVolume: number
  lastUpdate: string | null
  className?: string
  pendentOrders: number
  tonsPerHour: number
}

export const Summary = (props: ISummaryProps) => {
  const {
    completed,
    totalItens,
    totalOrders,
    className,
    separationOrders,
    totalVolume,
    totalFrexcoOrders,
    totalIfoodOrders,
    processedVolume,
    lastUpdate,
    pendentOrders,
    tonsPerHour,
  } = props

  const [index, setIndex] = useState(0)
  const [showTonsPerHour, setShowTonsPerHour] = useState(false)
  const [isBoxFixed, setIsBoxFixed] = useState(false)
  const boxRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(() => window?.innerWidth)
  const classes = useStyles({ isCarrouselVisible: width <= 1200 })
  const plugin = {
    resolve: arrowsPlugin,
    options: {
      arrowLeft: (
        <button className={classes.arrowLeft}>
          <ArrowBackIosIcon className={classes.arrowIcon} />
        </button>
      ),
      arrowLeftDisabled: (
        <button disabled={true} className={classes.arrowLeft}>
          <ArrowBackIosIcon className={classes.arrowIcon} />
        </button>
      ),
      arrowRight: (
        <button className={classes.arrowRight}>
          <ArrowForwardIosIcon className={classes.arrowIcon} />
        </button>
      ),
      arrowRightDisabled: (
        <button disabled={true} className={classes.arrowRight}>
          <ArrowForwardIosIcon className={classes.arrowIcon} />
        </button>
      ),
      addArrowClickHandler: true,
    },
  }

  const TotalOrders = () => (
    <Box className={classes.box}>
      <span className={`${classes.icon} ${classes.totalOrderIcon}`}>
        <FaBoxes style={{ color: '#ffffff', fontSize: '1.3rem' }} />
      </span>
      <div>
        <Typography className={classes.title}>Total de pedidos</Typography>
        <Typography className={classes.subtitle}>{totalOrders ?? 0}</Typography>
      </div>
    </Box>
  )

  const TotalIfoodOrders = () => (
    <Box className={classes.box}>
      <span className={`${classes.icon} ${classes.totalOrderIcon}`}>
        <img loading="lazy" src={logoIfood} style={{ width: '300px', height: 'auto' }} />
      </span>
      <div>
        <Typography className={classes.title}>Pedidos Ifood</Typography>
        <Typography className={classes.subtitle}>
          {totalIfoodOrders?.finalized ?? 0}/{totalIfoodOrders?.total ?? 0}
        </Typography>
      </div>
    </Box>
  )

  const TotalFrexcoOrders = () => (
    <Box className={classes.box}>
      <span className={`${classes.icon} ${classes.totalOrderIcon}`}>
        <img loading="lazy" src={logoFrexco} style={{ width: '300px', height: 'auto' }} />
      </span>
      <div>
        <Typography className={classes.title}>Pedidos Frexco</Typography>
        <Typography className={classes.subtitle}>
          {totalFrexcoOrders?.finalized ?? 0}/{totalFrexcoOrders?.total ?? 0}
        </Typography>
      </div>
    </Box>
  )

  const TotalItems = () => (
    <Box className={classes.box}>
      <span className={`${classes.icon} ${classes.totalItens}`}>
        <AiOutlineProfile style={{ color: '#ffffff' }} size={28} />
      </span>
      <div>
        <Typography className={classes.title}>Total de itens</Typography>
        <Typography className={classes.subtitle}>{totalItens ?? 0}</Typography>
      </div>
    </Box>
  )

  const FinishedOrders = () => (
    <Box className={classes.box}>
      <span className={`${classes.icon} ${classes.ordersFinished}`}>
        <img loading="lazy" src={iconOrderFinished} />
      </span>
      <div>
        <Typography className={classes.title}>Pedidos finalizados</Typography>
        <Typography className={classes.subtitle}>{completed ?? 0}</Typography>
      </div>
    </Box>
  )

  const OrdersInSeparation = () => (
    <Box className={classes.box}>
      <span className={`${classes.icon} ${classes.separationOrders}`}>
        <FaPeopleCarry style={{ color: '#ffffff', fontSize: '1.3rem' }} />
      </span>
      <div>
        <Typography className={classes.title}>Pedidos em separação</Typography>
        <Typography className={classes.subtitle}>{separationOrders ?? 0}</Typography>
      </div>
    </Box>
  )

  const PendingOrders = () => (
    <Box className={classes.box}>
      <span className={`${classes.icon} ${classes.ordersPending}`} style={{ marginTop: '0.9rem' }}>
        <MoreHorizIcon style={{ color: '#ffffff' }} />
      </span>
      <div>
        <Typography className={classes.title}>Pedidos pendentes</Typography>
        <Typography className={classes.subtitle}>{pendentOrders || 0}</Typography>
        <Typography className={classes.lastUpdate}>Atualizado às: {lastUpdate}</Typography>
      </div>
    </Box>
  )

  const TotalVolume = () => (
    <Box className={classes.box}>
      <span className={`${classes.icon} ${classes.ordersTotal}`}>
        <GiWeight style={{ color: '#ffffff', fontSize: '1.4rem' }} />
      </span>
      <div>
        <Typography className={classes.title}>Volume total</Typography>
        <Typography className={classes.subtitle}>
          {`${Math.floor(totalVolume).toLocaleString() || '0'} kg`}
        </Typography>
      </div>
    </Box>
  )

  const VolumeProcessed = () => (
    <div ref={boxRef}>
      <Box
        className={classes.box}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {showTonsPerHour || isBoxFixed ? (
          <>
            <span className={`${classes.icon} ${classes.ordersProcessed}`}>
              <MdOutlineSpeed style={{ color: '#ffffff', fontSize: '1.4rem' }} />
            </span>
            <div>
              <Typography className={classes.title}>Toneladas por Hora</Typography>
              <Typography className={classes.subtitle}>{`${
                tonsPerHour.toFixed(3).replace('.', ',') || '0'
              } T/h`}</Typography>
            </div>
          </>
        ) : (
          <>
            <span className={`${classes.icon} ${classes.ordersProcessed}`}>
              <AiOutlineCheck style={{ color: '#ffffff', fontSize: '1.3rem' }} />
            </span>
            <div>
              <Typography className={classes.title}>Volume processado</Typography>
              <Typography className={classes.subtitle}>
                {`${Math.floor(processedVolume).toLocaleString() || '0'} kg`}
              </Typography>
            </div>
          </>
        )}
      </Box>
    </div>
  )

  const handleMouseEnter = () => {
    if (!isBoxFixed) setShowTonsPerHour(true)
  }

  const handleMouseLeave = () => {
    if (!isBoxFixed) setShowTonsPerHour(false)
  }

  const handleClick = () => {
    setIsBoxFixed(!isBoxFixed)
    setShowTonsPerHour(!isBoxFixed)
  }

  useEffect(() => {
    const updateWidth = (e: any) => {
      setWidth(e.target?.innerWidth)
    }

    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
        setIsBoxFixed(false)
        setShowTonsPerHour(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [boxRef])

  useEffect(() => {
    const updateWidth = (e: any) => {
      setWidth(e.target?.innerWidth)
    }

    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  return (
    <>
      <Carousel
        className={`${classes.carousel} ${className}`}
        value={index}
        onChange={e => {
          if (e >= 0) {
            setIndex(e)
          }
        }}
        plugins={[
          {
            resolve: slidesToShowPlugin,
            options: { numberOfSlides: 3 },
          },
          plugin,
        ]}
        breakpoints={{
          820: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: { numberOfSlides: 2 },
              },
              plugin,
            ],
          },
          520: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: { numberOfSlides: 1 },
              },
              plugin,
            ],
          },
        }}
      >
        <div className={classes.ordersDiv}>
          <TotalOrders />
        </div>
        <div className={classes.ordersDiv}>
          <TotalFrexcoOrders />
        </div>
        <div className={classes.ordersDiv}>
          <TotalIfoodOrders />
        </div>

        <div style={{ display: 'flex' }}>
          <Divider className={classes.divider} orientation="vertical" flexItem />
          <TotalItems />
          <Divider className={classes.divider} orientation="vertical" flexItem />
        </div>

        <div style={{ display: 'flex' }}>
          <FinishedOrders />

          <Divider className={classes.divider} orientation="vertical" flexItem />
        </div>

        <div style={{ display: 'flex' }}>
          <OrdersInSeparation />

          <Divider className={classes.divider} orientation="vertical" flexItem />
        </div>

        <div style={{ display: 'flex' }}>
          <PendingOrders />
        </div>

        <div style={{ display: 'flex' }}>
          <TotalVolume />
        </div>

        <div style={{ display: 'flex' }}>
          <VolumeProcessed />
        </div>
      </Carousel>

      <Container className={`${classes.root} ${className}`}>
        <TotalOrders />

        <Divider className={classes.divider} />

        <TotalFrexcoOrders />

        <Divider className={classes.divider} />

        <TotalIfoodOrders />

        <Divider className={classes.divider} />

        <TotalItems />

        <Divider className={classes.divider} />

        <FinishedOrders />

        <Divider className={classes.divider} />

        <OrdersInSeparation />

        <Divider className={classes.divider} />

        <PendingOrders />

        <Divider className={classes.divider} />

        <TotalVolume />

        <Divider className={classes.divider} />

        <VolumeProcessed />
      </Container>
    </>
  )
}
