import { useCallback, useEffect, useState } from 'react'
import { Typography, Button, LinearProgress, CircularProgress, Box } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { usePopup } from 'hooks/usePopup'
import { Accept, useDropzone } from 'react-dropzone'
import { Modal } from 'shared'
import { skuConfigService } from 'services/skuConfigService'

import { useStyles } from './styles'
import { bool, boolean } from 'yup'

interface IModalEditMany {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  getSkus: () => Promise<void>
}

export const DialogUpload = ({ isOpen, setIsOpen, getSkus }: IModalEditMany) => {
  const classes = useStyles()
  const { addPopup } = usePopup()
  const [file, setFile] = useState({})
  const [isUploading, setIsUploading] = useState<boolean | null>(null)
  const [fileName, setFileName] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onDropAccepted = useCallback(acceptedFiles => {
    try {
      setIsUploading(true)
      const uploadedFile = acceptedFiles[0]
      setFileName(uploadedFile?.name)
      const formData = new FormData()
      formData.append('file', uploadedFile)
      setFile(formData)
    } finally {
      setIsUploading(false)
    }
  }, [])

  const handleCloseDialog = () => {
    setIsOpen(false)
    setIsLoading(false)
    setIsUploading(null)
    setFile({})
    setFileName('')
  }

  const handleUpdateSkus = async () => {
    try {
      setIsLoading(true)
      await skuConfigService.updateManyProducts(file)
      addPopup({
        type: 'success',
        title: 'Upload realizado com sucesso!',
      })
      handleCloseDialog()
      await getSkus()
    } catch (error: any) {
      const errorMessage = error?.message || error?.msg
      addPopup({
        type: 'error',
        title: 'Eita, deu ruim',
        description: errorMessage || '',
        autoClose: false,
      })
      setIsLoading(false)
    }
  }

  const { getRootProps, getInputProps, isDragAccept, isDragActive, isDragReject } = useDropzone({
    onDropAccepted,
    accept: '.xlsx, .xls, .xml, .csv' as unknown as Accept,
  })

  return (
    <Modal
      title="Alteração em massa para SKUs"
      open={isOpen}
      handleClose={handleCloseDialog}
      style={{ width: '40rem', borderRadius: '1rem' }}
    >
      <Box>
        <Typography>
          O Arquivo deve ser em excel com os dados iniciando na 2° linha e seguir a ordem das
          seguintes colunas:
        </Typography>
        <br />
        <Typography>A: Número do SKU</Typography>
        <Typography>B: % Extra Order Mínimo (0 a 100)*</Typography>
        <Typography>C: % Extra Order Máximo (0 a 100)*</Typography>
        <Typography>D: Etiqueta única? (SIM/NÃO)*</Typography>
        <Typography>E: Precisa de autorização? (SIM/NÃO)*</Typography>
        <Typography>F: ID. Lote? (SIM/NÃO)*</Typography>
        <Typography>G: Etapa de separação*</Typography>
        <br />
        <Typography>* Dados opcionais</Typography>
      </Box>
      <div
        {...getRootProps({
          className: `${classes.dropzone} ${isDragActive && classes.dragActive} ${
            isDragAccept && classes.dragAccept
          } ${isDragReject && classes.dragReject}`,
        })}
      >
        <input {...getInputProps()} disabled={isUploading === true} />
        {isUploading === false ? (
          <Typography style={{ display: 'flex', gap: 10 }}>
            <CheckCircleOutlineIcon />
            Arquivo enviado com sucesso
          </Typography>
        ) : isDragReject ? (
          <Typography>O tipo desse arquivo não é aceito</Typography>
        ) : (
          <Typography>
            {fileName === ''
              ? 'Arrasta o arquivo de excel pra cá ou clica aqui pra buscar ele'
              : fileName}
          </Typography>
        )}
      </div>

      {!isUploading ? (
        <Button
          variant="contained"
          className={classes.btnUpload}
          onClick={handleUpdateSkus}
          disabled={isLoading || isUploading || fileName === ''}
        >
          {isUploading ? (
            <CircularProgress size={24} style={{ color: '#7080df' }} />
          ) : isLoading ? (
            'Enviando, aguarde...'
          ) : (
            'Enviar'
          )}
        </Button>
      ) : (
        <div className={classes.progress}>
          <LinearProgress variant="buffer" value={100} valueBuffer={100} />
        </div>
      )}
    </Modal>
  )
}
