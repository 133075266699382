import { Container, Fab, Tab, Tabs, Typography } from '@material-ui/core'
import { useNavigate } from 'react-router'

import { useStyles } from './styles'
import Charts from './Charts'
import { useState, ChangeEvent } from 'react'
import Breaks from './Breaks'

const Performance = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [tab, setTab] = useState(0)

  const onChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  return (
    <Container className={classes.root}>
      <Typography className={classes.titlePage}>Seu desempenho</Typography>
      <Tabs value={tab} onChange={onChange} centered className={classes.tabs}>
        <Tab label="Dados" />
        <Tab label="Feedback sobre quebras" />
      </Tabs>
      {tab === 0 && <Charts />}
      {tab === 1 && <Breaks />}

      <Fab variant="extended" className={classes.trackLink} onClick={() => navigate('esteira')}>
        Iniciar Separação
      </Fab>
    </Container>
  )
}

export default Performance
