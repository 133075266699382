import { List as ListMui, styled, Dialog as DialogMui, Typography } from '@material-ui/core'
import { mapTagColor } from 'utils/mapTagColor'

export const Dialog = styled(DialogMui)(({ colorRoute }: { colorRoute: string }) => ({
  '& .MuiDialog-paper::after': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '20px',
    background: mapTagColor(colorRoute),
  },
  '& .MuiDialog-paper': {
    maxWidth: '700px',
    width: '100%',
    padding: '2rem 1rem',
  },
}))

export const Title = styled(Typography)({
  fontSize: '1.5rem',
  textAlign: 'center',
  fontWeight: 500,
})

export const Text = styled(Typography)({
  fontSize: '1.1rem',
  fontWeight: 400,
  color: '#757575',
  margin: '1rem 0',
})

export const List = styled(ListMui)({
  width: '100%',
  maxHeight: '200px',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '5px',
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#A1CA4F',
    borderRadius: '9px',
  },
  '& .MuiListItem-root': {
    borderBottom: '1px solid #e0e0e0',
    padding: '1rem',
    '& > p': {
      fontSize: '1.1rem',
    },
  },
})
