import { Grid } from '@material-ui/core'
import { ConfirmReposition, IFormatReplenishment, useReplecement } from 'hooks/useReplecement'
import { useRef, useState, useMemo } from 'react'

import { CardReplenishment } from '../CardReplenishment'
import { CardLoading } from '../CardLoading'
import { ModalReplecementProps, ModalReplecements } from '../ModalReplecements'
import { NoRequest } from '../NoRequest'

const Request = () => {
  const { data, isLoading, handleConfirmReposition } = useReplecement()
  const refModal = useRef<ModalReplecementProps>(null)
  const [separationGroupSelected, setSeparationGroupSelected] = useState<number>(0)

  const confirmReposition = async (data: ConfirmReposition) => {
    await handleConfirmReposition(data)
  }

  const handleDataChange = (separationGroup: number) => {
    setSeparationGroupSelected(separationGroup)
    refModal.current?.handleOpen()
  }

  const dataModal = useMemo<IFormatReplenishment>(() => {
    const hasSeparationGroup = data.find(
      separationGroup => separationGroup.separationGroup === separationGroupSelected,
    )

    return hasSeparationGroup || ({} as IFormatReplenishment)
  }, [separationGroupSelected, data])
  return (
    <>
      {isLoading && (
        <Grid container spacing={3}>
          {Array.from({ length: 3 }).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <CardLoading />
            </Grid>
          ))}
        </Grid>
      )}

      {!isLoading && data.length === 0 && <NoRequest />}

      <Grid container spacing={3}>
        {data.map(item => (
          <Grid item xs={12} sm={6} md={4} key={item.separationGroup}>
            <CardReplenishment handleDataChange={handleDataChange} data={item} />
          </Grid>
        ))}
      </Grid>
      <ModalReplecements
        handleConfirmReposition={confirmReposition}
        data={dataModal}
        ref={refModal}
      />
    </>
  )
}

export default Request
