import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    padding: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    borderRadius: '1.25rem',
    minWidth: '15.75rem',
    width: 'max-content',
    height: '6.25rem',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    background: '#ffffff',
    position: 'relative',
  },
  iconContainer: {
    width: '3.125rem',
    height: '3.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: (props: { color: string }) => props.color,
    borderRadius: '6rem',
  },
  content: {
    paddingTop: '2rem',
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: 500,
    color: '#757575',
    position: 'absolute',
    top: '1.5rem',
  },
  subtitle: {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#353535',
    lineHeight: '1rem',
  },
})
