import {
  IConfirmReplacement,
  IProductResponse,
  IProductRequest,
  IReplenishment,
  enStatusReplenishment,
} from 'interfaces/IRequestProduct'

import { api as apiService, ApiService } from './api'

class ProductRequestService {
  constructor(private readonly api: ApiService) {}

  async getProducts(): Promise<IProductResponse> {
    return this.api.get(carambolaUrl + '/replenishment/items')
  }

  async getReplacementProducts({
    controller,
    ...rest
  }: {
    status?: enStatusReplenishment
    initialSeparationDate?: string
    finalSeparationDate?: string
    userResponse?: string
    replenishmentToday?: boolean
    controller: AbortController
  }): Promise<IReplenishment[]> {
    return this.api.get(carambolaUrl + '/replenishment/', {
      params: rest,
      signal: controller.signal,
    })
  }

  async productRequest(data: IProductRequest): Promise<void> {
    return this.api.post(carambolaUrl + '/replenishment/', data)
  }

  async confirmReplacement(data: IConfirmReplacement): Promise<void> {
    return this.api.patch(carambolaUrl + '/replenishment/', data)
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const productResquestService = new ProductRequestService(apiService)
