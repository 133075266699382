import { makeStyles } from '@material-ui/core/styles'
import { shade } from 'polished'

export const useStyles = makeStyles({
  imageStyle: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    marginLeft: '1rem',
    width: '4rem',
    height: '4rem',
    borderRadius: '3rem',
    overflow: 'hidden',
    background: '#EAEAEA',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '90%',
    },
  },
  imageConfimed: {
    background: '#ffffff',
  },
  infos: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '1.2rem',
  },
  title: {
    color: '#353535',
    fontSize: '1.3rem',
  },
  titleActive: {
    color: '#353535',
    fontSize: '0.875rem',
  },
  quantity: {
    fontSize: '0.875rem',
    color: '#757575',
    '& span': {
      color: '#353535',
      fontWeight: 'bold',
    },
  },
  quantityActive: {
    fontSize: '0.875rem',
    color: '#757575',
    '& span': {
      color: '#353535',
      fontWeight: 'bold',
    },
  },
  obs: {
    fontSize: '1.12rem',
    padding: '1rem',
    color: '#757575',
    '& > span': {
      color: '#353535',
    },
  },
  boxBtn: {
    minWidth: '100px',
    display: 'flex',
    marginTop: '0.6rem',
    justifyContent: 'space-between',
  },
  confirmIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
  },
  confirmBtn: {
    background: '#89BD23',
    transition: 'background 0.2s',
    color: '#ffffff',
    boxShadow: 'none',
    textTransform: 'none',
    height: '3.5rem',
    width: '8rem',
    borderRadius: '0.25rem',
    '&:hover': {
      boxShadow: 'none',
      background: shade(0.1, '#89BD23'),
    },
  },
  branchConfirmBtn: {
    background: '#DA3737',
    transition: 'background 0.2s',
    color: '#ffffff',
    textTransform: 'none',
    boxShadow: 'none',
    height: '3.5rem',
    fontWeight: 600,
    width: '8rem',
    borderRadius: '0.25rem',
    'button + &': {
      marginTop: '0.7rem',
    },
    '&:hover': {
      boxShadow: 'none',
      background: shade(0.1, '#DA3737'),
    },
  },
})
