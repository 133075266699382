import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '1.5rem',
  },
  separatorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    color: '#414141',
    fontSize: '1.125rem',
    '& strong': {
      fontWeight: 500,
      flex: 1,
    },
  },
  table: {
    background: '#ffffff',
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '10px',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A1CA4F',
      borderRadius: '9px',
    },
  },
  instructions: {
    color: '#757575',
    '& > span': {
      color: '#353535',
    },
  },
  timeCount: {
    color: '#75a416',
  },
  content: {
    padding: '2.5rem 2rem',
    minWidth: '790px',
    overflowX: 'auto',
  },
  headerContent: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '50px 1fr 1fr 1fr 1fr 1.2fr',
    color: '#353535',
    fontSize: '1.125rem',
    marginBottom: '1rem',
    '& strong': {
      fontWeight: 500,
      flex: 1,
      color: '#757575',
    },
  },
  accordion: {
    color: '#414141',
    fontWeight: 500,
    '&:not(:last-child)': {
      marginBottom: '1rem',
    },
    '& .MuiAccordionSummary-root': {
      flexDirection: 'row',
      padding: '0.5rem 0',
      '& > .MuiAccordionSummary-content': {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '50px 1fr 1fr 1fr 1fr 80px auto',
        margin: 0,
      },
      '& .MuiAccordionSummary-expandIcon': {
        width: '80px',
        height: '100%',
      },
    },
  },
  alignItemsCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    gap: '1rem',
  },
  containerScroll: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A1CA4F',
      borderRadius: '9px',
    },
  },
  accordionHiddenButton: {
    '& .MuiAccordionSummary-expandIcon': {
      opacity: 0,
    },
  },
  containerButtons: {
    display: 'flex',
    gap: '1rem',
    margin: '1rem auto',
  },
  button: {
    height: '39px',
    width: '130px',
    borderRadius: '4px',
    color: '#FFF',
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    '& p': {
      color: '#757575',
      fontWeight: 500,
    },
  },
  tagIcon: {
    filter: 'invert(65%) sepia(90%) saturate(440%) hue-rotate(40deg) brightness(91%) contrast(85%)',
  },
  containerPrePickings: {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    textAlign: 'center',
    marginBottom: '1rem',
    minWidth: '1200px',
  },
  scrollPrePickings: {
    '&::-webkit-scrollbar': {
      width: '11px',
      height: '11px',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A1CA4F',
      borderRadius: '9px',
    },
  },
}))
