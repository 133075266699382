import { Box, Typography } from '@material-ui/core'
import { Button } from 'components/Button'
import { DownloadExcel } from 'components/ModalDownloadExcelLoading'
import { usePopup } from 'hooks/usePopup'
import { IPackingList, IResponsePackingDict } from 'interfaces/IPackingList'
import { useState } from 'react'
import { AiFillPrinter } from 'react-icons/ai'
import { BsCircleFill } from 'react-icons/bs'
import { RiFileDownloadLine } from 'react-icons/ri'
import { packingList } from 'services/packingListServices'
import { Search } from 'shared'
import { debaunce } from 'utils'

import { IHandleEvent } from '..'

import { IScreenPackingList } from '../Desktop'
import Accordion from './Accordion'
import { ButtonFilter, Header, ContainerSearch, ContainerFilter } from './styles'

const Mobile = ({ routes, handleEvent, params, selectedDate }: IScreenPackingList) => {
  const searchRoute = debaunce({ fn: handleSearch, delay: 200 })
  const [openModalDownloadExcel, setOpenModalDownloadExcel] = useState(false)
  const [isloadingPrint, setIsLoadingPrint] = useState(false)
  const { addPopup } = usePopup()

  function handleSearch(param: string) {
    handleEvent({ param, type: 'search' })
  }

  const handleFilter = (param: string) => {
    handleEvent({ param, type: 'filter' })
  }

  const toggleDownloadExcelBoxesRelation = () => {
    setOpenModalDownloadExcel(state => !state)
  }

  const handleManyPrints = async () => {
    setIsLoadingPrint(true)
    try {
      const routeData = routes
        .filter(({ route }) => !!JSON.parse(route.toString()))
        .map(route => ({
          route: route.route,
          driverName: route.orders[0]?.order_delivery_details?.driver,
          totalBoxes: route.orders.reduce((acc: number, order: IPackingList) => {
            const boxes =
              {
                true: order.corrected_boxes_number || 0,
                false: order.loading_boxes_number || 0,
              }[String(order.skipped)] ?? 0
            return acc + boxes
          }, 0),
        }))
      await packingList.printManyRoutes(routeData)

      addPopup({
        type: 'success',
        title: 'Impressão enviada! 🤠',
      })
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Deu erro pra mandar a impressão 😞',
        description: 'Dá um alô pro time de tech',
      })
    } finally {
      setIsLoadingPrint(false)
    }
  }

  return (
    <>
      <Header>
        <ContainerSearch>
          <Search
            placeholder="Qual rota você busca?"
            onChange={e => {
              searchRoute(e.target.value)
            }}
          />
        </ContainerSearch>

        <ContainerFilter>
          <Typography>Filtrar: </Typography>
          <Box>
            <ButtonFilter
              colorIcon="#2E75FF"
              isSelected={params.filter === 'loaded'}
              onClick={() => handleFilter('loaded')}
            >
              <BsCircleFill />
              <span>Carregado</span>
            </ButtonFilter>
            <ButtonFilter
              colorIcon="#FFC107"
              isSelected={params.filter === 'pending'}
              onClick={() => handleFilter('pending')}
            >
              <BsCircleFill /> <span>Pendente</span>
            </ButtonFilter>
          </Box>
          <Box style={{ display: 'flex', gap: '0.5rem' }}>
            <Button
              onClick={() => handleManyPrints()}
              variant="contained"
              startIcon={<AiFillPrinter size={20} />}
              disabled={isloadingPrint}
            >
              {isloadingPrint ? 'Imprimindo' : 'Imprimir todos'}
            </Button>
            <Button
              onClick={toggleDownloadExcelBoxesRelation}
              variant="contained"
              startIcon={<RiFileDownloadLine size={20} />}
            >
              Baixar planilha
            </Button>
          </Box>
        </ContainerFilter>
      </Header>
      <Box display="flex" flexDirection="column" style={{ gap: '12px', marginTop: '2rem' }}>
        {routes.map(item => {
          if (item.printed) return null
          return <Accordion handleEvent={handleEvent} item={item} key={item.route} />
        })}
      </Box>
      {routes.some(item => item.printed) && (
        <Box
          style={{
            gap: '12px',
            marginTop: '2rem',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography style={{ fontSize: '1.2rem', color: '#c4c4c4', fontWeight: 500 }}>
            Rotas Impressas
          </Typography>
          {routes.map(item => {
            if (!item.printed) return null
            return <Accordion handleEvent={handleEvent} item={item} key={item.route} />
          })}
        </Box>
      )}
      <DownloadExcel
        selectedDate={selectedDate}
        isOpen={openModalDownloadExcel}
        handleCloseModal={toggleDownloadExcelBoxesRelation}
      />
    </>
  )
}

export default Mobile
