import { mapErrors } from 'errors/mapErrors'
import { usePopup } from 'hooks/usePopup'
import { IStep } from 'interfaces/IFastTrack'
import { IOrder } from 'interfaces/IOrder'
import { enRules } from 'interfaces/IUser'
import { IWork } from 'interfaces/IWork'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import fastTrackService from 'services/fastTrackService'
import { naboServices } from 'services/naboServices'
import { separationOrderService } from 'services/separationOrderService'
import { IWorkConference } from 'services/workConference'
import { IState } from 'store'
import {
  clearOrder,
  clearWork,
  updateOrder,
  updatePreviousOrder,
  updateWork,
} from 'store/reducers/orderFastTrack/actions'
import { debaunce } from 'utils'

import { useConferenceChuchu } from '../useConferenceChuchu'
import { useItem } from './useItem'
import { useRecovery } from './useRecovery'

export type ScreenState =
  | 'viewLoadingOrderPending'
  | 'viewLoadingGetOrder'
  | 'viewGetOrder'
  | 'viewOrderReset'
  | 'viewOrderCanceled'
  | 'viewOrderCompleted'
  | 'viewOrderSeparation'
  | 'viewError'
  | 'viewAllOrderCompleted'

interface PropsFast {
  openPallet: () => void
  openScannerTag: () => void
  openAlertBeginner: () => void
  openBoxCountByStep?: (x: { callback: (boxes: number) => Promise<void> }) => void
  closeBoxCountByStep: () => void
}

export interface IError {
  title?: string
  message: string
  retry: (...rest: any) => Promise<void> | void
}

export const useOrderSeparation = (props: PropsFast) => {
  const { openPallet, openScannerTag, openAlertBeginner, openBoxCountByStep, closeBoxCountByStep } =
    props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { addPopup } = usePopup()
  const [searchParams] = useSearchParams()
  const { infos, user, orderFastTrack } = useSelector<IState, IState>(state => state)

  const [screenState, setScreenState] = useState<ScreenState>('viewLoadingOrderPending')
  const productivity = searchParams.get('productivity')
  const nextOrder = searchParams.get('nextOrder')
  const [error, setError] = useState<IError>({ message: '' } as IError)

  // Modais
  const [openBoxCountToPrint, setOpenBoxCountToPrint] = useState(false)

  // Estados do pedido
  const [steps, setSteps] = useState<IStep[]>([])

  const { updateWorkConference } = useConferenceChuchu()

  const { handleConfirmItem, confirmStep } = useItem({
    setSteps,
    setScreenState,
    openBoxCountToPrint: () => setOpenBoxCountToPrint(true),
    openBoxCountByStep: openBoxCountByStep,
    closeBoxCountByStep,
    canceledOrder,
    resetOrder,
    setError,
  })

  const { makeRecovery } = useRecovery({
    setSteps,
    setScreenState,
    openScannerTag,
    openBoxCountToPrint: () => setOpenBoxCountToPrint(true),
    openBoxCountByStep: openBoxCountByStep,
    closeBoxCountByStep,
    openPallet,
    setError,
    confirmStep,
  })

  async function resetOrder() {
    addPopup({ type: 'info', title: 'Pedido resetado' })
    setOpenBoxCountToPrint(false)
    setScreenState('viewOrderReset')
    setSteps([])
  }

  async function canceledOrder() {
    addPopup({
      autoClose: false,
      title: 'Este pedido foi cancelado e os pre pickings foram resetados!',
      type: 'info',
    })
    setScreenState('viewOrderCanceled')
    setSteps([])
  }

  const handleConfirmOrder = async (dataTable: { boxesNumber: number }) => {
    try {
      const { data, message } = await fastTrackService.confirmOrder({
        orderId: orderFastTrack.order.order_id,
        workId: orderFastTrack.work.id,
        boxesNumber: dataTable.boxesNumber,
      })

      setOpenBoxCountToPrint(false)
      dispatch(updatePreviousOrder(orderFastTrack.order))
      clearOrderAndWork()
      setScreenState('viewOrderCompleted')

      if (message === 'separation confirmed and started conference') {
        updateWorkConference(data as IWorkConference)
        navigate(`/operacao/conferencia/${orderFastTrack.order.order_id}`)
        return
      }
      if (user.userPermissions.includes(enRules.SeparationCriticalOrBeginner)) {
        openAlertBeginner()
      }
    } catch (err: any) {
      if (err?.message === 'This order has been canceled') {
        await canceledOrder()
        setOpenBoxCountToPrint(false)
      } else if (err?.message === 'Order reseted') {
        resetOrder()
      } else {
        throw err
      }
    }
  }

  const handleSetOrderData = async (work: IWork, newSteps: IStep[], orderData: IOrder) => {
    dispatch(updateOrder(orderData))
    dispatch(updateWork(work as any))
    setSteps(newSteps)
    setScreenState('viewOrderSeparation')

    if (orderData.is_palletized) {
      openPallet()
    }
  }

  const startPolling = async () => {
    try {
      setScreenState('viewLoadingGetOrder')
      const { message, work, order, steps, ...rest } = await fastTrackService.getNewOrderPooling({
        step: infos.separation as number,
        separationGroup: infos.runningMachine,
      })
      if (message === 'Success') {
        await handleSetOrderData(work, steps, { ...order, ...rest })
      }
    } catch (err: any) {
      if (err?.message === 'Orders not found') {
        setScreenState('viewAllOrderCompleted')
        clearOrderAndWork()
      } else if (err?.message === 'Pedido ainda não encontrado, tente novamente!') {
        setTimeout(() => startPolling(), 4000)
      } else {
        setScreenState('viewError')
        setError({
          title: 'Erro ao buscar um novo pedido',
          message: mapErrors(error?.message),
          retry: startPolling,
        })
      }
    }
  }

  const handleNextOrder = async (): Promise<void> => {
    try {
      setScreenState('viewLoadingGetOrder')
      const { message, work, order, steps, ...rest } = await separationOrderService.newOrder({
        step: infos.separation as number,
        separationGroup: infos.runningMachine,
      })
      if (message === 'Requisição enviada com sucesso!') {
        startPolling()
      } else if (message === 'Success') {
        await handleSetOrderData(work, steps, { ...order, ...rest })
      }
    } catch (error: any) {
      if (error?.remaining === 0 || error?.message === 'Orders not found') {
        setScreenState('viewAllOrderCompleted')
        clearOrderAndWork()
      } else if (error?.message === 'Network Error') {
        setScreenState('viewError')
        setError({
          title: 'Sem conexão',
          message: mapErrors(error?.message),
          retry: handleNextOrder,
        })
      } else {
        setScreenState('viewError')
        setError({
          title: 'Erro ao buscar um novo pedido',
          message: mapErrors(error?.message),
          retry: handleNextOrder,
        })
      }
    }
  }

  const clearOrderAndWork = () => {
    dispatch(clearOrder())
    dispatch(clearWork())
  }

  const debaunceOrder = debaunce({ fn: handleNextOrder, delay: 400 })

  const getOrderId = async (): Promise<void> => {
    setScreenState('viewLoadingGetOrder')
    clearOrderAndWork()
    debaunceOrder()
  }

  const checkTrackAndSeparation = () => {
    if (!infos.runningMachine) {
      navigate('/operacao/separacao/esteira')
    } else if (productivity) {
      setScreenState('viewOrderSeparation')
      searchParams.delete('productivity')
    } else if (nextOrder) {
      getOrderId()
      searchParams.delete('nextOrder')
    } else {
      makeRecovery()
    }
  }

  useEffect(() => {
    checkTrackAndSeparation()
  }, [])

  return {
    getOrderId,
    steps,
    screenState,
    error,
    openBoxCountToPrint,
    resetOrder,
    handleConfirmItem,
    handleConfirmOrder,
    setOpenBoxCountToPrint,
  }
}
