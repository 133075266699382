import { Container, Box, Typography } from '@material-ui/core'
import LogoFrexco from 'assets/images/logo-frexco.svg'
import { usePrePicking } from 'hooks/usePrePicking'
import { IProduct } from 'interfaces/IProduct'
import { ISeparationGroupConfig } from 'interfaces/ISeparationGroupService'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { ImQrcode } from 'react-icons/im'
import { useSelector } from 'react-redux'
import { IDataConfirmItem } from 'services/separationItemService'
import { IState } from 'store'

import { Button } from '../'
import AlertWeighins from './AlertWeighins'
import Cam from './Cam'
import ContainerWeighings from './ContainerWeighings'
import Loading from './Loading'
import ModalError from './ModalError'
import { ModalExclude, IModalExclude } from './ModalExclude'
import ModalPermAdmin from './ModalPermAdmin'
import { InvisibleInput, useStyles } from './styles'
import { useLostPrePicking } from './useLostPrePicking'
import { usePermAdmin } from './usePermAdmin'
import { useScanner } from './useScanner'

interface CardProductScannerProps {
  handleBranch: (weighings?: number[]) => void
  handleConfirm: (x: Omit<IDataConfirmItem, 'itemId'>) => Promise<IProduct | void>
  loading: boolean
  product: IProduct
  orderId: string
  resetOrder?: () => Promise<void>
}

export const CardProductScanner = ({
  handleBranch,
  handleConfirm,
  loading: propsLoading,
  product,
  orderId,
  resetOrder,
}: CardProductScannerProps) => {
  const { pre_boxed, unity_product_base } = product
  const modalExcludeRef = useRef<IModalExclude>(null)
  const classes = useStyles()
  const inputRef = useRef<HTMLInputElement>(null)
  const intervalRef = useRef<any>(null)
  const [lockScanner, setLockScanner] = useState(false)
  const [camScanner, infraredScanner] = useSelector<IState, [boolean, boolean]>(state => {
    const numberSeparationGroup = state.infos.runningMachine as number
    const { cam_scanner, infrared_scanner } = state.settings.separationGroups.find(
      ({ separation_group }) => Number(separation_group) === Number(numberSeparationGroup),
    ) as ISeparationGroupConfig
    return [cam_scanner, infrared_scanner]
  })

  const { checkIsBarcodeInfrared } = usePrePicking()

  const {
    handleCloseModalAdmin,
    handleOnConfirm,
    handleOpenModalAdmin,
    openModalAdmin,
    checkAuthorization,
    isAuthorized,
  } = usePermAdmin(product, resetOrder)

  const { isLock, startPolling } = useLostPrePicking()

  const {
    handleBreak,
    handleExclude,
    handleResetAllScanneds,
    handleSubmitQr,
    isTypeUnity,
    loading,
    modalErrorRef,
    scanneds,
  } = useScanner({
    product,
    propsLoading,
    handleBranch,
    orderId,
    handleConfirm,
    isAuthorized,
    startPolling,
  })

  const handleScanScanner = (param: FormEvent<HTMLElement>) => {
    const formData = new FormData(param.target as HTMLFormElement)
    param.preventDefault()
    const inputValue = formData.get('inputValue')
    const isBarCode = checkIsBarcodeInfrared(String(inputValue))
    handleSubmitQr(String(inputValue), isBarCode ? 'bar_code' : 'qr_code')
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  useEffect(() => {
    checkAuthorization(product.id, true)
    intervalRef.current = setInterval(() => {
      if (inputRef.current) {
        inputRef.current?.focus()
      }
    }, 200)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  const handleOpenModalExclude = () => {
    modalExcludeRef.current?.onOpen()
    setLockScanner(true)
  }

  const showInfraRedScanner = infraredScanner && !lockScanner && !openModalAdmin.open && !isLock
  const showCamScanner = camScanner && !lockScanner && !openModalAdmin.open && !isLock

  return (
    <Box id="card-product-scanner" paddingBottom="1rem">
      {showInfraRedScanner && (
        <form onSubmit={handleScanScanner}>
          <InvisibleInput
            type="text"
            inputRef={inputRef}
            hidden
            name="inputValue"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
        </form>
      )}
      <Container className={classes.container}>
        <Box className={classes.boxContainerInfo}>
          <Box>
            <img src={product.image ? product.image : LogoFrexco} alt="" className={classes.img} />
            <Typography className={classes.typography}>
              <strong>Produto Base:</strong> {product?.product_base_id}
            </Typography>
            <Typography className={classes.typography}>
              <strong>Produto:</strong> {product?.name}
            </Typography>
            <Typography className={classes.typography}>
              <strong>Quantidade:</strong> {pre_boxed && `${product?.quantity} Unidades`}
              {!pre_boxed &&
                `${product?.converted_quantity || product?.quantity} ${
                  isTypeUnity ? 'Unidades' : 'Kg'
                }`}
            </Typography>

            {pre_boxed && unity_product_base === 'unidade' && (
              <Typography className={classes.typography}>
                <strong>Quantidade total:</strong>{' '}
                {`${product?.converted_quantity || product?.quantity} Unidades`}
              </Typography>
            )}

            {pre_boxed && unity_product_base === 'peso' && (
              <Typography className={classes.typography}>
                <strong>Peso total:</strong>{' '}
                {`${product?.converted_quantity || product?.quantity} Kg`}
              </Typography>
            )}
          </Box>
          <AlertWeighins product={product} scanneds={scanneds} isAuthorized={!!isAuthorized} />
          <Box className={classes.boxButtons}>
            <Button
              className={classes.button}
              style={{ background: '#EB001B' }}
              onClick={handleBreak}
              disabled={isLock}
            >
              Quebra
            </Button>
            <Button
              className={classes.button}
              style={
                isAuthorized !== null && !isLock ? { color: 'white' } : { background: '#2E75FF' }
              }
              onClick={handleOpenModalAdmin}
              disabled={isLock || openModalAdmin.type === 'waiting' || isAuthorized !== null}
            >
              Bipar outro SKU
            </Button>
          </Box>
        </Box>
        <Box className={classes.boxContainerList}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <ContainerWeighings isTypeUnity={isTypeUnity} scanneds={scanneds} />
              {scanneds.totalValue > 0 && (
                <Button
                  className={classes.buttonSecondary}
                  onClick={handleOpenModalExclude}
                  style={{ color: '#EB001B', textAlign: 'start' }}
                  disabled={isLock}
                >
                  Excluir um item
                </Button>
              )}
            </>
          )}
          <Box className={classes.boxContainerTotal}>
            <Typography>
              <strong>Total:</strong>{' '}
              {isTypeUnity ? `${scanneds.totalValue} Un` : `${scanneds.totalValue.toFixed(3)} Kg`}
            </Typography>
            {scanneds.totalValue > 0 && (
              <Button
                className={classes.buttonSecondary}
                onClick={handleResetAllScanneds}
                style={{ color: '#587916' }}
                disabled={isLock}
              >
                Limpar
              </Button>
            )}
          </Box>
        </Box>
        <ModalError product={product} ref={modalErrorRef} />
        <ModalExclude
          onSubmit={handleExclude}
          setLockScanner={setLockScanner}
          ref={modalExcludeRef}
        />
        <ModalPermAdmin
          product={product}
          onConfirm={handleOnConfirm}
          onClose={handleCloseModalAdmin}
          open={openModalAdmin.open}
          quantity={!isAuthorized && !isTypeUnity ? scanneds.products.length : scanneds.totalValue}
          type={openModalAdmin.type}
        />
      </Container>
      {showCamScanner && <Cam onSubmit={handleSubmitQr} />}
      {showInfraRedScanner && (
        <ImQrcode
          size={150}
          color="#757575"
          style={{ margin: '2rem auto 0 auto', display: 'block' }}
        />
      )}
    </Box>
  )
}
