import {
  Divider as DividerMui,
  styled,
  FormControlLabel as FormControlLabelMui,
} from '@material-ui/core'

export const Divider = styled(DividerMui)(({ theme }) => ({
  marginLeft: '0.05rem',
  background: '#ccc',
  border: '1px solid #ccc',
  height: '1.5rem',
}))

export const FormControlLabel = styled(FormControlLabelMui)(({ theme }) => ({
  border: '1px solid #ccc',
  borderRadius: '4px',
  width: '100%',
  margin: '0',
  display: 'flex',
  justifyContent: 'flex-start',
  minWidth: '230px',
  height: '100%',
}))

export const ContainerIconImage = styled('span')(({ isRed }: { isRed?: boolean }) => ({
  width: '25px',
  height: '25px',
  borderRadius: '6rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '0.7rem',
  color: isRed ? '#DA3737' : '#618619',
  '& > img': {
    width: '1.1rem',
  },
  '& > svg': {
    width: '1.5rem',
  },
}))
