import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  boxDate: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '10rem',
    border: '1px solid #C4C4C4',
    padding: '0.9rem 1rem',
  },
})
