import { Typography } from '@material-ui/core'

import { useStyles } from './styles'

interface ILoading {
  textLoading: string
}

export const LoadingSearch = ({ textLoading }: ILoading) => {
  const classes = useStyles()

  return (
    <div className={classes.container} data-cy="loading-order">
      <Typography className={classes.text}>{textLoading}</Typography>
    </div>
  )
}
