import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { CSSProperties, useState, useRef } from 'react'
import { TableContainer } from '../styles'
import { SubTitle } from '../../CriticalOrders/styles'
import { IOption, IRouteOverResponseExt } from '../useRoutesOver'
import MenuItemRoutesOver from './MenuItemRoutesOver'

interface IPropsTableRoutesOver {
  data: IRouteOverResponseExt[]
  onClickRoute: (route: IRouteOverResponseExt) => void
  subtitle: string
  styleContainer?: CSSProperties
  options: IOption[]
}

const TableRoutesOver = (props: IPropsTableRoutesOver) => {

  return (
    <Box style={props.styleContainer || {}}>
      <SubTitle style={{ marginTop: '4rem' }}>{props.subtitle}</SubTitle>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rota</TableCell>
              <TableCell>Máximo veiculo</TableCell>
              <TableCell>Quantidade atual</TableCell>
              <TableCell>Quantidade a diminuir</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map(route => (
              <TableRow
                key={route.route}
                onClick={() => {
                  props.onClickRoute(route)
                }}
              >
                <TableCell>ROTA {route.route}</TableCell>
                <TableCell>{route.capacity_vehicle} caixas</TableCell>
                <TableCell>{route.real_boxes_number} caixas</TableCell>
                <TableCell>{route.quantityToDecrease}</TableCell>
                <TableCell>
                 <MenuItemRoutesOver options={props.options} route={route}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default TableRoutesOver
