import { Runouts } from 'interfaces/IBreakOrder'
import { ICheckResponse, IProduct, IProductResponse } from 'interfaces/IProduct'
import { ICheckItems, ISeparationItem, enStatusItem } from 'interfaces/ISeparationItem'

import { TValue } from '../components/CardProductScanner/useScanner'
import { IPrePickingV2 } from '../interfaces/IPrePickingV2'
import { api as apiService, ApiService } from './api'

export interface IDataConfirmItem {
  itemId: number
  statusId?: enStatusItem
  quantitySeparated?: number
  productCode?: number | string
  codeType?: 'data_matrix' | 'bar_code' | 'qr_code'
}

export interface IStepPickItem {
  step: number
  is_finished: boolean
  items: IProduct[]
}

type IReplenishItem = {
  product_code: number | string
  code_type: 'barcode' | 'qrcode'
}

export interface IGetItensToReposition {
  order_id: string
  client_name: string
  checking_status: number
  released_by: string
  is_old_order: boolean
  runouts: Runouts[]
}

export interface IDataRepositionItem {
  productsList?: IReplenishItem[]
  totalRepositionAmount?: number
}

class SeparationItemServiceV2 {
  constructor(private readonly api: ApiService) {}

  public async getItem(
    data: ISeparationItem,
    controller: AbortController,
  ): Promise<IProductResponse> {
    return await this.api.get(carambolaUrl + '/separation/separation-item/', {
      params: data,
      signal: controller.signal,
    })
  }

  public async checkItems(data: ICheckItems): Promise<ICheckResponse> {
    return await this.api.get(carambolaUrl + '/separation/separation-item/recovery', {
      params: data,
    })
  }

  public async pickItem(data: IDataConfirmItem): Promise<IStepPickItem[]> {
    return await this.api.post(carambolaUrl + `/separation/item/${data.itemId}/pick-item`, data)
  }

  public async pickReset(
    itemId: number,
    data?: { productCode: string; codeType: TValue },
  ): Promise<{ id?: number; orderPrePickingId?: number; orderItemId?: number }> {
    return await this.api.delete(carambolaUrl + `/separation/item/${itemId}/pick-item/reset`, {
      data,
    })
  }

  public async pickResetAll(
    itemId: number,
    data?: { resetInReposition: boolean },
  ): Promise<IStepPickItem[]> {
    return await this.api.delete(carambolaUrl + `/separation/item/${itemId}/pick-item/reset-all`, {
      data,
    })
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const separationItemServiceV2 = new SeparationItemServiceV2(apiService)
