import { Typography } from '@material-ui/core'
import { AiOutlineUser } from 'react-icons/ai'

import { useStyles } from './styles'

interface Props {
  className?: string
}

export const ClientCritical = ({ className }: Props) => {
  const classes = useStyles()

  return (
    <Typography className={`${classes.root} ${className}`}>
      <AiOutlineUser fontSize={25} /> Cliente crítico
    </Typography>
  )
}
