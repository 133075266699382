import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  itemTable: {
    position: 'relative',
    background: '#FCFCFC',
    borderRadius: '6px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    marginTop: '1rem',
    cursor: 'pointer',
    maxHeight: '75px',
    minHeight: '55px',
    '&:hover': {
      background: '#FFFFFF',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.10)',
    },
    '&:not(:first-child)': {
      borderTop: '3px solid #fff',
    },
    '& .MuiTableCell-root': {
      height: '65px',
      padding: '0 1rem',
    },
  },
  clientCritical: {
    background: '#fceded',
  },
  circleActive: {
    position: 'static',
    margin: '0 auto',
  },
})
