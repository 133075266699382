import { Accordion, Box, Button, IconButton, Typography, Tooltip, SvgIcon } from '@material-ui/core'
import { CircleActive } from 'components/CircleActive'
import { MdEdit, MdQrCode } from 'react-icons/md'
import { AccordionDetails, AccordionSummary } from 'shared'
import { mappingType } from 'utils'

import { usePopup } from 'hooks/usePopup'
import { dashboardService } from '../../../../services/dashboardService'
import { IFormattedItems } from '../SeparationItem'
import { ReactComponent as TagIcon } from 'assets/icons/tag.svg'
import { States } from './States'
import { useStyles } from './styles'
import { useAlertNotification } from 'hooks/useAlertNotification'
import { useSelector } from 'react-redux'
import { IState } from 'store'
import { IAlertResponse, IPrePickingBreakReport } from 'interfaces/IAlert'
import { IOrderItem, IPrePickingOrderItem } from '../../../../interfaces/IOrderItem'
import { useState } from 'react'
import moment from 'moment'

interface IPrePickingItem {
  tag_id: number
  quantity: number
  separatedAt: string
  created: string
  batch_id: string
  scale_id: string
  scale_label_id: string
  weighing_time: string
  unique_tag: boolean
}

export const Separator = (props: IFormattedItems) => {
  const { setItem, products } = props
  const [prePickings, setPrePickings] = useState<{
    [keyof in string]: IOrderItem<IPrePickingItem>
  }>({})
  const alerts = useSelector<IState, IAlertResponse[]>(state => state.alert)
  const controller = new AbortController()
  const classes = useStyles()
  const { addPopup } = usePopup()
  const { handleAnswerBreak } = useAlertNotification(false)
  const [expandedStates, setExpandedStates] = useState<{ [productId: string]: boolean }>({})

  const getOrderItemPrePickingMetrics = async (orderItemId: number) => {
    try {
      const results = await dashboardService.getOrderItemPrePickingData(orderItemId, { controller })
      setPrePickings(state => ({
        ...state,
        [orderItemId]: {
          ...results,
          pre_pickings: formatPrePickings(results?.pre_pickings),
        },
      }))
    } catch (err: any) {
      const msg = err?.msg || 'Erro ao buscar dados do item'
      addPopup({
        type: 'error',
        title: msg,
      })
    }
  }

  const formatPrePickings = (param: IPrePickingOrderItem[]): IPrePickingItem[] => {
    return (
      param.reduce((acc: IPrePickingItem[], prePicking) => {
        if (prePicking.unique_tag) {
          const data: IPrePickingItem = {
            batch_id: prePicking.batch_id ? String(prePicking.batch_id) : '',
            created: prePicking.created,
            quantity: Number(prePicking.item_weight || prePicking.purchase_value),
            scale_id: prePicking.scale_id || '',
            scale_label_id: prePicking.scale_label_id || '',
            separatedAt: prePicking?.orders[0]?.created,
            tag_id: prePicking.id,
            weighing_time: prePicking.weighing_time || '',
            unique_tag: prePicking.unique_tag,
          }

          return [...acc, data]
        }

        const prePickingsOrders: IPrePickingItem[] = prePicking.orders.map(item => ({
          batch_id: prePicking.batch_id ? String(prePicking.batch_id) : '',
          created: prePicking.created,
          quantity: Number(prePicking.item_weight || prePicking.purchase_value),
          scale_id: prePicking.scale_id || '',
          scale_label_id: prePicking.scale_label_id || '',
          separatedAt: item.created,
          tag_id: prePicking.id,
          weighing_time: prePicking.weighing_time || '',
          unique_tag: prePicking.unique_tag,
        }))

        return [...acc, ...prePickingsOrders]
      }, []) || []
    )
  }

  return (
    <Box className={classes.containerScroll}>
      <Box className={classes.content}>
        <Box component="header" className={classes.headerContent}>
          <strong style={{ textAlign: 'left' }}></strong>
          <strong style={{ textAlign: 'left' }}>Produto</strong>
          <strong style={{ textAlign: 'center' }}>Quantidade</strong>
          <strong style={{ textAlign: 'center' }}>Quantidade confirmada</strong>
          <strong style={{ textAlign: 'center' }}>Status</strong>
          <strong style={{ textAlign: 'right' }}></strong>
          <strong style={{ textAlign: 'right' }}></strong>
        </Box>

        <Box style={{ background: '#ffffff' }}>
          {products.map(product => {
            const quantity: number = product?.converted_quantity || product?.quantity
            const prePickingsData = prePickings[product.id]
            const alertPending = alerts.find(
              alert =>
                Number((alert.related_alert as IPrePickingBreakReport)?.item?.id) ===
                Number(product.id),
            )

            return (
              <Accordion
                className={`${classes.accordion} ${
                  alertPending ? '' : classes.accordionHiddenButton
                }`}
                key={product?.id}
                expanded={expandedStates[product?.id] || !!alertPending}
                onClick={() => {
                  if (!product?.hasPrePicking) return
                  setExpandedStates(prevState => ({
                    ...prevState,
                    [product?.id]: !prevState[product?.id],
                  }))
                  getOrderItemPrePickingMetrics(product?.id)
                }}
              >
                <AccordionSummary>
                  <div className={classes.alignItemsCenter}>
                    {!!alertPending && <CircleActive color="#EB001B" />}
                  </div>
                  <div
                    className={classes.alignItemsCenter}
                    style={{ justifyContent: 'flex-start' }}
                  >
                    <img loading="lazy" src={product.image} style={{ width: 60, height: 60 }} />{' '}
                    <span>{product?.name}</span>
                  </div>
                  <div className={classes.alignItemsCenter}>
                    {quantity !== 0
                      ? product.unity_product_base === 'peso'
                        ? `${quantity.toFixed(3)} ${mappingType(product?.unity_product_base)}`
                        : `${quantity} ${mappingType(product?.unity_product_base)}`
                      : null}
                  </div>
                  <div className={classes.alignItemsCenter} style={{ justifySelf: 'right' }}>
                    {' '}
                    {product.confirmed_quantity
                      ? `${
                          product?.unity_product_base === 'peso'
                            ? product.confirmed_quantity.toFixed(3)
                            : product.confirmed_quantity
                        } ${mappingType(product?.unity_product_base)}`
                      : null}{' '}
                    {product?.hasPrePicking && (
                      <Tooltip
                        title={
                          prePickingsData?.metrics?.unique_tag_beeped_percentual
                            ? `${prePickingsData?.metrics?.unique_tag_beeped_percentual}% Etiqueta única`
                            : 'Clique para carregar as informações'
                        }
                        arrow
                        placement="bottom"
                      >
                        <div className={classes.alignItemsCenter}>
                          <SvgIcon component={TagIcon} className={classes.tagIcon} />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <div className={classes.alignItemsCenter}>
                    <States state={product?.status || 1} />
                  </div>
                  <div className={classes.alignItemsCenter}>
                    {product?.status !== 3 ? (
                      <IconButton
                        onClick={e => {
                          e.stopPropagation()
                          !!setItem && setItem(product)
                        }}
                      >
                        <MdEdit size="1.5rem" />
                      </IconButton>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={classes.alignItemsCenter}>
                    {product?.reader ? (
                      <Tooltip title="Scaneamento ativo" arrow placement="bottom">
                        <div className={classes.alignItemsCenter}>
                          <MdQrCode color="green" size="1.5rem" />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Scaneamento desativado" arrow placement="bottom">
                        <div className={classes.alignItemsCenter}>
                          <MdQrCode color="red" size="1.5rem" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  {alertPending && (
                    <Box display="flex" flexDirection="column">
                      <Typography style={{ textAlign: 'center' }}>
                        Autorizar bipagem de outro SKU?
                      </Typography>
                      <Box className={classes.containerButtons}>
                        <Button
                          className={classes.button}
                          style={{ background: '#89BD23' }}
                          onClick={() => {
                            alertPending && handleAnswerBreak(alertPending, 'accept')
                          }}
                        >
                          Autorizar
                        </Button>
                        <Button
                          className={classes.button}
                          style={{ background: '#EB001B' }}
                          onClick={() => {
                            alertPending && handleAnswerBreak(alertPending, 'declined')
                          }}
                        >
                          Recusar
                        </Button>
                      </Box>
                    </Box>
                  )}
                  <Box overflow="auto" paddingBottom="16px" className={classes.scrollPrePickings}>
                    <Box className={classes.containerPrePickings}>
                      <Typography>ID da etiqueta</Typography>
                      <Typography>Quantidade referente</Typography>
                      <Typography>Separado em</Typography>
                      <Typography>Criado em</Typography>
                      <Typography>batch_id</Typography>
                      <Typography>scale_id</Typography>
                      <Typography>scale_label_id</Typography>
                      <Typography>weighing_time</Typography>
                    </Box>
                    {prePickingsData?.pre_pickings.map(prePicking => {
                      const weighing_time = prePicking?.weighing_time
                        ? moment(prePicking.weighing_time).format('DD/MM/YYYY HH:mm:ss')
                        : ''

                      return (
                        <Box
                          key={prePicking.separatedAt}
                          onClick={e => e.stopPropagation()}
                          className={classes.containerPrePickings}
                        >
                          <Typography>{prePicking.tag_id}</Typography>
                          <Typography>{prePicking.quantity}</Typography>
                          <Typography>
                            {moment(prePicking.separatedAt).format('DD/MM/YYYY HH:mm:ss')}
                          </Typography>
                          <Typography>
                            {moment(prePicking.created).format('DD/MM/YYYY HH:mm:ss')}
                          </Typography>
                          <Typography>{prePicking.batch_id}</Typography>
                          <Typography>{prePicking.scale_id}</Typography>
                          <Typography>{prePicking.scale_label_id}</Typography>
                          <Typography>{weighing_time}</Typography>
                        </Box>
                      )
                    })}
                  </Box>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}
