import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  iconButton: {
    width: '3.2rem',
    height: '3.2rem',
    background: '#f7f7f7',
  },
  icon: {
    color: '#89BD23',
  },
  iconWithAnimation: {
    color: '#89BD23',
    animation: '$breathing 8s  linear infinite',
  },
  '@keyframes breathing': {
    '0%': { width: '150%' },
    '50%': { width: '80%' },
    '100%': { width: '150%' },
  },
  iconOff: {
    color: '#A2A2A2',
  },
  sendHelpButton: {
    minWidth: '9.5rem',
    borderRadius: '8px',
    color: '#FFFFFF',
    backgroundColor: '#EB001B',
    '&:hover': {
      backgroundColor: '#C40017',
    },
  },
  cancelSendHelpButton: {
    minWidth: '9.5rem',
    borderRadius: '8px',
    color: '#FFFFFF',
    backgroundColor: '#353535',
    '&:hover': {
      backgroundColor: '#1e1c1ceb',
    },
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-child)': {
      borderBottom: '1px solid #C4C4C4',
    },
  },
  boxNotification: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    marginLeft: '1rem',
  },
  textInfo: {
    fontSize: '1rem',
    fontWeight: 500,
    fontFamily: 'Roboto',
    color: '#757575',
    '& strong': {
      color: '#000000',
    },
  },
  bsTextInfo: {
    fontSize: '1rem',
    fontWeight: 500,
    fontFamily: 'Roboto',
    color: '#757575',
    '& strong': {
      color: '#000000',
    },
    '&:before': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      width: '10px',
      height: '10px',
      background: '#EB001B',
      borderRadius: '50%',
      left: '10px',
      top: '35px',
    },
  },
  buttonClearNotification: {
    color: '#618619',
    background: 'none',
    width: '100%',
  },
})
