import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Typography } from '@material-ui/core'
import { Button } from 'components'
import { enModalStatus } from 'interfaces/enModal'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { IPrint, IReprint, printerService } from 'services/printerService'
import { Input, Modal } from 'shared'
import * as Yup from 'yup'

import { useStyles } from './styles'

const validateSchema = Yup.object().shape({
  qtyTag: Yup.number()
    .typeError('Quantidade de etiquetas obrigatório')
    .required('Quantidade de etiquetas obrigatório'),
})

interface Props {
  open: boolean
  handleClose: () => void
  orderId: string | null
  track: number | null
}

export const ModalPrinter = (props: Props) => {
  const { orderId, track, handleClose, open } = props
  const classes = useStyles()
  const [status, setStatus] = useState<enModalStatus>(enModalStatus.initial)
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(validateSchema),
  })

  const { errors } = formState

  const handleClearStates = () => {
    handleClose()
    reset({ qtyTag: undefined })
    setStatus(enModalStatus.initial)
  }

  const handleConfirm = async (data: { qtyTag: number }) => {
    try {
      setStatus(enModalStatus.pending)
      await printerService.print({
        orderId,
        reprint: true,
        boxQuantity: data.qtyTag,
        printerChoice: 1,
      } as IPrint)
      setStatus(enModalStatus.success)
    } catch {
      setStatus(enModalStatus.error)
    }
  }

  return (
    <Modal
      open={open}
      title="Etiquetas"
      titleSuccess="Deu bão na Impressão!"
      titleError="Deu erro pra imprimir ):"
      status={status}
      handleClose={handleClearStates}
      handleSend={() => setStatus(enModalStatus.initial)}
    >
      <Typography variant="h1" className={classes.title}>
        Aow trem bão! Quer reimprimir umas etiquetas? 🤠
      </Typography>

      <Typography variant="subtitle1" className={classes.subTitle}>
        Fala pra nós pra quantas caixas cê ta querendo:
      </Typography>

      <Box component="form" onSubmit={handleSubmit(handleConfirm)} className={classes.form}>
        <Input
          type="number"
          variant="outlined"
          className={classes.input}
          label="Quantidade de caixas a mais"
          error={!!errors?.qtyTag}
          helperText={errors?.qtyTag?.message}
          {...register('qtyTag')}
        />

        <Button type="submit" className={classes.button} variant="contained">
          Prontin, Imprime aí!
        </Button>
      </Box>
    </Modal>
  )
}
