import { Tab, Tabs, makeStyles } from '@material-ui/core'
import { ContainerPage, TitlePage } from 'components'
import { ChangeEvent, useState } from 'react'
import Request from './Requests'
import History from './History'

const useStyles = makeStyles(theme => ({
  tabs: {
    marginBottom: '2.5rem',
    '& .MuiTab-wrapper': {
      fontWeight: 500,
      textTransform: 'none',
      fontSize: '1.1rem',
    },
    '& .MuiTabs-indicator': {
      height: '5px',
      backgroundColor: '#567B0D',
      border: 'none',
      borderRadius: '5px',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: '#353535',
    },
    '& .MuiTab-textColorInherit': {
      color: '#A3A3A3',
    },
  },
}))

const Replecement = () => {
  const [tab, setTab] = useState(0)
  const classes = useStyles(tab)

  const onChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }
  return (
    <>
      <ContainerPage>
        <TitlePage>Reabastecimento</TitlePage>
        <Tabs value={tab} onChange={onChange} centered className={classes.tabs}>
          <Tab label="Solicitações" />
          <Tab label="Histórico" />
        </Tabs>
        {tab === 0 && <Request />}
        {tab === 1 && <History />}
      </ContainerPage>
    </>
  )
}

export default Replecement
