import {
  Paper as PaperMui,
  Typography,
  styled,
  Button as ButtonMui,
  Tabs as TabsMui,
} from '@material-ui/core'
import { TitlePage as TitlePageComponents } from 'components'

export const ContainerPage = styled(PaperMui)(({ theme }) => ({
  maxWidth: '900px',
  margin: '0 auto 0 auto',
  padding: '1rem',
  height: 'calc(100vh - 6.25rem - 5px)',
  display: 'flex',
  flexDirection: 'column',
  background: 'transparent',
  boxShadow: 'none',
}))

export const TitlePage = styled(TitlePageComponents)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
})

export const Tabs = styled(TabsMui)(({ theme }) => ({
  marginBottom: '2.5rem',
  '& .MuiTab-wrapper': {
    fontWeight: 500,
    textTransform: 'none',
    fontSize: '1.1rem',
  },
  '& .MuiTabs-indicator': {
    height: '5px',
    backgroundColor: '#567B0D',
    border: 'none',
    borderRadius: '5px',
  },
  '& .MuiTab-textColorInherit.Mui-selected': {
    color: '#353535',
  },
  '& .MuiTab-textColorInherit': {
    color: '#A3A3A3',
  },
}))
