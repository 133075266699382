import { IOrderResponse } from 'interfaces/IOrder'
import { INewSaparationOrder, ISeparationOrderResponse } from 'interfaces/ISeparationOrderService'

import { api as apiService, ApiService } from './api'

class SeparationOrderService {
  constructor(private readonly api: ApiService) {}

  public async getOrder(orderId: string): Promise<ISeparationOrderResponse> {
    return await this.api.get(carambolaUrl + '/separation/step', {
      params: {
        orderId,
      },
    })
  }

  public async newOrder(
    data: INewSaparationOrder,
    controller?: AbortController,
  ): Promise<IOrderResponse> {
    return await this.api.get(carambolaUrl + '/separation/order-separation/new/request', {
      params: { ...data, step: data.step || 1 },
      signal: controller?.signal,
    })
  }

  public async confirmOrderLoad(orderId: string, controller: AbortController): Promise<void> {
    return await this.api.patch(carambolaUrl + `/reposition/release-order/${orderId}`, {
      signal: controller.signal,
    })
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const separationOrderService = new SeparationOrderService(apiService)
