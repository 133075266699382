import {
  Box,
  Container,
  LinearProgress,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Select } from 'shared'
import { FormControl } from 'shared/Select/FormControl'
import { useStyles } from './styles'
import { useState, useEffect } from 'react'
import CircularProgress from './CircularProgress'
import { separatorService } from 'services/separatorService'
import { useSelector } from 'react-redux'
import { IState } from 'store'
import moment from 'moment'
import { usePopup } from 'hooks/usePopup'
import { IFeedbackSeparator } from 'interfaces/ISeparator'

type IFilter = 'today' | 'week' | 'month'

const Breaks = () => {
  const controller = new AbortController()
  const { addPopup } = usePopup()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState<IFilter>('today')
  const userId = useSelector<IState, number>(state => Number(state.user.userId))
  const [dataComplaint, setDataComplaint] = useState<IFeedbackSeparator>()

  const getDataFeedback = async () => {
    setLoading(state => !state)
    const dateFrom = formatDateFrom(filter)
    try {
      const response = await separatorService.getFeedbackSeparator(userId, dateFrom, controller)
      const complaints = response.complaints.sort((a, b) =>
        new Date(b.separation_date) > new Date(a.separation_date) ? 1 : -1,
      )
      setDataComplaint({
        ...response,
        complaints,
      })
    } catch (err: any) {
      if (err?.msg === `Itens não encontrados para o separador ${userId}.`) {
        addPopup({
          title: 'Não há itens separados no período selecionado',
          type: 'info',
        })
        setDataComplaint(undefined)
        return
      }
      addPopup({
        title: 'Erro ao buscar quebras',
        type: 'error',
        description: err?.message || err?.msg,
      })
    } finally {
      setLoading(state => !state)
    }
  }

  const formatDateFrom = (period: IFilter) => {
    if (period === 'today') {
      return moment().format('YYYY-MM-DD')
    }
    if (period === 'week') {
      return moment().subtract(1, 'week').format('YYYY-MM-DD')
    }
    return moment().subtract(1, 'month').format('YYYY-MM-DD')
  }

  const dateFilter = {
    dateFrom: moment(formatDateFrom(filter)).format('DD/MM/YYYY'),
    dateTo: moment().add(1, 'days').format('DD/MM/YYYY'),
  }

  useEffect(() => {
    getDataFeedback()
    return () => {
      controller.abort()
    }
  }, [filter])
  return (
    <Container>
      <LinearProgress
        variant="indeterminate"
        style={{ width: '100%', marginBottom: '1rem', opacity: loading ? 1 : 0 }}
      />
      <FormControl className={classes.filter}>
        <Select
          value={filter}
          onChange={(e: any, value: any) => {
            setFilter(value.props.value as IFilter)
          }}
        >
          <MenuItem value="today">Hoje</MenuItem>
          <MenuItem value="week">Semana</MenuItem>
          <MenuItem value="month">Mês</MenuItem>
        </Select>
        <Typography>
          {dateFilter.dateFrom} - {dateFilter.dateTo}
        </Typography>
      </FormControl>
      {dataComplaint && (
        <>
          <Box className={classes.containerProgress}>
            <CircularProgress
              color="#EB001B"
              percentage={Number(
                (
                  (dataComplaint.totalComplaintItems * 100) /
                  (dataComplaint.totalSeparatedItems || 1)
                ).toFixed(2),
              )}
              text="Itens quebrados"
              total={dataComplaint.totalComplaintItems}
            />
          </Box>
          <Box className={classes.scroll}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Produto</TableCell>
                  <TableCell>Sugestão</TableCell>
                  <TableCell align="center">Qntd. quebras</TableCell>
                  <TableCell>Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataComplaint.complaints.map(product => (
                  <TableRow key={product.name + product.order_id}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.reason.name}</TableCell>
                    <TableCell align="center">{product.quantity_complaint}</TableCell>
                    <TableCell>{product.separation_date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </>
      )}
    </Container>
  )
}

export default Breaks
