import { alpha, Chip, darken, styled } from '@material-ui/core'
import { IStatus } from 'interfaces/IStatus'

export const statusMap = {
  separated: {
    color: '#89BD23',
    text: 'Separado',
  },
  'in separation': {
    color: '#2E656A',
    text: 'Em separação',
  },
  waiting: {
    color: '#D7C82B',
    text: 'Aguardando',
  },
  conference: {
    color: '#219ebc',
    text: 'Conferência',
  },
  in_progress: {
    color: '#ccd5ae',
    text: 'Em progresso',
  },
  'waiting reposition': {
    color: '#ccd5ae',
    text: 'Aguardando reposição',
  },
  conferred: {
    color: '#d31f1f',
    text: 'Conferido',
  },
  loaded: {
    color: '#b5179e',
    text: 'Carregado',
  },
}

const ClipStyle = styled(Chip)(({ status }: { status: IStatus }) => ({
  borderRadius: '0.85rem',
  background: alpha(statusMap[status].color, 0.3),
  color: darken(statusMap[status].color, 0.4),
  '&:hover': {
    color: '#ffffff',
    background: statusMap[status].color,
  },
}))

interface Props {
  status: IStatus
}

export const ClipStatus = ({ status }: Props) => {
  return <ClipStyle size="small" label={statusMap[status].text} status={status} />
}
