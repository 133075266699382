import {
  TextField,
  Button as ButtonMui,
  makeStyles,
  styled,
} from '@material-ui/core'
import backgroundImg from 'assets/images/background.jpg'
import LogoDarkImg from 'assets/images/logo-dark.png'
import LogolightImg from 'assets/images/logo-light.png'
import { shade } from 'polished'

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    minWidth: '100vw',
    minHeight: '100vh',
    background: `url("${backgroundImg}")  no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&::after': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      background: 'rgba(65, 79, 88, 0.65)',
      backdropFilter: 'blur(20px)',
      top: 0,
      left: 0,
    },
  },
  container: {
    position: 'relative',
    zIndex: 1,
    maxWidth: '550px',
    minHeight: '100vh',
    display: 'flex',
    padding: '0 4%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    '@media(min-width: 800px)': {
      maxWidth: '800px',
      width: '45%',
      background: '#ffffff',
      margin: '0',
    },
  },
  img: {
    background: `url("${LogoDarkImg}") no-repeat`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    width: '100%',
    maxWidth: '240px',
    margin: '0 auto',
    height: '5.75rem',
    position: 'relative',
    top: '-100px',
    '@media(max-width: 800px)': {
      background: `url("${LogolightImg}") no-repeat`,
      backgroundSize: '100% 100%',
      backgroundPosition: 'center',
    },
  },
  box: {
    margin: '20px 0 40px',
  },
  title: {
    color: '#2C2C2C',
    fontWeight: 600,
    fontSize: '2rem',
    '@media(max-width: 800px)': {
      color: '#ffffff',
    },
  },
  subtitle: {
    color: '#2C2C2C',
    fontWeight: 500,
    fontSize: '1.5rem',
    '@media(max-width: 800px)': {
      color: '#ffffff',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
  },
}))

export const Input = styled(TextField)({
  '& + &': {
    marginTop: 15,
  },
  '&': {
    width: '100%',
  },
  '& .MuiInputAdornment-root svg': {
    color: '#C7C7C7',
  },
  '& .Mui-focused .MuiInputAdornment-positionStart svg': {
    color: '#89BD23',
  },
  '& .MuiOutlinedInput-input': {
    paddingLeft: 10,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: '2px solid #89BD23',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#89BD23',
    },
    '@media(min-width: 800px)': {
      '& fieldset': {
        border: '2px solid #C7C7C7',
      },
      '&:hover fieldset': {
        borderColor: '#89BD23',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#89BD23',
      },
    },
  },
})

export const Button = styled(ButtonMui)({
  backgroundColor: '#89BD23',
  boxShadow: 'none',
  width: '100%',
  height: '3rem',
  marginTop: 15,
  color: '#ffffff',
  '&:hover': {
    backgroundColor: shade(0.1, '#89BD23'),
    boxShadow: 'none',
  },
  '&:disabled': {
    backgroundColor: '#89BD23',
  },
  '&:active': {
    boxShadow: 'none',
  },
})
