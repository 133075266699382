import {
  Modal as ModalMui,
  Paper as PaperMui,
  Typography,
  Button as ButtonMui,
  styled,
} from '@material-ui/core'

export const Modal = styled(ModalMui)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
})

export const Paper = styled(PaperMui)({
  width: '100%',
  maxWidth: '400px',
  padding: '2rem 1rem',
})

export const Title = styled(Typography)({
  color: '#757575',
  fontWeight: 500,
  fontSize: '1.2rem',
  marginBottom: '2rem',
})

export const Button = styled(ButtonMui)({
  width: '100%',
  marginTop: '2rem',
  color: '#757575',
  border: '1px solid #757575',
  height: '32px',
  borderRadius: '16px',
  textTransform: 'none',
})
