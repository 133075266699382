import { useEffect, useState } from 'react'
import { Box, Typography, styled } from '@material-ui/core'
import { usePopup } from 'hooks/usePopup'
import QR from 'react-qr-code'
import { settingsService } from 'services/settingsService'
import ContentLoader from 'react-content-loader'

const Container = styled(Box)({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '2rem',
})

const QRCODE = styled(QR)({
  width: '300px',
  height: '300px',
})

const Title = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '2rem',
  color: '#353535',
  background: '#FFF',
  padding: '.2rem 1rem',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  borderRadius: '8px',
  border: '1px solid #E0E0E0',
  letterSpacing: '0.3rem',
  lineHeight: '4rem',
})

const Value = styled(Typography)({
  marginTop: '2rem',
  fontSize: '2rem',
})

const Token = () => {
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const { addPopup } = usePopup()

  const getToken = async () => {
    setLoading(true)
    try {
      const { token } = await settingsService.getSettings()
      setToken(token)
      await new Promise(resolve => setTimeout(resolve, 800))
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao buscar token',
        description: err?.message || err?.msg,
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getToken()
  }, [])

  return (
    <Container>
      <Title>TOKEN</Title>
      {loading ? (
        <ContentLoader
          width={300}
          height={300}
          viewBox="0 0 350 350"
          backgroundColor="#e8e8e8"
          foregroundColor="#d6d6d6"
        >
          <rect x="0" y="0" width="350" height="350" />
        </ContentLoader>
      ) : (
        <>
          <QRCODE value={token} />
          <Value>{token}</Value>
        </>
      )}
    </Container>
  )
}

export default Token
