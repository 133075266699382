import { makeStyles, styled } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  list: {
    overflowY: 'scroll',
    maxHeight: '155px',
    width: '100%',
    height: '100%',
    borderRadius: '0.62rem',
    '&::-webkit-scrollbar': {
      width: '0.45rem',
      borderRadius: '0.62rem 0.62rem 0 0',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#353535',
      borderRadius: '1.37rem',
    },
  },
  listItem: {
    padding: '12px 0 12px 0.5rem',
    fontSize: '1rem',
  },

  lineBottom: {
    '&:before': {
      content: '""',
      width: '2px',
      height: '25px',
      background: '#618619',
      position: 'absolute',
      left: '12px',
      top: '25px',
    },
  },

  iconClose: {
    border: '1px solid #C4C4C4',
    borderRadius: '1.56rem',
    width: '1.56rem',
    color: '#757575',
    height: '1.56rem',
  },
  containerBeepBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '1rem',
    padding: '20px 0',
  },
}))

export const SpanList = styled('span')(({ color }: { color: string }) => ({
  background: color,
  width: '25px',
  height: '25px',
  display: 'inline-block',
  borderRadius: '25px',
  lineHeight: '25px',
  textAlign: 'center',
  position: 'relative',
  marginRight: '0.5rem',
  color: '#FFF',
}))
