import { Box, Button, Typography } from '@material-ui/core'
import { ContainerPage } from 'components'
import { usePopup } from 'hooks/usePopup'
import { IDayliRankingResponse, IRanking } from 'interfaces/IRanking'
import { useEffect, useRef, useState } from 'react'
import { BiLeftArrowCircle } from 'react-icons/bi'
import { MdDateRange } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { rankingService } from 'services/rankingService'
import { DateRangePicker } from 'shared'

import { Classified } from '../Classified'
import LoadingMedal from '../LoadingMedal'
import { useStyles } from './styles'

export const RankingFiltered = () => {
  const controller: AbortController = new AbortController()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [filteredRanking, setFilteredRanking] = useState({
    ranking: [] as IRanking[],
  })
  const navegate = useNavigate()
  const [selectedSearchDateFrom, setSelectedSearchDateFrom] = useState<Date>()
  const [selectedSearchDateTo, setSelectedSearchDateTo] = useState<Date>()
  const { addPopup } = usePopup()
  const anchorEl = useRef(null)

  const handleDateRange = async () => {
    if (!selectedSearchDateFrom || !selectedSearchDateTo) {
      return
    }
    try {
      setLoading(true)
      const selectedDateRanking = await rankingService.getRanking(
        {
          initialSeparationDate: selectedSearchDateFrom!.toISOString().slice(0, 10),
          finalSeparationDate: selectedSearchDateTo!.toISOString().slice(0, 10),
        },
        controller,
      )
      setFilteredRanking({
        ranking: selectedDateRanking?.data || [],
      })
    } catch (error: any) {
      addPopup({ title: error?.message || '', type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const handleChangeDate = (startDate: Date, endDate: Date) => {
    const startDay = startDate.getDay()
    const endDay = endDate.getDay()
    const dayDifference = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)

    if (startDate.toDateString() === endDate.toDateString()) {
      setSelectedSearchDateFrom(startDate)
      setSelectedSearchDateTo(endDate)
    } else if (startDay === 0 && endDay >= 5 && dayDifference <= 6) {
      setSelectedSearchDateFrom(startDate)
      setSelectedSearchDateTo(endDate)
    } else {
      alert('Por favor, selecione uma única data ou uma semana completa (Domingo a Sexta).')
    }
  }

  const handleRedirectUser = () => {
    navegate('../ranking')
  }

  return (
    <ContainerPage>
      <Box component="header" display="flex" className={classes.header}>
        <Button onClick={handleRedirectUser}>
          <BiLeftArrowCircle size={42} />
        </Button>{' '}
        <Typography className={classes.title}>Histórico de ranking</Typography>
        <Box></Box>
      </Box>
      <Box display="flex">
        <Box style={{ flex: 1 }}>
          <Box display="flex">
            <Box className={classes.boxDate}>
              <MdDateRange fontSize="1.2rem" color="#757575" />
              <span>De</span>
              <span>
                {selectedSearchDateFrom ? selectedSearchDateFrom.toLocaleDateString() : ''}
              </span>
            </Box>
            <Box className={classes.boxDate}>
              <MdDateRange fontSize="1.2rem" color="#757575" />
              <span>Até</span>
              <span>{selectedSearchDateTo ? selectedSearchDateTo.toLocaleDateString() : ''}</span>
            </Box>
          </Box>
          <DateRangePicker
            startDate={selectedSearchDateFrom || new Date()}
            endDate={selectedSearchDateTo || new Date()}
            anchorEl={anchorEl.current}
            onChange={event =>
              handleChangeDate(
                event.selection.startDate || new Date(),
                event.selection.endDate || new Date(),
              )
            }
          />
          <Box style={{ textAlign: 'end', maxWidth: '20.75rem' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDateRange}
              disabled={loading}
            >
              Filtrar
            </Button>
          </Box>
        </Box>

        <Box style={{ flex: 3 }}>
          {loading && filteredRanking?.ranking?.length ? (
            <LoadingMedal />
          ) : (
            <Box className={classes.boxAlign}>
              {filteredRanking.ranking.length ? (
                <Box>
                  {filteredRanking.ranking.slice(0, 30).map((separator, index) => (
                    <Classified key={index} position={index + 1} separator={separator} />
                  ))}
                </Box>
              ) : (
                <Typography className={classes.subtitle}>Sem registros </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </ContainerPage>
  )
}

export default RankingFiltered
