import { Box, IconButton, Typography } from '@material-ui/core'
import { FaUsersCog } from 'react-icons/fa'

import { useStyles } from './styles'

interface ICardProps {
  title: string
  subTitle: string
  image?: string
  callback?: () => void
  testId?: string
  Icon?: any
}

export const CardConfig = ({ image, title, subTitle, callback, testId, Icon }: ICardProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.container} data-testid={testId}>
      <Box className={classes.box}>
        <Box
          className={
            subTitle.includes('com scanner') ? classes.boxImageScanner : classes.boxImageNoScanner
          }
        >
          {Icon && <Icon className={classes.image} />}
          {image && <img src={image} alt="Esteira" className={classes.image} />}
        </Box>
        <Box className={classes.boxInformations}>
          <Typography className={classes.boxTitle}>{title}</Typography>
          <Typography className={classes.boxSubtitle}>{subTitle}</Typography>
        </Box>
      </Box>
      {callback && (
        <IconButton onClick={callback}>
          <FaUsersCog size={'2rem'} style={{ color: '#757575' }} />
        </IconButton>
      )}
    </Box>
  )
}
