import { Box, FormControlLabel, Typography } from '@material-ui/core'
import IconBox from 'assets/images/gifBox.svg'
import { CircleActive, ContainerPage, SwitchIOS } from 'components'
import { usePopup } from 'hooks/usePopup'
import { ILoadingConference } from 'interfaces/ILoadingConference'
import { enStatus } from 'interfaces/IOrder'
import React, { useEffect, useRef, useState } from 'react'
import { loadingConference } from 'services/loadingConferenceService'
import { ImQrcode } from 'react-icons/im'
import { enEvent } from '../../../interfaces/enEvent'
import { ITypeError } from './CardError'
import Carousel from './Carousel'
import { ModalError } from './ModalError'
import { Container, InvisibleInput, useStyles, CardEmpty, InfoCustomer, Section } from './styles'
import { useSocketLoadingConferece } from './useSocketLoadingConference'
import { IDataWarningModal, IWarningModal, WarningModal } from './WarningModal'
import ModalErrorRoute from './ModalErrorRoute'
import ModalAlertOrder from './ModalAlertOrder'
import ModalRouteNotTratament from './ModalRouteNotTratament'
import CardConnection, { ImageConveyorConnection, ImageWebSocketConnection } from './CardConnection'
import ModalAlertInvoiceOrder from './ModalAlertInvoiceOrder'
import ModalAlertConferenceOrder from './ModalAlertConferenceOrder'
import ModalFinanceValidateOrder from './modalAlertFinanceValidateOrder'

export interface handleSubmitModal {
  type: 'save' | 'delete'
  value?: number
  orderId: string
}

export type IOrderModalEdit = {
  order_id: string
  loading_boxes: number
  conference_boxes: number
}

export interface IOrders {
  [key: string]: IOrdersInfo
}

export interface IOrdersInfo extends ILoadingConference {
  clientType?: string
  typeError?: ITypeError | null
}

export interface IBoxesRoutes {
  [key: string]: {
    boxesScanneds: number
    totalBoxes: number
  }
}

interface IEvent {
  event: enEvent
  data: any
}

interface IAddBoxNotConfirmed {
  error: string
  title: string
  orderId: string
}

const formatOrder = (order: any, orders: IOrders, typeError: ITypeError | null): IOrdersInfo => ({
  ...order,
  clientType: order?.client_type,
  typeError,
  created: orders[order?.order_id]?.created,
  id: orders[order?.order_id]?.id,
  track: orders[order?.order_id]?.separation_group,
  user: orders[order?.order_id]?.user,
  skipped: orders[order?.order_id]?.skipped,
})

const FixScanner = () => {
  const {
    confirmOrderBox,
    confirmBoxesQuantity,
    modalErrorRef,
    isConnected,
    conveyorBeltStatus,
    handleConveyorBeltStatus,
    conveyorBeltClientStatus,
    socket,
    modalAlertOrder,
    modalAlertInvoiceOrder,
    modalAlertFinanceValidateOrder,
    modalAlertConferenceOrder,
    modalRouteNotTratament,
    tratedRoute,
  } = useSocketLoadingConferece()
  const { addPopup } = usePopup()
  const [orders, setOrders] = useState<IOrders>({})
  const [showOrders, setShowOrders] = useState<IOrdersInfo[]>([])
  const [currentOrder, setCurrentOrder] = useState<IOrdersInfo>({} as IOrdersInfo)
  const [lastQRCode, setLastQRCode] = useState<string>('')
  const [modalErrorRoute, setModalErrorRoute] = useState({
    open: false,
    data: {
      route: '',
      orders: [],
      color_route: '',
    },
  })

  const isOpenModalErrorRoute = useRef<boolean>(false)
  const inputInvisible = useRef<HTMLInputElement>(null)
  const refWarningModal = useRef<IWarningModal>(null)
  const classes = useStyles()

  const getAllInfosOrders = async () => {
    try {
      const response = await loadingConference.getAllOrderBy({ orderBy: '-updated' })
      setOrders(state => ({
        ...state,
        ...response.reduce((acc, order) => ({ ...acc, [order.order_id]: order }), {}),
      }))
      setShowOrders(response)
    } catch (err) {
      addPopup({
        type: 'error',
        title: 'Não foi possível carregar as informações dos pedidos',
      })
    }
  }

  const formatShowOrders = (ordersShow: IOrdersInfo[]) => {
    return ordersShow.slice(0, 10)
  }

  const getTomorrow = () => {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    return tomorrow.toLocaleDateString()
  }

  const getToday = () => new Date().toLocaleDateString()

  const isDateBeforeToday = (date: Date) => {
    const currentDate = new Date()
    return new Date(date).getTime() < currentDate.getTime()
  }

  const fetchError = (order: any) => {
    const reschaduledDate = new Date(order?.scheduled_date).toLocaleDateString()
    const tomorrow = getTomorrow()
    const today = getToday()
    const hour = new Date().getHours()
    const isNotRescheduled =
      tomorrow === reschaduledDate || (today === reschaduledDate && hour <= 6)

    if (isDateBeforeToday(order?.scheduled_date)) {
      return null
    }
    if (!isNotRescheduled) {
      return 'rescheduled'
    }
    if (order?.permission_to_separate === enStatus.recused) {
      return 'recused'
    }

    return null
  }

  const confirmAddBox = (order: any) => {
    setOrders(state => {
      const typeError = fetchError(order)
      const newOrder = formatOrder(order, state, typeError)

      setShowOrders(state => {
        const indexOrder = state.findIndex(item => item.order_id === order?.order_id)
        if (indexOrder !== -1) state.splice(indexOrder, 1)
        return formatShowOrders([newOrder, ...state])
      })

      setCurrentOrder(newOrder)
      if (!order?.order_id) {
        addPopup({
          type: 'error',
          title: `Erro ao contabilizar a caixa no FRONT do pedido #${order?.order_id}`,
          description: 'Não foi possível encontrar o pedido',
        })
        return state
      }
      return { ...state, [order?.order_id]: newOrder }
    })
  }

  const notConfirmedAddBox = (data: IAddBoxNotConfirmed) => {
    addPopup({
      type: 'error',
      title: data.title,
      description: data.error,
      autoClose: false,
    })
  }

  const notConfirmedBoxesQuantity = (data: IAddBoxNotConfirmed) => {
    addPopup({
      type: 'error',
      title: data.title,
      description: data?.error,
    })
    modalErrorRef.current?.handleChangeOrderId({
      orderId: data?.orderId,
      title: `Divergência na quantidade de caixas do pedido: #${data?.orderId}`,
    })
  }

  const handleSubmitScanner = async (param: React.FormEvent) => {
    const formData = new FormData(param.target as HTMLFormElement)
    param.preventDefault()
    const inputValue = formData.get('inputValue')
    setLastQRCode(String(inputValue))
    confirmOrderBox(String(inputValue))

    if (inputInvisible.current) {
      inputInvisible.current.value = ''
    }
  }

  const updateShowOrders = async (data: any) => {
    setShowOrders(state => {
      const indexOrder = state.findIndex(item => item.order_id === data.order_id)
      if (indexOrder === -1) {
        return formatShowOrders([
          {
            clientType: data?.client_type,
            typeError: null,
            ...data,
          },
          ...state,
        ])
      }
      const order = state[indexOrder]
      order.skipped = true
      order.corrected_boxes_number = data.corrected_boxes_number
      order.typeError = null
      return formatShowOrders(state)
    })
  }

  const handleScanModalErrorRoute = (QRcode: string) => {
    socket.emit(enEvent.READ_TAG, { tag_value: QRcode })
  }

  const handleRouteError = ({ data, event }: { data: any; event: any }) => {
    if (event === enEvent.ROUTE_ERROR_RESOLVED) {
      setModalErrorRoute({
        open: false,
        data: {
          route: '',
          orders: [],
          color_route: '',
        },
      })
      isOpenModalErrorRoute.current = false
    }
    if (event === enEvent.ROUTE_ERROR_NOT_RESOLVED) {
      setModalErrorRoute({
        open: true,
        data,
      })
      isOpenModalErrorRoute.current = true
    }
    if (event === enEvent.ROUTE_ERROR_WRONG_ORDER) {
      addPopup({
        type: 'error',
        title: `Pedido #${data.orderId} não faz parte dos pedidos pendentes da rota ${data.route} `,
      })
    }
  }

  useEffect(() => {
    getAllInfosOrders()
    if (inputInvisible.current) {
      setInterval(() => {
        !modalErrorRef.current?.isOpen &&
          !isOpenModalErrorRoute.current &&
          inputInvisible.current?.focus()
      }, 100)
    }
    socket.on(enEvent.NOT_CONFIRMED, ({ event, data }: IEvent) => {
      if (event === enEvent.ADD_BOX) return notConfirmedAddBox(data)
      if (event === enEvent.SKIPPED) return notConfirmedBoxesQuantity(data)
    })
    socket.on(enEvent.CONFIRMATION, ({ event, data }: IEvent) => {
      if (event === enEvent.ADD_BOX) return confirmAddBox(data)
      if (event === enEvent.SKIPPED) return updateShowOrders(data)
    })
    socket.on(enEvent.RESCHEDULED, (data: IDataWarningModal) => {
      refWarningModal.current?.handleChangeOrder(data, 'rescheduled')
    })
    socket.on(enEvent.CANCELED, (data: IDataWarningModal) => {
      refWarningModal.current?.handleChangeOrder(data, 'recused')
    })
    socket.on(enEvent.ROUTE_ERROR, handleRouteError)
    socket.on(enEvent.READ_TAG, (data: { tag_value: string; image_base64: string }) => {
      setLastQRCode(data.tag_value)
    })
  }, [])

  return (
    <ContainerPage>
      <Container>
        <Box className={classes.boxContainer}>
          <form
            onSubmit={e => {
              handleSubmitScanner(e)
            }}
          >
            <InvisibleInput hidden type="text" inputRef={inputInvisible} name="inputValue" />
          </form>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{ width: '75%' }}
            marginBottom="1rem"
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <FormControlLabel
                label={<strong>{conveyorBeltStatus ? 'Desligar' : 'Ligar'} esteira</strong>}
                control={
                  <SwitchIOS
                    color="primary"
                    checked={conveyorBeltStatus}
                    onChange={(_, value) => handleConveyorBeltStatus(value)}
                  />
                }
              />
            </Box>
          </Box>
          {lastQRCode && (
            <Typography className={classes.lastQrcode}>
              <ImQrcode size={50} />
              {lastQRCode}
            </Typography>
          )}
          {!Object.values(currentOrder).length ? (
            <CardEmpty className={classes.animationImage}>
              <Box paddingX="1rem">
                <InfoCustomer>
                  <strong>Aguardando começar leitura de caixas</strong>
                </InfoCustomer>
                <Section style={{ marginTop: '2rem' }}>
                  <img src={IconBox} alt="Caixa" />
                </Section>
              </Box>
            </CardEmpty>
          ) : (
            <Carousel currentOrder={currentOrder} showOrders={showOrders} />
          )}
        </Box>
        <CardConnection
          isConnected={conveyorBeltClientStatus}
          Image={ImageConveyorConnection}
          bottomValue="0"
        />
        <CardConnection
          isConnected={isConnected}
          Image={ImageWebSocketConnection}
          bottomValue="200px"
        />
      </Container>
      <ModalError ref={modalErrorRef} onSubmit={confirmBoxesQuantity} socket={socket} />
      <WarningModal ref={refWarningModal} />
      <ModalErrorRoute
        open={modalErrorRoute.open}
        onScan={handleScanModalErrorRoute}
        data={modalErrorRoute.data}
      />
      <ModalAlertOrder open={modalAlertOrder.open} data={modalAlertOrder.data} />
      <ModalAlertInvoiceOrder
        open={modalAlertInvoiceOrder.open}
        data={modalAlertInvoiceOrder.data}
      />
      <ModalFinanceValidateOrder
        open={modalAlertFinanceValidateOrder.open}
        data={modalAlertFinanceValidateOrder.data}
      />
      <ModalAlertConferenceOrder
        open={modalAlertConferenceOrder.open}
        data={modalAlertConferenceOrder.data}
      />
      <ModalRouteNotTratament
        open={modalRouteNotTratament.open}
        data={modalRouteNotTratament.data}
        resolved={tratedRoute}
      />
    </ContainerPage>
  )
}

export default FixScanner
