import { useMemo } from 'react'

export const dashboardCalculateTime = (timeData: number) => {
  const time = useMemo(() => {
    if (isNaN(timeData)) {
      return '00:00:00'
    }

    let msec = Math.floor(timeData) * 1000

    const hh = Math.floor(msec / 1000 / 60 / 60)
    msec -= hh * 1000 * 60 * 60
    const mm = Math.floor(msec / 1000 / 60)
    msec -= mm * 1000 * 60
    const ss = Math.floor(msec / 1000)

    return `${String(hh).padStart(2, '0')}:${String(mm).padStart(
      2,
      '0',
    )}:${String(ss).padStart(2, '0')}`
  }, [timeData])

  return time
}
