import { Box, IconButton, Typography } from '@material-ui/core'
import { IBoxImage } from 'interfaces/IDashboard'
import { useRef } from 'react'
import { LuImageOff } from 'react-icons/lu'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import Slider from 'react-slick'

import { IOverlayRef, Overlay } from './Overlay'
import { useStyles } from './styles'

interface IProps {
  boxes: IBoxImage[]
  totalBoxes: number
}

export const Boxes = (props: IProps) => {
  const { boxes, totalBoxes } = props
  const sliderRef = useRef<Slider>(null)
  const overlayRef = useRef<IOverlayRef>(null)
  const classes = useStyles()
  const boxQuantity = boxes.length

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 150,
    slidesToShow: boxQuantity >= 3 ? 3 : boxQuantity,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: boxQuantity >= 2 ? 2 : boxQuantity,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const next = () => {
    sliderRef.current?.slickNext()
  }
  const previous = () => {
    sliderRef.current?.slickPrev()
  }

  if (!boxes.length) {
    return null
  }

  const data = boxes.sort(
    (prev, next) => new Date(prev.created).getTime() - new Date(next.created).getTime(),
  )

  return (
    <>
      <Box m="2rem 0">
        <Box component="header" display="flex" justifyContent="space-between" mb="1rem">
          <Box>
            <Typography component="h2" className={classes.title}>
              Caixas
            </Typography>
            <Typography component="h3" className={classes.subtitle}>
              Qtd. de caixas informada: <strong>{totalBoxes}</strong>
            </Typography>
            <Typography component="h3" className={classes.subtitle}>
              Qtd. de caixas com foto: <strong>{boxQuantity}</strong>
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={previous}>
              <MdOutlineKeyboardArrowLeft />
            </IconButton>
            <IconButton onClick={next}>
              <MdOutlineKeyboardArrowRight />
            </IconButton>
          </Box>
        </Box>

        <Slider {...settings} ref={sliderRef}>
          {data.map((box, index) => (
            <Box className={classes.container} key={box.id}>
              <Box className={classes.box}>
                <Box mb="1rem" p="0 0.5rem">
                  <Typography className={classes.boxTitle}>QRCODE: {box.tag_value}</Typography>
                  <Typography className={classes.boxSubtitle}>
                    {new Date(box.created).toLocaleString()}
                  </Typography>
                </Box>
                {box.url_image?.includes('Falha') || !box.url_image ? (
                  <Box
                    position="relative"
                    borderRadius="1rem"
                    width="100%"
                    pt="62.305%"
                    bgcolor="#dadada"
                  >
                    <Box className={classes.boxError}>
                      <LuImageOff size={40} color="#b4b4b4" />
                      <Typography style={{ textAlign: 'center', color: '#7e7e7e' }}>
                        Falha na comunicação com o serviço de imagens
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <img src={box.url_image} onClick={() => overlayRef.current?.handleOpen(index)} />
                )}
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>

      <Overlay ref={overlayRef} boxes={data} />
    </>
  )
}
