import { TableCell as TableCellMui, TableCellProps } from '@material-ui/core'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { IPaginationParams } from 'interfaces/IPagination'
import { memo, useCallback, useMemo, ReactNode } from 'react'

import { useStyles } from './styles'

interface IProps extends Omit<TableCellProps, 'onChange'> {
  style?: any
  disabled?: boolean
  column: string
  paginationParams?: IPaginationParams
  children: ReactNode
  onChange?: (params: { orderBy: string; orderDirection: 'asc' | 'desc' }) => void
}

const TableCellHeadComponent = (props: IProps) => {
  const classes = useStyles()
  const { paginationParams, children, column, disabled, onChange, className, style, ...extra } =
    props

  const isActive = useMemo(
    () => paginationParams?.orderBy === column,
    [column, paginationParams?.orderBy],
  )

  const handleChange = useCallback(() => {
    const direction = isActive && paginationParams?.orderDirection === 'asc' ? 'desc' : 'asc'
    onChange && onChange({ orderBy: column, orderDirection: direction })
  }, [column, isActive, onChange, paginationParams?.orderDirection])

  return (
    <TableCellMui {...extra} className={`${classes.root} ${className}`} style={style || null}>
      {disabled ? (
        children
      ) : (
        <TableSortLabel
          disabled={disabled}
          active={isActive}
          onClick={handleChange}
          style={style || null}
        >
          {children}
        </TableSortLabel>
      )}
    </TableCellMui>
  )
}

const TableCellHead = memo(TableCellHeadComponent)

export default TableCellHead
