import Carousel, { slidesToShowPlugin, arrowsPlugin } from '@brainhubeu/react-carousel'
import { Box, Container, Divider, Icon, Typography } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import iconOrderFinished from 'assets/images/icon-orderFinished.svg'
import iconShop from 'assets/images/icon-shop.svg'
import { IItem, ISeparation } from 'interfaces/ISeparation'
import { useEffect, useState } from 'react'
import { BsPersonBadgeFill } from 'react-icons/bs'
import { FaClock, FaUserCheck } from 'react-icons/fa'
import { Timer } from 'shared'
import { formatName } from 'utils/formatName'

import { useStyles } from './styles'

interface ISummaryOrderProps {
  textTotal: string
  textCompleted: string
  textPedding: string
  textTimer: string
  textSeparator: string
  textConferencer: string
  totalItens: number
  completed: number
  className?: string
  itensPending: IItem[]
  separations: ISeparation[]
  startSeparation?: Date
  timeLength?: string
  separatorName?: string
  conferencerName?: string
}

export const SummaryOrder = (props: ISummaryOrderProps) => {
  const {
    completed,
    totalItens,
    textCompleted,
    textPedding,
    textTotal,
    textTimer,
    textSeparator,
    textConferencer,
    className,
    itensPending,
    separations,
    startSeparation,
    separatorName,
    conferencerName,
  } = props

  const stepWithItem = separations.find(separation => separation.items.length > 0)
  const separationTime = stepWithItem?.items[0]?.work?.time_length

  const [index, setIndex] = useState(0)
  const classes = useStyles()
  const [width, setWidth] = useState(() => window?.innerWidth)

  useEffect(() => {
    const updateWidth = (e: any) => {
      setWidth(e.target?.innerWidth)
    }

    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  if (width <= 1050) {
    return (
      <Carousel
        className={`${classes.carousel} ${className}`}
        value={index}
        onChange={e => {
          if (e >= 0) {
            setIndex(e)
          }
        }}
        plugins={[
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 4,
            },
          },
        ]}
        breakpoints={{
          1050: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 3,
                },
              },
              {
                resolve: arrowsPlugin,
                options: {
                  arrowLeft: (
                    <button className={classes.arrowLeft}>
                      <ArrowBackIosIcon className={classes.arrowIcon} />
                    </button>
                  ),
                  arrowLeftDisabled: (
                    <button disabled={true} className={classes.arrowLeft}>
                      <ArrowBackIosIcon className={classes.arrowIcon} />
                    </button>
                  ),
                  arrowRight: (
                    <button className={classes.arrowRight}>
                      <ArrowForwardIosIcon className={classes.arrowIcon} />
                    </button>
                  ),
                  arrowRightDisabled: (
                    <button disabled={true} className={classes.arrowRight}>
                      <ArrowForwardIosIcon className={classes.arrowIcon} />
                    </button>
                  ),
                  addArrowClickHandler: true,
                },
              },
            ],
          },
          820: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 2,
                },
              },
              {
                resolve: arrowsPlugin,
                options: {
                  arrowLeft: (
                    <button className={classes.arrowLeft}>
                      <ArrowBackIosIcon className={classes.arrowIcon} />
                    </button>
                  ),
                  arrowLeftDisabled: (
                    <button disabled={true} className={classes.arrowLeft}>
                      <ArrowBackIosIcon className={classes.arrowIcon} />
                    </button>
                  ),
                  arrowRight: (
                    <button className={classes.arrowRight}>
                      <ArrowForwardIosIcon className={classes.arrowIcon} />
                    </button>
                  ),
                  arrowRightDisabled: (
                    <button disabled={true} className={classes.arrowRight}>
                      <ArrowForwardIosIcon className={classes.arrowIcon} />
                    </button>
                  ),
                  addArrowClickHandler: true,
                },
              },
            ],
          },
          520: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1,
                },
              },
              {
                resolve: arrowsPlugin,
                options: {
                  arrowLeft: (
                    <button className={classes.arrowLeft}>
                      <ArrowBackIosIcon className={classes.arrowIcon} />
                    </button>
                  ),
                  arrowLeftDisabled: (
                    <button disabled={true} className={classes.arrowLeft}>
                      <ArrowBackIosIcon className={classes.arrowIcon} />
                    </button>
                  ),
                  arrowRight: (
                    <button className={classes.arrowRight}>
                      <ArrowForwardIosIcon className={classes.arrowIcon} />
                    </button>
                  ),
                  arrowRightDisabled: (
                    <button disabled={true} className={classes.arrowRight}>
                      <ArrowForwardIosIcon className={classes.arrowIcon} />
                    </button>
                  ),
                  addArrowClickHandler: true,
                },
              },
            ],
          },
        }}
      >
        <div style={{ display: 'flex' }}>
          <Box className={classes.box}>
            <span className={`${classes.icon} ${classes.totalOrderIcon}`}>
              <img src={iconShop} />
            </span>
            <div>
              <Typography className={classes.title}>{textTotal}</Typography>
              <Typography className={classes.subtitle}>{totalItens}</Typography>
            </div>
          </Box>
        </div>
        <div style={{ display: 'flex' }}>
          <Divider className={classes.divider} orientation="vertical" flexItem />
          <Box className={classes.box}>
            <span className={`${classes.icon} ${classes.ordersFinished}`}>
              <img src={iconOrderFinished} />
            </span>
            <div>
              <Typography className={classes.title}>{textCompleted}</Typography>
              <Typography className={classes.subtitle}>{completed}</Typography>
            </div>
          </Box>
          <Divider className={classes.divider} orientation="vertical" flexItem />
        </div>
        <div style={{ display: 'flex' }}>
          <Box className={classes.box}>
            <span className={`${classes.icon} ${classes.ordersPending}`}>
              <MoreHorizIcon style={{ color: '#ffffff' }} />
            </span>
            <div>
              <Typography className={classes.title}>{textPedding}</Typography>
              <Typography className={classes.subtitle}>{itensPending.length}</Typography>
            </div>
          </Box>
        </div>
        <div style={{ display: 'flex' }}>
          <Box className={classes.box}>
            <span className={`${classes.icon} ${classes.separator}`}>
              <BsPersonBadgeFill size={34} color="#fff" />
            </span>
            <div>
              <Typography className={classes.title}>{textSeparator}</Typography>
              <Typography className={classes.subtitle} style={{ textTransform: 'capitalize' }}>
                {formatName(separatorName)}
              </Typography>
            </div>
          </Box>
        </div>
        <div style={{ display: 'flex' }}>
          <Divider className={classes.divider} orientation="vertical" flexItem />
          <Box className={classes.box}>
            <span className={`${classes.icon} ${classes.timer}`}>
              <FaClock size={30} color="#fff" />
            </span>
            <div>
              <Typography className={classes.title}>{textTimer}</Typography>
              <Typography className={classes.subtitle}>
                {separationTime ? (
                  <strong style={{ textAlign: 'right' }}>
                    {separationTime?.split('').slice(0, 8).join('')}
                  </strong>
                ) : (
                  <strong style={{ textAlign: 'right' }} className={classes.subtitle}>
                    {startSeparation ? <Timer time={startSeparation} isHours /> : '00:00'}
                  </strong>
                )}
              </Typography>
            </div>
          </Box>
        </div>
        <div style={{ display: 'flex' }}>
          <Divider className={classes.divider} orientation="vertical" flexItem />
          <Box className={classes.box}>
            <span className={`${classes.icon} ${classes.conferencer}`}>
              <FaUserCheck size={30} color="#fff" />
            </span>
            <div>
              <Typography className={classes.title}>{textConferencer}</Typography>
              <Typography className={classes.subtitle} style={{ textTransform: 'capitalize' }}>
                {formatName(conferencerName)}
              </Typography>
            </div>
          </Box>
        </div>
      </Carousel>
    )
  }

  return (
    <Container className={`${classes.root} ${className}`}>
      <Box className={classes.box}>
        <span className={`${classes.icon} ${classes.totalOrderIcon}`}>
          <img src={iconShop} />
        </span>
        <div>
          <Typography className={classes.title}>{textTotal}</Typography>
          <Typography className={classes.subtitle}>{totalItens}</Typography>
        </div>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.box}>
        <span className={`${classes.icon} ${classes.ordersFinished}`}>
          <img src={iconOrderFinished} />
        </span>
        <div>
          <Typography className={classes.title}>{textCompleted}</Typography>
          <Typography className={classes.subtitle}>{completed}</Typography>
        </div>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.box}>
        <span className={`${classes.icon} ${classes.ordersPending}`}>
          <MoreHorizIcon style={{ color: '#ffffff' }} />
        </span>
        <div>
          <Typography className={classes.title}>{textPedding}</Typography>
          <Typography className={classes.subtitle}>{itensPending.length}</Typography>
        </div>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.box}>
        <span className={`${classes.icon} ${classes.separator}`}>
          <BsPersonBadgeFill size={34} color="#fff" />
        </span>
        <div>
          <Typography className={classes.title}>{textSeparator}</Typography>
          <Typography className={classes.subtitle} style={{ textTransform: 'capitalize' }}>
            {formatName(separatorName)}
          </Typography>
        </div>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.box}>
        <span className={`${classes.icon} ${classes.timer}`}>
          <FaClock size={30} color="#fff" />
        </span>
        <div>
          <Typography className={classes.title}>{textTimer}</Typography>
          <Typography className={classes.subtitle}>
            {separationTime ? (
              <strong style={{ textAlign: 'right' }}>
                {separationTime?.split('').slice(0, 8).join('')}
              </strong>
            ) : (
              <strong style={{ textAlign: 'right' }} className={classes.subtitle}>
                {startSeparation ? <Timer time={startSeparation} isHours /> : '00:00'}
              </strong>
            )}
          </Typography>
        </div>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.box}>
        <span className={`${classes.icon} ${classes.conferencer}`}>
          <FaUserCheck size={30} color="#fff" />
        </span>
        <div>
          <Typography className={classes.title}>{textConferencer}</Typography>
          <Typography className={classes.subtitle} style={{ textTransform: 'capitalize' }}>
            {formatName(conferencerName)}
          </Typography>
        </div>
      </Box>
    </Container>
  )
}
