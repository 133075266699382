export interface ISeparationItem {
  orderId: string
  workId: number
  isAlreadyPrintStickers?: boolean
}

export interface ICheckItems {
  orderId: string | number | null
  separationId: number | null
  workId: number | null
}

export enum enStatusItem {
  separated = 2,
  totalBreak = 3,
  partialBreak = 4,
  reposition = 5,
}
