import { IOrdersPrePicking } from 'interfaces/IOrderItem'
import {
  IParamsPrePickingV2,
  IPrePickingV2,
  IPickPrePickingV2,
  IResetPrePickingV2,
} from 'interfaces/IPrePickingV2'
import { IProductApi } from 'interfaces/IRequestProductApi'

import { api, ApiService } from './api'

export interface IPrePickingResponse {
  message: string
}

interface IPrePickingGetResponse {
  data: IPrePicking[]
}

export interface IPrePicking {
  created: string
  id: number
  item_weight?: number
  product_base_id: string
  scale_id?: number
  scale_label_id?: string
  sku: IProductApi
  sku_id: string
  updated: string
  weighing_time?: string
  batch_id?: number
  orders: IOrdersPrePicking[]
}

export interface IPrePickingParams {
  productBaseId?: string
  orderId?: string
  skuId?: string
  itemId?: string
  scaleLabelId?: string
  prePickingId?: string
  scaleId?: number
  date?: string
  itemWeight?: number
}

export interface IPrePickingData {
  prePickingId?: string
  nextStatus?: string
  orderId?: string
  itemId?: number
  sku?: number
  valueBeeped?: number
  isBalance: boolean | undefined
  scaleId?: number
  scaleLabelId?: string
  date?: string
}

interface IResetPrePickingData {
  prePickingId?: string
  orderId?: string
  orders?: string[]
  itemId?: number
  isBalance?: boolean
  valueBeeped?: number
  sku?: number
  scaleId?: number
  scaleLabelId?: string
  date?: string
}

interface IPrePickingExtraOrder {
  minimumExtraOrder: number
  maximumExtraOrder: number
}

interface IPrePickingHistory {
  id: number
  created: string
  attempted_by: number
  pre_picking: Omit<IPrePicking, 'sku'>
}

interface IProductConfigResponse {
  id: number
  created: string
  updated: string
  sku: string
  unique_tag_only: boolean
  updated_by: number
}

class Nabo {
  constructor(private readonly api: ApiService, private readonly BASE_URL: string) {}

  async getPrePicking(params: IPrePickingParams): Promise<IPrePickingGetResponse> {
    return this.api.get(`${this.BASE_URL}/v1/nabo/pre-picking`, {
      params,
    })
  }

  async getProductConfig(sku: string): Promise<IProductConfigResponse> {
    return this.api.get(`${this.BASE_URL}/v1/nabo/product-config/${sku}`)
  }

  async updateStatusPrePicking(data: IPrePickingData): Promise<IPrePickingResponse> {
    return this.api.post(`${this.BASE_URL}/v1/nabo/pre-picking`, data)
  }

  async resetPrePicking(data: IResetPrePickingData): Promise<IPrePickingResponse> {
    return this.api.post(`${this.BASE_URL}/v1/nabo/pre-picking/reset`, data)
  }

  async getHistoryPrePicking(prePickingId: string): Promise<IPrePickingHistory> {
    return this.api.get(`${this.BASE_URL}/v1/nabo/pre-picking/history`, {
      params: {
        prePickingId,
      },
    })
  }

  async getExtraOrderBySkuId(skuId: string): Promise<IPrePickingExtraOrder> {
    return this.api.get(`${carambolaUrl}/separation/cache/product/${skuId}`)
  }

  async getPrePickingV2(params: IParamsPrePickingV2): Promise<IPrePickingV2> {
    return this.api.get(`${carambolaUrl}/pre-picking/search`, {
      params,
    })
  }

  async pickPrePickingV2(id: number, data: IPickPrePickingV2) {
    return this.api.post(`${this.BASE_URL}/v1/nabo/pre-picking/v2/${id}/pick`, data)
  }

  async resetPrePickingV2(data: IResetPrePickingV2) {
    return this.api.post(`${this.BASE_URL}/v1/nabo/pre-picking/v2/reset`, data)
  }

  async checkIsCorrectBarCode(productBaseId: number, productCode: string) {
    return this.api.get(`${carambolaUrl}/bar-code/`, {
      params: {
        productBaseId,
        productCode,
      },
    })
  }
}
const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
const naboUrl = process.env.REACT_APP_API_NABO
export const naboServices = new Nabo(api, naboUrl as string)
