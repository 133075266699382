import { alpha, Chip, darken, styled } from '@material-ui/core'
import { enStatus } from 'interfaces/IOrder'

const statusMap = {
  approved: {
    text: 'Aprovado',
    color: '#618619',
  },
  recused: {
    text: 'Cancelado',
    color: '#DA3737',
  },
  pendente: {
    text: 'Pendente',
    color: '#D7C82B',
  },
  aprovado: {
    text: 'Aprovado',
    color: '#618619',
  },
  recusado: {
    text: 'Cancelado',
    color: '#DA3737',
  },
  pending: {
    text: 'Pendente',
    color: '#D7C82B',
  },
}

const ClipStyle = styled(Chip)(({ status }: { status: enStatus }) => ({
  borderRadius: '0.85rem',
  background: alpha(statusMap[status].color, 0.3),
  color: darken(statusMap[status].color, 0.4),
  '&:hover': {
    color: '#ffffff',
    background: statusMap[status].color,
  },
}))

interface Props {
  status: enStatus
}

export const ClipStatusOrder = ({ status }: Props) => {
  return <ClipStyle size="small" label={statusMap[status].text} status={status} />
}
