import { ContainerOrder, Modal, Paper, Subtitle, Text, Title } from './styles'

export interface IDataAlertFinanceValidadeOrder {
  title: string
  subtitle: string
  order_id: string
  client_name: string
}

interface IProps {
  open: boolean
  data: IDataAlertFinanceValidadeOrder
}

const ModalFinanceValidateOrder = (props: IProps) => {
  return (
    <Modal open={props.open}>
      <Paper>
        <Title>{props.data.title}</Title>
        <Subtitle>{props.data.subtitle}</Subtitle>
        <ContainerOrder>
          <Text colorText="#757575">Pedido</Text>
          <Text colorText="#757575">Cliente</Text>
          <Text colorText="#5F752C">{props.data.order_id}</Text>
          <Text colorText="#353535">{props.data.client_name}</Text>
        </ContainerOrder>
      </Paper>
    </Modal>
  )
}

export default ModalFinanceValidateOrder
