import { makeStyles } from '@material-ui/core'
import { mapTagColor } from 'utils/mapTagColor'

export const useStyles = makeStyles({
  title: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: '1.8rem',
    textAlign: 'center',
    color: '#353535',
  },
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: '32.06rem',
      width: '100%',
      padding: '2rem 1rem',
      margin: '1rem 0.5rem',
    },
    width: '100%',
  },
  button: {
    width: '100%',
    background: '#89BD23',
    borderRadius: '4px',
    height: '3rem',
    textAlign: 'center',
    color: '#fff',
    flex: 'initial',
    '&:hover': {
      filter: 'brightness(0.9)',
      background: '#89BD23',
    },
    '&:disabled': {
      filter: 'grayscale(1)',
      color: '#fff !important',
    },
  },
  buttonChange: {
    background: 'transparent',
    borderRadius: '4px',
    height: '3rem',
    textAlign: 'center',
    color: '#757575',
    '&:hover': {
      filter: 'brightness(0.9)',
      background: 'transparent',
    },
  },
  input: {
    width: '100%',
    marginBottom: '1.12rem',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F4F4F4',
      borderRadius: '16px',
      '& fieldset': {
        borderColor: '#F4F4F4',
      },
    },
  },
  strong: {
    fontWeight: 500,
    color: '#353535',
  },
  alert: {
    height: '50px',
    width: '100%',
    border: '1px solid #EB001B',
    background: '#FDEDEF',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1rem',
    borderRadius: '16px',
    marginBottom: '2rem',
    '& p': {
      color: '#757575',
      fontWeight: 400,
      fontSize: '1rem',
      '& strong': {
        color: '#353535',
      },
    },
  },
  linearProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    background: '#618619',
  },
})

export const buttonStyle = makeStyles({
  buttonColor: {
    width: '12px',
    height: '12px',
    color: (props: { color: string }) => mapTagColor(props.color),
    marginRight: '0.5rem',
  },
})
