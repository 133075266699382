import { api as apiService, ApiService } from './api'

export interface IReprint {
  orderId: number | string
  track: number
  quantity?: number
}

export interface IPrint {
  orderId: string
  printerChoice?: number
  boxQuantity?: number
  reprint?: boolean
  initialPrint?: boolean
}

export interface IPrintResponse {
  message: string
}

class PrinterService {
  constructor(private readonly api: ApiService) {}

  public async reprint(data: IReprint): Promise<void> {
    return await this.api.get(carambolaUrl + '/separation/order-separation/reprint', {
      params: data,
    })
  }
  public async print(data: IPrint): Promise<IPrintResponse> {
    return await this.api.post(carambolaUrl + '/print/', data)
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const printerService = new PrinterService(apiService)
