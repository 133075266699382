import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Container, Dialog, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Button } from 'components'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { useStyles } from './styles'
import { useConfig } from 'hooks/useConfig/Index'
import { useSelector } from 'react-redux'
import { IState } from 'store'
import { productApiResquestService } from 'services/productApiService'
import { IAmountProduct } from 'interfaces/IRequestProduct'
import { usePopup } from 'hooks/usePopup'

interface IDialogEditProps {
  isOpen: boolean
  setIsOpen: (data: any) => void
  data: number
  amountProducts: IAmountProduct[]
}

export const DialogEdit = ({ setIsOpen, isOpen, data, amountProducts }: IDialogEditProps) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [valueInput, setValueInput] = useState<number>(0)

  const { addPopup } = usePopup()
  const { updateSettings } = useConfig()
  const separations = useSelector<IState, number>(state => state.settings.separations)
  const validateSchema = Yup.object().shape({
    separations: Yup.number().required('Campo obrigatório'),
  })

  const { handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(validateSchema),
  })

  const { errors } = formState

  const productsOverCurrent = amountProducts.filter(item => item.separationOrder > valueInput)

  const isReduceSeparations = productsOverCurrent.length

  const handleClose = () => {
    setIsOpen({
      isOpen: false,
      data: 0,
    })
  }

  const handleSubmitForm = async (data: any) => {
    setIsLoading(true)
    try {
      await updateSettings(data)
    } catch (err: any) {
      addPopup({
        title: 'Erro ao atualizar configurações',
        type: 'error',
      })
    }
    setIsLoading(false)
    handleClose()
  }

  const onChangeValue = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    setValueInput(value)
    setValue('separations', value)
  }

  useEffect(() => {
    setValueInput(Number(separations))
  }, [])

  return (
    <Dialog open={isOpen} className={classes.root} onClose={handleClose}>
      <Container component="div" className={classes.container}>
        <Typography className={classes.title}>É aqui que cê altera o n° de etapas!</Typography>

        <IconButton onClick={handleClose} className={classes.closeBtn}>
          <CloseIcon />
        </IconButton>

        <Box component="form" onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                style={{ width: '100%' }}
                label="Total de etapas na separação"
                variant="outlined"
                type="number"
                error={!!errors?.separations}
                helperText={errors?.separation?.message}
                defaultValue={separations}
                onChange={onChangeValue}
              />
            </Grid>
            {productsOverCurrent.length ? (
              <Box>
                <Typography className={classes.infoError}>
                  Existem produtos com etapa de separação superior a quantidade de etapas de
                  separação definidas.
                  {productsOverCurrent.map(item => (
                    <Typography
                      key={item.separationOrder * 4}
                      style={{
                        color: '#757575',
                        fontSize: '0.9rem',
                        margin: '0.5rem 0',
                      }}
                    >
                      Etapa: {item.separationOrder} - {item.qtdProducts} Produtos
                    </Typography>
                  ))}
                </Typography>
              </Box>
            ) : null}

            <Grid item xs={12} className={classes.btnSubmit}>
              {isReduceSeparations ? (
                <Button
                  type="submit"
                  isLoading={isLoading}
                  variant="contained"
                  style={{ backgroundColor: '#DA3737' }}
                >
                  Salvar
                </Button>
              ) : (
                <Button type="submit" isLoading={isLoading} variant="contained">
                  Salvar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  )
}
