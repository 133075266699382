import { IStatus } from 'interfaces/IStatus'

interface Props {
  checking_status: number
}

export const verifyStatus = (props: Props): IStatus => {
  const { checking_status } = props

  if (checking_status === 1) {
    return 'in separation'
  }

  if (checking_status === 2) {
    return 'conference'
  }

  if (checking_status === 3) {
    return 'separated'
  }

  if (checking_status === 4) {
    return 'loaded'
  }

  if (checking_status === 5) {
    return 'waiting reposition'
  }

  return 'waiting'
}
