import { Checkbox, LinearProgress, Paper, styled, TableBody, TableRow } from '@material-ui/core'
import { IOptions, OptionsMenu } from 'components'
import { IOrder } from 'interfaces/IOrder'
import { IOnChange } from 'interfaces/IPagination'
import { Dispatch, SetStateAction } from 'react'
import { Link } from 'react-router-dom'
import { Scrollbar, Table as TableCustom, TableCellBody } from 'shared'
import NoData from 'shared/TableCustom/NoData'
import TableCellHead from 'shared/TableCustom/TableCellHead'
import TableContainer from 'shared/TableCustom/TableContainer'
import TableHead, { TableRowHead } from 'shared/TableCustom/TableHead'
import Pagination from 'shared/TableCustom/TablePagination'
import { IColumn, IParamsPagination } from 'shared/TableCustom/types'
import { getNameClient, storeMap } from 'utils'
import { verifyStatus } from 'utils/verifyStatus'

import { ClipStatus } from './ClipStatus'
import { ClipStatusOrder } from './ClipStatusOrder'

const LinkStyle = styled(Link)(({ theme }) => ({
  color: '#353535',
  textDecoration: 'initial',
  '&:hover': {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
  },
}))

const columns: IColumn<IOrder>[] = [
  {
    field: 'order_id',
    label: 'Nº do pedido',
    disabledSort: true,
    style: { minWidth: '160px' },
  },
  {
    field: 'client_data',
    label: 'Cliente',
    disabledSort: true,
    style: { minWidth: '160px', maxWidth: '180px' },
  },
  {
    field: 'store_id',
    label: 'Loja',
    align: 'center',
    disabledSort: true,
    style: { minWidth: '140px', maxWidth: '180px' },
  },
  {
    field: 'status_order',
    label: 'Status do pedido',
    align: 'center',
    disabledSort: true,
    style: { minWidth: '160px', maxWidth: '180px' },
  },
  {
    field: 'route',
    label: 'Rota',
    align: 'center',
    disabledSort: true,
  },
  {
    field: 'scheduled_date',
    label: 'Data de entrega',
    align: 'center',
    disabledSort: true,
    style: { minWidth: '160px' },
  },
  {
    field: 'status',
    label: 'Status',
    align: 'center',
    disabledSort: true,
  },
  {
    field: '',
    label: '',
    align: 'center',
  },
]

interface Props {
  options: IOptions<IOrder>[]
  count: number
  data: IOrder[]
  params: IParamsPagination
  status: 'initial' | 'success' | 'error' | 'pending'
  refresh: () => Promise<void> | void
  onChange: (x: IOnChange) => void
  selected: string[]
  setSelected: Dispatch<SetStateAction<string[]>>
}

export const Table = (props: Props) => {
  const { options, data, onChange, params, status, refresh, count, selected, setSelected } = props

  const isNoData = data.length === 0

  const isSelected = (id: string) => selected.some(orderId => orderId === id)

  const handleClick = (row: IOrder) => {
    setSelected(state => {
      const selectedItem = state.some(orderId => orderId === row.order_id)
      if (selectedItem) {
        const filterItems = state.filter(orderId => orderId !== row.order_id)
        return filterItems
      }

      return [...state, row.order_id]
    })
  }

  return (
    <Paper style={{ margin: '1rem 0 1rem', padding: '1rem 0' }}>
      <TableContainer style={{ minHeight: isNoData ? '21rem' : 'initial' }}>
        <TableCustom size="medium">
          <TableHead>
            <TableRowHead>
              <TableCellHead padding="checkbox" type="checkbox" column="checkbox">
                {' '}
              </TableCellHead>

              {columns.map(column => (
                <TableCellHead
                  key={column?.field}
                  style={column?.style}
                  column={column.field || ''}
                  align={column?.align}
                  onChange={onChange}
                  disabled={column?.disabledSort}
                  paginationParams={{
                    orderDirection: params?.orderDirection,
                    orderBy: params?.orderBy,
                  }}
                >
                  {column.label}
                </TableCellHead>
              ))}
            </TableRowHead>
            <TableRowHead>
              <TableCellBody
                colSpan={columns.length}
                style={{ padding: '0.4rem 0.7rem', border: 'none' }}
              >
                {status === 'pending' && (
                  <LinearProgress color="secondary" style={{ marginTop: '-10px' }} />
                )}
              </TableCellBody>
            </TableRowHead>
          </TableHead>

          <TableBody>
            {data
              .map(order => ({
                ...order,
                isItemSelected: isSelected(order.order_id),
              }))
              .map(order => (
                <TableRow
                  key={order.order_id}
                  selected={order.isItemSelected}
                  aria-checked={order.isItemSelected}
                >
                  <TableCellBody padding="checkbox" style={{ paddingLeft: '1.5rem' }}>
                    <Checkbox
                      onClick={() => handleClick(order)}
                      checked={order.isItemSelected}
                      color="primary"
                    />
                  </TableCellBody>

                  <TableCellBody>
                    <LinkStyle to={`${order?.order_id}?view=${true}`}>{order?.order_id}</LinkStyle>
                  </TableCellBody>

                  <TableCellBody>
                    <LinkStyle to={`${order?.order_id}?view=${true}`}>
                      {getNameClient(order.client_data)}
                    </LinkStyle>
                  </TableCellBody>

                  <TableCellBody align="center">{storeMap(order?.store_id)}</TableCellBody>

                  <TableCellBody align="center">
                    <ClipStatusOrder status={order.permission_to_separate} />
                  </TableCellBody>

                  <TableCellBody align="center">{order.route}</TableCellBody>
                  <TableCellBody align="center">
                    {order.scheduled_date?.replace(/-/g, '/')}
                  </TableCellBody>
                  <TableCellBody align="center">
                    <ClipStatus status={verifyStatus({ checking_status: order.checking_status })} />
                  </TableCellBody>

                  <TableCellBody>
                    <OptionsMenu data={order} options={options} />
                  </TableCellBody>
                </TableRow>
              ))}
          </TableBody>
        </TableCustom>
        {status !== 'pending' && status !== 'initial' && isNoData && (
          <NoData status={status} refresh={refresh} />
        )}
      </TableContainer>
      <Scrollbar>
        <Pagination
          page={params.page}
          count={count}
          onChange={(_, newPage: number) => onChange({ page: newPage })}
          rowsPerPage={params.pageSize}
          handleRowsPerPageChange={newPageSize => onChange({ pageSize: newPageSize, page: 1 })}
        />
      </Scrollbar>
    </Paper>
  )
}
