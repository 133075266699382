import { Box, Button, Typography, styled } from '@material-ui/core'
import { ContainerPage, TitlePage, Title } from 'components'
import { ClientCritical } from 'components/ClientCritical'
import { Edit, IEdit } from 'components/ModalEditOrder/Edit'
import {
  ContainerInfos,
  ModalPrinter,
  Separations,
  SkeletonLoading,
  SummaryOrder,
  Boxes,
} from 'components/pages/OrderDetails'
import { useOrderDetails } from 'hooks/useOrderDetails'
import { enStatus } from 'interfaces/IOrder'
import { useState, useRef, useMemo } from 'react'
import { AiOutlinePrinter } from 'react-icons/ai'
import { FaCheck } from 'react-icons/fa'
import { IoIosArrowForward } from 'react-icons/io'
import { MdModeEditOutline, MdNotInterested } from 'react-icons/md'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getLastUpdateHourAndMinute } from 'utils'

import { ContainerTipInfo, useStyles } from './styles'

const InfoStyle = styled(Typography)({
  color: '#757575',
  fontWeight: 400,
  fontSize: '0.87rem',
  lineHeight: '1.31rem',
  marginBottom: '0.25rem',
  '& strong': {
    color: '#353535',
    fontWeight: 500,
    fontSize: '1rem',
  },
})

const OrderDetails = () => {
  const classes = useStyles()
  const [search] = useSearchParams()
  const [modalReprint, setModalReprint] = useState(false)
  const navigate = useNavigate()
  const {
    orderData,
    summaryData,
    separations,
    isLoading,
    refresh,
    orderConferenceData,
    totalBoxesOrder,
    endtimeOrder,
    boxes,
  } = useOrderDetails()
  const editRef = useRef<IEdit>(null)
  const handleOpenReprint = () => setModalReprint(true)
  const handleCloseReprint = () => setModalReprint(false)
  const { startSeparation, timeLength, separatorName } = useMemo(() => {
    const workSeparation = separations.find(separation => separation.items?.length)?.items[0]?.work
    return {
      startSeparation: workSeparation?.begin,
      timeLength: workSeparation?.time_length,
      separatorName: workSeparation?.separator?.username,
      endTimeSeparation: workSeparation?.end,
    }
  }, [separations])
  const view = search.get('view')
  return (
    <>
      <ContainerPage>
        <TitlePage className={classes.titlePage}>
          <button onClick={() => navigate(-1)} className={classes.link}>
            {view ? 'Pedidos' : 'Dashboard'}
          </button>
          <IoIosArrowForward fontSize="1.125rem" />
          {orderData?.order_id && `#${orderData?.order_id}`}
        </TitlePage>

        {isLoading ? (
          <SkeletonLoading />
        ) : (
          <>
            <Box component="header" className={classes.header}>
              <Box>
                <Typography variant="h1" className={classes.clientName}>
                  {orderData?.client_data?.client_type === 'PF'
                    ? orderData?.client_data?.client_name
                    : orderData?.client_data?.client_trade}
                  {orderData?.is_critical && <ClientCritical />}
                </Typography>
                <strong className={classes.orderId}>#{orderData?.order_id}</strong>
              </Box>

              <Box style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                <Button
                  className={classes.btnEdit}
                  onClick={() => {
                    editRef.current?.handleData(orderData)
                  }}
                >
                  <MdModeEditOutline size={25} /> Editar Pedido
                </Button>
                <Button className={classes.btnReprint} onClick={handleOpenReprint}>
                  <AiOutlinePrinter size={25} color="#757575" />
                  Reimprimir etiqueta
                </Button>
              </Box>
            </Box>
            <ContainerTipInfo>
              {orderConferenceData.has_been_reviewed && (
                <Box
                  className={classes.box}
                  style={{
                    backgroundColor: '#E5F6EA',
                    width: '175px',
                    flexShrink: '0',
                    height: '45px',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <FaCheck
                      color="white"
                      style={{
                        fill: '#4EAA68',
                      }}
                    />
                    <p
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        color: '#4EAA68',
                      }}
                    >
                      Pedido revisado
                    </p>
                  </div>
                </Box>
              )}
              {orderData.checking_status && !orderConferenceData.has_been_reviewed && (
                <Box
                  className={classes.box}
                  style={{ backgroundColor: '#f77c7c', width: '230px', height: '45px' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <MdNotInterested
                      color="white"
                      style={{
                        fill: '#4E0000',
                        fontSize: '1rem',
                      }}
                    />
                    <p
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        color: '#4E0000',
                      }}
                    >
                      Pedido não revisado
                    </p>
                  </div>
                </Box>
              )}
              {orderData?.replacement_order && (
                <Box
                  className={classes.box}
                  style={{ backgroundColor: '#EFEFEF', height: '45px', padding: '4px 16px' }}
                >
                  <div style={{ display: 'flex' }}>
                    <InfoStyle
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        color: '#7B7B7B',
                      }}
                    >
                      Reposição do pedido {orderData?.replacement_order?.old_order}
                    </InfoStyle>
                  </div>
                </Box>
              )}

              {orderData.permission_to_separate === enStatus.recused && (
                <Box
                  className={classes.box}
                  style={{ backgroundColor: '#FFDBCD', width: '180px', height: '45px' }}
                >
                  <div style={{ display: 'flex' }}>
                    <InfoStyle
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        color: '#BB1F32',
                      }}
                    >
                      Pedido cancelado
                    </InfoStyle>
                  </div>
                </Box>
              )}
            </ContainerTipInfo>

            <ContainerInfos {...orderData} />
            <Box className={classes.content}>
              <Box width="100%" className={classes.containerSeparations}>
                {orderData?.instructions && (
                  <Box className={classes.instructions}>
                    <Title style={{ color: '#353535' }}>Observação do pedido</Title>
                    <Typography>{orderData?.instructions}</Typography>
                  </Box>
                )}

                <Boxes boxes={boxes} totalBoxes={totalBoxesOrder} />

                <Separations separations={separations} order={orderData} refresh={refresh} />

                <Box mt="1rem">
                  <InfoStyle>
                    Quantidade de caixas: <strong>{totalBoxesOrder || 'Ainda não definido'}</strong>
                  </InfoStyle>
                </Box>
                <Box mt="0.5rem">
                  <InfoStyle>
                    Finalizado em: <strong>{getLastUpdateHourAndMinute(endtimeOrder)}</strong>
                  </InfoStyle>
                </Box>
              </Box>
              <SummaryOrder
                textTotal="Total de itens"
                textCompleted="Itens finalizados"
                textPedding="Itens em separação"
                textTimer="Tempo de separação"
                textSeparator="Separado por"
                textConferencer="Conferido por"
                completed={summaryData.completed}
                totalItens={summaryData.totalItens}
                itensPending={summaryData.itensPending}
                separations={separations}
                startSeparation={startSeparation}
                timeLength={timeLength}
                separatorName={separatorName}
                conferencerName={orderConferenceData?.checked_by?.username}
              />
            </Box>
          </>
        )}
      </ContainerPage>

      <ModalPrinter
        open={modalReprint}
        handleClose={handleCloseReprint}
        track={orderData?.separation_group}
        orderId={orderData?.order_id}
      />
      <Edit ref={editRef} refresh={refresh} />
    </>
  )
}

export default OrderDetails
