import { Theme, makeStyles, styled } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: '60px',
    minWidth: '280px',
  },
  root: {
    maxWidth: '300px',
    borderRadius: '50%',
    aspectRatio: '1/1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  total: {
    fontSize: '6rem',
    fontWeight: 500,
    color: '#353535',
  },
  text: {
    fontSize: '1.3rem',
    fontWeight: 500,
    color: '#353535',
    position: 'absolute',
    bottom: -15,
  },
  circularMui: {
    position: 'absolute',
    width: '100% !important',
    height: '100% !important',
    zIndex: 0,
  },
  backgroundCircular: {
    position: 'absolute',
    width: '100% !important',
    height: '100% !important',
    zIndex: -1,
    '& svg': {
      color: '#F0F0F0',
    },
  },
  [theme.breakpoints.down(900)]: {
    container: {
      paddingTop: '60px',
      minWidth: '200px',
    },
    total: {
      fontSize: '4rem',
      fontWeight: 500,
      color: '#353535',
    },
    text: {
      fontSize: '1rem',
      fontWeight: 500,
      color: '#353535',
      position: 'absolute',
      bottom: -15,
    },
  },
  [theme.breakpoints.down(700)]: {
    container: {
      paddingTop: '60px',
      minWidth: '150px',
    },
    total: {
      fontSize: '3rem',
      fontWeight: 500,
      color: '#353535',
    },
    text: {
      fontSize: '0.8rem',
      fontWeight: 500,
      color: '#353535',
      position: 'absolute',
      bottom: -15,
    },
  },
}))

export const TooltipStyled = styled('span')(({ color }: { color: string }) => ({
  position: 'absolute',
  top: -60,
  background: color,
  display: 'block',
  width: '90px',
  height: '40px',
  color: 'white',
  fontSize: '1.5rem',
  textAlign: 'center',
  lineHeight: '40px',
  borderRadius: '20px',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '98%',
    left: '50%',
    marginLeft: '-10px',
    width: '0',
    height: '0',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: `10px solid ${color}`,
  },
}))
