import { Box, Typography } from '@material-ui/core'
import { Button, ContainerPage } from 'components'
import { DialogEdit, ReturnArea } from 'components/pages/Config/'
import { useConfig } from 'hooks/useConfig/Index'
import { RefObject, useEffect, useRef, useState } from 'react'
import { FaCog } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { ISettingsRequest } from 'services/settingsService'
import { IState } from 'store'

import { useStyles } from './styles'
import { animateValue } from 'utils/animateNumber'

export const Goals = () => {
  const classes = useStyles()
  const ref: RefObject<HTMLElement> = useRef(null)
  const { updateSettings } = useConfig()
  const settings = useSelector<IState, ISettingsRequest>(state => state.settings)
  const [dialogEditSeparation, setDialogEditSeparation] = useState({
    isOpen: false,
    data: 0,
  })

  useEffect(() => {
    animateValue(ref.current as HTMLElement, 0, settings.goalSeparate, 2000)
  }, [settings.goalSeparate])

  return (
    <>
      <ContainerPage>
        <ReturnArea title="Metas" />

        <Box className={classes.boxAlign}>
          <Typography className={classes.titlePage}>Metas</Typography>

          <Button
            onClick={() => {
              setDialogEditSeparation({
                isOpen: true,
                data: settings.separations,
              })
            }}
            className={classes.configs}
          >
            <FaCog size={'1rem'} style={{ marginRight: '0.6rem' }} /> Editar
          </Button>
        </Box>

        <Box className={classes.containner}>
          <Typography className={classes.textWarning} ref={ref} />
          <Typography className={classes.textHelper}>
            Quantidade de pedidos para cada separador
          </Typography>
        </Box>
      </ContainerPage>

      <DialogEdit
        submit={updateSettings}
        setIsOpen={setDialogEditSeparation}
        title="Metas"
        field="goalSeparate"
        isOpen={dialogEditSeparation.isOpen}
        data={dialogEditSeparation.data}
        defaultValue={settings.goalSeparate}
      />
    </>
  )
}
