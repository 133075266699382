import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import cookies from 'js-cookie'

interface IApi {
  addPopup: (value: any) => void
  signOut: () => void
}

export class ApiService {
  private addPopup: any = null
  private signOut: any = null
  private isAuthError = false

  public setFuncions({ addPopup, signOut }: IApi) {
    this.addPopup = addPopup
    this.signOut = signOut
  }

  public instance = axios.create({
    headers: {
      Authorization: `Bearer ${cookies.get('authToken')}`,
    },
  })

  interceptor = this.instance.interceptors.response.use(
    (response: AxiosRequestConfig) => {
      return response
    },
    (error: AxiosError) => {
      if (
        error.response?.status === 401 &&
        error.response.data.detail !== 'No active account found with the given credentials'
      ) {
        if (!this.isAuthError) {
          this.isAuthError = true
          this.signOut()
          this.addPopup({
            type: 'info',
            title: 'Token expirado ou inválido.',
          })
        }
      }
      return Promise.reject(error)
    },
  )

  public get = async (url: string, config?: AxiosRequestConfig): Promise<any> => {
    return this.instance
      .get(url, config)
      .then(x => x.data)
      .catch(err => {
        if (err?.message === 'Network Error') throw new Error('Network Error')
        if (axios.isAxiosError(err)) throw err.response?.data

        throw err
      })
  }

  public post = async (url: string, params?: any, config?: AxiosRequestConfig): Promise<any> => {
    return this.instance
      .post(url, params, config)
      .then(x => x.data)
      .catch(err => {
        if (err?.message === 'Network Error') throw new Error('Network Error')
        if (axios.isAxiosError(err)) throw err.response?.data
        throw err
      })
  }

  public put = async (url: string, params?: any, config?: AxiosRequestConfig): Promise<any> => {
    return this.instance
      .put(url, params, config)
      .then(x => x.data)
      .catch(err => {
        if (err?.message === 'Network Error') throw new Error('Network Error')
        if (axios.isAxiosError(err)) throw err.response?.data
        throw err
      })
  }

  public delete = async (url: string, config?: AxiosRequestConfig): Promise<any> => {
    return this.instance
      .delete(url, config)
      .then(x => x.data)
      .catch(err => {
        if (err?.message === 'Network Error') throw new Error('Network Error')
        if (axios.isAxiosError(err)) throw err.response?.data
        throw err
      })
  }

  public patch = async (url: string, params?: any, config?: AxiosRequestConfig): Promise<any> => {
    return this.instance
      .patch(url, params, config)
      .then(x => x.data)
      .catch(err => {
        if (err?.message === 'Network Error') throw new Error('Network Error')
        if (axios.isAxiosError(err)) throw err.response?.data
        throw err
      })
  }
}

export const api = new ApiService()
