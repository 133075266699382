import { AppBar, Box, IconButton, Modal, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import trackImg from 'assets/images/track.svg'
import { Button } from 'components'
import { ConfirmReposition, IFormatReplenishment } from 'hooks/useReplecement'
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react'
import { formatName } from 'utils'

import { useStyles } from './styles'
import { enStatusReplenishment } from 'interfaces/IRequestProduct'

interface Props {
  data: IFormatReplenishment
  handleConfirmReposition: (value: ConfirmReposition) => void
}

export type ModalReplecementProps = {
  handleOpen: () => void
}

export const Component: ForwardRefRenderFunction<ModalReplecementProps, Props> = (props, ref) => {
  const { data, handleConfirmReposition } = props
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => setIsOpen(false)
  const handleOpen = () => setIsOpen(true)

  const confirmBroken = (id: number) => {
    handleConfirmReposition({ status: enStatusReplenishment.QUEBRA, replenishmentId: id })
  }

  const confirmReplenish = (id: number) => {
    handleConfirmReposition({ status: enStatusReplenishment.REABASTECIDO, replenishmentId: id })
  }

  useImperativeHandle(ref, () => {
    return { handleOpen }
  })

  return (
    <Modal open={isOpen} onClose={handleClose} className={classes.root}>
      <Box className={classes.container}>
        <AppBar position="sticky" className={classes.header}>
          <Box className={classes.contentHeader}>
            <img src={trackImg} className={classes.trackImg} />
            <Typography variant="h1" className={classes.title}>
              Grupo de separação {data.separationGroup}
            </Typography>

            <IconButton className={classes.closeBtn} onClick={handleClose}>
              <CloseIcon style={{ fontSize: '1.4rem' }} />
            </IconButton>
          </Box>
        </AppBar>
        <Box className={classes.content}>
          {data.items?.map((product, index) => (
            <Box key={product.id}>
              <Typography variant="subtitle2" className={classes.user}>
                <strong className={classes.nameUser}>{formatName(product.user_request)},</strong>{' '}
                está pedindo:
              </Typography>
              <Box>
                <Box key={product.sku_name + index} className={classes.product}>
                  <Box className={classes.productBox}>
                    <div className={classes.productImg}></div>
                    <Typography>{product.sku_name || product?.sku_name}</Typography>
                  </Box>

                  <Box className={classes.boxBtn}>
                    <Button
                      isDelete
                      variant="contained"
                      className={classes.btnReposition}
                      onClick={() => confirmBroken(product?.id)}
                    >
                      Sem estoque
                    </Button>
                    <Button
                      className={classes.btnReposition}
                      onClick={() => confirmReplenish(product?.id)}
                      variant="contained"
                    >
                      Reposto
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  )
}

export const ModalReplecements = forwardRef(Component)
