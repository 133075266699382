import { usePopup } from 'hooks/usePopup'
import {
  IReplacementProducts,
  IReplenishment,
  IUser,
  enStatusReplenishment,
} from 'interfaces/IRequestProduct'
import { useEffect, useMemo, useRef, useState } from 'react'
import { productResquestService } from 'services/productRequestService'

export type ConfirmReposition = {
  replenishmentId: number
  status: enStatusReplenishment
}

type IReplecement = {
  user: IUser
  products: IReplacementProducts[]
}

type SeparationReplecement = {
  level: number
  replecements: IReplecement[]
}

export type ITrackReplecement = {
  separationGroup: number
  quantityProducts: number
  separations: SeparationReplecement[]
}

export interface IFormatReplenishment {
  separationGroup: number
  items: IReplenishment[]
}

const formatData = (products: IReplenishment[]): IFormatReplenishment[] => {
  return products
    .reduce((acc: IFormatReplenishment[], product) => {
      const separationGroup = acc.find(
        separation => separation.separationGroup === product.separation_group,
      )

      if (separationGroup) {
        separationGroup.items.push(product)
      }

      if (!separationGroup) {
        acc.push({
          separationGroup: product.separation_group,
          items: [product],
        })
      }

      return acc
    }, [])
    .sort((a, b) => a.separationGroup - b.separationGroup)
}

const messagePopUp = {
  [enStatusReplenishment.REABASTECIDO]: 'Reposição concluída',
  [enStatusReplenishment.QUEBRA]: 'Confirmação concluída',
  [enStatusReplenishment.AGUARDANDO_RESPOSTA]: 'Alterado com sucesso',
}

let controller: AbortController

export const useReplecement = () => {
  const { addPopup } = usePopup()
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const [products, setProducts] = useState<IReplenishment[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const pollingReplecement = async (isError = false) => {

    try {
      controller = new AbortController()
      const productsData = await productResquestService.getReplacementProducts({
        controller,
        replenishmentToday: true,
        status: enStatusReplenishment.AGUARDANDO_RESPOSTA,
      })
      setProducts(productsData)
    } catch (err) {
      if (isError === false && controller.signal.aborted === false) {
        addPopup({
          type: 'error',
          title: 'Erro ao buscar solitações',
        })
      }
    } finally {
      setIsLoading(false)
      if (controller.signal.aborted === false) {
        timeout.current = setTimeout(() => pollingReplecement(true), 7000)
      }
    }
  }

  const removingProduct = (id: number) => {
    setProducts(state => state.filter(product => product.id !== id))
  }

  const handleConfirmReposition = async ({ replenishmentId, status }: ConfirmReposition) => {
    try {
      await productResquestService.confirmReplacement({ status, replenishmentId })
      removingProduct(replenishmentId)
      addPopup({
        type: 'success',
        title: messagePopUp[status],
      })
    } catch {
      addPopup({
        type: 'error',
        title: 'Erro ao repor produto',
      })
    }
  }

  const data = useMemo(() => formatData(products), [products])

  useEffect(() => {
    pollingReplecement()

    return () => {
      controller?.abort()
      // eslint-disable-next-line no-undef
      clearTimeout(timeout.current as NodeJS.Timeout)
    }
  }, [])

  return { data, isLoading, handleConfirmReposition }
}
