import { makeStyles, withStyles, createStyles, createTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import TableFooter from '@material-ui/core/TableFooter'
import Paper from '@material-ui/core/Paper'
import { IOrder } from 'interfaces/IOrder'
import React, { useState } from 'react'

const Customtheme = createTheme({
  palette: {
    primary: {
      main: '#f2f2f2',
    },
  },
})

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: Customtheme.palette.primary.main,
      },
    },
  }),
)(TableRow)

const useStyles = makeStyles({
  table: {
    minWidth: 200,
    maxWidth: 650,
  },
  root: {
    overflowX: 'visible',
    marginTop: '15px',
    width: '700px',
    display: 'flex',
    justifyContent: 'center',
  },
  tablePaginationActions: {
    '&.MuiTableCell-root .MuiTablePagination-actions': {
      marginLeft: '0px',
    },
  },
})

export default function CriticalOrdersConfereceTable({ orders }: { orders: IOrder[] }) {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <TableContainer classes={{ root: classes.root }} component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Pedido</TableCell>
              <TableCell align="center">Cliente</TableCell>
              <TableCell align="center">Separador</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : orders
            ).map(row => (
              <StyledTableRow key={row.order_id}>
                <TableCell component="th" scope="row">
                  {row.order_id}
                </TableCell>
                <TableCell align="center">
                  {row.client_data?.client_name || row.client_data?.client_trade}
                </TableCell>
                <TableCell align="center">{row.separator?.username}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
