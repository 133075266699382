import { useConfig } from 'hooks/useConfig/Index'
import { ILogin } from 'interfaces/ILogin'
import cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { FC, useContext, createContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from 'store'
import { useNavigate } from 'react-router'
import { api } from 'services/api'
import { authenticationService } from 'services/authenticationService'
import permissionsService from 'services/permissionsService'
import { separationWorkService } from 'services/separationWorkService'
import { resetStates, updateNameUser, updateRunningMachine } from 'store/reducers/infos/actions'
import { updateUser } from 'store/reducers/user/actions'
import { formatName } from 'utils'

import { toggleScanner } from '../../store/reducers/toggleScanner/actions'
import { usePopup } from '../usePopup'
import { clearOrderFastTrack } from 'store/reducers/orderFastTrack/actions'

type IUseAuth = {
  signIn: (props: ILogin) => Promise<void>
  signOut: () => void
  redirectUser: () => Promise<void>
}

const AuthContext = createContext<IUseAuth>({} as IUseAuth)

const AuthProvider: FC = ({ children }) => {
  const dispatch = useDispatch()
  const { getSetting } = useConfig()
  const { addPopup } = usePopup()
  const navegate = useNavigate()
  const { settings, infos } = useSelector<IState, IState>(state => state)
  const getRules = async () => {
    try {
      const data = await permissionsService.getCurrentUser()
      const name = formatName(data.user_login)
      dispatch(
        updateUser({
          isAuthenticated: true,
          userPermissions: data.user_groups_names,
          isLoading: false,
          userId: data.user_id,
        }),
      )
      dispatch(updateNameUser(name || ''))
      return redirectUser()
    } catch {
      dispatch(
        updateUser({
          isAuthenticated: true,
          userPermissions: [],
          isLoading: false,
          userId: 0,
        }),
      )
      addPopup({
        type: 'error',
        title: 'Erro ao procurar permissões',
      })
    }
  }

  const signIn = async (data: ILogin): Promise<void> => {
    try {
      const { access } = await authenticationService.postLogin(data)

      dispatch(updateNameUser(data.username))

      const date = new Date()
      date.setHours(date.getHours() + 11)
      cookies.set('authToken', access, {
        expires: date,
      })

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Unreachable code error
      // eslint-disable-next-line dot-notation
      api.instance.defaults.headers['Authorization'] = `Bearer ${access}`
      await getSetting()
      await getRules()

      addPopup({
        type: 'success',
        title: 'Logado com sucesso',
      })
    } catch (error: any) {
      if (error?.detail === 'No active account found with the given credentials') {
        addPopup({
          type: 'error',
          title: 'Credenciais inválidas',
        })
        return
      }

      addPopup({
        type: 'error',
        title: error?.message ?? 'Ocorreu um erro, contate o administrador.',
      })
    }
  }
  const redirectUser = async () => {
    try {
      const response = await separationWorkService.checkWork()
      const separation_group = response.data.order.separation_group

      dispatch(updateRunningMachine(separation_group))
      navegate(`operacao/separacao/pedido`)
    } catch (error: any) {
      if (error.message === 'Unfinished works not found!') {
        return
      }

      return addPopup({
        type: 'error',
        title: 'Ocorreu um erro, contate o administrador.',
      })
    }
  }

  const signOut = () => {
    cookies.remove('authToken')
    dispatch(resetStates())
    dispatch(clearOrderFastTrack())
    dispatch(
      updateUser({
        isAuthenticated: false,
        userPermissions: [],
      }),
    )
    navegate('/login')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Unreachable code error
    // eslint-disable-next-line dot-notation
    api.instance.defaults.headers['Authorization'] = ''
  }

  const verifyAuth = () => {
    const token = cookies.get('authToken')
    if (token) {
      try {
        const decodedToken = jwtDecode(token) as any
        const currentDate = new Date()

        if (decodedToken.exp * 1000 < currentDate.getTime()) {
          signOut()
          addPopup({
            type: 'info',
            title: 'Login expirado',
          })

          return
        }

        getSetting()
        getRules()
      } catch (err) {
        signOut()
        addPopup({
          type: 'info',
          title: 'Login expirado',
        })
      }
    } else {
      signOut()
    }
  }

  useEffect(() => {
    const separationGroup = settings.separationGroups.find(
      ({ separation_group }) => Number(separation_group) === (infos.runningMachine || 0),
    )
    const hasScanner = !!(separationGroup?.cam_scanner || separationGroup?.infrared_scanner)

    dispatch(toggleScanner(hasScanner))
  }, [settings.separationGroups, infos.runningMachine])

  useEffect(() => {
    api.setFuncions({
      addPopup: addPopup,
      signOut: signOut,
    })

    verifyAuth()
  }, [])

  return (
    <AuthContext.Provider value={{ signIn, signOut, redirectUser }}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = useContext(AuthContext)
  return context
}

export { AuthProvider, useAuth }
