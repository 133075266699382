import { Typography } from '@material-ui/core'
import runningMachineImg from 'assets/images/track.svg'
import { AiOutlineContainer } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { IState } from 'store'
import { IInfosReducer } from 'store/reducers/infos/reducer'

import { useStyles } from './styles'

interface IInformation {
  isSeparating?: boolean
}

export const Information = ({ isSeparating }: IInformation) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const { runningMachine, separation } = useSelector<IState, IInfosReducer>(state => state.infos)

  return (
    <div className={`${classes.containerNamePage} ${isSeparating} ? ${classes.container} : null`}>
      {pathname.includes('conferencia') && (
        <div className={classes.box} data-cy="runningMachine">
          <div className={classes.image}>
            <AiOutlineContainer size={15} color="#89BD23" data-testid="icon" />
          </div>
          <Typography
            className={`${isSeparating} ? ${classes.descriptionUpdated} :${classes.description}`}
          >
            Conferência de pedidos
          </Typography>
        </div>
      )}

      {runningMachine && (
        <div className={classes.box} data-cy="runningMachine">
          {pathname.includes('conferencia') ? null : (
            <div className={classes.image}>
              <img src={runningMachineImg} alt="Esteira" />
            </div>
          )}

          <Typography
            className={`${isSeparating}? ${classes.descriptionUpdated} :${classes.description}`}
          >
            {pathname.includes('conferencia') ? (
              <strong>Grupo {runningMachine} </strong>
            ) : (
              <>Grupo {runningMachine} /</>
            )}
          </Typography>
        </div>
      )}

      {separation && (
        <div className={classes.box} data-cy="separation">
          <Typography
            className={`${isSeparating}? ${classes.descriptionUpdated} :${classes.description}`}
          >
            <strong>
              {separation === 'caixa pronta' ? 'Caixa pronta' : `Separação ${separation}`}
            </strong>
          </Typography>
        </div>
      )}
    </div>
  )
}
