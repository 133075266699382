import { Box, LinearProgress } from '@material-ui/core'
import ModalScan from './ModalScan'
import TableRoutesOver from './TableRoutesOver'
import { useRoutesOver } from './useRoutesOver'
import ModalBoxFull from './ModalBoxFull'
import TableRoutesReduced from './TableRoutesReduced'

const RoutesOver = () => {
  const {
    loading,
    onClickRoute,
    onClickRouteHidden,
    onCloseModalScan,
    openModal,
    options,
    optionsOculted,
    refresh,
    routesHidden,
    routesOver,
    openModalBoxFull,
    routesReduced,
    routesNotReduced,
    optionsRouteNotReduced,
    onClickRouteNotReduced,
  } = useRoutesOver()

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <LinearProgress style={{ width: '100%', opacity: loading ? 1 : 0 }} />
      <TableRoutesOver
        data={routesOver}
        onClickRoute={onClickRoute}
        subtitle="Clique em uma rota para diminuir caixas"
        options={options}
      />

      <TableRoutesOver
        data={routesHidden}
        onClickRoute={onClickRouteHidden}
        subtitle="Rotas ocultas"
        styleContainer={{ marginTop: '4rem' }}
        options={optionsOculted}
      />

      <TableRoutesReduced
        data={routesReduced}
        subtitle="Rotas reduzidas"
        styleContainer={{ marginTop: '4rem' }}
      />

      <TableRoutesOver
        data={routesNotReduced}
        onClickRoute={onClickRouteNotReduced}
        subtitle="Rotas que não foram possíveis reduzir"
        options={optionsRouteNotReduced}
      />

      {openModal.open && (
        <ModalScan
          open={openModal.open}
          data={openModal.data}
          onClose={onCloseModalScan}
          refresh={refresh}
        />
      )}

      {openModalBoxFull.open && (
        <ModalBoxFull callback={openModalBoxFull.callback} data={openModalBoxFull.data} />
      )}
    </Box>
  )
}

export default RoutesOver
