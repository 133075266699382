import {
  makeStyles,
  styled,
  Typography,
  Button as ButtonMui,
  Container as ContainerMui,
  Box as BoxMui,
  Dialog as DialogMui,
} from '@material-ui/core'
import { Link as LinkRouter } from 'react-router-dom'

export const Container = styled(ContainerMui)({
  padding: '2.5rem 4rem',
  maxWidth: '550px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

export const Title = styled(Typography)({
  color: '#353535',
  fontWeight: 600,
  fontSize: '1.375rem',
  textAlign: 'center',
  marginBottom: '1rem',
})

export const SubTitle = styled(Typography)({
  color: '#353535',
  textAlign: 'center',
  marginBottom: '1.8rem',
})

export const BoxImg = styled(BoxMui)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > img:first-child': {
    width: '40%',
    maxWidth: '150px',
  },

  '& > img:last-child': {
    width: '40%',
    maxWidth: '150px',
  },
})

export const Button = styled(ButtonMui)({
  width: '100%',
  border: '1px solid #353535',
  borderRadius: '4rem',
  padding: '0.75rem',
  textAlign: 'center',
  marginTop: '2rem',
})

export const useStyles = makeStyles({
  root: {},
  separatorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#414141',
    fontSize: '1.125rem',
    '& strong': {
      fontWeight: 500,
      flex: 1,
    },
  },
  nameSeparator: {
    color: '#353535',
    fontWeight: 500,
    fontFamily: '"Inter", sans-serif',
    fontSize: '1.3rem',
    marginBottom: '0.5rem',
    textTransform: 'capitalize',
  },
})

export const Dialog = styled(DialogMui)({
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '510px',
    width: '100%',
    height: '100%',
    maxHeight: '403px',
    overflow: 'none',
    overflowY: 'initial',
  },
})

export const Link = styled(LinkRouter)({
  width: '100%',
  border: '1px solid #353535',
  height: '42px',
  borderRadius: '42px',
  display: 'block',
  lineHeight: '42px',
  textAlign: 'center',
  textDecoration: 'none',
  fontSize: '14px',
  color: '#353535',
  marginTop: '2rem',
})
