import { Box, MenuItem, Typography } from '@material-ui/core'
import { ContainerPage, TitlePage } from 'components'
import { ContainerInfos } from 'components/pages/Operators'
import { usePopup } from 'hooks/usePopup'
import { ISeparatorData } from 'interfaces/ISeparator'
import { useEffect, useMemo, useRef, useState } from 'react'
import Chart from 'react-apexcharts'
import { IoIosArrowForward } from 'react-icons/io'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router'
import { separatorService } from 'services/separatorService'
import { Search, MenuPopover } from 'shared'
import { convertHours, debaunce, formatName, mapFilter } from 'utils'

import { useStyles } from './styles'

type filterProps = 'today_data' | 'week_data' | 'month_data' | 'all_time_data'

const OperatorDetail = () => {
  const filterRef = useRef(null)
  const { addPopup } = usePopup()
  const { usuarioId } = useParams()
  const navigate = useNavigate()
  const classes = useStyles()
  const searchDebaunce = debaunce({ fn: handleSearch, delay: 300 })
  const [isFilter, setIsFilter] = useState(false)
  const [filter, setFilter] = useState<filterProps>('today_data')
  const [separatorData, setSeparatorData] = useState<ISeparatorData>({} as ISeparatorData)

  function handleSearch(value: string) {
    navigate(`../usuarios?search=${value}`)
  }

  const handleChangeFilter = (value: filterProps) => {
    setFilter(value)
    setIsFilter(false)
  }

  const { items, kilos, orders } = useMemo(() => {
    return { ...separatorData[filter] }
  }, [filter, separatorData])

  const getData = async () => {
    try {
      const data = await separatorService.getSeparatorData(Number(usuarioId) as number)
      setSeparatorData(data)
    } catch {
      navigate('./separadores')
      addPopup({
        type: 'error',
        title: 'Erro ao procurar dados do usuário',
      })
    }
  }

  useEffect(() => {
    if (!usuarioId) {
      navigate('./separadores')
      return
    }
    getData()
  }, [])

  const { month_data, today_data, week_data, all_time_data } = separatorData

  return (
    <ContainerPage>
      <Box component="header" className={classes.header}>
        <TitlePage className={classes.titlePage}>
          <button onClick={() => navigate(-1)} className={classes.link}>
            Usuários
          </button>
          <IoIosArrowForward fontSize="1.125rem" />
          {formatName(separatorData?.all_time_data?.separator)}
        </TitlePage>

        <Search
          placeholder="Quem você busca?"
          onChange={e => {
            searchDebaunce(e.target.value)
          }}
          className={classes.search}
        />
      </Box>

      <Box className={classes.containerName}>
        <Box>
          <Typography className={classes.operatorName}>
            {formatName(separatorData?.all_time_data?.separator)}
          </Typography>
          <Typography className={classes.operatorUser}>
            {separatorData?.all_time_data?.separator}
          </Typography>
        </Box>

        <Box>
          <button className={classes.btnFilter} onClick={() => setIsFilter(true)}>
            Filtrando por: <strong ref={filterRef}>{mapFilter(filter)}</strong>
            <MdKeyboardArrowDown color="#000000" size={16} />
          </button>

          <MenuPopover
            open={isFilter}
            onClose={() => setIsFilter(false)}
            anchorEl={filterRef.current}
          >
            <MenuItem onClick={() => handleChangeFilter('today_data')}>Hoje</MenuItem>
            <MenuItem onClick={() => handleChangeFilter('week_data')}>Semana</MenuItem>
            <MenuItem onClick={() => handleChangeFilter('month_data')}>Mês</MenuItem>
            <MenuItem onClick={() => handleChangeFilter('all_time_data')}>Todos</MenuItem>
          </MenuPopover>
        </Box>
      </Box>

      <ContainerInfos items={items} kilos={kilos} orders={orders} />

      <Chart
        options={{
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: '40px',
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
          },
          tooltip: {
            shared: false,
            intersect: true,
            y: {
              formatter: function (e, timestamp) {
                let time = e as number
                const hh = Math.floor(time / 1000 / 60 / 60)
                time -= hh * 1000 * 60 * 60
                const mm = Math.floor(time / 1000 / 60)
                time -= mm * 1000 * 60
                const ss = Math.floor(time / 1000)
                return `${String(hh).padStart(2, '0')}:${String(mm).padStart(2, '0')}:${String(
                  ss,
                ).padStart(2, '0')}`
              },
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
          },
          yaxis: {
            labels: {
              style: {
                colors: ['#000000'],
              },
            },
          },
          colors: ['#26a0fc', '#89BD23', '#E9D715'],
          chart: {
            type: 'bar',
            toolbar: {
              show: false,
            },
          },
          xaxis: {
            categories: ['Separação p/ pedido', 'Separação p/ item'],
            axisBorder: {
              color: '#AFAFAF',
            },
            axisTicks: {
              color: '#AFAFAF',
            },
            type: 'category',
            labels: {
              formatter: (e, timestamp) => {
                let time = Number(e)
                const hh = Math.floor(time / 1000 / 60 / 60)
                time -= hh * 1000 * 60 * 60
                const mm = Math.floor(time / 1000 / 60)
                time -= mm * 1000 * 60
                const ss = Math.floor(time / 1000)
                return `${String(hh).padStart(2, '0')}:${String(mm).padStart(2, '0')}:${String(
                  ss,
                ).padStart(2, '0')}`
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: '15px',
              colors: ['#fff'],
            },
            formatter: (e, timestamp) => {
              if (!e) {
                return ''
              }
              let time = e as number
              const hh = Math.floor(time / 1000 / 60 / 60)
              time -= hh * 1000 * 60 * 60
              const mm = Math.floor(time / 1000 / 60)
              time -= mm * 1000 * 60
              const ss = Math.floor(time / 1000)
              return `${String(hh).padStart(2, '0')}:${String(mm).padStart(2, '0')}:${String(
                ss,
              ).padStart(2, '0')}`
            },
          },
        }}
        series={[
          {
            name: 'Hoje',
            data: [
              convertHours(today_data?.time_per_order),
              convertHours(today_data?.time_per_item),
            ],
          },
          {
            name: 'Semana',
            data: [convertHours(week_data?.time_per_order), convertHours(week_data?.time_per_item)],
          },
          {
            name: 'Mês',
            data: [
              convertHours(month_data?.time_per_order),
              convertHours(month_data?.time_per_item),
            ],
          },
          {
            name: 'Total',
            data: [
              convertHours(all_time_data?.time_per_order),
              convertHours(all_time_data?.time_per_item),
            ],
          },
        ]}
        type="bar"
        height={350}
        className={classes.chart}
      />
    </ContainerPage>
  )
}

export default OperatorDetail
