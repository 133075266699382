import MuiAccordion from '@material-ui/core/Accordion'
import { styled } from '@material-ui/core/styles'

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  background: '#FCFCFC',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
  borderRadius: '6px',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

export default Accordion
