import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    color: '#757575',
    fontSize: '1.2rem',
    width: '100%',
  },
})

export const NoRequest = () => {
  const classes = useStyles()

  return (
    <Typography className={classes.root}>
      Nenhuma solicitação encontrada
    </Typography>
  )
}
