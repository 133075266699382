import { Fab, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core'
import { useState, useRef } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { RiPlantLine } from 'react-icons/ri'
import { MenuPopover } from 'shared'

import { useStyles } from './styles'
import { IProduct } from 'interfaces/IProduct'
import { productResquestService } from 'services/productRequestService'
import { useSelector } from 'react-redux'
import { IState } from 'store'
import { usePopup } from 'hooks/usePopup'

interface Props {
  item: IProduct
}

export const FabMenu = (props: Props) => {
  const { addPopup } = usePopup()
  const classes = useStyles()
  const [popover, setPopover] = useState(false)
  const menuRef = useRef(null)
  const separationGroup = useSelector<IState, number>(state => Number(state.infos.runningMachine))

  const togglePopover = () => setPopover(state => !state)

  const requestReposition = async () => {
    try {
      await productResquestService.productRequest({
        sku: props.item.sku,
        separationGroup: separationGroup,
      })
      addPopup({
        type: "success",
        title: `Reposição do sku #${props.item.sku} solicitada com sucesso`,
      })
    } catch (err: any) {
      addPopup({
        type: "error",
        title: "Erro ao solicitar reposição",
      })
    }
  }

  return (
    <>
      <Fab className={classes.btn} onClick={togglePopover} ref={menuRef}>
        <AiOutlineMenu size={30} color="#757575" />
      </Fab>
      <MenuPopover
        open={popover}
        onClose={togglePopover}
        anchorEl={menuRef.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MenuItem className={classes.menuItem} onClick={() => requestReposition()}>
          <ListItemIcon className={classes.menuIcon}>
            <RiPlantLine size={22} />
          </ListItemIcon>
          <ListItemText>Solicitar reposição</ListItemText>
        </MenuItem>
      </MenuPopover>
    </>
  )
}
