import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  styled,
  useRadioGroup,
} from '@material-ui/core'
import * as React from 'react'

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme, checked }: { theme: any; checked: any }) => ({
  '.MuiFormControlLabel-label': checked && {
    color: theme.palette.primary.main,
  },
}))

function MyFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup()

  let checked = false

  if (radioGroup) {
    checked = radioGroup.value === props.value
  }

  return <StyledFormControlLabel checked={checked} {...props} />
}

interface RadioButtonProps {
  value: number | string
  label: string
  color?: 'secondary' | 'primary'
}

const RadioButton = ({ color, ...rest }: RadioButtonProps) => (
  <MyFormControlLabel {...rest} control={<Radio color={color && color} />} />
)

export default RadioButton
