import { TableHead, TableRow as TableRowMui, TableBody } from '@material-ui/core'
import { NotFound, ModalConfirm, ModalConfirmLoad } from 'components/pages/Break'
import { useBreakOrder } from 'hooks/useBreakOrder'
import { BreakOrder, Runouts } from 'interfaces/IBreakOrder'
import { useEffect, useState } from 'react'
import { MdExpandMore } from 'react-icons/md'
import { Scrollbar, Table, TableCellBody, TableCellHead, TableRow } from 'shared'
import Pagination from 'shared/TableCustom/TablePagination'
import { mappingType } from 'utils'

import { Loading } from '../../styles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonReleaseOrder,
  ButtonReplenish,
  CardContainerOrdersStyled,
  ContainerAccordions,
  ContainerLabels,
  ContainerLabelsAccordion,
  ContainerScroll,
  TableContainer,
  Search,
} from './styles'
import { usePopup } from 'hooks/usePopup'

const CardContainerOrders = () => {
  const allowedCheckingStatuses = [1, 5]
  const breakItemStatuses = [3, 4]
  const [internetConnectionOk, setInternetConnectionOk] = useState(false)
  const { addPopup } = usePopup()
  const { data, params, isLoading, onChange, count, search, searchDebaunce, pollingBreak } =
    useBreakOrder()
  const [isConfirm, setIsConfirm] = useState({
    isOpen: false,
    item: {} as Runouts,
    workId: NaN,
    order: {} as BreakOrder,
  })
  const [isOrderLoadConfirmed, setIsOrderLoadConfirmed] = useState({
    isOpen: false,
    order: null as any as BreakOrder,
  })

  const handleCloseModal = () => {
    setIsConfirm({ isOpen: false, item: {} as Runouts, workId: NaN, order: {} as BreakOrder })
  }

  const handleOpenModal = (item: Runouts, workId: number, order: BreakOrder) => {
    setIsConfirm({ isOpen: true, item, workId, order: order })
  }

  const handleCloseOrderLoadConfirm = () => {
    setIsOrderLoadConfirmed({
      isOpen: false,
      order: null as any as BreakOrder,
    })
  }

  const checkInternetConnection = async () => {
    try {
      await fetch('https://www.google.com.br', { mode: 'no-cors' })
      setInternetConnectionOk(true)
    } catch (error) {
      setInternetConnectionOk(false)
      addPopup({
        type: 'error',
        title: 'Verificação de internet no CD executada.',
        description: `O CD está sem internet.`,
      })
    }
  }

  const handleOrderLoadRelease = (e: any, order: BreakOrder) => {
    e.stopPropagation()
    setIsOrderLoadConfirmed({
      isOpen: true,
      order,
    })
  }

  useEffect(() => {
    checkInternetConnection()

    const intervalId = setInterval(() => {
      checkInternetConnection()
    }, 60000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <CardContainerOrdersStyled>
      <Loading loading={isLoading} />
      <Search
        placeholder="N° do pedido"
        onChange={e => {
          searchDebaunce(e.target.value)
        }}
        defaultValue={search}
      />
      {!isLoading && data.length === 0 && <NotFound />}

      {data.length > 0 && (
        <>
          <ContainerScroll>
            <ContainerAccordions>
              <ContainerLabels>
                <span></span>
                <span>Cliente</span>
                <span>N° Pedido</span>
                <span>Itens com quebra</span>
              </ContainerLabels>
              {data.map(order => (
                <Accordion key={order.order_id}>
                  <AccordionSummary
                    expandIcon={<MdExpandMore color="#A3A3A3" />}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <ContainerLabelsAccordion>
                      <span>{order.client_name}</span>
                      <span>{order.order_id}</span>
                      <span>{order.totalItems}</span>
                      <span>
                        <ButtonReleaseOrder
                          onClick={e => {
                            handleOrderLoadRelease(e, order)
                          }}
                        >
                          Liberar pedido
                        </ButtonReleaseOrder>
                      </span>
                    </ContainerLabelsAccordion>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          {order?.runouts?.length > 0 ? (
                            <TableRowMui>
                              <TableCellHead
                                style={{ minWidth: '200px', fontWeight: 600, fontSize: '1.2rem' }}
                                colSpan={2}
                                align="left"
                                disabled
                                column="product"
                              >
                                Produto
                              </TableCellHead>

                              <TableCellHead
                                style={{ minWidth: '200px', fontWeight: 600, fontSize: '1.2rem' }}
                                align="center"
                                disabled
                                column="missed"
                              >
                                Faltou
                              </TableCellHead>
                              <TableCellHead
                                style={{ minWidth: '200px', fontWeight: 600, fontSize: '1.2rem' }}
                                align="right"
                                disabled
                                column="replenish"
                              >
                                {' '}
                              </TableCellHead>
                            </TableRowMui>
                          ) : (
                            <h2 style={{ textAlign: 'center', color: 'green' }}>
                              Nada encontrado para repor. Pode liberar o pedido
                            </h2>
                          )}
                        </TableHead>
                        <TableBody>
                          {order?.runouts?.map(item => (
                            <TableRow key={item?.id}>
                              <TableCellBody
                                align="left"
                                style={{ height: '5.75rem', width: '5.75rem' }}
                              >
                                <img
                                  src={item?.image}
                                  style={{ height: '4.5rem', width: '4.5rem' }}
                                />
                              </TableCellBody>
                              <TableCellBody align="left" style={{ maxWidth: '140px' }}>
                                {item?.name}
                              </TableCellBody>
                              <TableCellBody align="center">
                                {Number(Math.abs(item?.broken_quantity)).toFixed(3)}{' '}
                                {mappingType(item?.unity_product_base)}
                              </TableCellBody>
                              <TableCellBody align="center">
                                {item?.status === 5 && 'Reposto'}
                                {breakItemStatuses.includes(item?.status) && (
                                  <ButtonReplenish
                                    disabled={
                                      !allowedCheckingStatuses.includes(order?.checking_status)
                                    }
                                    onClick={() => handleOpenModal(item, item?.work, order)}
                                  >
                                    {allowedCheckingStatuses.includes(order?.checking_status)
                                      ? 'Repor'
                                      : 'Reposição indisponível'}
                                  </ButtonReplenish>
                                )}
                              </TableCellBody>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              ))}
            </ContainerAccordions>
          </ContainerScroll>
          <Scrollbar>
            <Pagination
              page={params.page}
              count={count}
              onChange={(_, newPage: number) => onChange({ page: newPage })}
              rowsPerPage={params.pageSize}
              handleRowsPerPageChange={newPageSize => onChange({ pageSize: newPageSize, page: 1 })}
            />
          </Scrollbar>
        </>
      )}

      {isConfirm.isOpen && (
        <ModalConfirm
          open={isConfirm.isOpen}
          pollingBreak={pollingBreak}
          handleClose={handleCloseModal}
          item={isConfirm.item}
          workId={isConfirm.workId}
          order={isConfirm.order}
          internetConnectionOk={internetConnectionOk}
        />
      )}

      {isOrderLoadConfirmed.isOpen && (
        <ModalConfirmLoad
          open={isOrderLoadConfirmed.isOpen}
          pollingBreak={pollingBreak}
          handleClose={handleCloseOrderLoadConfirm}
          order={isOrderLoadConfirmed.order}
        />
      )}
    </CardContainerOrdersStyled>
  )
}

export default CardContainerOrders
