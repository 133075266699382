import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles({
  popupContainer: {
    position: 'fixed',
    right: '0',
    top: '0',
    zIndex: 9999,
    '& > div:first-child': {
      marginTop: '30px',
    },
    '& > div': {
      marginRight: '30px',
    },
    '@media(max-width: 600px)': {
      width: '100%',
      padding: '2%',
    },
  },
})
