import { Overrides } from '@material-ui/core/styles/overrides'

const Base = (): Overrides => {
  return {
    MuiCssBaseline: {
      '@global': {
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        html: {
          '@media (max-width: 1080px)': {
            fontSize: '93.75%',
          },
          '@media (max-width: 720px)': {
            fontSize: '87.5%',
          },
        },
        body: {
          '-webkit-font-smoothing': 'antialiased',
          background: '#FAFAFA',
        },
      },
    },
  }
}

export default Base
