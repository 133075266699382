import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: '2.5rem',
    margin: '0.7rem 0 2.90rem',
  },
  carousel: {
    width: '80%',
    padding: '0.81rem',
    marginBottom: '2.90rem',
    height: '7.87rem',
    position: 'relative',
    '& ul': {
      padding: '0.5rem 0 !important',
    },
    '& .BrainhubCarouselItem': {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.between(0, 1290)]: {
      width: '100%',
    },
    [theme.breakpoints.between(1050, 4000)]: {
      '& .BrainhubCarouselItem': {
        width: 'max-content !important',
        minWidth: 'auto !important',
        maxWidth: 'auto !important',
      },
    },
  },
  arrowLeft: {
    cursor: 'pointer',
    outline: 'none',
    position: 'absolute',
    zIndex: 3,
    top: 0,
    left: 0,
    width: '6rem',
    height: '7.87rem',
    border: 'none',
    background:
      'linear-gradient(267.93deg, rgba(245, 245, 245, 0.05) 1.74%, #FAFAFA 96.48%);',
    '&:disabled': {
      opacity: 0.5,
    },
  },
  arrowRight: {
    cursor: 'pointer',
    outline: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '6rem',
    height: '7.87rem',
    border: 'none',
    background:
      'linear-gradient(266.77deg, #FAFAFA 2.3%, rgba(245, 245, 245, 0.05) 93.29%)',
    '&:disabled': {
      opacity: 0.3,
    },
  },
  arrowIcon: {
    fontSize: '2.5rem',
    color: '#646464',
  },
}))
