import { Tooltip as TooltipMui } from '@material-ui/core'
import React from 'react'

const Tooltip = ({
  children,
  isCritical,
}: {
  children: React.ReactElement
  isCritical: boolean
}) => {
  if (!isCritical) return children
  return (
    <TooltipMui title="Cliente crítico" arrow placement="top">
      {children}
    </TooltipMui>
  )
}

export default Tooltip
