import { Box, Typography } from '@material-ui/core'
import { ComponentType, ReactNode } from 'react'
import { IconBaseProps } from 'react-icons'

import { useStyles } from './styles'

interface ICardInfoProps {
  icon: ComponentType<IconBaseProps>
  title: string
  info?: string | number
  color: string
  className?: string
  children?: ReactNode
}

export const CardInfo = (props: ICardInfoProps) => {
  const { className, icon: Icon, title, info, children, ...rest } = props

  const classes = useStyles(rest)

  return (
    <Box className={`${classes.root} ${className}`}>
      <span className={classes.iconContainer}>
        <Icon fontSize="1.7rem" color="#ffffff" />
      </span>

      <Box className={classes.content}>
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {info === 0 ? '0' : info || children}
        </Typography>
      </Box>
    </Box>
  )
}
