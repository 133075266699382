import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  container: {
    background: '#FFFFFF',
    borderRadius: '4px',
    padding: '1rem',
    boxShadow:
      '0px 0px 2px rgba(160, 160, 160, 0.14), 0px 2px 2px rgba(160, 160, 160, 0.12), 0px 1px 3px rgba(160, 160, 160, 0.2)',
  },
  containerSeparations: {
    position: 'relative',
    height: '13rem',
    overflow: 'hidden',
    '&::after': {
      content: '""',
      width: '100%',
      height: '6rem',
      background: 'linear-gradient(transparent, #ffffff)',
      position: 'absolute',
      bottom: '0',
      left: '0',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #C4C4C4',
    padding: '0.5rem 0',
    marginBottom: '1rem',
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.6rem',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 500,
    fontFamily: '"Inter", sans-serif',
  },
  trackImg: {
    background: '#f1eafd',
    borderRadius: '50%',
    width: '1.875rem',
    height: '1.875rem',
    padding: '0.3rem',
  },
  quantityProducts: {
    fontSize: '0.9rem',
    color: '#757575',
    fontFamily: '"Inter", sans-serif',
  },
  separation: {
    fontFamily: '"Inter", sans-serif',
    color: '#757575',
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  replecementBox: {
    '& + div': {
      marginTop: '0.5rem',
    },
  },
  user: {
    fontFamily: '"Inter", sans-serif',
    fontSize: '0.875rem',
    color: '#757575',
    fontWeight: 400,
  },
  nameUser: {
    color: '#353535',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  containerProducts: {
    marginTop: '0.5rem',
  },
  product: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    marginTop: '0.5rem',
  },
  productImg: {
    background: '#6B727D',
    borderRadius: '50%',
    minWidth: '1.875rem',
    height: '1.875rem',
  },
  btn: {
    color: '#618619',
    margin: '1rem auto 0',
    display: 'block',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: '0.875rem',
  },
})
