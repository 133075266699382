import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles({
  root: {
    width: '360px',
    position: 'relative',
    zIndex: 4000,
    padding: '16px 30px 16px 16px',
    boxShadow: '2px 2px 8px rgba(0,0,0,0.2)',
    display: 'flex',
    '& + div': {
      marginTop: '6px',
    },
    ['@media(max-width: 600px)']: { // eslint-disable-line
      width: '100%',
    },
  },
  box: {
    flex: '1',
  },
  description: {
    marginTop: ' 4px',
    fontSize: '14px',
    opacity: '0.8',
    lineHeight: '20px',
    ['@media(max-width: 350px)']: { // eslint-disable-line
      fontSize: '13px',
    },
  },
  span: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '6px',
    animation: '$spanAnimation 6s',
  },
  '@keyframes spanAnimation': {
    '0%': { width: '0%' },
    '100%': { width: '100%' },
  },
  strong: {
    ['@media(max-width: 350px)']: { // eslint-disable-line
      fontSize: '12px',
    },
  },
  button: {
    position: 'absolute',
    right: '2%',
    top: '10px',
    opacity: '0.6',
    border: '0',
    background: 'transparent',
    color: 'inherit',
    cursor: 'pointer',
  },
})
