import { TextField } from '@material-ui/core'
import { styled } from '@material-ui/styles'

const Input = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: '0px',
    fontSize: '0.9rem',
    fontWeight: '300',
  },
  '& .MuiInputBase-input.Mui-disabled': {
    background: '#FAFAFA',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    '& fieldset': {
      border: '1px solid #C4C4C4',
    },
    '&:hover fieldset': {
      border: '1px solid #C4C4C4',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #C4C4C4',
    },
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: '#757575',
  },
})

export default Input
