import { api as apiService, ApiService } from './api'

export interface IHistoricDivergenceBoxes {
  id: number
  created: string
  expected_boxes: number
  inputed_boxes: number
  action: string
  order: string
  inputed_by: number
}

class HistoricDivergenceBoxesService {
  constructor(private readonly api: ApiService) {}

  async getList(): Promise<IHistoricDivergenceBoxes[]> {
    return this.api.get(carambolaUrl + '/routes/historic-divergence-boxes')
  }

  async create(
    data: Omit<Omit<IHistoricDivergenceBoxes, 'id'>, 'created'>,
  ): Promise<IHistoricDivergenceBoxes> {
    return this.api.post(carambolaUrl + '/routes/historic-divergence-boxes', data)
  }

  async update(
    data: Partial<IHistoricDivergenceBoxes> & Required<Pick<IHistoricDivergenceBoxes, 'id'>>,
  ): Promise<IHistoricDivergenceBoxes> {
    return this.api.patch(carambolaUrl + `/routes/historic-divergence-boxes/${data.id}`, data)
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const historicDivergenceService = new HistoricDivergenceBoxesService(apiService)
