import '@brainhubeu/react-carousel/lib/style.css'
import { styled, Box, Button as ButtonMui, darken } from '@material-ui/core'

export const Container = styled(Box)((theme: { darkBackground?: boolean }) => ({
  background: theme.darkBackground ? '#35353574' : '',
  transition: 'background 1s linear',
  padding: '1rem 0',
  height: '100%',
  width: '100%',
  display: 'flex',
}))

export const CarouselContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto 0',
  padding: '0 1rem',
})

export const ButtonCarousel = styled(ButtonMui)({
  background: '#e1dddd',
  transition: 'background 4s',
  '&:disabled': { opacity: 0 },
  '&:hover': {
    background: darken('#e1dddd', 0.1),
  },
})
