import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Container, Dialog, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Button } from 'components'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { useStyles } from './styles'

interface IDialogEditProps {
  isOpen: boolean
  submit: (data: any) => void
  field: string
  title: string
  setIsOpen: (data: any) => void
  data: number
  defaultValue?: number
}

export const DialogEdit = ({
  submit,
  field,
  title,
  setIsOpen,
  isOpen,
  defaultValue,
}: IDialogEditProps) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const validateSchema = Yup.object().shape({
    [field]: Yup.string().required('Campo obrigatório'),
  })

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(validateSchema),
  })

  const { errors } = formState

  const handleClose = () => {
    setIsOpen({
      isOpen: false,
      data: 0,
    })
  }

  const handleSubmitForm = async (data: any) => {
    setIsLoading(true)
    const value = Number(data[field])
    await submit({ [field]: value })
    setIsLoading(false)
    handleClose()
  }

  return (
    <Dialog open={isOpen} className={classes.root}>
      <Container component="div" className={classes.container}>
        <Typography className={classes.title}>
          Editar configuração da {title.toLowerCase()}
        </Typography>

        <IconButton onClick={handleClose} className={classes.closeBtn}>
          <CloseIcon />
        </IconButton>

        <Box component="form" onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                style={{ width: '100%' }}
                error={!!errors?.[field]}
                helperText={errors?.[field]?.message}
                label={title}
                variant="outlined"
                type="number"
                defaultValue={defaultValue || 0}
                {...register(field)}
              />
            </Grid>
            <Grid item xs={12} className={classes.btnSubmit}>
              <Button type="submit" isLoading={isLoading} variant="contained">
                Opa, pode criar!
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  )
}
