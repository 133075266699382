import { Box, LinearProgress, Typography } from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Button } from 'components'
import CircularStatic from 'components/CircularProgress'
import { IOrder } from 'interfaces/IOrder'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { ISettingsRequest } from 'services/settingsService'
import { IState } from 'store'

import { useStyles } from './styles'
import { IProductivity, productivityService } from 'services/productivityService'
import { useEffect, useState } from 'react'
import { usePopup } from 'hooks/usePopup'
import { updateSettings } from 'store/reducers/settings/actions'

interface ISeparationCompleted {
  order: IOrder
  getOrderId?: () => Promise<void>
  isConferenceGroup: boolean
}

export const SeparationCompleted = ({
  order,
  getOrderId,
  isConferenceGroup,
}: ISeparationCompleted) => {
  const { addPopup } = usePopup()
  const [loading, setLoading] = useState(false)
  const [dataProductivity, setDataProductivity] = useState<IProductivity>({} as IProductivity)
  const classes = useStyles()
  const settings = useSelector<IState, ISettingsRequest>(state => state.settings)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { completed_orders_user, total_completed_orders, total_orders } =
    dataProductivity as IProductivity

  const userPercentage = (completed_orders_user / settings.goalSeparate) * 100
  const totalPercentage = (total_completed_orders / total_orders) * 100
  const goalReached = total_orders === total_completed_orders

  const handleRunningMachine = () => {
    navigate('../../separacao/pedido?nextOrder=true')
  }

  const getDataProductivity = async () => {
    try {
      setLoading(true)
      const separatorHistory = await productivityService.getProductivity()

      dispatch(
        updateSettings({
          ...settings,
          goalSeparate: separatorHistory.goal_separate as number,
        }),
      )

      setDataProductivity(separatorHistory)
    } catch (err: any) {
      addPopup({
        title: 'Erro ao pegar os dados de produtividade',
        type: 'error',
        description: err?.msg || err?.message || 'Contate um administrador',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleNextOrder = async () => {
    if (isConferenceGroup) {
      handleRunningMachine()
      return
    }
    getOrderId && (await getOrderId())
  }

  useEffect(() => {
    getDataProductivity()
  }, [])

  return (
    <>
      <Box className={classes.root}>
        <header className={classes.header}>
          <Typography className={classes.subtitle}>Pedido #{order?.order_id}</Typography>

          <Typography className={classes.subtitle}>
            {order.client_data?.client_type === 'PF'
              ? order?.client_data?.client_name
              : order?.client_data?.client_trade}
          </Typography>

          <Typography component="strong" className={classes.title}>
            Pedido concluído
          </Typography>
          <Typography className={classes.subtitle}>
            Todos os itens do pedido foram separados.
          </Typography>
        </header>

        <Box className={classes.boxProgress}>
          <Typography
            className={classes.metaTitle}
            style={{ marginTop: '2rem', marginBottom: '2rem' }}
          >
            META INDIVIDUAL
          </Typography>

          <Typography className={classes.buttonPercentage}>{userPercentage.toFixed(0)}%</Typography>

          <ArrowDropDownIcon
            style={{
              width: '4rem',
              height: '4rem',
              color: '#89BD23',
              marginTop: '-1.7rem',
              padding: 0,
            }}
          />

          <CircularStatic
            value={completed_orders_user}
            maxValue={settings.goalSeparate}
            variant={loading ? 'indeterminate' : 'static'}
          />
        </Box>

        <Typography className={classes.completedGoalsText}>
          {userPercentage > 100 && !goalReached
            ? 'Parabéns! Você superou a meta diária! Busque novo pedido para continuar separando.'
            : null}

          {userPercentage === 100 && !goalReached
            ? 'Você concluiu a sua meta do dia. Busque novo pedido para continuar separando.'
            : null}

          {goalReached
            ? `Separação finalizada. Você fez ${completed_orders_user} de ${settings.goalSeparate} pedidos.`
            : null}
        </Typography>

        <Box>
          <Typography className={classes.metaTitle}>META DO TIME</Typography>

          <LinearProgress
            className={classes.progress}
            value={loading ? undefined : totalPercentage > 100 ? 100 : totalPercentage}
            variant={loading ? 'indeterminate' : 'determinate'}
          />

          <Typography className={classes.metaSubtitle}>
            {total_completed_orders}/{total_orders}
          </Typography>
        </Box>

        <Button
          endIcon={<ArrowForwardIos />}
          onClick={handleNextOrder}
          className={classes.nextOrder}
          variant="contained"
          id="nextOrder"
        >
          Buscar novo pedido
        </Button>
      </Box>
    </>
  )
}
