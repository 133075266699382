import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  containerInfo: {
    display: 'flex',
    gap: '1rem',
    paddingBottom: '1rem',
    [theme.breakpoints.between(0, 645)]: {
      flexDirection: 'column',
    },
  },
  card: {
    flex: 1,
    background: '#ffffff',
    borderRadius: '1rem',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
    padding: '1rem',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  boxIcon: {
    width: '3.65rem',
    height: '3.65rem',
    background: 'rgba(158, 211, 52, 0.2)',
    color: '#9ED334',
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '7rem',
  },
  title: {
    color: '#303030',
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  subtitle: {
    color: '#88888a',
    fontWeight: 400,
    fontSize: '1rem',
  },
  firtsValue: {
    color: '#000000',
    fontSize: '1.5rem',
  },
  lastValue: {
    color: '#88888a',
    fontSize: '1.25rem',
  },
}))
