import { makeStyles } from '@material-ui/core'
import { mapTagColor } from 'utils/mapTagColor'

export const useStyles = makeStyles({
  title: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '1.8rem',
    textAlign: 'center',
  },
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: '32.06rem',
      width: '100%',
      padding: '2.5rem 2.87rem 4.25rem 2.87rem',
    },
  },
  button: {
    width: '50%',
    alignSelf: 'center',
    background: '#89BD23',
    borderRadius: '2.5rem',
    height: '3rem',
    textAlign: 'center',
    color: '#fff',
    '&:hover': {
      filter: 'brightness(0.9)',
      background: '#89BD23',
    },
  },
  input: {
    width: '100%',
    marginBottom: '1.12rem',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F4F4F4',
      borderRadius: '16px',
      '& fieldset': {
        borderColor: '#F4F4F4',
      },
    },
  },
  strong: {
    fontWeight: 500,
    color: '#353535',
  },
})

export const buttonStyle = makeStyles({
  buttonColor: {
    width: '12px',
    height: '12px',
    color: (props: { color: string }) => mapTagColor(props.color),
    marginRight: '0.5rem',
  },
})
