import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: '-1rem auto 0',
    padding: '0 2% 1rem',
    maxWidth: '1200px',
    [theme.breakpoints.down(1200)]: {
      marginBottom: '80px',
    },
  },
  titlePage: {
    fontWeight: 400,
    fontSize: '1.5rem',
    color: '#353535',
    fontFamily: '"Inter", sans-serif',
    textAlign: 'center',
    marginBottom: '1rem',
  },
  trackLink: {
    bottom: '2.5rem',
    right: '2.5rem',
    position: 'fixed',
    background: '#89BD23',
    color: '#ffffff',
    boxShadow:
      '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2)',
  },
  tabs: {
    marginBottom: '2.5rem',
    '& .MuiTab-wrapper': {
      fontWeight: 500,
      textTransform: 'none',
      fontSize: '1.1rem',
    },
    '& .MuiTabs-indicator': {
      height: '5px',
      backgroundColor: '#89BD23',
      border: 'none',
      borderRadius: '5px',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: '#353535',
    },
    '& .MuiTab-textColorInherit': {
      color: '#A3A3A3',
    },
  },
}))
