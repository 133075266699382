import { enRules } from 'interfaces/IUser'
import Breaks from 'pages/Admin/Breaks'
import Config from 'pages/Admin/Config'
import { Goals } from 'pages/Admin/Config/Goals'
import { HighOrders } from 'pages/Admin/Config/HighOrders'
import { Palletizing } from 'pages/Admin/Config/Palletizing'
import SeparationGroup from 'pages/Admin/Config/SeparationGroup'
import { Separators } from 'pages/Admin/Config/Separators/Index'
import Dashboard from 'pages/Admin/Dashboard'
import FixScanner from 'pages/Admin/FixScanner'
import LoadingConference from 'pages/Admin/LoadingConference'
import { OrdersLoadingConference } from 'pages/Admin/LoadingConference/OrdersLoadingConference'
import OperatorDetail from 'pages/Admin/OperatorDetail'
import Operators from 'pages/Admin/Operators'
import OrderDetails from 'pages/Admin/OrderDetails'
import Orders from 'pages/Admin/Orders'
import PackingList from 'pages/Admin/PackingList'
import PerformanceAdmin from 'pages/Admin/Performance'
import PrePicking from 'pages/Admin/PrePicking'
import { Ranking } from 'pages/Admin/Ranking'
import { RankingFiltered } from 'pages/Admin/Ranking/RankingFilter'
import Replecement from 'pages/Admin/Replecement'
import ReprintRoutes from 'pages/Admin/ReprintRoutes'
import { Skus } from 'pages/Admin/Skus'
import Token from 'pages/Admin/Token'
import Conference from 'pages/Operation/Conference'
import OrderConference from 'pages/Operation/Conference/Order'
import Performance from 'pages/Operation/Operator'
import OrderSeparation from 'pages/Operation/OrderSeparation'
import TrackList from 'pages/Operation/TrackList'
import SignIn from 'pages/Public/SignIn'
import { Navigate, useRoutes } from 'react-router-dom'

import Admin from './private/admin'
import Operation from './private/Operation/Layout'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'
import Invoices from 'pages/Admin/Invoices'

const Routes = () => {
  return useRoutes([
    {
      path: '/login',
      element: <PublicRoute component={SignIn} />,
    },
    {
      path: '/painel',
      element: (
        <PrivateRoute
          component={Admin}
          rules={[
            enRules.Tech,
            enRules.SeparationDashboardView,
            enRules.SeparationBreakOrderManagement,
            enRules.SeparationOrdersManagement,
            enRules.SeparationInvoiceRelease,
            enRules.SeparationDataAnalysis,
            enRules.SeparationReleasePackingList,
            enRules.SeparationReplenishmentResponse,
            enRules.SeparationUsersView,
            enRules.SeparationShipmentManagement,
            enRules.SeparationRankingView,
            enRules.SeparationConfigsManagement,
            enRules.SeparationPrePickingConsult,
            enRules.SeparationFixedReader,
            enRules.SeparationGetToken,
            enRules.SeparationReprintRoutes,
          ]}
        />
      ),
      children: [
        {
          path: 'dashboard',
          element: (
            <PrivateRoute
              component={Dashboard}
              rules={[enRules.SeparationDashboardView, enRules.Tech]}
            />
          ),
        },
        {
          path: 'configuracoes',
          element: (
            <PrivateRoute
              component={Config}
              rules={[enRules.SeparationConfigsManagement, enRules.Tech]}
            />
          ),
        },
        {
          path: 'configuracoes/grupos',
          element: (
            <PrivateRoute
              component={SeparationGroup}
              rules={[enRules.SeparationConfigsManagement, enRules.Tech]}
            />
          ),
        },
        {
          path: 'configuracoes/separacao',
          element: (
            <PrivateRoute
              component={Skus}
              rules={[enRules.SeparationConfigsManagement, enRules.Tech]}
            />
          ),
        },
        {
          path: 'configuracoes/separadores',
          element: (
            <PrivateRoute
              component={Separators}
              rules={[enRules.SeparationConfigsManagement, enRules.Tech]}
            />
          ),
        },
        {
          path: 'configuracoes/metas',
          element: (
            <PrivateRoute
              component={Goals}
              rules={[enRules.SeparationConfigsManagement, enRules.Tech]}
            />
          ),
        },
        {
          path: 'configuracoes/alta-pedidos',
          element: (
            <PrivateRoute
              component={HighOrders}
              rules={[enRules.SeparationConfigsManagement, enRules.Tech]}
            />
          ),
        },
        {
          path: 'configuracoes/paletizacao',
          element: (
            <PrivateRoute
              component={Palletizing}
              rules={[enRules.SeparationConfigsManagement, enRules.Tech]}
            />
          ),
        },
        {
          path: 'usuarios',
          element: (
            <PrivateRoute
              component={Operators}
              rules={[enRules.SeparationUsersView, enRules.Tech]}
            />
          ),
        },
        {
          path: 'usuario/:usuarioId',
          element: (
            <PrivateRoute
              component={OperatorDetail}
              rules={[enRules.SeparationUsersView, enRules.Tech]}
            />
          ),
        },
        {
          path: 'quebras',
          element: (
            <PrivateRoute
              component={Breaks}
              rules={[enRules.SeparationBreakOrderManagement, enRules.Tech]}
            />
          ),
        },
        {
          path: 'nfs',
          element: (
            <PrivateRoute
              component={Invoices}
              rules={[enRules.SeparationInvoiceRelease, enRules.Tech]}
            />
          ),
        },
        {
          path: 'reabastecimento',
          element: (
            <PrivateRoute
              component={Replecement}
              rules={[enRules.SeparationReplenishmentResponse, enRules.Tech]}
            />
          ),
        },
        {
          path: 'pedidos',
          element: (
            <PrivateRoute
              component={Orders}
              rules={[enRules.SeparationOrdersManagement, enRules.Tech]}
            />
          ),
        },
        {
          path: 'pre-picking',
          element: (
            <PrivateRoute
              component={PrePicking}
              rules={[enRules.SeparationPrePickingConsult, enRules.Tech]}
            />
          ),
        },
        {
          path: 'pedidos/:orderId',
          element: (
            <PrivateRoute
              component={OrderDetails}
              rules={[
                enRules.SeparationDashboardView,
                enRules.SeparationOrdersManagement,
                enRules.Tech,
              ]}
            />
          ),
        },
        {
          path: 'conferencia-de-caixas',
          children: [
            {
              path: '',
              element: (
                <PrivateRoute
                  component={LoadingConference}
                  rules={[enRules.SeparationReleasePackingList, enRules.Tech]}
                />
              ),
            },
            {
              path: 'pedidos',
              element: (
                <PrivateRoute
                  component={OrdersLoadingConference}
                  rules={[enRules.SeparationReleasePackingList, enRules.Tech]}
                />
              ),
            },
            {
              path: 'leitor-fixo',
              element: (
                <PrivateRoute
                  component={FixScanner}
                  rules={[enRules.SeparationFixedReader, enRules.Tech]}
                />
              ),
            },
          ],
        },
        {
          path: 'romaneios',
          element: <PackingList />,
        },
        {
          path: 'ranking/',
          element: <Ranking />,
        },
        {
          path: 'sku',
          element: <Skus />,
        },
        {
          path: 'ranking-filtered',
          element: <RankingFiltered />,
        },
        {
          path: 'desempenho',
          element: <PerformanceAdmin />,
        },
        {
          path: 'token',
          element: <Token />,
        },
        {
          path: 'reprint-routes',
          element: <ReprintRoutes />,
        },
        {
          path: '*',
          element: <Navigate to="dashboard" />,
        },
      ],
    },
    {
      path: '/operacao',
      element: <PrivateRoute component={Operation} rules={[]} />,
      children: [
        {
          path: '',
          element: <Navigate to="separacao/pedido" />,
        },
        {
          path: '*',
          element: <Navigate to="separacao/pedido" />,
        },
        {
          path: 'separacao',
          children: [
            {
              path: 'pedido',
              element: <OrderSeparation />,
            },
          ],
        },
        {
          path: 'separacao',
          children: [
            {
              path: 'desempenho',
              element: <Performance />,
            },
            {
              path: 'grupos',
              element: <TrackList />,
            },
            {
              path: '',
              element: <Navigate to="grupos" />,
            },
            {
              path: '*',
              element: <Navigate to="grupos" />,
            },
          ],
        },
        {
          path: 'conferencia',
          children: [
            {
              path: 'inicio',
              element: <Conference />,
            },
            {
              path: ':orderId',
              element: <OrderConference />,
            },
            {
              path: '*',
              element: <Navigate to="operacao/separacao/grupos" />,
            },
          ],
        },
        {
          path: 'quebras',
          element: <Breaks />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/login" />,
    },
  ])
}

export default Routes
