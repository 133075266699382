import { Box, Button, MenuItem, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import { IAlertResponse, IPrePickingBreakReport } from 'interfaces/IAlert'
import { useNavigate } from 'react-router'

interface INotificationPrePickingProps {
  notification: IAlertResponse
}

const NotificationPrePicking = ({ notification, ...rest }: INotificationPrePickingProps) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const redirectToOrder = () => {
    navigate(
      `pedidos/${
        (notification.related_alert as IPrePickingBreakReport)?.item?.order?.order_id
      }?view=true`,
    )
  }

  return (
    <MenuItem {...rest} className={classes.root}>
      <Box>
        <Typography className={classes.textTypeNotification}>Quebra de pré picking</Typography>
        <Typography className={classes.textName}>
          {(notification.related_alert as IPrePickingBreakReport).user_request}
        </Typography>
      </Box>
      <Button className={classes.button} onClick={redirectToOrder}>
        Clique para ver
      </Button>
    </MenuItem>
  )
}

export default NotificationPrePicking
