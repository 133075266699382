export const mapMessagesGrouped = (
  message: 'separation' | 'fastTrack' | 'preBoxed' | 'converted',
) => {
  return {
    separation:
      'Esse item será separado na conferência. Não se preocupe, basta confirmar e continuar a separação.',
    fastTrack:
      'Esse item será separado nos itens de caixa pronta. Não se preocupe, basta confirmar e continuar a separação.',
    preBoxed: 'Esse item foi resultado de uma conversão.',
    converted:
      'O restante desse item foi convertido em caixa pronta. Continue a separação normalmente.',
  }[message]
}
