import { darken, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  container: {
    maxWidth: '28rem',
    width: '100%',
    margin: 'auto 2%',
    background: '#FFFFFF',
    padding: '1.875rem',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '208.25px',
  },
  loading: {
    textAlign: 'center',
  },
  title: {
    fontFamily: 'Inter',
    fontSize: '1.2rem',
    fontWeight: 500,
    textAlign: 'center',
    padding: '1rem 0 0 0',
  },
  subTitle: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '1rem',
    color: '#757575',
    textAlign: 'center',
    padding: '0.2rem 0',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '3.5rem',
    marginTop: '2rem',
  },
  input: {
    maxWidth: '21.8rem',
  },
  button: {
    width: '13.5rem',
    height: '3.1rem',
  },
})
