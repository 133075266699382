import { makeStyles, Theme } from '@material-ui/core'

interface IStylesProps {
  isCarrouselVisible: boolean
}

export const useStyles = makeStyles<Theme, IStylesProps>(theme => ({
  root: {
    width: '100%',
    maxWidth: '17rem',
    background: '#FFFFFF',
    padding: '1.875rem',
    borderRadius: '5px',
    margin: '0 !important',
    marginLeft: 'auto !important',
    [theme.breakpoints.between(0, 1200)]: {
      display: 'none',
    },
  },
  carousel: {
    display: 'none',
    width: '100%',
    background: '#FFFFFF',
    padding: '0.81rem',
    height: '7.87rem',
    position: 'relative',
    [theme.breakpoints.between(0, 1200)]: {
      display: 'block',
    },
  },
  arrowLeft: {
    cursor: 'pointer',
    outline: 'none',
    position: 'absolute',
    zIndex: 3,
    top: 0,
    left: 0,
    width: '6rem',
    height: '7.87rem',
    border: 'none',
    background: 'linear-gradient(267.93deg, rgba(245, 245, 245, 0.05) 1.74%, #FAFAFA 96.48%);',
    '&:disabled': {
      opacity: 0.3,
    },
  },
  arrowRight: {
    cursor: 'pointer',
    outline: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '6rem',
    height: '7.87rem',
    border: 'none',
    background: 'linear-gradient(266.77deg, #FAFAFA 2.3%, rgba(245, 245, 245, 0.05) 93.29%)',
    '&:disabled': {
      opacity: 0.3,
    },
  },
  arrowIcon: {
    fontSize: '2.5rem',
    color: '#646464',
  },
  divider: {
    margin: '2rem 0',
    background: '#C4C4C4',
    [theme.breakpoints.between(0, 1050)]: {
      margin: '0 1rem',
    },
  },
  box: {
    display: 'flex',
    gap: '1rem',
    [theme.breakpoints.between(0, 1200)]: {
      minWidth: '220px',
      marginTop: '1.1rem',
    },
  },
  icon: {
    width: '2.4rem',
    height: '2.4rem',
    borderRadius: '6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      width: '1.6rem',
    },
    '& > svg': {
      width: '1.6rem',
    },
  },
  totalOrderIcon: {
    background: '#2E656A',
  },
  totalItens: {
    background: '#ab98ee',
  },
  ordersFinished: {
    background: '#89BD23',
  },
  ordersPending: {
    background: '#EAD92A',
  },
  separationOrders: {
    background: '#6acfd8',
  },
  ordersTotal: {
    background: '#2E656A',
  },
  ordersProcessed: {
    background: '#AB98EE',
  },
  title: {
    color: '#757575',
    fontSize: '0.78rem',
  },
  subtitle: {
    fontSize: '1.2rem',
    fontWeight: 600,
    color: '#353535',
  },
  lastUpdate: {
    color: '#757575',
    fontSize: '0.7rem',
  },
  lastUpdateWMargin: {
    color: '#757575',
    fontSize: '0.7rem',
    marginTop: '0.6rem',
    marginBottom: '-0.9rem',
  },
  ordersDiv: {
    display: 'flex',
  },
}))
