import { Box } from '@material-ui/core'
import logoFrexco from 'assets/images/logo-frexco.svg'

import { useStyles } from './styles'

const LoadingRules = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <img src={logoFrexco} className={classes.image} />
    </Box>
  )
}

export default LoadingRules
