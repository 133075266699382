export const getDeliveryDate = () => {
  const dateNow = new Date()

  const hour = dateNow.getHours()

  if (hour > 12 || (hour === 12 && dateNow.getMinutes() > 0)) {
    dateNow.setDate(dateNow.getDate() + 1)
  }

  const year = dateNow.getFullYear()

  const month =
    (dateNow.getMonth() + 1).toString().length === 1
      ? '0' + (dateNow.getMonth() + 1)
      : dateNow.getMonth() + 1

  const day =
    dateNow.getDate().toString().length === 1
      ? '0' + dateNow.getDate().toString()
      : dateNow.getDate().toString()

  return {
    year,
    month,
    day,
  }
}
