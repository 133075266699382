import { Button as ButtonMui, styled, Box } from '@material-ui/core'

export const Button = styled(ButtonMui)({
  display: 'flex',
  padding: 0,
  borderRadius: '4px',
  '& > span > div': {
    borderRadius: '4px',
  },
})

export const ContainerDate = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  width: '10rem',
  border: '1px solid #C4C4C4',
  padding: '0.9rem 1rem',
})
