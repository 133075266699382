import { Box, Button, Typography } from '@material-ui/core'
import { ITrack } from 'interfaces/IDashboard'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { IoPlayCircleOutline, IoStopCircleOutline } from 'react-icons/io5'
import { MdExpandMore } from 'react-icons/md'
import { animated } from 'react-spring'
import { AccordionDetails, Timer } from 'shared'
import { dashboardCalculateTime, formatName } from 'utils'

import { Accordion as AccordionStyled, TrackInfo, AccordionSummary, useStyles } from './styles'
import ModalPause from './ModalPause'
import ContentAccordion from './ContentAccordion'

interface Props {
  averageDuration: string
  separator: ITrack
  style?: object
  pendentOrders: number
  refreshDashboard: () => Promise<void>
  averageIdleTime?: string
}

const Accordion = ({
  separator,
  averageDuration,
  style,
  pendentOrders,
  refreshDashboard,
  averageIdleTime,
}: Props) => {
  const {
    username,
    last_work_end,
    total_orders_completed,
    last_work,
    idle_time,
    is_paused,
    user_id,
    work_conference,
  } = separator

  const [expanded, setExpanded] = useState<boolean>(false)
  const [openModalPause, setOpenModalPause] = useState<boolean>(false)
  const classes = useStyles()
  const currentTime = moment()
  const isChecking = !!work_conference
  const isSeparating = !last_work.end
  const formatAverageDuration = dashboardCalculateTime(Number(averageDuration))
  const initSeparation = moment(last_work.begin)
  const separationTime = moment.duration(currentTime.diff(initSeparation)).asMilliseconds()
  const averageTime = moment.duration(formatAverageDuration).asMilliseconds()
  const idleTimeAverage = Number(averageIdleTime)
  const separatorIdleTime = moment(last_work_end || currentTime)
  const differenceIdleTime = moment.duration(currentTime.diff(separatorIdleTime)).asMilliseconds()

  const formatIdleTime = () => {
    const now = new Date()
    const subtractIdle = new Date(now.getTime() - Number(idle_time) * 1000)
    return subtractIdle
  }

  const idleTimeFormated = useMemo(() => formatIdleTime(), [idle_time])

  const onClickPause = (event: any) => {
    event.preventDefault()
    setOpenModalPause(true)
  }

  const onClose = async () => {
    setOpenModalPause(false)
  }

  return (
    <animated.div style={style}>
      <AccordionStyled
        onClick={() => {
          setExpanded(state => !state)
        }}
        expanded={expanded}
      >
        <AccordionSummary expandIcon={<MdExpandMore />}>
          <Box className={classes.containerInfos}>
            <TrackInfo>
              <Typography className={classes.username}>{formatName(username)}</Typography>
            </TrackInfo>
            <Typography className={classes.text}>
              <p>
                <span className={classes.label}>Pedidos separados:</span>
                {total_orders_completed}
              </p>
            </Typography>

            <Typography>
              {isChecking && (
                <span className={classes.labelTime}>
                  Em conferência:
                  <strong style={{ color: '#99df0f' }}>
                    <Timer time={moment(work_conference).toDate()} isHours />
                  </strong>
                </span>
              )}
              {isSeparating && (
                <span className={classes.labelTime}>
                  Em separação:
                  <strong
                    style={
                      separationTime < averageTime ? { color: '#99df0f' } : { color: '#ffbe0b' }
                    }
                  >
                    <Timer time={new Date(last_work.begin)} isHours />
                  </strong>
                </span>
              )}
              {!isSeparating && !isChecking && (
                <span className={classes.label}>
                  Última separação:
                  <strong
                    style={
                      is_paused
                        ? { color: '#A3A3A3' }
                        : differenceIdleTime < idleTimeAverage
                        ? { color: '#A020F0' }
                        : { color: '#ff0000' }
                    }
                  >
                    {is_paused ? (
                      ' 00:00:00'
                    ) : (
                      <Timer time={idleTimeFormated} stop={!pendentOrders || is_paused} isHours />
                    )}
                  </strong>
                </span>
              )}
            </Typography>
          </Box>
          <Button
            className={classes.containerButton}
            onClick={e => {
              onClickPause(e)
            }}
          >
            {is_paused ? (
              <IoPlayCircleOutline className={classes.iconPlayPause} />
            ) : (
              <IoStopCircleOutline className={classes.iconPlayPause} />
            )}
          </Button>
        </AccordionSummary>
        <AccordionDetails style={{ overflowX: 'scroll', padding: '0' }}>
          {expanded && <ContentAccordion userId={user_id} />}
        </AccordionDetails>
      </AccordionStyled>
      <ModalPause
        open={openModalPause}
        onClose={onClose}
        dataSeparator={separator}
        refreshDashboard={refreshDashboard}
        lastWork={last_work}
      />
    </animated.div>
  )
}

export default Accordion
