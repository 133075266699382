import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    padding: '5rem 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imgCompleted: {
    width: '6rem',
  },
  title: {
    color: '#353535',
    fontSize: '1.4rem',
    fontWeight: 500,
    margin: '1.5rem 0 0.4rem',
    textAlign: 'center',
  },
  subtitleReduced: {
    color: '#757575',
    textAlign: 'center',
    fontSize: '1.2rem',
  },
  subtitle: {
    color: '#757575',
    textAlign: 'center',
    fontSize: '1.2rem',
  },
  nextOrder: {
    marginTop: '4.3rem',
    fontSize: '1rem',
    padding: '0.5rem 1rem',
    minWidth: '11rem',
  },
})
