export interface IFormatValueScannerReturn {
  productBaseId?: number
  valueBeeped: number
  typeProduct: string
  id?: string
  sku?: number
  isBalance?: boolean
  scaleId?: number
  date?: string
  scaleLabelId?: string
}

export const formatValueScanner = (
  value: string,
  isInfrared?: boolean,
): IFormatValueScannerReturn => {
  // PRE PICKINGS ANTIGOS QUE NÃO FORAM REGISTRADOS NO BANCO DO NABO
  if (value.includes('-') && !value.includes(';')) {
    const valueFormated = value.replace(/\s/g, '')
    const especialCase = value.indexOf('-')
    const productBaseId = Number(valueFormated.substring(0, especialCase))
    let valueQrCode = 0
    if (value.includes('kg') || value.includes('un')) {
      valueQrCode = Number(valueFormated.substring(especialCase + 1, valueFormated.length - 2))
    } else {
      valueQrCode = Number(valueFormated.substring(especialCase + 1))
    }

    const typeProduct = valueFormated.substring(valueFormated.length - 2)
    return {
      productBaseId: productBaseId,
      valueBeeped: valueQrCode,
      typeProduct,
    }
  }
  // PRE PICKINGS NOVOS QUE FORAM REGISTRADOS NO BANCO DO NABO
  if (value.includes(';')) {
    const formattValue = value.split(';')
    const id = formattValue[0]
    const product_base_id = formattValue[1]
    const portioning = formattValue[2]
    const portion_type = formattValue[3]
    return {
      id,
      productBaseId: Number(product_base_id),
      valueBeeped: Number(portioning),
      typeProduct: portion_type,
    }
  }

  function transformarData(data: string) {
    const ano = `20${data.substring(0, 2)}`
    const mes = data.substring(2, 4)
    const dia = data.substring(4, 6)

    return `${ano}-${mes}-${dia}`
  }

  // PRE PICKINGS BIPADOS COM O SCANNER DE INFRAVERMELHO
  if (isInfrared) {
    const sku = value.slice(4, 9)
    const scaleId = value.slice(18, 24)
    const scaleLabelId = value.slice(24, 31)
    const weight = value.slice(44, 50)
    const date = value.slice(34, 40)

    return {
      id: scaleLabelId,
      sku: Number(sku),
      typeProduct: 'kg',
      valueBeeped: Number(weight) / 1000,
      isBalance: true,
      scaleId: Number(scaleId),
      date: date,
      scaleLabelId,
    }
  }

  // PRE PICKINGS QUE FORAM FEITOS PELA BALANÇA
  const sku = value.slice(5, 10)
  const scaleId = value.slice(19, 25)
  const scaleLabelId = value.slice(25, 32)
  const weight = value.slice(45, 51)
  const date = transformarData(value.slice(35, 41))

  return {
    id: scaleLabelId,
    sku: Number(sku),
    typeProduct: 'kg',
    valueBeeped: Number(weight) / 1000,
    isBalance: true,
    scaleId: Number(scaleId),
    date: date,
    scaleLabelId,
  }
}
