import { yupResolver } from '@hookform/resolvers/yup'
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import separationImg from 'assets/images/separation.svg'
import { usePopup } from 'hooks/usePopup'
import { IItem } from 'interfaces/ISeparation'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { separationItemServiceV2 } from 'services/separationItemServiceV2'
import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'

import useStyles from './styles'

interface IModalEditWeight {
  open: boolean
  handleClose: () => void
  item: IItem
  isFullBroken?: boolean
  refresh: () => Promise<void>
}

type retuntValidationProps = {
  item: IItem
  isFullBroken: boolean
}

const returnValidationObject = ({ item, isFullBroken }: retuntValidationProps) => {
  const { type, converted_quantity, quantity } = item

  const quantityToValidate = converted_quantity || quantity

  if (isFullBroken) {
    return Yup.object().shape({})
  }

  if (type === 'unidade') {
    return Yup.object().shape({
      quantity: Yup.number()
        .test(
          'quantity',
          `A quantidade precisa ser inferior a quantidade esperada ${quantityToValidate}`,
          (value: number | undefined) => Number(value) < quantityToValidate,
        )
        .typeError('Campo obrigatório')
        .positive('A quantidade precisa ser positiva')
        .required('Campo obrigatório'),
    })
  }

  return Yup.object().shape({
    quantity: Yup.number()
      .test(
        'quantity',
        `A quantidade precisa ser inferior a quantidade esperada ${quantityToValidate}`,
        (value: number | undefined) => Number(value) < quantityToValidate,
      )
      .typeError('Campo deve ser um número')
      .positive('A quantidade precisa ser positiva')
      .typeError('A quantidade precisa ser um número')
      .required('Campo obrigatório'),
  })
}

export const ModalEditWeight = ({ open, handleClose, item, refresh }: IModalEditWeight) => {
  const { id, name, type, confirmed_quantity, image } = item

  const [fullBroken, setFullBroken] = useState('fullBroken')
  const isFullBroken = fullBroken === 'fullBroken'
  const validateSchema = useMemo<Yup.ObjectSchema<ObjectShape>>(() => {
    return returnValidationObject({ item, isFullBroken })
  }, [item, isFullBroken])
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validateSchema),
  })
  const classes = useStyles()
  const { addPopup } = usePopup()

  const resetModal = () => {
    setFullBroken('fullBroken')
    handleClose()
  }

  const handleGenerateBroken = async (data: { quantity: number }) => {
    try {
      await separationItemServiceV2.pickItem({
        itemId: id,
        statusId: isFullBroken ? 3 : 4,
        quantitySeparated: isFullBroken ? undefined : data.quantity,
      })
      await refresh()
      return addPopup({
        type: 'success',
        title: 'Sucesso ao gerar quebra',
      })
    } catch (err: any) {
      if (err?.message === 'Order reseted') {
        addPopup({
          type: 'error',
          title: 'O pedido foi resetado.',
        })
        return
      }
      addPopup({
        type: 'error',
        title: 'Erro ao gerar quebra',
      })
    } finally {
      reset()
      resetModal()
    }
  }

  return (
    <Modal open={open} title="Título" onClose={resetModal}>
      <Box
        component="form"
        onSubmit={handleSubmit(handleGenerateBroken)}
        className={classes.modalBox}
      >
        <Typography className={classes.modalTitle}>Editar item separado</Typography>

        <Box className={classes.boxItem}>
          <Avatar alt="Item escolhido" src={image || separationImg} className={classes.itemImage} />

          <Box className={classes.boxItemInformation}>
            <Typography className={classes.itemInformation}>{name}</Typography>
            <Typography className={classes.itemInformation}>
              Qnt: {confirmed_quantity} {type === 'unidade' ? 'un' : 'kg'}
            </Typography>
          </Box>
        </Box>

        <Divider className={classes.divider} />

        <FormControl className={classes.checkBox}>
          <RadioGroup defaultValue="fullBroken" onChange={e => setFullBroken(e.target.value)}>
            <FormControlLabel value="fullBroken" control={<Radio />} label="Quebra total" />
            <div style={{ marginTop: '16px' }}>
              <b>Não esquece hein, Frexconauta?!</b>
            </div>
            <ul>
              <li>
                Depois dessa ação aqui, cê tem que tratar esse pedido seguindo o fluxo da reposição
                lá na tela de quebras.
              </li>
              <li>
                Se for quebra parcial, é só o cê repor a quantidade do item que vai mandar, lá pela
                tela de quebras.
              </li>
              <li>
                Caso ele já tenha sido finalizado, cê é obrigado a informar a quebra pros
                Frexconautas do financeiro, pra poderem ajustar a cobrança do pedido.
              </li>
            </ul>
          </RadioGroup>
        </FormControl>

        <Box className={classes.boxButtons}>
          <Button onClick={resetModal} className={classes.cancelButton}>
            Cancelar
          </Button>

          <Button type="submit" className={classes.confirmButton}>
            Salvar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
