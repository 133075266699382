import { Box, styled } from "@material-ui/core";

export const Container = styled(Box)((props: { isConnected: boolean, bottomValue: string }) => ({
  boxSizing: 'content-box',
  width: '150px',
  height: '150px',
  padding: '1rem',
  position: 'absolute',
  bottom: props.bottomValue,
  right: 0,
  borderRadius: '12.5%',
  background: props.isConnected ? '#88C946' : '#DA3737',
  boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
  zIndex: 1,
  '& .MuiTypography-root': {
    color: '#fff',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    transform: 'translateX(-50%)',
    left: '50%',
    bottom: '10px',
    zIndex: 1,
  }
}))

export const ImgWebSocketConnection = styled('img')({
  width: '110px',
  color: '#FFF',
  fill: '#FFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  bottom: "30px",
  transform: 'translate(-50%,-50%)',
  zIndex: 1,
})

export const ImgConveyorConnection = styled('img')({
  width: '100%',
  color: '#FFF',
  fill: '#FFF',
})


