import { Box, ClickAwayListener, Grow, Paper, Popper, styled } from '@material-ui/core'
import { ptBR } from 'date-fns/locale'
import { Calendar as CalendarRDR } from 'react-date-range'
import { getDeliveryDate } from 'utils'

interface IProps {
  openCalendar: boolean
  anchorRef: any
  selectedDate: Date
  onSelectDate: (date: any) => void
  handleClose: (event: any) => void
  baseFilter: TBaseFilter
}

export type TBaseFilter = 'separationDate' | 'deliveryDate'

const Calendar = styled(CalendarRDR)({
  '& .rdrSelected': {
    background: '#2549CC',
  },
  '& .rdrDayStartPreview': {
    borderColor: '#2549CC',
  },
  '& .rdrYearPicker': {
    display: 'none',
  },
})

const PopperCalendar = (props: IProps) => {
  const isDisabledDay = (date: Date) => {
    if (props.baseFilter === 'separationDate') {
      return date.getDay() === 6
    }
    return date.getDay() === 0
  }

  const getMaxDate = () => {
    if (props.baseFilter === 'separationDate') {
      return new Date()
    }
    const { day, month, year } = getDeliveryDate()
    const date = new Date(`${year}-${month}-${day}`)
    date.setHours(date.getHours() + 3)
    return date
  }

  return (
    <Popper
      open={props.openCalendar}
      anchorEl={props.anchorRef.current}
      role={undefined}
      transition
      disablePortal
      style={{ zIndex: 2 }}
      placement="top"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={props.handleClose}>
              <Box>
                <Calendar
                  maxDate={getMaxDate()}
                  dateDisplayFormat="dd/MM/yyyy"
                  disabledDay={isDisabledDay}
                  displayMode="date"
                  onChange={props.onSelectDate}
                  date={props.selectedDate}
                  locale={ptBR}
                />
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

export default PopperCalendar
