import { Box } from '@material-ui/core'
import { usePopup } from 'hooks/usePopup'
import { IOrder } from 'interfaces/IOrder'
import { enRules } from 'interfaces/IUser'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { orderService } from 'services/orderService'
import { IState } from 'store'
import { Button, Image, SubTitle } from './styles'
import CriticalOrdersConfereceTable from '../CriticalOrderTable'
import ModalScan from './ModalScan'
import ImgConference from 'assets/images/conference.svg'
import Pagination from '@material-ui/lab/Pagination'

const CriticalOrders = () => {
  const userPermissions = useSelector<IState, enRules[]>(state => state.user.userPermissions)

  const hasPermission = userPermissions.includes(enRules.SeparationCheckCriticalOrder)
  const { addPopup } = usePopup()
  const [openModal, setOpenModal] = useState(false)
  const controller = useRef<AbortController | null>(null)
  const [criticalOrders, setCriticalOrders] = useState<IOrder[]>([])
  const [page, setPage] = useState(1)
  const [pageSize] = useState(5)
  const [totalPages, setTotalPages] = useState(1)

  const getCriticalOrders = async (page: number) => {
    controller.current = new AbortController()
    try {
      const { results, totalCount } = await orderService.getOrders(
        { page, pageSize, isOrdersForConference: true },
        controller.current?.signal,
      )
      setCriticalOrders(results)
      setTotalPages(Math.ceil(totalCount / pageSize))
    } catch (err: any) {
      if (controller.current?.signal?.aborted) return
      addPopup({
        title: 'Erro ao buscar pedidos críticos',
        description: 'Não foi possível buscar os pedidos críticos. Erro: ' + err?.msg,
        type: 'error',
      })
    }
  }

  useEffect(() => {
    if (hasPermission) {
      getCriticalOrders(page)
    }
    return () => {
      controller.current?.abort()
    }
  }, [page, hasPermission])

  const handlePageChange = (event: any, value: number) => {
    setPage(value)
  }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      {hasPermission ? (
        <>
          <SubTitle style={{ textAlign: 'center' }}>
            Bipe um dos pedidos críticos abaixo para iniciar a conferência
          </SubTitle>
          <CriticalOrdersConfereceTable orders={criticalOrders} />
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            style={{ alignSelf: 'center', marginTop: '20px' }}
          />
        </>
      ) : (
        <SubTitle>Bipe uma caixa para iniciar a conferência</SubTitle>
      )}
      <Image src={ImgConference} />
      <Button onClick={() => setOpenModal(true)}>Bipar caixa</Button>
      <ModalScan open={openModal} onClose={() => setOpenModal(false)} />
    </Box>
  )
}

export default CriticalOrders
