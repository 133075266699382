import { useState, useRef } from 'react'
import { Box, MenuItem, Typography } from '@material-ui/core'
import { ButtonNotification, useStyles } from './styles'
import { IoIosNotifications } from 'react-icons/io'
import { MenuPopover } from 'shared'
import NotificationPrePicking from './NotificationPrePicking'
import NotificationHelp from './NotificationHelp'
import { IAlertResponse } from 'interfaces/IAlert'
import { useSelector } from 'react-redux'
import { IState } from 'store'
import NotificationLostPrePicking from './NotificationLostPrePicking'

interface IButtonBellProps {
  handleAcceptSolicitation: (notification: IAlertResponse) => Promise<void>
}

const ButtonBellNotification = ({ handleAcceptSolicitation }: IButtonBellProps) => {
  const notifications = useSelector<IState, IAlertResponse[]>(state => state.alert)

  const thereNotification = notifications.length > 0
  const numberNotification = notifications.length

  const [popover, setPopover] = useState(false)

  const buttonRef = useRef(null)

  const classes = useStyles()

  const togglePopover = () => {
    setPopover(state => !state)
  }

  return (
    <>
      <ButtonNotification
        className={thereNotification ? classes.animation : ''}
        onClick={togglePopover}
        numberNotification={numberNotification}
        ref={buttonRef}
      >
        <IoIosNotifications
          size={24}
          className={thereNotification ? classes.iconWithAnimation : classes.iconOff}
        />
      </ButtonNotification>

      <MenuPopover
        open={popover}
        onClose={togglePopover}
        anchorEl={buttonRef.current}
        className={classes.menuPopover}
      >
        <Typography className={classes.title}>Notificações</Typography>
        <Box className={classes.scroll}>
          {notifications.map((notification, index) => {
            switch (notification.kind) {
              case 'pre_picking_break':
                return (
                  <NotificationPrePicking notification={notification} key={notification.id * 4} />
                )
              case 'lost_pre_picking':
                return (
                  <NotificationLostPrePicking
                    notification={notification}
                    key={notification.id * 9.7}
                  />
                )
              case 'support':
                return (
                  <NotificationHelp
                    notification={notification}
                    handleAcceptSolicitation={handleAcceptSolicitation}
                    key={notification.id * 4}
                  />
                )
              default:
                return null
            }
          })}
        </Box>
      </MenuPopover>
    </>
  )
}

export default ButtonBellNotification
