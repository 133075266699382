import { Box, Typography } from '@material-ui/core'
import { IProduct } from 'interfaces/IProduct'
import {
  useState,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react'
import { Modal } from 'shared'

import ItemDetails from './ItemDetails'
import { ShowItemsStyles } from './styles'
import LogoFrexco from 'assets/images/logo-frexco.svg'

export interface IShowItems {
  toggleOpenModal: () => void
  setData: (x: IProduct[]) => void
}

interface Props {
  id?: string | number
  name?: string | number
}

export const Component: ForwardRefRenderFunction<IShowItems, Props> = (
  { id, name },
  ref,
) => {
  const [data, setData] = useState<IProduct[]>([] as IProduct[])
  const [isOpen, setIsOpen] = useState(false)
  const classes = ShowItemsStyles()

  const toggleOpenModal = () => setIsOpen(state => !state)

  const handleClose = () => {
    toggleOpenModal()
  }

  useImperativeHandle(ref, () => ({ toggleOpenModal, setData }))

  return (
    <Modal
      open={isOpen}
      handleClose={handleClose}
      title="Confira os itens"
      style={{ maxHeight: '35rem', height: '100%' }}
    >
      <Box display="flex" flexDirection="column" textAlign="center" mb="2.5rem">
        <Typography className={classes.itemDetail}>#{id}</Typography>
        <Typography className={classes.itemDetail}>
          <strong>{name}</strong>
        </Typography>
      </Box>

      {data.map(item => (
        <ItemDetails
          key={item.id}
          image={item.image ? item.image : LogoFrexco}
          name={item.name}
          quantity={item.quantity}
        />
      ))}
    </Modal>
  )
}

export const ShowItems = forwardRef(Component)
