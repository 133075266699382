import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  boxUrl: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },
  boxLink: {
    cursor: 'pointer',
    color: '#757575',
    fontSize: '1.3rem',
    border: 'none',
    background: 'transparent',
    fontWeight: 500,
    textDecoration: 'none',
    transition: '0.2s',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  boxPlace: {
    color: '#353535',
    fontSize: '1.3rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
  },
})
