import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  text: {
    color: '#353535',
    fontWeight: 400,
    marginBottom: '1.5rem',
  },
  filter: {
    marginBottom: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
    '& > div': {
      width: '100%',
      minWidth: '120px',
      maxWidth: '180px',
    },
    '& > p': {
      color: '#757575',
    },
    '& > .MuiInputBase-root .MuiSelect-selectMenu:focus': { background: 'none' },
  },
  table: {
    '& .MuiTableHead-root .MuiTableCell-head': { color: '#757575' },
    '& .MuiTableBody-root .MuiTableCell-body': {
      color: '#353535',
      '&:first-child': { fontWeight: 500 },
    },
  },
  scroll: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    padding: '0 1rem',
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '10px',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A1CA4F',
      borderRadius: '9px',
    },
  },
  containerProgress: {
    marginBottom: '2rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down(350)]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem',
    },
    [theme.breakpoints.up(900)]: {
      display: 'none',
    },
  },
  textHelp: {
    color: '#757575',
    fontSize: '1.5rem',
    textAlign: 'center',
  },
}))
