import { Box, Button, ListItemIcon, MenuItem, Select, Typography } from '@material-ui/core'
import { ContainerPage, IOptions, TitlePage } from 'components'
import { useTableParams } from 'hooks/useTableParams'
import { IOrder } from 'interfaces/IOrder'
import { IPaginationParams } from 'interfaces/IPagination'
import { IStatus } from 'interfaces/IStatus'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { BsCircleFill } from 'react-icons/bs'
import { MdModeEditOutline } from 'react-icons/md'
import { orderService } from 'services/orderService'
import { Search } from 'shared'
import { read, utils } from 'xlsx'

import { Edit, IEdit } from '../../../components/ModalEditOrder/Edit'
import { usePopup } from '../../../hooks/usePopup'
import { EditMultiple, IEditMultiple } from './EditMultiple'
import { styles } from './styles'
import { Table } from './Table'

const Orders = () => {
  const classes = styles()
  const { addPopup } = usePopup()
  const [selectedFilter, setSelectedFilter] = useState<IStatus>('all' as IStatus)
  const editRef = useRef<IEdit>(null)
  const editMultipleRef = useRef<IEditMultiple>(null)
  const [selectedOrders, setSelectedOrders] = useState<string[]>([])
  const { data, params, onChange, count, status, refresh, handleSearch } = useTableParams<IOrder>({
    service: (params: IPaginationParams, signal: AbortSignal) =>
      orderService.getOrders(params, signal),
  })

  const options: IOptions<IOrder>[] = [
    {
      title: 'Editar',
      icon: MdModeEditOutline,
      action: data => editRef.current?.handleData(data),
    },
  ]

  const handleAttachment = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0]
    const data = await file?.arrayBuffer()
    const workbook = read(data)
    const worksheet = workbook.Sheets[workbook.SheetNames[0]]
    const jsonData: IOrder[] = utils.sheet_to_json(worksheet)
    e.target.value = ''
    if (!jsonData[0].order_id) {
      addPopup({
        type: 'error',
        title: 'A coluna order_id não foi encontrada',
        description: 'Por favor, anexe a planilha com a coluna order_id.',
        autoClose: false,
      })
      return
    }
    const orders: string[] = jsonData
      .map(order => String(order?.order_id))
      .filter(orderId => orderId)
    setSelectedOrders(state => [...new Set([...state, ...orders])])
    editMultipleRef.current?.toggleOpen()
  }

  const handleChangeFilter = (filter: IStatus) => {
    const statusFilter = (filter === ('all' as IStatus) ? '' : filter) as IStatus
    setSelectedFilter(filter)
    onChange({ status: statusFilter })
  }

  return (
    <ContainerPage>
      <TitlePage>Lista de pedidos</TitlePage>

      <Box display="flex" style={{ gap: '1rem' }} alignItems="center" flexWrap="wrap">
        <Box minWidth={190} maxWidth={350} width="100%">
          <Search
            fullWidth
            onChange={handleSearch}
            defaultValue={params.search}
            placeholder="Quem você busca?"
          />
        </Box>

        <Box display="flex" style={{ gap: '1rem' }} ml="auto">
          <Button
            variant="contained"
            color="secondary"
            disabled={!selectedOrders.length}
            onClick={editMultipleRef.current?.toggleOpen}
          >
            Editar pedidos ({selectedOrders.length})
          </Button>

          <input
            accept=".xlsx, .xls, .csv"
            type="file"
            id="file-input"
            onChange={handleAttachment}
            hidden
          />

          <label htmlFor="file-input">
            <Button component="span" startIcon={<AiOutlineCloudUpload />} variant="outlined">
              anexar planilha
            </Button>
          </label>
        </Box>
      </Box>

      <Box className={classes.filterAlign}>
        <Typography>Filtrar por:</Typography>

        <Select
          value={selectedFilter}
          onChange={event => handleChangeFilter(event.target.value as IStatus)}
          variant="outlined"
          className={classes.buttonFilter}
        >
          <MenuItem value="all">
            <BsCircleFill className={classes.buttonAlign} style={{ color: '#0044bb' }} />
            Todos
          </MenuItem>
          <MenuItem value="waiting">
            <BsCircleFill className={classes.buttonAlign} style={{ color: '#D7C82B' }} />
            Aguardando
          </MenuItem>
          <MenuItem value="in separation">
            <BsCircleFill className={classes.buttonAlign} style={{ color: '#2E656A' }} />
            Em separação
          </MenuItem>
          <MenuItem value="waiting reposition" style={{ color: 'ccd5ae' }}>
            <BsCircleFill className={classes.buttonAlign} style={{ color: 'ccd5ae' }} />
            Aguardando reposição
          </MenuItem>
          <MenuItem value="separated">
            <BsCircleFill className={classes.buttonAlign} style={{ color: '#89BD23' }} />
            Separado
          </MenuItem>
          <MenuItem value="conferred">
            <BsCircleFill className={classes.buttonAlign} style={{ color: '#d31f1f' }} />
            Conferido
          </MenuItem>
          <MenuItem value="loaded">
            <BsCircleFill className={classes.buttonAlign} style={{ color: '#b5179e' }} />
            Carregado
          </MenuItem>
        </Select>
      </Box>

      <Table
        options={options}
        status={status}
        count={count || 0}
        onChange={onChange}
        data={data || []}
        params={params}
        selected={selectedOrders}
        setSelected={setSelectedOrders}
        refresh={refresh}
      />

      <Edit ref={editRef} refresh={refresh} />
      <EditMultiple
        ref={editMultipleRef}
        orders={selectedOrders}
        refresh={refresh}
        cleanOrders={() => setSelectedOrders([])}
      />
    </ContainerPage>
  )
}

export default Orders
