import { Box, Typography } from '@material-ui/core'
import { GiCardboardBoxClosed } from 'react-icons/gi'
import { useEffect, useState } from 'react'
import { useStyles } from './styles'
import { IRouteDashboard } from 'interfaces/IDashboard'
import { dashboardService } from 'services/dashboardService'
import iconRoute from 'assets/icons/Icon-rota.svg'
import { usePopup } from 'hooks/usePopup'
import { ModalRoute } from '../ModalRoute'
import { BsCircleFill } from 'react-icons/bs'

interface ICardRoute {
  onOpenModal: (routeData: IRouteDashboard) => void
}

export const CardRoute = ({ onOpenModal }: ICardRoute) => {
  const { addPopup } = usePopup()
  const [routeData, setRouteData] = useState<IRouteDashboard>({} as IRouteDashboard)
  const [isLoading, setIsLoading] = useState(false)
  const controller = new AbortController()
  const classes = useStyles({ isDisabled: !routeData.total_routes_in_carambola })

  const action = () => {
    onOpenModal(routeData)
  }

  const run = async () => {
    setIsLoading(true)
    try {
      const response = await dashboardService.getRoutes(controller)
      setRouteData(response)
    } catch (err: any) {
      if (!controller.signal.aborted) {
        addPopup({
          title: 'Erro ao obter dado das rotas.',
          type: 'error',
          description: err.message || err.msg,
        })
      }
    } finally {
      setIsLoading(false)
      if (!controller.signal.aborted) {
        setTimeout(() => {
          run()
        }, 5000)
      }
    }
  }

  useEffect(() => {
    run()
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <Box
      className={classes.root}
      onClick={routeData.total_routes_in_carambola ? action : undefined}
    >
      <span className={classes.containerIcon}>
        <img src={iconRoute} />
      </span>

      <Box>
        <Typography className={classes.title}>Rotas estouradas</Typography>
        <Typography className={classes.subtitle}>
          {isLoading ? (
            'carregando'
          ) : (
            <>
              {`${
                (routeData.routes_need_reduce_quantity || 0) -
                (routeData.routes_reduced_quantity || 0)
              } ${
                (routeData.routes_need_reduce_quantity || 0) -
                  (routeData.routes_reduced_quantity || 0) ===
                1
                  ? 'rota'
                  : 'rotas'
              }`}
              <span>
                {routeData.total_routes_in_carambola ? (
                  <BsCircleFill
                    style={{ fontSize: '0.8rem', marginLeft: '1rem' }}
                    color="#08c20e"
                  />
                ) : (
                  <BsCircleFill
                    style={{ fontSize: '0.8rem', marginLeft: '1rem' }}
                    color="#c70202"
                  />
                )}
              </span>
            </>
          )}
        </Typography>
      </Box>
    </Box>
  )
}
