import {
  Box,
  Container,
  Modal,
  Typography,
  Button,
  LinearProgress,
  TextField,
  FormHelperText,
} from '@material-ui/core'
import { NotificationImportantOutlined } from '@material-ui/icons'
import Cam from 'components/CardProductScanner/Cam'
import ModalError, { IModalError } from 'components/CardProductScanner/ModalError'
import { TValue, TValueBip } from 'components/CardProductScanner/useScanner'
import { usePopup } from 'hooks/usePopup'
import { usePrePicking } from 'hooks/usePrePicking'
import { BreakOrder, Runouts } from 'interfaces/IBreakOrder'
import { ISeparationGroupConfig } from 'interfaces/ISeparationGroupService'
import { useEffect, useRef, useState, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { separationItemService } from 'services/separationItemService'
import { IState } from 'store'

import { IModalOrderCanceled, ModalOrderCanceled } from '../ModalOrderCanceled'
import { useStyles } from './styles'
import { separationItemServiceV2 } from 'services/separationItemServiceV2'

interface IModalPrinter {
  open: boolean
  handleClose: () => void
  item: Runouts
  workId: number | null
  pollingBreak: (isError: boolean, isRefresh: boolean) => Promise<void>
  order: BreakOrder
  internetConnectionOk: boolean
}

export const ModalConfirm = ({
  item,
  handleClose,
  pollingBreak,
  open,
  order,
  internetConnectionOk,
}: IModalPrinter) => {
  const classes = useStyles()
  const modalOrderCanceledRef = useRef<IModalOrderCanceled>(null)
  const [loading, setLoading] = useState(false)
  const [typeScan, setTypeScan] = useState<'box' | 'product'>('box')
  const [confirmedQuantityNow, setConfirmedQuantityNow] = useState<String>(
    String(item?.confirmed_quantity),
  )
  const [valueTag, setValueTag] = useState<TValueBip>({ value: '', type: '' as TValue })
  const modalErrorRef = useRef<IModalError>(null)
  const { addPopup } = usePopup()
  const isTypeUnity = item.unity_product_base === 'unidade'
  const isKaItem = item.unity_product_base !== item.type
  const separationGroup = useSelector<IState, ISeparationGroupConfig>(state => {
    const orderSeparationGroupId = Number(order.separation_group)
    const currentSeparationGroup = state.settings.separationGroups.find(
      separationGroup => orderSeparationGroupId === Number(separationGroup.separation_group),
    )
    return currentSeparationGroup as ISeparationGroupConfig
  })

  const isScannerDisabled = !separationGroup?.cam_scanner && !separationGroup?.infrared_scanner
  const isScannerDisableInStep =
    isScannerDisabled ||
    (!isScannerDisabled &&
      separationGroup?.steps_without_scanner?.includes(Number(item?.separation?.level)))

  const [isShowAlertMin, setIsShowAlertMin] = useState(false)
  const [isShowAlertMax, setIsShowAlertMax] = useState(false)

  const brokenQuantity =
    item.status === 3
      ? item.broken_quantity + Number(confirmedQuantityNow)
      : Number(confirmedQuantityNow) - (item.converted_quantity || item.quantity)

  const { checkIsBarcode } = usePrePicking()

  const handleClearStates = () => {
    handleClose()
    setTypeScan('box')
    setConfirmedQuantityNow('0')
  }

  const handleCanceledReposition = async () => {
    if (!isScannerDisableInStep && internetConnectionOk) {
      await handleResetAllPrePickings()
    }
    handleClose()
    setTypeScan('box')
    setConfirmedQuantityNow('0')
  }

  const handleResetAllPrePickings = async () => {
    try {
      setLoading(true)
      await separationItemServiceV2.pickResetAll(item.item, { resetInReposition: true })
      addPopup({
        type: 'success',
        title: 'Pre pickings resetados',
        description: `Pre pickings para ${item.name} resetados com sucesso!`,
      })
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao resetar pre pickings',
        description: `Ocorreu um erro, contate o administrador`,
        autoClose: false,
      })
      return
    } finally {
      setLoading(false)
    }
  }

  const handleResetPrePicking = async (product_code: string, type: TValue) => {
    try {
      await separationItemServiceV2.pickReset(item.item, {
        productCode: product_code,
        codeType: type,
      })
      addPopup({
        type: 'info',
        title: 'Pre pickings não contabilizado',
        description: `Esse pre picking para o item ${item.name} não foi contabilizado!`,
        autoClose: false,
      })
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao excluir pre picking',
        description: err?.message || err?.msg || 'Contate um administrador',
      })
    }
  }

  const scanProduct = async (value: TValueBip) => {
    try {
      setIsShowAlertMin(false)
      setIsShowAlertMax(false)
      const response = await separationItemService.replenishItem(item.item, {
        productCode: String(value.value),
        codeType: value.type,
      })
      setConfirmedQuantityNow(String(response?.confirmed_quantity))
      addPopup({
        type: 'success',
        title: 'Sucesso ao adicionar pre picking',
        description: `Pre picking para ${item.name} contabilizado com sucesso!`,
      })
      return
    } catch (err: any) {
      if (
        !err?.message?.includes('já foi separado por') &&
        !err?.message?.includes('Pacote não separado')
      ) {
        handleResetPrePicking(String(value.value), value.type)
      }
      addPopup({
        type: 'error',
        title: 'Erro ao tentar repor pre picking',
        description: err?.message || err?.msg || 'Contate um administrador',
        autoClose: false,
      })
    }
  }

  const scanQrCode = (value: TValueBip) => {
    if (typeScan === 'box' && checkIsBarcode(value)) {
      addPopup({
        type: 'error',
        title: 'Não bipe código de barras',
        description: 'Bipe o QRCode da caixa',
      })
      return
    }

    const orderId = value.value.split(';')[0]
    if (typeScan === 'product' && orderId === item.order) {
      modalErrorRef.current?.dispatchError({
        typeError: 'useProductScanType',
        message: 'Você deve bipar etiqueta do produto!',
      })
      return
    }

    if (typeScan === 'box') {
      scanBox(value.value)
      return
    }
    scanProduct(value)
  }

  const scanBox = (QRcode: string) => {
    const orderId = QRcode.split(';')[0]
    if (orderId === item.order) {
      addPopup({
        type: 'success',
        title: 'Opa, é esse pedido mesmo!',
        description: 'Agora já pode ler a etiqueta do item.',
      })
      setTypeScan('product')
      return
    }
    modalErrorRef.current?.dispatchError({
      typeError: 'wrongBox',
      beepedBox: orderId,
      correctBox: item.order,
    })
  }

  const onConfirm = async () => {
    if (!isShowAlertMin && Number(confirmedQuantityNow) < Math.abs(item.broken_quantity)) {
      setIsShowAlertMax(false)
      setIsShowAlertMin(true)
      return
    }
    if (!isShowAlertMax && Number(confirmedQuantityNow) > Math.abs(item.broken_quantity)) {
      setIsShowAlertMin(false)
      setIsShowAlertMax(true)
      return
    }

    if (isScannerDisableInStep) {
      confirmWithoutScanner()
      return
    }
    setLoading(true)
    try {
      await separationItemService.replenishItem(item.item, {
        finishReposition: true,
      })
      await pollingBreak(false, true)
      handleClearStates()
      addPopup({
        type: 'success',
        title: 'Produto reposto com sucesso.',
      })
    } catch (err: any) {
      if (err?.message === 'This order has been canceled') {
        handleCanceledReposition()
        modalOrderCanceledRef.current?.onOpen()
        return
      } else {
        addPopup({
          type: 'error',
          title: 'Erro ao atualizar status do item.',
          description: err?.message,
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const confirmWithoutScanner = async () => {
    if (!Number(confirmedQuantityNow)) {
      addPopup({
        type: 'error',
        title: 'Erro ao adicionar produto.',
        description: 'Quantidade não pode ser vazia.',
      })
      return
    }

    setLoading(true)
    try {
      await separationItemService.replenishItem(item.item, {
        totalRepositionAmount: Number(confirmedQuantityNow),
        finishReposition: true,
      })
      await pollingBreak(false, true)
      handleClearStates()
    } catch (err: any) {
      if (err?.message === 'This order has been canceled') {
        handleCanceledReposition()
        modalOrderCanceledRef.current?.onOpen()
        return
      }
      addPopup({
        type: 'error',
        title: 'Erro ao atualizar status do item.',
        description: err?.message,
      })
    } finally {
      setLoading(false)
    }
  }

  const onChangeInputReplenish = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmedQuantityNow(e.target.value)
  }

  const isButtonDisabled = !Number(confirmedQuantityNow)

  useEffect(() => {
    if (valueTag.value) {
      scanQrCode(valueTag)
      setValueTag({
        value: '',
        type: '' as TValue,
      })
    }
  }, [valueTag.value])

  return (
    <>
      <Modal open={open} className={classes.root}>
        <>
          <Container className={classes.container}>
            <LinearProgress
              variant="indeterminate"
              style={{ width: '100%', opacity: loading ? 1 : 0 }}
            />
            {isKaItem && (
              <>
                <Typography className={classes.infoProductName}>{item.name}</Typography>
                <Typography className={classes.infoProductKey}>
                  Pedido: <Typography className={classes.infoProductValue}>{item.order}</Typography>
                </Typography>
                <Typography className={classes.infoProductKey}>
                  Qnt. total:{' '}
                  <Typography className={classes.infoProductValue}>
                    {item.converted_quantity || item.quantity} {isTypeUnity ? 'un' : 'kg'}
                  </Typography>
                </Typography>
                <Typography className={classes.infoProductKey}>
                  Quebra:{' '}
                  <span
                    className={classes.infoProductValue}
                    style={brokenQuantity >= 0 ? { color: '#87ba22' } : { color: '#db3636' }}
                  >
                    {isTypeUnity ? brokenQuantity : brokenQuantity.toFixed(3)}{' '}
                    {isTypeUnity ? 'un' : 'kg'}
                  </span>
                </Typography>
                <Typography className={classes.alertConvertion}>
                  <strong>Esse item foi convertido (cliente KA).</strong>
                </Typography>
              </>
            )}
            {!isKaItem && (
              <>
                <Typography className={classes.infoProductName}>{item.name}</Typography>
                <Typography className={classes.infoProductKey}>
                  Pedido: <Typography className={classes.infoProductValue}>{item.order}</Typography>
                </Typography>
                <Typography className={classes.infoProductKey}>
                  Porcionamento:{' '}
                  <Typography className={classes.infoProductValue}>
                    {item.purchase_value} {isTypeUnity ? 'un' : 'kg'}
                  </Typography>
                </Typography>
                <Typography className={classes.infoProductKey}>
                  Qnt: <Typography className={classes.infoProductValue}>{item.quantity}</Typography>
                </Typography>
                <Typography className={classes.infoProductKey}>
                  Qnt. total:{' '}
                  <Typography className={classes.infoProductValue}>
                    {item.converted_quantity || item.quantity} {isTypeUnity ? 'un' : 'kg'}
                  </Typography>
                </Typography>
                <Typography className={classes.infoProductKey}>
                  Quebra:{' '}
                  <span
                    className={classes.infoProductValue}
                    style={brokenQuantity >= 0 ? { color: '#87ba22' } : { color: '#db3636' }}
                  >
                    {isTypeUnity ? brokenQuantity : brokenQuantity.toFixed(3)}{' '}
                    {isTypeUnity ? 'un' : 'kg'}
                  </span>
                </Typography>
              </>
            )}
            {(isScannerDisableInStep || !internetConnectionOk) && (
              <>
                <TextField
                  label="Quantidade reposta em kg ou unidade"
                  variant="outlined"
                  style={{ width: '100%', margin: '16px 0 4px 0' }}
                  type="number"
                  value={confirmedQuantityNow}
                  onChange={onChangeInputReplenish}
                />
                <FormHelperText style={{ width: '100%' }}>
                  Digite em kg ou unidade a quantidade que você adicionou na reposição.
                </FormHelperText>
              </>
            )}

            {!isScannerDisableInStep && internetConnectionOk && (
              <>
                {typeScan === 'box' && (
                  <Typography className={classes.alertScanBox}>
                    <NotificationImportantOutlined
                      style={{ color: '#2E75FF', marginRight: '.5rem' }}
                    />{' '}
                    Bipe o QRcode da caixa
                  </Typography>
                )}
                {typeScan === 'product' && (
                  <Typography className={classes.alertScanBox}>
                    <NotificationImportantOutlined
                      style={{ color: '#2E75FF', marginRight: '.5rem' }}
                    />{' '}
                    Bipe o produto
                  </Typography>
                )}
                <Cam
                  onSubmit={(value, type) =>
                    setValueTag({
                      value,
                      type,
                    })
                  }
                />
              </>
            )}

            {isShowAlertMin && (
              <Typography className={classes.alertBreak}>
                <strong>
                  {' '}
                  Quantidade informada MENOR que a quebra. <br />
                  Tem certeza?
                </strong>
              </Typography>
            )}

            {isShowAlertMax && (
              <Typography className={classes.alertBreak}>
                <strong>
                  {' '}
                  Quantidade informada MAIOR que a quebra. <br />
                  Tem certeza?
                </strong>
              </Typography>
            )}

            <Box className={classes.containerButtons}>
              <Button
                className={classes.button}
                style={{ background: '#DA3737' }}
                onClick={handleCanceledReposition}
              >
                Cancelar
              </Button>
              <Button
                className={classes.button}
                style={{ background: isButtonDisabled ? 'gray' : '#89BD23' }}
                onClick={onConfirm}
                disabled={isButtonDisabled}
              >
                Confirmar
              </Button>
            </Box>
          </Container>

          <ModalError product={item} ref={modalErrorRef} />
        </>
      </Modal>
      <ModalOrderCanceled
        callback={handleClearStates}
        clientName={order.client_name}
        orderId={item.order}
        ref={modalOrderCanceledRef}
      />
    </>
  )
}
