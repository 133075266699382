import { Box, Typography } from '@material-ui/core'
import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'

import { useStyles } from './styles'

interface IProps {
  title: string
}

export const ReturnArea = ({ title }: IProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.boxUrl}>
      <Link to="../configuracoes" className={classes.boxLink}>
        Configurações
      </Link>

      <IoIosArrowForward fontSize="1.125rem" />
      <Typography className={classes.boxPlace}>{title}</Typography>
    </Box>
  )
}
