import { IAlertResponse } from 'interfaces/IAlert'
import { enAlert } from './types'

export type IAlertReducer = IAlertResponse[]

interface IAction {
  type: enAlert
  alerts: IAlertResponse[]
}

const initialState: IAlertResponse[] = []

export default (state = initialState, action: IAction): IAlertResponse[] => {
  switch (action.type) {
    case enAlert.UPDATE_ALERT:
      return action.alerts
    default:
      return state
  }
}
