import { ISettingsRequest } from 'services/settingsService'

import { eSettings } from './types'

export const updateSettings = (value: ISettingsRequest) => {
  return {
    type: eSettings.SETTINGS,
    settings: value,
  }
}
