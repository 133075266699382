import { Accordion, Box, Button, styled, Theme } from '@material-ui/core'

export const AccordionStyled = styled(Accordion)({
  minHeight: '72px',
  '& .MuiAccordionSummary-root': {
    paddingLeft: '0',
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
      gap: '.5rem',
      '& > .MuiBox-root': {
        display: 'flex',
        width: '100%',
      },
    },
  },
})

export const BoxContainerInfo = styled(Box)(({ theme }) => ({
  marginLeft: '0.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: '1rem',
  [theme.breakpoints.down(400)]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: '1rem',
  },
}))
export const BoxInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  padding: '1rem 0',
  width: '100%',
  '& > strong': {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    color: '#5F5E69',
    fontWeight: 400,
  },
})

export const ButtonPrinter = styled(Button)(
  ({ theme, disabled }: { theme: Theme; disabled?: boolean }) => ({
    border: !disabled ? '1px solid #757575' : '',
    color: '#618619',
    borderRadius: 'calc(2.5rem /2)',
    fontWeight: 400,
    marginRight: '0.5rem',
    width: '180px',
    fontSize: '1.1rem',
    height: '35px',
    '& svg': {
      fontSize: '25px',
      marginRight: '0.5rem',
    },
    [theme.breakpoints.down(400)]: {
      width: '100%',
    },
  }),
)
