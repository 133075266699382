import { styled } from '@material-ui/core'
import { ForwardRefRenderFunction, forwardRef, useImperativeHandle, useState } from 'react'
import Lightbox, { Plugin } from 'yet-another-react-lightbox'
import Fullscreen from 'yet-another-react-lightbox/dist/plugins/fullscreen'
import Thumbnails from 'yet-another-react-lightbox/dist/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/dist/plugins/zoom'
import 'yet-another-react-lightbox/dist/styles.css'
import 'yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css'

import { IBoxImage } from '../../../../../interfaces/IDashboard'

const Root = styled(Lightbox)({
  '& .yarl__thumbnails_container': {
    background: 'rgba(43, 43, 43, 0.95)',
  },
  '& .yarl__thumbnails_vignette': {
    background: 'initial',
  },
  '& .yarl__thumbnails_thumbnail': {
    border: 'none',
  },
})

export interface IOverlayRef {
  handleOpen: (url_image: number) => void
}

interface IProps {
  boxes: IBoxImage[]
}

export const Component: ForwardRefRenderFunction<IOverlayRef, IProps> = (props, ref) => {
  const { boxes } = props
  const [isOpen, setIsOpen] = useState(false)
  const [index, setIndex] = useState(0)

  const handleOpen = (index: number) => {
    setIndex(index)
    setIsOpen(true)
  }
  const handleClose = () => setIsOpen(false)

  useImperativeHandle(ref, () => ({ handleOpen }))

  return (
    <Root
      open={isOpen}
      close={handleClose}
      index={index}
      on={{ view: ({ index }) => setIndex(index) }}
      plugins={[Thumbnails as Plugin, Fullscreen as Plugin, Zoom as Plugin]}
      carousel={{ padding: '5%', finite: true }}
      styles={{
        container: { background: 'rgba(43, 43, 43, 0.95)' },
      }}
      toolbar={{
        buttons: [
          'zoom',
          'fullscreen',
          <button key="counter" style={{ fontSize: '1rem' }} className="yarl__button">
            {index + 1}/{boxes.length}
          </button>,
          'close',
        ],
      }}
      slides={boxes.map(box => ({ src: box.url_image, alt: box.tag_value }))}
    />
  )
}

export const Overlay = forwardRef(Component)
