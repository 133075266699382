import { Box, Typography, makeStyles } from '@material-ui/core'
import { Button } from 'components'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'

interface Props {
  title?: string
  handleClose: () => void
}

const useStyles = makeStyles({
  boxCompleted: {
    textAlign: 'center',
    marginTop: '2rem',
  },
  message: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1rem',
    color: '#353535',
    margin: '1rem 0 2.5rem',
  },
  btn: {
    padding: '1rem 2.6rem',
  },
})

export const Success = (props: Props) => {
  const classes = useStyles()
  const { handleClose, title } = props

  return (
    <Box className={classes.boxCompleted}>
      <Box>
        <IoIosCheckmarkCircleOutline size="4rem" color="#618619" />
        <Typography variant="subtitle1" className={classes.message}>
          {title}
        </Typography>
      </Box>

      <Button
        type="submit"
        onClick={handleClose}
        variant="contained"
        className={classes.btn}
      >
        Fechar
      </Button>
    </Box>
  )
}
