import { makeStyles } from '@material-ui/core'
import { transparentize } from 'polished'

interface IStyleProps {
  isDisabled: boolean
}

export const useStyles = makeStyles(theme => ({
  root: (props: IStyleProps) => ({
    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
    height: '100%',
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    borderRadius: '0.8rem',
    gap: '1.5rem',
    boxShadow: '3px 10px 10px 0px rgba(0,0,0,0.03)',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.between(1340, 3000)]: {
      flex: 1,
    },
    [theme.breakpoints.between(490, 720)]: {
      width: '100%',
    },
    '&:hover $title, &:hover $subtitle': {
      color: '#ffffff',
    },
    '&:hover $containerIcon': {
      background: transparentize(0.5, '#ffffff'),
    },
    '&:hover::after': {
      top: -50,
      right: -50,
    },
    '&::after': {
      top: 400,
      right: 700,
      content: '""',
      width: '30rem',
      height: '30rem',
      borderRadius: '10rem',
      position: 'absolute',
      background: transparentize(0.7, '#AB98EE'),
      transition: '0.3s',
    },
  }),
  containerIcon: {
    position: 'relative',
    width: '45px',
    height: '45px',
    aspectRatio: '1/1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: transparentize(0.7, '#AB98EE'),
    borderRadius: '0.5rem',
    zIndex: 1,
    transition: '0.3s',
  },
  title: {
    position: 'relative',
    color: '#757575',
    zIndex: 1,
    transition: '0.3s',
    fontSize: '0.9rem',
  },
  subtitle: {
    position: 'relative',
    color: '#353535',
    fontWeight: 500,
    fontSize: '1.125rem',
    zIndex: 1,
    transition: '0.3s',
  },
}))
