import { TableContainer as TableContainerMui, styled, Button as ButtonMui } from '@material-ui/core'
import { MenuPopover } from 'shared'

export const TableContainer = styled(TableContainerMui)(({ theme }) => ({
  boxShadow: '0px 0px 10px 2px rgba(0,0,0,0.2)',
  borderRadius: '8px',
  marginTop: '2rem',
  maxHeight: '500px',
  '& thead tr th': {
    color: '#757575',
    fontWeight: 500,
    fontSize: '1.1rem',
  },
  '& th, & td': {
    width: '25%',
    paddingLeft: '2rem',
  },
  '& tbody tr td:first-child': {
    fontWeight: 500,
    fontSize: '0.9rem',
  },
  '& thead tr th:nth-child(2)': {
    textAlign: 'center',
  },
  '& tbody tr td:nth-child(2)': {
    fontWeight: 400,
    fontSize: '0.9rem',
    textAlign: 'center',
  },
  '& tr:nth-child(even)': {
    backgroundColor: '#F5F5F5',
  },
  '& tr:hover': {
    backgroundColor: '#EFEFEF',
  },
  '& tr': {
    cursor: 'pointer',
  },
}))

export const Button = styled(ButtonMui)({
  background: '#89BD23',
  color: '#FFF',
})

export const MenuPopoverStyled = styled(MenuPopover)({
  '& .MuiPopover-paper': {
    boxShadow: 'none',
    border: '1px solid #E0E0E0',
  },
})
