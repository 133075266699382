import { makeStyles, Typography } from '@material-ui/core'
import { MdOutlineSearchOff } from 'react-icons/md'

export const useStyles = makeStyles({
  root: {
    margin: '13rem auto',
    color: '#757575',
    fontSize: '1.2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
  },
})

export const NotFound = () => {
  const classes = useStyles()

  return (
    <Typography className={classes.root}>
      <MdOutlineSearchOff size={50} />
      Nenhuma quebra encontrada
    </Typography>
  )
}
