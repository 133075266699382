export const mapTagColor = (value: string) => {
  const filter =
    {
      BRANCA: '#FFFAFA',
      VERDE: '#2dc84d',
      LARANJA: '#ff9e1b',
      AMARELO: '#f2cd00',
      ROSA: '#df1995',
      AZUL: '#00a9e0',
      ROXO: '#9e7889',
      'VERDE AGUA': '#00b0b9',
      TURQUESA: '#40e0d0',
      'TURQUESA MÉDIO': '#48d1cc',
      LILÁS: '#72246c',
      'AZUL BEBE': '#2cd5c4',
      'VERDE AGUA CLARO': '#5cdae0',
      'VERDE LIMÃO': '#DBE442',
      VERMELHO: '#cb2c30',
      SALMÃO: '#e8927c',
      MARROM: '#ad7c59',
    }[value] || '#FFFAFA'

  return filter
}
