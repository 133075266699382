import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    background: '#FCFCFC',
    borderRadius: '6px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    marginTop: '1rem',
    '&:hover': {
      background: '#FFFFFF',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.10)',
    },
    '&:hover::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      background: '#89BD23',
      height: '95%',
      width: '2px',
    },
  },
})
