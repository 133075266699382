import { Modal, Typography, Box, Button, Paper } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { handleSubmitModal, IOrderConference } from '..'
import IconTrash from 'assets/icons/trash.svg'
import useStyles from './styles'

interface Props {
  open: boolean
  handleClose: () => void
  order: IOrderConference
  handleSubmit: (param: handleSubmitModal) => void
}

const ModalEditData = ({ open, handleClose, order, handleSubmit }: Props) => {
  const [quantityBoxes, setQuantityBoxes] = useState(order.boxes)
  const [modalDelete, setModalDelete] = useState(false)
  const classes = useStyles()

  const reset = () => {
    handleClose()
    setQuantityBoxes(order.boxes)
  }

  const submit = async () => {
    await handleSubmit({
      type: 'save',
      value: quantityBoxes,
      orderId: order.orderId,
    })
    setQuantityBoxes(order.boxes)
    handleClose()
  }

  const deleteSubmit = async () => {
    await handleSubmit({
      type: 'delete',
      orderId: order.orderId,
    })
    setQuantityBoxes(order.boxes)
    handleClose()
    setModalDelete(false)
  }

  useEffect(() => {
    setQuantityBoxes(order.boxes)
  }, [order])

  return (
    <Modal open={open} onClose={reset} className={classes.modalContainer}>
      <Paper className={classes.paper}>
        <Box style={modalDelete ? { display: 'none' } : {}}>
          <Typography className={classes.title}>
            Editar dados da conferência
          </Typography>
          <Typography
            style={{ textAlign: 'center', fontSize: '18px', margin: '30px 0' }}
          >
            Caixas lidas
          </Typography>
          <Box className={classes.boxTrashAddSubtract}>
            <Button
              className={classes.buttonTrash}
              onClick={() => {
                setModalDelete(true)
              }}
            >
              <img src={IconTrash} className={classes.imgTrash} />
              <span className={classes.textTrash}>Excluir</span>
            </Button>
            <Box className={classes.boxButtonsAddSubtract}>
              <Button
                onClick={() => {
                  if (quantityBoxes > 0) {
                    setQuantityBoxes(state => state - 1)
                  }
                }}
              >
                -
              </Button>
              <Typography>{quantityBoxes}</Typography>
              <Button
                onClick={() => {
                  setQuantityBoxes(state => state + 1)
                }}
              >
                +
              </Button>
            </Box>
            <span className={classes.invisibleSpan}></span>
          </Box>
          <Box className={classes.boxButtonsSubmit}>
            <Button variant="outlined" onClick={reset}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={submit}>
              Salvar
            </Button>
          </Box>
        </Box>
        <Modal open={modalDelete} className={classes.modalContainer}>
          <Paper className={classes.paper}>
            <Typography className={classes.title}>
              Deseja excluir os dados da conferência?
            </Typography>
            <Typography
              style={{
                textAlign: 'center',
                fontSize: '18px',
                margin: '30px 0',
              }}
            >
              Resetar caixas conferidas
            </Typography>
            <Box className={classes.boxButtonsSubmit}>
              <Button
                variant="outlined"
                onClick={() => {
                  setModalDelete(false)
                }}
              >
                Cancelar
              </Button>{' '}
              <Button
                variant="contained"
                style={{ background: '#EB001B' }}
                onClick={deleteSubmit}
              >
                Excluir
              </Button>
            </Box>
          </Paper>
        </Modal>
      </Paper>
    </Modal>
  )
}

export default ModalEditData
