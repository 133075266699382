import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  boxAlign: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
  },
  titlePage: {
    color: '#353535',
    fontSize: '1.6rem',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '1.7rem',
    letterSpacing: '0.01em',
  },
  configs: {
    border: '0.5px solid #757575',
    color: '#757575',
    fontSize: '1rem',
  },
  containner: {
    display: 'flex',
    height: '60vh',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    textAlign: 'center',
  },
  textWarning: {
    color: '#81d719',
    fontSize: '4rem',
    fontFamily: 'Inter',
    fontWeight: 500,
    letterSpacing: '0.01em',
  },
  textHelper: {
    color: '#000000',
    fontSize: '1.75rem',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '1.2rem',
    letterSpacing: '0.01em',
  },
})
