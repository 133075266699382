import { Box, Typography, Button } from '@material-ui/core'
import { GridCellParams, GridColumns, GridSortModel } from '@material-ui/data-grid'
import { ContainerPage, TitlePage } from 'components'
import { usePopup } from 'hooks/usePopup'
import { useTableParams } from 'hooks/useTableParams'
import { IPaginationParams } from 'interfaces/IPagination'
import { useEffect, useRef, useState } from 'react'
import { BsCircleFill } from 'react-icons/bs'
import { MdQrCodeScanner } from 'react-icons/md'
import { useNavigate } from 'react-router'
import { loadingConference } from 'services/loadingConferenceService'
import { DataGrid, Search } from 'shared'

import { ILoadingConference } from '../../../interfaces/ILoadingConference'
import { IModalConfirm, ModalConfirm } from './ModalConfirm'
import { styles } from './styles'

const LoadingConference = () => {
  const { addPopup } = usePopup()
  const navigate = useNavigate()
  const [buttomOfApocalypse, setButtomOfApocalypse] = useState(false)
  const {
    pendingList,
    inProcessList,
    conferredList,
    count,
    params,
    onChange,
    status,
    handleSearch,
    refresh,
    data,
  } = useTableParams<ILoadingConference>({
    service: async (params: IPaginationParams, signal: AbortSignal) => {
      const responseData = await loadingConference.getAll(params, signal)
      setButtomOfApocalypse(
        responseData.user_request === 'pedro.lucas' ||
          responseData.user_request === 'pedro.andrade' ||
          responseData.user_request === 'pedro.baduntis',
      )

      return {
        ...responseData,
      }
    },
  })
  const classes = styles()
  const refModalConfirm = useRef<IModalConfirm>(null)
  const [list, setList] = useState<ILoadingConference[]>([])
  const [filterStatus, setFilterStatus] = useState(1)

  useEffect(() => {
    if (filterStatus === 1) {
      setList(pendingList)
    } else if (filterStatus === 2) {
      setList(inProcessList)
    } else {
      setList(conferredList)
    }
  }, [filterStatus])

  useEffect(() => {
    setList(pendingList)
  }, [pendingList])

  const verifyBoxes = (event: GridCellParams) => {
    try {
      const value = Number(event.getValue(event.id, 'loading_boxes_number'))
      if (isNaN(value) || !value) return

      const orderData = list.find(
        item => item.order_id === event.row.order_id,
      ) as ILoadingConference

      const newOrder = {
        ...orderData,
        loading_boxes_number: value,
      }

      if (value !== orderData?.conference_boxes_number) {
        refModalConfirm.current?.setData({
          ...orderData,
          loading_boxes_number: value,
        })
        refModalConfirm.current?.toggleOpenModal()
        return
      }

      handleUpdateBoxes(newOrder)
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao verificar ID do pedido',
      })
    }
  }

  const handleUpdateBoxes = async (order: ILoadingConference): Promise<boolean> => {
    try {
      await loadingConference.updateBoxe(order.order_id, order.loading_boxes_number)
      setList(state => state.filter(x => x.id !== order.id))

      addPopup({
        type: 'success',
        title: 'Quantidade de caixas confirmada com sucesso',
      })

      refresh()
      return true
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao alterar quantidade de caixas',
      })
      return false
    }
  }

  const handleUpdateAllBoxes = async () => {
    try {
      await loadingConference.updateAllBoxes()
      addPopup({
        type: 'success',
        title: 'Quantidade de caixas confirmada com sucesso',
      })

      refresh()
      return true
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao alterar quantidade de caixas',
      })
      return false
    }
  }

  const handleSort = (params: GridSortModel) => {
    if (!params.length) {
      onChange({ orderDirection: undefined })
      return
    }

    onChange({
      orderDirection: params[0].sort === 'asc' ? params[0].field : `-${params[0].field}`,
    })
  }

  const columns: GridColumns = [
    {
      field: 'order_id',
      headerName: 'N° do pedido',
      headerAlign: 'center',
      flex: 1,
      minWidth: 130,
      align: 'center',
      disableColumnMenu: true,
    },
    {
      field: 'client_name_or_trade',
      headerName: 'Cliente',
      flex: 2,
      minWidth: 250,
      disableColumnMenu: true,
    },
    {
      field: 'route',
      headerName: 'Rota',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 110,
      disableColumnMenu: true,
    },
    {
      field: 'loading_boxes_number',
      headerName: 'Caixas lidas',
      headerAlign: 'center',
      align: 'center',
      editable: true,
      type: 'number',
      flex: 1,
      minWidth: 200,
      disableColumnMenu: true,
      renderCell: data => {
        if (!data.formattedValue) {
          return (
            <Typography style={{ color: '#757575' }}>
              0/{data.row.conference_boxes_number}
            </Typography>
          )
        }

        return (
          <Typography>
            {data.formattedValue}/{data.row.conference_boxes_number}
          </Typography>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'right',
      align: 'right',
      width: 50,
      renderCell: () => {
        if (filterStatus === 3) {
          return <BsCircleFill style={{ color: '#04D361' }} />
        } else if (filterStatus === 2) {
          return <BsCircleFill style={{ color: '#EB001B' }} />
        } else {
          return <BsCircleFill style={{ color: '#FFC107' }} />
        }
      },
    },
  ]

  return (
    <>
      <ContainerPage>
        <Box className={classes.headerAlign}>
          <TitlePage>Conferência de caixas</TitlePage>

          {buttomOfApocalypse && (
            <Button
              variant="outlined"
              onClick={() => handleUpdateAllBoxes()}
              className={classes.buttonFilterActive}
            >
              <Typography className={classes.buttonText}>Liberar romaneios</Typography>
            </Button>
          )}
          <Search
            className={classes.search}
            fullWidth
            onChange={handleSearch}
            defaultValue={params.search}
            placeholder="Pesquise pelo N° do pedido/cliente"
          />
        </Box>

        <Box className={classes.filterAlign}>
          <Typography>Filtrar:</Typography>

          <Button
            variant="outlined"
            onClick={() => setFilterStatus(1)}
            className={filterStatus === 1 ? classes.buttonFilterActive : classes.buttonFilter}
          >
            <BsCircleFill className={classes.buttonAlign} style={{ color: '#FFC107' }} />
            <Typography className={classes.buttonText}>Pendente</Typography>
          </Button>

          <Button
            variant="outlined"
            onClick={() => setFilterStatus(2)}
            className={filterStatus === 2 ? classes.buttonFilterActive : classes.buttonFilter}
          >
            <BsCircleFill className={classes.buttonAlign} style={{ color: '#EB001B' }} />
            <Typography className={classes.buttonText}>Em andamento</Typography>
          </Button>

          <Button
            variant="outlined"
            onClick={() => setFilterStatus(3)}
            className={filterStatus === 3 ? classes.buttonFilterActive : classes.buttonFilter}
          >
            <BsCircleFill className={classes.buttonAlign} style={{ color: '#04D361' }} />
            <Typography className={classes.buttonText}>Conferido</Typography>
          </Button>
        </Box>

        <DataGrid
          columns={columns}
          rows={!data ? list : data}
          autoHeight
          refresh={refresh}
          status={status}
          loading={status === 'pending'}
          onCellEditStop={verifyBoxes}
          rowCount={count}
          page={params.page}
          onPageChange={value => onChange({ page: value })}
          onPageSizeChange={value => onChange({ pageSize: value, page: 1 })}
          onSortModelChange={handleSort}
          sortingMode="server"
          paginationMode="server"
          pagination
          footer={false}
        />

        <Box className={classes.BoxConference}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => navigate('./pedidos')}
          >
            <MdQrCodeScanner size={30} />
            Iniciar conferência
          </Button>
        </Box>
      </ContainerPage>

      <ModalConfirm ref={refModalConfirm} confirm={handleUpdateBoxes} cancelConfirm={refresh} />
    </>
  )
}

export default LoadingConference
