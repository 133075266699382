import { TableBody, TableRow, TextField, Typography, TableCell, TableHead } from '@material-ui/core'
import { ContainerPage, TitlePage } from 'components'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AlertError,
  ButtonPrint,
  Container,
  ContainerRoutes,
  RouteStatus,
  SkeletonAccordion,
  Table,
  useStyles,
} from './styles'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { usePopup } from 'hooks/usePopup'
import { IRoutes, routeService } from 'services/routeService'
import { getDeliveryDate } from 'utils'
import { mapTagColor } from 'utils/mapTagColor'
import moment from 'moment'

const ReprintRoutes = () => {
  const { addPopup } = usePopup()
  const controller = useRef<AbortController>(new AbortController())
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const allRoutes = useRef<[string, IRoutes][]>([])
  const [routes, setRoutes] = useState<[string, IRoutes][]>([])
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoadingPrint, setIsLoadingPrint] = useState({
    routes: [] as string[],
    orders: [] as string[],
  })

  const classes = useStyles()

  const onClickPrint = async (routeId: string, orderId?: string) => {
    try {
      setIsLoadingPrint(state => {
        if (orderId) {
          return {
            ...state,
            orders: [...state.orders, orderId],
          }
        }
        return {
          ...state,
          routes: [...state.routes, routeId],
        }
      })
      await routeService.postReprintRoute(routeId, orderId)
      await new Promise(resolve => setTimeout(resolve, 1000))
      addPopup({
        title: orderId
          ? `Impressão do pedido #${orderId} enviada com sucesso`
          : `Impressão da rota ${routeId} enviada com sucesso`,
        type: 'success',
        autoClose: false,
      })
      getRoutes()
    } catch (err: any) {
      addPopup({
        title: 'Erro ao reimprimir rota',
        type: 'error',
        description: err?.message ?? err?.msg,
      })
    } finally {
      setIsLoadingPrint(state => {
        if (orderId) {
          return {
            ...state,
            orders: state.orders.filter(order => order !== orderId),
          }
        }
        return {
          ...state,
          routes: state.routes.filter(route => route !== routeId),
        }
      })
    }
  }

  const getRoutes = async () => {
    try {
      setIsLoading(true)
      const { day, month, year } = getDeliveryDate()
      const response = await routeService.getRoutes(`${year}-${month}-${day}`, controller.current)
      allRoutes.current = Object.entries(response.data)
      setRoutes(Object.entries(response.data))
      setIsError(false)
    } catch (error: any) {
      if (controller.current.signal.aborted === true) return
      addPopup({
        title: 'Erro ao pegar dados de rota',
        type: 'error',
        description: error?.message ?? error?.msg,
      })
      setIsError(true)
    } finally {
      if (controller.current.signal.aborted === false) {
        timeout.current = setTimeout(() => {
          controller.current = new AbortController()
          getRoutes()
        }, 60000)
      }
      setIsLoading(false)
    }
  }

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setInput(value)
    const filteredRoutes = allRoutes.current.filter(
      ([route, { orders }]) =>
        route.includes(value) || orders.some(({ order_id }) => order_id.includes(value)),
    )
    setRoutes(filteredRoutes)
  }

  const formatDate = (date: string) => {
    const dateMoment = moment(date)
    return dateMoment.format('HH:mm:ss')
  }

  useEffect(() => {
    getRoutes()
    return () => {
      controller.current?.abort()
      clearTimeout(timeout.current as ReturnType<typeof setTimeout>)
    }
  }, [])

  return (
    <ContainerPage>
      <TitlePage>Reimpressão de rotas</TitlePage>
      <Container>
        <TextField
          label="Rota ou pedido"
          variant="outlined"
          value={input}
          onChange={onChangeInput}
        />
        <ContainerRoutes>
          {isError && (
            <AlertError background="#f4433650" borderColor="#f44336">
              Erro ao pegar dados de rotas, contate um administrador.
            </AlertError>
          )}
          {!isError && !isLoading && routes.length === 0 && (
            <AlertError background="#FFF3CD" borderColor="#CC9A06">
              Nenhuma rota encontrada.
            </AlertError>
          )}
          {isLoading &&
            routes.length === 0 &&
            Array.from({ length: 5 }).map((_, index) => (
              <SkeletonAccordion key={index * 50} className={classes.skeleton} />
            ))}
          {(!isLoading || routes.length > 0) &&
            routes.map(([route, { color_route, orders, status }]) => (
              <Accordion key={`${route}-${color_route}`}>
                <AccordionSummary
                  colorRoute={mapTagColor(color_route)}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Rota {route}</Typography>
                  <Typography>{color_route}</Typography>
                  <Typography>
                    {orders.length} pedido{orders.length > 1 ? 's' : ''}
                  </Typography>
                  <ButtonPrint
                    onClick={() => {
                      onClickPrint(route)
                    }}
                    disabled={isLoadingPrint.routes.includes(route)}
                  >
                    {isLoadingPrint.routes.includes(route) && (
                      <div className={classes.loadingSpinner}></div>
                    )}
                    Imprimir rota
                  </ButtonPrint>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Pedido</TableCell>
                        <TableCell>Caixas</TableCell>
                        <TableCell>Reimpressões</TableCell>
                        <TableCell>Impresso as</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orders.map(({ boxes, order_id, quantity_reprint, date_printed }) => (
                        <TableRow key={`${boxes}-${order_id}`}>
                          <TableCell>#{order_id}</TableCell>
                          <TableCell>{boxes}</TableCell>
                          <TableCell>{quantity_reprint}</TableCell>
                          <TableCell>{formatDate(date_printed)}</TableCell>
                          <TableCell>
                            <ButtonPrint
                              onClick={() => {
                                onClickPrint(route, order_id)
                              }}
                              disabled={isLoadingPrint.orders.includes(order_id)}
                            >
                              {isLoadingPrint.orders.includes(order_id) && (
                                <div className={classes.loadingSpinner}></div>
                              )}
                              Imprimir pedido
                            </ButtonPrint>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <AlertError background="#FFF3CD" borderColor="#CC9A06">
                    Demais pedidos serão impressos com a rota correta.
                  </AlertError>
                </AccordionDetails>
              </Accordion>
            ))}
        </ContainerRoutes>
      </Container>
    </ContainerPage>
  )
}

export default ReprintRoutes
