export const convertHours = (value: string) => {
  if (!value) return 0

  const hours = value.split(':').map(item => Number(item))

  const hh = hours[0] * 3600000
  const mm = hours[1] * 60000
  const ss = hours[2] * 1000

  return hh + mm + ss
}
