import { useTableParams } from 'hooks/useTableParams'
import { Runouts } from 'interfaces/IBreakOrder'
import moment from 'moment'
import { useState } from 'react'
import { MdExpandMore } from 'react-icons/md'
import { separationItemService } from 'services/separationItemService'
import { Scrollbar } from 'shared'
import Pagination from 'shared/TableCustom/TablePagination'

import { Loading } from '../../styles'
import { CardContainerOrdersStyled, Search } from '../CardContainerOrders/styles'
import Calendar from './components/Calendar'
import {
  ContainerFilter,
  ContainerScroll,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ContainerAccordions,
  ContainerLabels,
  ContainerLabelsAccordion,
  TableContainer,
} from './styles'

const CardHistory = () => {
  const { data, params, count, status, onChange, handleSearch, refresh } = useTableParams({
    service: params => separationItemService.getItensToReposition(params),
    paramsDefault: { operationDate: moment(new Date()).format('YYYY-MM-DD'), pageSize: 10 },
  })
  const isLoading = status === 'pending'
  const [selectedDate, setSelectedDate] = useState(new Date())

  const handleOnSelectDate = async (newDate: Date) => {
    setSelectedDate(newDate)
    onChange({ operationDate: moment(newDate).format('YYYY-MM-DD'), page: 1 })
  }

  return (
    <CardContainerOrdersStyled>
      <Loading loading={isLoading} loadingColor="#2549CC" colorBackground="#97a3d1" />
      <ContainerFilter>
        <Search
          placeholder="N° do pedido"
          onChange={handleSearch}
          defaultValue={params.search ?? ''}
        />
        <Calendar
          onSelectedDate={handleOnSelectDate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          baseFilter="separationDate"
        />
      </ContainerFilter>
      <ContainerScroll>
        <ContainerAccordions>
          <ContainerLabels>
            <span></span>
            <span>N° Pedido</span>
            <span>Liberado por</span>
            <span>Itens com quebra</span>
          </ContainerLabels>
          {data.map(order => (
            <Accordion key={order.order_id}>
              <AccordionSummary
                expandIcon={<MdExpandMore color="#A3A3A3" />}
                style={{ flexDirection: 'row-reverse' }}
              >
                <ContainerLabelsAccordion>
                  <span>{order.order_id}</span>
                  <span>{order.released_by}</span>
                  <span>{order.runouts.length}</span>
                </ContainerLabelsAccordion>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <span>Item</span>
                        </th>
                        <th>
                          <span>Tipo de quebra</span>
                        </th>
                        <th>
                          <span></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.runouts?.map(item => (
                        <tr key={item?.id}>
                          <td
                            align="left"
                            style={{ maxWidth: '140px', fontWeight: '500 !important' }}
                          >
                            <span>{item?.name}</span>
                          </td>
                          <td align="center">
                            <span>{item.status === 5 && 'Reposto'}</span>
                            <span>{item.status === 4 && 'Quebra parcial'}</span>
                            <span>{item.status === 3 && 'Quebra total'}</span>
                            <span>{item?.status === 5 && 'Reposto'}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))}
        </ContainerAccordions>
      </ContainerScroll>
      <Scrollbar>
        <Pagination
          page={params.page}
          count={count}
          onChange={(_, newPage: number) => onChange({ page: newPage })}
          rowsPerPage={params.pageSize}
          handleRowsPerPageChange={newPageSize => onChange({ pageSize: newPageSize, page: 1 })}
        />
      </Scrollbar>
    </CardContainerOrdersStyled>
  )
}

export default CardHistory
