import { eINFOS } from './types'

export interface IInfosReducer {
  separation: number | 'caixa pronta' | null
  runningMachine: number | null
  name?: string
}

interface actionProps extends IInfosReducer {
  type: eINFOS
}

const initialState: IInfosReducer = {
  separation: null,
  runningMachine: null,
  name: '',
}

export default (state = initialState, action: actionProps): IInfosReducer => {
  switch (action.type) {
    case eINFOS.SEPARATION:
      return {
        ...state,
        separation: action.separation,
      }
    case eINFOS.RUNNINGMACHINE:
      return {
        ...state,
        runningMachine: action.runningMachine,
      }
    case eINFOS.STARTSEPARATION:
      return {
        ...state,
      }
    case eINFOS.NAMEUSER:
      return {
        ...state,
        name: action.name,
      }
    case eINFOS.RESET:
      return {
        ...state,
        separation: null,
        runningMachine: null,
      }
    default:
      return state
  }
}
