export const getLastUpdateHourAndMinute = (lastUpdated?: Date | string): string => {
  if (!lastUpdated) {
    return 'Ainda não finalizado'
  }

  const date = new Date(lastUpdated)
  const hh = String(date.getHours()).padStart(2, '0')
  const mm = String(date.getMinutes()).padStart(2, '0')

  return `${hh}:${mm}`
}
