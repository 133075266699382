import { Box, MenuItem, Select, styled, Typography } from '@material-ui/core'
import { Pagination as MuiPagination, PaginationProps } from '@material-ui/lab'

import tableConfig from '../TableConfig'

const SelectStyle = styled(Select)({
  '& .MuiSelect-root': {
    paddingLeft: '0.5rem',
    fontSize: '0.9rem',
  },
  '&.MuiInput-underline:before': {
    display: 'none',
  },
  '&.MuiInput-underline:after': {
    display: 'none',
  },
})

interface Props extends PaginationProps {
  count: number
  rowsPerPage: number
  handleRowsPerPageChange: (x: number) => void
}

const Pagination = (props: Props) => {
  const { count, rowsPerPage, handleRowsPerPageChange, onChange, page } = props

  return (
    <Box
      p="1rem"
      display="flex"
      justifyContent="center"
      style={{ gap: '1rem' }}
      width="max-content"
      m="0 auto"
    >
      <MuiPagination
        page={page}
        color="secondary"
        onChange={onChange}
        count={Math.ceil(count / rowsPerPage)}
      />
      <Box display="flex" style={{ gap: '0.5rem' }} alignItems="center">
        <Typography style={{ fontSize: '0.9rem' }}>
          Linhas por página
        </Typography>

        <SelectStyle
          value={rowsPerPage}
          onChange={e => handleRowsPerPageChange(Number(e.target.value))}
        >
          {tableConfig.rowsPerPageOptions.map(item => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </SelectStyle>
      </Box>
    </Box>
  )
}

export default Pagination
