export const mapMessageError = (message: string) => {
  if (message?.includes('canceled')) {
    const orderId = message.match(/(\d+)/)
    return !orderId ? 'Pedido cancelado' : `Pedido ${orderId[0]} cancelado`
  }

  const errorMessage =
    {
      'Order(s) not found': 'Pedido não encontrado',
      'Error: Pedido ainda não iniciado': 'Pedido ainda não iniciado',
    }[message] || message

  return errorMessage
}
