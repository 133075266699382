import {
  alpha,
  Box,
  BoxProps,
  Button,
  styled,
  Typography,
  useTheme,
} from '@material-ui/core'
import { BiErrorAlt } from 'react-icons/bi'
import { MdSearchOff } from 'react-icons/md'

import { IStatusTable } from '../../../hooks/useTableParams'
import { debaunce } from '../../../utils'

const BoxStyle = styled(Box)({
  height: '15rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '1rem',
})

const getMessage = (status: IStatusTable) => {
  const initial = {
    title: '',
    icon: MdSearchOff,
  }

  const message = {
    success: {
      title: 'Nenhum dado encontrado',
      icon: MdSearchOff,
    },
    error: {
      title: 'Erro ao buscar dados',
      icon: BiErrorAlt,
    },
    initial: initial,
    pending: initial,
  }[status]

  return message
}

interface Props extends BoxProps {
  status: IStatusTable
  refresh: () => Promise<void> | void
}

const NoData = ({ status, refresh, ...rest }: Props) => {
  const { title, icon: Icon } = getMessage(status)
  const debaunceRefresh = debaunce({ fn: refresh, delay: 500 })
  const theme = useTheme()

  return (
    <BoxStyle {...rest}>
      <Icon
        color={alpha(
          status === 'error'
            ? theme.palette.warning.main
            : theme.palette.secondary.main,
          0.3,
        )}
        size="5rem"
      />
      <Typography
        component="strong"
        style={{ fontWeight: 'bold', color: '#757575' }}
      >
        {title}
      </Typography>

      {status === 'error' && (
        <Button
          variant="outlined"
          style={{
            color: theme.palette.warning.main,
            borderColor: theme.palette.warning.main,
          }}
          onClick={debaunceRefresh}
        >
          Tentar novamente
        </Button>
      )}
    </BoxStyle>
  )
}

export default NoData
