import { Tab, Tabs, Box } from '@material-ui/core'
import CardProductSeparated from 'components/CardProductSeparated'
import { IProduct } from 'interfaces/IProduct'

import useStyles from './styles'

interface Props {
  switchSepareted: 'separeted' | 'breaches'
  setSwitchSepareted: (value: 'separeted' | 'breaches') => void
  productSeparated: IProduct[]
}

const SeparetedProducts = (props: Props) => {
  const { switchSepareted, setSwitchSepareted, productSeparated } = props
  const classes = useStyles()

  const filterProductsSepareted = productSeparated.filter(product => product.status === 2)
  const filterProductsBreaches = productSeparated.filter(
    product => product.status === 3 || product.status === 4,
  )

  return (
    <>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={switchSepareted}
        className={classes.tabs}
        style={{ marginTop: '48px' }}
        onChange={() => {
          if (switchSepareted === 'separeted') {
            setSwitchSepareted('breaches')
            return
          }
          setSwitchSepareted('separeted')
        }}
      >
        <Tab label="Separados" value="separeted" />
        <Tab label="Quebras" value="breaches" />
      </Tabs>
      {switchSepareted === 'separeted' && (
        <Box marginTop="3rem">
          <Box className={classes.separatedOrder}>
            {filterProductsSepareted.map(item => (
              <CardProductSeparated key={item.id} {...item} />
            ))}
          </Box>
        </Box>
      )}
      {switchSepareted === 'breaches' && (
        <Box marginTop="3rem">
          <Box className={classes.separatedOrder}>
            {filterProductsBreaches.map(item => (
              <CardProductSeparated key={item.id} {...item} isBreaches />
            ))}
          </Box>
        </Box>
      )}
    </>
  )
}

export default SeparetedProducts
