import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    maxWidth: '28rem',
    width: '100%',
    margin: 'auto 2%',
    background: '#FFFFFF',
    padding: '1.875rem',
    borderRadius: '6px',
  },
  loading: {
    textAlign: 'center',
    padding: '2.81rem 0',
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: '2rem',
  },
  containerBtn: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  btnNo: {
    border: '1px solid #757575',
    background: '#FFFFFF',
    color: '#757575',
    transition: '0.2s',
    '&:hover': {
      background: '#FFFFFF',
      filter: 'brightness(90%)',
    },
  },
})
