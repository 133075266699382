import { IOrder } from 'interfaces/IOrder'

import { IWork } from '../../../interfaces/ISeparation'
import { eOrder } from './types'

export const updateOrder = (data: IOrder) => {
  return {
    type: eOrder.UPDATE_ORDER,
    data: data,
  }
}

export const updateWork = (data: IWork) => {
  return {
    type: eOrder.UPDATE_WORK,
    data: data,
  }
}

export const updatePreviousOrder = (data: IOrder) => {
  return {
    type: eOrder.UPDATE_PREVIOUS_ORDER,
    data: data,
  }
}

export const clearOrder = () => {
  return {
    type: eOrder.CLEAR_ORDER,
  }
}

export const clearWork = () => {
  return {
    type: eOrder.CLEAR_WORK,
  }
}

export const clearPreviouusOrder = () => {
  return {
    type: eOrder.CLEAR_PREVIOUS_ORDER,
  }
}

export const clearOrderFastTrack = () => {
  return {
    type: eOrder.CLEARFASTTRACKORDER,
  }
}
