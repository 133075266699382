import { usePopup } from 'hooks/usePopup'
import { IProduct } from 'interfaces/IProduct'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { alertService } from 'services/alertService'
import { IState } from 'store'

import { IModalType } from './ModalPermAdmin'

export const usePermAdmin = (product: IProduct, resetOrder?: () => Promise<void>) => {
  const { addPopup } = usePopup()
  const [openModalAdmin, setOpenModalAdmin] = useState({
    open: false,
    type: 'asking' as IModalType,
  })
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(
    product.use_conversion_flow ? true : null,
  )

  const workId = useSelector<IState, any>(state => state.orderFastTrack.work.id)

  const handleOnConfirm = async (itemId: number, orderId: string) => {
    setOpenModalAdmin(state => ({ ...state, type: 'waiting' }))
    getAuthorization(itemId, orderId)
  }

  const handleOpenModalAdmin = () => {
    setOpenModalAdmin({
      type: 'asking',
      open: true,
    })
  }

  const getAuthorization = async (itemId: number, orderId: string) => {
    try {
      await alertService.getAuthorization(itemId, orderId)
      checkAuthorization(itemId)
    } catch (err: any) {
      addPopup({
        title: 'Erro ao solicitar autorização',
        type: 'error',
        description: err?.message,
      })
      setOpenModalAdmin({
        open: false,
        type: 'asking' as IModalType,
      })
    }
  }

  const checkAuthorization = async (itemId: number, isStart = false) => {
    try {
      const [response] = await alertService.getAlertPrePickingBreak(itemId, workId)

      if (response?.status === 'awaiting') {
        setTimeout(() => {
          checkAuthorization(itemId)
        }, 5000)
      }

      if (isStart && response?.status === 'awaiting') {
        setOpenModalAdmin({ open: true, type: 'waiting' })
      }

      if (response?.status === 'accept') {
        setOpenModalAdmin({ open: true, type: 'authorized' })
        setIsAuthorized(true)
      }

      if (response?.status === 'declined') {
        setOpenModalAdmin({ open: true, type: 'denied' })
        setIsAuthorized(false)
      }
    } catch (err: any) {
      if (err?.message === 'Order reseted') {
        resetOrder && resetOrder()
        return
      }
      addPopup({
        title: 'Erro ao checar autorização',
        type: 'error',
        description: err?.message,
      })
    }
  }

  const handleCloseModalAdmin = () => {
    setOpenModalAdmin(state => ({ ...state, open: false }))
  }

  return {
    handleOpenModalAdmin,
    handleCloseModalAdmin,
    openModalAdmin,
    handleOnConfirm,
    checkAuthorization,
    isAuthorized,
  }
}
