import {
  Box,
  Modal as ModalMui,
  Typography,
  styled,
  Button as ButtonMui,
  Paper as PaperMui,
} from '@material-ui/core'

export const Modal = styled(ModalMui)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const Paper = styled(PaperMui)({
  maxWidth: '600px',
  width: '100%',
  padding: '2rem',
})

export const Title = styled(Typography)({
  color: '#353535',
  fontWeight: 500,
  fontSize: '1.2rem',
  textAlign: 'center',
  marginBottom: '2rem',
})

export const Subtitle = styled(Typography)({
  color: '#757575',
  fontSize: '1rem',
  marginBottom: '1rem',
})

export const ContainerOrder = styled(Box)({
  backgroundColor: '#FCFCFC',
  padding: '1rem 2rem',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  borderRadius: '1rem',
})

export const Text = styled(Typography)(({ colorText }: { colorText: string }) => ({
  color: colorText,
  fontSize: '1rem',
  fontWeight: 500,
  marginBottom: '0.5rem',
}))

export const Button = styled(ButtonMui)({
  width: '100%',
  border: '1px solid #757575',
  color: '#757575',
})
