import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: '400px',
      padding: '1rem 0',
    },
  },
  container: {
    '&.MuiContainer-root': {
      width: '100%',
      maxWidth: '650px',
      position: 'relative',
      padding: '0px 20px 10px',
    },
  },
  title: {
    textAlign: 'left',
    color: '#88BC23',
    fontSize: '1.3rem',
    marginBottom: '1.4rem',
  },
  closeBtn: {
    background: '#f1f1f5',
    position: 'absolute',
    top: -5,
    right: 10,
    width: 30,
    height: 30,
  },
  btnSubmit: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})
