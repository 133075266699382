import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    maxWidth: '400px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '2rem ',
  },
  title: {
    color: '#353535',
    fontWeight: 600,
    fontSize: '1.2rem',
    textTransform: 'none',
    textAlign: 'center',
  },
  description: {
    color: '#353535',
    fontWeight: 400,
    fontSize: '1rem',
    textAlign: 'center',
  },
  detailsText: {
    color: '#353535',
    fontSize: '1rem',
    textAlign: 'center',
    '& strong': {
      fontWeight: 500,
    },
  },
  buttonExit: {
    padding: '0.5rem 1rem',
    color: '#353535',
    border: '1px solid #353535',
    height: '42px',
    borderRadius: '42px',
  },
}))
