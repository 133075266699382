import { makeStyles } from '@material-ui/core'
import { mapTagColor } from 'utils/mapTagColor'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  info: {
    fontSize: '1.3rem',
    color: '#757575',
    fontWeight: 400,
  },
  obs: {
    fontSize: '1.12rem',
    color: '#757575',
    marginBottom: '2rem',
    '& > span': {
      color: '#353535',
    },
  },
  clientCritical: {
    margin: '0.5rem auto 1rem',
  },
  strong: {
    fontWeight: 500,
    color: '#353535',
  },
  timeConference: {
    fontSize: '1.1rem',
    color: '#757575',
    fontWeight: 400,
    textAlign: 'center',
  },
  store: {
    fontWeight: 500,
    color: ({ store }: { store: string }) => {
      if (store?.includes('iFood')) return '#E9202E'
      if (store?.includes('PF')) return '#2B6AA4'
      return '#89BD23'
    },
  },
})

export const buttonStyle = makeStyles({
  buttonColor: {
    width: '12px',
    height: '12px',
    color: (props: { color: string }) => mapTagColor(props.color),
    marginRight: '0.5rem',
  },
})
