/* eslint-disable prettier/prettier */

import { api as apiService, ApiService } from './api'
import { IAmountProductsBySeparationOrder } from 'interfaces/IRequestProduct'

class ProductApiRequestService {
  constructor(private readonly api: ApiService) {}

  async getAmountProductsBySeparationOrder(
    params: number,
  ): Promise<IAmountProductsBySeparationOrder> {
    return this.api.get(`${productApiUrl}/v2/product/separation/${params}`)
  }
}
const productApiUrl = process.env.REACT_APP_API_PRODUCT
export const productApiResquestService = new ProductApiRequestService(apiService)
