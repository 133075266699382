import { PopupContainer } from 'components'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { v4 } from 'uuid'

export type IPopupMessage = {
  id: string
  type: 'success' | 'error' | 'info'
  title: string
  description?: ReactNode
  autoClose?: boolean
}

export type addPopup = (message: Omit<IPopupMessage, 'id'>) => void

type IPopupContextData = {
  addPopup: addPopup
  removePopup(id: string): void
  clearAllPop(): void
}

const PopupContext = createContext({} as IPopupContextData)

const PupupProvider = ({ children }: { children: ReactNode }) => {
  const [messages, setMessages] = useState<IPopupMessage[]>([])

  const addPopup = ({
    autoClose = true,
    ...rest
  }: Omit<IPopupMessage, 'id'>) => {
    const id = v4()
    const popup = { id, autoClose, ...rest }
    setMessages(state => [...state, popup])
  }

  const removePopup = (id: string) => {
    setMessages(state => state.filter(message => id !== message.id))
  }

  const clearAllPop = () => setMessages([])

  useEffect(() => {
    window.addEventListener('offline', () => {
      addPopup({
        type: 'error',
        title: 'Sem conexão',
        description: 'Por favor, verifique sua conexão com a internet.',
        autoClose: false,
      })
    })

    window.addEventListener('online', () => {
      addPopup({
        type: 'success',
        title: 'Conexão restabelecida',
      })
    })
  }, [])

  return (
    <PopupContext.Provider value={{ addPopup, removePopup, clearAllPop }}>
      <PopupContainer messages={messages} />
      {children}
    </PopupContext.Provider>
  )
}

const usePopup = () => useContext(PopupContext)
export { PupupProvider, usePopup }
