import { makeStyles } from '@material-ui/core'
import { transparentize } from 'polished'

export const useStyles = makeStyles({
  root: {
    flex: 1,
    width: '100%',
    background: '#ffffff',
    boxShadow: '3px 10px 10px 0px rgba(0,0,0,0.03)',
    borderRadius: '0.625rem',
    position: 'relative',
    display: 'flex',
    gap: '1.5rem',
    alignItems: 'center',
    padding: '1rem',
    height: '100%',
  },
  containerIcon: {
    width: '45px',
    height: '45px',
    aspectRatio: '1/1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: transparentize(0.7, '#2E656A'),
    borderRadius: '0.5rem',
  },
  title: {
    color: '#757575',
    fontWeight: 400,
    fontSize: '0.9rem',
  },
  subtitle: {
    color: '#353535',
    fontWeight: 500,
    fontSize: '1.125rem',
  },
  noOrders: {
    color: '#353535',
    fontWeight: 500,
    fontSize: '0.9rem',
  },
})
