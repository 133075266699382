import { IProduct } from 'interfaces/IProduct'

import { ApiService, api as apiService } from './api'

class ItemServices {
  constructor(private readonly api: ApiService) {}

  async getItems(orderId: string): Promise<IProduct[]> {
    return await this.api.get(carambolaUrl + '/separation/item', {
      params: { orderId },
    })
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const itemServices = new ItemServices(apiService)
