import CardProduct from 'components/CardProduct'
import { CardProductScanner } from 'components/CardProductScanner'
import { IProduct } from 'interfaces/IProduct'
import { ISeparationGroupConfig } from 'interfaces/ISeparationGroupService'
import { IDataBreach } from 'pages/Operation/OrderSeparation/DialogBreak'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { animated } from 'react-spring'
import { IState } from 'store'

import { IDataConfirmItem } from '../../services/separationItemService'
import { useStyles } from './styles'

interface ICardProductProps {
  product: IProduct
  style: object
  view?: 'separation' | 'fastTrack' | 'conference'
  handleDialogBreach: (x: IDataBreach) => void
  handleConfirm: (x: IDataConfirmItem) => Promise<IProduct | void>
  orderId: string
  resetOrder?: () => Promise<void>
  step: number
}

export const ContainerCardProduct = ({
  product,
  style,
  handleDialogBreach,
  handleConfirm,
  orderId,
  resetOrder,
  step,
}: ICardProductProps) => {
  const classes = useStyles()
  const [isConfirm, setIsConfirm] = useState(() => {
    return product.status === 2 || product.status === 3
  })
  const [loading, setLoading] = useState(false)
  const isScanner = useSelector<IState, boolean>(state => state.toggleScanner)

  const { name, id, grouped, pre_boxed, converted_quantity, unity_product_base, quantity } = product

  const { isUnity, quantityToSeparate } = useMemo(() => {
    const isUnity: boolean = pre_boxed || unity_product_base === 'unidade'

    const quantityToSeparate: number = pre_boxed ? quantity : converted_quantity || quantity

    return { isUnity, quantityToSeparate }
  }, [product])

  const handleConfirmProduct = async (
    data: Omit<IDataConfirmItem, 'itemId'>,
  ): Promise<IProduct | void> => {
    setLoading(true)
    const response = await handleConfirm({ itemId: id, ...data })
    setLoading(false)

    if (response) {
      setIsConfirm(response.status !== 1)
    }
    return response
  }

  const handleBranchConfirmProduct = useCallback(
    async (weighings?: number[]) => {
      handleDialogBreach({
        id,
        name,
        expectedAmount: quantityToSeparate,
        type: isUnity ? 'Un' : 'Kg',
        weighings,
        step,
      })
    },
    [id],
  )

  const separationGroup = useSelector<IState, ISeparationGroupConfig>(state => {
    const currentSeparationGroupId = Number(state.infos.runningMachine)
    const currentSeparationGroup = state.settings.separationGroups.find(
      separationGroup => currentSeparationGroupId === Number(separationGroup.separation_group),
    )
    return currentSeparationGroup as ISeparationGroupConfig
  })

  const isScannerDisableInStep = separationGroup?.steps_without_scanner?.includes(Number(step))

  useEffect(() => {
    setIsConfirm(product.status === 2 || product.status === 3)
  }, [product.status])

  return (
    <animated.div
      style={style}
      className={`${classes.container} ${isConfirm ? classes.confirmed : classes.default}`}
    >
      {(grouped && quantity === 0) || isConfirm || !isScanner || isScannerDisableInStep ? (
        <CardProduct
          product={product}
          view="fastTrack"
          isConfirm={isConfirm}
          loading={loading}
          handleBranchConfirmProduct={handleBranchConfirmProduct}
          handleConfirmProduct={handleConfirmProduct}
        />
      ) : (
        <CardProductScanner
          handleBranch={handleBranchConfirmProduct}
          handleConfirm={handleConfirmProduct}
          loading={loading}
          product={product}
          orderId={orderId}
          resetOrder={resetOrder}
        />
      )}
    </animated.div>
  )
}
