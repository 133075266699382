import { Button, Theme, makeStyles, styled } from '@material-ui/core'

export const useStyles = makeStyles({
  iconButton: {},
  icon: {
    color: '#89BD23',
  },
  iconWithAnimation: {
    color: '#89BD23',
    animation: '$breathing 8s  linear infinite',
  },
  '@keyframes breathing': {
    '0%': { width: '150%' },
    '50%': { width: '80%' },
    '100%': { width: '150%' },
  },
  iconOff: {
    color: '#A2A2A2',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    '&:not(:last-child)': {
      borderBottom: '1px solid #C4C4C4',
    },
  },
  boxNotification: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    marginLeft: '1rem',
  },
  textInfo: {
    fontSize: '1rem',
    fontWeight: 500,
    fontFamily: 'Roboto',
    color: '#757575',
    '& strong': {
      color: '#000000',
    },
  },
  bsTextInfo: {
    fontSize: '1rem',
    fontWeight: 500,
    fontFamily: 'Roboto',
    color: '#757575',
    '& strong': {
      color: '#000000',
    },
    '&:before': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      width: '10px',
      height: '10px',
      background: '#EB001B',
      borderRadius: '50%',
      left: '10px',
      top: '35px',
    },
  },
  buttonClearNotification: {
    color: '#618619',
    background: 'none',
    width: '100%',
  },
  title: {
    fontSize: '1.2rem',
    color: '#757575',
    fontWeight: 500,
    marginBottom: '1rem',
  },
  scroll: {
    maxHeight: '300px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A1CA4F',
      borderRadius: '9px',
    },
  },
  menuPopover: {
    '& > .MuiPopover-paper': {
      padding: '1rem',
      marginTop: '1rem',
    },
  },
  animation: {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '55px',
      height: '55px',
      borderRadius: '50%',
      border: '.5px solid #EB001B',
      transform: 'scale(1)',
      animation: '$pulse 2s infinite',
    },
  },
  '@keyframes pulse': {
    from: { transform: 'scale(1)', opacity: 1 },
    to: { transform: 'scale(2)', opacity: 0 },
  },
})

export const ButtonNotification = styled(Button)(
  ({ theme, numberNotification }: { theme: Theme; numberNotification: number }) => ({
    width: '55px',
    height: '55px',
    aspectRatio: '1/1',
    background: '#F4F4F4',
    borderRadius: '50%',
    minWidth: '0 !important',
    position: 'relative',
    border: numberNotification > 0 ? '1px solid #EB001B' : 'none',
    '&::before': {
      content: `'${numberNotification}'`,
      position: 'absolute',
      top: 0,
      right: 0,
      background: '#EB001B',
      color: '#FFFFFF',
      borderRadius: '50%',
      width: '20px',
      height: '20px',
      lineHeight: '20px',
    },
  }),
)
