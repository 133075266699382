import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles({
  root: {
    minWidth: '15rem',
    height: '7.125rem',
    boxShadow:
      '0px 2px 4px rgba(196, 196, 196, 0.14), 0px 4px 5px rgba(196, 196, 196, 0.12), 0px 1px 10px rgba(196, 196, 196, 0.2)',
    border: '1px solid #C4C4C4',
    borderRadius: '0.5rem',
    background: '#FFFFFF',
    padding: '1rem',
    cursor: 'pointer',
  },
  divisionBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  boxImg: {
    width: '1.875rem',
    height: '1.875rem',
    display: 'flex',
    background: '#C4C4C4',
    borderRadius: '6rem',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '85%',
    },
  },
  track: {
    fontWeight: 500,
    fontSize: '1rem',
    color: '#353535',
    marginLeft: '0.5rem',
  },
  active: {
    border: '1px solid #618619',
    background: '#ffffff',
    '& span': {
      background: '#89BD23',
    },
  },
  disabled: {
    filter: 'grayscale(80%)',
  },
  ordersTitle: {
    color: '#929292',
    fontSize: '0.75rem',
  },
  orders: {
    color: '#353535',
    fontSize: '0.8rem',
    lineHeight: 1,
    fontWeight: 500,
  },
  circle: {
    height: '4.125rem',
    width: '4.125rem',
    borderRadius: '50%',
    border: '2px solid #757575',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  circleActive: {
    border: '3px solid #618619',
  },
  weight: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '1.1rem',
    color: '#757575',
    fontSize: '0.9rem',
    fontWeight: 500,
    '& strong': {
      fontWeight: 300,
      color: '#353535',
    },
  },
  weightActive: {
    color: '#618619',
  },
})
