import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button as MuiButton,
  Dialog,
  Grid,
  Paper,
  RadioGroup,
  Typography,
  FormControl,
  FormLabel,
} from '@material-ui/core'
import { Button } from 'components'
import { IHandleConfirmItem } from 'hooks/useOrderSeparation/useItem'
import { usePopup } from 'hooks/usePopup'
import { IStepStep } from 'interfaces/IFastTrack'
import { IProduct } from 'interfaces/IProduct'
import { enStatusItem } from 'interfaces/ISeparationItem'
import { ForwardRefRenderFunction, forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Input, RadioButton } from 'shared'
import { IState } from 'store'
import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'

import { useStyles } from './styles'

type retuntValidationProps = {
  typeBreak: number | null
  type: 'Kg' | 'Un' | null
  expectedAmount: number
}

const returnValidationObject = (props: retuntValidationProps) => {
  const { expectedAmount, type, typeBreak } = props

  if (typeBreak === 3) return Yup.object().shape({})

  if (type === 'Kg') {
    return Yup.object().shape({
      quantity: Yup.number()
        .test(
          'quantity',
          'A quantidade precisa ser inferior a quantidade esperada',
          (value: number | undefined) => Number(value) < expectedAmount,
        )
        .typeError('Campo obrigatório')
        .positive('A quantidade precisa ser positiva')
        .required('Campo obrigatório'),
    })
  }

  return Yup.object().shape({
    quantity: Yup.number()
      .test(
        'quantity',
        'A quantidade precisa ser inferior a quantidade esperada',
        (value: number | undefined) => Number(value) < expectedAmount,
      )
      .typeError('Campo obrigatório')
      .positive('A quantidade precisa ser positiva')
      .integer('A quantidade precisa ser um número inteiro')
      .required('Campo obrigatório'),
  })
}

interface IDialogBreakProps {
  onClose: () => void
  confirm: (form: { statusId: number; quantitySeparated?: number }) => Promise<void>
  name: string | null
  expectedAmount: number
  type: 'Un' | 'Kg' | null
  weighings?: number[]
  id: number
}

const Content = (props: IDialogBreakProps) => {
  const { onClose, confirm, name, expectedAmount, type, id, weighings } = props
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()
  const [typeBreak, setTypeBreak] = useState<enStatusItem | null>(null)

  const validateSchema = useMemo<Yup.ObjectSchema<ObjectShape>>(() => {
    return returnValidationObject({ expectedAmount, type, typeBreak })
  }, [type, expectedAmount, typeBreak])

  const { register, handleSubmit, formState, reset, setValue } = useForm({
    resolver: yupResolver(validateSchema),
  })

  const { errors } = formState

  const sumWeighings = weighings?.reduce((acc, curr) => acc + curr, 0)

  const isScanner = useSelector<IState, boolean>(state => state.toggleScanner)

  const abledPartialBreak = !isScanner || (isScanner && sumWeighings)
  const abledTotalBreak = !isScanner || (isScanner && !sumWeighings)
  if (isScanner) {
    setValue('quantity', type === 'Kg' ? sumWeighings?.toFixed(3) : sumWeighings)
  }

  const handleClear = () => {
    setTypeBreak(null)
    reset({ quantity: null })
    onClose()
  }
  const handleConfirmDialog = async (data: { quantity: number }) => {
    const quantitySeparated =
      typeBreak === enStatusItem.totalBreak || isScanner ? undefined : data.quantity

    setIsLoading(true)

    await confirm({ quantitySeparated, statusId: typeBreak as enStatusItem })
    setIsLoading(false)
    handleClear()
  }

  return (
    <Paper className={classes.paper}>
      <Grid>
        <Grid item xs={12}>
          <Typography className={classes.title}>Confirmação</Typography>
        </Grid>

        <Grid item xs={12} className={classes.box}>
          <Typography>
            <strong>Nome do produto:</strong> {name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={handleSubmit(handleConfirmDialog)} noValidate>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <FormLabel component="legend">Escolha um tipo</FormLabel>
              <RadioGroup
                row
                defaultValue={3}
                value={typeBreak}
                className={classes.boxRadio}
                placeholder="Escolha um tipo"
              >
                {abledTotalBreak && (
                  <MuiButton
                    variant="outlined"
                    style={{
                      color: '#353535',
                      width: !abledPartialBreak ? '100%' : '',
                    }}
                    className={
                      typeBreak === enStatusItem.totalBreak
                        ? classes.buttonCheck
                        : classes.buttonRadio
                    }
                    onClick={() => setTypeBreak(enStatusItem.totalBreak)}
                  >
                    <RadioButton value={3} label="Quebra total" />
                  </MuiButton>
                )}
                {abledPartialBreak && (
                  <MuiButton
                    variant="outlined"
                    style={{
                      color: '#353535',
                      width: !abledTotalBreak ? '100%' : '',
                    }}
                    className={
                      typeBreak === enStatusItem.partialBreak
                        ? classes.buttonCheck
                        : classes.buttonRadio
                    }
                    onClick={() => setTypeBreak(enStatusItem.partialBreak)}
                  >
                    <RadioButton value={4} label="Quebra parcial" />
                  </MuiButton>
                )}
              </RadioGroup>
            </FormControl>
            {typeBreak === 4 && (
              <Input
                variant="outlined"
                error={!!errors?.quantity}
                helperText={errors?.quantity?.message}
                label="Quantidade em estoque*"
                {...register('quantity')}
                type="number"
                autoComplete="off"
                disabled={isScanner}
              />
            )}
            <Grid container item xs={12} className={classes.boxBtn}>
              <Button isDelete variant="contained" onClick={handleClear}>
                Cancelar
              </Button>

              <Button type="submit" variant="contained" disabled={!typeBreak || isLoading}>
                Confirmar
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  )
}

export interface IDataBreach {
  id: null | number
  name: null | string
  quantity?: number
  expectedAmount: number
  type: 'Un' | 'Kg' | null
  step: IStepStep
  weighings?: number[]
}

interface IProps {
  handleConfirmItem: (data: IHandleConfirmItem) => Promise<void | IProduct>
}

export interface IDialogBreak {
  onOpen: (x: IDataBreach) => void
  onClose: () => void
}

const defaultData = {
  id: null,
  isOpen: false,
  name: null,
  quantity: 0,
  expectedAmount: 0,
  type: null,
  step: 0,
}

const Component: ForwardRefRenderFunction<IDialogBreak, IProps> = ({ handleConfirmItem }, ref) => {
  const classes = useStyles()
  const { addPopup } = usePopup()
  const [open, setOpen] = useState(false)
  const [data, setData] = useState<IDataBreach>(defaultData)

  const brenchConfirm = async (itemData: {
    statusId: enStatusItem
    quantitySeparated?: number
  }): Promise<void> => {
    try {
      await handleConfirmItem({
        ...itemData,
        itemId: data.id as number,
        step: data.step,
      })
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao confirmar quebra',
        description: err?.message ?? '',
      })
    }
  }

  const onOpen = (newData: IDataBreach) => {
    setData(newData)
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
    setData(defaultData)
  }

  useImperativeHandle(ref, () => ({ onOpen, onClose }))

  return (
    <Dialog open={open} className={classes.root}>
      <Content onClose={onClose} confirm={brenchConfirm} {...data} id={data.id as number} />
    </Dialog>
  )
}

export const DialogBreak = forwardRef(Component)
