import { IPaginationParams, IPaginationResponse } from '../interfaces/IPagination'
import { api as apiService, ApiService } from './api'
import { OrderDetails, OrderDetailsResponse } from 'interfaces/IOrderDetails'

class InvoiceService {
  constructor(private readonly api: ApiService) {}

  async getOrdersWithoutInvoice(
    params: IPaginationParams,
    signal: AbortSignal,
  ): Promise<IPaginationResponse<OrderDetailsResponse>> {
    return this.api
      .get(carambolaUrl + '/loading-conference/order-delivery-details', { params, signal })
      .then(response => ({ ...response, totalCount: response.total_count }))
  }

  async patchReleaseWithoutInvoice(
    data: OrderDetails,
    orderId: string,
  ): Promise<OrderDetailsResponse> {
    return await this.api.patch(
      carambolaUrl + `/loading-conference/order-delivery-details/${orderId}`,
      data,
    )
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const invoiceService = new InvoiceService(apiService)
