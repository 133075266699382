import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles({
  root: {
    fontFamily: '"Inter", sans-serif',
    color: '#757575',
    fontSize: '1.3rem',
    fontWeight: 500,
  },
})
