import { Box, LinearProgress, Typography } from '@material-ui/core'
import { usePopup } from 'hooks/usePopup'
import {
  FormEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import VirtualKeyboard from '../VirtualKeyboard'
import {
  ButtonBack,
  ButtonConfirm,
  ContainerAuthorization,
  ContainerInfoOrder,
  ContainerTitle,
  InfoOrder,
  InputBoxes,
  ModalInputBoxNumber,
  SubTitle,
} from './styles'
import { settingsService } from 'services/settingsService'
import { ImQrcode } from 'react-icons/im'
import { AiFillCloseCircle } from 'react-icons/ai'
import { Socket } from 'socket.io-client'
import { enEvent } from 'interfaces/enEvent'

export interface IModalError {
  handleChangeOrderId: (data: HandleChangeOrderId) => void
  isOpen: boolean
}

interface IFormdata {
  orderId: string
  boxesNumber: number
}

interface IProps {
  onSubmit: (data: IFormdata) => Promise<unknown>
  socket: Socket
}

type HandleChangeOrderId = {
  orderId: string
  title: string
}

const initialState = { orderId: '', title: '' }

export const Component: ForwardRefRenderFunction<IModalError, IProps> = (props, ref) => {
  const { onSubmit } = props
  const input = useRef<HTMLInputElement>(null)
  const { addPopup, clearAllPop } = usePopup()
  const [data, setData] = useState(initialState)
  const isOpenRef = useRef<boolean>(false)
  const [isOpen, setIsOpen] = useState(false)
  const [inputBoxes, setInputBoxes] = useState({
    value: '',
    error: false,
  })
  const [isAwaitAuthorization, setIsAwaitAuthorization] = useState({
    isAwaitAuthorization: false,
    boxes: 0,
    title: '',
  })
  const isAwaitAuthorizationRef = useRef({
    isAwaitAuthorization: false,
    boxes: 0,
    orderId: '',
  })
  const [loadingAwaitAuthorization, setLoadingAwaitAuthorization] = useState(false)


  const handleClose = () => {
    setIsOpen(false)
    isOpenRef.current = false
    setData(initialState)
    setInputBoxes({
      value: '',
      error: false,
    })
    setLoadingAwaitAuthorization(false)
    isAwaitAuthorizationRef.current = { isAwaitAuthorization: false, boxes: 0, orderId: '' }
    setIsAwaitAuthorization({ isAwaitAuthorization: false, boxes: 0, title: '' })
  }

  const handleChangeOrderId = (newData: HandleChangeOrderId) => {
    setData(newData)
    setIsOpen(true)
    isOpenRef.current = true
  }

  const handleAwaitAuthorization = (boxes: number) => {
    isAwaitAuthorizationRef.current = {
      isAwaitAuthorization: true,
      boxes,
      orderId: data.orderId,
    }
    setIsAwaitAuthorization({
      isAwaitAuthorization: true,
      boxes,
      title: `Conferente informou ${boxes} caixas`,
    })
  }

  const handleBack = () => {
    setIsAwaitAuthorization({ isAwaitAuthorization: false, boxes: 0, title: '' })
    isAwaitAuthorizationRef.current = { isAwaitAuthorization: false, boxes: 0, orderId: "" }
  }

  const submit = async ({ boxesNumber, orderId }: { boxesNumber: number; orderId: string }) => {
    if (!boxesNumber) {
      addPopup({ type: 'error', title: 'Quantidade de caixa não pode ser 0' })
      return
    }

    clearAllPop()
    const dataForm = { boxesNumber, orderId }
    onSubmit(dataForm)
    await new Promise(res => setTimeout(res, 400))
    handleClose()
  }

  const onClickKeyboard = (value: string) => {
    if (value === 'delete') {
      setInputBoxes(state => ({ ...state, error: false, value: state.value.slice(0, -1) }))
      return
    }
    if (value === 'submit') {
      if (Number(inputBoxes.value) <= 0) {
        setInputBoxes(state => ({
          ...state,
          error: true,
        }))
        return
      }
      handleAwaitAuthorization(Number(inputBoxes.value))
      return
    }
    setInputBoxes(state => ({
      ...state,
      value: state.value + value,
      error: false,
    }))
  }

  const handleSubmitScanner = async (param: FormEvent) => {
    const formData = new FormData(param.target as HTMLFormElement)
    param.preventDefault()
    const inputValue = formData.get('inputValue')
    compareQR(inputValue as string)
    if (input.current) {
      input.current.value = ''
    }
  }

  const compareQR = async (value: string) => {
    try {
      setLoadingAwaitAuthorization(true)
      const { token } = await settingsService.getSettings()
      if (token === value) {
        submit({ boxesNumber: isAwaitAuthorizationRef.current.boxes, orderId: isAwaitAuthorizationRef.current.orderId })
        addPopup({
          type: 'success',
          title: `Conferência de ${isAwaitAuthorizationRef.current.boxes} confirmada com sucesso!`,
        })
      } else {
        addPopup({
          type: 'error',
          title: 'QRcode diferente do ADMIN',
          description: `QRcode #${value} não corresponde ao QRcode do ADMIN`,
        })
      }
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao buscar QRcode da tabela CONFIG',
        description: err?.message || err?.msg,
      })
    } finally {
      setLoadingAwaitAuthorization(false)
    }
  }

  useImperativeHandle(ref, () => ({ handleChangeOrderId, isOpen }))

  useEffect(() => {
    props.socket.on(enEvent.READ_TAG, ({ tag_value }: { tag_value: string; image_base64: string }) => {
      if (isAwaitAuthorizationRef.current.isAwaitAuthorization) {
        compareQR(tag_value)
      }
    })

    setInterval(() => {
      if (
        isOpenRef.current &&
        input?.current &&
        isAwaitAuthorizationRef?.current?.isAwaitAuthorization
      ) {
        input.current?.focus()
      }
    }, 500)
  }, [])

  return (
    <ModalInputBoxNumber
      open={isOpen}
      title={isAwaitAuthorization.isAwaitAuthorization ? isAwaitAuthorization.title : data.title}
    >
      {isAwaitAuthorization.isAwaitAuthorization && (
        <ContainerAuthorization>
          <LinearProgress style={{ width: '100%', opacity: loadingAwaitAuthorization ? 1 : 0 }} />
          <form
            onSubmit={e => {
              handleSubmitScanner(e)
            }}
            style={{ position: 'absolute', top: 0, right: 0 }}
          >
            <InputBoxes
              style={{ position: 'absolute', opacity: 0 }}
              hidden
              inputRef={input}
              name="inputValue"
              type="text"
            />
          </form>
          <Typography style={{ fontSize: '1.2rem', fontWeight: 400, color: '#757575' }}>
            PASSE O QRCODE DO ADMIN NO LEITOR PARA CONFIRMAR
          </Typography>
          <ImQrcode size={100} color="#757575" style={{ margin: '2rem auto 0 auto' }} />
          <ButtonBack onClick={() => handleBack()}>Voltar e alterar</ButtonBack>
        </ContainerAuthorization>
      )}

      {!isAwaitAuthorization.isAwaitAuthorization && (
        <ContainerInfoOrder>
          <InfoOrder>
            <AiFillCloseCircle color="#EB001B" size={80} />
            <ContainerTitle>
              <Typography>
                {isAwaitAuthorization.isAwaitAuthorization ? isAwaitAuthorization.title : data.title}
              </Typography>
            </ContainerTitle>

            <Box style={{ width: '100%', padding: '1rem 0' }}>
              <SubTitle>
                Pedido: <strong>#{data.orderId}</strong>
              </SubTitle>
            </Box>

            <Box width="100%">
              <InputBoxes
                type="text"
                placeholder="Quantidade de caixas"
                label="Digite a quantidade total de caixas do pedido"
                variant="outlined"
                error={inputBoxes.error}
                value={inputBoxes.value}
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Box>
            <ButtonConfirm onClick={() => { onClickKeyboard('submit') }}>Confirmar</ButtonConfirm>
          </InfoOrder>
          <Box style={{ paddingLeft: '1rem', height: '100%' }}>
            <VirtualKeyboard onClick={onClickKeyboard} />
          </Box>
        </ContainerInfoOrder>
      )}
    </ModalInputBoxNumber>
  )
}

export const ModalError = forwardRef(Component)
