import { Typography, styled, Button as ButtonMui } from '@material-ui/core'

export const SubTitle = styled(Typography)({
  fontSize: '1.25rem',
  color: '#757575',
  width: '100%',
})

export const Image = styled('img')({
  width: '100%',
  maxWidth: '250px',
  margin: '3rem auto 3rem auto',
})

export const Button = styled(ButtonMui)(({ theme }) => ({
  background: '#89BD23',
  color: '#fff',
  fontWeight: 500,
  borderRadius: '16px',
  '&:hover': {
    background: '#89BD23',
  },
}))
