import { Box, Typography } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Button as MuiButton } from 'components'
import { usePopup } from 'hooks/usePopup'
import { ILoadingConference } from 'interfaces/ILoadingConference'
import { IProduct } from 'interfaces/IProduct'
import { useState, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef } from 'react'
import { itemServices } from 'services/itemServices'
import { Modal } from 'shared'

import { IShowItems, ShowItems } from './ShowItems'
import { useStyles } from './styles'

const Button = styled(MuiButton)({
  width: '9.375rem',
  padding: '0.6rem',
})

const mapErrors = (message: string) => {
  const errorMessage =
    {
      'Please inform an order id': 'Informe o Id do pedido',
    }[message] || 'Erro ao receber dados dos itens'

  return errorMessage
}

export interface IModalConfirm {
  toggleOpenModal: () => void
  setData: (x: ILoadingConference) => void
}

interface Props {
  confirm: (x: ILoadingConference) => Promise<boolean>
  cancelConfirm: () => void
}

const Component: ForwardRefRenderFunction<IModalConfirm, Props> = (props, ref) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isShowLoading, setShowLoading] = useState(false)
  const [data, setData] = useState<ILoadingConference>({} as ILoadingConference)
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  const refShowItems = useRef<IShowItems>(null)
  const { addPopup } = usePopup()

  const toggleOpenModal = () => setIsOpen(state => !state)

  const handleGetItems = async (id: string) => {
    try {
      const response = await itemServices.getItems(id)
      refShowItems.current?.setData(response)
      refShowItems.current?.toggleOpenModal()
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: mapErrors(error?.message),
      })
    }
  }

  const handleOpenModal = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    toggleOpenModal()
  }

  const handleConfirm = async () => {
    setIsLoading(true)
    const res = await props.confirm(data)
    if (res) {
      toggleOpenModal()
      setIsLoading(false)
      return
    }
    handleClose()
    setIsLoading(false)
  }

  useImperativeHandle(ref, () => ({
    toggleOpenModal: handleOpenModal,
    setData,
  }))

  return (
    <>
      <Modal open={isOpen} handleClose={handleClose} title="Quantidade de caixas divergente">
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Typography>#{data?.order_id}</Typography>
          <Typography style={{ fontWeight: 600 }}>{data?.client_name_or_trade}</Typography>
        </Box>

        <Box mt="3rem">
          <Typography>
            Verificamos no sistema e parece que a quantidade de caixas não é a mesma informada na{' '}
            <strong>Conferência de Pedidos</strong>.
          </Typography>
          <br />
          <Typography>
            Quantidade confirmada na conferência de pedidos:
            <strong> {data?.conference_boxes_number} caixas</strong>
          </Typography>
          <Typography>
            Você confirmou:
            <strong> {data?.loading_boxes_number} caixas</strong>
          </Typography>
          <br />
          <Typography>
            Por favor verifique a quantidade novamente... Caso esteja correto, apenas aperte em{' '}
            <strong>“Confirmar”</strong>.
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" style={{ gap: '1rem' }} mt="3rem">
          <Button
            variant="outlined"
            isLoading={isLoading}
            onClick={() => handleGetItems(data?.order_id)}
            className={classes.buttonSeeItens}
          >
            Ver itens
          </Button>

          <Button
            variant="contained"
            color="secondary"
            isLoading={isShowLoading}
            onClick={handleConfirm}
          >
            Confirmar
          </Button>
        </Box>
      </Modal>

      <ShowItems ref={refShowItems} id={data?.order_id} name={data?.client_name_or_trade} />
    </>
  )
}

export const ModalConfirm = forwardRef(Component)
