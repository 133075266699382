import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  boxAlign: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  titlePage: {
    color: '#353535',
    fontSize: '1.6rem',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '1.7rem',
    letterSpacing: '0.01em',
  },
  helpDescription: {
    color: '#353535',
    fontSize: '1.2rem',
    fontFamily: 'Inter',
    lineHeight: '1.9rem',
    letterSpacing: '0.01em',
    marginTop: '1rem',
  },
  help: {
    color: '#353535',
    fontSize: '0.9rem',
    fontFamily: 'Inter',
    lineHeight: '1.9rem',
    letterSpacing: '0.01em',
  },
  tableContainer: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  table: { maxWidth: '500px' },
  loading: {
    width: '100%',
    marginTop: '1rem',
  },
})
