import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  container: {
    paddingRight: '1rem',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '1rem',
    fontWeight: 'lighter',
  },
  box: {
    gap: '1rem',
    background: '#eef0ee',
    padding: '1rem',
    width: '100%',
    borderRadius: '1rem',
    maxWidth: '400px',
    '-webkit-box-shadow': '10px 10px 20px -20px rgba(0,0,0,0.2)',
    '-moz-box-shadow': '10px 10px 57px -20px rgba(0,0,0,0.2)',
    boxShadow: '10px 10px 57px -20px rgba(0,0,0,0.2)',
    [theme.breakpoints.between(0, 1050)]: {
      minWidth: '220px',
    },
    '& > img': {
      width: '100%',
      borderRadius: '0.875rem',
      cursor: 'zoom-in',
    },
  },
  boxTitle: {
    color: '#353535',
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  boxSubtitle: {
    color: '#353535',
    fontWeight: 400,
    fontSize: '0.75rem',
  },
  boxError: {
    width: '80%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
  },
}))
