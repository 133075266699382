import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { ReactNode, useEffect, useState } from 'react'
import { IoIosClose } from 'react-icons/io'
import { animated } from 'react-spring'

import { IPopupMessage, usePopup } from '../../../hooks/usePopup'
import { useStyles } from './styles'

type IPopupProps = {
  message: IPopupMessage
  style: object
}

type IPopupData = {
  success: any
  error: any
  info: any
}

const icons: IPopupData = {
  info: (
    <InfoOutlinedIcon fontSize="medium" style={{ margin: '4px 12px 0 0' }} />
  ),
  error: (
    <InfoOutlinedIcon fontSize="medium" style={{ margin: '4px 12px 0 0' }} />
  ),
  success: (
    <CheckCircleOutlineRoundedIcon
      fontSize="medium"
      style={{ margin: '4px 12px 0 0' }}
    />
  ),
}

const variantColorsPopup: IPopupData = {
  info: {
    background: '#ebf8ff',
    color: '#3172b7',
  },
  success: {
    background: '#e6fffa',
    color: '#2e656a',
  },
  error: {
    background: '#fddede',
    color: '#c53030',
  },
}

const Description = ({ children }: { children: ReactNode }) => {
  if (children) {
    return typeof children === 'string' ? <p>{children}</p> : <>{children}</>
  }

  return null
}

export const Popup = ({ message, style }: IPopupProps) => {
  const { removePopup } = usePopup()
  const classes = useStyles()
  const [color, setColor] = useState({
    background: '#ebf8ff',
    color: '#3172b7',
  })

  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (message.autoClose) {
        removePopup(message.id)
      }
    }, 6000)

    const alignItem = !message.description
      ? {
          alignItems: 'center',
          '> svg': {
            marginTop: 0,
          },
        }
      : {}

    setColor({
      ...variantColorsPopup[message.type],
      ...alignItem,
    })

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <animated.div
      className={classes.root}
      style={{ ...color, ...style }}
      data-cy="popup"
    >
      {icons[message.type || 'info']}

      <div className={classes.box}>
        <strong className={classes.strong}>{message.title}</strong>
        <Description>{message.description}</Description>
      </div>

      <button
        onClick={() => removePopup(message.id)}
        className={classes.button}
        type="button"
      >
        <IoIosClose size="1.8rem" />
      </button>
      {message?.autoClose && (
        <span
          className={classes.span}
          style={{ background: color.color }}
        ></span>
      )}
    </animated.div>
  )
}
