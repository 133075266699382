import { Box } from '@material-ui/core'
import { BsArrowRightCircleFill } from 'react-icons/bs'
import qrCodeImg from 'assets/images/qr-code.svg'
import readerImg from 'assets/images/reader.svg'
import { SubTitle, Title, Container, BoxImg, Link, Dialog } from './styles'

interface Props {
  open: boolean
  onClose: () => void
}

const ModalScanner = ({ open, onClose, ...rest }: Props) => {
  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <Container>
        <Box component="header">
          <Title>Pronto para iniciar a conferência</Title>
          <SubTitle>Aponte o leitor para o QR code</SubTitle>
        </Box>

        <BoxImg>
          <img src={readerImg} />
          <BsArrowRightCircleFill color="#757575" size={32} />
          <img src={qrCodeImg} />
        </BoxImg>
        <Link to="../">Cancelar</Link>
      </Container>
    </Dialog>
  )
}
export default ModalScanner
