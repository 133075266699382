import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@material-ui/core'
import { IOrderConference } from '../index'
import { HiPencil } from 'react-icons/hi'

interface Props {
  results: IOrderConference[]
  lastOrder: string
  setOpenModalEditData: (param: {
    open: boolean
    order: IOrderConference
  }) => void
}

const TableScanneds = ({ results, lastOrder, setOpenModalEditData }: Props) => {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Pedido</TableCell>
            <TableCell>Cliente</TableCell>
            <TableCell style={{ textAlign: 'center' }}>Rota</TableCell>
            <TableCell style={{ textAlign: 'center' }}>Caixas lidas</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map(order => {
            if (order.orderId === lastOrder) return null
            return (
              <TableRow key={order.orderId}>
                <TableCell>#{order.orderId}</TableCell>
                <TableCell>{order.clientName}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {order.route}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {order.boxes} de {order.totalBoxes}
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      setOpenModalEditData({
                        open: true,
                        order: order,
                      })
                    }}
                  >
                    <HiPencil
                      style={{
                        color: '#757575',
                        fontSize: '20px',
                      }}
                    />
                  </Button>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}

export default TableScanneds
