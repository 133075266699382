import { makeStyles } from '@material-ui/styles'

export const useStylesContainer = makeStyles({
  root: {
    margin: '0 auto 10px',
    width: '100%',
    maxWidth: '750px',
    padding: '0 2%',
  },
  info: {
    color: '#757575',
    fontWeight: 400,
    fontSize: '1.5rem',
  },
  strong: {
    fontWeight: 500,
    color: '#353535',
    textTransform: 'capitalize',
  },
  back: {
    display: 'flex',
    width: '5rem',
    alignItems: 'center',
    gap: '5px',
    marginTop: '1rem',
    marginLeft: '1.5rem',
    color: '#757575',
    fontWeight: 600,
    textDecoration: 'none',
    fontSize: '1rem',
  },
})

export const useStylesComponents = makeStyles({
  header: {
    position: 'sticky',
    zIndex: 3,
    marginTop: '-2rem',
    top: '6.2rem',
    left: '0',
  },
  contentHeader: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '1200px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 2.3rem 1.2rem',
  },
  title: {
    color: '#757575',
    fontSize: '1rem',
    fontWeight: 400,
    lineWeight: '1.18rem',
    '& strong': {
      color: '#353535',
      fontSize: '1.2rem',
    },
  },
  subTitle: {
    color: '#757575',
    fontSize: '1rem',
    '& strong': {
      color: '#353535',
      fontSize: '1.3rem',
    },
  },
  client: {
    fontSize: '1rem',
    color: '#757575',
    display: 'flex',
    '& strong': {
      marginLeft: '5px',
      color: '#353535',
      fontSize: '1.2rem',
    },
  },
  observation: {
    fontSize: '1.12rem',
    color: '#757575',
    '& strong': {
      color: '#4E4E4E',
      fontSize: '1.2rem',
    },
  },
  separationTime: {
    fontSize: '0.875rem',
    color: '#757575',
  },
  time: {
    fontSize: '1rem',
    color: '#353535',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  container: {
    paddingLeft: 'initial',
    paddingRight: 'initial',
    paddingTop: '1rem',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1.4rem',
  },
  clientCritical: {
    margin: '0.5rem auto 1rem',
  },
  separated: {
    fontSize: '1rem',
    fontHeigth: '1.125rem',
    marginBottom: '1rem',
    fontWeight: 600,
  },
  separatedOrder: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  storeContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.12rem',
    alignItems: 'center',
    '& .textStatus': {
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      color: '#757575',
      fontSize: '1.12rem',
    },
  },
  timer: {
    fontWeight: 600,
    color: '#757575',
    fontSize: '1.12rem',
  },
  timerTitle: {
    fontWeight: 500,
    color: '#757575',
    fontSize: '1.12rem',
    textAlign: 'center',
  },
  store: {
    color: '#353535',
    fontSize: '1.8rem',
    fontWeight: 500,
    '& strong': {
      fontWeight: 700,
      color: ({ store }: { store: string }) => {
        if (store?.includes('iFood')) return '#E9202E'
        if (store?.includes('PF')) return '#2B6AA4'
        return '#89BD23'
      },
    },
  },
  requestBtn: {
    bottom: '7.5rem',
    right: '2.5rem',
    position: 'fixed',
    background: '#FAFAFA',
    boxShadow:
      '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2)',
  },
  printerBtn: {
    bottom: '2.5rem',
    right: '2.5rem',
    position: 'fixed',
    background: '#FAFAFA',
    boxShadow:
      '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2)',
  },
})
