import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  container: {
    background: '#ffffff',
    padding: '0.5rem 2.5rem 2.5rem',
  },
  root: {
    marginTop: '2rem',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  subtitle: {
    color: '#353535',
    fontSize: '1.5rem',
    fontWeight: 600,
    marginBottom: '2rem',
  },
  separatorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#414141',
    fontSize: '1.125rem',
    '& strong': {
      fontWeight: 500,
    },
  },
})
