import { api, ApiService } from './api'

export interface ICheckBoxesParams {
  orderId?: string
}

export interface ICheckBoxes {
  id: number
  created: string
  order_id: string
  scheduled_date: Date | string
  has_been_reviewed: boolean
  amount_of_boxes: number
  checked_by: {
    id: number
    username: string
  }
}

export interface ICheckBoxesData {
  orderId: string
  items: {
    itemId?: number
    separated?: boolean
  }[]
}

class CheckBoxesService {
  constructor(private readonly api: ApiService) {}

  async getAll(params: ICheckBoxesParams): Promise<ICheckBoxes> {
    return this.api.get(carambolaUrl + '/order-conference/order', { params })
  }

  async create(orderId: string): Promise<ICheckBoxes> {
    return this.api.post(carambolaUrl + '/order-conference/start-conference', { orderId })
  }

  async finish(data: ICheckBoxesData): Promise<ICheckBoxes> {
    return this.api.post(carambolaUrl + '/order-conference/finish-conference', data)
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const checkBoxesService = new CheckBoxesService(api)
