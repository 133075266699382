import { Box, LinearProgress, MenuItem, Typography } from '@material-ui/core'
import { usePopup } from 'hooks/usePopup'
import { ISeparatorResponse } from 'interfaces/ISeparator'
import { useEffect, useState } from 'react'
import { AiFillClockCircle } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { separatorService } from 'services/separatorService'
import { Select } from 'shared'
import { FormControl } from 'shared/Select/FormControl'
import { updateNameUser } from 'store/reducers/infos/actions'

import { useStyles } from './styles'
import ReactApexChart from 'react-apexcharts'
import { v4 } from 'uuid'
import { ApexOptions } from 'apexcharts'

type IFilter = 'today' | 'week' | 'month' | 'always'

const Charts = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { addPopup } = usePopup()
  const [series, setSeries] = useState<ApexOptions['series']>([])
  const [filter, setFilter] = useState<IFilter>('today')
  const [dataPerformance, setDataPerformance] = useState<ISeparatorResponse>(
    {} as ISeparatorResponse,
  )
  const [loading, setLoading] = useState(false)

  const formatHours = (value: string[]) => {
    if (value.length === 1) {
      return '00:00:00'
    }

    return `${value[0].padStart(2, '0')}:${value[1].padStart(2, '0')}:${String(
      Math.floor(Number(value[2])),
    ).padStart(2, '0')}`
  }

  const dataFormatter = (data: ISeparatorResponse) => {
    const separatorTotalTimeItem = data.separator.time_per_order?.split(':')
    const separatorTotalTimeOrder = data.separator.time_per_item?.split(':')
    const separatorTotalTime = data.separator.total_time
      ?.replace('day', 'dia')
      .replace('days', 'dias')
      .replace('months', 'meses')
      .replace('month', 'mês')
      .replace('year', 'ano')
      .replace('years', 'anos')
      .split(':')

    const averageTotalTimeItem = data.average.time_per_item?.split(':')
    const averageTotalTimeOrder = data.average.time_per_order?.split(':')
    const averageTotalTime = data.average.total_time
      ?.replace('day', 'dia')
      .replace('days', 'dias')
      .replace('months', 'meses')
      .replace('month', 'mês')
      .replace('year', 'ano')
      .replace('years', 'anos')
      .split(':')

    setDataPerformance({
      average: {
        ...data.average,
        time_per_item: formatHours(averageTotalTimeItem),
        time_per_order: formatHours(averageTotalTimeOrder),
        total_time: formatHours(averageTotalTime),
      },
      separator: {
        ...data.separator,
        time_per_item: formatHours(separatorTotalTimeItem),
        time_per_order: formatHours(separatorTotalTimeOrder),
        total_time: formatHours(separatorTotalTime),
      },
    })
  }

  const getDataPerformance = async () => {
    setLoading(true)
    try {
      const data = await separatorService.getPerformance({
        timeRange: filter,
      })

      dataFormatter(data)

      dispatch(updateNameUser(data?.separator?.separator))

      setSeries([
        {
          name: 'Você',
          data: [data?.separator?.kilos, data?.separator?.items, data?.separator?.orders],
        },
        {
          name: 'Outros separadores',
          data: [data?.average?.kilos, data?.average?.items, data?.average?.orders],
        },
      ])
    } catch (error) {
      addPopup({
        type: 'error',
        title: 'Erro ao procurar dados de desempenho',
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    getDataPerformance()
  }, [filter])

  const dataCard = [
    {
      text: 'Tempo de separação por item',
      voce: dataPerformance?.separator?.time_per_item,
      outros: dataPerformance?.average?.time_per_item,
    },
    {
      text: 'Tempo de separação por pedido',
      voce: dataPerformance?.separator?.time_per_order,
      outros: dataPerformance?.average?.time_per_order,
    },
    {
      text: 'Tempo total de separação',
      voce: dataPerformance?.separator?.total_time,
      outros: dataPerformance?.average?.total_time,
    },
  ]

  const options: ApexOptions = {
    colors: ['#89BD23', '#6ACFD8'],
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        dataLabels: {
          position: 'top',
        },
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#89BD23', '#6ACFD8'],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Quilos separados', 'Itens separados', 'Pedidos separados'],
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value, { dataPointIndex }) => {
          switch (dataPointIndex) {
            case 0:
              return `${value} Quilos separados`
            case 1:
              return `${value} Itens separados`
            case 2:
              return `${value} Pedidos separados`
            default:
              return value.toString()
          }
        },
      },
    },
  }

  return (
    <Box>
      <LinearProgress
        variant="indeterminate"
        style={{ width: '100%', marginBottom: '1rem', opacity: loading ? 1 : 0 }}
      />

      <Box className={classes.containerChart}>
        <FormControl>
          <Select
            value={filter}
            onChange={(e: any, value: any) => {
              setFilter(value.props.value as IFilter)
            }}
          >
            <MenuItem value="today">Hoje</MenuItem>
            <MenuItem value="week">Semana</MenuItem>
            <MenuItem value="month">Mês</MenuItem>
            <MenuItem value="always">Todos</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box className={classes.containerChartInfo}>
        <Box>
          <ReactApexChart options={options} series={series} type="bar" height={316} />
        </Box>
        <Box className={classes.containerInfo}>
          {dataCard.map(item => {
            const key = v4()
            return (
              <Box className={classes.card} key={key}>
                <header className={classes.cardHeader}>
                  <span className={classes.boxIcon}>
                    <AiFillClockCircle />
                  </span>
                  <Typography className={classes.title}>{item.text}</Typography>
                </header>

                <Box className={classes.containerTimes}>
                  <Box>
                    <Typography className={classes.subtitle}>Você</Typography>
                    <Typography className={classes.firtsValue}>{item.voce}</Typography>
                  </Box>

                  <Box>
                    <Typography className={classes.subtitle}> Outros separadores</Typography>
                    <Typography className={classes.firtsValue}>{item.outros}</Typography>
                  </Box>
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

export default Charts
