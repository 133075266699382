import { Theme } from '@material-ui/core'

import Base from './Base'
import Button from './Button'
import DatePicker from './DatePicker'
import Input from './Input'
import Paper from './Paper'
import Popover from './Popover'
import Select from './Select'
import Tooltip from './Tooltip'

const Overrides = (theme: Theme) =>
  Object.assign(
    Input(),
    Button(theme),
    Select(theme),
    Popover(theme),
    Paper(theme),
    Base(),
    DatePicker(),
    Tooltip(theme),
  )

export default Overrides
