import { Box, makeStyles, styled } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    maxWidth: '43.75rem',
    borderRadius: '1rem',
    border: '1px solid #A2A2A2',
    margin: '1rem auto',
  },
  content: {
    padding: '1.93rem 2.125rem 1rem',
    borderBottom: '1px solid #A2A2A2',
  },
  info: {
    display: 'flex',
    width: '100%',
    fontSize: '1.25rem',
    color: '#353535',
    '&:not(:last-child)': {
      marginBottom: '19px',
    },
    '& > strong': {
      flex: 1,
      marginRight: '1rem',
    },
    '& > span': {
      display: 'block',
      flex: 5,
    },
  },
  footer: {
    padding: '1rem 2.125rem',
  },
  boxes: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#353535',
  },
  infoScanneds: {
    textAlign: 'center',
    '& > span': {
      display: 'block',
    },
    '& > strong': {
      display: 'block',
    },
  },
})

export const BoxInfoScanneds = styled(Box)(
  ({ borderLeft }: { borderLeft?: boolean }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    background: '#F4F4F4',
    height: '122px',
    borderRadius: borderLeft ? '17px 0 0 17px' : '0 17px 17px 0',
  }),
)
