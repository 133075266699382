import { Box, LinearProgress, Typography, styled } from '@material-ui/core'

export const Text = styled(Typography)({
  background: '#FFF',
  color: '#353535',
  borderRadius: '8px',
  border: '1px solid #E0E0E0',
  padding: '8px',
})

export const ContainerDataPrePicking = styled(Box)({
  background: '#FAFAFA',
  borderRadius: '1rem',
  padding: '2rem 1rem',
  maxWidth: '800px',
  margin: '0 auto',
  position: 'relative',
  '& .MuiBox-root': {
    '&:nth-child(2)': {
      width: '100% !important',
    },
    '&:last-child': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      marginTop: '1rem',
    },
  },
})

export const Loading = styled(LinearProgress)({
  height: '12px',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
})
