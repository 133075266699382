import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  filter: {
    marginBottom: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
    width: '100%',
    '& > div': {
      width: '100%',
      minWidth: '120px',
      maxWidth: '180px',
    },
    '& > p': {
      color: '#757575',
    },
    '& > .MuiInputBase-root .MuiSelect-selectMenu:focus': { background: 'none' },
  },
  scrollChart: {
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      width: '4px',
      background: 'transparent',
      height: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A1CA4F',
      borderRadius: '9px',
    },
  },
  alertNoData: {
    padding: '1rem',
    backgroundColor: '#DFEAFF',
    color: '#2E75FF',
    textAlign: 'center',
    borderRadius: '8px',
    marginBottom: '0.5rem',
  },
}))
