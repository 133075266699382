import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    '&.MuiTableCell-root': {
      borderBottom: 'none',
      fontSize: '1rem',
      color: '#414141',
      fontWeight: 500,
    },
  },
})
