import {
  Accordion as AccordionMui,
  AccordionSummary as AccordionSummaryMui,
  AccordionDetails as AccordionDetailsMui,
  TableContainer as TableContainerMui,
  Box,
  Paper,
  styled,
  Button,
} from '@material-ui/core'

import { Search as SearchShared } from 'shared'

export const Search = styled(SearchShared)(({ theme }) => ({
  width: '21rem',
  [theme.breakpoints.between(0, 460)]: {
    marginLeft: 'auto',
    marginBottom: '10px',
  },
}))

export const CardContainerOrdersStyled = styled(Paper)(({ theme }) => ({
  padding: '1rem',
  position: 'relative',
}))

export const ContainerScroll = styled(Box)(({ theme }) => ({
  overflow: 'auto',
}))

export const ContainerAccordions = styled(Box)(({ theme }) => ({
  marginTop: '2rem',
  minWidth: '900px',
}))

export const ContainerLabels = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '60px',
  '& *': {},
  '& > span': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '180px',
    padding: '16px',
    color: '#757575',
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  '& span:first-child': {
    width: '10rem',
  },

  '& span:nth-child(4)': {
    justifyContent: 'center',
  },
}))

export const ContainerLabelsAccordion = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  '& > span': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '180px',
    padding: '16px',
    fontWeight: 400,
    color: '#353535',
    fontSize: '1rem',
  },
  '& span:first-child': {
    width: '10rem',
  },
  '& span:nth-child(4)': {
    justifyContent: 'left',
    marginLeft: 'auto',
  },
}))

export const Accordion = styled(AccordionMui)(({ theme }) => ({
  '&::before': {
    content: 'none',
  },
  '&:nth-child(odd)': {
    background: '#F9F9F9',
  },
}))

export const AccordionSummary = styled(AccordionSummaryMui)(({ theme }) => ({
  '& .MuiAccordionSummary-expandIcon.MuiButtonBase-root': {
    marginRight: 0,
    fontSize: '24px !important',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0 !important',
  },
}))

export const AccordionDetails = styled(AccordionDetailsMui)(({ theme }) => ({}))

export const ButtonReleaseOrder = styled(Button)(({ theme }) => ({
  background: '#89BD23',
  color: '#FFF',
  fontWeight: 400,
  padding: '8px 20px',
  borderRadius: '8px',
  fontSize: '15px',
}))

export const TableContainer = styled(TableContainerMui)(({ theme }) => ({
  background: '#ffffff',
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '10px',
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#A1CA4F',
    borderRadius: '9px',
  },
}))

export const ButtonReplenish = styled(Button)(({ theme }) => ({
  padding: '0.6rem 1.875rem',
  border: '1px solid #757575',
  borderRadius: '2px',
  color: '#353535',
}))
