import { Box, Button, MenuItem, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import { IAlertResponse, ISupport } from 'interfaces/IAlert'

interface INotificationHelpProps {
  notification: IAlertResponse
  handleAcceptSolicitation: (notification: IAlertResponse) => Promise<void>
}

const NotificationHelp = ({
  notification,
  handleAcceptSolicitation,
  ...rest
}: INotificationHelpProps) => {
  const classes = useStyles()
  return (
    <MenuItem {...rest} className={classes.root}>
      <Box>
        <Typography className={classes.textTypeNotification}>Solicitando ajuda</Typography>
        <Typography className={classes.textName}>
          {(notification.related_alert as ISupport).user_request}
        </Typography>
      </Box>
      <Button className={classes.button} onClick={() => handleAcceptSolicitation(notification)}>
        Aceitar chamado
      </Button>
    </MenuItem>
  )
}

export default NotificationHelp
