import { Box, makeStyles } from '@material-ui/core'

const Loading = () => {
  const useStyles = makeStyles({
    root: {
      height: '60px',
      width: '60px',
      border: '6px solid #e5e5e5',
      borderTop: '6px solid #89BD23',
      borderRadius: '50%',
      animation: '$rotate 1s ease-in infinite',
    },
    '@keyframes rotate': {
      to: {
        transform: 'rotate(1turn)',
      },
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2rem 0',
    },
  })

  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.root} />
    </Box>
  )
}

export default Loading
