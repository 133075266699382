import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '31rem',
    minHeight: '23rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '1rem',
  },
  modalTitle: {
    marginTop: '2rem',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: '150%',
    textAlign: 'center',
    color: '#353535',
  },
  boxItem: {
    marginLeft: '2rem',
    marginTop: '1.8rem',
    display: 'flex',
  },
  itemImage: {
    width: '3.3rem',
    height: '3.3rem',
  },
  boxItemInformation: {
    marginLeft: '1rem',
  },
  itemInformation: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.1rem',
    lineHeight: '150%',
    color: '#757575',
  },
  divider: {
    width: '90%',
    margin: '1.5rem 1.5rem',
    border: '0.5px solid #C4C4C4',
    opacity: 0.4,
  },
  checkBox: {
    marginLeft: '2.2rem',
  },
  boxInput: {
    marginLeft: '4.2rem',
    display: 'flex',
    alignItems: 'center',
  },
  inputText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '150%',
  },
  disabledInput: {
    color: '#C4C4C4',
    marginRight: '0.7rem',
  },
  notDisabledInput: {
    color: '#000000',
    marginRight: '0.7rem',
  },
  boxButtons: {
    marginTop: '2rem',
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'space-around',
  },
  cancelButton: {
    width: '13rem',
    height: '3rem',
    borderRadius: '50px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.1rem',
    lineHeight: '150%',
    color: '#757575',
    border: '1px solid #757575',
  },
  confirmButton: {
    width: '13rem',
    height: '3rem',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.1rem',
    lineHeight: '150%',
    color: '#FFFFFF',
    background: '#89BD23',
    borderRadius: '50px',
    '&:hover': {
      background: '#59800fe3',
    },
  },
})

export default useStyles
