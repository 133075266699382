import { Table as TableMui, TableProps } from '@material-ui/core'
import { memo } from 'react'

const TableComponent = (props: TableProps) => {
  return <TableMui {...props}>{props.children}</TableMui>
}

const Table = memo(TableComponent)

export default Table
