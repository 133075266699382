import { Box, Button, styled, Theme } from '@material-ui/core'

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  color: '#757575',
  fontWeight: 500,
}))

export const ContainerSearch = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '1rem',
  [theme.breakpoints.down(300)]: {
    flexDirection: 'column',
  },
  '& .MuiTextField-root': {
    width: '100%',
    height: '56px',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
}))

export const ButtonPrinterAll = styled(Button)(({ theme }) => ({
  height: '56px',
  color: '#FFF',
  backgroundColor: '#89BD23',
  fontSize: '0.9rem',
  borderRadius: '16px',
  fontWeight: 400,
  width: '180px',
  [theme.breakpoints.down(300)]: {
    width: '100%',
  },
  '& svg': {
    fontSize: '19.5px',
    marginRight: '5px',
  },
}))

export const ContainerFilter = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
  [theme.breakpoints.down(650)]: {
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  '& > .MuiBox-root:first-child': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    width: '100%',
    maxWidth: '400px',
    [theme.breakpoints.down(400)]: {
      gap: '0.5rem',
      justifyContent: 'space-evenly',
      width: '100%',
    },
  },
}))

export const ButtonFilter = styled(Button)(
  (theme: { colorIcon: string; theme: Theme; isSelected?: boolean }) => ({
    color: '#757575',
    border: theme.isSelected ? '1px solid #757575' : '',
    height: '2rem',
    borderRadius: 'calc(2rem /2)',
    width: '7rem',
    [theme.theme.breakpoints.down(350)]: {
      fontSize: '0.8rem',
      width: '100%',
      '& svg': {
        fontSize: '8px',
      },
    },
    '& svg': {
      marginRight: '0.5rem',
      fontSize: '10px',
      color: theme.colorIcon,
    },
  }),
)
