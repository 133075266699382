import { IconButton, MenuItem } from '@material-ui/core'
import { Button } from 'components/Button'
import { useAlertNotification } from 'hooks/useAlertNotification'
import { usePopup } from 'hooks/usePopup'
import { useRef, useState } from 'react'
import { MdOutlineHeadsetMic } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { alertService } from 'services/alertService'
import { MenuPopover } from 'shared'
import { IState } from 'store'

import { ModalInformation } from './ModalInformations/Index'
import { useStyles } from './styles'
import ButtonBellNotification from './ButtonBellNotification'
import { routeConfig } from 'interfaces/IUser'

export const SendHelp = () => {
  const { infos, user, orderFastTrack } = useSelector<IState, IState>(state => state)
  const [popover, setPopover] = useState(false)
  const [modal, setModal] = useState({
    open: false,
    name: '',
    track: 0,
    order: '',
  })
  const { painelRules } = routeConfig
  const { userPermissions } = user
  const { handleAcceptSolicitation } = useAlertNotification()
  const { addPopup } = usePopup()
  const userRef = useRef(null)
  const classes = useStyles()

  const handleCallHelp = async () => {
    try {
      await alertService.postAlertNotification({
        separationGroup: infos.runningMachine ? infos.runningMachine : null,
        reasonForCalling: orderFastTrack.order.order_id || 'out of separation',
      })

      return addPopup({
        type: 'success',
        title: 'Sucesso ao chamar ajuda',
      })
    } catch (error) {
      addPopup({
        type: 'error',
        title: 'Erro ao chamar ajuda ou chamado já foi realizado anteriormente',
      })
    }
  }

  const canAccessPainel = painelRules.some(rule => userPermissions.includes(rule))

  const togglePopover = () => {
    setPopover(state => !state)
  }

  return (
    <>
      {!canAccessPainel ? (
        <>
          <IconButton className={classes.iconButton} ref={userRef} onClick={togglePopover}>
            <MdOutlineHeadsetMic className={classes.icon} />
          </IconButton>

          <MenuPopover
            open={popover}
            onClose={togglePopover}
            anchorEl={userRef.current}
            style={{ marginTop: '0.5rem', marginLeft: '4.5rem' }}
            arrow
          >
            <MenuItem onClick={handleCallHelp}>
              <Button className={classes.sendHelpButton}>Solicitar ajuda</Button>
            </MenuItem>
          </MenuPopover>
        </>
      ) : (
        <>
          <ButtonBellNotification handleAcceptSolicitation={handleAcceptSolicitation} />
        </>
      )}

      <ModalInformation
        isOpen={modal.open}
        onClose={() => setModal({ open: false, name: '', track: 0, order: '' })}
        order={modal.order}
        separatorName={modal.name}
        track={modal.track}
      />
    </>
  )
}
