import {
  LinearProgress as MuiLinearProgress,
  LinearProgressProps,
  styled,
  Theme,
} from '@material-ui/core'
import { shade, transparentize } from 'polished'

const LinearProgress = styled(MuiLinearProgress)<Theme, { customColor: string }>({
  color: ({ customColor }) => shade(0.1, customColor),
  background: ({ customColor }) => transparentize(0.8, customColor),
  '& > .MuiLinearProgress-barColorPrimary': {
    background: ({ customColor }) => shade(0.1, customColor),
  },
})

interface IProps extends Omit<LinearProgressProps, 'color'> {
  color: string
  loading: boolean
}

const CustomLinearProgress = ({ color, loading, ...rest }: IProps) => {
  return loading ? <LinearProgress customColor={color} {...rest} /> : null
}

export default CustomLinearProgress
