import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import confirmImg from 'assets/images/confirm.svg'
import confirmBranchImg from 'assets/images/confirmBranch.svg'
import separationImg from 'assets/images/separation.svg'
import { usePopup } from 'hooks/usePopup'
import { IProduct } from 'interfaces/IProduct'
import { enStatusItem } from 'interfaces/ISeparationItem'
import { useMemo } from 'react'
import { IDataConfirmItem } from 'services/separationItemService'

import { mapMessagesGrouped } from './mapMessagesGrouped'
import { useStyles } from './styles'

export interface ICardProduct {
  view?: 'separation' | 'fastTrack' | 'conference'
  product: IProduct
  isConfirm?: boolean
  isVisualization?: boolean
  loading?: boolean
  handleBranchConfirmProduct?: (weighings?: number[]) => void
  handleConfirmProduct?: (x: Omit<IDataConfirmItem, 'itemId'>) => Promise<IProduct | void>
}

const CardProduct = ({
  view,
  product,
  isConfirm,
  loading,
  isVisualization,
  handleBranchConfirmProduct,
  handleConfirmProduct,
}: ICardProduct) => {
  const classes = useStyles()
  const {
    name,
    pre_boxed,
    unity_product_base,
    converted_quantity,
    image,
    grouped,
    instructions,
    status,
  } = product
  const { addPopup } = usePopup()

  const { isUnity, quantityToSeparate } = useMemo(() => {
    const isUnity: boolean = pre_boxed || unity_product_base === 'unidade'

    const quantityToSeparate: number = pre_boxed
      ? product?.quantity
      : converted_quantity || product?.quantity

    return { isUnity, quantityToSeparate }
  }, [product])

  const handleBreak = () => {
    handleBranchConfirmProduct && handleBranchConfirmProduct()
  }

  const handleConfirm = async () => {
    try {
      if (!handleConfirmProduct) {
        return
      }
      await handleConfirmProduct({ statusId: enStatusItem.separated })
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao confirmar item',
        description: err?.message ?? '',
      })
    }
  }

  return (
    <Box id="card-product-separation">
      <Box className={classes.imageStyle}>
        <div className={`${classes.image} ${isConfirm && classes.imageConfimed}`}>
          <img src={image || separationImg} alt="Imagem do produto" />
        </div>

        <div className={classes.infos}>
          <Typography className={isConfirm ? classes.titleActive : classes.title}>
            {name}
          </Typography>

          <Typography className={isConfirm ? classes.quantityActive : classes.quantity}>
            {grouped && product?.quantity !== 0 && (
              <>
                Qnt: <strong>{`${quantityToSeparate} ${isUnity ? 'Unidade' : 'Kg'}`} </strong>
                {pre_boxed && (
                  <span style={{ marginLeft: '7px' }}>{mapMessagesGrouped('preBoxed')}</span>
                )}
                {!pre_boxed && <span>{mapMessagesGrouped('converted')}</span>}
              </>
            )}
            {grouped && product?.quantity === 0 && (
              <>
                {view === 'separation' && (
                  <span id="span-message-grouped">{mapMessagesGrouped('separation')}</span>
                )}
                {view === 'fastTrack' && (
                  <span id="span-message-grouped">{mapMessagesGrouped('fastTrack')}</span>
                )}
              </>
            )}
            {!grouped && (
              <>
                Qnt:
                <strong> {`${quantityToSeparate} ${isUnity ? 'Unidade' : 'Kg'}`}</strong>
              </>
            )}
          </Typography>
        </div>
      </Box>
      {instructions && (
        <Typography variant="subtitle1" className={classes.obs}>
          Obs: <span>{instructions}</span>
        </Typography>
      )}

      {!isVisualization ? (
        <Box
          display="flex"
          justifyContent={product?.quantity !== 0 ? 'space-between' : 'right'}
          marginTop="1.5rem"
        >
          {isConfirm ? (
            <img
              src={status === 2 ? confirmImg : confirmBranchImg}
              className={classes.confirmIcon}
              alt="Confirmado"
            />
          ) : (
            <>
              {product?.quantity !== 0 && (
                <Button
                  variant="contained"
                  onClick={handleBreak}
                  className={classes.branchConfirmBtn}
                  disabled={loading}
                >
                  Quebra
                </Button>
              )}

              <Button
                variant="contained"
                onClick={handleConfirm}
                className={classes.confirmBtn}
                disabled={loading}
              >
                {loading ? <CircularProgress size={22} /> : 'Confirmar'}
              </Button>
            </>
          )}
        </Box>
      ) : null}
    </Box>
  )
}

export default CardProduct
