import { Box, makeStyles } from '@material-ui/core'
import { BiMedal } from 'react-icons/bi'

const LoadingMedal = () => {
  const useStyles = makeStyles({
    root: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    icon: {
      width: '100%',
      maxWidth: '150px',
      height: '100%',
      maxHeight: '150px',
      color: '#353535',
      animation: '$rotateIcon 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite ',
    },
    '@keyframes rotateIcon': {
      '0%': {
        transform: 'rotate3d(0, 1, 0, 0deg)',
      },
      '50%': {
        transform: 'rotate3d(0, 1, 0, 90deg)',
      },
      '100%': {
        transform: 'rotate3d(0, 1, 0, 0deg)',
      },
    },
  })

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <BiMedal className={classes.icon} />
    </Box>
  )
}

export default LoadingMedal
