import { Header } from 'components'
import { Outlet } from 'react-router-dom'

import { useStyles } from './styles'

const Operation = () => {
  const { pathname } = window.location
  const isConference = pathname.includes('conferencia')
  const classes = useStyles({ isConference })

  return (
    <div>
      <div className={classes.containerHeader}>
        <Header />
      </div>
      <main className={classes.main}>
        <Outlet />
      </main>
    </div>
  )
}

export default Operation
