import { Box, Typography } from '@material-ui/core'
import { Button } from 'components'
import { IError } from 'hooks/useOrderSeparation'
import { AiFillWarning } from 'react-icons/ai'

import { useStyles } from './styles'

export const ErrorFeedback = (props: IError) => {
  const { title, message, retry } = props
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <AiFillWarning className={classes.icon} />

      {!!title && (
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
      )}
      <Typography variant="subtitle1" className={classes.subtitle}>
        {message}
      </Typography>

      <Button onClick={retry} className={classes.btn} variant="contained">
        Tente novamente
      </Button>
    </Box>
  )
}
