import { makeStyles } from '@material-ui/core/styles'
import { shade } from 'polished'

export const useStyles = makeStyles({
  header: {
    position: 'relative',
    zIndex: 1,
  },
  updatedHeader: {
    background: '#FFFFFF',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.04)',
  },
  container: {
    maxWidth: '1300px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 4%',
  },
  signout: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  btnSignout: {
    border: 'none',
    background: 'transparent',
    color: '#F0F0F0',
    display: 'flex',
    alignItems: 'center',
    gap: '3px',
    fontSize: '1rem',
    fontWeight: 600,
    cursor: 'pointer',
    transition: 'color 0.2s',
    '&:hover': {
      color: shade(0.1, '#F0F0F0'),
    },
  },
  hello: {
    color: '#F0F0F0',
    paddingBottom: '3px',
    fontSize: '1rem',
  },
})
