import { Days } from 'services/settingsService'

type IDay = { name: Days; disabled: boolean }[]

export const mapDays = (day: string) => {
  const formatedDay =
    {
      Sunday: 'Domingo',
      Monday: 'Segunda',
      Tuesday: 'Terça',
      Wednesday: 'Quarta',
      Thursday: 'Quinta',
      Friday: 'Sexta',
      Saturday: 'Sábado',
    }[day] || 'Desconhecido'

  return formatedDay
}

export const days: IDay = [
  {
    name: 'Sunday',
    disabled: false,
  },
  {
    name: 'Monday',
    disabled: false,
  },
  {
    name: 'Tuesday',
    disabled: false,
  },
  {
    name: 'Wednesday',
    disabled: false,
  },
  {
    name: 'Thursday',
    disabled: false,
  },
  {
    name: 'Friday',
    disabled: false,
  },
  {
    name: 'Saturday',
    disabled: true,
  },
]
