import { usePopup } from 'hooks/usePopup'
import { IAlertResponse, IPrePickingBreakReport } from 'interfaces/IAlert'
import { enRules } from 'interfaces/IUser'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { alertService } from 'services/alertService'
import { IState } from 'store'
import { updateAlert } from 'store/reducers/alert/actions'
import { IUser } from 'store/reducers/user/reducer'

let controller: AbortController

export const useAlertNotification = (looping = true) => {
  const { addPopup } = usePopup()
  const { userPermissions } = useSelector<IState, IUser>(state => state.user)
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const dispatch = useDispatch()

  const getAlertNotifications = async (isRefresh = false) => {
    try {
      controller = new AbortController()
      const response = await alertService.getAlertNotification({
        status: 'pendente',
      })

      dispatch(updateAlert(response))
    } catch {
      if (controller.signal.aborted === false) {
        addPopup({
          type: 'error',
          title: 'Erro ao receber notificações de chamado de ajuda',
        })
      }
    } finally {
      if (controller.signal.aborted === false && !isRefresh) {
        timeout.current = setTimeout(() => getAlertNotifications(), 30000)
      }
    }
  }

  const handleAcceptSolicitation = async (notification: IAlertResponse) => {
    try {
      await alertService.aceptAlertNotification(notification.related_alert.id)
      getAlertNotifications(true)
    } catch (err: any) {
      addPopup({
        title: 'Erro ao aceitar chamado.',
        type: 'error',
        description: err?.message,
      })
    }
  }

  const handleAnswerBreak = async (notification: IAlertResponse, answer: 'accept' | 'declined') => {
    try {
      await alertService.authorizeOtherSku(
        answer,
        Number((notification.related_alert as IPrePickingBreakReport)?.item?.id),
        notification.related_alert.id,
      )
      getAlertNotifications(true)
      addPopup({
        title: `Autorização de bipagem de outro SKU ${
          answer === 'accept' ? 'concedida' : 'recusada'
        } com sucesso!`,
        type: 'success',
        autoClose: false,
      })
    } catch (err: any) {
      addPopup({
        title: 'Erro ao autorizar bipagem de outro SKU.',
        type: 'error',
        description: err?.message,
      })
    }
  }

  useEffect(() => {
    const alertRules = [enRules.SeparationOrdersManagement, enRules.Tech]

    if (userPermissions.some(rule => alertRules.includes(rule)) && looping) {
      getAlertNotifications()
    }

    return () => {
      controller?.abort()
      // eslint-disable-next-line no-undef
      clearTimeout(timeout.current as NodeJS.Timeout)
    }
  }, [])

  return {
    handleAcceptSolicitation,
    handleAnswerBreak,
    refresh: getAlertNotifications,
  }
}
