import {
  Box,
  FormControl,
  Input,
  makeStyles,
  styled,
  TableContainer as TableContainerMui,
} from '@material-ui/core'
import { MenuPopover } from 'shared'

export const Container = styled(Box)({
  overflow: 'auto',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '12px',
  minHeight: '500px',
})

export const Header = styled(Box)({
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
  height: '112px',
  padding: '16px 16px 32px 16px',
  minWidth: '1150px',
  '& .MuiBox-root:nth-child(2)': {
    flex: 'none !important',
  },
})

export const InputSearch = styled(Input)({
  '&::after': {
    borderBottom: 'none',
  },
  '&::before': {
    borderBottom: 'none',
  },
  color: '#757575',
  border: '1px solid #c5c5c5',
  height: '52px',
  padding: '0 1rem',
  '& input::placeholder': {
    color: '#757575',
  },
})

export const OptionSelected = styled(Box)({
  fontSize:'0.9rem',
  '& .MuiSvgIcon-root':{
    fill:'#757575'
  }
})

export const ContainerSelect = styled(Box)({
  height:"52px",
  display:'flex',
  alignItems:'center',
  gap:'1rem',
  '& > .MuiTypography-root':{
    color:'#757575',
    fontWeight: 500
  },
  '& > .MuiBox-root':{
    display:'flex',
    alignItems:'center',
    gap:'8px',
    border:'1px solid #c5c5c5',
    borderRadius:'20px',
    padding: '8px 1rem',
    height:'40px',
    cursor:'pointer',
  }
})

export const MenuPopoverStyled = styled(MenuPopover)({
  '& .MuiPaper-root':{
    width: '190px',
    '& > .MuiBox-root':{
      display:'flex',
      gap:'8px',
      height:'45px',
      alignItems:'center',
      justifyContent:'start',
      padding:'0 1rem',
      cursor:'pointer',
      '&:hover':{
        background:'#F3F7E6'
      }
    }
  }

})


export const SelectContainer = styled(FormControl)({
  '& .MuiInputBase-root': {
    borderRadius: '0 !important',
    height: '52px',
  },
  '& .MuiSelect-root': {
    width: '190px',
  },
})

export const TableContainer = styled(TableContainerMui)({
  minWidth: '1150px',
  '& table': {
    '& thead tr': {
      background: '#F3F7E6',
      '& th': {
        color: '#757575',
        textAlign: 'center',
      },
    },
    '& td': {
      textAlign: 'center',
      fontWeight: 500,
      color: '#353535',
    },
  },
})

export const useStyles = makeStyles({
  calendar: {
    '& span.MuiButton-label': {
      '& div': {
        padding: '0 8px',
        height: '52px',
      },
    },
  },
})
