import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@material-ui/core'
import { ComponentType, useRef, useState } from 'react'
import { IconBaseProps } from 'react-icons'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import { MenuPopover } from 'shared'

export interface IOptions<T> {
  color?: string
  title: string
  icon: ComponentType<IconBaseProps>
  action: (value: T) => void
}

interface Props<T> {
  options: IOptions<T>[]
  data: T
}

export const OptionsMenu = <T,>({ data, options }: Props<T>) => {
  const [popover, setPopover] = useState(false)
  const optionsRef = useRef(null)

  const togglePopover = () => setPopover(state => !state)

  return (
    <>
      <IconButton
        ref={optionsRef}
        onClick={e => {
          e.stopPropagation()
          togglePopover()
        }}
      >
        <MdOutlineMoreHoriz color="#414141" size={22} />
      </IconButton>
      <MenuPopover
        open={popover}
        onClose={togglePopover}
        anchorEl={optionsRef?.current}
      >
        {options.map(option => {
          const { icon: Icon, action, title } = option

          return (
            <MenuItem
              key={title}
              button
              style={{ color: option?.color }}
              onClick={() => {
                togglePopover()
                action(data)
              }}
            >
              <ListItemIcon style={{ minWidth: 40 }}>
                <Icon size={20} style={{ color: option?.color }} />
              </ListItemIcon>
              <ListItemText primary={title} />
            </MenuItem>
          )
        })}
      </MenuPopover>
    </>
  )
}
