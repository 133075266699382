import {
  Box,
  Modal as ModalMui,
  Typography,
  styled,
  Button as ButtonMui,
  Paper as PaperMui,
} from '@material-ui/core'

export const Modal = styled(ModalMui)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const Paper = styled(PaperMui)({
  maxWidth: '600px',
  width: '100%',
  padding: '2rem',
})

export const Title = styled(Typography)({
  color: '#353535',
  fontWeight: 500,
  fontSize: '1.2rem',
  textAlign: 'center',
  marginBottom: '2rem',
})

export const Subtitle = styled(Typography)({
  color: '#757575',
  fontSize: '1rem',
  marginBottom: '1rem',
})

export const Button = styled(ButtonMui)(({ colorBackground }: { colorBackground: string }) => ({
  width: '45%',
  color: '#FFF',
  background: colorBackground,
}))
