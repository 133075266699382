import { Box, makeStyles } from '@material-ui/core'
import ContentLoader from 'react-content-loader'

const useStyles = makeStyles(theme => ({
  listContainer: {
    width: '70%',
    marginTop: '70px',
    [theme.breakpoints.between(0, 1050)]: {
      width: '100%',
    },
  },
  listItem: {
    width: '100%',
    height: '13px',
  },
  summary: {
    gap: '24px',
    paddingLeft: '30px',
    margin: '30px 0 -20px',
    display: 'none',
    [theme.breakpoints.between(0, 1050)]: {
      display: 'flex',
    },
  },
  summaryDesktop: {
    display: 'block',
    width: '30%',
    [theme.breakpoints.between(0, 1050)]: {
      display: 'none',
    },
  },
}))

export const SkeletonLoading = ({ speed = 1 }: { speed?: number }) => {
  const classes = useStyles()

  return (
    <Box>
      <ContentLoader
        speed={speed}
        width={300}
        height={90}
        viewBox="0 0 300 90"
        backgroundColor="#e8e8e8"
        foregroundColor="#d6d6d6"
      >
        <rect x="30" y="0" rx="5" ry="5" width="300" height="40" />
        <rect x="30" y="45" rx="5" ry="5" width="100" height="35" />
      </ContentLoader>

      <Box
        style={{
          display: 'flex',
          gap: '24px',
          paddingLeft: '30px',
          marginTop: '15px',
        }}
      >
        <ContentLoader
          speed={speed}
          width={240}
          height={94}
          viewBox="0 0 240 94"
          backgroundColor="#e8e8e8"
          foregroundColor="#d6d6d6"
        >
          <rect x="0" y="0" rx="20" ry="20" width="240" height="94" />
        </ContentLoader>
        <ContentLoader
          speed={speed}
          width={240}
          height={94}
          viewBox="0 0 240 94"
          backgroundColor="#e8e8e8"
          foregroundColor="#d6d6d6"
        >
          <rect x="0" y="0" rx="20" ry="20" width="240" height="94" />
        </ContentLoader>
        <ContentLoader
          speed={speed}
          width={240}
          height={94}
          viewBox="0 0 240 94"
          backgroundColor="#e8e8e8"
          foregroundColor="#d6d6d6"
        >
          <rect x="0" y="0" rx="20" ry="20" width="240" height="94" />
        </ContentLoader>
      </Box>

      <Box className={classes.summary}>
        <ContentLoader
          speed={speed}
          width={240}
          height={94}
          viewBox="0 0 240 94"
          backgroundColor="#e8e8e8"
          foregroundColor="#d6d6d6"
        >
          <rect x="0" y="0" rx="0" ry="0" width="240" height="94" />
        </ContentLoader>
        <ContentLoader
          speed={speed}
          width={240}
          height={94}
          viewBox="0 0 240 94"
          backgroundColor="#e8e8e8"
          foregroundColor="#d6d6d6"
        >
          <rect x="0" y="0" rx="0" ry="0" width="240" height="94" />
        </ContentLoader>
        <ContentLoader
          speed={speed}
          width={240}
          height={94}
          viewBox="0 0 240 94"
          backgroundColor="#e8e8e8"
          foregroundColor="#d6d6d6"
        >
          <rect x="0" y="0" rx="0" ry="0" width="240" height="94" />
        </ContentLoader>
      </Box>

      <Box style={{ display: 'flex' }}>
        <ContentLoader
          speed={speed}
          className={classes.listContainer}
          viewBox="0 0 240 94"
          backgroundColor="#e8e8e8"
          foregroundColor="#d6d6d6"
        >
          <rect x="6" y="0" rx="2" ry="2" className={classes.listItem} />
          <rect x="6" y="15" rx="2" ry="2" className={classes.listItem} />
          <rect x="6" y="30" rx="2" ry="2" className={classes.listItem} />
          <rect x="6" y="45" rx="2" ry="2" className={classes.listItem} />
          <rect x="6" y="60" rx="2" ry="2" className={classes.listItem} />
          <rect x="6" y="75" rx="2" ry="2" className={classes.listItem} />
        </ContentLoader>

        <ContentLoader
          speed={speed}
          className={classes.summaryDesktop}
          viewBox="0 0 240 300"
          backgroundColor="#e8e8e8"
          foregroundColor="#d6d6d6"
        >
          <rect
            x="6"
            y="65"
            rx="2"
            ry="2"
            style={{ width: '100%', height: '60px' }}
          />
          <rect
            x="6"
            y="135"
            rx="2"
            ry="2"
            style={{ width: '100%', height: '60px' }}
          />
          <rect
            x="6"
            y="200"
            rx="2"
            ry="2"
            style={{ width: '100%', height: '60px' }}
          />
        </ContentLoader>
      </Box>
    </Box>
  )
}
