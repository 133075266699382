import { Box, Button, Typography } from '@material-ui/core'
import { IOrderConference } from '../'
import { useStyles, BoxInfoScanneds } from './styles'

interface Props {
  order: IOrderConference
  setOpenModalEditData: (param: {
    open: boolean
    order: IOrderConference
  }) => void
}

export const CardOrder = ({ order, setOpenModalEditData }: Props) => {
  const { clientName, orderId, route } = order
  const classes = useStyles()

  const missingQuantity =
    order.totalBoxes - order.boxes <= 0 ? 0 : order.totalBoxes - order.boxes

  return (
    <Box style={{ maxWidth: '580px', margin: '50px auto' }}>
      <Typography className={classes.info}>
        <strong>Pedido </strong> <span>#{orderId}</span>
      </Typography>
      <Typography className={classes.info}>
        <strong>Cliente </strong> <span>{clientName}</span>
      </Typography>
      <Typography className={classes.info}>
        <strong>Rota </strong> <span>{route}</span>
      </Typography>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '1px',
        }}
      >
        <BoxInfoScanneds borderLeft>
          <Typography className={classes.infoScanneds}>
            <strong>Caixas lidas</strong>
            <p>{Number(order.boxes)}</p>
          </Typography>
        </BoxInfoScanneds>
        <BoxInfoScanneds>
          <Typography className={classes.infoScanneds}>
            <strong>Caixas faltando</strong>
            <span>{missingQuantity}</span>
          </Typography>
        </BoxInfoScanneds>
      </Box>
      <Box display="flex" justifyContent="center" marginY="24px">
        <Button
          style={{ color: '#6E8247', fontSize: '1rem' }}
          onClick={() => {
            setOpenModalEditData({
              open: true,
              order: order,
            })
          }}
        >
          Editar caixas
        </Button>
      </Box>
    </Box>
  )
}
