import { Box, Typography } from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import { Button } from 'components'
import { useConferenceChuchu } from 'hooks/useConferenceChuchu'

import { useStyles } from './styles'

interface IStartSeparation {
  getOrderId: () => Promise<void>
}

export const StartSeparation = ({ getOrderId }: IStartSeparation) => {
  const { loading } = useConferenceChuchu()
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <header>
        <Typography className={classes.title}>Inicie a separação</Typography>
        <Typography className={classes.subtitle}>Procure um novo pedido</Typography>
      </header>
      <Button
        endIcon={<ArrowForwardIos />}
        onClick={getOrderId}
        className={classes.nextOrder}
        variant="contained"
        disabled={loading}
      >
        Procurar pedido
      </Button>
    </Box>
  )
}
