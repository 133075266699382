export const mappingType = (type: string) => {
  const nameType = {
    peso: 'Kg',
    unidade: 'Un',
  }[type]

  return nameType
}

interface ITypeOptions {
  [key: string]: number
  peso: number
  unidade: number
}

export const mappingPortionType = (type: string): number => {
  const typeOptions: ITypeOptions = {
    peso: 1,
    unidade: 2,
  }

  const typeId = typeOptions[type]
  return typeId
}

interface IStatusOptions {
  [key: string]: number
  active: number
  suspended: number
  deactivated: number
}

export const mappingStatusType = (type: string): number => {
  const statusOptions: IStatusOptions = {
    active: 1,
    suspended: 2,
    deactivated: 3,
  }

  const statusId = statusOptions[type]
  return statusId
}
