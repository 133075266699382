import { enDashbord } from './types'

export interface IDashboardReducer {
  lockAnimation: boolean
}

const initialState: IDashboardReducer = {
  lockAnimation: false,
}

export default (state = initialState, action: any): IDashboardReducer => {
  switch (action.type) {
    case enDashbord.LOCKANIMATION:
      return {
        ...state,
        lockAnimation: action.value,
      }
    default:
      return state
  }
}
