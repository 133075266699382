import { Outlet } from 'react-router'

import Layout from './Layout'

const Admin = () => (
  <Layout>
    <Outlet />
  </Layout>
)

export default Admin
