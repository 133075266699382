import { Typography, Modal, Paper, Button } from '@material-ui/core'
import { useStyles } from './styles'
import { IRouteOverResponseExt } from '../useRoutesOver'

interface IProps {
  callback: (param: boolean) => void
  data: IRouteOverResponseExt
}

const ModalBoxFull = (props: IProps) => {
  const classes = useStyles()
  return (
    <Modal open={true} onClose={() => props.callback(false)} className={classes.root}>
      <Paper className={classes.container}>
        <Typography className={classes.title}>
          Deseja confirmar que não é possivel a diminuição de caixas da rota #{props.data.route}?
        </Typography>
        <Button className={classes.buttonConfirm} onClick={() => props.callback(true)}>
          Confimar
        </Button>
      </Paper>
    </Modal>
  )
}

export default ModalBoxFull
