import { styled, Typography } from '@material-ui/core'
import { Checkbox as CheckboxShared } from 'shared'
import { ContainerIconImage, Divider, FormControlLabel } from './styles'
import React from 'react'

interface IPropsImage {
  text: string
  checked: boolean
  disable?: boolean
  onChange: (value: boolean) => void
  image: string
  isRed?: boolean
  icon?: React.ComponentType<{ style: React.CSSProperties }>
}

interface IPropsIcon {
  text: string
  checked: boolean
  disable?: boolean
  onChange: (value: boolean) => void
  isRed?: boolean
  image?: string
  icon: React.ComponentType<{ style: React.CSSProperties }>
}

type IProps = IPropsImage | IPropsIcon

const LabelStyle = styled(Typography)({
  color: '#353535',
  textAlign: 'left',
  userSelect: 'none',
  fontSize: '1.1rem',
  marginLeft: '1rem',
  '& span': {
    display: 'block',
    color: '#757575',
    fontSize: '0.95rem',
  },
})

const CheckboxConfig = (props: IProps) => {
  return (
    <FormControlLabel
      label={<LabelStyle>{props.text}</LabelStyle>}
      control={
        <>
          <CheckboxShared
            color="primary"
            checked={props.checked}
            onChange={e => props.onChange(e.target.checked)}
            disabled={props.disable}
          />
          <Divider orientation="vertical" />
          <ContainerIconImage isRed={props.isRed}>
            {props.image && (
              <img loading="lazy" src={props.image} style={{ width: '25px', height: 'auto' }} />
            )}
            {props.icon && <props.icon style={{ width: '25px', height: 'auto' }} />}
          </ContainerIconImage>
        </>
      }
    />
  )
}

export default CheckboxConfig
