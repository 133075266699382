import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    background: '#FFC7C5',
    display: 'flex',
    width: '200px',
    minWidth: '200px',
    height: '45px',
    alignItems: 'center',
    padding: '0.3rem 0.7rem',
    gap: '0.5rem',
    borderRadius: '100px',
    border: '2px solid #DA3737',
    fontWeight: 700,
    fontSize: '1.2rem',
  },
})
