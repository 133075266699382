import { Button as ButtonMui, ButtonProps, CircularProgress } from '@material-ui/core'
import { ReactNode } from 'react'
import { AiOutlineCheckCircle } from 'react-icons/ai'

import { useStyles } from './styles'

interface Props extends ButtonProps {
  isDelete?: boolean
  isFullScreen?: boolean
  isLoading?: boolean
  isSuccess?: boolean
  children: ReactNode
}

export const Button = (props: Props) => {
  const {
    isDelete,
    isFullScreen,
    isLoading,
    isSuccess,
    className,
    disabled,
    children,
    ref,
    ...rest
  } = props
  const classes = useStyles({ isDelete })

  return (
    <ButtonMui
      className={`${classes.root} ${
        isFullScreen ? classes.fullScreen : classes.screenDefault
      } ${className}`}
      disabled={isLoading || disabled}
      ref={ref}
      {...rest}
    >
      {isSuccess ? (
        <AiOutlineCheckCircle size={25} color="#ffffff" />
      ) : isLoading ? (
        <CircularProgress data-testid="loading-btn" size={25} className={classes.loading} />
      ) : (
        children
      )}
    </ButtonMui>
  )
}
