import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    padding: '2rem 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subtitle: {
    color: '#757575',
    textAlign: 'center',
    fontSize: '1.2rem',
  },
  title: {
    color: '#353535',
    fontSize: '1.4rem',
    fontWeight: 500,
    marginTop: '1.5rem',
    textAlign: 'center',
  },
  boxProgress: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  metaTitle: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '1.1rem',
    lineHeight: '150%',
    color: '#6E8247',
    marginBottom: '0.5rem',
    marginTop: '1rem',
    [theme.breakpoints.between(0, 600)]: {
      fontSize: '1rem',
    },
  },
  buttonPercentage: {
    textAlign: 'center',
    position: 'relative',
    minWidth: '5rem',
    height: '2.2rem',
    background: '#89BD23',
    borderRadius: '30px',
    color: '#FFFFFF',
    fontSize: '1.5rem',
    fontFamily: 'Inter',
  },
  completedGoalsText: {
    textAlign: 'center',
    marginTop: '1.5rem',
    marginBottom: '0.5rem',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '1.2rem',
    color: '#618619',
    [theme.breakpoints.between(0, 600)]: {
      fontSize: '1rem',
    },
  },
  arrow: {
    content: "''",
    left: '50%',
    transform: 'translateX(-50%)',
    width: '50px',
    height: '50px',
    borderTop: '17px solid #f00',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    marginTop: '1rem',
  },
  progress: {
    width: '520px',
    height: '34px',
    borderRadius: '30px',
    color: '#668133',
    backgroundColor: '#E5E5E5',
    [theme.breakpoints.between(0, 600)]: {
      width: '400px',
    },
    [theme.breakpoints.between(0, 430)]: {
      width: '270px',
    },
  },
  metaSubtitle: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '135%',
    color: '#757575',
    textAlign: 'right',
    marginTop: '0.5rem',
  },
  nextOrder: {
    marginTop: '2.8rem',
    fontSize: '1rem',
    padding: '0.5rem 1rem',
    minWidth: '11rem',
  },
}))
