import { makeStyles } from '@material-ui/core'
import { Opacity } from '@material-ui/icons'
import { transparentize } from 'polished'
import theme from 'theme'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 1rem',
  },
  container: {
    maxWidth: '450px',
    width: '100%',
    height: '385',
    flexShrink: 0,
    borderRadius: '8px',
    background: '#FFF',
  },
  loading: {
    textAlign: 'center',
    padding: '2.81rem 0',
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 500,
    textAlign: 'center',
    marginTop: '32px',
  },
  subtitle: {
    fontSize: '1.2rem',
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: '24px',
  },
  supportText: {
    fontSize: '1rem',
    fontWeight: 400,
    textAlign: 'left',
    marginTop: '24px',
    marginBottom: '1rem',
  },
  containerBtn: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  btnConfirm: {
    display: 'flex',
    width: '165px',
    height: '48px',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexShrink: 0,
    borderRadius: '4px',
    background: '#89BD23',
    color: '#FFFFFF',
    marginTop: '24px',
    marginBottom: '32px',
    '&:hover': {
      background: '#89BD23',
      filter: 'brightness(0.85)',
      transition: 'all 0.5s',
    },
  },
  btnCancel: {
    display: 'flex',
    width: '165px',
    height: '48px',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexShrink: 0,
    borderRadius: '4px',
    background: '#DA3737',
    color: '#FFFFFF',
    marginTop: '24px',
    marginBottom: '32px',
    '&:hover': {
      background: '#DA3737',
      filter: 'brightness(0.85)',
      transition: 'all 0.5s',
    },
  },
  icon: {
    width: '1.2rem',
    height: '1.2rem',
    borderRadius: '6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '0.7rem',
    '& > img': {
      width: '1.1rem',
    },
    '& > svg': {
      width: '1.5rem',
    },
  },
  dividerButton: {
    width: '380',
    height: '1px',
    strokeWidth: '0.5px',
    stroke: 'var(--preto-cinza-100, #C4C4C4)',
    margin: '16px auto 16px auto',
  },
  printerIcon: {
    background: '#00000',
    width: '40px',
    height: '40px',
    color: 'green',
  },
}))
