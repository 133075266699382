import { Button, Typography } from '@material-ui/core'
import { memo } from 'react'

import { useStyles } from './styles'

interface ICardProps {
  name: string
  image: string
  callback: () => void
  testid: string
  isScanner?: boolean
  isDisabled?: boolean
}

const CardComponent = ({ name, image, callback, testid, isScanner, isDisabled }: ICardProps) => {
  const classes = useStyles()

  return (
    <Button
      variant="contained"
      className={`${classes.container} ${classes.fast} ${isScanner && classes.scanner}`}
      onClick={callback}
      data-testid={testid}
      disabled={isDisabled}
    >
      <img src={image} alt={name} className={classes.image} />
      <Typography className={classes.title}>{name}</Typography>
    </Button>
  )
}

const Card = memo(CardComponent)

export { Card }
