/* eslint-disable prettier/prettier */
import {
  IGetProductApiRequest,
  IProductApiResponse,
  IProductApiPutRequest,
} from 'interfaces/IRequestProductApi'

import { api as apiService, ApiService } from './api'

export interface ISkuConfigResponse {
  message: string
}

class SkuConfigService {
  constructor(private readonly api: ApiService) {}

  async getProducts(params: IGetProductApiRequest): Promise<IProductApiResponse> {
    return this.api.get(`${carambolaUrl}/product-data/product`, {
      params,
    })
  }

  async updateProduct(data: IProductApiPutRequest): Promise<ISkuConfigResponse> {
    return this.api.patch(`${carambolaUrl}/product-data/product/${data.sku}`, data)
  }

  async updateManyProducts(data: any): Promise<ISkuConfigResponse> {
    return this.api.patch(`${carambolaUrl}/product-data/product/upload/filename`, data)
  }
}
const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const skuConfigService = new SkuConfigService(apiService)
