import { ReactNode } from 'react'

import { AuthProvider } from './useAuth'
import { UseConferenceChuchuProvider } from './useConferenceChuchu'
import { PupupProvider } from './usePopup'

const AppProvider = ({ children }: { children: ReactNode }) => (
  <PupupProvider>
    <AuthProvider>
      <UseConferenceChuchuProvider>{children}</UseConferenceChuchuProvider>
    </AuthProvider>
  </PupupProvider>
)

export default AppProvider
