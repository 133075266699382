import { Typography } from '@material-ui/core'
import { IItem } from 'interfaces/ISeparation'
import { useMemo } from 'react'

import { Separator } from '../Separator'
import { useStyles } from './styles'

interface ISeparationItem {
  items: IItem[]
  setItem: (item: IItem) => void
}

export interface IFormattedItems {
  name: string
  time: string
  start: Date
  itens: number
  products: IItem[]
  setItem?: (item: IItem) => void
}

export const SeparationItem = ({ items, setItem }: ISeparationItem) => {
  const classes = useStyles()

  const formattedItems: IFormattedItems[] = useMemo(() => {
    return items.reduce((acc: any, item, index) => {
      const verifyWork = acc?.some(
        ({ name }: any) => name === item.work?.separator.username,
      )

      if (index === 0 || verifyWork === false) {
        const products = items.filter(
          ({ work }) =>
            work?.separator.username === item.work?.separator.username,
        )

        return [
          ...acc,
          {
            name: item.work?.separator.username,
            time: item.work?.time_length,
            start: item.work?.begin,
            itens: products.length,
            products,
          },
        ]
      }
      return acc
    }, [])
  }, [items])

  return (
    <>
      {Object.keys(formattedItems).length !== 0 ? (
        formattedItems.map((item, index) => {
          if (item.name) {
            return <Separator key={index} {...item} setItem={setItem} />
          }

          return null
        })
      ) : (
        <Typography className={classes.noItem}>
          Não havia itens para essa etapa
        </Typography>
      )}
    </>
  )
}
