import { Box, Dialog, LinearProgress, TextField, Typography } from '@material-ui/core'
import CheckSvg from 'assets/images/Icon check.svg'
import { Button, ModalChoicePrinter } from 'components'
import { usePopup } from 'hooks/usePopup'
import { ISeparationGroupConfig } from 'interfaces/ISeparationGroupService'
import VirtualKeyboard from 'pages/Admin/FixScanner/VirtualKeyboard'
import { useState, FormEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardService } from 'services/dashboardService'
import { historicDivergenceService } from 'services/historicDivergenceBoxesService'
import { printerService } from 'services/printerService'
import { separationOrderService } from 'services/separationOrderService'
import { IState } from 'store'
import { updateOrder } from 'store/reducers/orderFastTrack/actions'

import { useStyles } from './styles'

interface IProps {
  open: boolean
  handleConfirmOrder: (data: { boxesNumber: number }) => Promise<void>
  openModalScanner: () => void
  onClose: () => void
  openModalLoading: () => void
  closeModalLoading: () => void
}

const Content = ({
  handleConfirmOrder,
  openModalScanner,
  onClose: closeModal,
  closeModalLoading,
  openModalLoading,
}: IProps) => {
  const classes = useStyles()
  const {
    settings,
    infos: { runningMachine },
    user: { userId },
    orderFastTrack: { order: orderData },
  } = useSelector<IState, IState>(state => state)
  const separationGroup = settings.separationGroups.find(
    ({ separation_group }) => Number(separation_group) === Number(runningMachine),
  ) as ISeparationGroupConfig
  const { cam_scanner, infrared_scanner } = separationGroup
  const showInfraRedScanner = infrared_scanner
  const hasScanner = !!(cam_scanner || infrared_scanner)

  const [loading, setLoading] = useState(false)
  const [openModalPrinter, setOpenModalPrinter] = useState(false)
  const [boxNumber, setBoxNumber] = useState<number>(0)
  const [section, setSection] = useState<'initial' | 'divergence'>('initial')
  const [inputBoxes, setInputBoxes] = useState({
    value: '',
    error: false,
  })
  const [expectQuantity, setExpectQuantity] = useState<number>(0)
  const { addPopup } = usePopup()

  const dispatch = useDispatch()

  const onConfirmOrder = async (boxNumberParam: number) => {
    await handleConfirmOrder({ boxesNumber: boxNumberParam })
    dispatch(updateOrder({ ...orderData, boxes_number: boxNumberParam }))
    closeModal()
  }

  const onOpenModalScanner = (boxNumberParam: number) => {
    dispatch(updateOrder({ ...orderData, boxes_number: boxNumberParam }))
    openModalScanner()
    closeModal()
  }

  const getBoxNumberAndOpenModalScanner = async () => {
    const { order } = await dashboardService.getOrderDetails(orderData.order_id)
    onOpenModalScanner(order.boxes_number)
  }

  const print = async (boxesNumberPrint: number) => {
    try {
      openModalLoading()
      await printerService.print({
        orderId: orderData.order_id,
        boxQuantity: boxesNumberPrint,
        initialPrint: true,
      })

      addPopup({
        type: 'success',
        title: 'Impressão enviada! 🤠',
      })
      if (hasScanner) {
        onOpenModalScanner(boxesNumberPrint)
        return
      }
      await onConfirmOrder(boxesNumberPrint)
    } catch (error: any) {
      if (error?.message?.includes('já foram impressas')) {
        addPopup({
          type: 'info',
          title: 'As etiquetas já foram impressas',
          description: 'Caso cê queira, pede reimpressão.',
        })
        if (hasScanner) {
          try {
            await getBoxNumberAndOpenModalScanner()
            return
          } catch (err: any) {
            addPopup({
              type: 'error',
              title: 'Erro ao buscar informações do pedido',
              description: err?.message ?? err?.msg,
              autoClose: false,
            })
            return
          }
        }
        await onConfirmOrder(boxesNumberPrint).catch(() => {
          addPopup({
            type: 'error',
            title: 'Erro ao finalizar pedido',
            description: 'Dá um alô pro time de tech',
            autoClose: false,
          })
        })
        return
      }
      addPopup({
        type: 'error',
        title: 'Deu erro pra mandar a impressão',
        description: 'Dá um alô pro time de tech',
      })
    } finally {
      closeModalLoading()
    }
  }

  const startPrint = (boxesNumberData: number) => {
    const isWhite = orderData.color_route === 'BRANCA'
    const isFirstOrder = orderData.is_first_order
    setBoxNumber(boxesNumberData)
    if (isWhite && isFirstOrder) {
      setOpenModalPrinter(true)
      return
    }
    print(boxesNumberData)
  }

  const handleSubmitForm = async (printerChoice: number) => {
    try {
      openModalLoading()
      await printerService.print({
        orderId: orderData.order_id,
        printerChoice,
        boxQuantity: boxNumber,
        initialPrint: true,
      })

      addPopup({
        type: 'success',
        title: 'Impressão enviada! 🤠',
      })
      setOpenModalPrinter(false)
      if (hasScanner) {
        onOpenModalScanner(boxNumber)
      } else {
        await onConfirmOrder(boxNumber)
      }
    } catch (error: any) {
      if (error?.message?.includes('já foram impressa')) {
        addPopup({
          type: 'info',
          title: 'As etiquetas já foram impressas.',
          description: 'Caso cê queira, pede reimpressão.',
        })
        if (hasScanner) {
          onOpenModalScanner(boxNumber)
        } else {
          onConfirmOrder(boxNumber)
        }
      } else {
        addPopup({
          type: 'error',
          title: 'Deu erro pra mandar a impressão 😞',
          description: 'Dá um alô pro time de tech',
        })
      }
    } finally {
      closeModalLoading()
    }
  }

  const handleBoxSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    if (Number(inputBoxes.value) <= 0) {
      addPopup({
        type: 'error',
        title: 'Número de caixas não pode ser menor ou igual a 0.',
      })
      return
    }
    const totalBoxesExpected = await getTotalOrderBoxesExpected()

    if (
      section === 'initial' &&
      Number(totalBoxesExpected) !== Number(inputBoxes.value) &&
      separationGroup.has_count_by_step
    ) {
      setSection('divergence')
      return
    }

    if (section === 'divergence') {
      await createHistoricDivergenceBoxes('confirm_divergence', Number(inputBoxes.value))
    }

    startPrint(Number(inputBoxes.value))
  }

  const getTotalOrderBoxesExpected = async () => {
    setLoading(true)
    try {
      const {
        data: { total_boxes_of_order },
      } = await separationOrderService.getOrder(String(orderData.order_id))
      setExpectQuantity(total_boxes_of_order)
      return total_boxes_of_order
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao pegar a quantidade total de caixas esperadas',
        description: err?.message ?? err?.msg,
        autoClose: false,
      })
      return 0
    } finally {
      setLoading(false)
    }
  }

  const createHistoricDivergenceBoxes = async (action: string, boxNumberParam: number) => {
    setLoading(true)
    try {
      await historicDivergenceService.create({
        action,
        order: orderData.order_id,
        expected_boxes: expectQuantity,
        inputed_boxes: boxNumberParam,
        inputed_by: Number(userId),
      })
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao criar histórico de divergência de caixas',
        description: err?.message ?? err?.msg,
        autoClose: false,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleClickChange = async () => {
    await createHistoricDivergenceBoxes('back_divergence', Number(inputBoxes.value))
    setSection('initial')
  }

  const onClickKeyboard = (value: string) => {
    if (value === 'delete') {
      setInputBoxes(state => ({ ...state, error: false, value: state.value.slice(0, -1) }))
      return
    }
    if (value === 'submit') {
      if (Number(inputBoxes.value) <= 0) {
        setInputBoxes(state => ({
          ...state,
          error: true,
        }))
        return
      }
      return
    }
    setInputBoxes(state => ({
      ...state,
      value: state.value + value,
      error: false,
    }))
  }

  return (
    <>
      <LinearProgress className={classes.linearProgress} style={{ opacity: loading ? 1 : 0 }} />
      {section === 'initial' && (
        <Box>
          <Typography className={classes.title}>Pedido concluído</Typography>

          <img
            src={CheckSvg}
            alt="check"
            style={{ width: '70px', margin: '2rem auto 0 auto', display: 'block' }}
          />

          <Typography style={{ margin: '1.5rem 0 1rem 0', textAlign: 'center' }}>
            Quantas caixas você utilizou ao todo?
          </Typography>
          <form onSubmit={handleBoxSubmit}>
            {showInfraRedScanner && (
              <>
                <TextField
                  type="number"
                  placeholder="Quantidade de caixas"
                  label="Digite a quantidade total de caixas do pedido"
                  variant="outlined"
                  error={inputBoxes.error}
                  value={inputBoxes.value}
                  InputLabelProps={{ shrink: true }}
                  disabled
                  className={classes.input}
                />
                <Box style={{ paddingLeft: '1rem', height: '100%' }}>
                  <VirtualKeyboard onClick={onClickKeyboard} />
                </Box>
              </>
            )}
            {!showInfraRedScanner && (
              <>
                <TextField
                  type="number"
                  placeholder="Quantidade de caixas"
                  label="Digite a quantidade total de caixas do pedido"
                  variant="outlined"
                  value={inputBoxes.value}
                  onChange={e => {
                    setInputBoxes(state => ({
                      ...state,
                      value: e.target.value,
                    }))
                  }}
                  className={classes.input}
                />
              </>
            )}
            <Button
              type="submit"
              className={classes.button}
              disabled={loading || Number(inputBoxes.value) <= 0}
            >
              Tá no jeito, pode imprimir!
            </Button>
          </form>
        </Box>
      )}
      {section === 'divergence' && (
        <Box width="100%">
          <Typography className={classes.title} style={{ marginBottom: '1.5rem' }}>
            Erro na contagem de caixas!
          </Typography>

          <Box className={classes.alert}>
            <Typography>
              Qntd. informada: <strong>{inputBoxes.value} caixas</strong>
            </Typography>
          </Box>

          <Typography
            style={{
              margin: '1.5rem 0 0 0',
              textAlign: 'center',
            }}
          >
            A quantidade informada está diferente da contagem de caixas entre etapas.
          </Typography>
          <Typography
            style={{
              margin: '0 0 1.5rem 0',
              textAlign: 'center',
            }}
          >
            <strong>Confira antes de continuar!</strong>
          </Typography>
          <Typography
            style={{
              margin: '0 0 1.5rem 0',
              textAlign: 'center',
            }}
          >
            Deseja confirmar mesmo assim?
          </Typography>

          <Box display="flex" style={{ gap: '10px' }}>
            <Button
              className={`${classes.button} ${classes.buttonChange}`}
              disabled={loading}
              style={{ width: '50%' }}
              onClick={handleClickChange}
            >
              Alterar
            </Button>
            <Button
              className={classes.button}
              disabled={loading}
              style={{ width: '50%' }}
              onClick={() => handleBoxSubmit()}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      )}
      <ModalChoicePrinter
        open={openModalPrinter}
        confirm={handleSubmitForm}
        handleClose={() => setOpenModalPrinter(false)}
      />
    </>
  )
}

export const DialogBoxCount = (props: IProps) => {
  const classes = useStyles()

  return (
    <Dialog className={classes.dialog} open={props.open}>
      <Content {...props} />
    </Dialog>
  )
}
