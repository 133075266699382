import { usePopup } from 'hooks/usePopup'
import { get } from 'http'
import { useEffect, useRef, useState } from 'react'
import { FaCheck, FaEye, FaEyeSlash } from 'react-icons/fa'
import { MdBlock } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { IRouteLimit, IRouteOverResponse, routeService } from 'services/routeService'
import { IState } from 'store'

export interface IRouteOverResponseExt extends IRouteOverResponse {
  quantityToDecrease: string
}

export interface IOption {
  icon: any
  action: (data: IRouteOverResponseExt) => void
  title: string
}

export const useRoutesOver = () => {
  const [openModal, setOpenModal] = useState<any>({
    open: false,
    data: null,
  })
  const { addPopup } = usePopup()
  const controller = useRef<AbortController | null>(null)
  const [routesOver, setRoutesOver] = useState<IRouteOverResponseExt[]>([])
  const [routesHidden, setRoutesHidden] = useState<IRouteOverResponseExt[]>([])
  const [routesReduced, setRoutesReduced] = useState<IRouteOverResponseExt[]>([])
  const [routesNotReduced, setRoutesNotReduced] = useState<IRouteOverResponseExt[]>([])
  const userId = useSelector<IState, number>(state => Number(state.user.userId))
  const [loading, setLoading] = useState(false)
  const [openModalBoxFull, setOpenModalBoxFull] = useState({
    open: false,
    callback: null as any,
    data: null as any as IRouteOverResponseExt,
  })

  const timeout = useRef<any>(null)

  const getRoutesOver = async (isRefresh = true) => {
    controller.current = new AbortController()
    try {
      setLoading(true)
      const { data } = await routeService.getOverRoutes({
        controller: controller.current,
      })

      const formattedData: IRouteOverResponseExt[] = data.map(route => ({
        ...route,
        quantityToDecrease: getQuantityToDecrease(route),
      }))

      setRoutesOver(
        formattedData.filter(
          route => !route.route_above.is_hidden && route.route_above.reduced === null,
        ),
      )
      setRoutesHidden(
        formattedData.filter(
          route => route.route_above.is_hidden && route.route_above.reduced === null,
        ),
      )
      setRoutesReduced(formattedData.filter(route => route.route_above.reduced))
      setRoutesNotReduced(formattedData.filter(route => route.route_above.reduced === false))
    } catch (err: any) {
      if (controller.current?.signal?.aborted) return
      addPopup({
        title: 'Erro ao buscar rotas com número de caixas acima do esperado',
        description: err?.message ?? err?.msg ?? 'Erro desconhecido',
        type: 'error',
        autoClose: false,
      })
    } finally {
      if (!controller.current?.signal?.aborted && !isRefresh) {
        timeout.current = setTimeout(() => {
          getRoutesOver(false)
        }, 5000)
      }

      setLoading(false)
    }
  }

  const refresh = getRoutesOver

  const onClickRoute = (route: IRouteOverResponseExt) => {
    setOpenModal({ open: true, data: route })
  }

  const onClickRouteHidden = (route: IRouteOverResponseExt) => {
    addPopup({
      title: 'Não é necessário diminuir caixas de rota oculta',
      type: 'error',
      autoClose: false,
    })
  }

  const onClickRouteNotReduced = (route: IRouteOverResponseExt) => {
    addPopup({
      title: 'Não é possível diminuir caixas de rota marcada como não reduzível',
      type: 'error',
      autoClose: false,
    })
  }

  const onHandleRouteLimit = async (route: IRouteOverResponseExt, is_hidden: boolean) => {
    try {
      setLoading(true)

      await routeService.updateRouteLimit(route.route_above.id, {
        is_hidden,
        is_hidden_by: userId,
      })

      await refresh()

      addPopup({
        title: `Rota #${route.route} ${is_hidden ? 'ocultada' : 'exibida'} com sucesso`,
        type: 'success',
      })
    } catch (err: any) {
      addPopup({
        title: `Erro ao ${is_hidden ? 'ocultar' : 'exibir'} a rota #${route.route}`,
        description: err?.message ?? err?.msg ?? 'Erro desconhecido',
        type: 'error',
        autoClose: false,
      })
    } finally {
      setLoading(false)
    }
  }

  const onHandleRouteReduced = async (route: IRouteOverResponseExt, is_reduced: boolean | null) => {
    if (is_reduced === false) {
      const response = await new Promise(resolve => {
        setOpenModalBoxFull({
          open: true,
          callback: (param: boolean) => {
            onCloseModalBoxFull()
            resolve(param)
          },
          data: route,
        })
      })
      if (!response) return
    }

    try {
      setLoading(true)

      await routeService.updateRouteLimit(route.route_above.id, {
        reduced: is_reduced,
        reduced_by: userId,
      })

      await refresh()

      addPopup({
        title: `Rota #${route.route} atualizada com sucesso`,
        type: 'success',
      })
    } catch (err: any) {
      addPopup({
        title: `Erro ao atualizar a rota #${route.route}`,
        description: err?.message ?? err?.msg ?? 'Erro desconhecido',
        type: 'error',
        autoClose: false,
      })
    } finally {
      setLoading(false)
    }
  }

  const getQuantityToDecrease = (route: IRouteOverResponse): string => {
    if (route.real_boxes_number && route.capacity_vehicle) {
      if (route.capacity_vehicle >= route.real_boxes_number) {
        return '0 caixas'
      }
      return `${route.real_boxes_number - route.capacity_vehicle} caixas`
    }

    if (route.real_boxes_number && !route.capacity_vehicle) {
      return 'Limite não encontrado'
    }

    return 'Quantidade atual não encontrado'
  }

  const optionsOculted: IOption[] = [
    {
      title: 'Exibir',
      icon: FaEye,
      action: data => {
        onHandleRouteLimit(data, false)
      },
    },
  ]

  const options: IOption[] = [
    {
      title: 'Ocultar',
      icon: FaEyeSlash,
      action: data => {
        onHandleRouteLimit(data, true)
      },
    },
    {
      title: 'Não é possível reduzir',
      icon: MdBlock,
      action: data => {
        onHandleRouteReduced(data, false)
      },
    },
  ]

  const optionsRouteNotReduced: IOption[] = [
    {
      title: 'Tornar possível reduzir',
      icon: FaCheck,
      action: data => {
        onHandleRouteReduced(data, null)
      },
    },
  ]

  const onCloseModalScan = () => {
    setOpenModal({ open: false, data: null })
  }

  const onCloseModalBoxFull = () => {
    setOpenModalBoxFull({
      open: false,
      callback: null as any,
      data: null as any,
    })
  }

  useEffect(() => {
    refresh(false)

    return () => {
      controller.current?.abort()
      clearTimeout(timeout.current)
    }
  }, [])

  return {
    loading,
    routesOver,
    onClickRoute,
    options,
    routesHidden,
    onClickRouteHidden,
    optionsOculted,
    openModal,
    refresh,
    onCloseModalScan,
    openModalBoxFull,
    routesReduced,
    routesNotReduced,
    optionsRouteNotReduced,
    onClickRouteNotReduced,
  }
}
