import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { CSSProperties } from 'react'
import { TableContainer } from '../styles'
import { SubTitle } from '../../CriticalOrders/styles'
import { IRouteOverResponseExt } from '../useRoutesOver'

interface IPropsTableRoutesReduced {
  data: IRouteOverResponseExt[]
  subtitle: string
  styleContainer?: CSSProperties
}

const TableRoutesReduced = (props: IPropsTableRoutesReduced) => {
  return (
    <Box style={props.styleContainer || {}}>
      <SubTitle style={{ marginTop: '4rem' }}>{props.subtitle}</SubTitle>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rota</TableCell>
              <TableCell>Máximo veiculo</TableCell>
              <TableCell>Quantidade atual</TableCell>
              <TableCell>Usuário</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map(route => (
              <TableRow key={route.route}>
                <TableCell>ROTA {route.route}</TableCell>
                <TableCell>{route.capacity_vehicle} caixas</TableCell>
                <TableCell>{route.real_boxes_number} caixas</TableCell>
                <TableCell>{route.route_above.reduced_by}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default TableRoutesReduced
