import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { ColorRoute } from 'components/pages/PackingList/ColorRoute'
import { useMemo, useState } from 'react'
import { AiFillPrinter } from 'react-icons/ai'
import { BsCircleFill } from 'react-icons/bs'
import { MdExpandMore } from 'react-icons/md'
import { animated } from 'react-spring'
import { mapTagColor } from 'utils/mapTagColor'

import { IHandleEvent } from '../..'
import { AccordionStyled, BoxContainerInfo, BoxInfo, ButtonPrinter } from './styles'
import { IPackingList, IResponsePackingDict } from 'interfaces/IPackingList'

interface Props {
  handleEvent: (param: IHandleEvent) => void
  item: IResponsePackingDict
}

const Accordion = ({ item, handleEvent }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const readBoxes = useMemo(() => {
    return item.orders.reduce((acc, order) => {
      const boxes = Number(order?.adjusted_boxes_number_conveyor || order?.corrected_boxes_number || order.loading_boxes_number)
      return acc + boxes
    }, 0)
  }, [item])

  const getTotalOrderBoxes = (order: IPackingList) => {
    if (order.adjusted_boxes_number_conveyor) {
      return `${order.adjusted_boxes_number_conveyor} / ${order.adjusted_boxes_number_conveyor} (Reduzida na esteira)`
    }
    if (order.skipped) {
      return `${order.corrected_boxes_number} / ${order.corrected_boxes_number} (Foram corrigidas)`
    }
    if (order.adjusted_boxes_number) {
      return `${order.loading_boxes_number || 0} / ${order.adjusted_boxes_number
        } (Lidas / Reduzidas)`
    }
    if (order.conference_boxes_number === 0) {
      return 'Finalizado manualmente'
    }
    return `${order.loading_boxes_number || 0} / ${order.conference_boxes_number
      } (Lidas / Separadas)`
  }

  return (
    <animated.div>
      <AccordionStyled expanded={expanded}>
        <AccordionSummary
          expandIcon={
            <MdExpandMore
              onClick={() => {
                setExpanded(state => !state)
              }}
            />
          }
        >
          <Box>
            <ColorRoute colorRoute={mapTagColor(item.colorRoute)} />
            <BoxContainerInfo>
              <BoxInfo
                onClick={() => {
                  setExpanded(state => !state)
                }}
              >
                <strong style={{ color: '#353535', fontWeight: 500 }}>
                  {!item.route && <>Sem rota / {item.colorRoute} </>}
                  {!!item.route && (
                    <>
                      Rota {item.route} / {item.colorRoute}
                    </>
                  )}
                </strong>
                <strong>
                  {item.quantityOrders} {item.quantityOrders === 1 ? 'Pedido' : 'Pedidos'}
                </strong>
                <strong>
                  {readBoxes} {readBoxes === 1 ? 'Caixa' : 'Caixas'}
                </strong>
                <strong>
                  {item.status === 'pending' && (
                    <>
                      <BsCircleFill style={{ marginRight: '0.5rem' }} color="#FFC107" /> Pendente
                    </>
                  )}
                  {item.status === 'loaded' && (
                    <>
                      <BsCircleFill style={{ marginRight: '0.5rem' }} color="#2E75FF" /> Carregado
                    </>
                  )}
                </strong>
              </BoxInfo>
              <ButtonPrinter
                onClick={() => {
                  handleEvent({ type: 'print', param: item.route })
                }}
                disabled={item.status === 'pending'}
              >
                <AiFillPrinter /> <span>Imprimir</span>
              </ButtonPrinter>
            </BoxContainerInfo>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>N° Do Pedido</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Caixas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {item.orders.map(order => (
                <TableRow key={order.id}>
                  <TableCell>{order.order_id}</TableCell>
                  <TableCell>{order.client_name_or_trade}</TableCell>
                  <TableCell>{getTotalOrderBoxes(order)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </AccordionStyled>
    </animated.div>
  )
}

export default Accordion
