import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  separated: {
    fontSize: '1rem',
    fontHeigth: '1.125rem',
    marginBottom: '1rem',
    fontWeight: 600,
  },
  separatedOrder: {
    display: 'flex',
    flexDirection: 'column-reverse',
    gap: '1rem',
  },
  tabs: {
    color: '#757575',
    '& .MuiTabs-flexContainer': {
      width: '100%',
    },
    '& .MuiButtonBase-root': {
      width: '50%',
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#353535',
    },
  },
}))
export default useStyles
