import { enToggleScanner } from './types'

const initialState = true

export default (state = initialState, action: any) => {
  switch (action.type) {
    case enToggleScanner.TOGGLE:
      return action.value
    default:
      return state
  }
}
