import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles({
  container: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  text: {
    textAlign: 'center',
    fontSize: '1.3rem',
    position: 'relative',
    color: '#757575',
    '&:after': {
      content: '"."',
      animation: '$dots 1s steps(5, end) infinite',
    },
  },
  '@keyframes dots': {
    '0%, 20%': {
      color: 'rgba(0,0,0,0)',
      textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
    },
    '40%': {
      color: '#000000',
      textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
    },
    '60%': {
      textShadow: '.25em 0 0 #000000, .5em 0 0 rgba(0,0,0,0)',
    },
    '80%, 100%': {
      textShadow: '.25em 0 0 #000000, .5em 0 0 #000000',
    },
  },
})
