import {
  InputAdornment,
  styled,
  TextField as MuiTextField,
  TextFieldProps,
} from '@material-ui/core'
import { FiSearch } from 'react-icons/fi'

const TextField = styled(MuiTextField)({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#c0c0c0',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#c0c0c0',
    },
  },
})

const Search = ({ ...rest }: TextFieldProps) => {
  return (
    <TextField
      {...rest}
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FiSearch fontSize={22} color="#C4C4C4" />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default Search
