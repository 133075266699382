import { Box, LinearProgress, Tooltip, styled } from '@material-ui/core'
import { ContainerPage, TitlePage } from 'components'
import { usePopup } from 'hooks/usePopup'
import { IResponsePackingDict } from 'interfaces/IPackingList'
import moment from 'moment'
import { useState, useEffect, useMemo } from 'react'
import { packingList } from 'services/packingListServices'
import { getDeliveryDate } from 'utils'

import Calendar from '../Breaks/components/CardHistory/components/Calendar'
import Desktop from './Desktop'
import Mobile from './Mobile'

export type IHandleEvent = {
  param: any
  type: 'filter' | 'search' | 'print'
}

export type IParams = {
  search: string
  filter: 'loaded' | 'pending' | 'concluded' | null
}

const sortByRoutes = (a: IResponsePackingDict, b: IResponsePackingDict) => {
  if (a.route < b.route) {
    return -1
  }
  if (a.route > b.route) {
    return 1
  }
  return 0
}

const Alert = styled(Box)({
  display: 'flex',
  width: '100%',
  backgroundColor: '#FFF3CD',
  border: '1px solid #FEE59B',
  padding: '0.6rem 0 0.6rem 1rem',
  borderRadius: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#664D03 ',
  fontWeight: 500,
  margin: '1rem 0',
})

const PackingList = () => {
  const currentDeliveryDate = (() => {
    const { year, month, day } = getDeliveryDate()
    const date = new Date(`${year}-${month}-${day}`)
    date.setHours(date.getHours() + 3)
    return date
  })()

  const { addPopup } = usePopup()
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800)
  const [routes, setRoutes] = useState<IResponsePackingDict[]>([])
  const [params, setParams] = useState<IParams>({ search: '', filter: null })
  const [loading, setLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState(currentDeliveryDate)

  const getRoutes = async (scheduledDate: string) => {
    setLoading(true)
    try {
      const response = await packingList.getRoutes(scheduledDate)

      setRoutes(
        Object.entries(response)
          .map(([_, value]) => value)
          .sort(sortByRoutes),
      )
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao buscar rotas',
        description: err?.message || err?.msg,
      })
    } finally {
      setLoading(false)
    }
  }

  const handlePrintRoute = async (route: number) => {
    setLoading(true)
    try {
      await packingList.printSingleRoute({ route })
      addPopup({
        type: 'success',
        title: `Impressão da rota ${route} realizada com sucesso!`,
      })
    } catch (err) {
      addPopup({
        type: 'error',
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro, contate o administrador',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleEvent = async ({ param, type }: IHandleEvent) => {
    if (type === 'print') {
      handlePrintRoute(param)
      setRoutes(state =>
        state.map(item => (item.route === param ? { ...item, printed: true } : item)),
      )
      return
    }
    if (type === 'filter' && param === params.filter) {
      param = null
    }
    setParams(state => ({ ...state, [type]: param }))
  }

  const filteredRoutes = useMemo(() => {
    const { search, filter } = params
    if (search?.trim()) {
      return routes.filter(route => route.route.toString().includes(search.trim()))
    }

    if (filter) {
      return routes.filter(route => route.status === filter)
    }

    return routes
  }, [params, routes])

  const onSelectedDate = (params: Date) => {
    const formattedDate = moment(params).format('YYYY-MM-DD')
    getRoutes(formattedDate)
  }

  useEffect(() => {
    const formattedDate = moment(selectedDate).format('YYYY-MM-DD')
    getRoutes(formattedDate)

    const updateWidth = (e: any) => {
      setIsMobile(e?.target?.innerWidth < 800)
    }

    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  return (
    <ContainerPage>
      <TitlePage>Romaneios 🚛</TitlePage>
      <LinearProgress style={{ width: '100%', marginBottom: '1rem', opacity: loading ? 1 : 0 }} />
      <Tooltip
        title="Selecione o dia que as rotas serão entregues"
        arrow
        placement="top"
        style={{ display: 'inline-block', marginBottom: '8px' }}
      >
        <div>
          <Calendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            onSelectedDate={onSelectedDate}
            baseFilter="deliveryDate"
          />
        </div>
      </Tooltip>
      {!moment(currentDeliveryDate).isSame(selectedDate) && (
        <Alert>Você está filtrando por uma data diferente do próximo dia de entrega.</Alert>
      )}
      {isMobile ? (
        <Mobile
          routes={filteredRoutes}
          selectedDate={selectedDate}
          handleEvent={handleEvent}
          params={params}
        />
      ) : (
        <Desktop
          routes={filteredRoutes}
          selectedDate={selectedDate}
          handleEvent={handleEvent}
          params={params}
        />
      )}
    </ContainerPage>
  )
}

export default PackingList
