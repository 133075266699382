import { IProductApi } from './IRequestProductApi'

export type IProduct = {
  id: number
  created: Date
  updated: Date
  name: string
  unit_weight: number
  shelf_life: number
  span_sequence: number | null
  sku_reference: number | null
  status: number | null
  purchase_type: number
  seller: any | null
  product_span: number
  img?: string
  image?: string
  sku?: number
}

export interface IProductResponse {
  pageIndex: number
  pageSize: number
  results: IProduct[]
  total: number
}

export type IProductRequest = {
  separationGroup: number
  sku: string
}

export type IConfirmReplacement = {
  status: enStatusReplenishment
  replenishmentId: number
}

export type IReplacementProducts = {
  id: number
  created: Date
  updated: Date
  base_name: string
  sku_name?: string
  track: number
  level: number
  status: 'replenish' | 'broken'
  user: IUser
}

export type IUser = {
  id: number
  username: string
  email: string
  groups: string[]
}

export interface IAmountProductsBySeparationOrder {
  separationOrders: IAmountProduct[]
  results: IProductApi[]
}

export interface IAmountProduct {
  separationOrder: number
  qtdProducts: number
}

export enum enStatusReplenishment {
  AGUARDANDO_RESPOSTA = 'aguardando resposta',
  REABASTECIDO = 'reabastecido',
  QUEBRA = 'quebra',
}
export interface IReplenishment {
  id: number
  created: string
  updated: string
  sku_name: string
  separation_group: number
  status: enStatusReplenishment
  user_request: string
  user_response: string
  time_to_response: string
  scheduled_date: string
}
