import { api, ApiService } from './api'

export interface IProductivity {
  total_orders: number
  total_completed_orders: number
  completed_orders_user: number
  goal_separate: number
}

export interface IProductivityHistory {
  completedOrdersUser: number
  totalCompletedOrders: number
  totalOrders: number
}

class ProductivityService {
  constructor(private readonly api: ApiService) {}

  async getProductivity(): Promise<IProductivity> {
    return this.api.get(carambolaUrl + '/separation/productivity')
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const productivityService = new ProductivityService(api)
