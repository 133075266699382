import { Box, TextField } from '@material-ui/core'
import { useState, useEffect, useMemo } from 'react'
import Cam from 'components/CardProductScanner/Cam'
import { ContainerDataPrePicking, Loading, Text } from './styles'
import { usePopup } from 'hooks/usePopup'
import { naboServices } from 'services/naboServices'
import moment from 'moment'
import { formatValueScanner } from 'utils/formatValueScanner'
import { debaunce } from 'utils'
import { IPrePickingV2 } from 'interfaces/IPrePickingV2'

interface IPrePickingsV2Formatted {
  id: number
  unique_tag: 'Única' | 'Genérica'
  created: string
  sku_id: string
  product_base_id: string
  orders: string
  items: string
  batch_id: string | null
  scale_id: number | null
  item_weight: number | null
  scale_label_id: string | null
  weighing_time: string | null
  purchase_value: number | null
  portion_type: 'Kg' | 'Un'
  has_expired: 'Sim' | 'Não'
}

export type TValue = 'data_matrix' | 'qr_code' | 'bar_code'

export type TValueBip = {
  value: string
  type: TValue
}

const PrePicking = () => {
  const [valueTag, setValueTag] = useState<TValueBip>({ value: '', type: '' as TValue })
  const [prePickingData, setPrePickingData] = useState<IPrePickingV2>(null as any)
  const [loading, setLoading] = useState(false)
  const { addPopup } = usePopup()
  const [inputId, setInputId] = useState('')
  const searchDebaunce = debaunce({ fn: searchPrePicking, delay: 400 })

  const onSubmit = async (value: TValueBip) => {
    try {
      const response = await naboServices.getPrePickingV2({
        productCode: value.value,
        codeType: value.type,
      })
      setPrePickingData(response)
      return
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao buscar pre picking',
        description: err?.message || err?.msg || 'Contate um administrador',
        autoClose: false,
      })
    }
  }

  async function searchPrePicking(value: string) {
    if (!value) return
    try {
      setLoading(true)
      const response = await naboServices.getPrePickingV2({
        productCode: value,
        codeType: 'qr_code',
      })
      setPrePickingData(response)
    } catch (err: any) {
      addPopup({
        title: `Erro ao pegar os dados do Pre Picking #${value}`,
        type: 'error',
        autoClose: false,
        description: err?.message || err?.msg || 'Contate um administrador',
      })
    } finally {
      setLoading(false)
    }
  }

  const formatDate = (date: string) => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss')
  }

  const getOrders = (prePicking: IPrePickingV2): string => {
    const prePickingsOrders = prePicking.orders.reduce((acc: string[], prePickingOrder) => {
      if (!acc.includes(prePickingOrder.order_id)) {
        acc.push(prePickingOrder.order_id)
      }
      return acc
    }, [])

    return prePickingsOrders.join(', ')
  }

  const getOrdersItem = (prePicking: IPrePickingV2): string => {
    const prePickingsOrdersItems = prePicking.orders.reduce((acc: string[], prePickingOrder) => {
      if (!acc.includes(String(prePickingOrder.order_item_id))) {
        acc.push(String(prePickingOrder.order_item_id))
      }
      return acc
    }, [])

    return prePickingsOrdersItems.join(', ')
  }

  const prePickingsFormatted: IPrePickingsV2Formatted = useMemo(() => {
    if (!prePickingData) return null as any

    return {
      ...prePickingData,
      unique_tag: prePickingData?.unique_tag ? 'Única' : 'Genérica',
      created: formatDate(prePickingData?.created),
      product_base_id: prePickingData?.product_base_id,
      orders: getOrders(prePickingData),
      items: getOrdersItem(prePickingData),
      weighing_time: prePickingData?.weighing_time ? formatDate(prePickingData?.weighing_time) : '',
      portion_type: prePickingData?.portion_type === 'kg' ? 'Kg' : 'Un',
      has_expired: prePickingData?.has_expired ? 'Sim' : 'Não',
    } as IPrePickingsV2Formatted
  }, [prePickingData])

  useEffect(() => {
    if (valueTag.value) {
      onSubmit(valueTag)
    }
  }, [valueTag.value])

  return (
    <ContainerDataPrePicking>
      <Loading style={{ opacity: loading ? 1 : 0 }} />
      <Text
        style={{ fontSize: '1.5rem', textAlign: 'center', fontWeight: 500, marginBottom: '1rem' }}
      >
        Bipe ou digite o ID do pré picking
      </Text>

      <TextField
        variant="outlined"
        fullWidth
        style={{ borderRadius: '8px', marginBottom: '1rem' }}
        label="Digite o ID do pré picking"
        type="number"
        value={inputId}
        onChange={e => {
          searchDebaunce(e.target.value)
          setInputId(e.target.value)
        }}
      />

      <Cam
        onSubmit={(value, type) =>
          setValueTag({
            value,
            type,
          })
        }
      />
      {prePickingsFormatted && (
        <Box>
          <Text>
            <strong>ID: </strong>
            {prePickingsFormatted?.id}
          </Text>
          <Text>
            <strong>Tipo de etiqueta: </strong>
            {prePickingsFormatted?.unique_tag}
          </Text>
          <Text>
            <strong>Data de criação: </strong>
            {prePickingsFormatted?.created}
          </Text>
          <Text>
            <strong>Está vencido?: </strong>
            {prePickingsFormatted?.has_expired}
          </Text>
          <Text>
            <strong>ID SKU: </strong>
            {prePickingsFormatted?.sku_id}
          </Text>

          <Text>
            <strong>ID Produto Base: </strong>
            {prePickingData?.product_base_id}
          </Text>
          <Text>
            <strong>ID Pedido Atrelado: </strong>
            {prePickingsFormatted.orders}
          </Text>
          <Text>
            <strong>ID Item Atrelado: </strong>
            {prePickingsFormatted.items}
          </Text>

          <Text>
            <strong>ID do Lote: </strong>
            {prePickingsFormatted?.batch_id}
          </Text>

          {prePickingsFormatted?.scale_id && (
            <>
              <Text>
                <strong>Peso: </strong>
                {prePickingsFormatted?.item_weight}Kg
              </Text>
              <Text>
                <strong>ID da Balança: </strong>
                {prePickingsFormatted?.scale_id}
              </Text>
              <Text>
                <strong>ID unico da Balança: </strong>
                {prePickingsFormatted?.scale_label_id}
              </Text>

              <Text>
                <strong>Data da pesagem: </strong>
                {prePickingsFormatted?.weighing_time}
              </Text>
            </>
          )}

          <Text>
            <strong>Porcionamento SKU: </strong>
            {prePickingsFormatted?.purchase_value}
            {prePickingsFormatted?.portion_type}
          </Text>
        </Box>
      )}
    </ContainerDataPrePicking>
  )
}

export default PrePicking
