import {
  Box,
  Button,
  Checkbox as CheckboxMui,
  Modal,
  Paper,
  Typography,
  makeStyles,
  styled,
} from '@material-ui/core'

export const ModalContainer = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const PaperContainer = styled(Paper)({
  maxWidth: '450px',
  width: '100%',
  padding: '16px',
  margin: '8px',
})

export const Title = styled(Typography)({
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: 500,
  color: '#353535',
})

export const SubTitle = styled(Typography)({
  textAlign: 'start',
  fontSize: '15px',
  fontWeight: 400,
  color: '#353535',
})

export const ButtonCheckbox = styled(Button)({
  border: '2px solid #e5e5e5',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
})

export const Checkbox = styled(CheckboxMui)({
  padding: '4px',
  '&.MuiCheckbox-colorSecondary.Mui-checked': {
    color: '#8ABD24',
  },
})

export const Divider = styled('div')({
  width: '100%',
  height: '2px',
  backgroundColor: '#e5e5e5',
})

export const TextCheckbox = styled(Typography)({
  textTransform: 'none',
  color: '#353535',
})

export const ContainerList = styled(Box)({
  overflowY: 'auto',
  maxHeight: '300px',
  '&::-webkit-scrollbar': {
    width: '8px',
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#A1CA4F',
    borderRadius: '9px',
  },
})

export const ButtonConfirm = styled(Button)({
  width: '100%',
  background: '#8ABD24',
  border: '2px solid #8ABD24',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 500,
  '&:hover': {
    background: '#FFF',
    color: '#8ABD24',
  },
})

export const useStyles = makeStyles({
  animation: {
    animation: '$showIn .3s ease-in',
  },
  '@keyframes showIn': {
    from: {
      opacity: 0,
      transform: 'translateX(-400px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
})
