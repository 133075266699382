import { Box, makeStyles, styled, Typography } from '@material-ui/core'

export const ColorRoute = styled(Box)((theme: { color: string }) => ({
  width: '100%',
  height: '1.5rem',
  backgroundColor: theme.color,
  borderRadius: '8px 8px 0 0',
}))

export const Route = styled(Typography)({
  fontSize: '1.4rem',
  color: '#353535',
  fontWeight: 500,
  borderBottom: '1px solid #C4C4C4',
})

export const TextConferred = styled(Typography)({
  marginLeft: '1rem',
  background: '#89BD23',
  color: '#FFF',
  padding: '0.5rem',
  fontWeight: 500,
  borderRadius: '4px',
  display: 'inline-block',
})

export const useStyles = makeStyles(theme => ({
  player: {
    width: '50%',
    [theme.breakpoints.up(1000)]: {
      width: '55%',
    },
    [theme.breakpoints.up(1300)]: {
      width: '75%',
    },
    [theme.breakpoints.up(1500)]: {
      width: '85%',
    },
  },
  sizeText: {
    fontSize: '4rem !important',
  },
}))
