import { enRules } from 'interfaces/IUser'

import { eUser } from './types'

export interface IUser {
  isAuthenticated: boolean
  userPermissions: enRules[]
  isLoading?: boolean
  userId?: number
}

const initialState: IUser = {
  isAuthenticated: true,
  userPermissions: [],
  isLoading: true,
  userId: 0,
}

export default (state = initialState, action: any): IUser => {
  switch (action.type) {
    case eUser.USER:
      return action.user
    default:
      return state
  }
}
