import {
  ICheckSeparationWorkResponse,
  ISeparationCheckSeparationWork,
} from 'interfaces/ISeparationWorkService'

import { api as apiService, ApiService } from './api'

class SeparationWorkService {
  constructor(private readonly api: ApiService) {}

  public async checkWork(
    data?: ISeparationCheckSeparationWork,
  ): Promise<ICheckSeparationWorkResponse> {
    if (data) {
      return await this.api.get(carambolaUrl + '/separation/separation-work/recovery', {
        params: data,
      })
    }
    return await this.api.get(carambolaUrl + '/separation/separation-work/recovery')
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const separationWorkService = new SeparationWorkService(apiService)
