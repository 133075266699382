import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
  },
  paper: {
    maxWidth: '400px',
    width: '100%',
    padding: '0 1.5rem',
  },
  paperFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
  },
  title: {
    color: '#353535',
    fontWeight: 500,
    marginTop: '2rem',
    fontSize: '1.2rem',
    width: '100%',
  },
  image: {
    margin: '0 auto',
  },
  textProduct: {
    margin: '1rem 0 0.5rem 0 ',
  },
  text: {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#757575',
    marginTop: '1rem',
    '& strong': {
      color: '#353535',
    },
  },
  textQuantity: {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#757575',
    marginTop: '0.5rem',
    marginBottom: '1rem',
    '& strong': {
      color: '#353535',
    },
  },
  helperText: {
    color: '#757575',
    marginBottom: '2rem',
    fontWeight: 400,
    fontSize: '1rem',
  },
  rotateAnimation: {
    animation: '$fadeIn 3s linear infinite',
  },
  button: {
    height: '3rem',
    border: '1px #757575 solid',
    borderRadius: '0.5rem',
    width: '100%',
    marginBottom: '2rem',
    textTransform: 'none',
    color: '#757575',
  },
  containerButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    marginBottom: '2rem',
  },
  buttonGeneral: {
    width: '100%',
    height: '3rem',
  },
  buttonCancel: {
    border: '1px #757575 solid',
    color: '#757575',
  },
  buttonConfirm: {
    backgroundColor: '#89BD23',
    color: 'white',
  },

  '@keyframes fadeIn': {
    '0%': {
      rotate: '0deg',
    },
    '100%': {
      rotate: '360deg',
    },
  },
}))
