import { usePopup } from 'hooks/usePopup'
import { useState } from 'react'

import {
  ButtonCancel,
  ButtonConfirm,
  Container,
  ContainerButtons,
  InfoCostumer,
  Modal,
  TextConfirmRelease,
  Title,
} from './styles'
import { Loading } from 'pages/Admin/Breaks/styles'
import { packingList } from 'services/packingListServices'

interface IModalConfirmPrint {
  open: boolean
  handleClose: () => void
  route: number
}

export const ModalConfirmPrint = ({ handleClose, route, open }: IModalConfirmPrint) => {
  const { addPopup } = usePopup()
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      await packingList.printSingleRoute({ route: route, withoutScanner: true })
      addPopup({
        type: 'success',
        title: 'Romaneio impresso com sucesso',
      })
      handleClose()
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Ocorreu um erro',
        description: error?.message || error,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal open={open}>
        <Container>
          <Loading loading={isLoading} />
          <Title>Imprimir rota {route} sem o leitor</Title>
          <InfoCostumer>
            <p>
              Aoba, não esquece que se imprimir por aqui você está considerando a quantidade de
              caixas informadas em etapas anteriores ao carregamento. Essa ação é recomendada em
              casos extremos de não dar tempo de passar essa rota pelo leitor da esteira.
            </p>
          </InfoCostumer>
          <TextConfirmRelease>Você quer imprimir mesmo assim?</TextConfirmRelease>
          <ContainerButtons>
            <ButtonCancel onClick={handleClose} disabled={isLoading}>
              Quero não
            </ButtonCancel>
            <ButtonConfirm onClick={handleConfirm} disabled={isLoading}>
              Quero
            </ButtonConfirm>
          </ContainerButtons>
        </Container>
      </Modal>
    </>
  )
}
