import { TableRow as TableRowMui, TableRowProps } from '@material-ui/core'
import { memo } from 'react'

import { useStyles } from './styles'

const TableRowComponent = (props: TableRowProps) => {
  const classes = useStyles()
  const { children } = props

  return (
    <TableRowMui {...props} className={classes.root}>
      {children}
    </TableRowMui>
  )
}

const TableRow = memo(TableRowComponent)

export default TableRow
