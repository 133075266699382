import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { IRouteDashboard, TRouteData } from 'interfaces/IDashboard'
import { Modal } from 'shared'
import { useStyles } from './styles'
import { mapTagColor } from 'utils/mapTagColor'

interface IModalRoute {
  handleClose: () => void
  open: boolean
  data: IRouteDashboard
}

export const ModalRoute = ({ data, ...props }: IModalRoute) => {
  const classes = useStyles()

  const defineColor = (routeData: TRouteData) => {
    if (routeData.need_reduce && routeData.is_reduced === false) {
      return { color: 'red' }
    }
    if (routeData.need_reduce && routeData.is_reduced === null) {
      return { color: '#9e8903' }
    }
    return { color: 'green' }
  }

  return (
    <Modal {...props} title="Detalhes das rotas" rightButton style={{ height: '523px' }}>
      <Typography align="center">{data.total_routes_in_carambola} Rotas</Typography>
      <Typography align="center" style={{ marginTop: '8px', fontSize: '0.8rem' }}>
        Atualizado às: {data.updated_in}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Rota</TableCell>
            <TableCell align="center">Cor</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Estourou?</TableCell>
            <TableCell align="center">Foi reduzida?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.data_routes?.map(routeData => {
            return (
              <TableRow key={routeData.route} className={classes.tableRow}>
                <TableCell align="center">{routeData.route}</TableCell>
                <TableCell align="left">
                  <svg width="10" height="10" style={{ marginRight: '0.5rem' }}>
                    <circle cx="5" cy="5" r="5" fill={mapTagColor(routeData.tagColor)}></circle>
                  </svg>
                  {routeData.tagColor}
                </TableCell>
                <TableCell align="center">{routeData.status}</TableCell>
                <TableCell align="center" style={defineColor(routeData)}>
                  {routeData.need_reduce ? 'SIM' : 'NÃO'}
                </TableCell>
                <TableCell align="center" style={defineColor(routeData)}>
                  {!routeData.need_reduce
                    ? '-'
                    : routeData.is_reduced === null
                    ? 'EM ANÁLISE'
                    : routeData.is_reduced === false
                    ? 'NÃO'
                    : 'SIM'}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Modal>
  )
}
