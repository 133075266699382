import { Box, Typography } from '@material-ui/core'
import { Button, ContainerPage } from 'components'
import { ReturnArea } from 'components/pages/Config/'
import { padding } from 'polished'
import { useState } from 'react'
import { FaCog } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { ISettingsRequest } from 'services/settingsService'
import { IState } from 'store'

import { Edit } from './Edit'
import { useStyles } from './styles'

interface IDialogEditPalletizing {
  isOpen: boolean
  data: {
    palletLimitWeight?: number
    palletLimitWholesaleBox?: number
  }
}

export const Palletizing = () => {
  const classes = useStyles()
  const settings = useSelector<IState, ISettingsRequest>(state => state.settings)
  const [dialogEditPalletizing, setDialogEditPalletizin] = useState<IDialogEditPalletizing>({
    isOpen: false,
    data: {},
  })

  const handleOpenEdit = () => {
    setDialogEditPalletizin({
      isOpen: true,
      data: {
        palletLimitWeight: settings.palletLimitWeight,
        palletLimitWholesaleBox: settings.palletLimitWholesaleBox,
      },
    })
  }

  return (
    <>
      <ContainerPage>
        <ReturnArea title="Paletização" />

        <Box className={classes.boxAlign}>
          <Button onClick={handleOpenEdit} className={classes.configs}>
            <FaCog size={'1rem'} style={{ marginRight: '0.6rem' }} /> Editar
          </Button>
        </Box>

        <Box className={classes.containner}>
          <Box>
            <Typography className={classes.textHelper}>Pedidos com peso mínimo:</Typography>
            <Typography className={classes.textWarning}>
              {settings.palletLimitWeight || '-'} kg
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.textHelper} style={{ marginTop: '1.65rem' }}>
              Pedidos com caixa de atacado mínimo:
            </Typography>
            <Typography className={classes.textWarning}>
              {settings.palletLimitWholesaleBox || '-'} cx
            </Typography>
            <Typography>
              *Observação: Refere-se a quantidade de SKUs relacionado a caixa.
            </Typography>
          </Box>
        </Box>
      </ContainerPage>

      <Edit
        setIsOpen={setDialogEditPalletizin}
        isOpen={dialogEditPalletizing.isOpen}
        data={dialogEditPalletizing.data}
      />
    </>
  )
}
