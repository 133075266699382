import { ListItem, Typography } from '@material-ui/core'
import { Dialog, List, Text, Title } from './styles'
import { ImQrcode } from 'react-icons/im'
import { FormEvent, useEffect, useRef } from 'react'
import { InputBoxes } from '../ModalError/styles'

interface IData {
  route: string
  orders: any[]
  color_route: string
}

interface IProps {
  open: boolean
  onScan: (value: string) => void
  data: IData
}

const ModalErrorRoute = ({ onScan, open, data }: IProps) => {
  const input = useRef<HTMLInputElement>(null)
  const setIntervalRef = useRef<ReturnType<typeof setInterval>>()

  const handleSubmitScanner = async (param: FormEvent) => {
    const formData = new FormData(param.target as HTMLFormElement)
    param.preventDefault()
    const inputValue = formData.get('inputValue')
    onScan(inputValue as string)
    if (input.current) {
      input.current.value = ''
    }
  }

  useEffect(() => {
    setIntervalRef.current = setInterval(() => {
      if (input.current) {
        input.current.focus()
      }
    }, 100)
    return () => {
      if (setIntervalRef.current) {
        clearInterval(setIntervalRef.current)
      }
    }
  }, [])

  return (
    <Dialog open={open} colorRoute={data.color_route}>
      <Title variant="h1">Existem pedidos pendentes da rota {data.route}</Title>
      <Text style={{ textAlign: 'center' }}>
        Continua a conferência dos pedidos pendentes.<br></br>Passe o QRCODE de um dos pedidos
        pendentes no leitor
      </Text>
      <ImQrcode size={100} color="#757575" style={{ margin: '0 auto' }} />
      <Text style={{ marginBottom: '0.5rem' }}>Pedidos pendentes </Text>
      <List>
        {data.orders.map(order => (
          <ListItem key={order.order_id}>
            {order.message ? (
              <Typography>{order.message}</Typography>
            ) : (
              <Typography>#{order.order_id}</Typography>
            )}
          </ListItem>
        ))}
      </List>
      <form
        onSubmit={e => {
          handleSubmitScanner(e)
        }}
        style={{ position: 'absolute', top: 0, left: 0 }}
      >
        <InputBoxes
          style={{ position: 'absolute', opacity: 0 }}
          hidden
          inputRef={input}
          name="inputValue"
          type="text"
        />
      </form>
    </Dialog>
  )
}

export default ModalErrorRoute
