import { Box, Button, Typography } from '@material-ui/core'
import confirmImg from 'assets/images/confirm.svg'
import confirmBranchImg from 'assets/images/confirmBranch.svg'
import separationImg from 'assets/images/separation.svg'
import { IProduct } from 'interfaces/IProduct'
import { useState, useMemo } from 'react'

import { useStyles } from './styles'

interface Props extends IProduct {
  isBreaches?: boolean
}

const CardProductSeparated = (props: Props) => {
  const {
    name,
    instructions,
    status,
    image,
    pre_boxed,
    isBreaches,
    id,
    converted_quantity,
    unity_product_base,
    confirmed_quantity,
  } = props
  const classes = useStyles()
  const [limitText, setLimitText] = useState(false)
  const toggleLimitText = () => setLimitText(state => !state)
  const isConfirm = status === 2 || status === 3

  const { isUnity, quantity } = useMemo(() => {
    const isUnity = pre_boxed || unity_product_base === 'unidade'
    const quantity = pre_boxed ? props?.quantity : converted_quantity || props?.quantity

    return { isUnity, quantity }
  }, [id])

  return (
    <Box className={classes.cardSeparated}>
      <Box className={classes.header}>
        <Box className={classes.imageStyle}>
          <div className={`${classes.image} ${isConfirm && classes.imageConfimed}`}>
            <img src={image || separationImg} alt="Imagem do produto" />
          </div>

          <div className={classes.infos}>
            <Typography className={isConfirm ? classes.titleActive : classes.title}>
              {name}
            </Typography>

            <Typography className={classes.quantity}>
              Qnt:
              <strong> {`${quantity} ${isUnity ? 'Unidade' : 'Kg'}`}</strong> -{' '}
              <strong style={{ color: '#A1CA4F' }}>{`${
                confirmed_quantity ? confirmed_quantity.toFixed(2) : quantity.toFixed(2)
              } ${isUnity ? 'Unidade' : 'Kg'}`}</strong>
            </Typography>
          </div>
        </Box>

        <img
          src={status === 2 ? confirmImg : confirmBranchImg}
          className={classes.confirmIcon}
          alt="Confirmado"
        />
      </Box>

      {instructions && (
        <Typography variant="subtitle1" className={classes.obs}>
          <span className={limitText ? undefined : classes.strong}>
            {`Obs: ${instructions}`}{' '}
            {limitText && (
              <strong className={classes.noStyleButton} onClick={toggleLimitText}>
                Ver menos
              </strong>
            )}
          </span>
          {!limitText && (
            <strong className={classes.noStyleButton} onClick={toggleLimitText}>
              Ver mais
            </strong>
          )}
        </Typography>
      )}
    </Box>
  )
}

export default CardProductSeparated
