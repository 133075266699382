import { usePopup } from 'hooks/usePopup'
import { useRef, useState } from 'react'

import {
  ButtonCancel,
  ButtonConfirm,
  Container,
  ContainerButtons,
  InfoCostumer,
  Modal,
  TextConfirmRelease,
  Title,
} from './styles'
import { Loading } from 'pages/Admin/Breaks/styles'
import { IModalOrderCanceled, ModalOrderCanceled } from '../ModalOrderCanceled'
import { IOrder } from 'interfaces/IOrder'
import { orderService } from 'services/orderService'

interface IModalCanceledFinanceOrder {
  open: boolean
  handleClose: () => void
  order: IOrder
  pollingInvoice: (isError: boolean, isRefresh: boolean) => Promise<void>
}

export const ModalCanceledFinanceOrder = ({
  handleClose,
  order,
  pollingInvoice,
  open,
}: IModalCanceledFinanceOrder) => {
  const { addPopup } = usePopup()
  const [isLoading, setIsLoading] = useState(false)
  const modalOrderCanceledRef = useRef<IModalOrderCanceled>(null)
  const clientName = order?.client_data?.client_trade || order?.client_data?.client_name

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      await orderService.updateOrder({ newOrderStatus: 'cancelado', ordersId: [order.order_id] })
      await pollingInvoice(false, true)
      addPopup({
        type: 'success',
        title: 'Pedido cancelado com sucesso',
      })
      handleClose()
    } catch (error: any) {
      if (error?.message === 'This order has been canceled') {
        modalOrderCanceledRef.current?.onOpen()
        return
      }
      addPopup({
        type: 'error',
        title: 'Ocorreu um erro',
        description: error?.message || error,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal open={open}>
        <Container>
          <Loading loading={isLoading} />
          <Title>Cancelar pedido</Title>
          <InfoCostumer>
            <p>
              Cliente: <strong>{clientName} </strong>
            </p>
            <p>
              N° Pedido: <strong>#{order?.order_id}</strong>
            </p>
          </InfoCostumer>
          <TextConfirmRelease>Confirmar o cancelamento desse pedido?</TextConfirmRelease>
          <ContainerButtons>
            <ButtonCancel onClick={handleClose} disabled={isLoading}>
              Cancelar
            </ButtonCancel>
            <ButtonConfirm onClick={handleConfirm} disabled={isLoading}>
              Confirmar
            </ButtonConfirm>
          </ContainerButtons>
        </Container>
      </Modal>
      <ModalOrderCanceled
        orderId={order?.order_id}
        clientName={clientName}
        callback={handleClose}
        ref={modalOrderCanceledRef}
      />
    </>
  )
}
