import { Information, UserNav } from 'components'
import { SendHelp } from 'components/SendHelp/Index'
import { useLocation } from 'react-router'

import { useStyles } from './styles'

export const Header = () => {
  const { pathname } = useLocation()
  const separationLocation: string[] = ['etapa', 'pedido']
  const classes = useStyles()

  const isSeparating = separationLocation.some(element => pathname.includes(element))

  return (
    <header
      className={`${isSeparating} ? ${classes.header} && ${classes.updatedHeader} : ${classes.header}`}
      data-testid="header"
    >
      <div className={classes.container}>
        <Information isSeparating={isSeparating} />

        <div className={classes.signout}>
          <SendHelp />
          <UserNav theme="light" />
        </div>
      </div>
    </header>
  )
}
