import { Dialog, Typography } from '@material-ui/core'
import { Button, ModalChoicePrinter, ModalReprinter } from 'components'
import Cam from 'components/CardProductScanner/Cam'
import { InvisibleInput } from 'components/CardProductScanner/styles'
import { usePopup } from 'hooks/usePopup'
import { ISeparationGroupConfig } from 'interfaces/ISeparationGroupService'
import {
  useState,
  useEffect,
  FormEvent,
  useRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { BsCircleFill } from 'react-icons/bs'
import { ImQrcode } from 'react-icons/im'
import { useSelector } from 'react-redux'
import { IPrint, printerService } from 'services/printerService'
import { IState } from 'store'

import { useStyles, buttonStyle } from './styles'

interface IContentProps {
  open: boolean
  handleConfirmOrder: (data: { boxesNumber: number }) => Promise<void>
  onClose: () => void
  openModalLoading: () => void
  closeModalLoading: () => void
}

const Content = ({
  handleConfirmOrder,
  open,
  onClose: closeModal,
  closeModalLoading,
  openModalLoading,
}: IContentProps) => {
  const classes = useStyles()
  const {
    settings,
    infos: { runningMachine },
    orderFastTrack: { order: orderData },
  } = useSelector<IState, IState>(state => state)
  const separationGroup = settings.separationGroups.find(
    ({ separation_group }) => Number(separation_group) === Number(runningMachine),
  ) as ISeparationGroupConfig
  const { cam_scanner, infrared_scanner } = separationGroup
  const [openModalReprinter, setOpenModalReprinter] = useState(false)
  const [modalPrinter, setModalPrinter] = useState(false)
  const [valueQrCode, setValueQrCode] = useState<string>('')
  const { addPopup } = usePopup()
  const inputRef = useRef<HTMLInputElement>(null)
  const intervalRef = useRef<any>(null)
  const buttonClasses = buttonStyle({
    color: orderData?.color_route,
  })

  const handleReprint = async () => {
    try {
      await printerService.print({
        orderId: orderData.order_id,
        reprint: true,
      } as IPrint)

      addPopup({
        type: 'success',
        title: 'Reimpressão enviada! 🤠',
      })
    } catch {
      addPopup({
        type: 'error',
        title: 'Deu erro pra mandar a impressão 😞',
        description: 'Dá um alô pro time de tech',
      })
    }
  }

  const setQrCode = (valueQr: string) => {
    setValueQrCode(valueQr)
  }

  const handleBeepTag = async (valueQr: string) => {
    try {
      const [orderIdScanned] = valueQr.split(';')
      openModalLoading()
      await new Promise(resolve => setTimeout(resolve, 1000))
      if (orderIdScanned === orderData.order_id) {
        addPopup({
          type: 'success',
          title: 'É esse pedido mesmo! 🤠',
        })
        await handleConfirmOrder({ boxesNumber: orderData.boxes_number })
        closeModal()
      } else {
        addPopup({
          type: 'error',
          title: 'Opa, esse etiqueta é de outro pedido. 😞',
          description: 'Verifica se pegou a etiqueta certa.',
        })
      }
    } catch {
      addPopup({ type: 'error', title: 'Erro ao confirmar pedido e número de caixas' })
    } finally {
      setValueQrCode('')
      closeModalLoading()
    }
  }

  const handleOpenModalReprint = () => {
    if (orderData.color_route === 'BRANCA' && orderData.is_first_order) {
      setOpenModalReprinter(true)
      return
    }
    setModalPrinter(true)
  }

  const handleSubmitReprintForm = async (printerChoice: number) => {
    try {
      await printerService.print({
        orderId: orderData.order_id,
        printerChoice,
        reprint: true,
      } as IPrint)

      addPopup({
        type: 'success',
        title: 'Impressão enviada! 🤠',
      })
      setOpenModalReprinter(false)
    } catch (error: any) {
      if (error?.message?.includes('já foram impressa')) {
        addPopup({
          type: 'info',
          title: 'As etiquetas já foram impressas.',
          description: 'Caso cê queira, pede reimpressão.',
        })
        return
      }
      addPopup({
        type: 'error',
        title: 'Deu erro pra mandar a impressão 😞',
        description: 'Dá um alô pro time de tech',
      })
    }
  }

  const handleScanScanner = (param: FormEvent<HTMLElement>) => {
    const formData = new FormData(param.target as HTMLFormElement)
    param.preventDefault()
    const inputValue = formData.get('inputValue')
    setQrCode(String(inputValue))
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  useEffect(() => {
    valueQrCode && handleBeepTag(valueQrCode)
  }, [valueQrCode])

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (inputRef.current) {
        inputRef.current?.focus()
      }
    }, 1000)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  return (
    <>
      <Dialog className={classes.dialog} open={open}>
        <Typography className={classes.title}>Opa, bora conferir a etiqueta do pedido?</Typography>
        <Typography
          style={{
            margin: '1.5rem 0 1rem 0',
            textAlign: 'center',
          }}
        >
          Bipa uma das etiquetas que você imprimiu pra gente saber se tá certinho. 🤠
        </Typography>
        <Typography
          style={{
            margin: '0.5rem 0 0.5rem 0',
            textAlign: 'center',
          }}
        >
          <strong>N° do pedido: {orderData?.order_id ?? 'Desconhecido'} - </strong>
          {
            <>
              <BsCircleFill className={buttonClasses.buttonColor} />
              <strong className={classes.strong}>{orderData?.color_route ?? 'Desconhecido'}</strong>
            </>
          }
        </Typography>
        {cam_scanner && <Cam onSubmit={setQrCode} />}
        {infrared_scanner && (
          <form onSubmit={handleScanScanner}>
            <InvisibleInput type="text" inputRef={inputRef} hidden name="inputValue" />
            <ImQrcode
              size={150}
              color="#757575"
              style={{ margin: '2rem auto 0 auto', display: 'block' }}
            />
          </form>
        )}

        <Typography
          style={{
            margin: '1rem 0 1rem 0',
            textAlign: 'center',
          }}
        >
          Preocupa não que se tiver certinho o pedido finaliza na hora! 😉
        </Typography>
        <Button onClick={handleOpenModalReprint} type="submit" className={classes.button}>
          {'Reimprimir etiqueta'}
        </Button>
        <ModalReprinter
          open={modalPrinter}
          confirm={handleReprint}
          handleClose={() => {
            setModalPrinter(false)
          }}
        />
        <ModalChoicePrinter
          open={openModalReprinter}
          confirm={handleSubmitReprintForm}
          handleClose={() => {
            setOpenModalReprinter(false)
          }}
        />
      </Dialog>
    </>
  )
}

export interface IDialogScanTag {
  onOpen: () => void
  onClose: () => void
}

const Component: ForwardRefRenderFunction<
  IDialogScanTag,
  Omit<IContentProps, 'open' | 'onClose'>
> = (props, ref) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  useImperativeHandle(ref, () => ({ onOpen, onClose }))

  return (
    <Dialog className={classes.dialog} open={open}>
      <Content open={open} onClose={onClose} {...props} />
    </Dialog>
  )
}

export const DialogScanTag = forwardRef(Component)
