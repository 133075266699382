import { Box, LinearProgress, Typography } from '@material-ui/core'
import { Button, ContainerPage } from 'components'
import { useRanking } from 'hooks/useRanking'
import { useNavigate } from 'react-router'

import { Classified } from './Classified'
import { useStyles } from './styles'

export const Ranking = () => {
  const classes = useStyles()
  const { dailyRanking, weeklyRanking, dailyLoading, weeklyLoading } = useRanking()
  const navegate = useNavigate()

  const handleRedirectUser = () => {
    navegate('../ranking-filtered')
  }

  const DateFormatter = (updated: string) => {
    const timePart = updated.split('T')[1]
    const [hours, minutes] = timePart.split(':')

    return <span>{`${hours}:${minutes}`}</span>
  }

  return (
    <ContainerPage>
      <Typography className={classes.title}>🏆 Top separadores 🏆</Typography>

      <Box className={classes.centralizeButton}>
        <Box className={classes.boxAlign}>
          <Box className={classes.rankings}>
            {dailyLoading && <LinearProgress variant="indeterminate" />}

            {dailyRanking?.ranking?.length ? (
              <Box>
                <Typography className={classes.subtitle}>Diário</Typography>
                <Typography className={classes.updatedIn}>
                  Atualizado às: {DateFormatter(dailyRanking.updated)}
                </Typography>

                {dailyRanking.ranking.slice(0, 10).map((separator, index) => (
                  <Classified key={index} position={index + 1} separator={separator} />
                ))}
              </Box>
            ) : (
              <Typography className={classes.subtitle}>Sem registros Diários </Typography>
            )}
          </Box>
          <Box className={classes.rankings}>
            {weeklyLoading && <LinearProgress variant="indeterminate" />}

            {weeklyRanking?.ranking?.length ? (
              <Box>
                <Typography className={classes.subtitle}>Semanal</Typography>
                <Typography className={classes.updatedIn}>
                  Atualizado às: {DateFormatter(weeklyRanking.updated)}
                </Typography>

                {weeklyRanking.ranking.slice(0, 10).map((separator, index) => (
                  <Classified key={index} position={index + 1} separator={separator} />
                ))}
              </Box>
            ) : (
              <Typography className={classes.subtitle}>Sem registros Semanais</Typography>
            )}
          </Box>
        </Box>
        <Button onClick={handleRedirectUser} variant="contained" className={classes.redirectUser}>
          Ver ranking de outros dias
        </Button>
      </Box>
    </ContainerPage>
  )
}
