import { Checkbox as MuiCheckbox } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { transparentize } from 'polished'

const Checkbox = styled(MuiCheckbox)({
  color: '#89bd23',
  '&.Mui-checked': {
    color: '#89bd23',
  },
  '&.MuiIconButton-root:hover': {
    backgroundColor: transparentize(0.9, '#BF98FF'),
  },
})

export default Checkbox
