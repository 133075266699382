import { IGetBreakReports } from "services/breakService";
import { ContainerHeader, ContainerTable, Modal, Paper, Title } from "./styles";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'

interface IProps {
    open: boolean;
    onClose: () => void;
    data: IGetBreakReports
}

const ModalBreakDetail = (props: IProps) => {

    return (
        <Modal open={props.open} onClose={props.onClose}>
            <Paper>
                <ContainerHeader>
                    <Title>
                        Quebras: {props.data.username.replace('.', ' ')}
                    </Title>
                    <IconButton

                        onClick={props.onClose}
                    >
                        <CloseIcon style={{ fontSize: '1.4rem' }} />
                    </IconButton>
                </ContainerHeader>

                <ContainerTable>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Pedido</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell>Motivo</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.data.products.map((product, index) => (
                                <TableRow key={index * 9}>
                                    <TableCell>{product.order_id}</TableCell>
                                    <TableCell>{product.product.name}</TableCell>
                                    <TableCell>{product.reason.name}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </ContainerTable>
            </Paper>
        </Modal>
    )
}

export default ModalBreakDetail;