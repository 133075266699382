import { IconButton, ListItemIcon, ListItemText, MenuItem } from "@material-ui/core"
import { IOption, IRouteOverResponseExt } from "../../useRoutesOver"
import { MenuPopoverStyled } from "../../styles"
import { MdOutlineMoreHoriz } from "react-icons/md"
import { useRef, useState } from "react"

interface IProps {
  options: IOption[]
  route: IRouteOverResponseExt
}

const MenuItemRoutesOver = ({options, route}: IProps) => {
  const [popover, setPopover] = useState(false)
  const optionsRef = useRef(null)

  const togglePopover = (e?: any) => {
    e?.stopPropagation()
    setPopover(state => {
      if (!state) {
        setRef(e?.currentTarget)
      }
      return !state
    })
  }

  const setRef = (instance: HTMLButtonElement) => {
    optionsRef.current = instance as any
  }

  return (
    <>
      <IconButton onClick={togglePopover}>
        <MdOutlineMoreHoriz color="#414141" size={22} />
      </IconButton>
        <MenuPopoverStyled
          open={popover}
          onClose={togglePopover}
          anchorEl={optionsRef?.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {options.map(option => {
            const { icon: Icon, action, title } = option

            return (
              <MenuItem
                key={title}
                button
                onClick={e => {
                  togglePopover(e)
                  action(route)
                }}
              >
                <ListItemIcon style={{ minWidth: 40 }}>
                  <Icon size={20} />
                </ListItemIcon>
                <ListItemText primary={title} />
              </MenuItem>
            )
          })}
        </MenuPopoverStyled>
    </>

  )

}

export default MenuItemRoutesOver
