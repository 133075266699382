import { IPopupMessage } from 'hooks/usePopup'
import { useTransition } from 'react-spring'

import { Popup } from './Popup'
import { useStyles } from './styles'

type IPopupContainerProps = {
  messages: IPopupMessage[]
}

export const PopupContainer = ({ messages }: IPopupContainerProps) => {
  const classes = useStyles()

  const transition = useTransition(messages, {
    keys: message => message.id,
    from: { right: '-120%', opacity: 0 },
    enter: () => [
      { right: '2%', opacity: 1 },
      { right: '-2%' },
      { right: '2%' },
      { right: '0%' },
    ],
    leave: () => [{ right: '3%' }, { right: '-120%', opacity: 0 }],
    trail: 100,
    config: { duration: 100 },
  })

  return (
    <div className={classes.popupContainer}>
      {transition((style, popup) => (
        <Popup style={style} message={popup} />
      ))}
    </div>
  )
}
