import { IOrder } from 'interfaces/IOrder'

import { IWork } from '../../../interfaces/ISeparation'
import { eOrder } from './types'

export interface IOrderFastTrackReducer {
  order: IOrder
  work: IWork
  previousOrder: IOrder
}

const initialState = {} as IOrderFastTrackReducer

interface actionProps {
  data: IOrderFastTrackReducer | IOrder | IWork
  type: eOrder
}

export default (state = initialState, action: actionProps): IOrderFastTrackReducer => {
  switch (action.type) {
    case eOrder.CLEAR_PREVIOUS_ORDER:
      return {
        ...state,
        previousOrder: {} as IOrder,
      }
    case eOrder.CLEAR_WORK:
      return {
        ...state,
        work: {} as IWork,
      }
    case eOrder.CLEAR_ORDER:
      return {
        ...state,
        order: {} as IOrder,
      }

    case eOrder.UPDATE_ORDER:
      return {
        ...state,
        order: action.data as IOrder,
      }
    case eOrder.UPDATE_WORK:
      return {
        ...state,
        work: action.data as IWork,
      }
    case eOrder.UPDATE_PREVIOUS_ORDER:
      return {
        ...state,
        previousOrder: action.data as IOrder,
      }
    case eOrder.CLEARFASTTRACKORDER:
      return initialState
    default:
      return state
  }
}
