import { makeStyles } from '@material-ui/core'
import { shade } from 'polished'

interface Props {
  isDelete?: boolean
}

export const useStyles = makeStyles<Props>({
  root: {
    padding: '0.4rempx 0.5rem',
    minWidth: '7rem',
    maxHeight: '50px',
    width: 'initial',
    textTransform: 'capitalize',
    fontSize: '0.875rem',
    backgroundColor: (props: Props) => {
      return props.isDelete ? '#d46e67' : undefined
    },
    '&:hover': {
      backgroundColor: (props: Props) => {
        return props.isDelete ? shade(0.1, '#d46e67') : undefined
      },
    },
  },
  loading: {
    color: '#BF98FF',
  },
  success: {
    backgroundColor: 'transparent',
  },
  fullScreen: {
    '@media(max-width: 400px)': {
      width: '100%',
    },
  },
  screenDefault: {
    flex: 'none',
  },
})
