export enum enRules {
  Tech = 'Tech',
  SeparationDashboardView = 'Separation Dashboard View',
  SeparationCheck = 'Separation Check',
  SeparationFixedReader = 'Separation Fixed Reader',
  SeparationCheckCriticalOrder = 'Separation Critical Order Check',
  SeparationCriticalOrBeginner = 'Separation Critical or Beginner',
  SeparationBreakOrderManagement = 'Separation Break Order Management',
  SeparationOrdersManagement = 'Separation Orders Management',
  SeparationDataAnalysis = 'Separation Data Analysis',
  SeparationInvoiceRelease = 'Separation Invoice Release',
  SeparationReleasePackingList = 'Separation Release Packing List',
  SeparationReplenishmentResponse = 'Separation Replenishment Response',
  SeparationUsersView = 'Separation Users View',
  SeparationShipmentManagement = 'Separation Shipment Management',
  SeparationPrintShipmentWithoutScanner = 'Separation Print Shipment Without Scanner',
  SeparationRankingView = 'Separation Ranking View',
  SeparationPrePickingConsult = 'Separation Pre Picking Consult',
  SeparationConfigsManagement = 'Separation Configs Management',
  SeparationReprintRoutes = 'Separation Reprint Routes',
  SeparationGetToken = 'Separation Get Token',
}

export const permissionsAplication = {
  [enRules.Tech]: 'Tecnologia',
  [enRules.SeparationDashboardView]: 'Visualizador do Dashboard',
  [enRules.SeparationCheck]: 'Conferente de Pedidos',
  [enRules.SeparationBreakOrderManagement]: 'Gerenciador de Pedidos com Quebra',
  [enRules.SeparationOrdersManagement]: 'Visualizador do Dashboard',
  [enRules.SeparationInvoiceRelease]: 'Verificador de NF',
  [enRules.SeparationDataAnalysis]: 'Analisador de Dados',
  [enRules.SeparationReleasePackingList]: 'Liberador de Romaneios',
  [enRules.SeparationReplenishmentResponse]: 'Reabastecedor',
  [enRules.SeparationUsersView]: 'Visualizador de Usuários',
  [enRules.SeparationShipmentManagement]: 'Gerenciador de Romaneios',
  [enRules.SeparationPrintShipmentWithoutScanner]: 'Gerenciador de Romaneios sem leitor',
  [enRules.SeparationRankingView]: 'Visualizador do Ranking',
  [enRules.SeparationPrePickingConsult]: 'Verificador de Pre Picking',
  [enRules.SeparationConfigsManagement]: 'Configurador da Separação',
  [enRules.SeparationFixedReader]: 'Leitor Fixo do Carregamento',
  [enRules.SeparationCheckCriticalOrder]: 'Conferência de Pedidos Críticos',
  [enRules.SeparationCriticalOrBeginner]: 'Crítico / Novato',
  [enRules.SeparationReprintRoutes]: 'Reimpressão por rota',
  [enRules.SeparationGetToken]: 'Token do carregamento',
}

export interface IGroup {
  id: number
  name: enRules
  value?: string
}

export const permissionsChildren = [
  {
    main: 'Separation',
    children: [enRules.SeparationCriticalOrBeginner],
  },
]

export interface IUser {
  user_login: string
  user_groups_names: enRules[]
  user_id?: number
  username: string
  email?: string
}

export const routeConfig = {
  painelRules: [
    enRules.Tech,
    enRules.SeparationDashboardView,
    enRules.SeparationBreakOrderManagement,
    enRules.SeparationOrdersManagement,
    enRules.SeparationInvoiceRelease,
    enRules.SeparationDataAnalysis,
    enRules.SeparationReleasePackingList,
    enRules.SeparationReplenishmentResponse,
    enRules.SeparationUsersView,
    enRules.SeparationShipmentManagement,
    enRules.SeparationRankingView,
    enRules.SeparationConfigsManagement,
    enRules.SeparationPrePickingConsult,
  ],
}
