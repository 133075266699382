import { makeStyles } from '@material-ui/core/styles'
import { shade } from 'polished'

export const useStyles = makeStyles({
  cardSeparated: {
    width: '100%',
    background: '#FAFAFA',
    boxShadow:
      '0px 2px 4px rgba(160, 160, 160, 0.14), 0px 1px 5px rgba(160, 160, 160, 0.2), 0px 3px 4px rgba(160, 160, 160, 0.12)',
    borderRadius: '4px',
    padding: '1rem 0.5rem',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  imageStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    width: '4rem',
    height: '4rem',
    borderRadius: '3rem',
    overflow: 'hidden',
    background: '#EAEAEA',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '90%',
    },
  },
  imageConfimed: {
    background: '#ffffff',
  },
  confirmed: {
    background: shade(0.02, '#F5F5F5'),
    border: '0.5px solid #C4C4C4',
  },
  infos: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '1.2rem',
  },
  titleActive: {
    color: '#353535',
    fontSize: '0.875rem',
  },
  title: {
    color: '#353535',
    fontSize: '1.3rem',
  },
  quantity: {
    fontSize: '1rem',
    color: '#757575',
  },
  editButton: {
    marginRight: '1.2rem',
  },
  editIcon: {
    color: '#757575',
  },
  confirmIcon: {
    padding: '.8rem',
  },
  observationBox: {
    display: 'flex',
  },
  obs: {
    marginTop: '0.5rem',
    fontSize: '1rem',
    display: 'flex',
    align: 'flex-end',
  },
  strong: {
    flex: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    'line-clamp': '1',
    '-webkit-box-orient': 'vertical',
  },
  noStyleButton: {
    flex: 1,
    fontSize: '1rem',
    fontWeight: 500,
    color: '#353535',
    cursor: 'pointer',
  },
  buttonReplacement: {
    width: '100%',
    color: '#6E8247',
    borderColor: '#6E8247',
    marginTop: '1rem',
  },
})
