import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    width: '23.5rem',
    height: '8rem',
    background: '#FFFFFF',
    padding: '0.7rem',
    borderRadius: '0.7rem',
    boxShadow:
      '0px 3px 3px rgba(160, 160, 160, 0.14), 0px 3px 4px rgba(160, 160, 160, 0.12), 0px 1px 8px rgba(160, 160, 160, 0)',
    '& > span': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    marginLeft: '1rem',
  },
  boxImage: {
    height: '3.7rem',
    minWidth: '3.7rem',
    backgroundColor: '#618619',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxImageScanner: {
    height: '3.7rem',
    minWidth: '3.7rem',
    background: 'linear-gradient(261.08deg, #3b6c09 5.76%, #384e0d 87.46%), #C4C4C4',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxImageNoScanner: {
    height: '3.7rem',
    minWidth: '3.7rem',
    background: 'linear-gradient(261.08deg, #9724BD 5.76%, #540070 87.46%), #C4C4C4',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '55%',
    marginBottom: '0.3rem',
    position: 'absolute',
    color: '#FFF',
    fontSize: '100rem',
    height: '100%',
  },
  boxInformations: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '0.5rem',
  },
  boxTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.1rem',
    lineHeight: '1.37rem',
    letterSpacing: '0.01em',
    color: '#353535',
  },
  boxSubtitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.35rem',
    letterSpacing: '0.01em',
    color: '#757575',
  },
})
