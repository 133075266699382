import {
  TableCell,
  TableSortLabel,
  styled,
  TableCellProps,
  TableSortLabelProps,
} from '@material-ui/core'
import { IOnChange } from 'interfaces/IPagination'
import { memo, ReactNode, useCallback, useMemo } from 'react'

type Props = TableCellProps &
  TableSortLabelProps & {
    paginationParams?: {
      orderBy?: string
      orderDirection?: string
    }
    children: ReactNode
    column: string
    type?: 'checkbox'
    onChange?: (value: IOnChange) => void
  }

const TableCellStyle = styled(TableCell)({
  padding: '0.9rem 1rem',
  border: 'none',
  borderBottom: '1px solid #C4C4C4',
})

const TableSortLabelStyle = styled(TableSortLabel)({
  color: '#757575',
  padding: 0,
  '&.MuiTableSortLabel-active': {
    color: '#353535',
  },
  '&:hover': {
    background: 'transparent',
  },
})

const TableCellHeadComponent = (props: Props) => {
  const {
    paginationParams,
    children,
    column,
    disabled,
    onChange,
    type,
    ...rest
  } = props

  const isActive = useMemo(
    () => paginationParams?.orderBy === column,
    [column, paginationParams?.orderBy],
  )

  const { direction } = useMemo(() => {
    return {
      direction:
        paginationParams?.orderDirection === column
          ? 'asc'
          : ('desc' as 'asc' | 'desc'),
    }
  }, [isActive, paginationParams?.orderDirection])

  const handleChange = useCallback(() => {
    if (isActive && paginationParams?.orderDirection !== column) {
      onChange && onChange({ orderBy: undefined, orderDirection: undefined })
      return
    }

    const direction =
      isActive && paginationParams?.orderDirection === column
        ? `-${column}`
        : column
    onChange && onChange({ orderBy: column, orderDirection: direction })
  }, [column, isActive, onChange, paginationParams?.orderDirection])

  if (type === 'checkbox') {
    return (
      <TableCellStyle style={{ paddingLeft: '1.5rem' }} {...rest}>
        {children}
      </TableCellStyle>
    )
  }

  return (
    <TableCellStyle {...rest} sortDirection={direction}>
      {disabled && children}

      {!disabled && (
        <TableSortLabelStyle
          disabled={disabled}
          active={isActive}
          direction={direction}
          onClick={handleChange}
        >
          {children}
        </TableSortLabelStyle>
      )}
    </TableCellStyle>
  )
}

const TableCellHead = memo(TableCellHeadComponent)

export default TableCellHead
