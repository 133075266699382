import { Box, Typography } from '@material-ui/core'

import { ItemDetailsStyles } from './styles'

interface ItemDetailsProps {
  image: string
  name: string
  quantity: number
}

const ItemDetails = (props: ItemDetailsProps) => {
  const classes = ItemDetailsStyles()
  const { image, name, quantity } = props

  return (
    <>
      <Box
        mt="0.5rem"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <img
            src={image}
            alt="Imagem do produto"
            className={classes.itemImage}
          />

          <Typography className={classes.itemInformations}>{name}</Typography>
        </Box>
        <Box>
          <Typography className={classes.itemInformations}>
            {quantity}
          </Typography>
        </Box>
      </Box>

      <hr className={classes.separator}></hr>
    </>
  )
}

export default ItemDetails
