import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    maxWidth: '28rem',
    width: '100%',
    margin: 'auto 2%',
    background: '#FFFFFF',
    padding: '1.875rem',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '208.25px',
  },
  loading: {
    textAlign: 'center',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 400,
    textAlign: 'center',
    marginTop: '2rem',
  },
  containerButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: '1rem',
    marginTop: '1rem',
  },
  button: {
    width: '7.30rem',
    color: '#FFF',
  },
  infoProductKey: {
    display: 'flex',
    fontSize: '18px',
    fontWeight: 500,
    font: 'Roboto, sans-serif',
    color: '#757575',
    height: '27px',
    width: '100%',
  },
  infoProductValue: {
    fontSize: '18px',
    fontWeight: 500,
    font: 'Roboto, sans-serif',
    color: '#353535',
    height: '27px',
    paddingLeft: '.25rem',
  },
  infoProductName: {
    fontSize: '20px',
    font: 'Inter, sans-serif',
    fontWeight: 600,
    color: '#353535',
    textAlign: 'start',
    width: '100%',
    marginBottom: '10px',
  },
  alertScanBox: {
    fontSize: '1rem',
    fontWeight: 400,
    textAlign: 'center',
    margin: '2rem 0 1rem 0',
    background: '#DFEAFF',
    padding: '1rem 2rem',
    width: '100%',
    height: '2.5rem',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    paddingLeft: '1rem',
    borderRadius: '1rem',
    color: '#2E75FF',
  },
  alertBreak: {
    fontSize: '1rem',
    marginTop: '1rem',
    fontWeight: 400,
    background: '#FFF',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    color: '#DA3737',
    border: '2px solid #DA3737',
    padding: '8px',
    animation: '$showIn .3s ease-in',
  },
  '@keyframes showIn': {
    from: {
      opacity: 0,
      transform: 'translateY(-200px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },

  alertConvertion: {
    fontSize: '1rem',
    marginTop: '1rem',
    fontWeight: 400,
    background: '#FFF',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    color: '#89BD23',
    border: '2px solid #89BD23',
    padding: '8px',
    animation: '$showIn .3s ease-in',
  },
})
