import { styled, Select as MuiSelect } from '@material-ui/core'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'

const Select = styled(props => (
  <MuiSelect
    {...props}
    disableUnderline
    IconComponent={ExpandMoreRoundedIcon}
    classes={{
      select: {
        width: 'auto',
        fontSize: '12px',
        padding: '0 0.5rem',
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        position: 'relative',
        color: '#6EC177',
        fontSize: '14px',
      },
    }}
    MenuProps={{
      classes: {
        list: {
          paddingTop: 0,
          paddingBottom: 0,
          '& li': {
            fontWeight: 200,
            paddingTop: 8,
            paddingBottom: 8,
            fontSize: '12px',
          },
          '& li.Mui-selected': {
            color: 'white',
            background: '#6EC177',
          },
          '& li.Mui-selected:hover': {
            background: '#6EC177',
          },
        },
        paper: {
          borderRadius: 12,
          marginTop: 8,
        },
      },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      getContentAnchorEl: null,
    }}
    style={{
      padding: '0 0.5rem',
    }}
  />
))(({ theme }) => ({
  '& li': {
    fontWeight: 200,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: '12px',
  },
  '& li.MuiListItem-root.Mui-selected': {
    color: 'white',
    background: '#6EC177',
  },
  '& li.MuiListItem-root.Mui-selected:hover': {
    background: '#6EC177',
  },
}))

export default Select
