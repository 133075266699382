import { Box, Button, Typography } from '@material-ui/core'
import icon from 'assets/images/maintenance.png'
import { Modal } from 'shared'

import { useStyles } from './styles'

interface Props {
  isOpen: boolean
  separatorName: string
  track: number
  order: string
  onClose: () => void
}

export const ModalInformation = ({
  isOpen,
  separatorName,
  track,
  order,
  onClose,
}: Props) => {
  const classes = useStyles()
  return (
    <Modal
      open={isOpen}
      handleClose={onClose}
      title=""
      style={{ overflowX: 'visible' }}
      haventCloseButton={true}
    >
      <Box className={classes.root}>
        <Box component="header" className={classes.header}>
          <img src={icon} alt="maintenance" className={classes.image} />
        </Box>

        <Box className={classes.boxInformations}>
          <Typography className={classes.title}>Pedido de suporte</Typography>
          <Typography className={classes.subTitle}>
            O separador <strong>{separatorName} </strong>
            está solicitando suporte.
          </Typography>
        </Box>
      </Box>

      <Box className={classes.separatorInfos}>
        <Typography className={classes.info}>
          Separador: <strong>{separatorName}</strong>
        </Typography>
        <Typography className={classes.info}>
          Esteira: <strong>{track}</strong>
        </Typography>
        <Typography className={classes.info}>
          Pedido: <strong>{order}</strong>
        </Typography>
      </Box>

      <Button onClick={onClose} className={classes.buttonClose}>
        Fechar
      </Button>
    </Modal>
  )
}
