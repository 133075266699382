import { Theme } from '@material-ui/core'
import { Overrides } from '@material-ui/core/styles/overrides'

const Tooltip = (theme: Theme): Overrides => {
  return {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#383B40',
        color: '#FFFFFF',
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
      arrow: {
        color: '#383B40',
      },
    },
  }
}

export default Tooltip
