import { ILoadingConference } from 'interfaces/ILoadingConference'
import { IOnChange, IPaginationParams, IPaginationResponse } from 'interfaces/IPagination'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { debaunce } from 'utils'

interface Props<T> {
  service: (x: IPaginationParams, s: AbortSignal) => Promise<IPaginationResponse<T>>
  paramsDefault?: {
    [key: string]: string | number | null | boolean | Date
  }
}

export type IStatusTable = 'initial' | 'pending' | 'error' | 'success'

export const useTableParams = <T>({ service, paramsDefault }: Props<T>) => {
  const controller = new AbortController()
  const [status, setStatus] = useState<IStatusTable>('pending')
  const [pendingList, setpendingList] = useState<ILoadingConference[]>([])
  const [conferredList, setConferredList] = useState<ILoadingConference[]>([])
  const [inProcessList, setInProcessList] = useState<ILoadingConference[]>([])
  const [searchUrl, setSearchUrl] = useSearchParams()
  const [data, setData] = useState<T[]>([])
  const [params, setParams] = useState<IPaginationParams>({
    page: 1,
    pageSize: 10,
    search: searchUrl.get('search') || undefined,
    ...paramsDefault,
  })
  const [count, setCount] = useState(0)
  const debaunceSearch = debaunce({ fn: handleSearch, delay: 500 })
  const debaunceChange = debaunce({ fn: onChange, delay: 200 })

  function handleSearch(e: ChangeEvent<HTMLInputElement>) {
    const search = e.target.value
    setParams(state => ({ ...state, page: 1, search }))
    setSearchUrl({ search })
  }

  function onChange(newParams: IOnChange) {
    setParams(state => ({ ...state, ...newParams }))
  }

  const run = useCallback(async () => {
    try {
      setStatus('pending')
      const { results, totalCount, conferred, in_process, pending } = await service(
        {
          ...params,
          orderBy: params.orderDirection,
        },
        controller.signal,
      )

      if (conferred && in_process && pending) {
        setpendingList(pending)
        setConferredList(conferred)
        setInProcessList(in_process)
      }

      setData(results)
      setCount(totalCount)
      setStatus('success')
    } catch {
      if (controller.signal.aborted === false) {
        setStatus('error')
      }
    }
  }, [service, params])

  useEffect(() => {
    run()
    return () => {
      controller.abort()
    }
  }, [params])

  return {
    pendingList,
    conferredList,
    inProcessList,
    data,
    setData,
    params,
    onChange: debaunceChange,
    status,
    count,
    refresh: run,
    handleSearch: debaunceSearch,
  }
}
