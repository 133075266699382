import { usePopup } from 'hooks/usePopup'
import { IDashboardResponse, ITrack } from 'interfaces/IDashboard'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { dashboardService } from 'services/dashboardService'
import { lockAnimation } from 'store/reducers/dashboard/actions'

const sortSeparators = (separators: ITrack[]) => {
  const isDoingNothing = (separator: ITrack) => {
    return separator.last_work.end && !separator.work_conference
  }

  const isSeparating = (separator: ITrack) => {
    return !separator.last_work.end
  }

  const isConference = (separator: ITrack) => {
    return !!separator.work_conference
  }

  return separators?.sort((a, b) => {
    if (a.is_paused && !b.is_paused) {
      return 1
    }

    if (!a.is_paused && b.is_paused) {
      return -1
    }

    if (isDoingNothing(a) && (isSeparating(b) || isConference(b))) {
      return -1
    }

    if (isDoingNothing(a) && isDoingNothing(b)) {
      return moment(a.last_work.end).isBefore(moment(b.last_work.end)) ? -1 : 1
    }

    if (isSeparating(a) && isSeparating(b)) {
      return moment(a.last_work.begin).isBefore(moment(b.last_work.begin)) ? -1 : 1
    }

    if (isSeparating(a) && isConference(b)) {
      return 1
    }

    if (isConference(a) && isSeparating(b)) {
      return -1
    }

    if (isConference(a) && isConference(b)) {
      return moment(a.work_conference).isBefore(moment(b.work_conference)) ? -1 : 1
    }

    return 0
  })
}

export const useDashboard = () => {
  const dispatch = useDispatch()
  const controller = new AbortController()
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const [loading, setLoading] = useState(false)
  const { addPopup } = usePopup()
  const [dashboardData, setDashboardData] = useState<IDashboardResponse>({
    completed_orders: 0,
    data: [],
    date: '',
    total_orders: 0,
    average_duration: '0',
    start_time: new Date(),
    end_time: null,
    total_items: 0,
    total_volume: 0,
    tons_per_hour: 0,
    total_ifood_orders: {
      finalized: 0,
      total: 0,
    },
    total_frexco_orders: {
      finalized: 0,
      total: 0,
    },
    processed_volume: 0,
    orders_in_progress: 0,
    updated_in: null,
    average_idle_time: '0',
    avg_time_first_to_last_item: {
      scheduled_date: '',
      average_time_seconds: 0,
    },
  })

  const pollingDashboard = async (isError = false, refresh = false) => {
    try {
      setLoading(true)
      const data = await dashboardService.getDataDashboard({ controller })
      setDashboardData({
        ...data,
        data: sortSeparators(data.data),
      })
      const animationTime = data.data?.length * 300
      setTimeout(() => {
        dispatch(lockAnimation(true))
      }, animationTime)
    } catch {
      if (isError === false && controller.signal.aborted === false) {
        addPopup({
          type: 'error',
          title: 'Erro ao buscar dados',
        })
      }
    } finally {
      setLoading(false)
      if (controller.signal.aborted === false && refresh === false) {
        timeout.current = setTimeout(() => pollingDashboard(true), 7000)
      }
    }
  }
  const refreshDashboard = async () => {
    return pollingDashboard(false, true)
  }

  useEffect(() => {
    dispatch(lockAnimation(false))
    pollingDashboard()

    return () => {
      controller.abort()
      // eslint-disable-next-line no-undef
      clearTimeout(timeout.current as NodeJS.Timeout)
    }
  }, [])

  return { pollingDashboard, dashboardData, refreshDashboard, loading }
}
