import { Box, Typography, CircularProgress as CircularProgressMui } from '@material-ui/core'
import { TooltipStyled, useStyles } from './styles'

interface ICircularProps {
  total: number
  text: string
  percentage: number
  color: string
}

const CircularProgress = ({ color, percentage, text, total }: ICircularProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <CircularProgressMui
          variant="determinate"
          value={percentage}
          className={classes.circularMui}
          style={{ color }}
        />
        <CircularProgressMui
          variant="determinate"
          value={100}
          className={classes.backgroundCircular}
        />
        <TooltipStyled color={color}>{percentage?.toString()?.replace('.', ',')}%</TooltipStyled>

        <Box className={classes.content}>
          <Typography className={classes.total}>{total}</Typography>
          <Typography className={classes.text}>{text}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default CircularProgress
