import { Theme } from '@material-ui/core'
import { Overrides } from '@material-ui/core/styles/overrides'

const Paper = (theme: Theme): Overrides => {
  return {
    MuiPaper: {
      root: {
        borderRadius: '0.5rem',
      },
      rounded: {
        borderRadius: '0.5rem',
      },
    },
  }
}

export default Paper
