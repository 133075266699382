import { Box, Divider, Typography } from '@material-ui/core'
import { Button } from 'components'
import { usePopup } from 'hooks/usePopup'
import { useEffect, useState } from 'react'
import { Modal } from 'shared'

import { useStyles } from './styles'
import logoIfood from 'assets/icons/logo-ifood.png'
import { IoStorefrontSharp, IoHome } from 'react-icons/io5'
import { FiAlertTriangle } from 'react-icons/fi'
import { FaTruckLoading } from 'react-icons/fa'
import {
  ISeparationGroupConfig,
  ISeparationGroupConfigRequest,
} from 'interfaces/ISeparationGroupService'
import {
  MdOutlinePrecisionManufacturing,
  MdPendingActions,
  MdQrCodeScanner,
  MdScale,
} from 'react-icons/md'
import CheckboxConfig from 'components/pages/Config/CheckboxConfig'
import { BsBoxSeam, BsCheck2All } from 'react-icons/bs'
import { ContainerCheckboxs } from '../styles'
import { ButtonSecondary } from 'components/pages/Config/DialogNewSeparationGroup'
import ModalStepScanner, { IStepsSelected } from '../ModalStepScanner'

interface IProps extends ISeparationGroupConfig {
  isOpen: boolean
  handleCloseModal: () => void
  submitUpdateSeparationGroup: (
    params: ISeparationGroupConfigRequest,
    type: 'create' | 'update',
  ) => Promise<void>
}

export const SeparationGroupConfig = ({
  isOpen,
  handleCloseModal,
  submitUpdateSeparationGroup,
  ...rest
}: IProps) => {
  const classes = useStyles()
  const {
    separation_group,
    cam_scanner,
    receive_pj_orders,
    receive_pf_orders,
    receive_ka_orders,
    receive_critical_orders,
    receive_ifood_orders,
    receive_dinamic_price_orders,
    separation_group_commands,
    has_conference,
    infrared_scanner,
    has_count_by_step,
    steps_without_scanner,
  } = rest
  const { addPopup } = usePopup()
  const [isPJ, setIsPJ] = useState(receive_pj_orders)
  const [isPF, setIsPF] = useState(receive_pf_orders)
  const [isKA, setIsKA] = useState(receive_ka_orders)
  const [isCritical, setIsCritical] = useState(receive_critical_orders)
  const [isIfood, setIsIfood] = useState(receive_ifood_orders)
  const [isDinamicPrice, setIsDinamicPrice] = useState(receive_dinamic_price_orders)
  const [isDirected, setIsDirected] = useState(separation_group_commands)
  const [isCamScanner, setIsCamScanner] = useState(cam_scanner)
  const [hasConference, setHasConference] = useState(has_conference)
  const [isInfraredScanner, setIsInfraredScanner] = useState(infrared_scanner)
  const [hasCountByStep, setHasCountByStep] = useState(has_count_by_step)
  const [stepsWithoutScanner, setStepsWithoutScanner] = useState(steps_without_scanner || [])

  const [modalStepScanner, setModalStepScanner] = useState({
    open: false,
    callback: null as any as (steps: IStepsSelected) => void,
  })

  const handleSubmitForm = async () => {
    try {
      await submitUpdateSeparationGroup(
        {
          separationGroup: separation_group,
          camScanner: isCamScanner,
          fastTrack: true,
          pjOrders: isPJ,
          pfOrders: isPF,
          kaOrders: isKA,
          criticalOrders: isCritical,
          ifoodOrders: isIfood,
          dinamicPriceOrders: isDinamicPrice,
          separationGroupCommands: isDirected,
          hasConference,
          infraredScanner: isInfraredScanner,
          hasCountByStep,
          stepsWithoutScanner,
        },
        'update',
      )

      handleCloseModal()

      addPopup({
        type: 'success',
        title: 'Solicitação concluída',
      })
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro para atualizar grupo de separação',
        description: 'Contate o time de tech',
      })
    }
  }

  useEffect(() => {
    setIsPJ(receive_pj_orders)
    setIsPF(receive_pf_orders)
    setIsKA(receive_ka_orders)
    setIsIfood(receive_ifood_orders)
    setIsCritical(receive_critical_orders)
    setIsDinamicPrice(receive_dinamic_price_orders)
    setIsDirected(separation_group_commands || false)
    setIsCamScanner(cam_scanner)
    setHasConference(has_conference)
    setIsInfraredScanner(infrared_scanner)
    setHasCountByStep(has_count_by_step)
    setStepsWithoutScanner(steps_without_scanner || [])
  }, [separation_group])

  const titleGroupSeparationEdit = () => {
    const tituloModal = 'Configurar grupo de separação ' + separation_group

    return <p>{tituloModal}</p>
  }

  const handleInfraredScanner = (value: boolean) => {
    if (value && isCamScanner) {
      setIsCamScanner(false)
      setIsInfraredScanner(true)
      return
    }
    setIsInfraredScanner(value)
  }

  const handleCamScanner = (value: boolean) => {
    if (value && isInfraredScanner) {
      setIsInfraredScanner(false)
      setIsCamScanner(true)
      return
    }
    setIsCamScanner(value)
  }

  const onOpenModalStepScanner = () => {
    setModalStepScanner({
      open: true,
      callback: (steps: IStepsSelected) => {
        const stepsNotSelected = steps.filter(step => !step[Number(Object.keys(step)[0])])
        setStepsWithoutScanner(stepsNotSelected.map(step => Number(Object.keys(step)[0])))
        setModalStepScanner({
          open: false,
          callback: () => null as any as (steps: IStepsSelected) => void,
        })
      },
    })
  }

  const handleDirectedChange = () => {
    setIsDirected(!isDirected)
  }

  const handleInverseDirectedChange = () => {
    setIsDirected(!isDirected)
  }

  return (
    <Modal title={titleGroupSeparationEdit()} handleClose={handleCloseModal} open={isOpen}>
      <Typography className={classes.trackNumber}>
        Fala aí se é você ou o grupo que vai escolher decidir quais pedidos buscar
      </Typography>
      <Box>
        <ContainerCheckboxs>
          <CheckboxConfig
            checked={isDirected}
            onChange={handleDirectedChange}
            text="O grupo manda"
            icon={MdOutlinePrecisionManufacturing}
          />
          <CheckboxConfig
            checked={!isDirected}
            onChange={handleInverseDirectedChange}
            text="Eu que mando"
            icon={MdPendingActions}
          />
        </ContainerCheckboxs>
      </Box>
      <Divider className={classes.divider} />
      <ContainerCheckboxs>
        <CheckboxConfig
          checked={isPJ}
          onChange={setIsPJ}
          text="Pedidos PJ"
          icon={IoStorefrontSharp}
          disable={!isDirected}
        />
        <CheckboxConfig
          checked={isCritical}
          onChange={setIsCritical}
          text="Pedidos Críticos"
          icon={FiAlertTriangle}
          disable={!isDirected}
          isRed
        />
        <CheckboxConfig
          checked={isPF}
          onChange={setIsPF}
          disable={!isDirected}
          text="Pedidos PF"
          icon={IoHome}
        />
        <CheckboxConfig
          checked={isIfood}
          onChange={setIsIfood}
          text="Pedidos IFood"
          disable={!isDirected}
          image={logoIfood}
        />
        <CheckboxConfig
          checked={isKA}
          onChange={setIsKA}
          disable={!isDirected}
          text="Pedidos KA"
          icon={FaTruckLoading}
        />
        <CheckboxConfig
          checked={isDinamicPrice}
          onChange={setIsDinamicPrice}
          text="Pedidos Extra Order"
          disable={!isDirected}
          icon={MdScale}
        />
      </ContainerCheckboxs>
      <Divider className={classes.divider} />
      <ContainerCheckboxs>
        <CheckboxConfig
          checked={isCamScanner}
          onChange={handleCamScanner}
          text="Leitura câmera"
          icon={MdQrCodeScanner}
        />
        <CheckboxConfig
          checked={hasConference}
          onChange={setHasConference}
          text="Revisão"
          icon={BsCheck2All}
        />
        <CheckboxConfig
          checked={isInfraredScanner}
          onChange={handleInfraredScanner}
          text="Leitura infra-vermelho"
          icon={MdQrCodeScanner}
        />
        <CheckboxConfig
          checked={hasCountByStep}
          onChange={setHasCountByStep}
          text="Contagem por etapa"
          icon={BsBoxSeam}
        />
      </ContainerCheckboxs>
      <Box display="flex" justifyContent="space-between">
        {(isCamScanner || isInfraredScanner) && (
          <ButtonSecondary
            className={classes.buttonConfigs}
            onClick={onOpenModalStepScanner}
            style={{ marginLeft: 0 }}
          >
            <Typography className={classes.buttonText}>Etapas com leitor</Typography>
          </ButtonSecondary>
        )}
        <Button variant="contained" className={classes.buttonConfigs} onClick={handleSubmitForm}>
          <Typography className={classes.buttonText}>Opa, pode salvar!</Typography>
        </Button>
      </Box>
      {modalStepScanner.open && (
        <ModalStepScanner
          open={modalStepScanner.open}
          onConfirm={modalStepScanner.callback}
          steps_without_scanner={stepsWithoutScanner}
          onClose={() => {
            setModalStepScanner({
              open: false,
              callback: () => null as any as (steps: IStepsSelected) => void,
            })
          }}
        />
      )}
    </Modal>
  )
}
