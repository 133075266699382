import { alpha, Box, styled } from '@material-ui/core'
import { CSSProperties, ReactNode } from 'react'
import SimpleBarReact from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'

const RootStyle = styled('div')({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
})

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: '100vh',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.3),
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10,
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
  '& .simplebar-content': { height: '100%' },
}))

interface IScrollBar {
  children: ReactNode
  style?: CSSProperties | undefined
  [key: string]: any
}

const Scrollbar = ({ children, style, ...other }: IScrollBar) => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )

  if (isMobile) {
    return (
      <Box style={{ overflowX: 'auto', ...style }} {...other}>
        {children}
      </Box>
    )
  }

  return (
    <RootStyle>
      <SimpleBarStyle
        timeout={500}
        clickOnTrack={false}
        style={style}
        {...other}
      >
        {children}
      </SimpleBarStyle>
    </RootStyle>
  )
}

export default Scrollbar
