export const animateValue = (obj: HTMLElement, start: number, end: number, duration: number) => {
  let startTimestamp = 0

  const step = (timestamp: number) => {
    if (!startTimestamp) {
      startTimestamp = timestamp
    }

    const progress = Math.min((timestamp - startTimestamp) / duration, 1)

    obj.innerHTML = String(Math.floor(progress * (end - start) + start))
    if (progress < 1) {
      window.requestAnimationFrame(step)
    }
  }

  window.requestAnimationFrame(step)
}
