import { Box, Typography } from '@material-ui/core'
import { ClientCritical } from 'components'
import { useConferenceChuchu } from 'hooks/useConferenceChuchu'
import { usePopup } from 'hooks/usePopup'
import { IOrder } from 'interfaces/IOrder'
import { ISeparation } from 'interfaces/ISeparation'
import { enRules } from 'interfaces/IUser'
import {
  DialogAlertBeginner,
  IDialogAlertBeginner,
} from 'pages/Operation/OrderSeparation/DialogAlertBeginner'
import { useEffect, useMemo, useRef, useState } from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { checkBoxesService } from 'services/checkBoxesService'
import { IConferenceItem, conferenceItemService } from 'services/conferenceItemService'
import { dashboardService } from 'services/dashboardService'
import { enTypeOfAnalysis } from 'services/workConference'
import { IState } from 'store'
import { updateRunningMachine } from 'store/reducers/infos/actions'
import { updatePreviousOrder } from 'store/reducers/orderFastTrack/actions'
import { getDeliveryDate, mappingType } from 'utils'

import CarouselInfo from './CarouselInfo'
import {
  ClientName,
  ContainerContent,
  ContainerSeparatinos,
  Header,
  Instructions,
  OrderId,
  Title,
  TitlePage,
  LinearProgress,
  Card,
  TitleSeparation,
  CardItem,
  ImageItem,
  QuantityItem,
  NameItem,
  ButtonCheckItem,
  ContainerPage,
  Alert,
  BreakQuantity,
  ButtonCheckSeparated,
  SeparatedContainer,
} from './styles'
import { SeparationCompleted } from 'pages/Operation/OrderSeparation/SeparationCompleted'

export interface IConferenceItemParams {
  itemId?: number
  separated?: boolean
}

export type CheckState = {
  default: IConferenceItem[]
  updated: IConferenceItemParams[]
}

const OrderConference = () => {
  const dialogAlertBeginnerRef = useRef<IDialogAlertBeginner>(null)
  const [orderCheckData, setOrderCheckData] = useState<IOrder>(null as unknown as IOrder)
  const [separatedItems, setSeparatedItems] = useState<CheckState>({ default: [], updated: [] })
  const [separations, setSeparations] = useState<ISeparation[]>([])
  const [loading, setLoading] = useState(false)
  const [revisionCompleted, setRevisionCompleted] = useState(false)
  const [totalBoxesOrder, setTotalBoxesOrder] = useState<number>(0)
  const { user } = useSelector<IState, IState>(state => state)
  const [isError, setIsError] = useState('')
  const { orderId } = useParams()
  const { addPopup } = usePopup()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { workConference, updateWorkConference, recoveryConference } = useConferenceChuchu()

  const getOrderData = async () => {
    setLoading(true)
    try {
      const [dashboardData, separatedCheck] = await Promise.all([
        dashboardService.getOrderDetails(orderId as string),
        conferenceItemService.getAll({ orderId: orderId }),
      ])
      if (![3, 4, 5].includes(dashboardData.order.checking_status)) {
        addPopup({ title: `Pedido #${orderId} ainda não foi finalizado!`, type: 'info' })
        window.history.go(-1)
        return
      }

      setSeparations(dashboardData.data.filter((separation: any) => separation.items.length > 0))
      setOrderCheckData(dashboardData.order)
      setTotalBoxesOrder(dashboardData.total_boxes)
      setSeparatedItems(state => ({ ...state, default: separatedCheck }))
      setIsError('')
    } catch (error: any) {
      setIsError(error?.message || error?.msg || 'Erro ao carregar dados do pedido')
      addPopup({ title: 'Erro ao carregar dados do pedido', type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const confirmCheck = async () => {
    setLoading(true)
    try {
      const separatedItemList = separations.reduce((acc, separation) => {
        const items = separation.items.map(item => ({
          itemId: item.id,
          separated: separatedItems.updated.find(si => si.itemId === item.id)?.separated ?? true,
        }))

        return [...acc, ...items]
      }, [] as IConferenceItemParams[])
      await checkBoxesService.finish({ orderId: orderId as string, items: separatedItemList })

      addPopup({ title: `Pedido #${orderId} conferido com sucesso!`, type: 'info' })

      if (workConference?.type_of_analysis === enTypeOfAnalysis.REVISION) {
        if (user.userPermissions.includes(enRules.SeparationCriticalOrBeginner)) {
          await showAlertBeginnerSeparator()
        }
        updateWorkConference(null as any)
        dispatch(updatePreviousOrder(orderCheckData))
        callbackProductivity()
        return
      }
      updateWorkConference(null as any)
      navigate('../inicio')
    } catch {
      addPopup({ title: 'Erro ao confirmar conferência', type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const getSeparatedStatus = (itemId: number): boolean => {
    const item = separatedItems.default.find(item => item.item_id === itemId)
    return item?.separated ?? true
  }

  const showAlertBeginnerSeparator = async () => {
    return new Promise<void>(resolve => {
      dialogAlertBeginnerRef.current?.onOpen(() => resolve())
    })
  }

  const onCheckboxChange = (itemId: number, separated: boolean) => {
    const updatedItems = separatedItems.updated.map(item =>
      item.itemId === itemId ? { ...item, separated } : item,
    )

    if (!updatedItems.some(item => item.itemId === itemId)) {
      updatedItems.push({ itemId, separated })
    }

    setSeparatedItems(state => ({ ...state, updated: updatedItems }))
  }

  const { totalItens, separatorName } = useMemo(() => {
    const totalItens = separations.reduce((acc, separation) => {
      return acc + separation.items.length
    }, 0)
    const separatorName: string = separations.reduce((acc: string, separation) => {
      return separation.items.length > 0
        ? separation.items[0].work?.separator?.username ?? acc
        : acc
    }, 'Desconhecido')

    return { totalItens, separatorName }
  }, [orderCheckData])

  const dateSeparation = getDeliveryDate()

  function transformDate(date: string) {
    const partes = date.split('-')
    if (partes.length !== 3) {
      return 'Data inválida'
    }

    const ano = partes[0]
    const mes = partes[1]
    const dia = partes[2]

    return `${dia}/${mes}/${ano}`
  }

  const isCorrectDate =
    orderCheckData?.scheduled_date ===
    `${dateSeparation.year}-${dateSeparation.month}-${dateSeparation.day}`

  const callbackProductivity = async () => {
    setRevisionCompleted(true)
    dispatch(updateRunningMachine(orderCheckData.separation_group))
  }

  useEffect(() => {
    recoveryConference()
    if (orderId) {
      getOrderData()
    } else {
      navigate('../inicio')
    }
  }, [])

  return (
    <>
      <ContainerPage>
        <TitlePage>
          Conferência
          <IoIosArrowForward fontSize="1.125rem" />
          {orderId && `#${orderId}`}
        </TitlePage>

        {loading && <LinearProgress />}
        {revisionCompleted && (
          <SeparationCompleted order={orderCheckData} isConferenceGroup={true} />
        )}
        {!loading && !revisionCompleted && (
          <Box>
            <Header component="header">
              <Box>
                <ClientName variant="h1">
                  {orderCheckData?.client_data?.client_type === 'PF'
                    ? orderCheckData?.client_data?.client_name
                    : orderCheckData?.client_data?.client_trade}
                  {orderCheckData?.is_critical && <ClientCritical />}
                </ClientName>
                <OrderId>#{orderCheckData?.order_id}</OrderId>
              </Box>
            </Header>

            {isError && (
              <Alert colorBackground="#F8D7DA" colorBorder="#af1625">
                <span>{isError}</span>
              </Alert>
            )}
            {!isCorrectDate && orderCheckData && (
              <Alert colorBackground="#F8D7DA" colorBorder="#B02A37">
                Pedido agendado para {transformDate(orderCheckData.scheduled_date)}
              </Alert>
            )}

            <CarouselInfo
              separations={separations}
              separatorName={separatorName}
              totalItens={totalItens}
              quantityBoxes={totalBoxesOrder}
            />
            <ContainerContent>
              <ContainerSeparatinos>
                {orderCheckData?.instructions && (
                  <Instructions>
                    <Title>Observação do pedido</Title>
                    <Typography>{orderCheckData?.instructions}</Typography>
                  </Instructions>
                )}
                {separations.map(separation => {
                  return (
                    <Card key={separation.separation}>
                      <TitleSeparation variant="h1">Etapa {separation.separation}</TitleSeparation>
                      {separation.items.map(item => {
                        const itemQuantityBreak =
                          (item.converted_quantity || item.quantity) - item.confirmed_quantity
                        return (
                          <CardItem key={item.id}>
                            <ImageItem src={item.image} />
                            <NameItem>{item.name}</NameItem>
                            <QuantityItem>
                              <strong>Quantidade:</strong> {item.confirmed_quantity === 0 && 0}
                              {item.confirmed_quantity > 0 &&
                                `${
                                  item?.unity_product_base === 'peso'
                                    ? item.confirmed_quantity.toFixed(3)
                                    : item.confirmed_quantity
                                } ${mappingType(item?.unity_product_base)}`}
                            </QuantityItem>
                            {itemQuantityBreak > 0 && (
                              <BreakQuantity>
                                {item.confirmed_quantity === 0 ? (
                                  <strong>Quebra Total</strong>
                                ) : (
                                  <>
                                    <strong>Quebra Parcial - </strong>
                                    {`${itemQuantityBreak > 1 ? 'Faltam' : 'Falta'} ${
                                      item?.unity_product_base === 'peso'
                                        ? itemQuantityBreak.toFixed(3)
                                        : itemQuantityBreak
                                    } ${mappingType(item?.unity_product_base)}`}
                                  </>
                                )}
                              </BreakQuantity>
                            )}
                            {item.confirmed_quantity > 0 && (
                              <SeparatedContainer>
                                <ButtonCheckSeparated
                                  type="checkbox"
                                  id={`checkbox-conference ${item.id}`}
                                  onChange={e => onCheckboxChange(item.id, !e.target.checked)}
                                  defaultChecked={!getSeparatedStatus(item.id)}
                                />
                                <label htmlFor={`checkbox-conference ${item.id}`}>
                                  Separador não colocou o item na caixa
                                </label>
                              </SeparatedContainer>
                            )}
                          </CardItem>
                        )
                      })}
                    </Card>
                  )
                })}
              </ContainerSeparatinos>
            </ContainerContent>
            <ButtonCheckItem onClick={() => confirmCheck()}>Conferir Pedido</ButtonCheckItem>
          </Box>
        )}
      </ContainerPage>

      <DialogAlertBeginner ref={dialogAlertBeginnerRef} />
    </>
  )
}

export default OrderConference
