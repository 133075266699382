import { makeStyles } from '@material-ui/core'

export const styles = makeStyles(theme => ({
  filterAlign: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    alignItems: 'center',
    marginTop: '1.5rem',
    marginBottom: '0.8rem',
  },
  buttonFilter: {
    minWidth: '6.5rem',
    height: '1.7rem',
    background: '#F4F4F4',
    borderRadius: '2.5rem',
    marginLeft: '0.3rem',
    border: 'none',
  },
  buttonFilterActive: {
    minWidth: '6.5rem',
    height: '1.7rem',
    background: '#F4F4F4',
    borderRadius: '2.5rem',
    marginLeft: '1rem',
    border: '1px solid #757575',
  },
  buttonAlign: {
    marginRight: '0.5rem',
  },
  buttonText: {
    color: '#757575',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.89rem',
    lineHeight: '1rem',
  },
}))
