import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import logoFrexco from 'assets/images/logo-frexco.svg'
import { UserNav } from 'components'
import { SendHelp } from 'components/SendHelp/Index'
import { useState } from 'react'
import { FiChevronsRight } from 'react-icons/fi'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useLocation } from 'react-router'
import { ItemNav, Scrollbar } from 'shared'

import NavConfig, { INavConfig } from '../NavConfig'
import { useStylesDesktop } from './styles'
import { useSelector } from 'react-redux'
import { IState } from 'store'
import { enRules } from 'interfaces/IUser'

export const DesktopNavbar = () => {
  const classes = useStylesDesktop()
  const [open, setOpen] = useState(false)
  const isMobile = useMediaQuery('(max-width: 1000px)')
  const userPermissions = useSelector<IState, enRules[]>(state => state.user.userPermissions)

  const { pathname } = useLocation()

  const handleDrawerToggle = () => {
    setOpen(state => !state)
  }

  const handleSeeToggle = () => {
    setOpen(state => !state)
  }

  const filterByPermission = (item: INavConfig): boolean => {
    return userPermissions.some(permission => item.permissionsRequired.includes(permission))
  }

  return (
    <>
      <AppBar
        position="fixed"
        className={`${classes.appBar} ${open && !isMobile && classes.appBarShift}`}
      >
        <Toolbar className={classes.toolbar}>
          {isMobile && (
            <Button onClick={handleDrawerToggle} style={{ marginRight: 'auto' }}>
              <GiHamburgerMenu color="#353535" size={30} />
            </Button>
          )}

          <Box
            style={{
              display: 'flex',
              gap: 1,
              marginLeft: 'auto',
              marginRight: '1rem',
            }}
          >
            {!pathname.includes('leitor-fixo') && (
              <Box className={classes.userExperience}>
                <SendHelp />
              </Box>
            )}
            <UserNav theme="light" />
          </Box>
        </Toolbar>
      </AppBar>

      <Box className={classes.navbarContainer}>
        <IconButton
          onClick={handleDrawerToggle}
          className={`${classes.buttonDrawer} ${open && classes.activeDrawer}`}
        >
          <FiChevronsRight size={10} className={classes.iconDrawer} />
        </IconButton>
        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          onClose={isMobile ? handleDrawerToggle : undefined}
          classes={{
            paper: `${classes.drawerPaper} ${!open && classes.drawerPaperClose}`,
          }}
          open={open}
          ModalProps={{
            keepMounted: isMobile,
          }}
        >
          <Scrollbar style={{ overflowX: 'hidden', height: '100%' }}>
            <Typography className={classes.logoContainer}>
              <img src={logoFrexco} />
              <strong className={classes.title}>Carambola</strong>
            </Typography>

            <Box component="nav" className={classes.nav}>
              <List className={classes.listClose}>
                {NavConfig.filter(filterByPermission).map(item => (
                  <ItemNav
                    key={item.title}
                    open={open}
                    pathname={pathname}
                    handleClose={isMobile ? handleDrawerToggle : undefined}
                    handleSeeToggle={handleSeeToggle}
                    item={item}
                  />
                ))}
              </List>
            </Box>
          </Scrollbar>
        </Drawer>
      </Box>
    </>
  )
}
