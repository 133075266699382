import { Box, Typography } from '@material-ui/core'
import { FC } from 'react'

import { useStyles } from './styles'

interface ITitlePageProps {
  className?: string
}

export const TitlePage: FC<ITitlePageProps> = ({ children, className }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <span className={classes.bar}></span>
      <Typography variant="h1" className={`${classes.title} ${className}`}>
        {children}
      </Typography>
    </Box>
  )
}
