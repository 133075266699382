import {
  Box,
  Container,
  Divider,
  Modal,
  Radio,
  Typography,
  Button,
  styled,
} from '@material-ui/core'
import { useState } from 'react'
import { AiOutlinePrinter } from 'react-icons/ai'

import { useStyles } from './styles'

interface IModalChoicePrinter {
  open: boolean
  handleClose: () => void
  confirm: (params: number) => Promise<void>
}

const LabelStyle = styled(Typography)({
  color: '#353535',
  textAlign: 'left',
  userSelect: 'none',
  fontSize: '1.1rem',
  '& span': {
    display: 'block',
    color: '#757575',
    fontSize: '0.95rem',
  },
})

export const ModalChoicePrinter = ({ confirm, open, handleClose }: IModalChoicePrinter) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [printerChoiced, setPrinterChoiced] = useState<number>(1)

  const handleConfirm = async () => {
    setIsLoading(true)
    await confirm(printerChoiced)
    setIsLoading(false)
  }

  return (
    <Modal open={open} className={classes.root}>
      <Container className={classes.container}>
        {isLoading ? (
          <Typography className={classes.loading}>Aí Sim! Tamo imprimindo...🖨️</Typography>
        ) : (
          <>
            <Typography className={classes.title}>
              Aow trem, a etiqueta desse pedido é branca!
            </Typography>
            <Typography className={classes.subtitle}>
              Qual impressora cê quer usar agora? 🤠
            </Typography>

            <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
              <span className={`${classes.icon} ${classes.printerIcon}`}>
                <AiOutlinePrinter style={{ width: '40px', height: 'auto' }} />
              </span>

              <LabelStyle style={{ textAlign: 'left', marginLeft: '1rem' }}>
                Impressora Branca 1
              </LabelStyle>

              <Radio
                style={{ marginLeft: 'auto', borderRadius: '50%' }}
                color="primary"
                value={1}
                checked={printerChoiced === 1}
                onChange={e => setPrinterChoiced(Number(e.target.value))}
              />
            </div>
            <Divider className={classes.dividerButton} orientation="horizontal" />
            <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
              <span className={`${classes.icon} ${classes.printerIcon}`}>
                <AiOutlinePrinter style={{ width: '40px', height: 'auto' }} />
              </span>

              <LabelStyle style={{ textAlign: 'left', marginLeft: '1rem' }}>
                Impressora Branca 2
              </LabelStyle>

              <Radio
                style={{ marginLeft: 'auto', borderRadius: '50%' }}
                color="primary"
                value={2}
                checked={printerChoiced === 2}
                onChange={e => setPrinterChoiced(Number(e.target.value))}
              />
            </div>
            <Typography className={classes.supportText}>
              Não esquece que a impressão vai ser feita na impressora que cê escolheu viu? Fica de
              olho! 👀
            </Typography>
            <Box className={classes.containerBtn}>
              <Button className={classes.btnCancel} onClick={handleClose}>
                Já imprimi!
              </Button>
              <Button className={classes.btnConfirm} onClick={handleConfirm}>
                Imprime aí!
              </Button>
            </Box>
          </>
        )}
      </Container>
    </Modal>
  )
}
