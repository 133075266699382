import Carousel, { slidesToShowPlugin, arrowsPlugin } from '@brainhubeu/react-carousel'
import { Box, Divider, Typography } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import iconShop from 'assets/images/icon-shop.svg'
import { useEffect, useState } from 'react'
import { ISeparation } from 'interfaces/ISeparation'
import { FaClock } from 'react-icons/fa'

import { useStyles } from './styles'
import { BsBoxSeam, BsPersonBadgeFill } from 'react-icons/bs'
import { formatName } from 'utils/formatName'

interface ISummaryOrderProps {
  separatorName: string
  totalItens: number
  separations: ISeparation[]
  quantityBoxes: number
}

const CarouselInfo = (props: ISummaryOrderProps) => {
  const { totalItens, separations, separatorName, quantityBoxes } = props
  const time =
    separations[separations?.length - 1]?.items[separations?.length - 1]?.work?.time_length
  const [index, setIndex] = useState(0)
  const classes = useStyles()
  const [width, setWidth] = useState(() => window?.innerWidth)

  useEffect(() => {
    const updateWidth = (e: any) => {
      setWidth(e.target?.innerWidth)
    }

    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  return (
    <Carousel
      className={classes.carousel}
      value={index}
      onChange={e => {
        if (e >= 0) {
          setIndex(e)
        }
      }}
      plugins={[
        {
          resolve: slidesToShowPlugin,
          options: {
            numberOfSlides: 4,
          },
        },
      ]}
      breakpoints={{
        4096: {
          plugins: [
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 3,
              },
            },
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: (
                  <button className={classes.arrowLeft}>
                    <ArrowBackIosIcon className={classes.arrowIcon} />
                  </button>
                ),
                arrowLeftDisabled: (
                  <button disabled={true} className={classes.arrowLeft}>
                    <ArrowBackIosIcon className={classes.arrowIcon} />
                  </button>
                ),
                arrowRight: (
                  <button className={classes.arrowRight}>
                    <ArrowForwardIosIcon className={classes.arrowIcon} />
                  </button>
                ),
                arrowRightDisabled: (
                  <button disabled={true} className={classes.arrowRight}>
                    <ArrowForwardIosIcon className={classes.arrowIcon} />
                  </button>
                ),
                addArrowClickHandler: true,
              },
            },
          ],
        },
        820: {
          plugins: [
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 2,
              },
            },
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: (
                  <button className={classes.arrowLeft}>
                    <ArrowBackIosIcon className={classes.arrowIcon} />
                  </button>
                ),
                arrowLeftDisabled: (
                  <button disabled={true} className={classes.arrowLeft}>
                    <ArrowBackIosIcon className={classes.arrowIcon} />
                  </button>
                ),
                arrowRight: (
                  <button className={classes.arrowRight}>
                    <ArrowForwardIosIcon className={classes.arrowIcon} />
                  </button>
                ),
                arrowRightDisabled: (
                  <button disabled={true} className={classes.arrowRight}>
                    <ArrowForwardIosIcon className={classes.arrowIcon} />
                  </button>
                ),
                addArrowClickHandler: true,
              },
            },
          ],
        },
        520: {
          plugins: [
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 1,
              },
            },
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: (
                  <button className={classes.arrowLeft}>
                    <ArrowBackIosIcon className={classes.arrowIcon} />
                  </button>
                ),
                arrowLeftDisabled: (
                  <button disabled={true} className={classes.arrowLeft}>
                    <ArrowBackIosIcon className={classes.arrowIcon} />
                  </button>
                ),
                arrowRight: (
                  <button className={classes.arrowRight}>
                    <ArrowForwardIosIcon className={classes.arrowIcon} />
                  </button>
                ),
                arrowRightDisabled: (
                  <button disabled={true} className={classes.arrowRight}>
                    <ArrowForwardIosIcon className={classes.arrowIcon} />
                  </button>
                ),
                addArrowClickHandler: true,
              },
            },
          ],
        },
      }}
    >
      <div style={{ display: 'flex' }}>
        <Box className={classes.box}>
          <span className={`${classes.icon} ${classes.totalOrderIcon}`}>
            <img src={iconShop} />
          </span>
          <div>
            <Typography className={classes.title}>Total de itens</Typography>
            <Typography className={classes.subtitle}>{totalItens}</Typography>
          </div>
        </Box>
      </div>
      <div style={{ display: 'flex' }}>
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <Box className={classes.box}>
          <span className={`${classes.icon} ${classes.separator}`}>
            <BsPersonBadgeFill size={34} color="#fff" />
          </span>
          <div>
            <Typography className={classes.title}>Separado por</Typography>
            <Typography className={classes.subtitle} style={{ textTransform: 'capitalize' }}>
              {formatName(separatorName)}
            </Typography>
          </div>
        </Box>
      </div>

      <div style={{ display: 'flex' }}>
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <Box className={classes.box}>
          <span className={`${classes.icon} ${classes.timer}`}>
            <FaClock size={30} color="#fff" />
          </span>
          <div>
            <Typography className={classes.title}>Tempo de separação</Typography>
            <Typography className={classes.subtitle}>
              <strong style={{ textAlign: 'right' }}>
                {time?.split('')?.slice(0, 8)?.join('') ?? 'Desconhecido'}
              </strong>
            </Typography>
          </div>
        </Box>
      </div>

      <div style={{ display: 'flex' }}>
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <Box className={classes.box}>
          <span className={`${classes.icon} ${classes.boxes}`}>
            <BsBoxSeam size={30} color="#fff" />
          </span>
          <div>
            <Typography className={classes.title}>Quantidade de caixas</Typography>
            <Typography className={classes.subtitle}>
              <strong style={{ textAlign: 'right' }}>{quantityBoxes}</strong>
            </Typography>
          </div>
        </Box>
      </div>
    </Carousel>
  )
}

export default CarouselInfo
