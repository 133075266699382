import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > .MuiPaper-root': {
      maxWidth: '664px',
      width: '100%',
      padding: '0 1.25rem 1.25rem 1.25rem',
      height: '500px',
    },
  },
  containerDescriptions: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    color: '#757575',
    fontSize: '1rem',
    fontWeight: 500,
  },
  accordion: {
    '&:nth-child(even)': {
      '& .MuiAccordionSummary-root': {
        background: '#F4F4F4',
      },
    },
    '&:nth-child(odd)': {
      '& .MuiAccordionSummary-root': {
        background: '#FFFFFF',
      },
    },
    '& .MuiAccordionSummary-root': {
      display: 'flex',
      justifyContent: 'space-between',
      height: '54px',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '54px',
    },
    '& .MuiAccordionSummary-content': {
      maxWidth: '50%',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '1rem',
    },
  },
  tableHead: {
    '& th': {
      fontWeight: 400,
      color: '#757575',
      fontSize: '1rem',
    },
  },
}))
