import { ContainerOrder, Modal, Paper, Subtitle, Text, Title } from './styles'

export interface IDataAlertConferenceOrder {
  title: string
  subtitle: string
  order_id: string
  checker_name: string
}

interface IProps {
  open: boolean
  data: IDataAlertConferenceOrder
}

const ModalAlertConferenceOrder = (props: IProps) => {
  return (
    <Modal open={props.open}>
      <Paper>
        <Title>{props.data.title}</Title>
        <Subtitle>{props.data.subtitle}</Subtitle>
        <ContainerOrder>
          <Text colorText="#757575">Pedido</Text>
          <Text colorText="#757575">Conferente</Text>
          <Text colorText="#5F752C">{props.data.order_id}</Text>
          <Text colorText="#353535">{props.data.checker_name}</Text>
        </ContainerOrder>
      </Paper>
    </Modal>
  )
}

export default ModalAlertConferenceOrder
