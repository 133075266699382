import { api as apiService, ApiService } from './api'
import { ICreatePause, IDataPause, IGetPauses, IParamsPause } from 'interfaces/IPause'

class PauseService {
  constructor(private readonly api: ApiService) {}

  public getPauses = async (params: IParamsPause): Promise<IGetPauses> => {
    return this.api.get(carambolaUrl + '/dashboard/pause', {
      params,
    })
  }

  public createPause = async (data: IDataPause): Promise<ICreatePause> => {
    return this.api.post(carambolaUrl + '/dashboard/pause', data)
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const pauseService = new PauseService(apiService)
