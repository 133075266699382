import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    color: '#ffffff',
    padding: '0.625rem 0.937rem',
    borderRadius: '5px',
  },
  completed: {
    background: '#89BD23',
  },
  breach: {
    background: '#FF0000',
  },
  partialBreak: {
    background: '#EDDB1C',
  },
  inSeparation: {
    background: '#2B6AA4',
  },
  replenished: {
    background: '#757575',
  },
})

interface IStateProps {
  state: number
}

export const States = ({ state }: IStateProps) => {
  const classes = useStyles()

  const btns: any = {
    1: (
      <strong className={`${classes.root} ${classes.inSeparation}`}>
        Em separação
      </strong>
    ),
    2: (
      <strong className={`${classes.root} ${classes.completed}`}>
        Concluído
      </strong>
    ),
    3: <strong className={`${classes.root} ${classes.breach}`}>Quebra</strong>,
    4: (
      <strong className={`${classes.root} ${classes.partialBreak}`}>
        Quebra parcial
      </strong>
    ),
    5: (
      <strong className={`${classes.root} ${classes.replenished}`}>
        Reposto
      </strong>
    ),
  }

  return btns[state] || <div></div>
}
