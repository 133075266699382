import {
  Box,
  Button,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@material-ui/core'
import { usePopup } from 'hooks/usePopup'
import { IOrder } from 'interfaces/IOrder'
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState, memo } from 'react'
import { orderService } from 'services/orderService'
import { Modal } from 'shared'
import { getNameClient } from 'utils'
import { mapMessageError } from 'utils/mapMessageError'

const LabelStyle = styled(Typography)({
  color: '#353535',
  textAlign: 'center',
  userSelect: 'none',
  fontSize: '1.1rem',
  '& span': {
    display: 'block',
    color: '#757575',
    fontSize: '0.95rem',
  },
})

const TitleStyle = styled(Typography)({
  color: '#353535',
  fontSize: '1.125rem',
  fontWeight: 500,
  marginBottom: '0.5rem',
})

const ButtonStyle = styled(Button)({
  padding: '0.8rem 2rem',
  margin: '1.5rem auto 0',
  marginTop: 'auto',
  textTransform: 'initial',
})

type Config = 'priority' | 'critical' | 'separated' | 'reset' | 'directedGroup' | ''

export interface IEdit {
  handleData: (x: IOrder) => void
}

interface Props {
  refresh: () => void
}

export const Component: ForwardRefRenderFunction<IEdit, Props> = ({ refresh }, ref) => {
  const { addPopup } = usePopup()
  const [isOpen, setIsOpen] = useState(false)
  const [isTypeError, setIsTypeError] = useState({
    isCanceled: false,
    isPending: false,
  })
  const [data, setData] = useState<IOrder>({} as IOrder)
  const [configSelect, setConfigSelect] = useState<Config>('')
  const [isLoading, setIsLoading] = useState(false)
  const [separationGroup, setSeparationGroup] = useState<Number | undefined>()
  const scheduledOrder = new Date(data.scheduled_date)
  scheduledOrder.setHours(scheduledOrder.getHours() + 3)
  scheduledOrder.setDate(
    scheduledOrder.getHours() >= 12 ? scheduledOrder.getDate() + 1 : scheduledOrder.getDate(),
  )
  const now = new Date()
  if (now.getHours() >= 12) {
    now.setDate(now.getDate() + 1)
  }

  const isScheduledTomorrow = scheduledOrder.getDate() === now.getDate()

  const formattedDate = data?.scheduled_date?.split('-').reverse().join('-')

  const handleClose = () => {
    setIsOpen(false)
    setData({} as IOrder)
    setConfigSelect('')
    handleChangeStateStatus()
  }

  const handleChangeStateStatus = (type?: string) => {
    if (type === 'pending') {
      return setIsTypeError({ isPending: true, isCanceled: false })
    }

    if (type === 'recused') {
      return setIsTypeError({ isPending: false, isCanceled: true })
    }

    setIsTypeError({
      isPending: false,
      isCanceled: false,
    })
  }

  useImperativeHandle(ref, () => {
    return { handleData }
  })

  const handleData = (order: IOrder) => {
    setData(order)
    setIsOpen(true)
  }

  const handleChangeConfig = async () => {
    try {
      setIsLoading(true)
      if (configSelect === 'directedGroup') {
        const response = await orderService.updateOrder({
          [configSelect]: separationGroup,
          ordersId: [data?.order_id],
        })
        addPopup({
          type: 'success',
          title: response.message,
        })
      } else {
        const response = await orderService.updateOrder({
          [configSelect]: true,
          ordersId: [data?.order_id],
        })
        addPopup({
          type: 'success',
          title: response.message,
        })
      }
      refresh()
      handleClose()
    } catch (error: any) {
      const messageError = error?.message
      if (messageError?.includes('iniciado')) {
        return handleChangeStateStatus('pending')
      }

      if (messageError?.includes('canceled')) {
        return handleChangeStateStatus('recused')
      }

      addPopup({
        type: 'error',
        title: mapMessageError(messageError),
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      open={isOpen}
      handleClose={handleClose}
      title="Ajustes"
      style={{ maxHeight: '30.25rem', height: '100%' }}
    >
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Typography>{data?.order_id}</Typography>
        <Typography style={{ fontWeight: 600 }}>{getNameClient(data?.client_data)}</Typography>
      </Box>

      <Box
        component="form"
        display="flex"
        alignItems="space-between"
        justifyContent="space-between"
        flexDirection="column"
        pt="1rem"
        height="calc(100% - 45px)"
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="grouped-select">Selecione a configuração</InputLabel>
          <Select
            id="grouped-select"
            value={configSelect}
            onChange={e => {
              const value = e.target.value
              if (value) {
                setConfigSelect(value as Config)
              }
            }}
            label="Selecione a configuração"
          >
            <ListSubheader>O que você quer fazer?</ListSubheader>
            <MenuItem value="priority">Priorizar pedido</MenuItem>
            <MenuItem value="critical">Sinalizar como crítico</MenuItem>
            <MenuItem value="directedGroup">Direcionar pedido</MenuItem>
            <MenuItem value="separated">Finalizar pedido</MenuItem>
            <MenuItem value="reset">Resetar pedido</MenuItem>
          </Select>
        </FormControl>

        {configSelect === 'priority' && (
          <Box mt="2rem" width="100%">
            <LabelStyle style={{ marginTop: '2rem' }}>
              Priorizar pedido
              <span>Ele será separado pela próxima pessoa que solicitar um novo pedido.</span>
            </LabelStyle>
          </Box>
        )}

        {configSelect === 'critical' && (
          <Box mt="2rem" width="100%">
            <LabelStyle style={{ marginTop: '2rem' }}>
              Pedido crítico
              <span>Será marcado como crítico para a separação.</span>
            </LabelStyle>
          </Box>
        )}

        {configSelect === 'directedGroup' && (
          <Box mt="2rem" width="100%">
            <LabelStyle style={{ marginTop: '2rem' }}>
              Direcionar pedido
              <span>Digite abaixo o n° do grupo que deseja direcionar esse pedido:</span>
              <input
                type="number"
                placeholder="N° do grupo"
                onChange={e => {
                  const number = parseInt(e.target.value)
                  if (number > 0) {
                    setSeparationGroup(number)
                  } else {
                    setSeparationGroup(undefined)
                  }
                }}
                style={{
                  marginTop: '1rem',
                  padding: '0.5rem',
                  borderRadius: '0.5rem',
                  border: '1px solid #ccc',
                }}
                min={1}
              />
            </LabelStyle>
          </Box>
        )}

        {configSelect === 'separated' && (
          <>
            <LabelStyle style={{ marginTop: '2rem' }}>
              Finalizar separação
              <span>Será retirado da lista de separação e marcado como separado.</span>
            </LabelStyle>
            {!isScheduledTomorrow && (
              <LabelStyle
                style={{
                  marginTop: '2.2rem',
                  color: '#e63946',
                }}
              >
                A data de entrega desse pedido não permite que ele seja finalizado hoje.
                <span>
                  Esse pedido não pode ser finalizado pois a data de entrega dele é dia{' '}
                  {formattedDate}.
                </span>
              </LabelStyle>
            )}
          </>
        )}

        {configSelect === 'reset' && (
          <>
            <LabelStyle style={{ marginTop: '2rem' }}>
              Resetar separação
              <span>Pedido volta às configurações iniciais</span>
            </LabelStyle>

            {isTypeError.isCanceled ? (
              <LabelStyle
                style={{
                  marginTop: '2.2rem',
                  color: '#e63946',
                  fontWeight: 500,
                }}
              >
                Este pedido foi cancelado
                <span>
                  Retorne à tela de pedidos e selecione apenas pedidos que não foram cancelados
                </span>
              </LabelStyle>
            ) : null}

            {isTypeError.isPending ? (
              <LabelStyle
                style={{
                  marginTop: '2.2rem',
                  color: '#e63946',
                  fontWeight: 500,
                }}
              >
                Este pedido está pendente
                <span>
                  Retorne à tela de pedidos e selecione apenas pedidos que não estão pendentes
                </span>
              </LabelStyle>
            ) : null}
          </>
        )}

        {configSelect === '' ? (
          <ButtonStyle variant="contained" color="secondary" onClick={handleClose}>
            Fechar
          </ButtonStyle>
        ) : (
          <ButtonStyle
            disabled={
              (!isScheduledTomorrow && configSelect === 'separated') ||
              isLoading ||
              (configSelect === 'directedGroup' && !separationGroup)
            }
            variant="contained"
            color="secondary"
            onClick={handleChangeConfig}
          >
            {isLoading ? 'Alterando, aguarde...' : 'Salvar e fechar'}
          </ButtonStyle>
        )}
      </Box>
    </Modal>
  )
}

export const Edit = memo(forwardRef(Component))
