import { TableBody, TableHead, TableRow as TableRowMui } from '@material-ui/core'
import { CircleActive } from 'components'
import ColorRoute from 'components/ColorRoute'
import { IOrderDashboard } from 'interfaces/IDashboard'
import { useNavigate } from 'react-router'
import { animated, useTransition } from 'react-spring'
import { Table as TableShared, TableCellBody, TableCellHead } from 'shared'
import { formatNameClient } from 'utils'
import { mapTagColor } from 'utils/mapTagColor'

import { useStyles } from './styles'
import Tooltip from './Tooltip'

interface Props {
  orders: IOrderDashboard[]
}

const Table = ({ orders }: Props) => {
  const classes = useStyles()
  const navegate = useNavigate()

  orders.sort((a, b) => {
    if (a.queue_index > b.queue_index) {
      return -1
    }

    if (a.queue_index < b.queue_index) {
      return 1
    }

    return 0
  })

  const transitionSeparation = useTransition(orders, {
    keys: order => order.order_id,
    from: { opacity: 0 },
    enter: { opacity: 1 },
  })

  return (
    <TableShared>
      <TableHead>
        <TableRowMui>
          <TableCellHead
            style={{
              minWidth: '12px',
              paddingRight: 0,
              paddingLeft: 0,
            }}
            align="center"
            disabled
            column="separation"
          >
            {''}
          </TableCellHead>

          <TableCellHead
            style={{
              width: '25px',
              minWidth: '25px',
              paddingRight: 0,
              paddingLeft: 0,
            }}
            align="center"
            disabled
            column="separation"
          >
            {''}
          </TableCellHead>

          <TableCellHead
            style={{
              minWidth: '200px',
              maxWidth: '250px',
            }}
            align="center"
            disabled
            column="orderTotal"
          >
            Pedido
          </TableCellHead>

          <TableCellHead
            style={{
              minWidth: '230px',
              maxWidth: '300px',
            }}
            align="center"
            disabled
            column="clientName"
          >
            Cliente
          </TableCellHead>

          <TableCellHead
            style={{
              maxWidth: '170px',
            }}
            align="center"
            disabled
            column="route"
          >
            Rota
          </TableCellHead>

          <TableCellHead
            style={{
              maxWidth: '170px',
              padding: '16px 24px',
            }}
            align="center"
            disabled
            column="queue_index"
          >
            Fila
          </TableCellHead>

          <TableCellHead
            style={{
              maxWidth: '170px',
              padding: '16px 24px',
            }}
            align="center"
            disabled
            column="porcentage_scanned"
          >
            Peso bipado
          </TableCellHead>
        </TableRowMui>
      </TableHead>

      <TableBody>
        {transitionSeparation((style, order) => (
          <Tooltip isCritical={order?.is_critical}>
            <animated.tr
              style={style}
              className={`${classes.itemTable} ${order?.is_critical ? classes.clientCritical : ''}`}
              onClick={() => navegate(`../pedidos/${order.order_id}`)}
            >
              <TableCellBody style={{ padding: '0 16px 0 0' }}>
                <ColorRoute colorRoute={mapTagColor(order?.color_route)} />
              </TableCellBody>

              <TableCellBody style={{ paddingLeft: 0, paddingRight: 0 }}>
                {order?.checking_status === 1 && <CircleActive className={classes.circleActive} />}
              </TableCellBody>

              <TableCellBody align="center">{`#${order.order_id}`}</TableCellBody>

              <TableCellBody align="center">
                {order.client_data.client_type === 'PF'
                  ? formatNameClient(order.client_data.client_name)
                  : order.client_data?.client_trade}
              </TableCellBody>

              <TableCellBody align="center">{order.route ?? 'Sem rota'}</TableCellBody>

              <TableCellBody align="center">{order.queue_index}º</TableCellBody>

              <TableCellBody align="center">
                {Number(order?.porcentage_scanned).toFixed()}%
              </TableCellBody>
            </animated.tr>
          </Tooltip>
        ))}
      </TableBody>
    </TableShared>
  )
}

export default Table
