import AppProvider from 'hooks'
import { BrowserRouter } from 'react-router-dom'

import RoutesAplication from './Routes'

const Routes = () => (
  <BrowserRouter>
    <AppProvider>
      <RoutesAplication />
    </AppProvider>
  </BrowserRouter>
)

export default Routes
