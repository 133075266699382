import { usePopup } from 'hooks/usePopup'
import { BreakOrder } from 'interfaces/IBreakOrder'
import { IOnChange } from 'interfaces/IPagination'
import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { breakService } from 'services/breakService'
import { debaunce } from 'utils'

type BreaksOrders = BreakOrder & {
  totalItems: number
}

export const useBreakOrder = () => {
  const { addPopup } = usePopup()
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const controller = new AbortController()
  const [isLoading, setIsloading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const [count, setCount] = useState(0)
  const [data, setData] = useState<BreaksOrders[]>([])
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    search: searchParams.get('search') ?? '',
  })
  const searchDebaunce = debaunce({ fn: handleSearch, delay: 300 })
  const search = searchParams.get('search')

  function handleSearch(value: string) {
    setSearchParams(`search=${value}`)
    setParams(prevState => ({ ...prevState, search: value, page: 1 }))
  }

  const onChange = (newParams: IOnChange) => {
    setParams(prevState => ({ ...prevState, ...newParams }))
  }

  const pollingBreak = async (isError = false, isRefresh = false) => {
    try {
      const { results, totalCount } = await breakService.getBreakOrders(params, controller.signal)
      setCount(totalCount)
      setData(setTotalItems(results))
    } catch {
      if (isError === false && controller.signal.aborted === false) {
        addPopup({
          type: 'error',
          title: 'Erro ao buscar dados de quebra',
        })
      }
    } finally {
      if (controller.signal.aborted === false && isRefresh === false) {
        timeout.current = setTimeout(() => pollingBreak(true), 7000)
      }
      setIsloading(false)
    }
  }

  const setTotalItems = (data: BreakOrder[]) => {
    return data.map(order => ({ ...order, totalItems: order?.runouts?.length || 0 }))
  }

  useEffect(() => {
    // eslint-disable-next-line no-undef
    clearTimeout(timeout.current as NodeJS.Timeout)
    pollingBreak()

    return () => {
      controller.abort()
      // eslint-disable-next-line no-undef
      clearTimeout(timeout.current as NodeJS.Timeout)
    }
  }, [params])

  return {
    data,
    isLoading,
    searchDebaunce,
    search,
    pollingBreak,
    params,
    onChange,
    count,
  }
}
