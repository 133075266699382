import { usePopup } from 'hooks/usePopup'
import { ILostPrePicking } from 'interfaces/IAlert'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { alertService } from 'services/alertService'
import { IState } from 'store'

export const useLostPrePicking = () => {
  const controller = new AbortController()
  const {
    user: { userId },
  } = useSelector<IState, IState>(state => state)
  const { addPopup } = usePopup()
  const [isLock, setIsLock] = useState(false)
  const delay = 5000

  const toggleLock = (param?: boolean) => {
    setIsLock(state => param ?? !state)
  }

  const pollingCheck = async () => {
    await checkAlerts()

    if (controller.signal.aborted === false) {
      setTimeout(() => {
        pollingCheck()
      }, delay)
    }
  }

  const checkAlerts = async (isStart = false) => {
    try {
      const alerts = await alertService.getAlertNotification({
        status: 'pendente',
        controller,
      })
      const thereAlert = alerts.some(
        alert =>
          alert.kind === 'lost_pre_picking' &&
          Number((alert.related_alert as ILostPrePicking)?.attempted_by) === Number(userId),
      )
      if (thereAlert) {
        addPopup({
          title: 'Aguardando liberação do supervisor',
          type: 'info',
        })
        toggleLock(true)
        if (isStart) {
          setTimeout(() => {
            pollingCheck()
          }, delay)
        }
        return
      }
      controller.abort()
      toggleLock(false)
    } catch (err: any) {
      addPopup({
        title: 'Erro ao verificar alertas de pacotes perdidos',
        type: 'error',
        description: err?.message || err?.msg,
      })
    }
  }

  useEffect(() => {
    setIsLock(false)
    checkAlerts(true)
  }, [])

  return {
    isLock,
    toggleLock,
    startPolling: pollingCheck,
  }
}
