import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '60px',
    height: '60px',
    transform: 'translate(-50%, -50%)',
  },
  image: {
    position: 'relative',
    zIndex: 3,
    width: '100%',
    height: '100%',
    animation: '$rotate 0.7s infinite',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotateY(0deg)',
    },
    '100%': {
      transform: 'rotateY(360deg)',
    },
  },
})
