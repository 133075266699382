import { usePopup } from 'hooks/usePopup'
import { usePrePicking } from 'hooks/usePrePicking'
import { ILostPrePicking } from 'interfaces/IAlert'
import { IPrePickingV2 } from 'interfaces/IPrePickingV2'
import { useState, useRef, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { alertService } from 'services/alertService'
import { separationItemServiceV2 } from 'services/separationItemServiceV2'
import { IState } from 'store'
import { v4 } from 'uuid'

import { IProductScanned, IScanneds, IUserScannerProps } from './IUseScanner'
import { IModalError } from './ModalError'

export type TValue = 'data_matrix' | 'qr_code' | 'bar_code'

export type TValueBip = {
  value: string
  type: TValue
}

const getId = (message: string) => {
  const messageSplit = message?.split('. ')
  const invalidId = messageSplit?.[1]?.split(' ')?.[0] ?? ''
  const correctId = messageSplit?.[2]?.split(' ')?.[0] ?? ''
  return { invalidId, correctId }
}

export const useScanner = (props: IUserScannerProps) => {
  const { getIsTypeUnity } = usePrePicking()
  const { product, isAuthorized } = props
  const modalErrorRef = useRef<IModalError>(null)
  const { addPopup } = usePopup()
  const {
    user: { userId },
  } = useSelector<IState, IState>(state => state)

  const [valueBip, setValueBip] = useState<TValueBip>({
    value: '',
    type: '' as TValue,
  })
  const [loading, setLoading] = useState(props.propsLoading)
  const loadingRef = useRef<boolean>(props.propsLoading)
  const [openModalExclude, setOpenModalExclude] = useState(false)

  const { isTypeUnity } = useMemo(
    () => ({ isTypeUnity: getIsTypeUnity(product) }),
    [product, isAuthorized],
  )

  const [scanneds, setScanneds] = useState<IScanneds>(() =>
    (props.product.pre_pickings || []).reduce(
      (acc: IScanneds, prePicking) => ({
        totalValue:
          acc.totalValue + ((prePicking.item_weight || prePicking.purchase_value) as number),
        products: [
          ...acc?.products,
          {
            id: prePicking.unique_tag ? prePicking.id.toString() : v4(),
            isBarcode: false,
            valueBeeped: Number(prePicking.item_weight || prePicking.purchase_value),
            isAdmin: !prePicking.unique_tag,
            scaleId: Number(prePicking.scale_id),
            prePickingData: prePicking,
          },
        ],
      }),
      { totalValue: 0, products: [] },
    ),
  )

  const handleBreak = () => {
    const items = scanneds.products.map(item => item.valueBeeped)
    props.handleBranch(items.length ? items : undefined)
  }

  const handleSubmitQr = (value: string, type: TValue) => {
    if (loadingRef.current) return
    setValueBip({ value, type })
  }

  const updatedPrePickings = (prePickings: IPrePickingV2[]) => {
    const data = prePickings.reduce(
      (acc, prePickingData) => {
        return {
          totalValue:
            acc.totalValue +
            ((prePickingData.item_weight || prePickingData.purchase_value) as number),
          products: [
            ...acc.products,
            {
              id: prePickingData.unique_tag ? String(prePickingData.id) : v4(),
              valueBeeped: Number(prePickingData.item_weight || prePickingData.purchase_value),
              isAdmin: !prePickingData.unique_tag,
              prePickingData,
            },
          ],
        }
      },
      { totalValue: 0, products: [] } as IScanneds,
    )
    setScanneds(data)
  }

  const handleBip = async (valueQR: TValueBip) => {
    setLoadingRef(true)
    try {
      setValueBip({ value: '', type: '' as TValue })
      const response = await props.handleConfirm({
        productCode: valueQR.value,
        codeType: valueQR.type,
      })

      if (!response || !response.pre_pickings) {
        return
      }
      updatedPrePickings(response.pre_pickings)
    } catch (err: any) {
      await handleCommonErrors(err, valueQR)
    } finally {
      setLoadingRef(false)
    }
  }

  const handleCommonErrors = async (err: any, valueQR: TValueBip) => {
    if (err?.message?.includes('reporte ao supervisor para liberar')) {
      await handleLostPrePicking(valueQR.value, err)
    } else if (err?.message?.includes('Etiqueta inválida')) {
      addPopup({
        type: 'error',
        title: `Não é permitido etiqueta genérica para esse produto`,
        autoClose: false,
        description: err?.message || err?.msg || 'Contate o administrador',
      })
    } else if (err?.message?.includes('já foi separado')) {
      modalErrorRef.current?.dispatchError({
        typeError: 'repeatProduct',
        id: valueQR.value,
        message: err.message,
      })
    } else if (err?.message?.includes('Sku incorreto')) {
      const { invalidId, correctId } = getId(err?.message)
      modalErrorRef.current?.dispatchError({
        typeError: 'wrongSku',
        sku: { beeped: invalidId, correct: correctId },
      })
    } else if (err?.message?.includes('Produto base incorreto')) {
      const { invalidId } = getId(err?.message)
      modalErrorRef.current?.dispatchError({
        typeError: 'wrongProductProductBase',
        productBeeped: { productId: Number(invalidId) },
      })
    } else if (err?.message.includes('Etiqueta vencida')) {
      addPopup({
        type: 'error',
        title: `Pre picking #${valueQR.value} vencido`,
        description: 'Pre picking passou da validade, informe seu superior.',
        autoClose: false,
      })
    } else if (err?.message.includes('Porção incorreta')) {
      modalErrorRef.current?.dispatchError({
        typeError: 'wrongUnity',
        productBeeped: { message: err?.message },
      })
    } else if (err?.message.includes('Esse pacote excedeu')) {
      modalErrorRef.current?.dispatchError({
        typeError: 'overweight',
        weighings: { message: err?.message },
      })
    } else {
      addPopup({
        type: 'error',
        title: 'Erro ao confirmar pre picking',
        description: err?.message || err?.msg || 'Contate um administrador',
      })
    }
  }

  const handleResetAllScanneds = async () => {
    try {
      setLoadingRef(true)
      await separationItemServiceV2.pickResetAll(product.id)
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao resetar pre pickings de etiquetas novas',
        description: `Ocorreu um erro, contate o administrador`,
        autoClose: false,
      })
      return
    } finally {
      setLoadingRef(false)
    }
    setScanneds({ totalValue: 0, products: [] })
  }

  const handleExclude = async (qrcode: string, type: TValue) => {
    try {
      const data = await separationItemServiceV2.pickReset(product.id, {
        productCode: qrcode,
        codeType: type,
      })

      setScanneds(state => {
        let products: IProductScanned[] = []
        const isBarcode = !data.orderPrePickingId && !data.id
        const isUniqueTag = !!data.id
        const isNotUniqueTag = !!data.orderPrePickingId

        if (isBarcode) {
          products = state.products.slice(0, -1)
        } else if (isUniqueTag) {
          products = state.products.filter(prePicking => prePicking.prePickingData?.id !== data.id)
        } else if (isNotUniqueTag) {
          products = state.products.filter(
            prePicking =>
              prePicking.prePickingData?.generic_pre_picking_id !== data.orderPrePickingId,
          )
        }

        const totalValue = products.reduce((acc, item) => acc + item.valueBeeped, 0)
        const newState = { ...state, totalValue, products }
        return newState
      })

      addPopup({
        type: 'success',
        title: 'Sucesso ao excluir pre picking',
        description: `Pre picking #${Number(
          data?.id || data?.orderItemId || data?.orderPrePickingId,
        )} excluido com sucesso!`,
      })
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao excluir pre picking',
        description: err?.message || err?.msg || 'Contate um administrador',
      })
    }
  }

  const handleLostPrePicking = async (id: string, err: any) => {
    try {
      const alerts = await alertService.getAlertNotification({
        status: 'pendente',
      })
      const thereAlert = alerts.some(
        alert =>
          alert.kind === 'lost_pre_picking' &&
          Number((alert.related_alert as ILostPrePicking)?.attempted_by) === Number(userId),
      )
      if (thereAlert) {
        props.startPolling()
      }
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao pegar histórico do pre picking',
      })
    }

    modalErrorRef.current?.dispatchError({
      typeError: 'repeatProduct',
      id,
      message: err.message,
    })
  }

  const setLoadingRef = (value: boolean) => {
    setLoading(value)
    loadingRef.current = value
  }

  useEffect(() => {
    valueBip.value && handleBip(valueBip)
  }, [valueBip])

  return {
    scanneds,
    loading,
    isTypeUnity,
    handleBreak,
    setOpenModalExclude,
    handleResetAllScanneds,
    modalErrorRef,
    openModalExclude,
    handleExclude,
    handleSubmitQr,
  }
}
