import { Box, Button, Modal, Paper, Typography } from '@material-ui/core'
import CheckSvg from 'assets/images/Icon check.svg'
import DeniedSvg from 'assets/images/Icon denied.svg'
import LoadingSvg from 'assets/images/Icon loading.svg'
import { IProduct } from 'interfaces/IProduct'

import { useStyles } from './styles'

export interface IModalPermAdminProps {
  open: boolean
  onClose: () => void
  type: 'asking' | 'waiting' | 'authorized' | 'denied'
  onConfirm: (itemId: number, orderId: string) => void
  product: IProduct
  quantity: number
}

export type IModalType = 'asking' | 'waiting' | 'authorized' | 'denied'

const ModalPermAdmin = (props: IModalPermAdminProps) => {
  const { onClose, onConfirm, open, type, product, quantity } = props
  const classes = useStyles()

  if (type === 'asking') {
    return (
      <Modal open={open} onClose={onClose} className={classes.modal}>
        <Paper className={classes.paper}>
          <Typography className={classes.title}>Confirmar bipagem de novo SKU</Typography>
          <Typography className={classes.text}>
            Produto: <strong>{product.name}</strong>
          </Typography>
          <Typography className={classes.textQuantity}>
            Quantidade bipada: <strong>{quantity}</strong>
          </Typography>
          <Typography className={classes.helperText}>
            Ao clicar em confirmar, uma solicitação será enviada ao supervisor
          </Typography>
          <Box className={classes.containerButton}>
            <Button
              className={`${classes.buttonGeneral} ${classes.buttonCancel}`}
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              className={`${classes.buttonGeneral} ${classes.buttonConfirm}`}
              onClick={() => onConfirm(product.id, product.order)}
            >
              Confirmar
            </Button>
          </Box>
        </Paper>
      </Modal>
    )
  }

  if (type === 'waiting') {
    return (
      <Modal open={open} onClose={onClose} className={classes.modal}>
        <Paper className={`${classes.paper} ${classes.paperFlex}`}>
          <Typography className={classes.title}>Solicitação enviada</Typography>
          <img
            src={LoadingSvg}
            alt="loading"
            className={`${classes.image} ${classes.rotateAnimation}`}
          />
          <Typography className={classes.helperText}>
            Aguarde a autorização do supervisor
          </Typography>
        </Paper>
      </Modal>
    )
  }

  if (type === 'authorized') {
    return (
      <Modal open={open} onClose={onClose} className={classes.modal}>
        <Paper className={`${classes.paper} ${classes.paperFlex}`}>
          <Typography className={classes.title}>Permissão autorizada</Typography>
          <img src={CheckSvg} alt="check" className={`${classes.image}`} />
          <Typography className={classes.helperText}>Agora você pode bipar outro SKU</Typography>
        </Paper>
      </Modal>
    )
  }

  if (type === 'denied') {
    return (
      <Modal open={open} onClose={onClose} className={classes.modal}>
        <Paper className={`${classes.paper} ${classes.paperFlex}`}>
          <Typography className={classes.title}>Permissão recusada</Typography>
          <img src={DeniedSvg} alt="denied" className={`${classes.image}`} />
          <Button className={classes.button} onClick={onClose}>
            Voltar para a separação
          </Button>
        </Paper>
      </Modal>
    )
  }

  return null
}

export default ModalPermAdmin
