import {
  Box,
  styled,
  Accordion as AccordionMui,
  AccordionSummary as AccordionSummaryMui,
  AccordionDetails as AccordionDetailsMui,
  TableContainer as TableContainerMui,
  Button,
} from '@material-ui/core'

export const ContainerFilter = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '1rem',
})

export const ContainerScroll = styled(Box)(({ theme }) => ({
  overflow: 'auto',
}))

export const ContainerAccordions = styled(Box)(({ theme }) => ({
  marginTop: '2rem',
  minWidth: '900px',
}))

export const ContainerLabels = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '60px',
  margin: '0 16px',
  '& *': {},
  '& > span': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '180px',
    padding: '16px',
    color: '#757575',
    fontWeight: 500,
    fontSize: '1rem',
  },
  '& span:first-child': {
    width: '48px',
  },

  '& span:nth-child(4)': {
    justifyContent: 'center',
  },
}))

export const ContainerLabelsAccordion = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  '& > span': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '180px',
    padding: '16px',
    fontWeight: 400,
    color: '#353535',
  },
  '& span:nth-child(3)': {
    justifyContent: 'center',
  },
  '& span:nth-child(4)': {
    justifyContent: 'center',
    marginLeft: 'auto',
  },
}))

export const Accordion = styled(AccordionMui)(({ theme }) => ({
  '&::before': {
    content: 'none',
  },
  '&:nth-child(odd)': {
    background: '#F9F9F9',
  },
}))

export const AccordionSummary = styled(AccordionSummaryMui)(({ theme }) => ({
  '& .MuiAccordionSummary-expandIcon.MuiButtonBase-root': {
    marginRight: 0,
    fontSize: '24px !important',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0 !important',
  },
}))

export const AccordionDetails = styled(AccordionDetailsMui)(({ theme }) => ({}))

export const TableContainer = styled(TableContainerMui)(({ theme }) => ({
  background: '#ffffff',
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '10px',
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#A1CA4F',
    borderRadius: '9px',
  },
  '& table': {
    width: '100%',
    '& th': {
      padding: '8px 16px',
    },
    '& td': {
      padding: '8px 16px',
    },
    '& thead th': {
      color: '#757575',
      fontWeight: 500,
      fontSize: '1rem',
    },
    '& span': {
      display: 'flex',
    },
  },
}))

export const ButtonReplenish = styled(Button)(({ theme }) => ({
  background: '#89BD23',
  color: '#FFF',
  fontWeight: 400,
  padding: '8px 20px',
  borderRadius: '8px',
  fontSize: '15px',
  '&:disabled': {
    background: '#BDBDBD',
    color: '#FFF',
  },
}))
