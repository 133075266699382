import {
  styled,
  Box,
  Typography,
  LinearProgress as LinearProgressMui,
  Paper as PaperMui,
  Button,
  Theme,
  Checkbox,
} from '@material-ui/core'
import { TitlePage as TitlePageComponents, Title as TitleComponents } from 'components'

export const ContainerPage = styled(PaperMui)(({ theme }) => ({
  maxWidth: '900px',
  margin: '0 auto 1rem auto',
  padding: '1rem 1rem 3rem 1rem',
}))

export const TitlePage = styled(TitlePageComponents)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
})

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '0.5rem',
})

export const ClientName = styled(Typography)(({ theme }) => ({
  color: '#353535',
  fontSize: '1.8rem',
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  [theme.breakpoints.between(0, 650)]: {
    fontSize: '1.3rem',
  },
}))

export const OrderId = styled('strong')(({ theme }) => ({
  color: '#6F6F6F',
  fontSize: '1.2rem',
  fontWeight: 700,
  lineHeight: '2rem',
  [theme.breakpoints.between(0, 650)]: {
    fontSize: '1.1rem',
  },
}))

export const ContainerContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0 .5rem',
  flexDirection: 'column',
}))

export const Instructions = styled(Box)(({ theme }) => ({
  background: '#ffffff',
  padding: '1.5rem',
  marginBottom: '2.5rem',
}))

export const ContainerSeparatinos = styled(Box)(({ theme }) => ({
  width: '100%',
}))

export const Title = styled(TitleComponents)(({ theme }) => ({
  color: '#353535',
}))

export const LinearProgress = styled(LinearProgressMui)(({ theme }) => ({
  width: '100%',
  height: '6px',
}))

export const Card = styled(Box)(({ theme }) => ({
  padding: '2rem .5rem',
  width: '100%',
}))

export const CardItem = styled(Box)(({ theme }) => ({
  padding: '0.5rem',
  display: 'grid',
  gridTemplateColumns: '60px 1fr',
  gridColumnGap: '1rem',
  border: '1px solid #89BD23',
  borderRadius: '5px',
  '&:not(:last-child)': {
    marginBottom: '1rem',
  },
}))

export const TitleSeparation = styled(Typography)(({ theme }) => ({
  color: '#353535',
  fontSize: '1.5rem',
  fontWeight: 600,
  marginBottom: '2rem',
}))

export const ContainerStatus = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  gap: '1rem',
}))

export const ImageItem = styled('img')(({ theme }) => ({
  width: 60,
  height: 60,
  borderRadius: '5px',
  gridColumnStart: 1,
  gridColumnEnd: 2,
  gridRowStart: 1,
  gridRowEnd: 3,
}))

export const NameItem = styled('span')(({ theme }) => ({
  fontSize: '1.3rem',
  color: '#353535',
  gridColumnStart: 2,
  gridColumnEnd: 3,
  gridRowStart: 1,
  gridRowEnd: 2,
}))

export const QuantityItem = styled('span')(({ theme }) => ({
  fontSize: '1.2rem',
  color: '#353535',
  gridColumnStart: 2,
  gridColumnEnd: 3,
  gridRowStart: 2,
  gridRowEnd: 3,
}))

export const BreakQuantity = styled('span')(({ theme }) => ({
  fontSize: '1.2rem',
  color: '#EB001B',
  gridColumnStart: 2,
  gridColumnEnd: 3,
  gridRowStart: 3,
  gridRowEnd: 4,
}))

export const SeparatedContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '1rem 0',
  justifyContent: 'flex-start',
  fontSize: '1rem',
  gridColumnStart: 1,
  gridColumnEnd: 3,
  gridRowStart: 4,
  gridRowEnd: 5,
  '& strong': {
    fontWeight: 500,
  },
}))

export const ButtonCheckSeparated = styled('input')(({ theme }) => ({
  width: '24px',
  height: '24px',
  border: '1px solid #D9D9D9',
  borderRadius: '4px',
  backgroundColor: '#ffffff',
  '&:hover': {
    borderColor: '#EB001B',
  },
  '&:checked': {
    backgroundColor: '#EB001B',
  },
}))

export const ButtonCheckItem = styled(Button)(({ theme }) => ({
  gridColumnStart: 2,
  gridColumnEnd: 3,
  gridRowStart: 4,
  gridRowEnd: 5,
  width: '160px',
  display: 'block',
  margin: '2rem 0 0 auto',
  background: '#89BD23',
  padding: '1rem 0',
  color: '#ffffff',
  '&:hover': {
    background: '#89BD23',
  },
  '&:disabled': {
    color: '#ffffff',
    filter: 'grayScale(1)',
  },
}))

export const QuantityBoxes = styled(Box)(({ theme }) => ({
  fontSize: '1.2rem',
  marginTop: '1rem',
  color: '#353535',
  fontWeight: 500,
}))

export const CheckBox = styled(Checkbox)(({ theme }) => ({
  gridColumnStart: 2,
  gridColumnEnd: 3,
  gridRowStart: 3,
  gridRowEnd: 4,
  margin: '1rem 0 0 auto',
  '&:hover': {},
}))

export const Alert = styled(Box)(
  ({ colorBackground, colorBorder }: { colorBackground: string; colorBorder: string }) => ({
    width: '100%',
    background: colorBackground,
    border: `1px solid ${colorBorder}`,
    borderRadius: '8px',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colorBorder,
    fontSize: '1rem',
    margin: '0.6rem 0',
    padding: '8px 1rem',
    '& span': {
      width: '100%',
      wordWrap: 'break-word',
    },
  }),
)
