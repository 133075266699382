import { IResponsePackingList } from 'interfaces/IPackingList'
import { getDeliveryDate } from 'utils/dateNow'

import { api, ApiService } from './api'

class PackingList {
  constructor(private readonly api: ApiService) {}

  async getRoutes(scheduledDate?: string): Promise<IResponsePackingList> {
    const { day, month, year } = getDeliveryDate()
    return this.api.get(carambolaUrl + '/loading-conference/packing-list', {
      params: {
        scheduledDate: scheduledDate || `${year}-${month}-${day}`,
      },
    })
  }

  async printSingleRoute(data: object): Promise<any> {
    return this.api.post(carambolaUrl + '/loading-conference/print-packing-list/single-route', data)
  }

  async printManyRoutes(data: object[]): Promise<any> {
    return this.api.post(carambolaUrl + '/loading-conference/print-packing-list/many-routes', data)
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const packingList = new PackingList(api)
