import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
  },
  textTypeNotification: {
    fontSize: '.9rem',
    fontWeight: 500,
    textTransform: 'none',
    color: '#353535',
  },
  textName: {
    color: '#757575',
    fontSize: '.9rem',
    fontWeight: 500,
  },
  button: {
    color: '#618619',
    textTransform: 'none',
  },
}))
