import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: '1600px',
    margin: '0 auto',
    padding: '0.25rem',
  },
})
