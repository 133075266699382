import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2rem',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imgCompleted: {
    width: '6rem',
  },
  title: {
    color: '#353535',
    fontSize: '1.4rem',
    fontWeight: 400,
    margin: '1.5rem 0 0.4rem',
    textAlign: 'center',
  },
  subtitle: {
    color: '#757575',
    textAlign: 'center',
    fontSize: '1.2rem',
    'p + &': {
      marginTop: '0.7rem',
    },
  },
  button: {
    marginTop: '4.3rem',
    fontSize: '1rem',
    padding: '0.5rem 1rem',
    minWidth: '11rem',
  },
})
