import { HTMLAttributes, memo, useEffect, useState } from 'react'

interface Props extends HTMLAttributes<HTMLSpanElement> {
  time?: Date | null
  isDays?: boolean
  isHours?: boolean
  stop?: boolean
}

type ITime = {
  days: string
  seconds: string
  minutes: string
  hours: string
}

const calculateTimeLeft = (startTime: Date): ITime => {
  const msec = +new Date() - +startTime

  const time = {
    days: String(Math.floor(msec / (1000 * 60 * 60 * 24))),
    hours: String(Math.floor((msec / (1000 * 60 * 60)) % 24)).padStart(2, '0'),
    minutes: String(Math.floor((msec / 1000 / 60) % 60)).padStart(2, '0'),
    seconds: String(Math.floor((msec / 1000) % 60)).padStart(2, '0'),
  }

  return time
}

const Timer = (props: Props) => {
  const { time, isDays, isHours, stop, ...rest } = props
  const [startTime, setStartTime] = useState(new Date())
  const [timer, setTimer] = useState<ITime>(calculateTimeLeft(startTime))

  useEffect(() => {
    if (stop && time) {
      setTimer(calculateTimeLeft(new Date(time)))
    }
  }, [])

  useEffect(() => {
    if (!stop && time) {
      setStartTime(new Date(time))
    }

    if (!stop && !time) {
      setStartTime(new Date())
    }
  }, [time])

  useEffect(() => {
    let timer: any = null

    if (!stop) {
      timer = setTimeout(() => {
        setTimer(calculateTimeLeft(startTime))
      }, 1000)
    }
    return () => timer && clearTimeout(timer)
  })

  const { days, hours, minutes, seconds } = timer

  return (
    <span {...rest}>{`${isDays ? days + ' dias,' : ''} ${
      isHours ? hours + ':' : ''
    }${minutes}:${seconds}`}</span>
  )
}

export default memo(Timer)
