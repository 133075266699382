import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  styled,
  Tooltip,
  Collapse,
  List,
  Box,
} from '@material-ui/core'
import { INavConfig } from 'interfaces/INavConfig'
import { memo, useState } from 'react'
import { AiFillCaretUp } from 'react-icons/ai'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { Link as LinkRouter } from 'react-router-dom'

export const Link = styled(LinkRouter)({
  display: 'flex',
  textDecoration: 'none',
  alignItems: 'center',
  width: '100%',
  color: '#5e5d5d',
  transition: '0.2s',
  paddingLeft: '5px',
  height: '3.125rem',
})

const useStyles = makeStyles({
  item: {
    display: 'flex',
    alignItems: 'center',
    color: '#5F5E69',
    justifyContent: 'center',
    width: '70%',
    minHeight: '3.12rem',
    borderRadius: '6px',
    '&:hover': {
      background: 'rgba(137, 189, 35, 0.2)',
    },
    transition: '.2s',
    padding: 0,
    '& + div': {
      marginTop: '6px',
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: '#89BD23',
    },
  },
  iconItem: {
    paddingLeft: '0px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    width: '70%',
    height: '3.125rem',
    background: 'rgba(137, 189, 35, 0.2)',
  },
  itemOpen: {
    width: '90%',
  },
  linkActive: {
    color: '#6D9422',
    padding: '0',
  },
  link: {
    padding: '0',
  },
})

interface ItemNavProps {
  open: boolean
  pathname: string
  item: INavConfig
  handleClose?: () => void | undefined
  handleSeeToggle: () => void
}

const ItemNav = (props: ItemNavProps) => {
  const { open, handleClose, pathname, item, handleSeeToggle } = props
  const classes = useStyles()
  const [isOpen, setOpenNav] = useState(false)
  const { title, path, icon: IconComponent, children } = item

  const handleCloseNav = () => {
    if (handleClose) {
      handleClose()
    }
  }

  const handleOpenNav = () => {
    if (!open) {
      return handleSeeToggle()
    }

    setOpenNav(state => !state)
  }

  if (children) {
    return (
      <>
        <ListItem
          button
          onClick={handleOpenNav}
          className={`${classes.item} ${
            pathname.includes(path) && classes.active
          } ${open && classes.itemOpen}`}
        >
          {open ? (
            <>
              <ListItem
                className={
                  pathname.includes(path) ? classes.linkActive : undefined
                }
                style={{ paddingLeft: '6px', paddingRight: '0px' }}
              >
                <ListItemIcon className={classes.iconItem}>
                  <IconComponent
                    size={24}
                    style={{
                      color: pathname.includes(path) ? '#89BD23' : '#B0AEAE',
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={title} />
                {isOpen ? (
                  <FaAngleUp
                    size={15}
                    style={{ marginLeft: '25px', color: '#757575' }}
                  />
                ) : (
                  <FaAngleDown size={15} style={{ color: '#757575' }} />
                )}
              </ListItem>
            </>
          ) : (
            <Tooltip title={title} arrow placement="right">
              <Box>
                <ListItem
                  onClick={handleCloseNav}
                  className={
                    pathname.includes(path) ? classes.linkActive : classes.link
                  }
                >
                  <ListItemIcon className={classes.iconItem}>
                    <IconComponent
                      size={24}
                      style={{
                        color: pathname.includes(path) ? '#89BD23' : '#B0AEAE',
                      }}
                    />
                  </ListItemIcon>
                </ListItem>
              </Box>
            </Tooltip>
          )}
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List disablePadding>
            {open &&
              children?.map(itemSub => (
                <Link
                  key={itemSub?.title}
                  to={itemSub?.path}
                  onClick={handleCloseNav}
                >
                  <ListItemIcon className={classes.iconItem}>
                    <AiFillCaretUp
                      size={20}
                      style={{
                        color: pathname.includes(itemSub?.path)
                          ? '#618619'
                          : '#5F5E69',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={itemSub.title}
                    style={{
                      color: pathname.includes(itemSub?.path)
                        ? '#618619'
                        : '#5F5E69',
                    }}
                  />
                </Link>
              ))}
          </List>
        </Collapse>
      </>
    )
  }
  return (
    <>
      <ListItem
        button
        className={`${classes.item} ${
          pathname.includes(path) && classes.active
        } ${open && classes.itemOpen}`}
      >
        {open ? (
          <>
            <Link
              to={path}
              onClick={handleCloseNav}
              className={
                pathname.includes(path) ? classes.linkActive : undefined
              }
            >
              <ListItemIcon className={classes.iconItem}>
                <IconComponent
                  size={24}
                  style={{
                    color: pathname.includes(path) ? '#89BD23' : '#B0AEAE',
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={title} />
            </Link>
          </>
        ) : (
          <Tooltip title={title} arrow placement="right">
            <Box>
              <Link
                to={path}
                onClick={handleCloseNav}
                className={
                  pathname.includes(path) ? classes.linkActive : classes.link
                }
              >
                <ListItemIcon className={classes.iconItem}>
                  <IconComponent
                    size={24}
                    style={{
                      color: pathname.includes(path) ? '#89BD23' : '#B0AEAE',
                    }}
                  />
                </ListItemIcon>
              </Link>
            </Box>
          </Tooltip>
        )}
      </ListItem>
    </>
  )
}

export default memo(ItemNav)
