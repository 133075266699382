import {
  alpha,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Modal as MuiModal,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import { enModalStatus } from 'interfaces/enModal'
import { CSSProperties, ReactNode } from 'react'

import { Error } from './Error'
import { Success } from './Success'

interface IModalProps {
  title: string | ReactNode
  titleSuccess?: string
  titleError?: string
  handleClose: () => void
  handleSend?: () => Promise<void> | void
  open: boolean
  children: ReactNode
  status?: enModalStatus
  style?: CSSProperties
  haventCloseButton?: boolean
  backdropClick?: boolean
  rightButton?: boolean
  maxHeight?: number | string
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0.9rem',
  },
  container: {
    background: '#FFFFFF',
    maxWidth: '41.5rem',
    width: '100%',
    minHeight: '24rem',
    maxHeight: '85vh',
    padding: '0',
    borderRadius: '6px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A1CA4F',
      borderRadius: '9px',
    },
  },
  header: {
    position: 'sticky',
    top: '0',
    left: '0',
    width: '100%',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
    backgroundColor: alpha('#FFFFFF', 0.72),
    zIndex: 2,
    padding: '1.25rem 0',
  },
  closeBtn: {
    position: 'absolute',
    top: '1rem',
    left: '1rem',
    background: '#FAFAFA',
    height: '2.5rem',
    width: '2.5rem',
  },
  title: {
    fontFamily: '"Inter", sans-serif',
    fontSize: '1.25rem',
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: '1rem',
  },
  subtitle: {
    fontFamily: '"Inter", sans-serif',
    fontSize: '1.10rem',
    fontWeight: 500,
    textAlign: 'left',
    marginLeft: '1.5rem',
  },
  loading: {
    margin: '5rem auto 0',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#D70077',
    display: 'block',
  },
  content: {
    width: '100%',
    height: 'calc(100% - 70px)',
    padding: '0 1.25rem 1.25rem',
    position: 'relative',
  },
  rightButton: {
    left: 'initial',
    right: '1rem',
  },
}))

const Modal = (props: IModalProps) => {
  const {
    children,
    handleClose,
    handleSend,
    open,
    title,
    titleSuccess,
    titleError,
    status = enModalStatus.initial,
    haventCloseButton,
    backdropClick = true,
    rightButton,
    maxHeight,
  } = props
  const classes = useStyles({ maxHeight })

  return (
    <MuiModal
      open={open}
      onClose={e => {
        backdropClick && handleClose()
      }}
      className={classes.root}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Container className={classes.container} style={props?.style}>
        <Box component="header" className={classes.header}>
          {!haventCloseButton ? (
            <IconButton
              className={`${classes.closeBtn} ${rightButton && classes.rightButton}`}
              onClick={handleClose}
            >
              <CloseIcon style={{ fontSize: '1.4rem' }} />
            </IconButton>
          ) : null}

          <Typography className={classes.title}>{title}</Typography>
        </Box>
        <Box justifyContent="center" className={classes.content}>
          {status === enModalStatus.pending && (
            <CircularProgress size="3.1rem" className={classes.loading} />
          )}

          {status === enModalStatus.initial && children}

          {status === enModalStatus.success && (
            <Success title={titleSuccess} handleClose={handleClose} />
          )}

          {status === enModalStatus.error && (
            <Error title={titleError} handleSend={handleSend} handleClose={handleClose} />
          )}
        </Box>
      </Container>
    </MuiModal>
  )
}

export default Modal
