import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    margin: '1.25rem 0 1rem',
  },
  bar: {
    display: 'block',
    width: '0.9rem',
    height: '3.125rem',
    background: '#89BD23',
    borderRadius: '4px',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: '#353535',
    fontFamily: '"Inter", sans-serif',
    position: 'relative',
  },
})
