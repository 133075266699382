import { api, ApiService } from './api'

export interface IConferenceItem {
  id: number
  item_id: number
  separated: boolean
}

class ConferenceItemService {
  constructor(private readonly api: ApiService) {}

  async getAll(params: { orderId?: string }): Promise<IConferenceItem[]> {
    return this.api.get(carambolaUrl + '/order-conference/start-conference/get-items', { params })
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const conferenceItemService = new ConferenceItemService(api)
