import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { ContainerPage, TitlePage } from 'components'
import { CardOperator, LoadingPage } from 'components/pages/Operators'
import { usePopup } from 'hooks/usePopup'
import { ISeparators } from 'interfaces/ISeparator'
import { useEffect, useRef, useState } from 'react'
import { BsFilter } from 'react-icons/bs'
import { MdOutlineSearchOff } from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'
import { separatorService } from 'services/separatorService'
import { MenuPopover, Search } from 'shared'
import { formatName, debaunce } from 'utils'
import { IGroup, permissionsAplication } from 'interfaces/IUser'
import { useStyles } from './styles'

const Operators = () => {
  const controller = new AbortController()
  const { addPopup } = usePopup()
  const userRef = useRef(null)
  const classes = useStyles()
  const [searchParams, setSearchParams] = useSearchParams()
  const searchDebaunce = debaunce({ fn: handleSearch, delay: 300 })
  const [isLoading, setIsLoading] = useState(true)
  const [FilterOpen, setFilterOpen] = useState(false)
  const [filterValue, setFilterValue] = useState(0)
  const [listPermission, setListPermission] = useState<IGroup[]>([])
  const [separators, setSeparators] = useState<ISeparators[]>([])
  const search = searchParams.get('search')

  const handleClick = () => {
    setFilterOpen(state => !state)
  }

  function handleSearch(value: string) {
    setSearchParams(`search=${value}`)
  }

  const init = async () => {
    try {
      setIsLoading(true)
      const data = await separatorService.getSeparators(
        search || undefined,
        controller.signal,
        filterValue || 23,
      )
      setSeparators(
        data?.results.map(operator => ({
          ...operator,
          username: formatName(operator.username) as string,
        })),
      )
    } catch {
      if (controller.signal.aborted === false) {
        addPopup({
          type: 'error',
          title: 'Erro ao buscar usuários',
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleGetPermissions = async () => {
    try {
      const response = await separatorService.getPermissions()

      setListPermission(
        response.map(permission => ({
          ...permission,
          value: permissionsAplication[permission.name],
        })),
      )
    } catch {
      addPopup({
        type: 'error',
        title: 'Erro ao receber permissões',
      })
    }
  }

  const filteredSeparator = separators.filter(item => {
    if (!search) return true
    return item.username.toLowerCase().includes(search.trim().toLocaleLowerCase())
  })

  useEffect(() => {
    handleGetPermissions()
  }, [])

  useEffect(() => {
    init()

    return () => {
      controller.abort()
    }
  }, [filterValue, search])

  return (
    <>
      <ContainerPage>
        <Box component="header" flexWrap="wrap" className={classes.header}>
          <TitlePage>Usuários</TitlePage>

          <Box className={classes.headerComponents}>
            <Box className={classes.filters}>
              <Typography className={classes.filter} ref={userRef} onClick={handleClick}>
                Filtros
                <BsFilter size="1.5rem" />
              </Typography>

              <MenuPopover open={FilterOpen} onClose={handleClick} anchorEl={userRef.current} arrow>
                <Box className={classes.boxPermissions}>
                  <FormGroup>
                    <RadioGroup
                      value={filterValue}
                      onChange={e => setFilterValue(Number(e.target.value))}
                    >
                      <FormControlLabel value={null} control={<Radio />} label="Todos" />

                      {listPermission.map(permission => (
                        <FormControlLabel
                          key={permission.id}
                          value={permission?.id}
                          control={<Radio />}
                          label={permission?.value}
                        />
                      ))}
                    </RadioGroup>
                  </FormGroup>
                </Box>
              </MenuPopover>

              <Search
                placeholder="Quem você busca?"
                onChange={e => searchDebaunce(e.target.value)}
                defaultValue={search}
                className={classes.search}
                autoFocus
              />
            </Box>
          </Box>
        </Box>

        <Grid container spacing={2}>
          {isLoading && <LoadingPage />}

          {!isLoading && filteredSeparator.length === 0 && (
            <Typography className={classes.noOperator}>
              <MdOutlineSearchOff size={50} />
              Nenhum usuário encontrado
            </Typography>
          )}

          {filteredSeparator.map(separator => (
            <Grid
              key={separator.id}
              container
              item
              xs={6}
              sm={4}
              md={3}
              lg={2}
              justifyContent="center"
            >
              <CardOperator {...separator} />
            </Grid>
          ))}
        </Grid>
      </ContainerPage>
    </>
  )
}

export default Operators
