import { Box, makeStyles, styled, Theme } from '@material-ui/core'
import { Accordion as AccordionShared, AccordionSummary as AccordionSummaryShared } from 'shared'

export const Accordion = styled(AccordionShared)(() => ({
  '& .MuiAccordionDetails-root': {
    '&::-webkit-scrollbar': {
      width: '4px',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A1CA4F',
      borderRadius: '9px',
    },
  },
}))

export const AccordionSummary = styled(AccordionSummaryShared)(
  ({ theme, isFastTrack }: { theme: Theme; isFastTrack?: boolean }) => ({
    background: isFastTrack ? '#ab98ee20' : '#fff',
    flexDirection: 'row',
    height: '64px',
    [theme.breakpoints.down(570)]: {
      height: 'auto',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: '1rem',
      [theme.breakpoints.down(1000)]: {
        gap: '1.5rem',
      },
      [theme.breakpoints.down(400)]: {
        alignItems: 'center !important',
        gap: '0.4rem',
        paddingRight: '0',
        paddingLeft: '1rem',
      },
    },
  }),
)

export const TrackInfo = styled(Box)(theme => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1rem',
  '& .MuiTypography-root': {
    fontWeight: 500,
    color: '#353535',
  },
  [theme.theme.breakpoints.down(1000)]: {
    maxWidth: '130px',
  },
}))

export const ImageContainer = styled(Box)({
  background: '#757575',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.3rem',
  borderRadius: '22.5px',
  height: '35px',
  width: '35px',
})

export const useStyles = makeStyles(theme => ({
  username: {
    marginLeft: '0.7rem',
    maxWidth: '200px',
    [theme.breakpoints.down(570)]: {
      marginLeft: '0',
    },
  },
  label: {
    marginRight: '1rem',
    fontSize: '0.9rem',
    color: '#757575',
    '& strong': {
      color: '#ef233c',
    },
  },
  labelTime: {
    letterSpacing: '0.6px',
    marginRight: '1.8rem',
    fontSize: '0.9rem',
    color: '#757575',
  },
  separationLabel: {
    marginLeft: '3.5em',
    marginRight: '1rem',
    fontSize: '0.9rem',
    color: '#757575',
    '& strong': {
      color: '#353535',
    },
    [theme.breakpoints.down(570)]: {
      marginLeft: '0',
    },
  },
  info: {
    color: '#353535',
    fontWeight: 500,
  },
  alert: {
    color: '#757575',
    fontSize: '1.3rem',
    textAlign: 'center',
    width: '100%',
    fontWeight: 500,
  },
  containerButton: {
    [theme.breakpoints.down(600)]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '20%',
      marginTop: '1rem',
    },
  },
  containerInfos: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    width: '100%',
    gap: '1rem',
    [theme.breakpoints.down(570)]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start !important',
      gap: '0.4rem',
      paddingRight: '0',
      paddingLeft: '1rem',
    },
  },
  text: {
    display: 'flex',
    alignItems: 'center',
  },
  iconPlayPause: {
    color: '#89BD23',
    fontSize: '24px',
  },
}))
