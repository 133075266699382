import { Box, Button, styled } from '@material-ui/core'

export const Header = styled(Box)({
  display: 'flex',
  gap: '1rem',
  color: '#757575',
  fontWeight: 500,
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
  },
})

export const ButtonFilter = styled(Button)(
  (theme: { colorIcon: string; isSelected?: boolean }) => ({
    color: '#757575',
    border: theme.isSelected ? '1px solid #757575' : '',
    marginLeft: '0.5rem',
    height: '2rem',
    borderRadius: 'calc(2rem /2)',
    width: '7rem',
    '& svg': {
      marginRight: '0.5rem',
      fontSize: '10px',
      color: theme.colorIcon,
    },
  }),
)

export const ButtonPrinterAll = styled(Button)({
  height: '2.5rem',
  color: '#FFF',
  backgroundColor: '#89BD23',
  fontSize: '0.8rem',
  marginLeft: '0.5rem',
  borderRadius: '8px',
  fontWeight: 400,
  width: '9rem',
  '& svg': {
    fontSize: '19.5px',
    marginRight: '5px',
  },
})
