import { Box } from '@material-ui/core'

import { useStyles } from './styles'

interface CircleActiveProps {
  className?: string
  width?: string
  color?: string
}

export const CircleActive = ({
  className,
  width,
  color,
}: CircleActiveProps) => {
  const classes = useStyles({ width, color })

  return (
    <Box className={`${classes.root} ${className}`}>
      <span className={classes.circle} />
      <span className={classes.circle} />
      <span className={classes.circle} />
      <span className={classes.circle} />
    </Box>
  )
}
