import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import React from 'react'
import ReactDOM from 'react-dom'
import ThemeProvider from 'theme'

import App from './App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>,
  document.getElementById('root'),
)

reportWebVitals()
