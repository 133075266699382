import { usePopup } from 'hooks/usePopup'
import { useDispatch, useSelector } from 'react-redux'
import { ISettingsRequest, settingsService } from 'services/settingsService'
import { IState } from 'store'
import { updateSettings as updateSettingsLocal } from 'store/reducers/settings/actions'

export const useConfig = () => {
  const dispatch = useDispatch()
  const { addPopup } = usePopup()

  const settings = useSelector<IState, ISettingsRequest>(state => state.settings)

  const getSetting = async () => {
    try {
      const dataSettings = await settingsService.getSettings()
      const dataSeparationGroups = await settingsService.getSeparationGroup()
      dispatch(
        updateSettingsLocal({
          ...dataSettings,
          numberOfSeparators: dataSettings?.number_of_separators || 0,
          goalSeparate: dataSettings?.goal_separate || 1,
          peakDays: dataSettings?.peak_days || [],
          separationGroups: dataSeparationGroups,
          palletLimitWeight: dataSettings?.pallet_limit_weight,
          palletLimitWholesaleBox: dataSettings?.pallet_limit_wholesale_box,
          prePickingValidityCheck: dataSettings?.pre_picking_validity_check,
          PendingOrderBlocksLoading: dataSettings?.pending_order_blocks_loading,
        }),
      )
    } catch {
      addPopup({
        type: 'error',
        title: 'Erro ao buscar configurações',
      })
    }
  }

  const updateSettings = async (value: {
    track?: number
    separation?: number
    palletLimitWeight?: number
    palletLimitWholesaleBox?: number
  }) => {
    try {
      await settingsService.updateSettings({ ...settings, ...value })

      dispatch(updateSettingsLocal({ ...settings, ...value }))

      addPopup({
        type: 'success',
        title: 'Sucesso ao atualizar configurações',
      })

      getSetting()
    } catch {
      addPopup({
        type: 'error',
        title: 'Erro ao atualizar configuração',
      })
    }
  }

  return {
    getSetting,
    updateSettings,
  }
}
