import { mapErrors } from 'errors/mapErrors'
import { usePopup } from 'hooks/usePopup'
import { IStep } from 'interfaces/IFastTrack'
import { IOrder } from 'interfaces/IOrder'
import { useDispatch, useSelector } from 'react-redux'
import { separationItemService } from 'services/separationItemService'
import { separationWorkService } from 'services/separationWorkService'
import { settingsService } from 'services/settingsService'
import { IState } from 'store'
import { updateOrder, updateWork } from 'store/reducers/orderFastTrack/actions'

import { IError, ScreenState } from '.'

interface Props {
  openScannerTag: () => void
  openBoxCountToPrint: () => void
  openPallet: () => void
  setSteps: (x: IStep[]) => void
  setScreenState: (x: ScreenState) => void
  setError: (x: IError) => void
  openBoxCountByStep?: (x: { callback: (boxes: number) => Promise<void>; step: IStep }) => void
  closeBoxCountByStep: () => void
  confirmStep: (...rest: any) => Promise<void>
}

export const isSeparationCompleted = (steps: IStep[]) => {
  return steps.every(step => step.items.every(item => item.status !== 1))
}

export const useRecovery = (props: Props) => {
  const {
    setSteps,
    setScreenState,
    openScannerTag,
    openBoxCountToPrint,
    openBoxCountByStep,
    closeBoxCountByStep,
    openPallet,
    confirmStep,
    setError,
  } = props
  const dispatch = useDispatch()
  const { addPopup } = usePopup()
  const { infos } = useSelector<IState, IState>(state => state)
  const hasCountByStep = useSelector<IState, boolean>(state => {
    const separationGroup = state.settings.separationGroups.find(
      separationGroup =>
        Number(separationGroup.separation_group) === Number(state.infos.runningMachine),
    )
    return !!separationGroup?.has_count_by_step
  })

  const makeError = (data: IError) => {
    setScreenState('viewError')
    setError(data)
  }

  const recoveryToScanTag = async (order: IOrder, count = 0) => {
    try {
      const groups = await settingsService.getSeparationGroup()
      const group = groups?.find(
        group => Number(group.separation_group) === Number(order?.separation_group),
      )
      const hasScanner = group?.cam_scanner || group?.infrared_scanner

      if (order?.is_already_print_stickers && hasScanner) {
        openScannerTag()
      } else {
        openBoxCountToPrint()
      }
    } catch (err: any) {
      handleRetryError(order, count, err)
    }
  }

  const handleRetryError = (order: IOrder, count: number, err: any) => {
    if (count < 3) {
      addPopup({
        type: 'info',
        title: 'Erro ao buscar configurações',
        description: 'Tentando novamente...',
      })
      setTimeout(() => {
        recoveryToScanTag(order, count + 1)
      }, 3000)
    } else {
      addPopup({
        type: 'error',
        title: 'Erro ao buscar configurações',
        description: mapErrors(err.message),
      })
    }
  }

  const makePendingOrder = async (data: { order: IOrder; steps: IStep[]; workId: number }) => {
    setSteps(data.steps)

    const stepWithBoxesPending = data.steps.find(step => {
      return (
        step.is_finished && step.accounted_boxes === null && !!step.items.length && hasCountByStep
      )
    })

    if (stepWithBoxesPending) {
      openBoxCountByStep &&
        openBoxCountByStep({
          callback: async boxes => {
            await confirmStep(
              stepWithBoxesPending.step,
              data.steps,
              boxes,
              data.order.order_id,
              data.workId,
            )
            closeBoxCountByStep()
            if (isSeparationCompleted(data.steps)) {
              await recoveryToScanTag(data.order)
            }
          },
          step: stepWithBoxesPending,
        })
    } else if (isSeparationCompleted(data.steps)) {
      await recoveryToScanTag(data.order)
    }

    if (data.order.is_palletized) {
      openPallet()
    }

    setScreenState('viewOrderSeparation')
  }

  const makeRecovery = async () => {
    try {
      setScreenState('viewLoadingOrderPending')
      const { data } = await separationWorkService.checkWork({
        level: infos.separation as number | 'caixa pronta',
        track: infos.runningMachine as number,
      })

      dispatch(updateWork(data as any))
      dispatch(updateOrder(data.order))

      const responseCheckItems = await separationItemService.checkItems({
        orderId: data?.order?.order_id,
        separationId: data?.separation?.id,
        workId: data.id,
      })

      makePendingOrder({
        order: {
          ...data.order,
          is_first_order: data.is_first_order,
          is_already_print_stickers: data.is_already_print_stickers,
        },
        steps: responseCheckItems.data,
        workId: data.id,
      })
    } catch (err: any) {
      handleRecoveryError(err?.message, makeRecovery)
    }
  }

  const handleRecoveryError = (message: string, retry: (...rest: any) => Promise<void>) => {
    if (message === 'Unfinished works not found!') {
      setScreenState('viewGetOrder')
    } else if (message === 'Network Error') {
      makeError({ title: 'Sem conexão', message: mapErrors(message), retry })
    } else if (message.includes('pre picking')) {
      makeError({ message: 'Erro ao buscar pre picking usados', retry })
    } else {
      makeError({ message: 'Erro ao verificar pedido em aberto', retry })
    }
  }

  return { makeRecovery }
}
