import { makeStyles } from '@material-ui/core'

export type Theme = 'dark' | 'light'

interface Props {
  theme: Theme
}

export const useStyles = makeStyles({
  button: {
    borderRadius: '3rem',
    textTransform: 'none',
    '& .MuiTouchRipple-child': {
      backgroundColor: '#89BD23',
    },
  },
  avatar: {
    boxShadow:
      '0px 3px 3px rgba(130, 130, 130, 0.14), 0px 3px 4px rgba(130, 130, 130, 0.12), 0px 1px 8px rgba(130, 130, 130, 0.2)',
  },
  name: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: '0.5rem',
    color: (props: Props) => (props.theme === 'dark' ? '#ffffff' : '#000000'),
    lineHeight: '1.1rem',
  },
  nameUser: {
    textTransform: 'capitalize',
  },
  menuItem: {
    margin: '0.3rem 0',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
      background: '#EDF2EC',
    },
  },
  menuIcon: {
    flexShrink: 'initial',
    minWidth: 'initial',
    paddingRight: '0.9rem',
  },
  operationIcon: {
    width: '22px',
    height: '22px',
  },
})
