import { FC } from 'react'

import { DesktopNavbar } from './DesktopNavbar'
import { useStylesLayout } from './styles'

const Layout: FC = ({ children }) => {
  const classes = useStylesLayout()

  return (
    <div className={classes.root}>
      <DesktopNavbar />
      <main className={classes.content}>{children}</main>
    </div>
  )
}

export default Layout
