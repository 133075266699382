import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField } from '@material-ui/core'
import { Button } from 'components'
import { useConfig } from 'hooks/useConfig/Index'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal } from 'shared'
import * as Yup from 'yup'

interface IProps {
  isOpen: boolean
  setIsOpen: (data: any) => void
  data: {
    palletLimitWeight?: number
    palletLimitWholesaleBox?: number
  }
}

export const Edit = ({ setIsOpen, isOpen, data }: IProps) => {
  const { updateSettings } = useConfig()
  const [isLoading, setIsLoading] = useState(false)
  const validateSchema = Yup.object().shape({
    palletLimitWeight: Yup.string().required('Campo obrigatório'),
    palletLimitWholesaleBox: Yup.string().required('Campo obrigatório'),
  })

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: { ...data },
  })

  const { errors } = formState

  const handleClose = () => setIsOpen({ isOpen: false, data: {}})

  const handleSubmitForm = async (data: any) => {
    setIsLoading(true)
    await updateSettings({
      palletLimitWeight: Number(data.palletLimitWeight),
      palletLimitWholesaleBox: Number(data.palletLimitWholesaleBox),
    })
    setIsLoading(false)
    handleClose()
  }

  useEffect(() => {
    setValue('palletLimitWeight', data.palletLimitWeight)
    setValue('palletLimitWholesaleBox', data.palletLimitWholesaleBox)
  }, [data])

  return (
    <Modal
      open={isOpen}
      handleClose={handleClose}
      title="Editar configurações da paletização"
      style={{ minHeight: '10rem' }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(handleSubmitForm)}
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop="2rem"
        style={{ gap: '1rem' }}
      >
        <TextField
          style={{ width: '50%' }}
          error={!!errors?.palletLimitWeight}
          helperText={errors?.palletLimitWeight?.message}
          label="EM KG"
          variant="outlined"
          type="number"
          {...register('palletLimitWeight')}
        />
        <TextField
          style={{ width: '50%' }}
          error={!!errors?.palletLimitWholesaleBox}
          helperText={errors?.palletLimitWholesaleBox?.message}
          label="EM CX"
          variant="outlined"
          type="number"
          {...register('palletLimitWholesaleBox')}
        />
        <Button
          style={{ width: '10rem', alignSelf: 'flex-end' }}
          type="submit"
          isLoading={isLoading}
          variant="contained"
        >
          Opa, pode salvar!
        </Button>
      </Box>
    </Modal>
  )
}
