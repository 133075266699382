import { Box, Typography } from '@material-ui/core'
import separationGroupImg from 'assets/images/separation-group.svg'
import { CardConfig } from 'components/pages/Config/CardSeparationGroup'
import { Button, ContainerPage } from 'components'
import { ReturnArea } from 'components/pages/Config/'
import { useConfig } from 'hooks/useConfig/Index'
import { usePopup } from 'hooks/usePopup'
import {
  ISeparationGroupConfig,
  ISeparationGroupConfigRequest,
} from 'interfaces/ISeparationGroupService'
import { useEffect, useState } from 'react'
import { FaUserPlus } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { ISettingsRequest, settingsService } from 'services/settingsService'
import { IState } from 'store'

import { NewSeparationGroup } from '../../../../components/pages/Config/DialogNewSeparationGroup'
import { IStepsSelected } from './ModalStepScanner'
import { SeparationGroupConfig } from './SeparationGroupConfig'
import { useStyles } from './styles'

interface IModalUpdate {
  isOpen: boolean
  data: ISeparationGroupConfig
}

interface IModalCreate {
  isOpen: boolean
}

const SeparationGroup = () => {
  const classes = useStyles()
  const { getSetting } = useConfig()
  const { addPopup } = usePopup()
  const settings = useSelector<IState, ISettingsRequest>(state => state.settings)
  const [isModalOpen, setIsModalOpen] = useState<IModalUpdate>({
    isOpen: false,
    data: {},
  } as IModalUpdate)
  const [isModalCreateOpen, setIsModalCreateOpen] = useState<IModalCreate>({
    isOpen: false,
  } as IModalCreate)

  const handleCloseModal = () => {
    setIsModalOpen({ isOpen: false, data: {} } as IModalUpdate)
    setIsModalCreateOpen({ isOpen: false } as IModalCreate)
  }

  const submitSeparationGroup = async (
    params: ISeparationGroupConfigRequest,
    type: 'create' | 'update',
  ) => {
    try {
      const data = {
        ...params,
        fastTrack: true,
      }

      if (type === 'create') {
        await settingsService.postSeparationGroup(data)
      }

      if (type === 'update') {
        await settingsService.updateSeparationGroup(data)
      }

      await getSetting()
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro para criar grupo de separação',
        description: error?.message || error?.msg || 'Contate o time de tech',
      })
    }
  }

  return (
    <>
      <ContainerPage>
        <ReturnArea title="Grupos de separação" />

        <Box className={classes.boxAlign}>
          <Typography className={classes.titlePage}>Grupos de separação</Typography>

          <Button
            onClick={() => setIsModalCreateOpen({ isOpen: true })}
            className={classes.configs}
          >
            <FaUserPlus size={'2rem'} style={{ marginRight: '0.6rem' }} /> Novo Grupo
          </Button>
        </Box>
        <Box className={classes.containner}>
          {settings.separationGroups
            .sort((sepPrev, sepNext) =>
              sepPrev.separation_group > sepNext.separation_group ? 1 : -1,
            )
            .map(separationGroup => {
              const hasReader =
                separationGroup.cam_scanner || separationGroup.infrared_scanner
                  ? 'com scanner 📱'
                  : 'sem scanner 📵'
              const groupCommand = separationGroup.separation_group_commands
                ? 'Automático'
                : 'Manual'

              return (
                <Box
                  key={separationGroup.separation_group}
                  style={{ marginRight: '2rem', marginTop: '2rem' }}
                >
                  <CardConfig
                    image={separationGroupImg}
                    callback={() => {
                      setIsModalOpen({ isOpen: true, data: separationGroup })
                    }}
                    title={`Grupo ${separationGroup.separation_group}`}
                    subTitle={`${groupCommand} ${hasReader}`}
                  />
                </Box>
              )
            })}
        </Box>
      </ContainerPage>

      <NewSeparationGroup
        isOpen={isModalCreateOpen.isOpen}
        handleCloseModal={handleCloseModal}
        submitNewSeparationGroup={submitSeparationGroup}
        {...isModalOpen.data}
      />

      <SeparationGroupConfig
        isOpen={isModalOpen.isOpen}
        handleCloseModal={handleCloseModal}
        submitUpdateSeparationGroup={submitSeparationGroup}
        {...isModalOpen.data}
      />
    </>
  )
}

export default SeparationGroup
