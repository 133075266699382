import { IUser } from 'interfaces/IUser'

import { api as apiService, ApiService } from './api'

class PermissionsService {
  constructor(private readonly api: ApiService) {}

  async getCurrentUser(): Promise<IUser> {
    const url = carambolaUrl + `/me/`
    return await this.api.get(url)
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
const permissionsService = new PermissionsService(apiService)
export default permissionsService
