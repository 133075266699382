import { alpha, makeStyles } from '@material-ui/core'
import { shade } from 'polished'

const drawerWidth = 220

export const useStylesDesktop = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24,
  },
  userExperience: {
    marginRight: '3rem',
    marginTop: '0.2rem',
    [theme.breakpoints.between(0, 500)]: {
      marginRight: '1rem',
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
    backgroundColor: alpha('#FFFFFF', 0.72),
    zIndex: 3,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: '70px',
    [theme.breakpoints.between(0, 1000)]: {
      width: '100%',
    },
    width: `calc(100% - 72px)`,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  navbarContainer: {
    position: 'relative',
    maxHeight: '100vh',
  },
  buttonDrawer: {
    padding: 0,
    position: 'absolute',
    right: '-19px',
    width: '2.4rem',
    height: '2.4rem',
    zIndex: 1300,
    top: '70px',
    background: '#ffffff',
    transition: 'transform 0.2s',
    transform: 'rotate(0deg)',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      background: shade(0.02, '#FFFFFF'),
    },
  },
  nav: {
    height: 'calc(100% - 140px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.between(0, 1000)]: {
      height: 'calc(100% - 80px)',
    },
  },
  activeDrawer: {
    transform: 'rotate(-180deg)',
  },
  iconDrawer: {
    color: '#353535',
    width: '1.875rem',
    height: '1.875rem',
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  drawerPaper: {
    height: '100vh',
    overflowX: 'hidden',
    position: 'relative',
    whiteSpace: 'nowrap',
    padding: '0',
    background: '#FFFFFF',
    borderRight: '0.5px dotted #E3E2E2',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  logoContainer: {
    paddingLeft: '20px',
    display: 'flex',
    alignItems: 'center',
    gap: '19px',
    margin: '1.437rem 0 70px',
    [theme.breakpoints.between(0, 1000)]: {
      margin: '1.437rem 0 25px',
    },
  },
  title: {
    color: '#353535',
    fontSize: '1.25rem',
  },
  listClose: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
    marginLeft: '0px',
    marginRight: '0px',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: '#fafafa',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    padding: '12px 0px',
    paddingLeft: '23px',
    width: '100%',
    height: '100%',
  },
}))
