import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '500px',
  },
  seachBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    justifyContent: 'space-between',
    width: '100%',
  },
  search: {
    width: '100%',
    [theme.breakpoints.between(0, 460)]: {
      marginLeft: 'auto',
      width: '100%',
    },
  },
  buttonFilter: {
    maxWidth: '7.43rem',
    minHeight: '3rem',
    background: '#89BD23',
    borderRadius: '0.5rem',
    fontSize: '0.9rem',
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  textButton: {
    [theme.breakpoints.between(0, 537)]: {
      textIndent: '-99999px',
    },
  },
  quantityFilter: {
    marginLeft: '0.3rem',
  },
  menuItem: {
    display: 'flex',
    margin: '0.3rem 0',
    borderRadius: '6px',
    justifyContent: 'flex-start',
    '&:hover': {
      background: '#EDF2EC',
    },
  },
  buttonEdit: {
    minWidth: '7.1rem',
    minHeight: '3rem',
    border: '1px solid #6E8247',
    borderRadius: '0.5rem',
    color: '#6E8247',
    [theme.breakpoints.between(0, 507)]: {
      marginTop: '1rem',
    },
  },
  content: {
    padding: '2.5rem 2rem',
    minWidth: '600px',
    overflowX: 'auto',
  },
  headerContent: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    textTransform: 'capitalize',
    color: '#414141',
    fontSize: '1.125rem',
    padding: '0 2rem',
    marginBottom: '1rem',
    '& strong': {
      fontWeight: 500,
      flex: 1,
      color: '#757575',
    },
  },
  boxItem: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    columnGap: '1rem',
    marginTop: '1.1rem',
    padding: '0 2rem 1rem 2rem',
  },
  containerInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputExtraOrder: {
    height: '2.5rem',
    maxWidth: '100px',
    border: 'none !important',
    color: '#353535',
    fontWeight: 600,
    '&::webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& > .MuiInputBase-root': {
      height: '2.5rem',
      fontWeight: 500,
      backgroundColor: '#F4F4F4',
      borderRadius: '8px',
    },
    '& fieldset': {
      border: 'none !important',
    },
  },
  itemTitle: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '1.1rem',
    lineHeight: '150%',
    color: '#353535',
  },
  selectForm: {
    width: '146px',
    height: '40px',
    borderRadius: '30px',
    color: '#757575',
    fontWeight: 400,
    backgroundColor: '#E9F4D2 !important',
    '& fieldset': {
      border: 'none !important',
    },
    '& svg': {
      fontSize: '27px',
      paddingRight: '3px',
    },
    '& .MuiSelect-selectMenu:focus': {
      background: 'none !important',
      backgroundColor: 'none !important',
    },
  },
  withoutLevel: {
    backgroundColor: '#F4F4F4 !important',
  },
  divider: {
    width: '100%',
    '&:first-child': {
      background: 'transparent',
    },
  },
  boxUrl: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },
  boxLink: {
    cursor: 'pointer',
    color: '#757575',
    fontSize: '1.3rem',
    border: 'none',
    background: 'transparent',
    fontWeight: 500,
    textDecoration: 'none',
    transition: '0.2s',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  boxPlace: {
    color: '#353535',
    fontSize: '1.3rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
  },
  boxAlign: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  containerConfig: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '1rem 0 ',
  },
  configs: {
    border: '0.5px solid #757575',
    color: '#757575',
    fontSize: '1rem',
    [theme.breakpoints.down(507)]: {
      width: '100%',
    },
  },
  titlePage: {
    color: '#353535',
    fontSize: '1.6rem',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '1.7rem',
    letterSpacing: '0.01em',
  },
  containerSelect: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  containerPopover: {
    maxHeight: '300px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A1CA4F',
      borderRadius: '9px',
    },
  },
  checkBoxStep: {
    padding: '0 .5rem 0 0',
  },
  containerScroll: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A1CA4F',
      borderRadius: '9px',
    },
  },
  buttonManyEdit: {
    border: '0.5px solid #89BD23',
    color: 'white',
    fontSize: '1rem',
    backgroundColor: '#89BD23',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: '#89BD23',
      filter: 'brightness(0.9)',
    },
  },
  containerManyEdit: {
    width: '100%',
    maxWidth: '100%',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonSave: {
    border: '0.5px solid #89BD23',
    color: 'white',
    fontSize: '1rem',
    backgroundColor: '#89BD23',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: '#89BD23',
      filter: 'brightness(0.9)',
    },
    '&:disabled': {
      backgroundColor: '#E5E5E5',
      border: '0.5px solid #E5E5E5',
      opacity: 0.9,
    },
    [theme.breakpoints.down(507)]: {
      width: '100%',
    },
  },
}))
