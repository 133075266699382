import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    width: '7rem',
    height: '7rem',
    position: 'absolute',
    top: '-5.8rem',
    left: '17rem',
    background: '#ffffff',
    borderRadius: '50%',
    zIndex: 2,
    [theme.breakpoints.between(0, 600)]: {
      left: '38%',
    },
  },
  image: {
    marginTop: '0.5rem',
    marginLeft: '0.5rem',
    width: '6rem',
    height: '6rem',
  },
  boxInformations: {
    marginTop: '1.5rem',
    textAlign: 'center',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 600,
    fontFamily: 'Inter',
    letterSpacing: '0.01em',
    color: '#353535',
  },
  subTitle: {
    marginTop: '0.5rem',
    fontSize: '1.1rem',
    fontWeight: 400,
    fontFamily: 'Inter',
    letterSpacing: '0.01em',
    color: '#606060',
    borderBottom: '1px solid #C4C4C4',
    overflowWrap: 'break-word',
    inlineSize: '23rem',
    '& strong': {
      color: '#000000',
    },
  },
  separatorInfos: {
    marginTop: '1.5rem',
    marginLeft: '20%',
    textAlign: 'left',
    [theme.breakpoints.between(500, 600)]: {
      marginLeft: '20%',
    },
    [theme.breakpoints.between(0, 500)]: {
      marginLeft: '10%',
    },
  },
  info: {
    fontSize: '1.1rem',
    fontWeight: 500,
    fontFamily: 'Roboto',
    color: '#757575',
    '& strong': {
      color: '#000000',
    },
  },
  buttonClose: {
    width: '50%',
    marginTop: '2rem',
    border: '1px solid #353535',
    borderRadius: '0.5rem',
    position: 'relative',
    left: '25%',
  },
}))
