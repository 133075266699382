import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 1rem',
  },
  paper: {
    maxWidth: '513px',
    width: '100%',
    padding: '2rem',
    [theme.breakpoints.down(480)]: {
      padding: '2rem 1rem',
    },
    [theme.breakpoints.down(260)]: {
      padding: '2rem .5rem',
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 600,
    textAlign: 'center',
  },
  boxTrashAddSubtract: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(480)]: {
      flexWrap: 'wrap-reverse',
      justifyContent: 'center',
    },
  },
  buttonTrash: {
    width: '56px',
    height: '56px',
    color: '#618619',
    [theme.breakpoints.down(480)]: {
      width: '100%',
      marginTop: '1rem',
      height: '42px',
      borderRadius: '21px',
    },
  },
  boxButtonsAddSubtract: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '.5rem',
    [theme.breakpoints.down(480)]: {
      width: '100%',
    },
    '& .MuiButton-root': {
      color: '#618619',
      fontSize: '2rem',
      fontWeight: '400',
    },
    '& .MuiTypography-body1': {
      margin: '0 1rem',
      background: '##D9D9D9',
      fontSize: '24px',
      height: '56px',
      padding: '0 1rem',
      lineHeight: '56px',
      maxWidth: '142px',
      width: '100%',
      borderRadius: '17px',
      fontWeight: 600,
      textAlign: 'center',
    },
  },

  boxButtonsSubmit: {
    display: 'flex',
    gap: '1rem',
    marginTop: '2rem',
    [theme.breakpoints.down(350)]: {
      flexDirection: 'column',
      marginTop: '2rem',
    },
    '& .MuiButton-root': {
      width: '50%',
      height: '42px',
      lineHeight: '42px',
      borderRadius: '21px',
      [theme.breakpoints.down(350)]: {
        width: '100%',
      },
    },
  },
  invisibleSpan: {
    width: '56px',
    height: '56px',
    [theme.breakpoints.down(480)]: {
      display: 'none',
    },
  },
  imgTrash: {
    [theme.breakpoints.down(480)]: {
      display: 'none',
    },
  },
  textTrash: {
    [theme.breakpoints.up(480)]: {
      display: 'none',
    },
  },
}))
export default useStyles
