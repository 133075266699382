import { Box, Tabs, styled, LinearProgress } from '@material-ui/core'

export const ContainerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.between(0, 460)]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

export const TabContainer = styled(Tabs)((props: { tab: number }) => {
  const getBackgroundColor = (tab: number) => {
    return (
      {
        0: '#89BD23',
        1: '#2549CC',
      }[tab] || '#FFFFFF'
    )
  }

  return {
    marginBottom: '2.5rem',
    '& .MuiTab-wrapper': {
      fontWeight: 500,
      textTransform: 'none',
      fontSize: '1.1rem',
    },
    '& .MuiTabs-indicator': {
      height: '5px',
      backgroundColor: getBackgroundColor(props.tab),
      border: 'none',
      borderRadius: '5px',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: '#353535',
    },
    '& .MuiTab-textColorInherit': {
      color: '#A3A3A3',
    },
  }
})

export const Loading = styled(LinearProgress)(
  ({
    loading,
    colorBackground,
    loadingColor,
  }: {
    loading: boolean
    colorBackground?: string
    loadingColor?: string
  }) => ({
    opacity: loading ? 1 : 0,
    height: '5px',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    borderRadius: '0.5rem 0.5rem 0 0',
    backgroundColor: colorBackground || 'rgb(210, 229, 171)',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: loadingColor || '#89bd23',
    },
  }),
)
