import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '19rem',
    minWidth: '19rem',
    background: '#FFFFFF',
    padding: '1.875rem',
    borderRadius: '5px',
  },
  carousel: {
    width: '100%',
    background: '#FFFFFF',
    padding: '0.81rem',
    marginBottom: '2.90rem',
    height: '7.87rem',
    position: 'relative',
  },
  arrowLeft: {
    cursor: 'pointer',
    outline: 'none',
    position: 'absolute',
    zIndex: 3,
    top: 0,
    left: 0,
    width: '6rem',
    height: '7.87rem',
    border: 'none',
    background: 'linear-gradient(267.93deg, rgba(245, 245, 245, 0.05) 1.74%, #FAFAFA 96.48%);',
    '&:disabled': {
      opacity: 0.3,
    },
  },
  arrowRight: {
    cursor: 'pointer',
    outline: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '6rem',
    height: '7.87rem',
    border: 'none',
    background: 'linear-gradient(266.77deg, #FAFAFA 2.3%, rgba(245, 245, 245, 0.05) 93.29%)',
    '&:disabled': {
      opacity: 0.3,
    },
  },
  arrowIcon: {
    fontSize: '2.5rem',
    color: '#646464',
  },
  divider: {
    margin: '2rem 0',
    background: '#C4C4C4',
    [theme.breakpoints.between(0, 1050)]: {
      margin: '0 1rem',
    },
  },
  box: {
    display: 'flex',
    gap: '1rem',
    [theme.breakpoints.between(0, 1050)]: {
      minWidth: '220px',
    },
  },
  icon: {
    width: '3.125rem',
    height: '3.125rem',
    borderRadius: '6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  totalOrderIcon: {
    background: '#2E656A',
  },
  ordersFinished: {
    background: '#89BD23',
  },
  ordersPending: {
    background: '#EAD92A',
  },
  title: {
    color: '#757575',
    fontSize: '1rem',
  },
  subtitle: {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: '#353535',
  },
  titlePending: {
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    color: '#757575',
    fontSize: '1rem',
    fontWeight: 600,
    display: 'flex',
    gap: '3px',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      color: '#89BD23',
      textDecoration: 'underline',
    },
    '&:disabled': {
      fontWeight: 500,
    },
    '&:disabled:hover': {
      color: '#757575',
      textDecoration: 'initial',
      cursor: 'initial',
    },
  },
  timer: {
    background: '#5e42db',
  },
  separator: {
    background: '#3ca2de',
  },
  conferencer: {
    background: '#F690FF',
  },
}))
