import { Box, Typography, Divider, FormControlLabel, styled } from '@material-ui/core'
import { Button } from 'components'
import { usePopup } from 'hooks/usePopup'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { useState } from 'react'
import { FiAlertTriangle } from 'react-icons/fi'
import { IoStorefrontSharp } from 'react-icons/io5'
import { loadingConference } from 'services/loadingConferenceService'
import { Checkbox, Modal } from 'shared'

import { useStyles } from './styles'

interface IProps {
  isOpen: boolean
  handleCloseModal: () => void
  selectedDate: Date
}

const LabelStyle = styled(Typography)({
  color: '#353535',
  textAlign: 'left',
  userSelect: 'none',
  fontSize: '1.1rem',
  '& span': {
    display: 'block',
    color: '#757575',
    fontSize: '0.95rem',
  },
})

export const DownloadExcel = ({ isOpen, handleCloseModal, selectedDate }: IProps) => {
  const classes = useStyles()
  const { addPopup } = usePopup()
  const [disregardConveyorBelt, setDisregardConveyorBelt] = useState(false)
  const [disregardConference, setDisregardConference] = useState(false)

  const handleDownloadExcelCountBoxes = async () => {
    try {
      const data = await loadingConference.downloadExcelCountBoxes(
        disregardConveyorBelt,
        disregardConference,
        moment(selectedDate).format('YYYY-MM-DD'),
      )
      fileDownload(data, 'Contagem_de_caixas.xls')
      handleCloseModal()

      addPopup({
        type: 'success',
        title: 'Opa, o download foi solicitado com sucesso 🤠',
        description: 'E esse copão aí?!',
      })
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao tentar fazer o download 😢',
        description: 'Dá um alô pro time de tech',
      })
    }
  }

  return (
    <Modal
      title="Relação caixas - pedidos de hoje? Simbora! 🤠"
      handleClose={handleCloseModal}
      open={isOpen}
    >
      <Typography className={classes.subTitle}>
        Quer desconsiderar a contagem em algum processo nosso? Se sim é só marcar.
      </Typography>
      <Box
        mt="1rem"
        width="100%"
        borderRight="1rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
      ></Box>
      <Box mt="2rem" width="100%" borderRight="1rem" display="flex" borderRadius="10px">
        <Box borderRadius={10} flex="1" border="1px solid #ccc">
          <FormControlLabel
            label={
              <LabelStyle style={{ textAlign: 'left', marginLeft: '0.5rem' }}>
                📦 Sim, da esteira
              </LabelStyle>
            }
            control={
              <>
                <Checkbox
                  style={{ marginLeft: '1rem' }}
                  color="primary"
                  checked={disregardConveyorBelt}
                  onChange={e => setDisregardConveyorBelt(e.target.checked)}
                />
                <Divider className={classes.dividerButton} orientation="vertical" />
              </>
            }
          />
        </Box>
        <Box borderRadius={10} flex="1" border="1px solid #ccc" marginLeft="2rem">
          <FormControlLabel
            label={
              <LabelStyle style={{ textAlign: 'left', marginLeft: '0.5rem' }}>
                🔎 Sim, dos conferentes
              </LabelStyle>
            }
            control={
              <>
                <Checkbox
                  style={{ marginLeft: '1rem' }}
                  color="primary"
                  checked={disregardConference}
                  onChange={e => setDisregardConference(e.target.checked)}
                />
                <Divider className={classes.dividerButton} orientation="vertical" />
              </>
            }
          />
        </Box>
      </Box>
      <Button
        variant="contained"
        className={classes.buttonConfigs}
        onClick={handleDownloadExcelCountBoxes}
      >
        <Typography className={classes.buttonText}>Certin, pode baixar!</Typography>
      </Button>
    </Modal>
  )
}
