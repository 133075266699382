import { Box } from '@material-ui/core'
import { FC } from 'react'
import ContentLoader from 'react-content-loader'

import { useStyles } from './style'

const ContainerLoading: FC = ({ children }) => {
  return (
    <ContentLoader
      speed={1}
      viewBox="0 0 600 50"
      width="100%"
      backgroundColor="#e8e8e8"
      foregroundColor="#d6d6d6"
      title="Carregando..."
    >
      {children}
    </ContentLoader>
  )
}

export const CardLoading = () => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box component="header" className={classes.header}>
        <ContainerLoading>
          <rect x="0" y="0" rx="60" ry="60" width="50" height="50" />
          <rect x="60" y="10" rx="5" ry="5" width="120" height="30" />
          <rect x="510" y="10" rx="5" ry="5" width="80" height="30" />
        </ContainerLoading>
      </Box>
      <Box className={classes.containerSeparations}>
        <ContainerLoading>
          <rect x="0" y="0" rx="5" ry="5" width="140" height="30" />
          <rect x="0" y="40" rx="5" ry="5" width="200" height="30" />
        </ContainerLoading>

        <Box className={classes.containerProducts}>
          {Array.from({ length: 3 }).map((_, index) => (
            <Box key={index}>
              <ContainerLoading>
                <rect x="0" y="0" rx="60" ry="60" width="45" height="45" />
                <rect x="60" y="10" rx="5" ry="5" width="170" height="30" />
              </ContainerLoading>
            </Box>
          ))}
        </Box>
      </Box>
      <ContainerLoading>
        <rect x="250" y="0" rx="5" ry="5" width="120" height="35" />
      </ContainerLoading>
    </Box>
  )
}
