import { Box, Typography } from '@material-ui/core'
import trackImg from 'assets/images/track.svg'

import { useStyles } from './style'
import { IFormatReplenishment } from 'hooks/useReplecement'

interface Props {
  handleDataChange: (value: number) => void
  data: IFormatReplenishment
}

export const CardReplenishment = ({ handleDataChange, data }: Props) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box component="header" className={classes.header}>
        <Box className={classes.headerBox}>
          <img src={trackImg} className={classes.trackImg} />
          <Typography variant="h1" className={classes.title}>
            Grupo de separação {data.separationGroup}
          </Typography>
        </Box>

        <Typography className={classes.quantityProducts}>
          {`${data.items.length} ${data.items.length === 1 ? 'item' : 'itens'}`}
        </Typography>
      </Box>
      <Box className={classes.containerSeparations}>
        {data.items.map((product, index) => (
          <Box key={product.id}>
            <Box key={product.sku_name + index} className={classes.product}>
              <div className={classes.productImg}></div>
              <Typography>{product.sku_name || product?.sku_name}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <button className={classes.btn} onClick={() => handleDataChange(data.separationGroup)}>
        Ver todos
      </button>
    </Box>
  )
}
