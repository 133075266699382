import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { ISkuBroken } from 'interfaces/IDashboard'
import { useState } from 'react'
import { MdExpandMore } from 'react-icons/md'
import { Modal } from 'shared'
import { v4 } from 'uuid'
import { useStyles } from './styles'

interface IModalBreak {
  handleClose: () => void
  open: boolean
  breaks: ISkuBroken[]
}

export const ModalBreak = ({ breaks, ...props }: IModalBreak) => {
  const classes = useStyles()
  const [accordionsOpen, setAccordionsOpen] = useState<string[]>([])

  const toggleAccordion = (skuName: string) => {
    if (accordionsOpen.includes(skuName)) {
      setAccordionsOpen(accordionsOpen.filter(sku => sku !== skuName))
      return
    }
    setAccordionsOpen([...accordionsOpen, skuName])
  }

  return (
    <Modal {...props} title="Quebras" rightButton style={{ height: '523px' }}>
      <Box paddingY="3rem">
        <Box className={classes.containerDescriptions}>
          <Typography>Produtos</Typography>
          <Typography style={{ marginRight: '-1.8rem' }}>Quantidades</Typography>
        </Box>
        {breaks.map(skuBroken => (
          <Accordion
            className={classes.accordion}
            key={skuBroken.name}
            expanded={accordionsOpen.includes(skuBroken.name)}
            TransitionProps={{
              timeout: 300,
              appear: true,
              exit: true,
            }}
          >
            <AccordionSummary
              expandIcon={<MdExpandMore color="#A3A3A3" />}
              onClick={() => toggleAccordion(skuBroken.name)}
            >
              <Typography className="p-sku-name">{skuBroken.name}</Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography className="p-sku-quantity_breaks">
                  {skuBroken.type === 'peso'
                    ? skuBroken.quantity_breaks.toFixed(3)
                    : skuBroken.quantity_breaks}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell>N° do pedido</TableCell>
                      <TableCell>Separador</TableCell>
                      <TableCell align="center">Quebras</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {skuBroken.items.map(breakItem => {
                      const key = v4()
                      return (
                        <TableRow key={key}>
                          <TableCell>{breakItem.order}</TableCell>
                          <TableCell>{breakItem.work.separator.username}</TableCell>
                          <TableCell align="center">
                            {skuBroken.type === 'peso'
                              ? breakItem.quantity_break.toFixed(3)
                              : breakItem.quantity_break}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Modal>
  )
}
