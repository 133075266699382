import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  trackNumber: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    color: '#414141',
    textAlign: 'center',
  },
  buttonConfigs: {
    fontSize: '1rem',
    background: '#618619',
    marginTop: '2rem',
    marginLeft: 'auto',
    display: 'block',
  },
  buttonText: {
    textAlign: 'center',
    fontWeight: 500,
    width: '100%',
  },
  divider: {
    margin: '1.5rem 0',
    background: '#C4C4C4',
  },
}))
