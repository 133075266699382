import { useSelector } from "react-redux"
import { ButtonCheckbox, ButtonConfirm, Checkbox, ContainerList, Divider, ModalContainer, PaperContainer, SubTitle, TextCheckbox, Title, useStyles } from "./styles"
import { IState } from "store"
import { useState } from "react"
import { ISettingsRequest } from "services/settingsService"
import { usePopup } from "hooks/usePopup"

export type IStepsSelected = { [key: number]: boolean }[]

interface IProps {
    open: boolean
    onConfirm: (steps: IStepsSelected) => void
    steps_without_scanner: number[]
    onClose: () => void
}

const ModalStepScanner = (props: IProps) => {
    const { separations } = useSelector<IState, ISettingsRequest>(state => state.settings)
    const { addPopup } = usePopup()
    const classes = useStyles()

    const arraySeparations = Array.from({ length: separations }).map((_, index) => index + 1)
    const [controlSelected, setControlSelected] = useState(() => {
        const steps = startSeparations(arraySeparations)

        if (Object.values(steps).every(value => value)) {
            return {
                all: true,
                steps
            }
        }
        return {
            all: false,
            steps
        }
    })

    function startSeparations(arraySeparations: number[]): { [key: number]: boolean } {
        return arraySeparations.reduce((acc: { [key: number]: boolean }, step) => {
            if (props?.steps_without_scanner?.includes(step)) {
                acc[step] = false
                return acc
            }
            acc[step] = true
            return acc

        }, {})
    }

    function formatSeparations(arraySeparations: number[], value: boolean): { [key: number]: boolean } {
        return arraySeparations.reduce((acc: { [key: number]: boolean }, step) => {
            acc[step] = value
            return acc
        }, {})
    }

    const onClickSelectAll = () => {
        setControlSelected({
            all: !controlSelected.all,
            steps: formatSeparations(arraySeparations, !controlSelected.all)
        })
    }

    const onClickSelectStep = (step: number) => {
        setControlSelected(state => {
            const newSteps = { ...state.steps, [step]: !state.steps[step] }
            const all = Object.values(newSteps).every(value => value)
            return {
                all,
                steps: newSteps
            }
        })
    }

    const onClickConfirm = () => {
        const formatted = Object.entries(controlSelected.steps).map(([key, value]) => ({ [key]: value }))

        const isEveryStepNotSelected = Object.values(controlSelected.steps).every(value => !value)

        if (isEveryStepNotSelected) {
            addPopup({
                title: 'Marque pelo menos uma etapa',
                type: "error"
            })
            return
        }

        props.onConfirm(formatted)
    }

    return (
        <ModalContainer open={props.open} onClose={props.onClose}>
            <PaperContainer className={classes.animation}>
                <Title>Em quais etapas será obrigatório usar o scanner?</Title>
                <SubTitle style={{ margin: '16px 0' }}>Selecione as etapas em que será obrigatório usar o scanner</SubTitle>
                <ButtonCheckbox onClick={onClickSelectAll}>
                    <Checkbox checked={controlSelected.all} />
                    <TextCheckbox>Todas as etapas</TextCheckbox>
                </ButtonCheckbox>
                <Divider style={{ margin: '1rem 0 ' }} />
                <ContainerList style={{ marginBottom: '16px' }}>
                    {arraySeparations.map((step) => (
                        <ButtonCheckbox onClick={() => { onClickSelectStep(step) }} key={step} style={{ marginBottom: '8px' }}>
                            <Checkbox checked={controlSelected?.steps[step]} />
                            <TextCheckbox>Etapa {step}</TextCheckbox>
                        </ButtonCheckbox>
                    ))}
                </ContainerList>
                <ButtonConfirm onClick={onClickConfirm}>Confirmar</ButtonConfirm>
            </PaperContainer>
        </ModalContainer>
    )
}

export default ModalStepScanner