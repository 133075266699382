import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '7rem',
  },
  icon: {
    color: '#E9D715',
    fontSize: '5rem',
  },
  title: {
    color: '#353535',
    fontSize: '1.5rem',
    fontWeight: 500,
    marginTop: '1.5rem',
    textAlign: 'center',
  },
  subtitle: {
    color: '#757575',
    fontSize: '1.125rem',
    textAlign: 'center',
  },
  btn: {
    marginTop: '4rem',
    fontSize: '1rem',
    padding: '0.5rem 1rem',
    minWidth: '11rem',
  },
})
