import { Container, Grid, Typography } from '@material-ui/core'
import separationGroupImg from 'assets/images/separation-group.svg'
import { Card } from 'components'
import { useAuth } from 'hooks/useAuth'
import { useConferenceChuchu } from 'hooks/useConferenceChuchu'
import { useConfig } from 'hooks/useConfig/Index'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { ISettingsRequest } from 'services/settingsService'
import { IState } from 'store'
import { updateRunningMachine } from 'store/reducers/infos/actions'
import { toggleScanner } from 'store/reducers/toggleScanner/actions'

import { useStyles } from './styles'

const TrackList = () => {
  const classes = useStyles()
  const { getSetting } = useConfig()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const settings = useSelector<IState, ISettingsRequest>(state => state.settings)
  const { pathname } = useLocation()
  const { redirectUser } = useAuth()
  const { recoveryConference } = useConferenceChuchu()
  const [isDisabled, setIsDisabled] = useState(true)

  const handleRunningMachine = (value: number) => {
    dispatch(updateRunningMachine(value))
    const separationGroup = settings.separationGroups.find(
      ({ separation_group }) => Number(separation_group) === Number(value || 0),
    )
    const hasScanner = !!(separationGroup?.cam_scanner || separationGroup?.infrared_scanner)
    dispatch(toggleScanner(hasScanner))

    if (pathname.includes('conferencia')) {
      navigate('/operacao/conferencia/pedido')
      return
    }

    navigate('/operacao/separacao/pedido')
  }

  const recovery = async () => {
    await getSetting()
    await redirectUser()
    await recoveryConference()
    setIsDisabled(false)
  }

  useEffect(() => {
    dispatch(updateRunningMachine(null))
    recovery()
  }, [])

  const isConference = pathname.includes('/conferencia/grupos')

  return (
    <Container className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        Aoba, que bão que cê veio uai! 🤠
      </Typography>
      <Typography variant="h1" className={classes.subtitle}>
        Clica aí no grupo de separação que cê vai separar hoje
      </Typography>

      <Grid container spacing={4}>
        {settings.separationGroups
          .sort((sepPrev, sepNext) =>
            sepPrev.separation_group > sepNext.separation_group ? 1 : -1,
          )
          .map(separationGroup => {
            const { cam_scanner, infrared_scanner, separation_group } = separationGroup
            const hasReader = cam_scanner || infrared_scanner ? 'com leitor' : 'sem leitor'
            if ((isConference && (cam_scanner || infrared_scanner)) || isConference) return null

            return (
              <Grid
                key={separation_group}
                container
                item
                xs={4}
                sm={3}
                md={3}
                lg={3}
                justifyContent="center"
              >
                <Card
                  image={separationGroupImg}
                  name={`Grupo ${separation_group}  (${hasReader})`}
                  isScanner={cam_scanner || infrared_scanner}
                  callback={() => handleRunningMachine(Number(separation_group))}
                  testid={`btn-runningMachine`}
                  isDisabled={isDisabled}
                />
              </Grid>
            )
          })}
      </Grid>
    </Container>
  )
}

export default TrackList
