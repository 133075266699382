import {
  IConfirmOrder,
  IConfirmOrderResponse,
  IConfirmStep,
  IGetItems,
  IGetItemsResponse,
} from 'interfaces/IFastTrack'
import { IOrderResponse } from 'interfaces/IOrder'
import { INewSaparationOrder } from 'interfaces/ISeparationOrderService'
import { ISeparationWorkResponse } from 'interfaces/ISeparationWorkService'

import { api as apiService, ApiService } from './api'

class FastTrackService {
  constructor(private readonly api: ApiService) {}

  async getNewOrderPooling(params: INewSaparationOrder): Promise<IOrderResponse> {
    return this.api.get(carambolaUrl + '/separation/order-separation/new', {
      params: { ...params, step: params.step || 1 },
    })
  }

  async createWork(orderId: string): Promise<ISeparationWorkResponse> {
    return this.api.post(carambolaUrl + '/separation/fast-track/work', { orderId })
  }

  async getItems(params: IGetItems): Promise<IGetItemsResponse> {
    return this.api.get(carambolaUrl + '/separation/fast-track/item', { params })
  }

  async confirmStep(data: IConfirmStep): Promise<{ message: string }> {
    return this.api.patch(carambolaUrl + `/separation/step/${data.orderId}`, data)
  }

  async confirmOrder({
    orderId,
    workId,
    boxesNumber,
  }: IConfirmOrder): Promise<IConfirmOrderResponse> {
    return this.api.put(carambolaUrl + `/separation/finish-order/${orderId}`, {
      workId,
      boxesNumber,
    })
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
const fastTrackService = new FastTrackService(apiService)
export default fastTrackService
