const url = () => {
  if (window.location.href.includes('cd.carambola.frexco.com.br')) {
    return {
      baseURL: `https://cd.carambola.frexco.com.br${process.env.REACT_APP_API_CARAMBOLA}`,
      breakUrl: process.env.REACT_APP_API_COMPLAINT,
      carambolaWebSocket: `https://cd.carambola.frexco.com.br${process.env.REACT_APP_API_BOXES_CONFERENCE}/socket/boxes-count`,
      productURL: `https://estoque.frexco.com.br/api/products`,
    }
  }

  return {
    baseURL: process.env.REACT_APP_API_CARAMBOLA,
    productURL: `https://stg.estoque.frexco.com.br/api/products`,
    breakUrl: process.env.REACT_APP_API_COMPLAINT,
    carambolaWebSocket: `${process.env.REACT_APP_API_BOXES_CONFERENCE}/socket/boxes-count`,
  }
}

const { baseURL, breakUrl, carambolaWebSocket, productURL } = url()
export { baseURL, breakUrl, carambolaWebSocket, productURL }
