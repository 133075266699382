import { Box, makeStyles, styled } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  titlePage: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },
  link: {
    cursor: 'pointer',
    color: '#757575',
    fontSize: '1.5rem',
    border: 'none',
    background: 'transparent',
    fontWeight: 500,
    textDecoration: 'none',
    transition: '0.2s',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '0.5rem',
    [theme.breakpoints.between(0, 1200)]: {
      flexWrap: 'wrap',
    },
  },
  clientName: {
    color: '#353535',
    fontSize: '1.8rem',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    [theme.breakpoints.between(0, 650)]: {
      fontSize: '1.3rem',
    },
  },
  orderId: {
    color: '#6F6F6F',
    fontSize: '1.2rem',
    fontWeight: 700,
    lineHeight: '2rem',
    [theme.breakpoints.between(0, 650)]: {
      fontSize: '1.1rem',
    },
  },
  btnReprint: {
    minWidth: '12.5rem',
    background: '#FAFAFA',
    padding: '0.625rem 1rem',
    marginLeft: 'auto',
    border: '0.5px solid #757575',
    borderRadius: '2px',
    color: '#757575',
    fontSize: '0.875rem',
    fontWeight: 400,
    textTransform: 'initial',
    '& .MuiButton-label': {
      display: 'flex',
      gap: '6px',
    },
    '&:hover': {
      background: '#FFFFFF',
    },
    [theme.breakpoints.down(335)]: {
      width: '100%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.between(1051, 5000)]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: '1.875rem',
    },
  },
  containerSeparations: {
    width: '100%',
    [theme.breakpoints.between(1050, 5000)]: {
      width: 'calc(100% - 19rem)',
    },
  },
  instructions: {
    background: '#ffffff',
    padding: '1.5rem',
    marginBottom: '2.5rem',
  },
  btnEdit: {
    display: 'flex',
    background: '#89BD23',
    color: '#FFF',
    '& svg': {
      marginRight: '6px',
      color: '#FFF',
    },
    [theme.breakpoints.down(335)]: {
      width: '100%',
    },
  },
  box: {
    display: 'flex',
    padding: '6px',
    borderRadius: '93px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  canceledOrder: {
    background: '#f77c7c',
    color: '#4E0000',
    maxWidth: '400px',
    height: '50px',
    fontSize: '1.2rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px',
    margin: '2rem 0',
  },
}))

export const ContainerTipInfo = styled(Box)({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  margin: '1rem 0',
})
