import { makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
export const useStylesLayout = makeStyles(theme => {
  const { pathname } = useLocation()
  const isFixScanner = pathname.includes('leitor-fixo')

  return {
    root: {
      display: 'flex',
      height: '100%',
      width: '100%',
      backgroundColor: '#fafafa',
      overflow: 'hidden',
    },
    content: {
      width: '100%',
      height: '100vh',
      overflow: 'auto',
      padding: isFixScanner ? '70px 0 0 0 !important' : '75px 2% 10px',
      [theme.breakpoints.between(0, 1000)]: {
        position: 'absolute',
      },
      [theme.breakpoints.up(700)]: {
        // eslint-disable-line
        '&::-webkit-scrollbar': {
          width: '9px',
          background: 'transparent',
        },
        '&::-webkit-sscrollbar-thumb': {
          background: '#A1CA4F',
          borderRadius: '9px',
        },
      },
    },
  }
})
