import {
  Avatar,
  Button,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@material-ui/core'
import { ReactComponent as OperationIcon } from 'assets/icons/operation-icon.svg'
import { useAuth } from 'hooks/useAuth'
import { usePopup } from 'hooks/usePopup'
import { enRules, routeConfig } from 'interfaces/IUser'
import { useRef, useState } from 'react'
import {
  AiOutlineAreaChart,
  AiOutlineCheckCircle,
  AiOutlineLineChart,
  AiOutlinePrinter,
} from 'react-icons/ai'
import { BsKey } from 'react-icons/bs'
import { MdLogout, MdQrCodeScanner, MdOutlineBrokenImage } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { MenuPopover } from 'shared'
import { IState } from 'store'
import { resetStates } from 'store/reducers/infos/actions'
import { formatName } from 'utils'

import { Theme, useStyles } from './styles'

const stringAvatar = (name: string, theme: Theme) => {
  return {
    style: {
      background: theme === 'dark' ? '#ffffff' : '#89BD23',
      color: theme === 'dark' ? '#89BD23' : '#ffffff',
      width: '2.8rem',
      height: '2.8rem',
    },
    children:
      name.split(' ').length > 1
        ? `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}`
        : `${name[0].toUpperCase()}${name[1].toUpperCase()}`,
  }
}

interface Props {
  theme: Theme
}

export const UserNav = (props: Props) => {
  const { theme } = props
  const classes = useStyles({ theme })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const userRef = useRef(null)
  const { signOut } = useAuth()
  const { addPopup } = usePopup()
  const { infos, user } = useSelector<IState, IState>(state => state)
  const { userPermissions } = user
  const nameUser = formatName(infos.name)
  const { painelRules } = routeConfig
  const [popover, setPopover] = useState(false)

  const canAccessPainel = painelRules.some(rule => userPermissions.includes(rule))

  const logOut = () => {
    signOut()
    addPopup({
      type: 'success',
      title: 'Deslogado com sucesso',
    })

    navigate('/')
  }

  const togglePopover = () => {
    setPopover(state => !state)
  }

  const actionBlocked = (action: any) => {
    dispatch(resetStates())
    togglePopover()
    action()
  }

  const onClickPanel = () => {
    if (userPermissions.includes(enRules.SeparationDashboardView)) {
      actionBlocked(() => navigate('/painel/dashboard'))
      return
    } else if (userPermissions.includes(enRules.SeparationDataAnalysis)) {
      actionBlocked(() => navigate('/painel/desempenho'))
      return
    } else if (userPermissions.includes(enRules.SeparationBreakOrderManagement)) {
      actionBlocked(() => navigate('/painel/quebras'))
      return
    } else if (userPermissions.includes(enRules.SeparationInvoiceRelease)) {
      actionBlocked(() => navigate('/painel/nfs'))
      return
    } else if (userPermissions.includes(enRules.SeparationReplenishmentResponse)) {
      actionBlocked(() => navigate('/painel/reabastecimento'))
      return
    } else if (userPermissions.includes(enRules.SeparationOrdersManagement)) {
      actionBlocked(() => navigate('/painel/pedidos'))
      return
    } else if (userPermissions.includes(enRules.SeparationUsersView)) {
      actionBlocked(() => navigate('/painel/usuarios'))
      return
    } else if (userPermissions.includes(enRules.SeparationReleasePackingList)) {
      actionBlocked(() => navigate('/painel/conferencia-de-caixas'))
      return
    } else if (userPermissions.includes(enRules.SeparationShipmentManagement)) {
      actionBlocked(() => navigate('/painel/romaneios'))
      return
    } else if (userPermissions.includes(enRules.SeparationRankingView)) {
      actionBlocked(() => navigate('/painel/ranking'))
      return
    } else if (userPermissions.includes(enRules.SeparationPrePickingConsult)) {
      actionBlocked(() => navigate('/painel/pre-picking'))
      return
    } else if (userPermissions.includes(enRules.SeparationConfigsManagement)) {
      actionBlocked(() => navigate('/painel/configuracoes'))
      return
    }
    actionBlocked(() => navigate('/operacao/separacao/grupos'))
  }

  return (
    <>
      <Button className={classes.button} onClick={togglePopover}>
        <Avatar
          {...stringAvatar(nameUser || 'Frexconauta', theme)}
          ref={userRef}
          className={classes.avatar}
        />
        <Typography className={classes.name}>
          Olá, <br />
          <strong className={classes.nameUser}>{nameUser || 'Frexconauta'}</strong>
        </Typography>
      </Button>

      <MenuPopover
        open={popover}
        onClose={togglePopover}
        anchorEl={userRef.current}
        style={{ marginTop: '0.5rem' }}
        arrow
      >
        {!pathname.includes('painel') && canAccessPainel && (
          <MenuItem className={classes.menuItem} onClick={onClickPanel}>
            <ListItemIcon className={classes.menuIcon}>
              <AiOutlineAreaChart size={22} />
            </ListItemIcon>
            <ListItemText>Painel</ListItemText>
          </MenuItem>
        )}

        {!pathname.includes('grupos ') && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => actionBlocked(() => navigate('/operacao/separacao/grupos'))}
          >
            <ListItemIcon className={classes.menuIcon}>
              <SvgIcon component={OperationIcon} viewBox="0 0 50 70" />
            </ListItemIcon>
            <ListItemText>Operação</ListItemText>
          </MenuItem>
        )}
        {!pathname.includes('leitor-fixo') &&
          [enRules.SeparationFixedReader].some(rule => userPermissions.includes(rule)) && (
            <MenuItem
              className={classes.menuItem}
              onClick={() =>
                actionBlocked(() => navigate('/painel/conferencia-de-caixas/leitor-fixo'))
              }
            >
              <ListItemIcon className={classes.menuIcon}>
                <MdQrCodeScanner size={22} />
              </ListItemIcon>
              <ListItemText>Leitor Fixo</ListItemText>
            </MenuItem>
          )}
        {!pathname.includes('desempenho') && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => actionBlocked(() => navigate('/operacao/separacao/desempenho'))}
          >
            <ListItemIcon className={classes.menuIcon}>
              <AiOutlineLineChart size={22} />
            </ListItemIcon>
            <ListItemText>Desempenho</ListItemText>
          </MenuItem>
        )}
        {!pathname.includes('conferencia') &&
          [enRules.SeparationCheck].some(rule => userPermissions.includes(rule)) && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => actionBlocked(() => navigate('/operacao/conferencia/inicio'))}
            >
              <ListItemIcon className={classes.menuIcon}>
                <AiOutlineCheckCircle size={22} />
              </ListItemIcon>
              <ListItemText>Conferência</ListItemText>
            </MenuItem>
          )}
        {!pathname.includes('token') &&
          [enRules.SeparationGetToken, enRules.Tech].some(rule =>
            userPermissions.includes(rule),
          ) && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => actionBlocked(() => navigate('/painel/token'))}
            >
              <ListItemIcon className={classes.menuIcon}>
                <BsKey size={22} />
              </ListItemIcon>
              <ListItemText>Token</ListItemText>
            </MenuItem>
          )}

        {!pathname.includes('reprint-routes') &&
          [enRules.SeparationReprintRoutes, enRules.Tech].some(rule =>
            userPermissions.includes(rule),
          ) && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => actionBlocked(() => navigate('/painel/reprint-routes'))}
            >
              <ListItemIcon className={classes.menuIcon}>
                <AiOutlinePrinter size={22} />
              </ListItemIcon>
              <ListItemText>Reimprimir rotas</ListItemText>
            </MenuItem>
          )}

        <MenuItem className={classes.menuItem} onClick={() => actionBlocked(logOut)}>
          <ListItemIcon className={classes.menuIcon}>
            <MdLogout size={22} />
          </ListItemIcon>
          <ListItemText>Sair da conta</ListItemText>
        </MenuItem>
      </MenuPopover>
    </>
  )
}
