import { Box, LinearProgress, Typography } from '@material-ui/core'
import { ContainerPage, Title } from 'components'
import { usePopup } from 'hooks/usePopup'
import { enStatus } from 'interfaces/IOrder'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { dashboardService } from 'services/dashboardService'
import { loadingConference } from 'services/loadingConferenceService'

import { CardOrder } from './CardOrder'
import ModalEditData from './ModalEditData'
import { IOrderCanceled, ModalOrder } from './ModalOrder'
import ModalScanner from './ModalScanner'
import { Link, TitlePage, Header, InvisibleInput } from './styles'
import TableScanneds from './TableScanneds'

export interface IOrderConference {
  clientName: string
  orderId: string
  route: number
  boxes: number
  updated: Date
  status?: enStatus
  totalBoxes: number
}

export interface handleSubmitModal {
  type: 'save' | 'delete'
  value?: number
  orderId: string
}

const messageErrorItem = 'não encontrado na tabela de conferência de carregamento'

export const OrdersLoadingConference = () => {
  const { addPopup } = usePopup()
  const modalOrderRef = useRef<IOrderCanceled>(null)
  const input = useRef<HTMLInputElement>(null)
  const [openModalScanner, setOpenModalScanner] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lastOrder, setLastOrder] = useState('')
  const [orders, setOrders] = useState<{
    [keyof: string]: IOrderConference
  }>({})
  const [orderId, setOrderId] = useState('')
  const [openModalEditData, setOpenModalEditData] = useState({
    open: false,
    order: {} as IOrderConference,
  })

  const getAllOrders = async () => {
    setLoading(true)
    try {
      const response = await loadingConference.getAllOrderBy({
        orderBy: '-updated',
      })
      setLastOrder(state => {
        return response.filter(
          item => item.loading_boxes_number === 0 || item.loading_boxes_number > 0,
        )[0]?.order_id
      })
      setOrders(state => ({
        ...state,
        ...response.reduce((acc, current) => {
          return {
            ...acc,
            [current.order_id]: {
              boxes: current?.loading_boxes_number,
              clientName: current?.client_name_or_trade,
              orderId: current?.order_id,
              route: current?.route,
              updated: new Date(current?.updated),
              totalBoxes: current?.conference_boxes_number,
            },
          }
        }, {}),
      }))
    } catch (err) {
      addPopup({
        type: 'error',
        title: 'Erro ao carregar histórico de pedidos',
        autoClose: false,
      })
    }
    setLoading(false)
  }

  const confirmBox = async (orderId: string) => {
    if (orders[orderId].boxes >= orders[orderId].totalBoxes) {
      setLastOrder(orderId)
      modalOrderRef?.current?.handleChangeOrderId({
        id: orderId,
        type: 'previous',
      })
      return
    }
    try {
      await loadingConference.updateBoxesOrder(orderId)
      setLastOrder(orderId)
      setOrders(state => {
        state[orderId].boxes += 1
        if (state[orderId].boxes === state[orderId].totalBoxes) {
          modalOrderRef?.current?.handleChangeOrderId({
            id: orderId,
            type: 'conferred',
          })
        }
        return { ...state }
      })
    } catch (err: any) {
      if (err?.includes(messageErrorItem)) {
        addPopup({
          type: 'error',
          title: err,
          autoClose: false,
        })
        return
      }

      addPopup({
        type: 'error',
        title: `Erro ao confirmar caixa do pedido ${orderId}`,
        autoClose: false,
      })
    }
  }

  const getDataOrder = async (orderId: string) => {
    try {
      const orderInfo = await loadingConference.getById(orderId)
      const { order } = await dashboardService.getOrderDetails(orderId)

      const { client_data } = order

      if (order.permission_to_separate === enStatus.recused) {
        modalOrderRef?.current?.handleChangeOrderId({
          id: orderId,
          type: 'recused',
        })
        setOrders(state => {
          delete state[orderId]
          return { ...state }
        })
      }

      const clientName =
        client_data.client_type === 'PF'
          ? (client_data.client_name as string)
          : (client_data.client_trade as string)
      setOrders(state => {
        state[orderId].clientName = clientName
        state[orderId].route = order.route
        state[orderId].boxes = orderInfo.loading_boxes_number
        state[orderId].status = order.permission_to_separate
        state[orderId].totalBoxes = orderInfo.conference_boxes_number
        return { ...state }
      })

      await confirmBox(orderId)
    } catch {
      setOrders(state => {
        delete state[orderId]
        return { ...state }
      })

      addPopup({
        type: 'error',
        title: `Erro ao procurar pedido #${orderId}`,
        autoClose: false,
      })
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    setOrderId('')
    e.preventDefault()
    const isNew = !orders[orderId]?.status
    if (isNew) {
      setOrders(state => ({
        ...state,
        [orderId]: {
          ...state[orderId],
          orderId,
          boxes: state[orderId]?.boxes ? state[orderId].boxes : 0,
          updated: state[orderId]?.updated ? state[orderId].updated : new Date(),
        } as IOrderConference,
      }))
      await getDataOrder(orderId)
      return
    }
    await confirmBox(orderId)
  }

  const handleSubmitModal = async ({ type, value, orderId }: handleSubmitModal) => {
    if (type === 'save') {
      try {
        await loadingConference.updateBoxe(orderId, Number(value))
        setOrders(state => {
          state[orderId].boxes = Number(value)
          return { ...state }
        })
        setLastOrder(orderId)
      } catch (err) {
        addPopup({
          type: 'error',
          title: `Erro ao alterar pedido #${orderId}`,
          autoClose: false,
        })
      }
    }
    if (type === 'delete') {
      try {
        await loadingConference.updateBoxe(orderId, 0)
        setOrders(state => {
          state[orderId].boxes = 0
          return { ...state }
        })
        setLastOrder(orderId)
      } catch (err) {
        addPopup({
          type: 'error',
          title: `Erro ao alterar pedido #${orderId}`,
          autoClose: false,
        })
      }
    }
  }

  useEffect(() => {
    setInterval(() => {
      if (input?.current) {
        input.current?.focus()
      }
    }, 500)
    setOpenModalScanner(true)
    setTimeout(() => {
      setOpenModalScanner(false)
    }, 2000)
    getAllOrders()
  }, [])

  const results = Object.values(orders)
    .filter(item => item.boxes === 0 || item.boxes > 0)
    .slice(0, 19)
    .sort((a, b) => {
      if (a.updated > b.updated) return -1
      if (a.updated < b.updated) return 1
      return 0
    })

  return (
    <>
      <ContainerPage>
        <Box component="form" onSubmit={handleSubmit}>
          <InvisibleInput
            type="text"
            inputRef={input}
            value={orderId}
            hidden
            onChange={e => setOrderId(e.target.value)}
          />
        </Box>

        <Header component="header">
          <TitlePage>
            <Link to="../">Conferência de caixas</Link>
            <IoIosArrowForward fontSize="1.125rem" />
            Pedidos
          </TitlePage>
        </Header>
        {loading && <LinearProgress />}

        <Box style={{ maxWidth: '580px', margin: '0 auto' }}>
          {orders[lastOrder] && (
            <CardOrder order={orders[lastOrder]} setOpenModalEditData={setOpenModalEditData} />
          )}

          {results.length > 1 && (
            <>
              <Typography
                style={{
                  fontWeight: '600',
                  fontSize: '20px',
                  color: '##5F5E69',
                }}
              >
                Últimas leituras
              </Typography>
              <TableScanneds
                results={results}
                lastOrder={lastOrder}
                setOpenModalEditData={setOpenModalEditData}
              />
            </>
          )}

          {results.length < 1 && (
            <Box display="flex" justifyContent="center" alignItems="center" height="350px">
              <Title style={{ textAlign: 'center' }}>Sem caixas conferidas pendentes</Title>
            </Box>
          )}
        </Box>
      </ContainerPage>
      <ModalScanner open={openModalScanner} onClose={() => setOpenModalScanner(false)} />
      <ModalOrder ref={modalOrderRef} />
      <ModalEditData
        open={openModalEditData.open}
        handleClose={() => {
          setOpenModalEditData(state => ({ ...state, open: false }))
        }}
        order={openModalEditData.order}
        handleSubmit={handleSubmitModal}
      />
    </>
  )
}
