import { styled, FormControl as MuiFormControl } from '@material-ui/core'

export const FormControl = styled(MuiFormControl)(({ theme }) => ({
  '& .MuiInputBase-root': {
    color: '#353535',
    borderColor: '#9ED334',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '10px',
    minWidth: '120px',
    justifyContent: 'center',
  },
  '& .MuiSelect-select.MuiSelect-select': {
    paddingRight: '0px',
  },
}))
