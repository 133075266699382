import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  containerHeader: {
    width: '100%',
    height: '6.25rem',
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 2,
  },
  main: {
    marginTop: (props: any) => (props.isConference ? '0' : '2rem'),
  },
})
