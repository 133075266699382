import { makeStyles } from '@material-ui/styles'

export const useStylesContainer = makeStyles({
  boxAlign: {
    marginTop: '3rem',
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '1.2rem',
    fontWeight: 400,
    color: '#000000',
  },
  boxProduct: {
    marginTop: '0.7rem',
    padding: '0.5rem',
    minHeight: '7rem',
    border: '0.5px solid #478d00bd',
  },
})
