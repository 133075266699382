import { IAlert, IAlertResponse } from 'interfaces/IAlert'

import { api as apiService, ApiService } from './api'

interface IPrePickingBreak {
  created: string
  status: string
  user_request: string
  user_response: string
  id: number
}

class AlertService {
  constructor(private readonly api: ApiService) {}

  async getAlertNotification(params: {
    status: 'pendente' | 'concluido'
    userRequest?: string
    controller?: AbortController
  }): Promise<IAlertResponse[]> {
    return this.api.get(`${carambolaUrl}/alert/`, {
      params,
      signal: params.controller?.signal,
    })
  }

  async getAlertPrePickingBreak(itemId: number, workId: string): Promise<IPrePickingBreak[]> {
    return this.api.get(`${carambolaUrl}/alert/pre-picking-break-report`, {
      params: {
        itemId,
        separationWorkId: workId,
      },
    })
  }

  async postAlertNotification(data: IAlert): Promise<void> {
    return this.api.post(`${carambolaUrl}/alert/support`, data)
  }

  async aceptAlertNotification(id: number): Promise<void> {
    return this.api.patch(`${carambolaUrl}/alert/support/${id}`, {
      status: 'concluido',
    })
  }

  async getAuthorization(itemId: number, orderId: string): Promise<{ message: string }> {
    return this.api.post(`${carambolaUrl}/alert/pre-picking-break-report`, {
      orderId,
      itemId,
    })
  }

  async authorizeOtherSku(
    statusForBreak: 'accept' | 'declined',
    itemId: number,
    idBreak: number,
  ): Promise<void> {
    return this.api.patch(`${carambolaUrl}/alert/pre-picking-break-report/${idBreak}`, {
      statusForAlert: 'concluido',
      statusForBreak,
      itemId,
    })
  }

  async updateAlertNotification(id: number, status: 'concluido' | 'pendente'): Promise<void> {
    return this.api.patch(`${carambolaUrl}/alert/${id}`, {
      status,
    })
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const alertService = new AlertService(apiService)
