import { eINFOS } from './types'

export const updateSeparation = (value: number | 'caixa pronta' | null) => {
  return {
    type: eINFOS.SEPARATION,
    separation: value,
  }
}

export const updateRunningMachine = (value: number | null) => {
  return {
    type: eINFOS.RUNNINGMACHINE,
    runningMachine: value,
  }
}

export const updateNameUser = (value: string) => {
  return {
    type: eINFOS.NAMEUSER,
    name: value,
  }
}

export const resetStates = () => {
  return {
    type: eINFOS.RESET,
  }
}
