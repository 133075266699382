import { api as apiService, ApiService } from './api'
import { IOrder } from 'interfaces/IOrder'

export interface IWorkConference {
  begin: string
  end: string
  time_length: string
  checker: number
  order: {
    order_id: string
    created: string
    scheduled_date: string
  }
  id: number
  type_of_analysis: enTypeOfAnalysis
}

interface IResponseRecovery {
  message: string
  data: IWorkConference
}

interface IParamsWorkConferenceAll {
  beginDate?: string
  endDate?: string
  timeLength?: string
  checkerId?: number
  orderId?: string
  typeOfAnalysis?: enTypeOfAnalysis
  scheduledDate?: string
}

export enum enTypeOfAnalysis {
  REVISION = 'revision',
  CONFERENCE = 'conference',
}

class WorkConferenceService {
  constructor(private readonly api: ApiService) {}

  public async getRecovery(): Promise<IResponseRecovery> {
    return this.api.get(carambolaUrl + '/order-conference/recovery')
  }

  public async getAllWork(params?:IParamsWorkConferenceAll): Promise<{results: IWorkConference[]}> {
    return this.api.get(carambolaUrl + '/order-conference/', {
      params
    })
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const workConferenceService = new WorkConferenceService(apiService)
