import { mapErrors } from 'errors/mapErrors'
import { IStep, IStepStep } from 'interfaces/IFastTrack'
import { IProduct } from 'interfaces/IProduct'
import { Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux'
import fastTrackService from 'services/fastTrackService'
import { IDataConfirmItem, IStepPickItem } from 'services/separationItemService'
import { separationItemServiceV2 } from 'services/separationItemServiceV2'
import { IState } from 'store'

import { IError, ScreenState } from '.'
import { usePopup } from '../usePopup'

export interface IHandleConfirmItem extends IDataConfirmItem {
  step: IStepStep
  boxes?: number
}

interface Props {
  openBoxCountToPrint: () => void
  setSteps: Dispatch<SetStateAction<IStep[]>>
  setScreenState: (x: ScreenState) => void
  resetOrder: () => void
  canceledOrder: () => Promise<void>
  openBoxCountByStep?: (x: { callback: (boxes: number) => Promise<void>; step: IStep }) => void
  closeBoxCountByStep: () => void
  setError: Dispatch<SetStateAction<IError>>
}

const isDefaultError = (message?: string) => {
  return [
    'Order rescheduled',
    'Order reseted',
    'This order has been canceled',
    'Network Error',
  ].includes(message ?? '')
}

export const useItem = (props: Props) => {
  const {
    resetOrder,
    canceledOrder,
    openBoxCountToPrint,
    openBoxCountByStep,
    setScreenState,
    setSteps,
    setError,
    closeBoxCountByStep,
  } = props
  const { orderFastTrack } = useSelector<IState, IState>(state => state)
  const { addPopup } = usePopup()

  const hasCountByStep = useSelector<IState, boolean>(state => {
    const separationGroup = state.settings.separationGroups.find(
      separationGroup =>
        Number(separationGroup.separation_group) === Number(state.infos.runningMachine),
    )
    return !!separationGroup?.has_count_by_step
  })

  const handleCommonErrors = (message: string, retry?: (...rest: any) => Promise<void> | void) => {
    if (message === 'Order rescheduled') {
      addPopup({ type: 'info', title: 'Pedido reagendado' })
      setScreenState('viewOrderSeparation')
    } else if (message === 'Order reseted') {
      resetOrder()
    } else if (message === 'This order has been canceled') {
      canceledOrder()
    } else if (message === 'Network Error' && !!retry) {
      setScreenState('viewError')
      setError({
        title: 'Sem conexão',
        message: mapErrors(message),
        retry: retry as (...rest: any) => void | Promise<void>,
      })
    } else {
      throw Error(message)
    }
  }

  const handleConfirmItem = async (data: IHandleConfirmItem): Promise<IProduct | void> => {
    try {
      const response = await separationItemServiceV2.pickItem(data)
      const stepData = response.find(({ step }) => step === data.step) as IStepPickItem
      const item = stepData?.items.find(({ id }) => id === data.itemId)

      updateProducts(stepData)

      if (stepData.is_finished && hasCountByStep) {
        openBoxCountByStep &&
          openBoxCountByStep({
            callback: async boxes => {
              await confirmStep(stepData.step, response, boxes)
              closeBoxCountByStep()
            },
            step: stepData,
          })
      } else if (stepData.is_finished) {
        await confirmStep(stepData.step, response, data.boxes)
      }
      return item
    } catch (error: any) {
      if (isDefaultError(error?.message)) {
        handleCommonErrors(error?.message)
      } else {
        throw new Error(error?.message)
      }
    }
  }

  const updateProducts = (stepData: IStepPickItem) => {
    setSteps(prevState => {
      const newSteps = [...prevState]
      const stepIndex = prevState.findIndex(({ step }) => step === stepData.step)
      newSteps[stepIndex] = { step: stepData.step, items: stepData.items }
      return newSteps
    })
  }

  const confirmStep = async (
    step: number,
    steps: IStepPickItem[],
    boxes?: number,
    orderId?: string,
    workId?: number,
  ) => {
    try {
      if (!['pre_boxed', 'no_separation_step'].includes(String(step))) {
        await fastTrackService.confirmStep({
          level: Number(step),
          orderId: orderId || orderFastTrack.order.order_id,
          workId: workId || orderFastTrack.work.id,
          boxes,
        })
      }
      closeBoxCountByStep && closeBoxCountByStep()

      const isSeparationCompleted = steps.every(({ is_finished }) => is_finished)
      if (isSeparationCompleted) {
        openBoxCountToPrint()
      }
    } catch (error: any) {
      if (isDefaultError(error?.message)) {
        handleCommonErrors(error?.message)
      } else {
        addPopup({ type: 'error', title: 'Erro ao confirmar etapa' })
      }
    }
  }

  return { handleConfirmItem, confirmStep }
}
