import { Box, Button, MenuItem, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import { IAlertResponse } from 'interfaces/IAlert'
import { alertService } from 'services/alertService'
import { usePopup } from 'hooks/usePopup'
import { useAlertNotification } from 'hooks/useAlertNotification'

interface INotificationLostPrePicking {
  notification: IAlertResponse
}

const NotificationLostPrePicking = ({ notification, ...rest }: INotificationLostPrePicking) => {
  const classes = useStyles()
  const { addPopup } = usePopup()
  const { refresh } = useAlertNotification(false)

  const confirmAlert = async () => {
    try {
      await alertService.updateAlertNotification(notification.id, 'concluido')
      await refresh()
    } catch (err: any) {
      addPopup({
        title: 'Erro ao aceitar chamado.',
        type: 'error',
        description: err?.message,
      })
    }
  }

  return (
    <MenuItem {...rest} className={classes.root}>
      <Box>
        <Typography className={classes.textTypeNotification}>
          {notification.user_request}
        </Typography>
        <Typography className={classes.textName}>Encontrou um pacote perdido</Typography>
      </Box>
      <Button className={classes.button} onClick={confirmAlert}>
        Liberar separador
      </Button>
    </MenuItem>
  )
}

export default NotificationLostPrePicking
