import { usePopup } from 'hooks/usePopup'
import { Button, Modal, Paper, Subtitle, Title } from './styles'
import { routeService } from 'services/routeService'
import { useSelector } from 'react-redux'
import { IState } from 'store'
import { Box } from '@material-ui/core'

export interface IDataAlertRouteNotTratament {
  route: number
  id: number
  is_hidden: boolean
  reduced: boolean
  totalBoxes: number
  capacity_vehicle: number
}

interface IProps {
  open: boolean
  data: IDataAlertRouteNotTratament
  resolved: () => void
}

const ModalRouteNotTratament = (props: IProps) => {
  const { addPopup } = usePopup()
  const userId = useSelector<IState, number>(state => Number(state.user.userId))

  const notReduced = async () => {
    try {
      await routeService.updateRouteLimit(props.data.id, {
        reduced: false,
        reduced_by: userId,
      })
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao salvar a não redução da rota',
        autoClose: false,
        description: err?.message ?? err?.msg ?? err ?? 'Erro desconhecido',
      })
    } finally {
      props.resolved()
    }
  }

  const diff = props.data.totalBoxes - props.data.capacity_vehicle

  return (
    <Modal open={props.open}>
      <Paper>
        <Title>
          Rota #{props.data.route} está com <strong>{diff}</strong> caixa{diff > 1 && 's'} acima do
          limite de caixas e está sem tratamento
        </Title>
        <Subtitle>
          - Caso vá reduzir, reduza a quantidade de caixas e passe as caixas novamente na esteira.
        </Subtitle>
        <Subtitle>
          - Caso não dê pra reduzir, clique na opção abaixo e repasse o motivo para o supervisor.
        </Subtitle>
        <Box display="flex" justifyContent="space-between" mt="3rem">
          <Button onClick={notReduced} colorBackground="#DA3737">
            Não dá pra reduzir
          </Button>
          <Button onClick={props.resolved} colorBackground="#89BD23">
            Reduzi
          </Button>
        </Box>
      </Paper>
    </Modal>
  )
}

export default ModalRouteNotTratament
