import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  container: {
    maxWidth: '400px',
    width: '100%',
    padding: '1rem',
  },
  text: {
    fontSize: '1.2rem',
    color: '#353535',
    fontWeight: 600,
    textAlign: 'center',
  },
  description: {
    marginTop: '1rem',
    fontSize: '1rem',
    color: '#353535',
    fontWeight: 400,
    textAlign: 'center',
  },
  buttonCancel: {
    padding: '0.5rem 1rem',
    color: '#DA3737',
  },
  buttonConfirm: {
    padding: '0.5rem 1rem',
    color: '#FFF',
    backgroundColor: '#89BD23',
    textTransform: 'none',
    '& :hover': {
      backgroundColor: '#89BD23',
    },
  },
  containerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '1rem',
  },
}))
