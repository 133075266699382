import { IStep } from './IFastTrack'
import { IWork } from './IWork'

export interface IClient {
  client_address: string
  client_id: number
  client_name: string
  client_phone: number
  client_type: 'PF' | 'PJ'
  client_trade: string | null
  critical: boolean
  ifood_bag: boolean
}

export enum enStatus {
  approved = 'approved',
  aprovado = 'aprovado',
  recused = 'recused',
  recusado = 'recusado',
  pendente = 'pendente',
  pending = 'pending',
}

export enum enFinanceStatus {
  approved = 'approved',
  aprovado = 'aprovado',
  recused = 'recused',
  recusado = 'recusado',
  pendente = 'pendente',
  pending = 'pending',
}

export interface ISeparatorUser {
  user_id: number | null
  username: string | null
}

export interface IOrder {
  order_id: string
  permission_to_separate: enStatus
  order_status: enFinanceStatus
  created: Date
  color_route: string
  updated: Date
  route: number
  client_data: IClient
  is_critical: boolean
  separation_group: number
  completed: number[]
  instructions: string
  store_id: number
  checking_status: number
  boxes_number: number
  porcentage_scanned: number
  checking_status_id: number
  scheduled_date: string
  priority: 'A' | 'B'
  fast_track: boolean
  queue_index: number
  separator: ISeparatorUser
  work: IWork[]
  is_first_order: boolean
  is_already_print_stickers: boolean
  is_palletized: boolean
  replacement_order?: {
    created: string
    id: number
    kind: string
    new_order: string
    old_order: string
    updated: string
  }
}

export interface IOrderSeparation {
  id: number
  level: number
  order_id: string
  status_id: number
}

export interface IOrderResponse {
  message: string
  order: IOrder
  steps: IStep[]
  remaining: number
  work: IWork
  is_first_order: boolean
  is_already_print_stickers: boolean
}
