import { Box } from '@material-ui/core'
import moment from 'moment'
import { useRef, useState } from 'react'
import { MdDateRange } from 'react-icons/md'

import PopperCalendar, { TBaseFilter } from './PopperCalendar'
import { Button, ContainerDate } from './styles'

interface IProps {
  onSelectedDate: (params: Date) => void
  selectedDate: Date
  setSelectedDate: (params: Date) => void
  baseFilter: TBaseFilter
}

const Calendar = (props: IProps) => {
  const [openCalendar, setOpenCalendar] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpenCalendar(prevOpen => !prevOpen)
  }

  const onSelectDate = (date: any) => {
    props.setSelectedDate(date)
    setOpenCalendar(false)
    props.onSelectedDate(date)
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpenCalendar(false)
  }

  const dateFormatted = moment(props.selectedDate).format('DD/MM/YYYY')

  return (
    <Box>
      <Button
        ref={anchorRef}
        aria-controls={openCalendar ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <ContainerDate>
          <MdDateRange fontSize="1.2rem" color="#757575" />
          <span>{dateFormatted}</span>
        </ContainerDate>
      </Button>
      <PopperCalendar
        anchorRef={anchorRef}
        handleClose={handleClose}
        onSelectDate={onSelectDate}
        openCalendar={openCalendar}
        selectedDate={props.selectedDate}
        baseFilter={props.baseFilter}
      />
    </Box>
  )
}

export default Calendar
