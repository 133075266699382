import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  styled,
  TableBody,
  TextField,
} from '@material-ui/core'
import { Title } from 'components'
import { IOnChange } from 'interfaces/IPagination'
import { useState, CSSProperties, ChangeEvent, memo } from 'react'
import { FiSearch } from 'react-icons/fi'
import { Scrollbar } from 'shared'
import { v4 } from 'uuid'

import NoData from './NoData'
import Table from './Table'
import TableCellBody from './TableCellBody'
import TableCellHead from './TableCellHead'
import TableContainer from './TableContainer'
import TableHead, { TableRowHead } from './TableHead'
import Pagination from './TablePagination'
import TableRow from './TableRow'
import { IColumn, IFilter, IParamsPagination } from './types'

interface Props<T> {
  columns: IColumn<T>[]
  count: number
  data: T[]
  params: IParamsPagination
  status: 'initial' | 'success' | 'error' | 'pending'
  size?: 'small' | 'medium'
  filterProps?: IFilter
  checkboxSelection?: boolean
  title?: string
  hover?: boolean
  refresh: () => Promise<void> | void
  onChange: (x: IOnChange) => void
  onSelect?: (x: T[]) => void
  handleSearch?: (
    x: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => Promise<void> | void
  rowStyle?: (x: T) => CSSProperties
  rowOnClick?: (x: T) => void
  paginationServer?: boolean
  keyId?: keyof T
}

const TextFieldStyle = styled(TextField)(({ theme }) => ({
  maxWidth: 'initial',
  [theme.breakpoints.up('sm')]: {
    width: '50%',
    maxWidth: '350px',
  },
}))

const FormFilterStyle = styled(FormControl)(({ theme }) => ({
  maxWidth: 'initial',
  [theme.breakpoints.up('sm')]: {
    width: '45%',
    maxWidth: '300px',
  },
}))

function stableSort(array: any, cmp: any) {
  const stabilizedThis = array.map((el: any, index: number) => [el, index])
  stabilizedThis.sort((a: any, b: any) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el: any) => el[0])
}

function getSorting(order: any, orderBy: any) {
  return order === `-${orderBy}`
    ? (a: object, b: object) => desc(a, b, orderBy)
    : (a: object, b: object) => -desc(a, b, orderBy)
}

const desc = (a: any, b: any, orderBy: any) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const TableCustom = <T,>(props: Props<T>) => {
  const {
    columns,
    checkboxSelection,
    data,
    onChange,
    params,
    status,
    refresh,
    title,
    count,
    handleSearch,
    rowStyle,
    hover,
    filterProps,
    rowOnClick,
    size,
    paginationServer = true,
  } = props
  const [selected, setSelected] = useState<T[]>([])

  const isNoData = data.length === 0

  const isSelected = (id: any) => !!selected.find((item: any) => item.id === id)

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(state => [...state, ...data])
      props?.onSelect?.(data)
      return
    }
    props?.onSelect?.([] as any)
    setSelected([] as any)
  }

  const handleClick = (row: T & { id: number | string }) => {
    setSelected((state: any) => {
      const selectedItem = state.some(
        (item: T & { id: number | string }) => item.id === row.id,
      )

      if (selectedItem) {
        const filterItems = state.filter(
          (item: T & { id: number | string }) => item.id !== row.id,
        )
        props.onSelect?.(filterItems)
        return filterItems
      }

      const items = [...state, row]
      props?.onSelect?.(items)
      return items
    })
  }

  return (
    <Paper style={{ margin: '1rem 0 1rem', padding: '1rem 0' }}>
      {title && (
        <Title style={{ padding: '0 1.5rem 0', fontWeight: 'bold' }}>
          {title}
        </Title>
      )}

      <Box
        style={{ padding: '0 0.8rem', width: '100%', gap: '1rem' }}
        display="flex"
        flexWrap="wrap"
      >
        {handleSearch && (
          <TextFieldStyle
            onChange={handleSearch}
            fullWidth
            variant="outlined"
            label="Pesquisar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiSearch />
                </InputAdornment>
              ),
            }}
          />
        )}

        {filterProps && (
          <FormFilterStyle variant="outlined" fullWidth>
            <InputLabel shrink>{filterProps.label}</InputLabel>
            <Select
              label={filterProps.label}
              value={params[filterProps.parameter] || ''}
              defaultValue=""
              displayEmpty
              onChange={event =>
                onChange({
                  [filterProps.parameter]: event.target.value || undefined,
                  page: 1,
                })
              }
            >
              <MenuItem value="">Todos</MenuItem>

              {filterProps.values.map(value => {
                return (
                  <MenuItem key={value.id} value={value.id}>
                    {value.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormFilterStyle>
        )}
      </Box>

      <TableContainer style={{ minHeight: isNoData ? '21rem' : 'initial' }}>
        <Table size={size || 'medium'}>
          <TableHeader
            columns={columns}
            dataNumber={data.length}
            handleSelectAllClick={handleSelectAllClick}
            loading={status === 'pending'}
            onChange={onChange}
            selectedNumber={selected.length}
            checkboxSelection={checkboxSelection}
            orderBy={params.orderBy}
            orderDirection={params.orderDirection}
          />

          <TableBody>
            {stableSort(
              data,
              getSorting(params?.orderDirection, params?.orderBy),
            )
              .slice(
                !paginationServer ? params.page * params.pageSize : 0,
                !paginationServer
                  ? params.page * params.pageSize + params.pageSize
                  : data.length,
              )
              .map((item: any) => {
                const isItemSelected = isSelected(item.id)
                const id = v4()

                return (
                  <TableRow
                    key={id}
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                    onClick={() => rowOnClick && rowOnClick(item)}
                    hover={!!checkboxSelection || hover}
                    style={{
                      cursor: rowOnClick ? 'pointer' : 'initial',
                      ...(rowStyle ? rowStyle(item) : {}),
                    }}
                  >
                    {checkboxSelection && (
                      <TableCellBody
                        padding="checkbox"
                        style={{ paddingLeft: '1.5rem' }}
                      >
                        <Checkbox
                          onClick={() => handleClick(item)}
                          checked={isItemSelected}
                        />
                      </TableCellBody>
                    )}
                    {columns.map(column => {
                      const idCell = v4()
                      const { cellStyle } = column

                      if (column?.renderCell) {
                        return (
                          <TableCellBody
                            style={{ ...(cellStyle ? cellStyle(item) : {}) }}
                            key={idCell}
                            align={column?.align || 'left'}
                          >
                            {column.renderCell({ data: item })}
                          </TableCellBody>
                        )
                      }

                      return (
                        <TableCellBody
                          key={idCell}
                          style={{ ...(cellStyle ? cellStyle(item) : {}) }}
                          align={column?.align || 'left'}
                        >
                          {column?.formatter
                            ? column.formatter(item[column.field || ''])
                            : item[column.field || '']}
                        </TableCellBody>
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
        {status !== 'pending' && status !== 'initial' && isNoData && (
          <NoData status={status} refresh={refresh} />
        )}
      </TableContainer>
      <Scrollbar>
        <Pagination
          page={params.page}
          count={count}
          onChange={(_, newPage: number) => onChange({ page: newPage })}
          rowsPerPage={params.pageSize}
          handleRowsPerPageChange={newPageSize =>
            onChange({ pageSize: newPageSize, page: 1 })
          }
        />
      </Scrollbar>
    </Paper>
  )
}

interface TableHeaderProps {
  columns: any
  checkboxSelection?: boolean
  loading: boolean
  selectedNumber: number
  dataNumber: number
  handleSelectAllClick: (x: ChangeEvent<HTMLInputElement>) => void
  orderDirection?: string
  orderBy?: string
  onChange: (x: IOnChange) => void
}

const Component = (props: TableHeaderProps) => {
  const {
    columns,
    checkboxSelection,
    loading,
    selectedNumber,
    dataNumber,
    handleSelectAllClick,
    orderDirection,
    orderBy,
    onChange,
  } = props

  return (
    <TableHead>
      <TableRowHead>
        {checkboxSelection && (
          <TableCellHead padding="checkbox" type="checkbox" column="checkbox">
            <Checkbox
              indeterminate={selectedNumber > 0 && selectedNumber < dataNumber}
              checked={selectedNumber > 0 && selectedNumber === dataNumber}
              onChange={handleSelectAllClick}
            />
          </TableCellHead>
        )}

        {columns.map((column: any) => (
          <TableCellHead
            key={column?.field}
            style={column?.style}
            column={column.field || ''}
            align={column?.align}
            onChange={onChange}
            disabled={column?.disabledSort}
            paginationParams={{
              orderDirection,
              orderBy,
            }}
          >
            {column.label}
          </TableCellHead>
        ))}
      </TableRowHead>
      <TableRowHead>
        <TableCellBody
          colSpan={columns.length}
          style={{ padding: '0.4rem 0.7rem', border: 'none' }}
        >
          {loading && (
            <LinearProgress color="secondary" style={{ marginTop: '-10px' }} />
          )}
        </TableCellBody>
      </TableRowHead>
    </TableHead>
  )
}
const TableHeader = memo(Component)

export default TableCustom
