import { makeStyles } from '@material-ui/styles'
import { transparentize } from 'polished'

export const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      margin: 0,
      width: '96%',
      maxWidth: '410px',
    },
  },
  paper: {
    padding: 15,
    width: '100%',
  },
  box: {
    margin: '10px 0 15px',
    width: '100%',
  },
  title: {
    fontSize: 'clamp(1.1rem, 2.5vw, 1.4rem)',
    fontWeight: 600,
  },
  boxRadio: {
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    gap: '0.7rem',
    margin: '0.5rem 0',
  },
  buttonRadio: {
    width: '50%',
    textTransform: 'none',
    '&:hover': {
      border: '1px solid',
      borderColor: transparentize(0.2, '#d05a52'),
    },
  },
  buttonCheck: {
    width: '50%',
    background: transparentize(0.8, '#d05a52'),
    textTransform: 'none',
    '&:hover': {
      border: '1px solid',
      borderColor: transparentize(0.2, '#d05a52'),
    },
  },
  textFieldQuantity: {
    width: '100%',
    '& .MuiInputBase-input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  boxBtn: {
    marginTop: '1rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
  },
})
