import { IconProps, SvgIconProps } from '@material-ui/core'
import { enRules } from 'interfaces/IUser'
import { ComponentType } from 'react'
import { IconBaseProps } from 'react-icons'
import { AiOutlineDropbox, AiOutlineLineChart, AiOutlineScan } from 'react-icons/ai'
import { BiMedal } from 'react-icons/bi'
import { BsFillBarChartFill } from 'react-icons/bs'
import { FaFileInvoiceDollar, FaTruck, FaUserAlt } from 'react-icons/fa'
import { GiSteeringWheel } from 'react-icons/gi'
import { IoReceiptSharp } from 'react-icons/io5'
import { RiDashboardFill, RiSettings4Fill } from 'react-icons/ri'
export interface INavConfig {
  title: string
  path: string
  icon: ComponentType<IconBaseProps> | ComponentType<IconProps> | ComponentType<SvgIconProps>
  children?: INavConfig[]
  permissionsRequired: enRules[]
}

const NavConfig: INavConfig[] = [
  {
    title: 'Dashboard',
    path: 'dashboard',
    icon: RiDashboardFill,
    permissionsRequired: [enRules.Tech, enRules.SeparationDashboardView],
  },
  {
    title: 'Desempenho geral',
    path: 'desempenho',
    icon: BsFillBarChartFill,
    permissionsRequired: [enRules.Tech, enRules.SeparationDataAnalysis],
  },
  {
    title: 'Quebras',
    path: 'quebras',
    icon: AiOutlineLineChart,
    permissionsRequired: [enRules.Tech, enRules.SeparationBreakOrderManagement],
  },
  {
    title: 'Notas Fiscais',
    path: 'nfs',
    icon: FaFileInvoiceDollar,
    permissionsRequired: [enRules.Tech, enRules.SeparationInvoiceRelease],
  },
  {
    title: 'Reabastecimento',
    path: 'reabastecimento',
    icon: AiOutlineDropbox,
    permissionsRequired: [enRules.Tech, enRules.SeparationReplenishmentResponse],
  },
  {
    title: 'Pedidos',
    path: 'pedidos',
    icon: IoReceiptSharp,
    permissionsRequired: [enRules.Tech, enRules.SeparationOrdersManagement],
  },
  {
    title: 'Usuários',
    path: 'usuarios',
    icon: FaUserAlt,
    permissionsRequired: [enRules.Tech, enRules.SeparationUsersView],
  },
  {
    title: 'Conf. de Caixas',
    path: 'conferencia-de-caixas',
    icon: FaTruck,
    permissionsRequired: [enRules.Tech, enRules.SeparationReleasePackingList],
  },
  {
    title: 'Romaneios',
    path: 'romaneios',
    icon: GiSteeringWheel,
    permissionsRequired: [enRules.Tech, enRules.SeparationShipmentManagement],
  },
  {
    title: 'Ranking',
    path: 'ranking',
    icon: BiMedal,
    permissionsRequired: [enRules.Tech, enRules.SeparationRankingView],
  },
  {
    title: 'Pre pickings',
    path: 'pre-picking',
    icon: AiOutlineScan,
    permissionsRequired: [enRules.Tech, enRules.SeparationPrePickingConsult],
  },
  {
    title: 'Configurações',
    path: 'configuracoes',
    icon: RiSettings4Fill,
    permissionsRequired: [enRules.Tech, enRules.SeparationConfigsManagement],
  },
]

export default NavConfig
