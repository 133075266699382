import {
  IBreak,
  IDashboardResponse,
  IGetOrdersByUserId,
  IOrderDetailsResponse,
  IRouteDashboard,
} from 'interfaces/IDashboard'
import { IOrderItem, IPrePickingOrderItem } from 'interfaces/IOrderItem'

import { api as apiService, ApiService } from './api'

class DashboardService {
  constructor(private readonly api: ApiService) {}

  public getDataDashboard = async ({
    controller,
  }: {
    controller: AbortController
  }): Promise<IDashboardResponse> => {
    return this.api.get(carambolaUrl + '/dashboard/', {
      signal: controller.signal,
    })
  }

  public getOrderDetails = async (
    orderId: number | string,
    controller?: AbortController,
  ): Promise<IOrderDetailsResponse> => {
    return this.api.get(carambolaUrl + '/dashboard/order', {
      params: { orderId },
      signal: controller?.signal,
    })
  }

  public getBreaks = async (controller: AbortController): Promise<IBreak> => {
    return this.api.get(carambolaUrl + '/dashboard/break', {
      signal: controller.signal,
    })
  }

  public getRoutes = async (controller: AbortController): Promise<IRouteDashboard> => {
    return this.api.get(carambolaUrl + '/routes/', {
      signal: controller.signal,
    })
  }

  public getOrdersByUserId = async (
    userId: number,
    controller: AbortController,
  ): Promise<IGetOrdersByUserId> => {
    return this.api.get(carambolaUrl + '/dashboard/orders-user', {
      signal: controller.signal,
      params: { userId },
    })
  }
  public getOrderItemPrePickingData = async (
    orderItemId: number,
    {
      controller,
    }: {
      controller: AbortController
    },
  ): Promise<IOrderItem<IPrePickingOrderItem>> => {
    return this.api.get(carambolaUrl + '/dashboard/order/item', {
      signal: controller.signal,
      params: { orderItemId },
    })
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const dashboardService = new DashboardService(apiService)
