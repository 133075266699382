import { combineReducers } from 'redux'
import { ISettingsRequest } from 'services/settingsService'

import alert, { IAlertReducer } from './reducers/alert/reducer'
import dashboard, { IDashboardReducer } from './reducers/dashboard/reducer'
import infos, { IInfosReducer } from './reducers/infos/reducer'
import orderFastTrack, { IOrderFastTrackReducer } from './reducers/orderFastTrack/reducer'
import settings from './reducers/settings/reducer'
import toggleScanner from './reducers/toggleScanner/reducer'
import user, { IUser } from './reducers/user/reducer'

export type IState = {
  infos: IInfosReducer
  user: IUser
  settings: ISettingsRequest
  orderFastTrack: IOrderFastTrackReducer
  toggleScanner: boolean
  dashboard: IDashboardReducer
  alert: IAlertReducer
}

const reducers = combineReducers<IState>({
  infos,
  user,
  settings,
  orderFastTrack,
  toggleScanner,
  dashboard,
  alert,
})

export default reducers
