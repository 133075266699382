import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    maxWidth: '400px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '2rem ',
    margin: '0 1rem',
  },
  title: {
    color: '#353535',
    fontWeight: 600,
    fontSize: '1.2rem',
    textTransform: 'none',
    textAlign: 'center',
  },
  buttonConfirm: {
    padding: '0.5rem 1rem',
    color: '#FFF',
    height: '42px',
    borderRadius: '42px',
    background: '#89BD23',
  },
}))
