import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  btn: {
    bottom: '1.5rem',
    right: '1rem',
    position: 'fixed',
    background: '#FAFAFA',
    boxShadow:
      '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2)',
  },
  menuItem: {
    margin: '0.5rem',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
      background: '#EDF2EC',
    },
  },
  menuIcon: {
    flexShrink: 'initial',
    minWidth: 'initial',
    paddingRight: '0.9rem',
  },
})
