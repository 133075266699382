import { Box, Typography } from '@material-ui/core'
import { usePopup } from 'hooks/usePopup'
import { ISeparatorResponse } from 'interfaces/ISeparator'
import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { AiFillClockCircle } from 'react-icons/ai'
import { separatorService } from 'services/separatorService'
import { Modal } from 'shared'

import { useStyles } from './styles'

interface IModalperformance {
  isOpen: boolean
  handleClose: () => void
}

export const ModalPerformance = ({
  isOpen,
  handleClose,
}: IModalperformance) => {
  const classes = useStyles()
  const { addPopup } = usePopup()
  const [series, setSeries] = useState<any>([])
  const [dataPerformance, setDataPerformance] = useState<ISeparatorResponse>(
    {} as ISeparatorResponse,
  )

  const dataFormatter = (data: ISeparatorResponse) => {
    const separatorTotalTimeItem = data.separator.time_per_order.split(':')
    const separatorTotalTimeOrder = data.separator.time_per_item.split(':')
    const separatorTotalTime = data.separator.total_time
      .replace('day', 'dia')
      .replace('days', 'dias')
      .replace('months', 'meses')
      .replace('month', 'mês')
      .replace('year', 'ano')
      .replace('years', 'anos')
      .split(':')

    const averageTotalTimeItem = data.average.time_per_order.split(':')
    const averageTotalTimeOrder = data.average.time_per_item.split(':')
    const averageTotalTime = data.average.total_time
      .replace('day', 'dia')
      .replace('days', 'dias')
      .replace('months', 'meses')
      .replace('month', 'mês')
      .replace('year', 'ano')
      .replace('years', 'anos')
      .split(':')

    setDataPerformance({
      average: {
        ...data.average,
        time_per_item: `${averageTotalTimeItem[0].padStart(
          2,
          '0',
        )}:${averageTotalTimeItem[1].padStart(2, '0')}:${String(
          Math.floor(Number(averageTotalTimeItem[2])),
        ).padStart(2, '0')}`,
        time_per_order: `${averageTotalTimeOrder[0].padStart(
          2,
          '0',
        )}:${averageTotalTimeOrder[1].padStart(2, '0')}:${String(
          Math.floor(Number(averageTotalTimeOrder[2])),
        ).padStart(2, '0')}`,
        total_time: `${averageTotalTime[0].padStart(
          2,
          '0',
        )}:${averageTotalTime[1].padStart(2, '0')}:${String(
          Math.floor(Number(averageTotalTime[2])),
        ).padStart(2, '0')}`,
      },
      separator: {
        ...data.separator,
        time_per_item: `${separatorTotalTimeItem[0].padStart(
          2,
          '0',
        )}:${separatorTotalTimeItem[1].padStart(2, '0')}:${String(
          Math.floor(Number(separatorTotalTimeItem[2])),
        ).padStart(2, '0')}`,
        time_per_order: `${separatorTotalTimeOrder[0].padStart(
          2,
          '0',
        )}:${separatorTotalTimeOrder[1].padStart(2, '0')}:${String(
          Math.floor(Number(separatorTotalTimeOrder[2])),
        ).padStart(2, '0')}`,
        total_time: `${separatorTotalTime[0].padStart(
          2,
          '0',
        )}:${separatorTotalTime[1].padStart(2, '0')}:${String(
          Math.floor(Number(separatorTotalTime[2])),
        ).padStart(2, '0')}`,
      },
    })
  }

  const getDataPerformance = async () => {
    try {
      const data = await separatorService.getPerformance({
        timeRange: 'today',
      })

      dataFormatter(data)

      setSeries([
        {
          name: 'Você',
          data: [
            data.separator.kilos,
            data.separator.items,
            data.separator.orders,
          ],
        },
        {
          name: 'Outros separadores',
          data: [data.average.kilos, data.average.items, data.average.orders],
        },
      ])
    } catch {
      addPopup({
        type: 'error',
        title: 'Erro ao procurar dados de desempenho',
      })
    }
  }

  useEffect(() => {
    getDataPerformance()
  }, [])

  return (
    <Modal open={isOpen} handleClose={handleClose} title="Desempenho do dia">
      <Chart
        options={{
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
          },
          tooltip: {
            shared: false,
            intersect: false,
          },
          yaxis: {
            labels: {
              style: {
                colors: ['#000000'],
              },
            },
          },
          colors: ['#26a0fc', '#89BD23'],
          legend: {
            position: 'top',
            horizontalAlign: 'center',
          },
          chart: {
            toolbar: {
              show: false,
            },
          },
          xaxis: {
            categories: [
              'Quilos separados',
              'Itens separados',
              'Pedidos separados',
            ],
            axisBorder: {
              color: '#AFAFAF',
            },
            axisTicks: {
              color: '#AFAFAF',
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: '15px',
              colors: ['#fff'],
            },
          },
        }}
        series={series}
        type="bar"
        height={250}
        style={{ width: '100%' }}
      />

      <Box className={classes.containerInfo}>
        <Box className={classes.card}>
          <header className={classes.cardHeader}>
            <span className={classes.boxIcon}>
              <AiFillClockCircle />
            </span>
            <Typography className={classes.title}>
              Tempo de separação por item
            </Typography>
          </header>
          <Typography className={classes.subtitle}>
            Você - Outros separadores
          </Typography>
          <Typography>
            <strong className={classes.firtsValue}>
              {dataPerformance?.separator?.time_per_item}
            </strong>{' '}
            -{' '}
            <strong className={classes.lastValue}>
              {dataPerformance?.average?.time_per_item}{' '}
            </strong>
          </Typography>
        </Box>

        <Box className={classes.card}>
          <header className={classes.cardHeader}>
            <span className={classes.boxIcon}>
              <AiFillClockCircle />
            </span>
            <Typography className={classes.title}>
              Tempo de separação por pedido
            </Typography>
          </header>
          <Typography className={classes.subtitle}>
            Você - Outros separadores
          </Typography>
          <Typography>
            <strong className={classes.firtsValue}>
              {dataPerformance?.separator?.time_per_order}
            </strong>{' '}
            -{' '}
            <strong className={classes.lastValue}>
              {dataPerformance?.average?.time_per_order}{' '}
            </strong>
          </Typography>
        </Box>

        <Box className={classes.card}>
          <header className={classes.cardHeader}>
            <span className={classes.boxIcon}>
              <AiFillClockCircle />
            </span>
            <Typography className={classes.title}>
              Tempo total de separação
            </Typography>
          </header>
          <Typography className={classes.subtitle}>
            Você - Outros separadores
          </Typography>
          <Typography>
            <strong className={classes.firtsValue}>
              {dataPerformance?.separator?.total_time}
            </strong>{' '}
            -{' '}
            <strong className={classes.lastValue}>
              {dataPerformance?.average?.total_time}{' '}
            </strong>
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
}
