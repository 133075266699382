import { Container, Typography, makeStyles, styled, Dialog, Box } from '@material-ui/core'
import { Input } from 'shared'
import { Button as ButtonMui } from 'components'

export const ModalInputBoxNumber = styled(Dialog)({
  width: '100%',
  height: '100%',
  '& .MuiDialog-paper': {
    maxWidth: '900px',
    width: '900px',
    height: '350px',
    padding: '2rem 2rem 2rem 2rem',
    overflowY: 'initial',
  },
})

export const ContainerTitle = styled(Box)({
  '& .MuiTypography-root': {
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: 500,
  },
})

export const ContainerInfoOrder = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  height: '100%',
})

export const InfoOrder = styled(Box)({
  width: '100%',
  paddingRight: '1rem',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& > svg': {
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    top: '-80px',
    left: '50%',
    zIndex: 9999,
    background: '#FFFFFF',
    borderRadius: '50%',
    padding: '0.1rem',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    height: '100%',
    width: '1px',
    background: '#F4F4F4',
    right: 0,
    top: 0,
  },
})

export const ButtonConfirm = styled(ButtonMui)({
  width: '100%',
  background: '#8ABD23',
  color: '#FFF',
  fontWeight: 400,
  height: '50px',
})

export const InputBoxes = styled(Input)({
  '& input': {
    background: '#FFF !important ',
    color: '#353535 !important',
    fontSize: '1.5rem',
  },
})

export const SubTitle = styled(Typography)({
  color: '#353535',
  fontWeight: 500,
})

export const ContainerAuthorization = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  position: 'relative',
})

export const ButtonBack = styled(ButtonMui)({
  margin: '3rem auto 0 0',
  background: '#FFF',
  border: '1px solid #353535',
  color: '#353535',
  fontSize: '1.1rem',
  textTransform: 'none',
  width: '100%',
})
