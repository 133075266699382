import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 600,
    fontSize: '2.5rem',
    lineHeight: '135%',
    color: '#353535',
  },
  boxAlign: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginTop: '2rem',
    gap: '1rem',
    marginLeft: '3rem',
  },
  subtitle: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '135%',
    color: '#353535',
    marginBottom: '1rem',
  },
  buttonsAlign: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '1rem',
    marginBottom: '1.5rem',
  },
  redirectUser: {
    marginTop: '4rem',
  },
  centralizeButton: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  header: {
    justifyContent: 'space-between',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    width: '45rem',
    [theme.breakpoints.between(0, 550)]: {
      width: '25rem',
    },
    [theme.breakpoints.between(0, 350)]: {
      width: 'auto',
    },
  },
  boxDate: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '10rem',
    border: '1px solid #C4C4C4',
    padding: '0.9rem 1rem',
  },
}))
