import { useState } from 'react'
import { Box, Modal, Paper, Typography } from '@material-ui/core'
import { ITrack, IWorkCompleted } from 'interfaces/IDashboard'
import { useStyles } from './styles'
import { Button } from 'components/Button'
import { usePopup } from 'hooks/usePopup'
import { orderService } from 'services/orderService'
import { pauseService } from 'services/pauseService'

interface IModalPauseProps {
  open: boolean
  onClose: () => void
  dataSeparator: ITrack
  refreshDashboard: () => Promise<void>
  lastWork: IWorkCompleted
}

const ModalPause = ({
  open,
  onClose,
  dataSeparator,
  refreshDashboard,
  lastWork,
}: IModalPauseProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { addPopup } = usePopup()

  const classes = useStyles()
  const orderInSeparation = lastWork.end ? false : lastWork.order

  const resetOrder = async () => {
    setLoading(true)
    try {
      if (orderInSeparation) {
        await orderService.updateOrder({
          reset: true,
          ordersId: [orderInSeparation as string],
        })
        addPopup({
          type: 'success',
          title: `Pedido #${orderInSeparation} resetado com sucesso`,
        })
      }
      const { message } = await pauseService.createPause({
        userId: dataSeparator.user_id,
        action: dataSeparator.is_paused ? 'play' : 'pause',
      })
      addPopup({
        type: 'success',
        title: message,
      })

      await refreshDashboard()
      onClose()
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao criar pausa',
        description: err?.message || 'Erro ao criar pausa',
      })
    }
    setLoading(false)
  }

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Paper>
        <Box className={classes.container}>
          <Typography className={classes.text}>
            Deseja {dataSeparator.is_paused ? 'voltar' : 'pausar'} o tempo de ociosidade de{' '}
            {dataSeparator.username}?
          </Typography>
          {orderInSeparation && (
            <Typography className={classes.description}>
              Ele tem o pedido #{orderInSeparation} em separação.
            </Typography>
          )}

          <Box className={classes.containerButtons}>
            <Button className={classes.buttonCancel} onClick={onClose}>
              Cancelar
            </Button>

            <Button className={classes.buttonConfirm} isLoading={loading} onClick={resetOrder}>
              {orderInSeparation
                ? 'Resetar e pausar'
                : dataSeparator.is_paused
                ? 'Voltar'
                : 'Pausar'}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  )
}

export default ModalPause
