import { Box, styled } from '@material-ui/core'
import ScanIcon from 'assets/icons/scan-bold.svg'
import { usePopup } from 'hooks/usePopup'
import { useState } from 'react'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'

import { TValue } from '../useScanner'

const position = 'initial !important'

interface IContainerCam {
  border: boolean
}
const ContainerCam = styled(Box)(({ border }: IContainerCam) => ({
  width: '70%',
  maxWidth: '300px',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  zIndex: 1,
  margin: '0 auto',
  '& > img': {
    position: 'absolute',
    width: '100%',
    height: '80%',
    padding: '1.5rem',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 3,
    filter:
      'invert(100%) sepia(1%) saturate(7388%) hue-rotate(162deg) brightness(121%) contrast(100%)',
  },
  '& video': {
    maxWidth: '100%',
    width: '100%',
    borderRadius: '10px',
    height: 'auto !important',
    position: position as any,
    margin: '0 auto',
    border: border ? `10px solid #89bd23` : 'none',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    width: 'calc(100% - 2rem)',
    height: '6px',
    borderRadius: '4px',
    background: '#FFF',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
  },
}))

const getCodeType = (format?: number): TValue => {
  const codeType =
    {
      5: 'data_matrix',
      11: 'qr_code',
    }[format as number] || 'bar_code'
  return codeType as TValue
}

const Cam = ({ onSubmit }: { onSubmit: (param: string, type: TValue) => void }) => {
  const [, setdisabledScanner] = useState(false)
  const [border, setBorder] = useState(false)
  const { addPopup } = usePopup()

  const middleSubmit = (result: any) => {
    if (!result || !result?.text) return
    setdisabledScanner(state => {
      if (!state) {
        onSubmit(result?.text, getCodeType(result?.format))
        setBorder(true)
        navigator.vibrate(200)
        setTimeout(() => {
          setdisabledScanner(false)
          setBorder(false)
        }, 1000)
      }
      return true
    })
  }

  const onError = () => {
    addPopup({
      type: 'error',
      title: 'Erro ao ativar a camera do dispositivo',
    })
  }

  return (
    <ContainerCam border={border}>
      <img src={ScanIcon} />
      <BarcodeScannerComponent
        onError={onError}
        onUpdate={(_, result: any) => middleSubmit(result)}
      />
    </ContainerCam>
  )
}

export default Cam
