import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  titlePage: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },
  link: {
    cursor: 'pointer',
    color: '#757575',
    fontSize: '1.5rem',
    border: 'none',
    background: 'transparent',
    fontWeight: 500,
    textDecoration: 'none',
    transition: '0.2s',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.between(0, 610)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  search: {
    width: '16.25rem',
    [theme.breakpoints.between(0, 610)]: {
      marginLeft: 'auto',
      marginBottom: '10px',
    },
  },
  containerName: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  btnFilter: {
    marginRight: '60px',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#757575',
    fontWeight: 500,
    fontSize: '0.875rem',
    display: 'flex',
    gap: '0.2rem',
    '& strong': {
      color: '#353535',
    },
  },
  operatorName: {
    color: '#353535',
    fontSize: '1.8rem',
    fontWeight: 700,
  },
  operatorUser: {
    color: '#6F6F6F',
    fontSize: '1.2rem',
    fontWeight: 700,
    lineHeight: '2rem',
  },
  chart: {
    width: '75%',
    [theme.breakpoints.between(0, 1000)]: {
      width: '100%',
    },
  },
}))
