import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  containerChartInfo: {
    display: 'flex',
    width: '100%',
    gap: '2rem',
    '& > .MuiBox-root': {
      width: '50%',
    },
    [theme.breakpoints.down(500)]: {
      flexDirection: 'column',
      '& > .MuiBox-root': {
        width: '100%',
      },
    },
  },
  containerChart: {
    position: 'relative',
  },
  containerInfo: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },
  card: {
    width: '100%',
    background: '#ffffff',
    borderRadius: '1rem',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    padding: '1rem',
    display: 'grid',
    gridColumn: '1fr 1fr',

    [theme.breakpoints.between(0, 645)]: {
      width: '100%',
    },
  },
  cardHeader: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  boxIcon: {
    width: '3.65rem',
    aspectRatio: '1/1',
    background: 'rgba(158, 211, 52, 0.2)',
    color: '#9ED334',
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '7rem',
  },
  title: {
    color: '#303030',
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  containerTimes: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative',
    '& .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    '&::after': {
      content: '""',
      width: '1px',
      height: '100%',
      background: '#e0e0e0',
      position: 'absolute',
      right: 'calc(50% - 0.5px)',
    },
  },
  subtitle: {
    color: '#88888a',
    fontWeight: 500,
    fontSize: '0.9rem',
    position: 'relative',
    [theme.breakpoints.down(600)]: {
      fontSize: '0.7rem',
    },
  },
  firtsValue: {
    color: '#353535',
    fontSize: '1.1rem',
    fontWeight: 600,
    width: '50%',
    [theme.breakpoints.down(600)]: {
      fontSize: '0.9rem',
    },
  },
}))
