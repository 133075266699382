import {
  styled,
  Paper as PaperMui,
  Typography,
  TableContainer,
  Modal as ModalMui,
  Box,
} from '@material-ui/core'

export const Modal = styled(ModalMui)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const Paper = styled(PaperMui)({
  maxWidth: '600px',
  width: '100%',
  margin: '1rem',
  paddingBottom: '2rem',
  '& .MuiTable-root': {
    width: '100%',
  },
})

export const ContainerHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  margin: '8px 1rem',
  padding: '8px 0',
  '& .MuiIconButton-root': {
    position: 'absolute',
    right: '0',
  },
})

export const Title = styled(Typography)({
  fontSize: '1.4rem',
  fontWeight: 500,
  textTransform: 'capitalize',
})

export const ContainerTable = styled(TableContainer)({
  padding: '1rem',
  overflow: 'auto',
  maxHeight: '400px',
  '&::-webkit-scrollbar': {
    width: '4px',
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#A1CA4F',
    borderRadius: '9px',
  },
  '& .MuiTableHead-root .MuiTableRow-head': {
    borderBottom: '1px solid #e0e0e0',
  },
  '& .MuiTableCell-root': {
    padding: '12px',
    border: 'none',
  },
  '& .MuiTableCell-head': {
    color: '#757575',
  },

  '& .MuiTableBody-root': {
    '& .MuiTableRow-root': {
      '&:nth-child(even)': {
        backgroundColor: '#f5f5f5',
      },
    },
  },
})
