import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  title: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '1.8rem',
    textAlign: 'center',
  },
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: '32.06rem',
      width: '100%',
      padding: '2rem',
      margin: '1rem',
    },
  },
  button: {
    width: '100%',
    background: '#89BD23',
    borderRadius: '4px',
    height: '3rem',
    textAlign: 'center',
    color: '#fff',
    '&:hover': {
      filter: 'brightness(0.9)',
      background: '#89BD23',
    },
    '&:disabled': {
      filter: 'grayscale(1)',
      color: '#fff',
      background: '#89BD23',
    },
  },
  buttonChange: {
    background: 'transparent',
    borderRadius: '4px',
    height: '3rem',
    textAlign: 'center',
    color: '#757575',
    '&:hover': {
      filter: 'brightness(0.9)',
      background: 'transparent',
    },
    '&:disabled': {
      filter: 'grayscale(1)',
      color: '#fff',
    },
  },
  input: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F4F4F4',
      borderRadius: '16px',
      '& fieldset': {
        borderColor: '#F4F4F4',
      },
    },
    '& input': {
      textAlign: 'center',
    },
  },
  containerInputButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '5px',
    marginBottom: '2rem',
    '& button': {
      width: '40px',
      height: '40px',
    },
  },
  textDescription: {
    margin: '1.5rem 0',
    textAlign: 'center',
  },
  linearProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    background: '#618619',
  },
})

export default useStyles
