import { Typography, Modal, Paper, Button } from '@material-ui/core'
import { IProduct } from 'interfaces/IProduct'
import { forwardRef, ForwardRefRenderFunction, useState, useImperativeHandle } from 'react'

import { useStyles } from './styles'
export interface IModalError {
  dispatchError: (param: IError) => void
}
export interface IModalErrorProps {
  product: IProduct
}

type ITypeError =
  | 'wrongProductProductBase'
  | 'wrongUnity'
  | 'overweight'
  | 'wrongBox'
  | 'overQuantity'
  | 'repeatProduct'
  | 'incorrectWeight'
  | 'notFound'
  | 'wrongSku'
  | 'uniqueTagOnly'
  | 'useProductScanType'
  | 'wrongBarcode'
interface IError {
  typeError: ITypeError
  productBeeped?: {
    productId?: number
    weight?: number
    quantity?: number
    typePrepick?: 'un' | 'kg'
    message?: string
  }
  id?: string
  beepedBox?: string
  weighings?: {
    currentQuantity?: number | string
    limitQuantity?: number
    minimumQuantity?: number
    message?: string
  }
  correctBox?: string
  message?: string
  sku?: {
    correct: string
    beeped: string
  }
  codeBarBiped?: string
  CodeBarCorrect?: string
}

const Component: ForwardRefRenderFunction<IModalError, IModalErrorProps> = ({ product }, ref) => {
  const [open, setOpen] = useState(false)
  const [dataError, setDataError] = useState<IError>({} as IError)
  const classes = useStyles()

  const {
    typeError,
    productBeeped,
    weighings,
    beepedBox,
    correctBox,
    id,
    message,
    sku,
    CodeBarCorrect,
    codeBarBiped,
  } = dataError

  const typePrepick = productBeeped?.typePrepick === 'un' ? 'Unidade' : 'Kg'

  const mapError = (typeError: ITypeError) => {
    return {
      wrongProductProductBase: {
        error: 'Produto bipado errado',
        message: 'Confira se o produto está correto!',
        message2: `ID do produto bipado: ${productBeeped?.productId}`,
        message3: `ID do produto correto: ${product?.product_base_id}`,
      },
      wrongUnity: {
        error: 'Produto bipado tem porcionamento errado',
        message: 'Confira se o porcionamento do produto está correto',
        message2: productBeeped?.message,
      },
      overweight: {
        error: 'Produto bipado tem peso diferente que o permitido',
        message: 'Confira se o peso do produto está correto',
        message2: weighings?.message,
      },
      incorrectWeight: {
        error: 'Produto bipado tem peso diferente do esperado',
        message: 'Confira se o peso do produto está correto',
        message2: `Peso total bipado: ${weighings?.currentQuantity} ${typePrepick}`,
        message3: `Peso esperado ${
          typePrepick === 'Unidade'
            ? weighings?.minimumQuantity
            : weighings?.minimumQuantity?.toFixed(3)
        } ${typePrepick}`,
      },
      wrongBox: {
        error: 'Caixa bipada errada',
        message: 'Confira se a caixa está correta',
        message2: `ID caixa bipada: ${beepedBox}`,
        message3: `ID caixa correta: ${correctBox}`,
      },
      overQuantity: {
        error: 'Quantidade de produtos bipados maior que a permitida',
        message: 'Confira se a quantidade de produtos está correta',
        message2: `Quantidade total bipada: ${weighings?.currentQuantity} Un`,
        message3: `Quantidade total permitida ${weighings?.limitQuantity} Un`,
      },
      repeatProduct: {
        error: 'Produto já foi bipado',
        message2: message || 'Produto já foi bipado',
      },
      notFound: {
        error: `Produto com ID #${id} não encontrado`,
      },
      wrongSku: {
        error: 'SKU bipado errado',
        message: 'Confira se o SKU está correto!',
        message2: `ID do SKU bipado: ${sku?.beeped}`,
        message3: `ID do SKU correto: ${sku?.correct}`,
      },
      uniqueTagOnly: {
        error: 'Produto com tag única',
        message: 'Este produto só pode ser bipado com etiqueta única',
      },
      useProductScanType: {
        error: 'Tipo de leitura incorreto',
        message: 'Apenas bipagem do produto é permitida.',
      },
      wrongBarcode: {
        error: 'Código de barras incorreto',
        message2: `Código de barras bipado: ${codeBarBiped}`,
        message3: `Código de barras correto: ${CodeBarCorrect}`,
      },
    }[typeError]
  }

  const handleClose = () => {
    setOpen(false)
  }

  const dispatchError = (dataError: IError) => {
    setOpen(true)
    setDataError(dataError)
  }

  useImperativeHandle(ref, () => ({ dispatchError }))

  return (
    <Modal open={open} onClose={handleClose} className={classes.root}>
      <Paper className={classes.container}>
        <Typography className={classes.title}>{mapError(typeError)?.error}</Typography>
        <Typography className={classes.description}>{mapError(typeError)?.message}</Typography>
        <Typography className={classes.detailsText}>
          <p>
            <strong>{mapError(typeError)?.message2}</strong>
          </p>
          <p>
            <strong>{mapError(typeError)?.message3}</strong>
          </p>
        </Typography>
        <Button className={classes.buttonExit} onClick={handleClose}>
          Fechar
        </Button>
      </Paper>
    </Modal>
  )
}
export default forwardRef(Component)
