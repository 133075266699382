import { Box, Typography } from '@material-ui/core'
import orderCanceledImg from 'assets/images/order-canceled.svg'
import { Button } from 'components'
import { useStyles } from './styles'
import { ForwardRefRenderFunction, forwardRef, useImperativeHandle, useState } from 'react'
import { Modal } from 'shared'

export interface IModalOrderCanceled {
  onOpen: () => void
  onClose: () => void
}

interface IProps {
  callback?: () => void
  orderId: string | null
  clientName: string | null
}

export const Component: ForwardRefRenderFunction<IModalOrderCanceled, IProps> = (props, ref) => {
  const { clientName, orderId, callback } = props
  const classes = useStyles()
  const [open, setIsOpen] = useState(false)

  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  const handleConfirm = () => {
    callback && callback()
    onClose()
  }

  useImperativeHandle(ref, () => ({ onOpen, onClose }))

  return (
    <Modal open={open} title="Opa, pedido cancelado!" handleClose={onClose} haventCloseButton>
      <Box className={classes.root}>
        <header className={classes.header}>
          <img src={orderCanceledImg} className={classes.imgCompleted} />
        </header>
        <Typography component="strong" className={classes.title}>
          Pedido <strong>{`${orderId}`}</strong>
        </Typography>
        <Typography className={classes.subtitle}>{clientName}</Typography>
        <Typography className={classes.subtitle}>
          Esquenta não que ele já vai sair da tela de quebras. Cê Pode desmontar esse pedido. 🤠
        </Typography>
        <Button onClick={handleConfirm} className={classes.button} variant="contained">
          Opa, tá bão!
        </Button>
      </Box>
    </Modal>
  )
}

export const ModalOrderCanceled = forwardRef(Component)
