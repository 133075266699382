import {
  IFeedbackSeparator,
  ISeparator,
  ISeparatorData,
  ISeparatorInfos,
  ISeparatorResponse,
  ISeparatorResult,
  ISeparators,
  IUpdateSeparator,
  IGetAverageTimeSeparators,
  IGetAverageTimeItemSeparators,
} from 'interfaces/ISeparator'

import { api as apiService, ApiService } from './api'
import moment from 'moment'
import { IGroup, permissionsAplication } from 'interfaces/IUser'

class SeparatorService {
  constructor(private readonly api: ApiService) {}

  public async getSeparators(
    search: string | undefined,
    signal: AbortSignal,
    groupId: number | undefined,
  ): Promise<ISeparatorResult> {
    return await this.api.get(carambolaUrl + '/separator/', {
      params: {
        search,
        groupId,
      },
      signal,
    })
  }

  public async getSeparatorData(id: number): Promise<ISeparatorData> {
    return await this.api.get(carambolaUrl + '/dashboard/separator', {
      params: {
        separatorId: id,
      },
    })
  }

  public async getPerformance(params: ISeparator): Promise<ISeparatorResponse> {
    return await this.api.get(carambolaUrl + '/separator/performance', {
      params,
    })
  }

  public async getPermissions(): Promise<IGroup[]> {
    const response = (await this.api.get(carambolaUrl + '/separator/groups')) as IGroup[]
    const permissionsCarambola = Object.keys(permissionsAplication)
    return response
      .filter(group => permissionsCarambola.includes(group.name))
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  public async updateSeparatorInfo(data: Partial<IUpdateSeparator>): Promise<ISeparators> {
    return await this.api.patch(carambolaUrl + `/separation/${data.id}`, data)
  }

  public createSeparator = async (data: Partial<ISeparatorInfos>): Promise<ISeparatorInfos> => {
    return this.api.post(carambolaUrl + '/separator', data)
  }

  public getFeedbackSeparator = async (
    userId: number,
    date_from: string,
    controller: AbortController,
  ): Promise<IFeedbackSeparator> => {
    const today = moment().add(1, 'days').format('YYYY-MM-DD')

    return this.api.get(carambolaUrl + `/separation/feedback/${userId}`, {
      params: {
        date_from,
        date_to: today,
      },
      signal: controller.signal,
    })
  }

  public getAverageTimeSeparators = async (
    initialSeparationDate: string,
    finalSeparationDate: string,
    controller: AbortController,
  ): Promise<IGetAverageTimeSeparators[]> => {
    return this.api.get(carambolaUrl + `/data-analysis/time-to-separation-by-separator`, {
      params: {
        initialSeparationDate,
        finalSeparationDate,
      },
      signal: controller.signal,
    })
  }

  public getAverageTimeItemSeparators = async (
    initialSeparationDate: string,
    finalSeparationDate: string,
    controller: AbortController,
  ): Promise<IGetAverageTimeItemSeparators[]> => {
    return this.api.get(carambolaUrl + `/data-analysis/time-to-item-separation-by-separator`, {
      params: {
        initialSeparationDate,
        finalSeparationDate,
      },
      signal: controller.signal,
    })
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const separatorService = new SeparatorService(apiService)
