import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    border: '2px solid #89BD23',
    borderRadius: '50%',
    backgroundColor: '#F5F5F5',
    minWidth: '17rem',
    minHeight: '17rem',
    [theme.breakpoints.between(0, 600)]: {
      minWidth: '15rem',
      minHeight: '15rem',
    },
  },
  box: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#89BD23',
  },
  title: {
    width: '80%',
    height: '80%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '5rem',
    color: '#353535',
    background: '#ffffff',
    borderRadius: '50%',
    paddingTop: '2.8rem',
    [theme.breakpoints.between(0, 600)]: {
      fontSize: '4rem',
      paddingTop: '3rem',
    },
    '& > strong': {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '3rem',
      color: '#757575',
      [theme.breakpoints.between(0, 600)]: {
        fontSize: '2rem',
      },
    },
  },
}))
