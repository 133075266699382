import { useState, useEffect, useRef, useMemo } from 'react'
import { Box, LinearProgress, Typography, makeStyles } from '@material-ui/core'
import Table from '../Table'
import { dashboardService } from 'services/dashboardService'
import { IGetOrdersByUserId, IOrderDashboard } from 'interfaces/IDashboard'
import { usePopup } from 'hooks/usePopup'
import { BsStopwatch } from 'react-icons/bs'

interface IContentAccordionProps {
  userId: number
}

interface IShowOrders extends IGetOrdersByUserId {
  allOrders: IOrderDashboard[]
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  textLastUpdated: {
    display: 'flex',
    gap: '2px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    color: '#757575',
    fontWeight: 400,
    fontSize: '0.9rem',
  },
}))

const ContentAccordion = (props: IContentAccordionProps) => {
  const [loading, setLoading] = useState(false)
  const timeout = useRef<any>(null)
  const [orders, setOrders] = useState<IShowOrders>(null as any)
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date())
  const classes = useStyles()
  const { addPopup } = usePopup()
  const controller = new AbortController()

  const getOrders = async () => {
    setLoading(true)
    try {
      const { completed_orders_user, orders_user_in_progress, user_id } =
        await dashboardService.getOrdersByUserId(props.userId, controller)
      setLastUpdated(new Date())
      setOrders({
        completed_orders_user,
        orders_user_in_progress,
        user_id,
        allOrders: orders_user_in_progress
          ? [...completed_orders_user, orders_user_in_progress]
          : [...completed_orders_user],
      })
    } catch (err: any) {
      if (controller.signal.aborted === false) {
        addPopup({
          title: 'Erro ao pegar os pedidos do usuario',
          type: 'error',
          description: err?.message,
        })
      }
    } finally {
      setLoading(false)
      if (controller.signal.aborted === false) {
        timeout.current = setTimeout(() => getOrders(), 7000)
      }
    }
  }

  const formatLastUpdated = useMemo(
    () =>
      `${lastUpdated.getHours().toString().padStart(2, '0')}:${lastUpdated
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${lastUpdated.getSeconds().toString().padStart(2, '0')}`,
    [lastUpdated],
  )

  useEffect(() => {
    getOrders()

    return () => {
      controller.abort()
      clearTimeout(timeout.current)
    }
  }, [])

  return (
    <Box className={classes.root}>
      {loading && <LinearProgress variant="indeterminate" />}
      {orders && (
        <Typography className={classes.textLastUpdated}>
          {' '}
          <BsStopwatch />
          {formatLastUpdated}
        </Typography>
      )}

      {orders && (orders?.completed_orders_user?.length > 0 || orders.orders_user_in_progress) && (
        <Table orders={orders.allOrders} />
      )}
    </Box>
  )
}

export default ContentAccordion
