import { Box, styled, TextField } from '@material-ui/core'
import { TitlePage as TitlePageComponent } from 'components'
import { Link as LinkRouter } from 'react-router-dom'

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.between(0, 610)]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

export const Link = styled(LinkRouter)({
  cursor: 'pointer',
  color: '#757575',
  fontSize: '1.5rem',
  border: 'none',
  background: 'transparent',
  fontWeight: 500,
  textDecoration: 'none',
  transition: '0.2s',
  '&:hover': {
    textDecoration: 'underline',
  },
})

export const TitlePage = styled(TitlePageComponent)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
})

export const InvisibleInput = styled(TextField)({
  opacity: 0,
  position: 'absolute',
})
