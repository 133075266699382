import { Box, Table, TableBody, TableCell, TableRow, makeStyles } from '@material-ui/core'
import { RiDeleteBack2Fill } from 'react-icons/ri'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
    '& table': {
      borderSpacing: '10px',
      borderCollapse: 'separate',
    },
    '& td': {
      userSelect: 'none',
      cursor: 'pointer',
      textAlign: 'center',
      minWidth: '100px',
      maxWidth: '100px',
      minHeight: '50px',
      maxHeight: '50px',
      background: '#F4F4F4',
      fontSize: '2rem',
      borderBottom: 'none',
      '&:hover': {
        filter: 'brightness(0.9)',
        transition: 'all 0.1s ease',
      },
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '.5rem',
    margin: '0 auto',
    color: 'white',
    fontSize: '1rem',
    userSelect: 'none',
    '&:active': {
      filter: 'brightness(0.9)',
    },
  },
})

interface IProps {
  onClick: (value: string) => void
}

const VirtualKeyboard = ({ onClick }: IProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell onClick={() => onClick('1')}>1</TableCell>
            <TableCell onClick={() => onClick('2')}>2</TableCell>
            <TableCell onClick={() => onClick('3')}>3</TableCell>
          </TableRow>
          <TableRow>
            <TableCell onClick={() => onClick('4')}>4</TableCell>
            <TableCell onClick={() => onClick('5')}>5</TableCell>
            <TableCell onClick={() => onClick('6')}>6</TableCell>
          </TableRow>
          <TableRow>
            <TableCell onClick={() => onClick('7')}>7</TableCell>
            <TableCell onClick={() => onClick('8')}>8</TableCell>
            <TableCell onClick={() => onClick('9')}>9</TableCell>
          </TableRow>

          <TableRow>
            <TableCell style={{ background: '#FFF' }}></TableCell>
            <TableCell onClick={() => onClick('0')}>0</TableCell>
            <TableCell onClick={() => onClick('delete')}>
              <RiDeleteBack2Fill
                size={40}
                color="#f33c3c"
                style={{
                  display: 'block',
                  margin: '0 auto',
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  )
}

export default VirtualKeyboard
