import { Box, Typography } from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import imageCompleted from 'assets/images/operation-completed.svg'
import { Button } from 'components'

import { useStyles } from './styles'

interface IOperationCompleted {
  getOrderId: () => Promise<void>
}

export const OperationCompleted = ({ getOrderId }: IOperationCompleted) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <header className={classes.header}>
        <img src={imageCompleted} className={classes.imgCompleted} />

        <Typography component="strong" className={classes.title}>
          Sem pedidos novos
        </Typography>
        <Typography className={classes.subtitle}>
          Todos os pedidos foram separados.
        </Typography>
      </header>

      <Button
        endIcon={<ArrowForwardIos />}
        onClick={getOrderId}
        className={classes.nextOrder}
        variant="contained"
      >
        Procurar pedido
      </Button>
    </Box>
  )
}
