import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { ContainerPage, SwitchIOS } from 'components'
import { DialogEdit } from './DialogEdit'
import { DialogUpload } from './DialogUpload'
import { IProductApi, IProductApiPutRequest } from 'interfaces/IRequestProductApi'
import React, { useEffect, useRef, useState } from 'react'
import { BsFilter } from 'react-icons/bs'
import { FaCog, FaFileUpload } from 'react-icons/fa'
import { IoIosArrowForward } from 'react-icons/io'
import { IoChevronDown } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import { productApiResquestService } from 'services/productApiService'
import { skuConfigService } from 'services/skuConfigService'
import { settingsService } from 'services/settingsService'
import { MenuPopover, Search } from 'shared'
import { IState } from 'store'
import { debaunce, mappingPortionType, mappingStatusType } from 'utils'

import { useStyles } from './styles'
import { usePopup } from 'hooks/usePopup'
import { IAmountProduct } from 'interfaces/IRequestProduct'
import Pagination from 'shared/TableCustom/TablePagination'
import { AiFillSave } from 'react-icons/ai'
import { HiPencil } from 'react-icons/hi'
import { useConfig } from 'hooks/useConfig/Index'

export const Skus = () => {
  const { getSetting } = useConfig()
  const searchDebaunce = debaunce({ fn: handleSearch, delay: 500 })
  const { addPopup } = usePopup()
  const [searchParams, setSearchParams] = useSearchParams()
  const [popover, setPopover] = useState(false)
  const [level, setLevel] = useState<Array<number>>([])
  const [skus, setSkus] = useState<Array<IProductApi>>([])
  const [staticSkus, setStaticSkus] = useState<Array<IProductApi>>([])
  const [loading, setLoading] = useState(false)
  const [loadingValidty, setLoadingValidty] = useState(false)
  const [dialogEditSeparation, setDialogEditSeparation] = useState({
    isOpen: false,
    data: 0,
  })
  const [dialogUploadExcel, setDialogUploadExcel] = useState(false)
  const {
    settings,
    user: { userId },
  } = useSelector<IState, IState>(state => state)
  const userRef = useRef(null)
  const [amountProducts, setAmountProducts] = useState<IAmountProduct[]>([])
  const [separationsSelected, setSeparationsSelected] = useState<number[]>([])
  const [editedSkus, setEditedSkus] = useState<IProductApi[]>([])
  const [checked, setChecked] = useState(false)
  const classes = useStyles()
  const [params, setParams] = useState({
    pageIndex: 1,
    pageSize: 20,
    total: 0,
    search: searchParams.get('search') || '',
    separationOrder: [] as number[],
  })

  const getSkus = async () => {
    getAmountProducts(0)
    setLoading(true)
    const isSku = /^[0-9]+$/.test(params?.search || '')
    try {
      const { results, pageIndex, pageSize, total } = await skuConfigService.getProducts({
        orderBy: 'name',
        status: 'active',
        pageIndex: params?.pageIndex,
        pageSize: params?.pageSize,
        separationOrder: params?.separationOrder.join(','),
        ...(params?.search && !isSku && { search: params?.search }),
        ...(isSku && { sku: Number(params?.search), search: '' }),
      })
      setParams(state => ({ ...state, pageIndex, pageSize, total }))
      const resultsData = results.reduce(
        (acc: IProductApi[], item) => [
          ...acc,
          {
            ...item,
            maximumExtraOrder: item.maximumExtraOrder * 100,
            minimumExtraOrder: item.minimumExtraOrder * 100,
          },
        ],
        [],
      )
      setSkus(resultsData)
      setStaticSkus(resultsData)
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao buscar produtos',
        description: err?.message || '',
      })
    }
    setLoading(false)
  }

  async function getAmountProducts(value: number) {
    try {
      setLoading(true)
      const { separationOrders } =
        await productApiResquestService.getAmountProductsBySeparationOrder(value)
      setAmountProducts(separationOrders)
    } catch (err: any) {
      addPopup({
        title: 'Erro ao pegar produtos por ordem de separação!',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  async function handleSearch(value: string) {
    setSearchParams({ search: value })
    setParams(state => ({ ...state, search: value, pageIndex: 1 }))
  }

  async function handleSearchBySeparationOrder() {
    setParams(state => ({ ...state, separationOrder: separationsSelected }))
    setPopover(false)
  }

  const setSeparationSteps = async () => {
    setLevel(Array.from({ length: settings.separations }, (_, i) => i + 1))
  }

  const changeSkuLevel = async (sku: IProductApi) => {
    const productApiPutRequest: IProductApiPutRequest = {
      ...sku,
      portionName: sku?.portionName1,
      portionType: mappingPortionType(sku?.portionType1),
      status: mappingStatusType(sku?.status),
      userId: userId,
    }

    try {
      await skuConfigService.updateProduct(productApiPutRequest)
    } catch (err: any) {
      if (err?.message.includes('You do not have permission to perform')) {
        addPopup({
          type: 'error',
          title: 'Você não tem permissão para atualizar o produto.',
          description: err?.message || err?.msg,
        })
        return
      }
      addPopup({
        type: 'error',
        title: 'Erro ao atualizar o produto.',
        description: err?.message || err?.msg,
      })
    }
  }

  const togglePopover = () => {
    setPopover(state => !state)
  }

  const onChangePagination = ({ page, pageSize }: { page?: number; pageSize?: number }) => {
    setParams(state => ({
      ...state,
      pageIndex: page || state.pageIndex,
      pageSize: pageSize || state.pageSize,
    }))
  }

  const onCheckSeparation = (separationOrder: number) => {
    setSeparationsSelected(state => {
      if (state.includes(separationOrder)) {
        return state.filter(item => item !== separationOrder)
      }
      return [...state, separationOrder]
    })
  }

  const onClearSeparations = () => {
    setSeparationsSelected([])
    setParams(state => ({ ...state, separationOrder: [] }))
  }

  const onSaveEditedSkus = async () => {
    setLoading(true)
    for (const item of editedSkus) {
      await changeSkuLevel({
        ...item,
        minimumExtraOrder: Number(item.minimumExtraOrder) / 100,
        maximumExtraOrder: Number(item.maximumExtraOrder) / 100,
      })
      setEditedSkus(state => state.filter(sku => sku.sku !== item.sku))
    }
    await new Promise(res => setTimeout(res, 3000))

    await getSkus()
    setLoading(false)
  }

  const EditedSkuHandler = (newSku: IProductApi) => {
    const staticSku = staticSkus.find(item => item.sku === newSku.sku)
    if (
      staticSku &&
      staticSku.uniqueTagOnly === newSku.uniqueTagOnly &&
      staticSku.maximumExtraOrder === newSku.maximumExtraOrder &&
      staticSku.minimumExtraOrder === newSku.minimumExtraOrder &&
      staticSku.separationOrder === newSku.separationOrder &&
      staticSku.needAuthorization === newSku.needAuthorization &&
      staticSku.batchRequired === newSku.batchRequired
    ) {
      setEditedSkus(state => state.filter(item => item.sku !== newSku.sku))
    } else {
      setEditedSkus(state => {
        const newEditedSkus = state.filter(item => item.sku !== newSku.sku)
        return [...newEditedSkus, newSku]
      })
    }
  }

  const onChangeEditedSkus = (
    sku: IProductApi,
    level: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => {
    const newSku = { ...sku, separationOrder: Number(level.target.value) }
    EditedSkuHandler(newSku)
    setSkus(state => state.map(item => (item.sku === sku.sku ? newSku : item)))
  }

  function onChangeMinPorcentage(sku: IProductApi, porcentage: number) {
    if (porcentage > 100 || porcentage < 0) return

    const newSku = { ...sku, minimumExtraOrder: porcentage }
    EditedSkuHandler(newSku)
    setSkus(state => state.map(item => (item.sku === sku.sku ? newSku : item)))
  }

  function onChangeMaxPorcentage(sku: IProductApi, porcentage: number) {
    if (porcentage > 100 || porcentage < 0) return

    const newSku = { ...sku, maximumExtraOrder: porcentage }
    EditedSkuHandler(newSku)
    setSkus(state => state.map(item => (item.sku === sku.sku ? newSku : item)))
  }

  function onChangeUniqueTagOnly(sku: IProductApi) {
    const newSku = { ...sku, uniqueTagOnly: !sku.uniqueTagOnly }
    EditedSkuHandler(newSku)
    setSkus(state => state.map(item => (item.sku === sku.sku ? newSku : item)))
  }

  function onChangeNeedAuthorization(sku: IProductApi) {
    const newSku = { ...sku, needAuthorization: !sku.needAuthorization }
    EditedSkuHandler(newSku)
    setSkus(state => state.map(item => (item.sku === sku.sku ? newSku : item)))
  }

  function onChangeBatchRequired(sku: IProductApi) {
    const newSku = { ...sku, batchRequired: !sku.batchRequired }
    EditedSkuHandler(newSku)
    setSkus(state => state.map(item => (item.sku === sku.sku ? newSku : item)))
  }

  const InputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <HiPencil color="#A3A3A3" size={22} />
      </InputAdornment>
    ),
  }

  const handleChange = async () => {
    setLoadingValidty(true)
    try {
      setChecked(state => !state)
      await settingsService.updateSettings({
        prePickingValidityCheck: !checked,
      })

      getSetting()

      addPopup({
        type: 'success',
        title: 'Sucesso ao atualizar trava de validade',
        description: 'Trem bão é coisa boa',
      })
    } catch {
      setChecked(state => !state)
      addPopup({
        type: 'error',
        title: 'Erro ao atualizar trava de validade',
        description: 'Dá um alô pro time de Tech',
      })
    } finally {
      setLoadingValidty(false)
    }
  }

  useEffect(() => {
    setEditedSkus([])
    getSkus()
  }, [params.pageIndex, params.pageSize, params.search, params.separationOrder])

  useEffect(() => {
    setSeparationSteps()
  }, [settings.separations])

  return (
    <>
      <ContainerPage>
        <Box>
          <Box className={classes.boxUrl}>
            <Link to="../configuracoes" className={classes.boxLink}>
              Configurações
            </Link>
            <IoIosArrowForward fontSize="1.125rem" />
            <Typography className={classes.boxPlace}>Etapas</Typography>
          </Box>
          <Box className={classes.boxAlign}>
            <Typography className={classes.titlePage}>SKUs</Typography>
          </Box>
          <Box className={classes.containerConfig}>
            <Button
              variant="outlined"
              onClick={onSaveEditedSkus}
              className={classes.buttonSave}
              disabled={!editedSkus.length}
            >
              <AiFillSave size={'1rem'} style={{ marginRight: '0.6rem' }} /> Salvar
            </Button>
            <Button
              variant="outlined"
              onClick={() => setDialogUploadExcel(true)}
              className={classes.buttonManyEdit}
            >
              <FaFileUpload size={'1rem'} style={{ marginRight: '0.6rem' }} /> Alterar em massa
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setDialogEditSeparation({
                  data: settings.separations,
                  isOpen: true,
                })
              }}
              className={classes.configs}
            >
              <FaCog size={'1rem'} style={{ marginRight: '0.6rem' }} /> Alterar n° de etapas
            </Button>
          </Box>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Tooltip title="Aqui você ativa ou desativa a verificação de validade dos pre pickings na separação.">
              <FormControlLabel
                control={
                  <SwitchIOS checked={settings.prePickingValidityCheck} onChange={handleChange} />
                }
                label={loadingValidty ? 'Opa, tamo alterando...' : 'Verificação de validade'}
                style={{ justifyContent: 'flex-end', marginRight: '0', fontWeight: 'bold' }}
                disabled={loadingValidty}
              />
            </Tooltip>
          </Box>
        </Box>
        <Box className={classes.header}>
          <Box className={classes.seachBox}>
            <Search
              placeholder="Digite o nome ou SKU do produto"
              onChange={e => searchDebaunce(e.target.value)}
              className={classes.search}
              defaultValue={searchParams.get('search')}
              autoFocus
            />
            <Box>
              <Button
                variant="contained"
                ref={userRef}
                className={classes.buttonFilter}
                onClick={togglePopover}
              >
                <BsFilter size="1.5rem" /> <span className={classes.textButton}>Filtrar</span>
                {!!separationsSelected.length && (
                  <span className={classes.quantityFilter}>{separationsSelected.length}</span>
                )}
              </Button>
              <MenuPopover
                open={popover}
                onClose={() => setPopover(false)}
                anchorEl={userRef.current}
                style={{ marginTop: '0.5rem' }}
              >
                <Box className={classes.containerPopover}>
                  {amountProducts.map((value, index) => (
                    <MenuItem
                      key={value.separationOrder}
                      className={classes.menuItem}
                      onClick={() => onCheckSeparation(value.separationOrder)}
                    >
                      <ListItemText>
                        <Checkbox
                          className={classes.checkBoxStep}
                          value={value.separationOrder}
                          checked={separationsSelected.includes(value.separationOrder)}
                        />{' '}
                        Etapa {value.separationOrder}
                      </ListItemText>
                    </MenuItem>
                  ))}
                  <MenuItem
                    className={classes.menuItem}
                    style={{ marginBottom: '0' }}
                    onClick={() => handleSearchBySeparationOrder()}
                  >
                    <ListItemText>
                      <Button style={{ width: '100%', background: '#89BD23', color: 'white' }}>
                        Filtrar
                      </Button>
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    style={{ marginTop: '0' }}
                    className={classes.menuItem}
                    onClick={() => onClearSeparations()}
                  >
                    <ListItemText style={{ textAlign: 'center' }}>Limpar</ListItemText>
                  </MenuItem>
                </Box>
              </MenuPopover>
            </Box>
          </Box>
        </Box>
        {loading && <LinearProgress variant="indeterminate" style={{ marginTop: '1rem' }} />}
        {!loading && (
          <>
            <Box className={classes.containerScroll}>
              <Box className={classes.content}>
                <Box component="header" className={classes.headerContent}>
                  <strong style={{ textAlign: 'left' }}>SKU</strong>
                  <strong style={{ textAlign: 'left' }}>Produto</strong>
                  <strong style={{ textAlign: 'center' }}>Mín. % Extra order</strong>
                  <strong style={{ textAlign: 'center' }}>Max. % Extra order</strong>
                  <strong style={{ textAlign: 'center' }}>Etiqueta única</strong>
                  <strong style={{ textAlign: 'center' }}>Precisa de autorização?</strong>
                  <strong style={{ textAlign: 'center' }}>ID. Lote</strong>
                  <strong style={{ textAlign: 'right' }}>Ordem de separação</strong>
                </Box>

                <Box style={{ background: '#ffffff' }}>
                  {skus.map((sku, index) => (
                    <>
                      <Divider className={classes.divider} />

                      <Box key={sku.sku} className={classes.boxItem}>
                        <strong className={classes.itemTitle}>{sku?.sku}</strong>
                        <strong className={classes.itemTitle}>{sku?.name}</strong>

                        <Box className={classes.containerInput}>
                          <TextField
                            type="number"
                            variant="outlined"
                            className={classes.inputExtraOrder}
                            onChange={e => onChangeMinPorcentage(sku, Number(e.target.value))}
                            value={sku.minimumExtraOrder || ''}
                            InputProps={InputProps}
                          />
                        </Box>
                        <Box className={classes.containerInput}>
                          <TextField
                            type="number"
                            variant="outlined"
                            className={classes.inputExtraOrder}
                            onChange={e => onChangeMaxPorcentage(sku, Number(e.target.value))}
                            value={sku.maximumExtraOrder || ''}
                            InputProps={InputProps}
                          />
                        </Box>
                        <Box className={classes.containerInput}>
                          <Checkbox
                            color="primary"
                            checked={sku.uniqueTagOnly}
                            onChange={e => {
                              onChangeUniqueTagOnly(sku)
                            }}
                          />
                        </Box>
                        <Box className={classes.containerInput}>
                          <Checkbox
                            color="primary"
                            checked={sku.needAuthorization}
                            onChange={e => {
                              onChangeNeedAuthorization(sku)
                            }}
                          />
                        </Box>
                        <Box className={classes.containerInput}>
                          <Checkbox
                            color="primary"
                            checked={sku.batchRequired}
                            onChange={e => {
                              onChangeBatchRequired(sku)
                            }}
                          />
                        </Box>
                        <FormControl variant="outlined" className={classes.containerSelect}>
                          <Select
                            className={`${classes.selectForm} ${
                              !sku.separationOrder && classes.withoutLevel
                            }`}
                            value={sku.separationOrder || 0}
                            renderValue={value => {
                              const curr = level.find(lvl => lvl === value)
                              return curr ? `Etapa ${value}` : 'Selecionar'
                            }}
                            IconComponent={IoChevronDown}
                            onChange={event => onChangeEditedSkus(sku, event)}
                          >
                            {!level.find(number => number === sku.separationOrder) && (
                              <MenuItem value={0}>Selecionar</MenuItem>
                            )}
                            {level.map((lvl, idx) => (
                              <MenuItem key={idx} value={lvl}>
                                Etapa {lvl}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box className={classes.containerScroll}>
              <Pagination
                page={params.pageIndex}
                count={params.total}
                onChange={(_, newPage: number) => onChangePagination({ page: newPage })}
                rowsPerPage={params.pageSize}
                handleRowsPerPageChange={newPageSize =>
                  onChangePagination({ pageSize: newPageSize, page: 1 })
                }
              />
            </Box>
          </>
        )}
      </ContainerPage>
      {dialogEditSeparation.isOpen && (
        <DialogEdit
          setIsOpen={setDialogEditSeparation}
          isOpen={dialogEditSeparation.isOpen}
          data={dialogEditSeparation.data}
          amountProducts={amountProducts}
        />
      )}

      <DialogUpload isOpen={dialogUploadExcel} setIsOpen={setDialogUploadExcel} getSkus={getSkus} />
    </>
  )
}
