import { Box, Typography } from '@material-ui/core'
import { IOrder } from 'interfaces/IOrder'
import { ISeparation, IItem } from 'interfaces/ISeparation'
import { useState } from 'react'

import { SeparationItem } from '../SeparationItem'
import { ModalEditWeight } from './ModalEditWeight'
import { useStyles } from './styles'

interface ISeparations {
  separations: ISeparation[]
  order: IOrder
  refresh: () => Promise<void>
}

export const Separations = (props: ISeparations) => {
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false)
  const [item, setItem] = useState<IItem>({} as IItem)
  const { separations, order, refresh } = props
  const classes = useStyles()

  const handleOpenModalEdit = (item: IItem) => {
    setItem(item)
    setIsOpenModalEdit(state => !state)
  }
  return (
    <Box>
      <Typography component="h2" className={classes.title}>
        Itens do pedido
      </Typography>

      <Box className={classes.container}>
        {order.checking_status &&
          separations
            ?.filter(separation => separation.items.length)
            .map(separation => {
              if (separation.separation === 'no_separation_step') {
                return (
                  <Box className={classes.root} key={separation.separation}>
                    <Typography variant="h1" className={classes.subtitle}>
                      Itens sem etapa
                    </Typography>
                    <SeparationItem items={separation.items} setItem={handleOpenModalEdit} />
                  </Box>
                )
              }
              return (
                <Box className={classes.root} key={separation.separation}>
                  <Typography variant="h1" className={classes.title}>
                    {`Etapa ${separation.separation}`}
                  </Typography>
                  <SeparationItem items={separation.items} setItem={handleOpenModalEdit} />
                </Box>
              )
            })}
      </Box>

      <ModalEditWeight
        open={isOpenModalEdit}
        item={item}
        handleClose={() => setIsOpenModalEdit(state => !state)}
        refresh={refresh}
      />
    </Box>
  )
}
