export const storeMap = (value: number | null) => {
  const text =
    {
      1: 'PJ',
      2: 'PF',
      3: 'PJ',
      4: 'PF',
      5: 'iFood Economic',
      6: 'iFood Economic',
      7: 'iFood Shop',
      8: 'iFood Cestas',
      9: 'iFood Mercado',
      10: 'Aplicativo',
      11: 'Aplicativo',
      12: 'IFood Entregue p/ frexco',
    }[value as number] || 'Loja não encontrada'

  return text
}
