import '@brainhubeu/react-carousel/lib/style.css'
import Carousel, { slidesToShowPlugin, arrowsPlugin } from '@brainhubeu/react-carousel'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { CardInfo } from 'components'
import { IOrder } from 'interfaces/IOrder'
import { useEffect, useState } from 'react'
import { FaRoute, FaPalette } from 'react-icons/fa'
import { RiShoppingBagFill } from 'react-icons/ri'
import { storeMap } from 'utils'

import { useStyles } from './styles'
import { mapTagColor } from 'utils/mapTagColor'

interface IContainerInfosProps extends IOrder {
  className?: string
}

export const ContainerInfos = (props: IContainerInfosProps) => {

  const classes = useStyles()
  const [index, setIndex] = useState(0)
  const [width, setWidth] = useState(() => window?.innerWidth)

  const optionsButton = {
    resolve: arrowsPlugin,
    options: {
      arrowLeft: (
        <button className={classes.arrowLeft}>
          <ArrowBackIosIcon className={classes.arrowIcon} />
        </button>
      ),
      arrowLeftDisabled: (
        <button disabled={true} className={classes.arrowLeft}>
          <ArrowBackIosIcon className={classes.arrowIcon} />
        </button>
      ),
      arrowRight: (
        <button className={classes.arrowRight}>
          <ArrowForwardIosIcon className={classes.arrowIcon} />
        </button>
      ),
      arrowRightDisabled: (
        <button disabled={true} className={classes.arrowRight}>
          <ArrowForwardIosIcon className={classes.arrowIcon} />
        </button>
      ),
      addArrowClickHandler: true,
    },
  }

  useEffect(() => {
    const updateWidth = (e: any) => {
      setWidth(e?.target?.innerWidth)
    }

    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  return (
    <Carousel
      className={classes.carousel}
      offset={30}
      draggable={width <= 1240}
      value={width <= 1240 ? index : 0}
      onChange={e => {
        if (e >= 0) {
          setIndex(e)
        }
      }}
      plugins={[
        {
          resolve: arrowsPlugin,
          options: {
            arrowLeft: () => <button style={{ display: 'none' }}></button>,
            arrowLeftDisabled: () => <button style={{ display: 'none' }}></button>,
            arrowRight: () => <button style={{ display: 'none' }}></button>,
            arrowRightDisabled: () => <button style={{ display: 'none' }}></button>,
            addArrowClickHandler: true,
          },
        },
        {
          resolve: slidesToShowPlugin,
          options: {
            numberOfSlides: 3,
          },
        },
      ]}
      breakpoints={{
        1100: {
          plugins: [
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 3,
              },
            },
            optionsButton,
          ],
          offset: 30,
        },
        850: {
          plugins: [
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 2,
              },
            },
            optionsButton,
          ],
          offset: 20,
        },
        520: {
          plugins: [
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 1,
              },
            },
            optionsButton,
          ],
        },
      }}
    >
      <CardInfo icon={RiShoppingBagFill} title="Loja" info={storeMap(props.store_id)} color="#2E656A" />
      <CardInfo icon={FaRoute} title="Rota" info={props.route ?? 'SEM ROTA'} color="#2E656A" />
      <CardInfo
        icon={FaPalette}
        title="Cor da Rota"
        info={props.color_route}
        color={props.color_route === 'BRANCA' ? '#9b9ea3' : mapTagColor(props.color_route)}
      />
    </Carousel>
  )
}
