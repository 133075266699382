import { usePrePicking } from 'hooks/usePrePicking'
import { ISeparationGroupConfig } from 'interfaces/ISeparationGroupService'
import {
  Dispatch,
  FormEvent,
  ForwardRefRenderFunction,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { ImQrcode } from 'react-icons/im'
import { useSelector } from 'react-redux'
import { Modal } from 'shared'
import { IState } from 'store'

import Cam from '../Cam'
import { InvisibleInput } from '../styles'
import { TValue } from '../useScanner'

interface IProps {
  onSubmit: (qrcode: string, type: TValue) => void
}

const Content = ({ onSubmit }: IProps) => {
  const [camScanner, infraredScanner] = useSelector<IState, [boolean, boolean]>(state => {
    const numberSeparationGroup = state.infos.runningMachine as number
    const { cam_scanner, infrared_scanner } = state.settings.separationGroups.find(
      ({ separation_group }) => Number(separation_group) === Number(numberSeparationGroup),
    ) as ISeparationGroupConfig
    return [cam_scanner, infrared_scanner]
  })
  const inputRef = useRef<HTMLInputElement>(null)
  const intervalRef = useRef<any>(null)
  const { checkIsBarcodeInfrared } = usePrePicking()

  const handleScanScanner = (param: FormEvent<HTMLElement>) => {
    const formData = new FormData(param.target as HTMLFormElement)
    param.preventDefault()
    const inputValue = formData.get('inputValue')
    const isBarcode = checkIsBarcodeInfrared(String(inputValue))
    onSubmit(String(inputValue), isBarcode ? 'bar_code' : 'qr_code')

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 200)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  return (
    <>
      {camScanner && <Cam onSubmit={onSubmit} />}
      {infraredScanner && (
        <form onSubmit={handleScanScanner}>
          <InvisibleInput type="text" inputRef={inputRef} hidden name="inputValue" />
          <ImQrcode
            size={150}
            color="#757575"
            style={{ margin: '2rem auto 0 auto', display: 'block' }}
          />
        </form>
      )}
    </>
  )
}

export interface IModalExclude {
  onOpen: () => void
  onClose: () => void
}

interface IModalProps extends IProps {
  setLockScanner: Dispatch<SetStateAction<boolean>>
}

const Component: ForwardRefRenderFunction<IModalExclude, IModalProps> = (
  { onSubmit, setLockScanner },
  ref,
) => {
  const [open, setOpen] = useState(false)

  const onOpen = () => setOpen(true)
  const onClose = () => {
    setOpen(false)
    setLockScanner(false)
  }

  useImperativeHandle(ref, () => ({ onOpen, onClose, open }))

  const handleSubmit = (qrcode: string, type: TValue) => {
    onSubmit(qrcode, type)
    onClose()
  }

  return (
    <Modal
      title="Escaneie para excluir"
      open={open}
      handleClose={onClose}
      style={{ minHeight: '13.81rem', paddingBottom: '1.5rem' }}
    >
      <Content onSubmit={handleSubmit} />
    </Modal>
  )
}

export const ModalExclude = forwardRef(Component)
