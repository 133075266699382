import { ForwardRefRenderFunction, forwardRef, useImperativeHandle, useState } from 'react'

import { Title, Modal, Text, Button } from './styles'

export interface IDialogAlertBeginner {
  onOpen: (callback?: Function) => void
  onClose: () => void
}

const Component: ForwardRefRenderFunction<IDialogAlertBeginner> = (_, ref) => {
  const [open, setOpen] = useState(false)
  const [callback, setCallback] = useState<Function | null>(null)

  const onOpen = (callback?: Function) => {
    if (callback) {
      setCallback(callback)
    }
    setOpen(true)
  }
  const onClose = async () => {
    callback && (await callback())
    setOpen(false)
    setCallback(null)
  }

  useImperativeHandle(ref, () => ({ onOpen, onClose }))

  return (
    <Modal open={open}>
      <Title variant="h4">Pedido crítico!</Title>
      <Text>
        Por gentileza, leve as caixas desse pedido pro cantinho especial para conferência!
      </Text>
      <Button onClick={onClose}>É pra já!</Button>
    </Modal>
  )
}

export const DialogAlertBeginner = forwardRef(Component)
