import { Box, Typography } from '@material-ui/core'
import { GiCardboardBoxClosed } from 'react-icons/gi'

import { useStyles } from './styles'

interface CardBreakProps {
  quantity?: number
  action: () => void
}

export const CardBreak = ({ quantity, action }: CardBreakProps) => {
  const classes = useStyles({ isDisabled: !quantity })

  return (
    <Box className={classes.root} onClick={Number(quantity) > 0 ? action : undefined}>
      <span className={classes.containerIcon}>
        <GiCardboardBoxClosed fontSize={30} color="#AB98EE" />
      </span>

      <Box>
        <Typography className={classes.title}>Total de quebras</Typography>
        <Typography className={classes.subtitle}>
          {quantity ? `${quantity} produto${quantity > 1 ? 's' : ''}` : 'Nenhuma quebra'}
        </Typography>
      </Box>
    </Box>
  )
}
