import { usePopup } from 'hooks/usePopup'
import { FC, useContext, createContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { dashboardService } from 'services/dashboardService'
import { IWorkConference, enTypeOfAnalysis, workConferenceService } from 'services/workConference'
import { IState } from 'store'
import { updateRunningMachine } from 'store/reducers/infos/actions'
import { updatePreviousOrder } from 'store/reducers/orderFastTrack/actions'
import { getDeliveryDate } from 'utils'

type IUseConferenceChuchu = {
  workConference?: IWorkConference
  updateWorkConference: (value: IWorkConference) => void
  recoveryConference: () => Promise<void>
  loading: boolean
}

const UseConferenceChuchuContext = createContext<IUseConferenceChuchu>({} as IUseConferenceChuchu)

const UseConferenceChuchuProvider: FC = ({ children }) => {
  const [workConference, setWorkConference] = useState<IWorkConference>()
  const {
    user: { userId },
  } = useSelector<IState, IState>(state => state)
  const { addPopup } = usePopup()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const updateWorkConference = async (value: IWorkConference) => {
    setWorkConference(value)
  }

  const inConferencePageAndHasNoPendingConference = async () => {
    try {
      const scheduledDate = getDeliveryDate()
      const responseWorks = await workConferenceService.getAllWork({
        scheduledDate: `${scheduledDate.year}-${scheduledDate.month}-${scheduledDate.day}`,
        checkerId: Number(userId),
      })

      // If the user don't confered any order yet, redirect to choose a separation group to separate order
      if (!responseWorks.results.length) {
        navigate('/operacao/separacao/grupos')
        return
      }

      const mostRecentWork = responseWorks.results[0]

      if (mostRecentWork.type_of_analysis !== enTypeOfAnalysis.REVISION) return

      const { order } = await dashboardService.getOrderDetails(mostRecentWork.order.order_id)
      dispatch(updateRunningMachine(order.separation_group))
      dispatch(updatePreviousOrder(order))
      navigate('operacao/separacao/pedido?productivity=true')
      addPopup({
        type: 'info',
        title: `A conferência do pedido #${mostRecentWork.order.order_id} já foi finalizada`,
        description: 'Redirecionando para a tela de separação...',
      })
    } catch (err: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao redirecionar para a tela de separação',
      })
    }
  }

  const recoveryConference = async () => {
    try {
      setLoading(true)
      const response = await workConferenceService.getRecovery()
      if (response.message === 'Foi encontrado uma conferência pendente') {
        const { order } = await dashboardService.getOrderDetails(response.data.order.order_id)
        dispatch(updateRunningMachine(order.separation_group))
        updateWorkConference(response.data)

        if (!window.location.href.includes(`conferencia/${response.data.order.order_id}`)) {
          addPopup({
            type: 'success',
            title: 'Foi encontrado uma conferência pendente',
          })
          navigate(`/operacao/conferencia/${response.data.order.order_id}`)
        }
        return
      }
    } catch (err: any) {
      if (err?.message === 'Nenhuma conferência pendente') {
        const url = window.location.href
        const lastParamUrl = url.split('/')[url.split('/').length - 1]
        if (
          url.includes('operacao/conferencia/') &&
          !['inicio', 'conferencia'].includes(lastParamUrl)
        ) {
          await inConferencePageAndHasNoPendingConference()
        }
        return
      }
      addPopup({
        type: 'error',
        title: 'Erro ao procurar conferência pendente',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <UseConferenceChuchuContext.Provider
      value={{ updateWorkConference, workConference, recoveryConference, loading }}
    >
      {children}
    </UseConferenceChuchuContext.Provider>
  )
}

const useConferenceChuchu = () => useContext(UseConferenceChuchuContext)

export { UseConferenceChuchuProvider, useConferenceChuchu }
