import { IAuthentication } from 'interfaces/IAuthentication'
import { ILogin } from 'interfaces/ILogin'

import { api as apiService, ApiService } from './api'

class AuthenticationService {
  constructor(private readonly api: ApiService) {}

  public postLogin = async (login: ILogin): Promise<IAuthentication> => {
    return this.api.post(authUrl + '/v2/token', login)
  }
}
const authUrl = process.env.REACT_APP_API_AUTH
export const authenticationService = new AuthenticationService(apiService)
