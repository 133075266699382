import {
  createTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core'
import { ptBR } from '@material-ui/core/locale'
import { ReactNode } from 'react'

import Overrides from './overrides'
import palette from './palette'
import shadows from './shadows'

interface Props {
  children: ReactNode
}

const ThemeProvider = ({ children }: Props) => {
  const theme = createTheme(
    {
      palette,
      shadows,
    },
    ptBR,
  )
  theme.overrides = Overrides(theme)

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}

export default ThemeProvider
